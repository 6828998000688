import React from 'react';
import * as r from 'ramda'
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';

import Api from 'api';
import './indexTopLeft.scss';

import Img_down from 'img/up.png';
import Img_up from 'img/down.png';

TweenOne.plugins.push(Children);


class IndexTopLeft extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            todayExpenses: 0,
            todayExpensesUpOrDown: 0,

            todayEnergy: 0,
            todayEnergyUpOrDown: 0,

            monthExpenses: 0,
            monthExpensesUpOrDown: 0,

            yearsEnergy: 0,
            yearsEnergyUpOrDown: 0,

            yearsExpenses: 0,
            yearsExpensesUpOrDown: 0,
        };
    }

    formatCurrent(data) {
        return r.propOr(0)('current')(data).toFixed(2)
    }
    formatTrend(data) {
        const number = r.propOr(0)('trend')(data) === "Infinity" || r.propOr(0)('trend')(data) === "NaN" ? 0 : r.propOr(0)('trend')(data) * 100
        return number.toFixed(2)
    }

    getData() {
        const self = this;
        // 今日 能耗 : /energy/kgceDaily  费用 :/cost/costDaily
        Api.postV3('/energy/kgceDaily', {
            project: Api.projectId
        }).then(({ data }) => {
            this.setState({
                todayEnergy: this.formatCurrent(data),
                todayEnergyUpOrDown: this.formatTrend(data)
            })
        })
        //日费用取消 20240903
        this.setState({
            todayExpenses: 0,
            todayExpensesUpOrDown: 0,
        })
        // Api.postV3('/cost/costDaily', {
        //     project: Api.projectId
        // }).then(({ data }) => {
        //     this.setState({
        //         todayExpenses: this.formatCurrent(data),
        //         todayExpensesUpOrDown: this.formatTrend(data),
        //     })
        // })

        // 本月 能耗:/cost/costDaily
        // 费用接口取消 20240903
        // 这里怎么能耗数据给了费用参数，下面的也是用这个错误的名称，最终数据是到了对了位置
        Api.postV3('/energy/kgceMonthly', {
            project: Api.projectId
        }).then(({ data }) => {
            this.setState({
                monthExpenses: this.formatCurrent(data),
                monthExpensesUpOrDown: this.formatTrend(data)
            })
        })

        // 今年 能耗 :/energy/kgceAnnually  费用 :/cost/costAnnually
        Api.postV3('/energy/kgceAnnually', {
            project: Api.projectId
        }).then(({ data }) => {
            this.setState({
                yearsEnergy: this.formatCurrent(data),
                yearsEnergyUpOrDown: this.formatTrend(data)
            })
        })
        //费用取消 20240903
        this.setState({
            yearsExpenses: 0,
            yearsExpensesUpOrDown: 0

        })
        // Api.postV3('/cost/costAnnually', {
        //     project: Api.projectId
        // }).then(({ data }) => {
        //     this.setState({
        //         yearsExpenses: this.formatCurrent(data),
        //         yearsExpensesUpOrDown: this.formatTrend(data)
        //
        //     })
        // })

    }


    componentDidMount() {
        this.getData()
    }

    render() {
        const { } = this.state
        return (
            <ul className='indexTopLeft'>
                <li>
                    <p>今日总费用（元）
                        <span className={`${this.state.todayExpensesUpOrDown >= 0 ? "up" : "down"}`}>
                            {this.state.todayExpensesUpOrDown >= 0 ? "+" + this.state.todayExpensesUpOrDown : this.state.todayExpensesUpOrDown}%
                            <i>
                                <img src={this.state.todayExpensesUpOrDown >= 0 ? Img_up : Img_down} alt="智慧云能源" />
                            </i>
                        </span>
                    </p>
                    <b>
                        <TweenOne
                            animation={
                                {
                                    Children: {
                                        value: this.state.todayExpenses, floatLength: 2,
                                    },
                                    duration: 3000,
                                }
                            }
                        >
                            0
          </TweenOne>
                    </b>
                    <i className="border"></i>
                </li>
                <li>
                    <p>今日总能耗（kgec）<span className={`${this.state.todayEnergyUpOrDown >= 0 ? "up" : "down"}`}>{this.state.todayEnergyUpOrDown >= 0 ? "+" + this.state.todayEnergyUpOrDown : this.state.todayEnergyUpOrDown}%<i><img src={this.state.todayEnergyUpOrDown >= 0 ? Img_up : Img_down} alt="智慧云能源" /></i></span></p>
                    <b>
                        <TweenOne
                            animation={
                                {
                                    Children: {
                                        value: this.state.todayEnergy, floatLength: 2,
                                    },
                                    duration: 3000,
                                }
                            }
                        >
                            0
          </TweenOne>
                    </b>
                    <i className="border"></i>
                </li>
                <li>
                    <p>本月总能耗（kgec）<span className={`${this.state.monthExpensesUpOrDown >= 0 ? "up" : "down"}`}>{this.state.monthExpensesUpOrDown >= 0 ? "+" + this.state.monthExpensesUpOrDown : this.state.monthExpensesUpOrDown}%<i><img src={this.state.monthExpensesUpOrDown >= 0 ? Img_up : Img_down} alt="智慧云能源" /></i></span></p>
                    <b>
                        <TweenOne
                            animation={
                                {
                                    Children: {
                                        value: this.state.monthExpenses, floatLength: 2,
                                    },
                                    duration: 3000,
                                }
                            }
                        >
                            0
          </TweenOne>
                    </b>
                    <i className="border"></i>
                </li>
                <li>
                    <p>年度总能耗（kgec）<span className={`${this.state.yearsEnergyUpOrDown >= 0 ? "up" : "down"}`}>{this.state.yearsEnergyUpOrDown >= 0 ? "+" + this.state.yearsEnergyUpOrDown : this.state.yearsEnergyUpOrDown}%<i><img src={this.state.yearsEnergyUpOrDown >= 0 ? Img_up : Img_down} alt="智慧云能源" /></i></span></p>
                    <b>
                        <TweenOne
                            animation={
                                {
                                    Children: {
                                        value: this.state.yearsEnergy, floatLength: 2,
                                    },
                                    duration: 3000,
                                }
                            }
                        >
                            0
          </TweenOne>
                    </b>
                    <i className="border"></i>
                </li>
                <li>
                    <p>年度总费用（百万元）
                        <span className={`${this.state.yearsExpensesUpOrDown >= 0 ? "up" : "down"}`}>
                            {this.state.yearsExpensesUpOrDown >= 0 ? "+" + this.state.yearsExpensesUpOrDown : this.state.yearsExpensesUpOrDown}%
                        <i><img src={this.state.yearsExpensesUpOrDown >= 0 ? Img_up : Img_down} alt="智慧云能源" /></i>
                        </span>
                    </p>
                    <b>
                        <TweenOne
                            animation={
                                {
                                    Children: {
                                        value: this.state.yearsExpenses, floatLength: 2,
                                    },
                                    duration: 3000,
                                }
                            }
                        >
                            0
          </TweenOne>
                    </b>
                </li>
            </ul >
        )
    }
}

export default IndexTopLeft;