import React, {useContext} from 'react';
import * as r from 'ramda';
import moment from 'moment'
import {Form, message, Modal} from 'antd';
import {context, loadUpdateData} from './registerInfo';
import {of} from 'rxjs';
import Api from 'src/api'
import UpdateProjectForm from "../newRegisterInfo/updateRegisterInfo";
import {joinAllModules} from "src/context/project";

const Management = (props) => {
  const {state, dispatch} = useContext(context)
  const {record} = props
  console.log('Management state', state);
  const showModal = () => {
    dispatch(s => of({...r.omit(['project'], s), visible: record.id, project: record}))
  }

  const handleUpdate = () => {

    console.log(state.project);
    dispatch(state => of({...r.omit(['project'], state), visible: false, loading: true}))
    const {id, createTime, stopTime} = state.project
    Api.put(`/project/${id}`, {
      ...state.project,
      type: state.project.category,
      effectiveDays: Math.floor(moment.duration(moment(stopTime).diff(moment(createTime))).asDays())
    })
      .then(() => {
        dispatch(state => of({...r.omit(['project'], state), visible: false, loading: true}))
        return dispatch(state => loadUpdateData({
          ...state, loading: true,
        }))
      }).catch(() => message.info('请重试'))

  }

  const handleCancel = () => {
    dispatch(s => of({...r.omit(['project'], s), visible: false}))
  }

  const onValuesChange = (props, changedValues, allValues) => {
    console.log('onValuesChange', props, changedValues, allValues);
    //因为输入框会不停触发，这里返回
    // if(changedValues.building || changedValues.longitude2  || changedValues.latitude2  || changedValues.parent  || changedValues.path ){
    //     return;
    // }
    const {web, admin, category, analysis, extra, time, timeRange: [, endRangeTime],longitude2,latitude2,region,building,parent,path} = allValues
    const modules = r.uniq(joinAllModules(web, admin, analysis, extra))

    const stopTime = time === '自定义' ? endRangeTime : moment(record.createTime).add(time, 'days')
    dispatch(s => of({
      ...s,
      project: r.mergeDeepRight(r.mergeDeepRight(record, {modules:[]}), {category, modules, stopTime: stopTime.format('YYYY-MM-DD'), time,longitude2,latitude2,region,building,parent,path})
    }))
  }

  const UpdateProject = Form.create({name: 'updateProject', onValuesChange})(UpdateProjectForm)

  return (
    <div className='managementRegister'>
            <span onClick={showModal}>
                管理
            </span>

      <Modal
        title="编辑项目"
        visible={state.visible === record.id}
        onOk={handleUpdate}
        onCancel={handleCancel}
        destroyOnClose={true}
        className='edit-project'
      >
        <UpdateProject project={state.project || record}/>


      </Modal>
    </div>
  );
}


export default Management;