import React, {useContext} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
// import {context, loadNodeUsage} from '../report'
// import {context, loadNodeUsage} from '../indexMidAverage'
import {of, from} from 'rxjs'
import './intervalReport.scss';
import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';
// import {context,nodeUsage} from "../indexMidAverage";
import {nodeUsage,nodeUsage2} from "../indexMidAverage";
import {context} from "../averageContext";
import Docxtemplater from "docxtemplater";
import saveAs from "file-saver";
import PizZip from "pizzip";
import ImageModule from "docxtemplater-image-module-free";
import JSZipUtils from "jszip-utils";
import wenzhoushiDoc from '../../wenzhoushi_projects.docx'

export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))
}
//用来新的写法使用，不是withState使用的方式
export const loadNodeUsage2 = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage2(state)
        .then(res => ({...state, ...res})))
}

// const nodeUsage = async ({node, from, to, deviceType, search}) => {
//     const {data: dayReport} = await Api.get(`/api/v3/project/${Api.projectId}/report/${node}?from=${from}&to=${to}`)
//
//     const isFn = n => {
//         const {sensor, title} = n;
//         const re = new RegExp(search)
//         return r.test(re)(sensor) || r.test(re)(title)
//     }
//     // console.log(dayReport)
//     // const all = r.filter(isFn)(translateDeviceType(deviceType, dayReport));
//     // const tableData = all.map(({usage: sum, sensor: instrumentId, channel, cost, startScale = 0, endScale = 0, price, coding, title, type, comi = '-'}, i) => ({
//     //     sum,
//     //     instrumentId,
//     //     channelName: translateChannel(channel),
//     //     order: i + 1 < 10 ? `0${i + 1}` : i + 1,
//     //     name: title,
//     //     eqType: translateType(type).name,
//     //     price,
//     //     cost,
//     //     energyType: translateSubentry(coding),
//     //     startScale: Number(startScale).toFixed(2),
//     //     endScale: Number(endScale).toFixed(2),
//     //     comi,
//     //
//     // }))
//
//     // const totalUsage = r.compose(r.sum, r.flatten, r.map(v => r.prop('usage')(v)))
//     // (r.filter(n => !r.equals('-', r.prop('usage')(n)))(all))
//     //
//     // const totalCost = r.compose(r.sum, r.flatten, r.map(v => r.prop('cost')(v)))
//     // (r.filter(n => !r.equals('-', r.prop('cost')(n)))(all))
//
//     return {
//         loading: false,
//         node,
//         // select: r.map(([k, v]) => (translateType(k)))(r.toPairs(dayReport)),
//         tableData:dayReport,
//         from,
//         to,
//         deviceType,
//     }
// }

const projectTypeConverter = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}




const IntervalReport = (props) => {

    const {state: { active, active2,active3,active4,active5,active6,active7,active8,active9,active10, headCount, totalArea,sector,gov_d ,gov_m,no_gov_d ,no_gov_m,buildType, colorDealRes,chartsDealArr,chartsDealArr2,standLineDealArr,
        standLineDealArr2,totalDealResArr,totalDealResArr2,chartsTime2,colorDealResArr,colorDealResArr2,totalAreaArr,totalAreaArr2,headCountArr,headCountArr2,totalNameArr,activeArr,style,deviceType,select}, dispatch} = useContext(context);

    // const setType_table = (tableData)=>{
    //     props.setType_table(tableData);
    // }

    // const {state: {loading, tableData, from, to, deviceType, select, total}, dispatch} = useContext(context);
    // const tableData = [{
    //     average: "019010400616",
    //     name: "2F???-150/5",
    //     order: "01",
    //     type: "1",
    //     area: "344.14",
    //     headCount: "14.92"}];

        //----- start 创建表格数据 -----
        let order = 1;
        let tableData = [];
        for(let key in chartsDealArr){
            if(chartsDealArr[key].length>0){
                // -- start ---添加该类型建筑总的平均数值
                // tableData.push({
                //     month_1:totalDealResArr[key][0][0],
                //     month_2:totalDealResArr[key][0][1],
                //     month_3:totalDealResArr[key][0][2],
                //     month_4:totalDealResArr[key][0][3],
                //     month_5:totalDealResArr[key][0][4],
                //     month_6:totalDealResArr[key][0][5],
                //     month_7:totalDealResArr[key][0][6],
                //     month_8:totalDealResArr[key][0][7],
                //     month_9:totalDealResArr[key][0][8],
                //     month_10:totalDealResArr[key][0][9],
                //     month_11:totalDealResArr[key][0][10],
                //     month_12:totalDealResArr[key][0][11],
                //     avg:(r.sum(totalDealResArr[key][0])/12).toFixed(2),
                //     // name: key,
                //     name: '类型总值',
                //     order: ''+order++,
                //     type:projectTypeConverter[key],
                //     area: r.sum(totalAreaArr[key]).toFixed(2),
                //     headCount: r.sum(headCountArr[key]).toFixed(2)
                // });
                // --- end --
                chartsDealArr[key].forEach(function (v,i) {
                    tableData.push({
                        month_1:chartsDealArr[key][i][0] ,
                        month_2:chartsDealArr[key][i][1],
                        month_3:chartsDealArr[key][i][2],
                        month_4:chartsDealArr[key][i][3],
                        month_5:chartsDealArr[key][i][4],
                        month_6:chartsDealArr[key][i][5],
                        month_7:chartsDealArr[key][i][6],
                        month_8:chartsDealArr[key][i][7],
                        month_9:chartsDealArr[key][i][8],
                        month_10:chartsDealArr[key][i][9],
                        month_11:chartsDealArr[key][i][10],
                        month_12:chartsDealArr[key][i][11],
                        //把同类型总的平均值也放进去，用于比较和效果的区别显示,因为重复的数据存储了很多，所以这里就用第一个组数的数据
                        total_1:totalDealResArr[key][0][0] ,
                        total_2:totalDealResArr[key][0][1],
                        total_3:totalDealResArr[key][0][2],
                        total_4:totalDealResArr[key][0][3],
                        total_5:totalDealResArr[key][0][4],
                        total_6:totalDealResArr[key][0][5],
                        total_7:totalDealResArr[key][0][6],
                        total_8:totalDealResArr[key][0][7],
                        total_9:totalDealResArr[key][0][8],
                        total_10:totalDealResArr[key][0][9],
                        total_11:totalDealResArr[key][0][10],
                        total_12:totalDealResArr[key][0][11],
                        avg:(r.sum(chartsDealArr[key][i])/12).toFixed(2),
                        name: totalNameArr[key][i],
                        order: ''+order++,
                        type:projectTypeConverter[key],
                        area: totalAreaArr[key][i],
                        headCount: headCountArr[key][i]});
                })

            }

        }
        console.log('tableData create ---------------');
        console.log(tableData);
        // this.props.setData({tableData:tableData,totalDealResArr:totalDealResArr});

       props.setType_table(tableData);




        //------ end 表格数据创建完成

    // console.log('props.tableData');
    // console.log(props.tableData);
    // const tableData = props.tableData?props.tableData.tableData:[];

    // const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData));
    const columns = [{
        title:' 序号',
        dataIndex: 'order',
        key: 'order',
        width: '6%',
    }, {
        title: '项目名称',
        dataIndex: 'name',
        key: 'name',
        width: '6%',
        // render: (text, record) => (
        //     <Tooltip placement="top" title={text}>
        //         <span className='tableName'>{text}</span>
        //     </Tooltip>
        // ),
    }, {
        title: '项目类型',
        dataIndex: 'type',
        key: 'type',
        width: '6%',
    }, {
        title: '面积',
        dataIndex: 'area',
        key: 'area',
        width: '6%',
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        // sortDirections: ['descend'],
    },
        {
            title: '人数',
            dataIndex: 'headCount',
            key: 'headCount',
            width: '6%',

        },
        {
            title: '1月',
            dataIndex: 'month_1',
            key: 'month_1',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_1){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_1})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
            // sorter: (a, b) => a.sum - b.sum,
        },
        {
            title: '2月',
            dataIndex: 'month_2',
            key: 'month_2',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_2){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_2})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '3月',
            dataIndex: 'month_3',
            key: 'month_3',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_3){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_3})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '4月',
            dataIndex: 'month_4',
            key: 'month_4',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_4){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_4})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '5月',
            dataIndex: 'month_5',
            key: 'month_5',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_5){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_5})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '6月',
            dataIndex: 'month_6',
            key: 'month_6',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_6){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_6})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '7月',
            dataIndex: 'month_7',
            key: 'month_7',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_7){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_7})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '8月',
            dataIndex: 'month_8',
            key: 'month_8',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_8){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_8})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '9月',
            dataIndex: 'month_9',
            key: 'month_9',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_9){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_9})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '10月',
            dataIndex: 'month_10',
            key: 'month_10',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_10){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_10})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '11月',
            dataIndex: 'month_11',
            key: 'month_11',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_11){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_11})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '12月',
            dataIndex: 'month_12',
            key: 'month_12',
            width: '4%',
            render: (text, record) => {
                if(text > record.total_12){
                    // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_12})</span>
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
                }else{
                    return <span>{text}</span>
                }
            },
        },
        {
            title: '年度平均',
            dataIndex: 'avg',
            key: 'avg',
            width: '4%',
            // sorter: (a, b) => a.sum - b.sum,
        },
    ];
// 导出文档
    const exportWord = ()=>{
        var _this = this;
        console.log('click the export word button : ------ ');
        // 点击导出word
        function replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        }

        function errorHandler(error) {
            console.log(JSON.stringify({error: error}, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors.map(function (error) {
                    return error.properties.explanation;
                }).join("\n");
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }

// image start ---
        var opts = {}
        opts.centered = false; //Set to true to always center images
        opts.fileType = "docx"; //Or pptx


// 导出echarts图片，格式转换
        const base64DataURLToArrayBuffer =(dataURL) => {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }

//Pass your image loader
// opts.getImage = function(tagValue, tagName) {
//     //tagValue is 'examples/image.png'
//     //tagName is 'image'
//     return fs.readFileSync(tagValue);
// }
        opts.getImage = function(chartId){
            return base64DataURLToArrayBuffer(chartId);
        }


//Pass the function that return image size
        opts.getSize = function(img, tagValue, tagName,widX,widY) {
            //img is the image returned by opts.getImage()
            //tagValue is 'examples/image.png'
            //tagName is 'image'
            //tip: you can use node module 'image-size' here
            return [widX?widX:150, widY?widY:150];
        }

        var imageModule = new ImageModule(opts);

// image end -----




//Load the docx file as a binary
//         var content = fs
//             .readFileSync('src/reportTemplates/wenzhoushi_projects.docx', 'binary');
        //Load the docx file as a binary
        // var content =fs
        //     .readFile(path.resolve(__dirname, 'wenzhoushi_projectsdocx'), 'binary');
        // var content = JSZipUtils.getBinaryContent("../../../../static/docTemplate/exportTemplate.docx",function(error, content) {
        //
        // })
        // var content = Fse.read(path.resolve(__dirname, 'tag-example.docx'), 'binary');
        JSZipUtils.getBinaryContent(wenzhoushiDoc,function(error, content) {
            // exportTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            // 抛出异常
            if (error) {
                throw error;
            }
            // 图片处理
            let opts = {}
            opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
            opts.fileType = "docx";
            opts.getImage = function(chartId){
                return base64DataURLToArrayBuffer(chartId);
            }
            opts.getSize = function(){
                return [200,200]
            }
            let imageModule = new ImageModule(opts);
            // 创建一个PizZip实例，内容为模板的内容
            let zip = new PizZip(content);
            // 创建并加载docxtemplater实例对象
            let doc = new Docxtemplater();
            // doc.attachModule(imageModule);
            doc.loadZip(zip);

            // 设置模板变量的值
            doc.setData({
                // table: _this.tableData,
                first_name: 'John',
                last_name: 'Doe',
                phone: '0652455478',
                description: 'New Website',
                // type_builds:_this.state.echart_type.getDataURL(), // 获取echarts图片
                // region_builds:_this.state.echart_region.getDataURL(), // 获取echarts图片
                // energy_map:_this.state.mychart.getDataURL() // 获取echarts图片
                table:tableData.toString(),


            });

            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                throw error;
            }
            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            let out = doc.getZip().generate({
                type: "blob",
                mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            // 将目标文件对象保存为目标类型的文件，并命名
            saveAs(out, "exportDocx.docx");
        });
    }

    const tableTop = {
        result: tableData?tableData.length:0,
        liData: [/*'区间',*/ '选择'/*, '搜索',*/],
        // from,
        // to,
        select,
        deviceType,
        columns,
        tableData,
        handleScaleChange: ({deviceType, from, to}) => {
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                deviceType,
                from,
                to
            }))
        },
    };

    return (
        <div className='reportRightTable'>
            {/*<TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}*/}
                      {/*tableData={tableData}/>*/}
            <Table
                columns={columns}
                dataSource={tableData}
                scroll={{y: 710}}
                pagination={false}
                // loading={loading}
                rowKey='order'
                className='reportRightTableTabAvg'/>
            <div>
                <button type="button" onClick={exportWord}>导出word文档</button>
            </div>

        </div>
    )
}


export default IntervalReport;