import React from 'react';

import './indexBottom1.scss';
import Api from 'api';
import Border2 from '../../ui/border2/border2';
import Border4 from '../../ui/border4/border4';

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

const assistdata = [];
const center = ['50%', '50%'],
    radius = ['40%', '65%'],
    selectedOffset = 10,
    gridNumber = 10;
for (let i = 0; i < 100; i += gridNumber) {
    assistdata.push({
        name: "1",
        value: 10,
        selected: true
    });
}
const charetsContainer = {
    name: "半透明格子",
    type: "pie",
    silent: true,
    radius: radius,
    center: center,
    startAngle: 270,
    selectedOffset: selectedOffset,
    avoidLabelOverlap: false,
    color: ["rgba(255,255,255,0.2)"],
    selectedMode: true,
    hoverAnimation: false,
    label: {
        show: false
    },
    labelLine: {
        show: false
    },
    data: assistdata
}

const charetsContext = ({ sum, showNub, color }) => {
    const iAdd = sum / gridNumber;
    let assistdata2 = [];
    for (let i = iAdd; i < showNub; i += iAdd) {
        assistdata2.push({
            name: "1",
            value: iAdd,
            selected: true,
            itemStyle: {
                show: false,
                normal: {
                    color: color
                }
            }
        });
    }
    if (showNub % iAdd !== 0) {
        assistdata2.push({
            name: "1",
            value: showNub % iAdd,
            selected: true,
            itemStyle: {
                show: false,
                normal: {
                    color: color
                }
            },
        });
    } else {
        assistdata2.push({
            name: "1",
            value: iAdd,
            selected: true,
            itemStyle: {
                show: false,
                normal: {
                    color: color
                }
            }
        });
    }
    assistdata2.push({
        name: "2",
        value: sum - showNub,
        selected: true,
        itemStyle: { color: 'transparent' }
    });
    return assistdata2
}

const colorSet = {
    color: '#468EFD'
}

const      getOption = (name, value,sum)=>{
    return             {

        series: [{
            name: "内部进度条",
            type: "gauge",
            // center: ['20%', '50%'],
            radius: '40%',
            startAngle: 200,
            endAngle: -20,
            splitNumber: 5,
            axisLine: {
                lineStyle: {
                    color: [
                        // [dataArr / (sum>0?sum:1), colorSet.color],
                        [
                            1, new echarts.graphic.LinearGradient(
                            0, 1, 1, 0, [
                                {
                                    offset: 0,
                                    color: 'rgba(19,226,103,1)',
                                },
                                {
                                    offset: 0.55,
                                    color: 'rgba(239,232,17,1)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(255,0,0,1)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(255,0,0,1)',
                                }
                            ]
                        )
                        ],
                        [1, "#111F42"]
                    ],
                    width: 5
                }
            },
            axisLabel: {
                show: false,
            },
            axisTick: {
                show: false,

            },
            splitLine: {
                show: false,
            },
            itemStyle: {
                show: true,
                color:'#1aacda'
            },
            detail: {
                formatter: function(value) {
                    if (value !== 0) {
                        var num = Math.round(value ) ;
                        return parseInt(num).toFixed(0)+"%";
                    } else {
                        return 0;
                    }
                },
                offsetCenter: [0, 30],
                textStyle: {
                    padding: [0, 0, 0, 0],
                    fontSize: 13,
                    fontWeight: '700',
                    color: colorSet.color
                }
            },
            title: { //标题
                show: true,
                offsetCenter: [0, 46], // x, y，单位px
                textStyle: {
                    color: "#459dfd",
                    fontSize: 12, //表盘上的标题文字大小
                    fontWeight: 400,
                    fontFamily: 'PingFangSC'
                }
            },
            data: [{
                name: name,
                value: (value/ (sum>0?sum:1))*100,
            }],
            pointer: {
                show: true,
                length: '75%',
                radius: '20%',
                width: 3, //指针粗细
                color:'#1b9dc6'
            },
            animationDuration: 4000,
        },
            {
                name: '外部刻度',
                type: 'gauge',
                //  center: ['20%', '50%'],
                radius: '50%',
                min: 0, //最小刻度
                max: 100, //最大刻度
                splitNumber: 5, //刻度数量
                startAngle: 200,
                endAngle: -20,
                axisLine: {
                    show: true,
                    lineStyle: {
                        width: 1,
                        color: [
                            [1, 'rgba(0,0,0,0)']
                        ]
                    }
                }, //仪表盘轴线
                axisLabel: {
                    show: true,
                    color: '#4d5bd1',
                    distance: -13,
                    fontSize:10,
                    formatter: function(v) {
                        switch (v + '') {
                            case '0':
                                return '0';
                            case '10':
                                return '10';
                            case '20':
                                return '20';
                            case '30':
                                return '30';
                            case '40':
                                return '40';
                            case '50':
                                return '50';
                            case '60':
                                return '60';
                            case '70':
                                return '70';
                            case '80':
                                return '80';
                            case '90':
                                return '90';
                            case '100':
                                return '100';
                        }
                    }
                }, //刻度标签。
                axisTick: {
                    show: true,
                    splitNumber: 7,
                    lineStyle: {
                        color: colorSet.color, //用颜色渐变函数不起作用
                        width: 1,
                    },
                    length: -8
                }, //刻度样式
                splitLine: {
                    show: true,
                    length: -10,
                    lineStyle: {
                        color: colorSet.color, //用颜色渐变函数不起作用
                    }
                }, //分隔线样式
                detail: {
                    show: false
                },
                pointer: {
                    show: false
                }
            },
        ]
    }
}

class IndexBottom1 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sum: 0,
            water: 0,
            electricity: 0,
            ac: 0,
            gas: 0,
            style: { height: '100%' },
            active: "day"
        };
        this.waterEcharts = null;
        this.eleEcharts = null;
        this.acEcharts = null;
        this.gasEcharts = null;
    }



    componentDidMount() {
        console.log('componentDidMount--- ');
        const { sum, water, electricity, ac, gas } = this.state;
        // console.log('get from state');
        const colorSet = {
            color: '#468EFD'
        };
        // 基于准备好的dom，初始化echarts实例
        this.waterEcharts = echarts.init(document.getElementById('waterEcharts'));
        this.eleEcharts = echarts.init(document.getElementById('eleEcharts'));
        this.acEcharts = echarts.init(document.getElementById('acEcharts'));
        this.gasEcharts = echarts.init(document.getElementById('gasEcharts'));
        // 绘制图表

        this.waterEcharts.setOption(
            {
                // backgroundColor: '#0E1327',
                // tooltip: {
                //     formatter: "{a} <br/>{b} : {c}%"
                // },

                series: [{
                    name: "内部进度条",
                    type: "gauge",
                    // center: ['20%', '50%'],
                    radius: '40%',
                    startAngle: 200,
                    endAngle: -20,
                    splitNumber: 5,
                    axisLine: {
                        lineStyle: {
                            color: [
                                // [dataArr / (sum>0?sum:1), colorSet.color],
                                [
                                    1, new echarts.graphic.LinearGradient(
                                    0, 1, 1, 0, [
                                        {
                                            offset: 0,
                                            color: 'rgba(19,226,103,1)',
                                        },
                                        {
                                            offset: 0.55,
                                            color: 'rgba(239,232,17,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        }
                                    ]
                                )
                                ],
                                [1, "#111F42"]
                            ],
                            width: 5
                        }
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,

                    },
                    splitLine: {
                        show: false,
                    },
                    itemStyle: {
                        show: true,
                        color:'#1aacda'
                    },
                    detail: {
                        formatter: function(value) {
                            if (value !== 0) {
                                var num = Math.round(value ) ;
                                return parseInt(num).toFixed(0)+"%";
                            } else {
                                return 0;
                            }
                        },
                        offsetCenter: [0, 30],
                        textStyle: {
                            padding: [0, 0, 0, 0],
                            fontSize: 13,
                            fontWeight: '700',
                            color: colorSet.color
                        }
                    },
                    title: { //标题
                        show: true,
                        offsetCenter: [0, 46], // x, y，单位px
                        textStyle: {
                            color: "#459dfd",
                            fontSize: 13, //表盘上的标题文字大小
                            fontWeight: 400,
                            fontFamily: 'PingFangSC'
                        }
                    },
                    data: [{
                        name: "水",
                        value: (water/ (sum>0?sum:1))*100,
                    }],
                    pointer: {
                        show: true,
                        length: '75%',
                        radius: '20%',
                        width: 3, //指针粗细
                        color:'#1b9dc6'
                    },
                    animationDuration: 4000,
                },
                    {
                        name: '外部刻度',
                        type: 'gauge',
                        //  center: ['20%', '50%'],
                        radius: '50%',
                        min: 0, //最小刻度
                        max: 100, //最大刻度
                        splitNumber: 5, //刻度数量
                        startAngle: 200,
                        endAngle: -20,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [1, 'rgba(0,0,0,0)']
                                ]
                            }
                        }, //仪表盘轴线
                        axisLabel: {
                            show: true,
                            color: '#4d5bd1',
                            distance: -13,
                            fontSize:10,
                            formatter: function(v) {
                                switch (v + '') {
                                    case '0':
                                        return '0';
                                    case '10':
                                        return '10';
                                    case '20':
                                        return '20';
                                    case '30':
                                        return '30';
                                    case '40':
                                        return '40';
                                    case '50':
                                        return '50';
                                    case '60':
                                        return '60';
                                    case '70':
                                        return '70';
                                    case '80':
                                        return '80';
                                    case '90':
                                        return '90';
                                    case '100':
                                        return '100';
                                }
                            }
                        }, //刻度标签。
                        axisTick: {
                            show: true,
                            splitNumber: 7,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                                width: 1,
                            },
                            length: -8
                        }, //刻度样式
                        splitLine: {
                            show: true,
                            length: -10,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                            }
                        }, //分隔线样式
                        detail: {
                            show: false
                        },
                        pointer: {
                            show: false
                        }
                    },
                ]
            }
        )
console.log('value:---');
console.log(electricity);
console.log(sum);

        this.eleEcharts.setOption(
            getOption('电',electricity,sum)
        )
        this.acEcharts.setOption(
            getOption('空调',ac,sum)
        )
        this.gasEcharts.setOption(
            getOption('燃气',gas,sum)
        )

        let a = 0;
        let isTrue = true;
        // setInterval(() => {
        //
        //     if (isTrue) {
        //         a += 0.05;
        //         if (a >= 0.9) {
        //             isTrue = false;
        //         }
        //     }
        //     if (!isTrue) {
        //         a -= 0.05;
        //         if (a <= 0) {
        //             isTrue = true;
        //         }
        //     }

            // this.waterEcharts.setOption(
            //     {
            //         series: [{
            //             itemStyle: {
            //                 shadowColor: `rgba(134, 204, 255, 0.4)`,
            //                 shadowBlur: 10
            //             },
            //         },
            //         ]
            //     }
            // )
            // this.eleEcharts.setOption(
            //     {
            //         series: [{
            //             itemStyle: {
            //                 shadowColor: `rgba(255, 173, 51, 0.4)`,
            //                 shadowBlur: 10
            //             },
            //         },
            //         ]
            //     }
            // )
            // this.acEcharts.setOption(
            //     {
            //         series: [{
            //             itemStyle: {
            //                 shadowColor: `rgba(135, 230, 255, 0.4)`,
            //                 shadowBlur: 10
            //             },
            //         },
            //         ]
            //     }
            // )
            // this.gasEcharts.setOption(
            //     {
            //         series: [{
            //             itemStyle: {
            //                 shadowColor: `rgba(248, 230, 26, 0.4)`,
            //                 shadowBlur: 10
            //             },
            //         },
            //         ]
            //     }
            // )
        // }, 1000)

        this.getData('/energyRegionNew/usageRatioDaily', 'day')

    }

    componentWillReceiveProps(nextProps) {
        console.log('componentWillReceiveProps ----- ');
        const { ac, electricity, gas, total, water } = nextProps;
        const colorSet = {
            color: '#468EFD'
        };
        // this.setState({
        //     waterEcharts: { series: [] },
        //     eleEcharts: { series: [] },
        //     acEcharts: { series: [] },
        //     gasEcharts: { series: [] },
        // })


        const sum = total;

        this.waterEcharts.setOption(
            {
                // backgroundColor: '#0E1327',
                // tooltip: {
                //     formatter: "{a} <br/>{b} : {c}%"
                // },

                series: [{
                    name: "内部进度条",
                    type: "gauge",
                    // center: ['20%', '50%'],
                    radius: '40%',

                    splitNumber: 10,
                    axisLine: {
                        lineStyle: {
                            color: [
                                // [dataArr / (sum>0?sum:1), colorSet.color],
                                [
                                    1, new echarts.graphic.LinearGradient(
                                    0, 1, 1, 0, [
                                        {
                                            offset: 0,
                                            color: 'rgba(19,226,103,1)',
                                        },
                                        {
                                            offset: 0.55,
                                            color: 'rgba(239,232,17,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        }
                                    ]
                                )
                                ],
                                [1, "#111F42"]
                            ],
                            width: 5
                        }
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,

                    },
                    splitLine: {
                        show: false,
                    },
                    itemStyle: {
                        show: true,
                        color:'#1aacda'
                    },
                    detail: {
                        formatter: function(value) {
                            if (value !== 0) {
                                var num = Math.round(value ) ;
                                return parseInt(num).toFixed(0)+"%";
                            } else {
                                return 0;
                            }
                        },
                        offsetCenter: [0, 30],
                        textStyle: {
                            padding: [0, 0, 0, 0],
                            fontSize: 13,
                            fontWeight: '700',
                            color: colorSet.color
                        }
                    },
                    title: { //标题
                        show: true,
                        offsetCenter: [0, 46], // x, y，单位px
                        textStyle: {
                            color: "#459dfd",
                            fontSize: 13, //表盘上的标题文字大小
                            fontWeight: 400,
                            fontFamily: 'PingFangSC'
                        }
                    },
                    data: [{
                        name: "水",
                        value: (water/ (sum>0?sum:1))*100,
                    }],
                    pointer: {
                        show: true,
                        length: '75%',
                        radius: '20%',
                        width: 3, //指针粗细
                        color:'#1b9dc6'
                    },
                    animationDuration: 4000,
                },
                    {
                        name: '外部刻度',
                        type: 'gauge',
                        //  center: ['20%', '50%'],
                        radius: '50%',
                        min: 0, //最小刻度
                        max: 100, //最大刻度
                        splitNumber: 5, //刻度数量
                        // startAngle: 225,
                        // endAngle: -45,
                        startAngle: 200,
                        endAngle: -20,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [1, 'rgba(0,0,0,0)']
                                ]
                            }
                        }, //仪表盘轴线
                        axisLabel: {
                            show: true,
                            color: '#4d5bd1',
                            distance: -13,
                            fontSize:10,
                            formatter: function(v) {
                                switch (v + '') {
                                    case '0':
                                        return '0';
                                    case '10':
                                        return '10';
                                    case '20':
                                        return '20';
                                    case '30':
                                        return '30';
                                    case '40':
                                        return '40';
                                    case '50':
                                        return '50';
                                    case '60':
                                        return '60';
                                    case '70':
                                        return '70';
                                    case '80':
                                        return '80';
                                    case '90':
                                        return '90';
                                    case '100':
                                        return '100';
                                }
                            }
                        }, //刻度标签。
                        axisTick: {
                            show: true,
                            splitNumber: 7,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                                width: 1,
                            },
                            length: -8
                        }, //刻度样式
                        splitLine: {
                            show: true,
                            length: -10,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                            }
                        }, //分隔线样式
                        detail: {
                            show: false
                        },
                        pointer: {
                            show: false
                        }
                    },
                ]
            }
        )
        console.log('value:---');
        console.log(electricity);
        console.log(sum);
        this.eleEcharts.setOption(
            {
                // backgroundColor: '#0E1327',
                // tooltip: {
                //     formatter: "{a} <br/>{b} : {c}%"
                // },

                series: [{
                    name: "内部进度条",
                    type: "gauge",
                    // center: ['20%', '50%'],
                    radius: '40%',

                    splitNumber: 10,
                    axisLine: {
                        lineStyle: {
                            color: [
                                // [dataArr / (sum>0?sum:1), colorSet.color],
                                [
                                    1, new echarts.graphic.LinearGradient(
                                    0, 1, 1, 0, [
                                        {
                                            offset: 0,
                                            color: 'rgba(19,226,103,1)',
                                        },
                                        {
                                            offset: 0.55,
                                            color: 'rgba(239,232,17,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        }
                                    ]
                                )
                                ],
                                [1, "#111F42"]
                            ],
                            width: 5
                        }
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,

                    },
                    splitLine: {
                        show: false,
                    },
                    itemStyle: {
                        show: true,
                        color:'#1aacda'
                    },
                    detail: {
                        formatter: function(value) {
                            if (value !== 0) {
                                var num = Math.round(value ) ;
                                return parseInt(num).toFixed(0)+"%";
                            } else {
                                return 0;
                            }
                        },
                        offsetCenter: [0, 30],
                        textStyle: {
                            padding: [0, 0, 0, 0],
                            fontSize: 13,
                            fontWeight: '700',
                            color: colorSet.color
                        }
                    },
                    title: { //标题
                        show: true,
                        offsetCenter: [0, 46], // x, y，单位px
                        textStyle: {
                            color: "#459dfd",
                            fontSize: 13, //表盘上的标题文字大小
                            fontWeight: 400,
                            fontFamily: 'PingFangSC'
                        }
                    },
                    data: [{
                        name: "电",
                        value: (electricity/ (sum>0?sum:1))*100,
                    }],
                    pointer: {
                        show: true,
                        length: '75%',
                        radius: '20%',
                        width: 3, //指针粗细
                        color:'#1b9dc6'
                    },
                    animationDuration: 4000,
                },
                    {
                        name: '外部刻度',
                        type: 'gauge',
                        //  center: ['20%', '50%'],
                        radius: '50%',
                        min: 0, //最小刻度
                        max: 100, //最大刻度
                        splitNumber: 5, //刻度数量
                        // startAngle: 225,
                        // endAngle: -45,
                        startAngle: 200,
                        endAngle: -20,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [1, 'rgba(0,0,0,0)']
                                ]
                            }
                        }, //仪表盘轴线
                        axisLabel: {
                            show: true,
                            color: '#4d5bd1',
                            distance: -13,
                            fontSize:10,
                            formatter: function(v) {
                                switch (v + '') {
                                    case '0':
                                        return '0';
                                    case '10':
                                        return '10';
                                    case '20':
                                        return '20';
                                    case '30':
                                        return '30';
                                    case '40':
                                        return '40';
                                    case '50':
                                        return '50';
                                    case '60':
                                        return '60';
                                    case '70':
                                        return '70';
                                    case '80':
                                        return '80';
                                    case '90':
                                        return '90';
                                    case '100':
                                        return '100';
                                }
                            }
                        }, //刻度标签。
                        axisTick: {
                            show: true,
                            splitNumber: 7,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                                width: 1,
                            },
                            length: -8
                        }, //刻度样式
                        splitLine: {
                            show: true,
                            length: -10,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                            }
                        }, //分隔线样式
                        detail: {
                            show: false
                        },
                        pointer: {
                            show: false
                        }
                    },
                ]
            }
        )
        this.acEcharts.setOption(
            {
                // backgroundColor: '#0E1327',
                // tooltip: {
                //     formatter: "{a} <br/>{b} : {c}%"
                // },

                series: [{
                    name: "内部进度条",
                    type: "gauge",
                    // center: ['20%', '50%'],
                    radius: '40%',

                    splitNumber: 10,
                    axisLine: {
                        lineStyle: {
                            color: [
                                // [dataArr / (sum>0?sum:1), colorSet.color],
                                [
                                    1, new echarts.graphic.LinearGradient(
                                    0, 1, 1, 0, [
                                        {
                                            offset: 0,
                                            color: 'rgba(19,226,103,1)',
                                        },
                                        {
                                            offset: 0.55,
                                            color: 'rgba(239,232,17,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        }
                                    ]
                                )
                                ],
                                [1, "#111F42"]
                            ],
                            width: 5
                        }
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,

                    },
                    splitLine: {
                        show: false,
                    },
                    itemStyle: {
                        show: true,
                        color:'#1aacda'
                    },
                    detail: {
                        formatter: function(value) {
                            if (value !== 0) {
                                var num = Math.round(value ) ;
                                return parseInt(num).toFixed(0)+"%";
                            } else {
                                return 0;
                            }
                        },
                        offsetCenter: [0, 30],
                        textStyle: {
                            padding: [0, 0, 0, 0],
                            fontSize: 13,
                            fontWeight: '700',
                            color: colorSet.color
                        }
                    },
                    title: { //标题
                        show: true,
                        offsetCenter: [0, 46], // x, y，单位px
                        textStyle: {
                            color: "#459dfd",
                            fontSize: 13, //表盘上的标题文字大小
                            fontWeight: 400,
                            fontFamily: 'PingFangSC'
                        }
                    },
                    data: [{
                        name: "空调",
                        value: ac,
                    }],
                    pointer: {
                        show: true,
                        length: '75%',
                        radius: '20%',
                        width: 3, //指针粗细
                        color:'#1b9dc6'
                    },
                    animationDuration: 4000,
                },
                    {
                        name: '外部刻度',
                        type: 'gauge',
                        //  center: ['20%', '50%'],
                        radius: '50%',
                        min: 0, //最小刻度
                        max: 100, //最大刻度
                        splitNumber: 5, //刻度数量
                        // startAngle: 225,
                        // endAngle: -45,
                        startAngle: 200,
                        endAngle: -20,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [1, 'rgba(0,0,0,0)']
                                ]
                            }
                        }, //仪表盘轴线
                        axisLabel: {
                            show: true,
                            color: '#4d5bd1',
                            distance: -13,
                            fontSize:10,
                            formatter: function(v) {
                                switch (v + '') {
                                    case '0':
                                        return '0';
                                    case '10':
                                        return '10';
                                    case '20':
                                        return '20';
                                    case '30':
                                        return '30';
                                    case '40':
                                        return '40';
                                    case '50':
                                        return '50';
                                    case '60':
                                        return '60';
                                    case '70':
                                        return '70';
                                    case '80':
                                        return '80';
                                    case '90':
                                        return '90';
                                    case '100':
                                        return '100';
                                }
                            }
                        }, //刻度标签。
                        axisTick: {
                            show: true,
                            splitNumber: 7,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                                width: 1,
                            },
                            length: -8
                        }, //刻度样式
                        splitLine: {
                            show: true,
                            length: -10,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                            }
                        }, //分隔线样式
                        detail: {
                            show: false
                        },
                        pointer: {
                            show: false
                        }
                    },
                ]
            }
        )

        this.gasEcharts.setOption(
            {
                // backgroundColor: '#0E1327',
                // tooltip: {
                //     formatter: "{a} <br/>{b} : {c}%"
                // },

                series: [{
                    name: "内部进度条",
                    type: "gauge",
                    // center: ['20%', '50%'],
                    radius: '40%',

                    splitNumber: 10,
                    axisLine: {
                        lineStyle: {
                            color: [
                                // [dataArr / (sum>0?sum:1), colorSet.color],
                                [
                                    1, new echarts.graphic.LinearGradient(
                                    0, 1, 1, 0, [
                                        {
                                            offset: 0,
                                            color: 'rgba(19,226,103,1)',
                                        },
                                        {
                                            offset: 0.55,
                                            color: 'rgba(239,232,17,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(255,0,0,1)',
                                        }
                                    ]
                                )
                                ],
                                [1, "#111F42"]
                            ],
                            width: 5
                        }
                    },
                    axisLabel: {
                        show: false,
                    },
                    axisTick: {
                        show: false,

                    },
                    splitLine: {
                        show: false,
                    },
                    itemStyle: {
                        show: true,
                        color:'#1aacda'
                    },
                    detail: {
                        formatter: function(value) {
                            if (value !== 0) {
                                var num = Math.round(value ) ;
                                return parseInt(num).toFixed(0)+"%";
                            } else {
                                return 0;
                            }
                        },
                        offsetCenter: [0, 30],
                        textStyle: {
                            padding: [0, 0, 0, 0],
                            fontSize: 13,
                            fontWeight: '700',
                            color: colorSet.color
                        }
                    },
                    title: { //标题
                        show: true,
                        offsetCenter: [0, 46], // x, y，单位px
                        textStyle: {
                            color: "#459dfd",
                            fontSize: 13, //表盘上的标题文字大小
                            fontWeight: 400,
                            fontFamily: 'PingFangSC'
                        }
                    },
                    data: [{
                        name: "燃气",
                        value: (gas/ (sum>0?sum:1))*100,
                    }],
                    pointer: {
                        show: true,
                        length: '75%',
                        radius: '20%',
                        width: 3, //指针粗细
                        color:'#1b9dc6'
                    },
                    animationDuration: 4000,
                },
                    {
                        name: '外部刻度',
                        type: 'gauge',
                        //  center: ['20%', '50%'],
                        radius: '50%',
                        min: 0, //最小刻度
                        max: 100, //最大刻度
                        splitNumber: 5, //刻度数量
                        // startAngle: 225,
                        // endAngle: -45,
                        startAngle: 200,
                        endAngle: -20,
                        axisLine: {
                            show: true,
                            lineStyle: {
                                width: 1,
                                color: [
                                    [1, 'rgba(0,0,0,0)']
                                ]
                            }
                        }, //仪表盘轴线
                        axisLabel: {
                            show: true,
                            color: '#4d5bd1',
                            distance: -13,
                            fontSize:10,
                            formatter: function(v) {
                                switch (v + '') {
                                    case '0':
                                        return '0';
                                    case '10':
                                        return '10';
                                    case '20':
                                        return '20';
                                    case '30':
                                        return '30';
                                    case '40':
                                        return '40';
                                    case '50':
                                        return '50';
                                    case '60':
                                        return '60';
                                    case '70':
                                        return '70';
                                    case '80':
                                        return '80';
                                    case '90':
                                        return '90';
                                    case '100':
                                        return '100';
                                }
                            }
                        }, //刻度标签。
                        axisTick: {
                            show: true,
                            splitNumber: 7,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                                width: 1,
                            },
                            length: -8
                        }, //刻度样式
                        splitLine: {
                            show: true,
                            length: -10,
                            lineStyle: {
                                color: colorSet.color, //用颜色渐变函数不起作用
                            }
                        }, //分隔线样式
                        detail: {
                            show: false
                        },
                        pointer: {
                            show: false
                        }
                    },
                ]
            }
        )

        this.setState({
            sum,
            water,
            electricity,
            ac,
            gas,

        })
    }
    async getData(api, active) {
        console.log('begin to usageratiodaily ---- ');
        // this.state.waterEcharts.series = [];
        // this.state.eleEcharts.series = [];
        // this.state.acEcharts.series = [];
        // this.state.gasEcharts.series = [];
        const data = await Api.postV3(api, {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });

        const sum = data.data.total || 0;
        const water = data.data.water || 0;
        const electricity = data.data.electricity || 0;
        const ac = data.data.ac || 0;
        const gas = data.data.gas || 0;
        const colorSet = {
            color: '#468EFD'
        };
        this.waterEcharts.setOption(
            getOption('水',water,sum)
        )
        this.eleEcharts.setOption(
            getOption('电',electricity,sum)
        )
        this.acEcharts.setOption(
            getOption('空调',ac,sum)
        )
        this.gasEcharts.setOption(
            getOption('燃气',water,sum)
        )

        this.setState({
            sum,
            water,
            electricity,
            ac,
            gas,
            active
        })
    }

    selected(params) {

        switch (params) {
            case 'day':
                this.getData('/energyRegionNew/usageRatioDaily', 'day')
                break;
            case 'month':
                this.getData('/energyRegionNew/usageRatioMonthly', 'month')
                break;
            case 'year':
                this.getData('/energyRegionNew/usageRatioAnnually', 'year')
                break;
            default:
                this.getData('/energyRegionNew/usageRatioDaily', 'day')
                break;

        }
        // console.log(this.state)
    }

    render() {
        const { sum,
            water,
            electricity,
            ac,
            gas, } = this.state;
        return (
            <div className='indexBottom1V3' >
                <div className='indexBottomHead' >
                    <Border4 className='Angle' sideLength={'5px'}>
                        <i className='round'></i>
                    </Border4>
                    <span style={{fontSize: '18px',
                        fontWeight: 'bold',left:'20px',top:'0px',position:'absolute',color: '#92d271', fontFamily: 'monospace'}}> 温州市分项能耗碳排放消费结构趋势| </span>

                </div>
                <div style={{position:'relative',width:'300px',height:'400px'}}>
                    <div style={{position:'absolute',width:'200px',height:'200px',top:'-5px'}}>
                        <div id='eleEcharts' style={{height: '100%'}}></div>
                        {/*<p>*/}
                            {/*电*/}
                            {/*<br />*/}
                            {/*{(electricity ? (electricity / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}
                    </div>
                    <div style={{position:'absolute',width:'200px',height:'200px',left:'230px',top:'-5px'}}>
                        <div id='gasEcharts' style={{height: '100%'}}></div>
                        {/*<p>*/}
                            {/*燃气*/}
                            {/*<br />*/}
                            {/*{(gas ? (gas / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}
                    </div>
                    <div style={{position:'absolute',width:'200px',height:'200px',top:'120px'}}>
                        <div id='waterEcharts' style={{height: '100%'}}></div>
                        {/*<p>*/}
                            {/*水*/}
                            {/*<br />*/}
                            {/*{(water ? (water / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}
                    </div>
                    <div style={{position:'absolute',width:'200px',height:'200px',left:'230px',top:'120px'}}>
                        <div id='acEcharts' style={{height: '100%'}}></div>
                        {/*<p>*/}
                            {/*空调*/}
                            {/*<br />*/}
                            {/*{(ac ? (ac / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}
                    </div>
                </div>
                {/*<ul>*/}
                    {/*<li>*/}
                        {/*<div id='eleEcharts' style={this.state.style}></div>*/}
                        {/*<p>*/}
                            {/*电*/}
                            {/*<br />*/}
                            {/*{(electricity ? (electricity / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<div id='gasEcharts' style={this.state.style}></div>*/}
                        {/*<p>*/}
                            {/*燃气*/}
                        {/*<br />*/}
                            {/*{(gas ? (gas / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}

                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<div id='waterEcharts' style={this.state.style}></div>*/}
                        {/*<p>*/}
                            {/*水*/}
                            {/*<br />*/}
                            {/*{(water ? (water / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}
                    {/*</li>*/}
                    {/*<li>*/}
                        {/*<div id='acEcharts' style={this.state.style}></div>*/}
                        {/*<p>*/}
                            {/*空调*/}
                        {/*<br />*/}
                            {/*{(ac ? (ac / sum) * 100 : 0).toFixed(2) + '%'}*/}
                        {/*</p>*/}
                    {/*</li>*/}
                {/*</ul>*/}
            </div >
        )
    }
}


export default IndexBottom1;