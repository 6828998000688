import React from 'react';
import * as r from 'ramda';
import { from } from 'rxjs'
import withStates from 'src/context/withStates';

import './equipment.scss';
import Api from 'api';

import EquipmentNavigator from './equipmentNavigator/equipmentNavigator';
import EquipmentTable from './equipmentTable/equipmentTable';
import moment from "moment";
import { flattenDevices, formatTree, idAsKey, ROOT_NODE } from "../../context/treeView";

const translate = name => {
  const deviceTypes = [
    {
      "id": "COLDWATERMETER",
      "name": "冷水表"
    },
    {
      "id": "HOTWATERMETER",
      "name": "热水表"
    },
    {
      "id": "HEATENERGYMETER",
      "name": "热能表"
    },
    {
      "id": "TEMPERATURECONTROL",
      "name": "温控器"
    },
    {
      "id": "TIMERMETER",
      "name": "时间采集器"
    },
    {
      "id": "PRESSUREMETER",
      "name": "压力表"
    },
    {
      "id": "ULTRACOLDWATERMETER",
      "name": "超声波水表"
    },
    {
      "id": "DDWATERMETER",
      "name": "直饮水"
    },
    {
      "id": "ENERGYMETER",
      "name": "能量表"
    },
    {
      "id": "TTYPETEMPCONTROL",
      "name": "T型温控器"
    },
    {
      "id": "OXYGENMETER",
      "name": "氧气表"
    },
    {
      "id": "MELECTRICITYMETER",
      "name": "多功能电表"
    },
    {
      "id": "TEMPHUMMETER",
      "name": "温湿度表"
    },
    {
      "id": "ZTYPETEMPCONTROL",
      "name": "Z型温控器"
    },
    {
      "id": "ELECTRICITYMETER",
      "name": "电表"
    },
    {
      "id": "NBCOLDWATERMETER",
      "name": "NB冷水表"
    },
    {
      "id": "PHOTOVOLTAIC",
      "name": "光伏"
    },
    {
      "id": "NBELECTRICITYMETER",
      "name": "NB电表"
    },
    {
      "id": "NBFEECONTROLMETER",
      "name": "NB费控表"
    },
    {
      "id": "INNERCONTROLMETER",
      "name": "内控电表"
    }
  ]
  return r.propOr(name)(name)(r.mergeAll(r.map(({ id, name }) => ({ [id]: name }))(deviceTypes)))
}

const chargeChannelOf = devicetype => {
  const channelMap = {
    COLDWATERMETER: '01',
    HOTWATERMETER: '03',
    MELECTRICITYMETER: '11'
  }
  return r.propOr('00')(devicetype)(channelMap)
}

const chargeTableData = devicetype => data => (pageindex, pagesize) => {

  const mapIndex = r.addIndex(r.map);

  const orderAlgorithm = (x) => {
    const index = (x + 1) + ((pageindex - 1) * pagesize)
    return index < 10 ? `0${index}` : index
  };
  const realdataFormat=(channelId,i)=>r.pathOr(0)(['channels',channelId,'realdata'])(i)
  const statusFormat=(channelId,i)=>r.pathOr(0)(['channels',channelId,'status'])(i)
  const lastupdateFormat=(channelId,i)=>r.pathOr(0)(['channels',channelId,'lastupdate'])(i)
  
  const channelMap = {
    COLDWATERMETER: devicetype === 'COLDWATERMETER' && mapIndex((i, k) => {
      const channelId = '01'
      return {
        key: k,
        order: orderAlgorithm(k),
        name: i.title,
        instrumentId: i.addrid,
        comi: i.comi,
        channel:realdataFormat(channelId,i),
        reading: realdataFormat(channelId,i),
        state: i.channels[channelId].status,
        time: moment(lastupdateFormat(channelId,i)).format('YYYY-MM-DD HH:mm:ss'),
        number: i.gatewayid,
        channelId: i.channels[channelId].id,
        allInformation: i
      }
    })(data),
      NBCOLDWATERMETER: devicetype === 'NBCOLDWATERMETER' && mapIndex((i, k) => {
          const channelId = '52'
          return {
              key: k,
              order: orderAlgorithm(k),
              name: i.title,
              instrumentId: i.addrid,
              comi: i.comi,
              channel:realdataFormat(channelId,i),
              reading: realdataFormat(channelId,i),
              state: i.channels[channelId].status,
              time: moment(lastupdateFormat(channelId,i)).format('YYYY-MM-DD HH:mm:ss'),
              number: i.gatewayid,
              channelId: i.channels[channelId].id,
              allInformation: i
          }
      })(data),
    MELECTRICITYMETER: devicetype === 'MELECTRICITYMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      instrumentId: i.addrid,
      comi: i.comi,
      positive:realdataFormat('11',i) ,
      voltageA: realdataFormat('15',i),
      voltageB: realdataFormat('16',i),
      voltageC: realdataFormat('17',i),
      currentA: realdataFormat('18',i),
      currentB: realdataFormat('19',i),
      currentC: realdataFormat('20',i),
      state: statusFormat('11',i),
      time: moment(lastupdateFormat('11',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    ELECTRICITYMETER: devicetype === 'ELECTRICITYMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      positive: realdataFormat('11',i),
      state: statusFormat('11',i),
      time: moment(lastupdateFormat('11',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),

    ULTRACOLDWATERMETER: devicetype === 'ULTRACOLDWATERMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      flow: realdataFormat('04',i),
      flowVelocity: realdataFormat('09',i),
      temperature: realdataFormat('05',i),
      state: statusFormat('04',i),
      time: moment(lastupdateFormat('04',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),

    HOTWATERMETER: devicetype === 'HOTWATERMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      channel: i.channels['03'].channel,
      reading: realdataFormat('03',i),
      state: statusFormat('03',i),
      channelId: i.channels['03'].id,
      time: moment(lastupdateFormat('03',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),

    HEATENERGYMETER: devicetype === 'HEATENERGYMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      flow: realdataFormat('04',i),
      temperature: realdataFormat('05',i),
      returnWaterTemperature: realdataFormat('06',i),
      totalCold: realdataFormat('07',i),
      totalHot: realdataFormat('08',i),
      state: statusFormat('04',i),
      time: moment(lastupdateFormat('04',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    OXYGENMETER: devicetype === 'OXYGENMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      oxygen: realdataFormat('46',i),
      time: moment(lastupdateFormat('46',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    TEMPHUMMETER: devicetype === 'TEMPHUMMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      environmentTemperature: realdataFormat('48',i),
      environmentHumidity: realdataFormat('49',i),
      time: moment(lastupdateFormat('48',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    ENERGYMETER: devicetype === 'ENERGYMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      flow: realdataFormat('04',i),
      flowVelocity: realdataFormat('09',i),
      temperature: realdataFormat('05',i),
      returnWaterTemperature: realdataFormat('06',i),
      totalCold: realdataFormat('07',i),
      totalHot: realdataFormat('08',i),
      time: moment(lastupdateFormat('04',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    TEMPERATURECONTROL: devicetype === 'TEMPERATURECONTROL' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      energyNumber: realdataFormat('33',i),
      panelSwitch: realdataFormat('47',i),
      // columnsValveSwitch,
      model: realdataFormat('39',i),
      // columnsGear,
      indoorTemperature: realdataFormat('40',i),
      setTemperature: realdataFormat('37',i),
      state: statusFormat('10',i),
      time: moment(lastupdateFormat('10',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    TIMERMETER: devicetype === 'TIMERMETER' && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      cold: realdataFormat('07',i),
      hot: realdataFormat('08',i),
      state: realdataFormat('10',i),
      model: realdataFormat('39',i),
      gear: i.status.windspeed,
      time: moment(lastupdateFormat('10',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    NBFEECONTROLMETER: devicetype === "NBFEECONTROLMETER" && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      positive: realdataFormat('11',i),
      state: statusFormat('11',i),
      time: moment(lastupdateFormat('11',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
    TTYPETEMPCONTROL: devicetype === "TTYPETEMPCONTROL" && mapIndex((i, k) => ({
      key: k,
      order: orderAlgorithm(k),
      name: i.title,
      comi: i.comi,
      instrumentId: i.addrid,
      energyNumber:r.pathOr(0)(['channels','33','realdata'])(i),
      model: realdataFormat('39',i),
      setTemperature: realdataFormat('37',i),
      state: statusFormat('10',i),
      time: moment(lastupdateFormat('10',i)).format('YYYY-MM-DD HH:mm:ss'),
      number: i.gatewayid,
      allInformation: i
    }))(data),
  }

  return r.propOr([])(devicetype)(channelMap)
}

const Equipment = () => (<div className="equipment">
  <EquipmentNavigator />
  <EquipmentTable />
</div>
)


const fullData = async (state) => {
  const { data: sourceData } = await Api.getV3(`/project/${Api.projectId}/nodes`)
  const nodes = idAsKey(sourceData);
  return {
    ...state,
    searchIsShow: true,
    loading: false,
    node: ROOT_NODE,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        children: formatTree(ROOT_NODE)(sourceData)
      },
    ],
    nodes,
  }
}

const loadDevicesByType = (devicetype, node, search) => async (pageindex, pagesize) => {

  const { data } = await Api.post('/business/monitor', {
    devicetype,
    project: Api.projectId,
    ext: { enableMask: 1 },
    usesocity: true,
    socitynode: node === ROOT_NODE ? 'ROOT' : r.last(r.split(',', node)),
    mode: "SENSOR",
    pageindex: pageindex,
    pagesize: pagesize,
    key: search
  });
  if (data.code === 90000005) {
    return {
      data: [],
      total: 0,
      pageCount: 0
    }
  }
  const channelId = chargeChannelOf(devicetype)
  const resultInProject = data.result[Api.projectId];

  return {
    data: chargeTableData(devicetype)(resultInProject.detail)(pageindex, pagesize),
    total: resultInProject.detail.length,
    pageCount: resultInProject.paging.count
  }
}


const devices = async ({ node, nodes, devicetype, currentPage, pageSize, search, ...rest }) => {
  const selectedNodes = node === ROOT_NODE ?
    r.values(nodes) :
    [r.pathOr({})(node.replace('0,', '').split(','))(nodes)];

  const devicesUnderThisNode = r.chain(flattenDevices)(selectedNodes);

  const nav = r.sortBy(r.prop('id'))(r.map(({ type }) => ({
    id: type,
    name: translate(type)
  }))(r.uniqBy(r.prop('type'))(devicesUnderThisNode)))

  const currentDeviceType = r.isEmpty(r.filter(r.whereEq({ id: devicetype }))(nav)) ?
    r.pathOr('')(['0', 'id'])(nav) : devicetype

  const devices = r.isNil(currentDeviceType) || r.isEmpty(devicesUnderThisNode) ?
    [] :
    await loadDevicesByType(currentDeviceType, node, search)(currentPage, pageSize)
  return {
    ...rest,
    loading: false,
    node,
    nodes,
    devices,
    nav,
    devicetype: currentDeviceType,
    currentPage,
    pageSize,
  }
}

const fetchAll = (state) => {
  const input = fullData(state).then(devices)
  return from(input)
}

export const loadDevices = (state) => {
  return from(devices(state))
}
const initState = {
  loading: true,
  node: ROOT_NODE,
  nav: [],
  tree: [],
  devices: {
    data:[],
    pageCount: 0,
    total: 0,
  },
  currentPage: 1,
  pageSize: 20,
  operationIsShow: false,

}

export const { context, Component } = withStates(
  Equipment,
  initState,
  fetchAll,
)

export default Component