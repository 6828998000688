import React from 'react';
import { Input, message, Tooltip } from 'antd';
import './tableEdit.scss';
import Api from 'api';

class TableEdit extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            text: this.props.text,
            id: this.props.id,
            isEdit: false
        };
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    inputShow = () => {
        this.setState({
            isEdit: true
        })
    }

    inputBlur = () => {
        const { allInformation: { id } } = this.props.record;
        this.setState({
            isEdit: false
        })
        Api.post("/sensor/update", {
            did: id,
            projectid: Api.projectId,
            title: this.state.text,
        }).then(
            () => message.success('修改成功'),
            () => message.error('修改失败')
        )
    }

    edit = (e) => {
        this.setState({ text: e.target.value });
    }

    render() {
        const text = this.state.text;
        const isEdit = this.state.isEdit;
        return (
            <div className='tableEdit' >
                {
                    isEdit ?
                        <Input placeholder={text} onBlur={this.inputBlur} value={this.state.text} onChange={this.edit} autoFocus='autoFocus' />
                        :
                        <Tooltip placement="top" title={text}>
                            <p>
                                <span>{text}</span><b className='tableHover' onClick={this.inputShow} >修改</b>
                            </p>
                        </Tooltip>
                }
            </div >
        )
    }
}


export default TableEdit;