import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as r from 'ramda';
import Api from "api";
import moment from 'moment'
import {Row, Col, Table} from 'antd'
import './mapTableRight.scss';
import { typesInCategory } from 'src/context/typeCategory'
import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';
import Util2  from 'src/util/util2';
//地区使用

const translateType = v => {
    const valueMap = {
        ELECTRICITYMETER: '电表',
        WATER: '水表',
        AC: '空调',
        COLDWATERMETER: '冷水',
        ENERGYMETER_COLD: '总冷量',
        ENERGYMETER_HOT: '总热量',
        HOTWATERMETER: '热水',
        MELECTRICITYMETER: '多功能电表',
        ULTRACOLDWATERMETER: '超声波水表',
    }
    return r.propOr(v)(v)(valueMap)
}
const translateFormat = r.map(r.pipe(r.prop('value'),
    x => isNaN(x) || r.isNil(x) ? NaN : Number(x).toFixed(2)))

const translateTimeline = v => {
    const valueMap = {
        daily: r.map(i => `0${i}`.slice(-2) + ':00')(r.range(0, moment().hours())),
        monthly: r.map(i => `0${i}`.slice(-2))(
            r.range(1, moment().date())),
        annually: r.map(i => `0${i}`.slice(-2))(r.range(1, 13)),
    }
    return r.propOr(v)(v)(valueMap)
}
const translateKgce = (type) => {
    return type === 'annually' ?
        moment().format('YYYY') :
        moment().format('YYYYMM')
}
const translateLastKgce = (type) => {
    const time = moment().subtract(1, 'year');
    return type === 'annually' ? time.format('YYYY') : time.format('YYYYMM')
}

const formatMonthlyKgce = r.compose(
    r.values,
    r.map(r.pipe(
        r.pluck('kgce'),
        ([w = 0, e = 0]) => Number(w) + Number(e))),
    r.groupBy(r.prop('date')),
    r.flatten,
    r.values)
const categoryMeasure = category => data => r.pipe(
    r.map(type => r.pipe(r.propOr([])(type), r.pluck('value'),
        r.map(x => Number(x)), r.sum)(data)),
    r.sum,
    x => x.toFixed(2))(typesInCategory(category))

const ratioOf = category => (current, last) => {
    const thisPhrase = categoryMeasure(category)(current)
    const lastPhrase = categoryMeasure(category)(last)
    return lastPhrase === 0 || r.isNil(lastPhrase) ?
        0 :
        (100 * (Number(thisPhrase) - Number(lastPhrase)) /
            Number(lastPhrase)).toFixed(2)
}


const echartsLabel = {
    normal: {
        show: false,
        position: 'top',
        distance: 10,
        align: 'center',
        verticalAlign: 'middle',
    },
}
const textStyle = {
    color: '#fff'
}

let reactEcharts = null;
let reactEcharts2 = null;
let index = 0;

const mapTableRight = (props) => {
    const [nodeUsage, setNodeUsage] = useState({})
    const [kgceData, setKgceData] = useState({})
    const [listData, setListData] = useState({})
    const [activeR, setactiveR] = useState('day')
    const [activeR2, setactiveR2] = useState({})
    const [sum, setSum] = useState(0)
    const [water, setWater] = useState(0)
    const [ele, setEle] = useState(0)
    const [areaDosage, setAreaDosage] = useState(0)
    const [perCapitaDosage, setPerCapitaDosage] = useState(0)
    const [areaElectricDosage, setAreaElectricDosage] = useState(0)
    const [perCapitaElectricDosage, setPerCapitaElectricDosage] = useState(0)
    const [areaWaterDosage, setAreaWaterDosage] = useState(0)
    const [perCapitaWaterDosage, setPerCapitaWaterDosage] = useState(0)
    const [regionData, setRegionData] = useState([])
    const [regionData2, setRegionData2] = useState([])
    const [tableLoading, setTableLoading] = useState([])
    const [dataSource, setDataSource] = useState([])
    const [dataSourceType, setDataSourceType] = useState([])



    const { mapTableIsShow, nodeid ,region,regionName,projectId,projectLocate,projectLocateType} = props;

    let a = 0;
    const gridNumber = 16;
    const waterColor = '#b0f0fe',
        eleColor = '#ffa348',
        sumColor = '#7accfc';
    //    let regionJson = {"市辖区":330301,"鹿城区":330302,"龙湾区":330303,"瓯海区":330304,"洞头区":330305,"永嘉县":330324,"平阳县":330326
    //       ,"苍南县":330327,"文成县":330328,"泰顺县":330329,"温州高新区(经开区)":330371,"瑞安市":330381,"乐清市":330382};
    //因为前期编号的关系，这里330300，归入到330302中
    //一个存放对应的projectid，一个存放累加的能耗
    const regionArr = {'330300':[],'330301':[],'330302':[],'330303':[],'330304':[],'330305':[],'330324':[],'330326':[],'330327':[],'330328':[],'330329':[],'330371':[],'330381':[],'330382':[],};
    const regionArrData = {'330300':[],'330301':[],'330302':[],'330303':[],'330304':[],'330305':[],'330324':[],'330326':[],'330327':[],'330328':[],'330329':[],'330371':[],'330381':[],'330382':[],};
    const regionJsonConverter = Util2.regionJsonConverter;
    //存储区域数据的json对象，数组的存储依次是，0：数量，1：面积，2：人数，3：能耗,暂时保留，已经有数组有这个数据了
    const regionTableData = {'330300':[0,0,0,0],'330301':[0,0,0,0],'330302':[0,0,0,0],'330303':[0,0,0,0],'330304':[0,0,0,0],'330305':[0,0,0,0],'330324':[0,0,0,0],'330326':[0,0,0,0],'330327':[0,0,0,0],'330328':[0,0,0,0],'330329':[0,0,0,0],'330371':[0,0,0,0],'330381':[0,0,0,0],'330382':[0,0,0,0],};

    //建筑类型分类·
    const projectTypeConverter = Util2.projectTypeTransfer;
    const projectTypeArr = [
        'SPORT',
        'CBD',
        'HOSPITAL',
        'HOTEL',
        'MARKET',
        'OFFICE',
        'TEACH',
        'OTHER',
    ]
    //用来存储对应的项目id
    const projectTypeList = {
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    }
    //用来存储对应的能耗之和
    const projectTypeData = {
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    }
    //0：数量，1：面积，2：人数，3：能耗,
    const typeTableData = {SPORT:[0,0,0,0],CBD:[0,0,0,0],HOSPITAL:[0,0,0,0],HOTEL:[0,0,0,0],MARKET:[0,0,0,0],OFFICE:[0,0,0,0],TEACH:[0,0,0,0],OTHER:[0,0,0,0]};

    //表格表头
    const columns = [

        {
        title: '区域名称',
        dataIndex: 'name',
        key: 'name',
        // render: (text, record) => (
        //     <TableEdit text={text}  record={record} />
        // ),
        width: 50,
    }, {
        title: '项目数量',
        dataIndex: 'projectNum',
        key: 'projectNum',
        width: 50,
    }, {
        title: '面积',
        dataIndex: 'area',
        key: 'area',
        width: 50,
    },{
        title: '人数',
        dataIndex: 'headCount',
        key: 'headCount',
        width: 30,
    },
        {
            title: '能耗',
            dataIndex: 'kgceEnergy',
            key: 'kgceEnergy',
            width: 100,
            sorter: (a, b) => a.kgceEnergy - b.kgceEnergy,
        }, ];
    const columnsType = [

        {
        title: '建筑类型',
        dataIndex: 'name',
        key: 'name',
        // render: (text, record) => (
        //     <TableEdit text={text}  record={record} />
        // ),
        width: 50,
    }, {
        title: '项目数量',
        dataIndex: 'projectNum',
        key: 'projectNum',
        width: 50,
    }, {
        title: '面积',
        dataIndex: 'area',
        key: 'area',
        width: 50,
    },{
        title: '人数',
        dataIndex: 'headCount',
        key: 'headCount',
        width: 30,
    },
        {
            title: '能耗',
            dataIndex: 'kgceEnergy',
            key: 'kgceEnergy',
            width: 100,
            sorter: (a, b) => a.kgceEnergy - b.kgceEnergy,
        }, ];

    useEffect(() => {
        // console.log('useEffectRight go to run ---------');
        //请求数据到表格里面
        const dataSource = [];
        const dataSourceType = [];
        // dataSource.push({
        //     key: 1,
        //     order: 'order',
        //     name: 'a',
        //     projectNum: 'a',
        //     area: 'a',
        //     headCount: 1,
        //     kgceEnergy: moment().format('YYYY-MM-DD HH:mm:ss'),
        //
        // })
        reactEcharts = echarts.init(document.getElementById('ReactEcharts'));
        reactEcharts2 = echarts.init(document.getElementById('ReactEcharts2'));

        // changeTime("day");
        // reactEcharts.setOption({
        //     series: [
        //         {
        //             type: 'pie',
        //             center: ['30%', '50%'],
        //             radius: ['30%', '40%'],
        //             clockwise: false,
        //             hoverRAnimation: false,
        //             silent: true,
        //             stillShowZeroSum: false,
        //             minAngle: 10,
        //             label: {
        //                 normal: {
        //                     show: false
        //                 }
        //             },
        //             labelLine: {
        //                 normal: {
        //                     show: false
        //                 }
        //             },
        //             data: charetsContext({ sum, showNub: water * 0.8, color: waterColor })
        //         },
        //         {
        //             type: 'pie',
        //             center: ['30%', '50%'],
        //             radius: ['45%', '55%'],//环的位置
        //             clockwise: false,
        //             hoverRAnimation: false,
        //             silent: true,
        //             stillShowZeroSum: false,
        //             minAngle: 10,
        //             label: {
        //                 normal: {
        //                     show: false,
        //                 }
        //             },
        //             labelLine: {
        //                 normal: {
        //                     show: false
        //                 }
        //             },
        //             data: charetsContext({ sum, showNub: ele * 0.8, color: eleColor })
        //         },
        //         {
        //             type: 'pie',
        //             center: ['30%', '50%'],
        //             radius: ['60%', '70%'],//环的位置
        //             clockwise: false,
        //             hoverRAnimation: false,
        //             silent: true,
        //             stillShowZeroSum: false,
        //             minAngle: 10,
        //             label: {
        //                 normal: {
        //                     show: false
        //                 }
        //             },
        //             labelLine: {
        //                 normal: {
        //                     show: false
        //                 }
        //             },
        //             itemStyle: {
        //                 shadowColor: `rgba(134, 204, 255, ${a})`,
        //                 shadowBlur: 10,
        //             },
        //             data: charetsContext({ sum, showNub: sum * 0.85, color: sumColor })
        //         },
        //     ]
        // })

        setDataSource(dataSource);
        setDataSourceType(dataSourceType);
        setTableLoading(false);

    }, [nodeid,region])

    const charetsContext = ({ sum, showNub, color }) => {
        const iSum = sum > 0 ? sum : 100;
        const iShowNub = showNub > 0 ? showNub : 0
        const iAdd = iSum / gridNumber;
        const showTab = isNaN(iShowNub) || iSum === 0 && iShowNub === 0 ? 80 : iShowNub
        let assistdata2 = [];
        for (let i = 0; i < showTab - iAdd; i += iAdd) {
            assistdata2.push({
                name: "1",
                value: iAdd,
                selected: true,
                itemStyle: {
                    show: false,
                    normal: {
                        color: color
                    }
                }
            });
        }
        if (showTab % iAdd !== 0) {
            assistdata2.push({
                name: "1",
                value: showTab % iAdd,
                selected: true,
                itemStyle: {
                    show: false,
                    normal: {
                        color: color
                    }
                },
            });
        }
        assistdata2.push({
            name: "2",
            value: iSum - showTab,
            selected: true,
            itemStyle: { color: 'transparent' }
        });
        return assistdata2
    }

    const usagePeriodRegion = (node,region,projectId, usagePeriod) => {
        // console.log(node,projectId, usagePeriod);
        if(typeof node === 'undefined' || node==null || typeof region === 'undefined' || region==null|| region=='' || typeof projectId === 'undefined' || projectId==null|| projectId==''|| typeof usagePeriod === 'undefined' || usagePeriod==null ){
            return;
        }
        // console.log('to req the usageregion ------');
        Api.get(
            `/api/v3/energy/project/${projectId}/region/${region}/nodeUsageRegion/${node}/${usagePeriod}`).then(({ data: { current: today, last: yesterday } }) => {
                setNodeUsage({
                    color: ['#86CCFF', '#77e6fd', '#FFAD33', '#FBE641'],
                    textStyle,
                    grid: { left: '0', top: '25', right: '30', bottom: '22', containLabel: true },
                    legend: {
                        data: r.map(([k, x]) => ({
                            name: translateType(k),
                        }))(r.toPairs(today)),
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    xAxis: {
                        type: 'category',
                        data: translateTimeline(usagePeriod),
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: r.map(([k, x]) => ({
                        name: translateType(k),
                        type: 'bar',
                        data: translateFormat(x || []),
                        label: echartsLabel
                        // itemStyle: { barBorderRadius: 5 },
                        // barWidth: 10,
                    }))(r.toPairs(today)),
                })
                setListData({
                    todayElectricity: categoryMeasure('ELECTRICITYMETER')(today),
                    todayElectricityRatio: ratioOf('ELECTRICITYMETER')(today, yesterday),
                    todayWater: categoryMeasure('WATER')(today),
                    todayWaterRatio: ratioOf('WATER')(today, yesterday),
                })

            })
    }
    const kgcePeriodRegion = (node,region,projectId, kgcePeriod) => {
        // console.log(node,projectId, usagePeriod);
        if(typeof node === 'undefined' || node==null || typeof region === 'undefined' || region==null|| region=='' || typeof projectId === 'undefined' || projectId==null|| projectId==''|| typeof kgcePeriod === 'undefined' || kgcePeriod==null ){
            return;
        }

        // console.log('to req the kgceregion ------');
        Api.get(
            `/api/v3/energy/project/${projectId}/region/${region}/nodeKgceRegion/${node}/${kgcePeriod}`).then(
                ({ data: { current: thisYear, last: lastYear } }) => {
                    const seriesTime = r.map(({ date }) => date.toString().slice(-2))(
                        thisYear['ELECTRICITYMETER'] || thisYear['WATER'] || [])
                    const monthlyKgce = formatMonthlyKgce(thisYear);
                    const lastYearMonthlyKgce = formatMonthlyKgce(lastYear);

                    const seriesData = r.zip(r.range(0, 12))(r.map(v => v.toFixed(2))(monthlyKgce))
                    const seriesDataLast = r.zip(r.range(0, 12))(r.map(v => v.toFixed(2))(lastYearMonthlyKgce))



                    setKgceData({
                        color: ['#86CCFF', '#FFAD33'],
                        textStyle,
                        grid: { left: '0', top: '25', right: '30', bottom: '22', containLabel: true },
                        legend: {
                            data: [translateKgce(kgcePeriod), translateLastKgce(kgcePeriod)],
                            align: 'left',
                            textStyle: {
                                color: '#fff'
                            }
                        },
                        tooltip: {
                            trigger: 'axis',
                        },
                        xAxis: {
                            type: 'category',
                            data: seriesTime,
                        },
                        yAxis: {
                            type: 'value',
                        },
                        series: [
                            {
                                name: translateKgce(kgcePeriod),
                                data: seriesData,
                                type: 'bar',
                                label: echartsLabel
                            }, {
                                name: translateLastKgce(kgcePeriod),
                                data: seriesDataLast,
                                type: 'bar',
                                label: echartsLabel
                            }],
                    })
                    // setMonthListData(
                    //     {
                    //         monthWaterTotal: categoryMeasure('WATER')(thisYear),
                    //         monthWaterRatio: ratioOf('WATER')(thisYear, lastYear),
                    //         monthElectricityTotal: categoryMeasure('ELECTRICITYMETER')(thisYear),
                    //         monthElectricityRatio: ratioOf('ELECTRICITYMETER')(thisYear, lastYear),
                    //         monthlyRatio: ((r.last(monthlyKgce) || 0 - r.last(lastYearMonthlyKgce) ||
                    //             0) / (r.last(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
                    //         annuallyRatio: ((r.sum(monthlyKgce) - r.sum(lastYearMonthlyKgce)) /
                    //             (r.sum(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
                    //         kgceMonth: (r.last(monthlyKgce) || 0).toFixed(2),
                    //         kgceAnnual: r.sum(monthlyKgce).toFixed(2),
                    //     }
                    // )

                })
    }
    const formatNub = (nub) => {
        if (10000 > nub && nub >= 1000) {
            return (nub / 1000).toFixed(2) + '千'
        }
        if (1000000 > nub && nub >= 10000) {
            return (nub / 10000).toFixed(2) + '万'
        }
        if (10000000 > nub && nub >= 1000000) {
            return (nub / 1000000).toFixed(2) + '百万'
        }
        if (nub >= 10000000) {
            return (nub / 10000000).toFixed(2) + '千万'
        }
        return nub.toFixed(2)

    }
    const changeTime = (type) =>{
            switch (type) {
                case 'day':
                    getData('/energyNew/usageRatioDaily');
                    setactiveR(type);
                    break;
                case 'month':
                    getData('/energyNew/usageRatioMonthly');
                    setactiveR(type);
                    break;
                case 'year':
                    getData('/energyNew/usageRatioAnnually');
                    setactiveR(type);
                    break;
                default:
                    getData('/energyNew/usageRatioDaily');
                    setactiveR(type);
                    break;

            }

    }


    //随机颜色

    const rgb=()=>{//rgb颜色随机
        let r = Math.floor(Math.random()*256);
        let g = Math.floor(Math.random()*256);
        let b = Math.floor(Math.random()*256);
        let rgb = '('+r+','+g+','+b+')';
        return rgb;
    }
    const color16 = () => {//十六进制颜色随机
        let r = Math.floor(Math.random()*256);
        let g = Math.floor(Math.random()*256);
        let b = Math.floor(Math.random()*256);
        let color = '#'+r.toString(16)+g.toString(16)+b.toString(16);
        return color;
    }




    const getData = async (api)=> {
        console.log('getData to req----');
        index =0;
        dataSourceType.splice(0);
        dataSource.splice(0);
        const projectLocatePrjIdArr=[];//保存区域的projectid
        let projectLocatTypeData = projectLocateType.data;
        let listData = projectLocatTypeData[1];
        let projectHeadAreaData = projectLocatTypeData[0];

        const { total, ...rest } = await Api.postV3(api, {
            project: Api.projectId
        }).then(r.prop('data'));


        if(!total){
            return;
        }

        //区域类型数据-start
        //因为返回的数据是多个项目的数据，然后这里重新整理成一个，方便后续的数据计算
        // const {ele2,water2} = [r.sum(r.map(y=>r.props('electricity')(y)))(rest),11];
        //统计projectid到regionArr中，同时统计人数和面积到regionTableData中
        r.map(y=>
            {
                //因为admin后台添加项目乱加，project_locate这里也加进来，
                //但是projectTotal这里会找有building记录的数据，然后没有找到，就会导致projectHeadAreaData[y[5]]为空
                if(!projectHeadAreaData[y[5]]){
                    console.error('no project find: ' + y[5]);
                    return;
                }
                projectLocatePrjIdArr.push(y[5]);//保存projectid到这里
               if(y[7]=='330300'){
                   regionArr['330302'].push(y[5]);
                   regionTableData['330302'][2]+=(projectHeadAreaData[y[5]][1])?parseInt(projectHeadAreaData[y[5]][1]):0;//y[7]:330333 类型 y[5] projectid 类型 ，head 数量存储起来
                   regionTableData['330302'][1]+=(projectHeadAreaData[y[5]][2])?parseInt(projectHeadAreaData[y[5]][2]):0;//y[7]:330333 类型 y[5] projectid 类型 ，head 数量存储起来
               }else{
                   regionArr[y[7]].push(y[5]);
                   regionTableData[y[7]][2]+=(projectHeadAreaData[y[5]][1])?parseInt(projectHeadAreaData[y[5]][1]):0;//y[7]:330333 类型 y[5] projectid 类型 ，head 数量存储起来
                   regionTableData[y[7]][1]+=(projectHeadAreaData[y[5]][2])?parseInt(projectHeadAreaData[y[5]][2]):0;//y[7]:330333 类型 y[5] projectid 类型 ，head 数量存储起来
               }

            }
        )(projectLocate);


        console.log('regionArr: ' +regionArr);
        const ele = r.sum(r.values(r.map(y=>y['electricity'])(rest)));
        const water = r.sum(r.values(r.map(y=>y['water'])(rest)));
        //处理数据到arr，画成单个饼图而不是内外嵌套圆形图
        const regionData = [];
        const regionData2 = [];
        const colorArr = ['#a7a7ea',
            '#d9ac5d',
            '#7cb56e',
            '#bcb076',
            '#7a5829',
            '#7a1155',
            '#7a7155',
            '#507a6e',
            '#55b36e'];


         // 这个写法暂时留在这里，后续需要的再用起来
        // (r.map(y=>regionData.push({
        //     value: y['electricity'],
        //     name: "用电" ,
        //     itemStyle: {
        //         normal: {
        //             color: (index > (colorArr.length-1))?(colorArr[index=0]):(colorArr[index++])
        //         }
        //     }
        // }))(rest));
        //建筑数量统计，地域能耗统计
        for (let i in regionArr) {
            if(i=='330300'){
                regionTableData['330302'][0]+=regionArr[i].length;
            }else{
                regionTableData[i][0]+=regionArr[i].length;
            }
            //这里regionArr已经把330300 转移到了330302中，这里就不用再判断330300，
            regionArrData[i].push(r.sum(r.values(r.map(x=>{
                if(rest[x]){
                    return (rest[x])['electricity'];
                }else{
                    return 0;
                }
            })(regionArr[i]))));
        }

        for (let i in regionArrData) {
            let value  = regionArrData[i].length>0?(regionArrData[i])[0]:0;
            if(value == 0){
                continue;
            }
            regionData.push({
                value: value,
                name:  regionJsonConverter[i],
                itemStyle: {
                    normal: {
                        color: (index > (colorArr.length-1))?(colorArr[index=0]):(colorArr[index++])
                    }
                }
            })

        }
        //区域类型数据-end
        // 建筑类型数据 start ---------------------
        // 把projectid保存进projectTypeList中
        //同时把type的面积和人数也统计一下,这里
        // 增加判断，只有在projectLocate 中的项目可以被统计进来
        for(let i =0;i<listData.length;i++){
            for(let key in listData[i]){// key 样式 HOSPITAL 之类
                //y 样式 {'a':1}
                r.map(y=> {

                    for(let key2 in y){// key2:是projectid
                        if(projectLocatePrjIdArr.indexOf(key2)==-1){
                            continue;
                        }else{
                            console.log('in:' + key2);
                        }
                        if(y[key2]!=0){
                            projectTypeList[key].push(key2);
                            typeTableData[key][2]+=(projectHeadAreaData[key2][1])?parseInt(projectHeadAreaData[key2][1]):0;//y[7]:330333 类型 y[5] projectid 类型 ，head 数量存储起来
                            typeTableData[key][1]+=(projectHeadAreaData[key2][2])?parseInt(projectHeadAreaData[key2][2]):0;//y[7]:330333 类型 y[5] projectid 类型 ，head 数量存储起来
                        }

                    }
                })((listData[i][key])[1]);

            }

        }
        //将projectTypeList格式转换： {'a':[]}
        // 判断项目是否在projectlocate中

        for( let key in projectTypeList){ // key : HOSPITAL 之类的
            typeTableData[key][0]+=projectTypeList[key].length;
            projectTypeData[key].push(r.sum(r.values(r.map(x=>(rest[x])?(rest[x])['electricity']:0)(projectTypeList[key]))));
        }

        for (let i in projectTypeData) {
            let value  = projectTypeData[i].length>0?(projectTypeData[i])[0]:0;
            if(value == 0){
                continue;
            }
            regionData2.push({
                value: value,
                name:  projectTypeConverter[i],
                itemStyle: {
                    normal: {
                        color: (index > (colorArr.length-1))?(colorArr[index=0]):(colorArr[index++])
                    }
                }
            })

        }

        // 建筑类型数据 end ------------------------
        // console.log(regionData);

        const sum = total.toFixed(2);

        const { headCount: people, totalArea: area } = await Api.postV3('/info/statistics', {
            project: Api.projectId
        }).then(r.prop('data'))

        const areaDosage = sum && area ? formatNub(sum / area) : 0;
        const perCapitaDosage = sum && people ? formatNub(sum / people) : 0;

        const areaElectricDosage = ele && area ? formatNub(ele / area) : 0;
        const perCapitaElectricDosage = ele && people ? formatNub(ele / people) : 0;

        const areaWaterDosage = water && area ? formatNub(water / area) : 0;
        const perCapitaWaterDosage = water && people ? formatNub(water / people) : 0;

        reactEcharts.setOption({
            tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b}: {c} ({d}%)'

                formatter:function(params)//数据格式
                {
                    // console.log(params);
                    var relVal = params.name+':'+params.value.toFixed(2) + "<br/>";
                    return relVal;
                }

            },
            series: [
                {
                    type: 'pie',
                    // center: ['30%', '50%'],
                    radius: ['30%', '80%'],
                    clockwise: false,
                    hoverRAnimation: false,
                    // silent: true,
                    stillShowZeroSum: false,
                    minAngle: 10,
                    label: {
                        normal: {
                            show: true
                        }
                    },
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    data: regionData
                }
            ]
        })
        reactEcharts2.setOption({
            tooltip: {
                trigger: 'item',
                // formatter: '{a} <br/>{b}: {c} ({d}%)'
                formatter:function(params)//数据格式
                {
                    // console.log(params);
                    var relVal = params.name+':'+params.value.toFixed(2) + "<br/>";
                    return relVal;
                }
            },
            series: [
                {
                    type: 'pie',
                    // center: ['30%', '50%'],
                    radius: ['30%', '80%'],
                    clockwise: false,
                    hoverRAnimation: false,
                    // silent: true,
                    stillShowZeroSum: false,
                    minAngle: 10,
                    label: {
                        normal: {
                            show: true
                        }
                    },
                    labelLine: {
                        normal: {
                            show: true
                        }
                    },
                    data: regionData2,
                    animation: false
                }
            ]
        })
        //获取echarts的数据并进行展示
        var picInfo = reactEcharts.getDataURL();
        console.log('picInfo ------ ');
        console.log(picInfo);
        var picInfo2 = reactEcharts2.getDataURL();
        console.log('picInfo2 ------ ');
        console.log(picInfo2);

        //增加对区域和建筑类型的表格数据请求
        for(let key in regionTableData){
            dataSource.push({
                // key: 1,
                // order: 'order',
                name: regionJsonConverter[key],
                projectNum: regionTableData[key][0],
                area: regionTableData[key][1],
                headCount: regionTableData[key][2],
                kgceEnergy: parseFloat(regionArrData[key]).toFixed(2),

            })

        }
        for(let key in typeTableData){
            dataSourceType.push({
                // key: 1,
                // order: 'order',
                name: projectTypeConverter[key],
                projectNum: typeTableData[key][0],
                area: typeTableData[key][1],
                headCount: typeTableData[key][2],
                kgceEnergy: parseFloat(projectTypeData[key]).toFixed(2),

            })

        }


        // ---------- end --------------------
        setAreaDosage(areaDosage);
        setPerCapitaDosage(perCapitaDosage);
        setAreaElectricDosage(areaElectricDosage);
        setPerCapitaElectricDosage(perCapitaElectricDosage);
        setAreaWaterDosage(areaWaterDosage);
        setPerCapitaElectricDosage(perCapitaWaterDosage);
        setSum(sum);
        setWater(water);
        setEle(ele);
        setRegionData(regionData);
        setRegionData2(regionData2);
        setDataSource(dataSource);
    }


    const { todayElectricity,
        todayElectricityRatio,
        todayWater,
        todayWaterRatio } = listData

    const abnormalNub = (x) => {
        return x === 'NaN' || x === 'Infinity' ? 0.00 : x;
    }

    //显示时重新请求
    if(mapTableIsShow){
        console.log('to show ');


    }else{
        console.log('not to show');
    }
    const getOption = {
        title: {
            text: '总能耗',
            textStyle: {
                fontSize: 14,
                fontFamily: 'PingFang-SC-Medium',
                fontWeight: 500,
                color: '#5b6b73',
                lineHeight: 20
            },
            subtext: 0,
            subtextStyle: {
                fontSize: 26,
                fontFamily: 'PingFang-SC-Bold',
                fontWeight: 'bold',
                color: '#5b6b73',
                lineHeight: 37,
            },
            right: 30,
            top: 40
        },
        legend: {
            orient: 'vertical',
            x: 'right',
            y: 100,
            icon: "circle",
            align: "left",
            formatter: '{name}',
            textStyle: { color: '#5b6b73', }
        },
        series: {
            type: 'pie',
            center: ['25%', '50%'],
            radius: ['80%', '50%'],
            clockwise: false,
            hoverRAnimation: false,
            stillShowZeroSum: false,
            label: {
                normal: {
                    show: false,
                }
            },
            itemStyle: {
                normal: {
                    borderWidth: 2,
                    borderColor: '#fff',
                }
            },
            data: [
                {
                    value: 1,
                    name: "照明插座：0 %",
                    itemStyle: {
                        normal: {
                            color: '#87E6FF'
                        }
                    }
                },
                {
                    value: 1,
                    name: "空调用电： 0 %",
                    itemStyle: {
                        normal: {
                            color: '#86CCFF'
                        }
                    }
                },
                {
                    value: 1,
                    name: "动力用电：0 %",
                    itemStyle: {
                        normal: {
                            color: '#F8E61A'
                        }
                    }
                },
                {
                    value: 1,
                    name: "特殊用电：0 %",
                    itemStyle: {
                        normal: {
                            color: '#FFAD33'
                        }
                    }
                }
            ]
        }
    }
    const getOption2 = {
        title: {
            text: '总能耗',
            textStyle: {
                fontSize: 14,
                fontFamily: 'PingFang-SC-Medium',
                fontWeight: 500,
                color: '#5b6b73',
                lineHeight: 20
            },
            subtext: 0,
            subtextStyle: {
                fontSize: 26,
                fontFamily: 'PingFang-SC-Bold',
                fontWeight: 'bold',
                color: '#5b6b73',
                lineHeight: 37,
            },
            right: 30,
            top: 40
        },
        legend: {
            orient: 'vertical',
            x: 'right',
            y: 100,
            icon: "circle",
            align: "left",
            formatter: '{name}',
            textStyle: { color: '#5b6b73', }
        },
        series: {
            type: 'pie',
            center: ['25%', '50%'],
            radius: ['80%', '50%'],
            clockwise: false,
            hoverRAnimation: false,
            stillShowZeroSum: false,
            label: {
                normal: {
                    show: false,
                }
            },
            itemStyle: {
                normal: {
                    borderWidth: 2,
                    borderColor: '#fff',
                }
            },
            data: [
                {
                    value: 1,
                    name: "照明插座：0 %",
                    itemStyle: {
                        normal: {
                            color: '#87E6FF'
                        }
                    }
                },
                {
                    value: 1,
                    name: "空调用电： 0 %",
                    itemStyle: {
                        normal: {
                            color: '#86CCFF'
                        }
                    }
                },
                {
                    value: 1,
                    name: "动力用电：0 %",
                    itemStyle: {
                        normal: {
                            color: '#F8E61A'
                        }
                    }
                },
                {
                    value: 1,
                    name: "特殊用电：0 %",
                    itemStyle: {
                        normal: {
                            color: '#FFAD33'
                        }
                    }
                }
            ]
        }
    }
    const dataFormatting = (v) => {
        const nub = Number(v)
        return (nub ? nub : 0).toFixed(2)
    }

    //-----------表格相关工具
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        getCheckboxProps: record => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };



    // -------- end -------------



    return (

        <ul className={`chartsContainerRightV2 ${mapTableIsShow ? 'chartsOpenRight' : 'chartsCloseRight'}`}>
            <li>

                    <div>
                        <i className={activeR === "day" ? "activeR hoverR" : "hoverR"}  onClick={()=>changeTime("day")}>日 </i>
                        <i className={activeR === "month" ? "activeR hoverR" : "hoverR"}  onClick={()=>changeTime("month")}>月 </i>
                        <i className={activeR === "year" ? "activeR hoverR" : "hoverR"}  onClick={()=>changeTime("year")}>年 </i>
                    </div>
                    <br/>
                    <i className="icon" style={{ backgroundColor: eleColor }}></i><span>总电能耗: {dataFormatting(ele)} kecg</span>

                <Table
                    // rowSelection={rowSelection}
                    columns={columns}
                    dataSource={dataSource}
                    scroll={{ y: 180 }}
                    pagination={false}
                    // loading={tableLoading}
                    // rowKey='Elecricity'
                    className='mapTableRightType'
                />
            </li>
            <li >

                {/*<ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} style={{ width: "100%", height: "240px" }} />*/}

                {/*<div className="legend">*/}
                {/*    <p></p>*/}
                {/*    <ul>*/}
                {/*        /!*<li><i className="icon" style={{ backgroundColor: waterColor }}></i><span>水能耗: {dataFormatting(water)} kecg</span></li>*!/*/}
                {/*        /!*<li><i className="icon" style={{ backgroundColor: eleColor }}></i><span>总电能耗: {dataFormatting(ele)} kecg</span></li>*!/*/}
                {/*        /!*<li><i className="icon" style={{ backgroundColor: sumColor }}></i><span>总能耗: {dataFormatting(sum)} kecg</span></li>*!/*/}
                {/*    </ul>*/}
                {/*</div>*/}
                {/*<div className='indexBottom2Bottom'>*/}
                {/*    <Row gutter={8} >*/}
                {/*        /!*<Col span={8}>面积能耗：{areaDosage}kecg</Col>*!/*/}
                {/*        /!*<Col span={8}>面积水耗：{areaWaterDosage}kecg</Col>*!/*/}
                {/*        /!*<Col span={8}>面积电耗：{areaElectricDosage}kecg</Col>*!/*/}
                {/*    </Row>*/}
                {/*    <Row gutter={8} >*/}
                {/*        /!*<Col span={8}>人均能耗：{perCapitaDosage}kecg</Col>*!/*/}
                {/*        /!*<Col span={8}>人均水耗：{perCapitaWaterDosage}kecg</Col>*!/*/}
                {/*        /!*<Col span={8}>人均电耗：{perCapitaElectricDosage}kecg</Col>*!/*/}
                {/*    </Row>*/}
                {/*</div>*/}
                <Table
                    // rowSelection={rowSelection}
                    columns={columnsType}
                    dataSource={dataSourceType}
                    scroll={{ y: 180 }}
                    pagination={false}
                    // loading={tableLoading}
                    // rowKey='Elecricity'
                    className='mapTableRightType'
                />
            </li>
            <li>
                <div className='echartsDiv' id='ReactEcharts' style={{ width: "40%", height: "240px" }}></div>
                <div className='echartsDiv' id='ReactEcharts2' style={{ width: "40%", height: "240px" }}></div>
            </li>

        </ul >

    )
}

export default mapTableRight;
