import {Redirect} from "react-router-dom";
import React, {useContext} from "react";
import * as r from "ramda";
import {Spin} from "antd";
import {context, firstTopRoute, loadProjects} from "src/router";
import cookie from "react-cookies";

// const sleep = time => {
//     return new Promise(resolve => setTimeout(resolve,time))
// }

function sleep(ms) {
    var start = new Date().getTime()
    while (new Date().getTime() < start + ms);
}

const Home = () => {
  const {state: {thisProject}, dispatch} = useContext(context)
    dispatch(loadProjects);

  //对于刚开始建立的，似乎这里会导致不断请求。价格时间限制吧。

  if (r.isNil(thisProject)) {
    sleep(2000);
    dispatch(loadProjects);

    return <Spin />
  }
  //切换账户的时候，thisprojectid 和 currentproject的不一致，导致modules有问题，增加判断
    if(thisProject && (thisProject._id == cookie.load('projectId'))){
      console.log('equal');
    }else{
      console.log('not equal');
        dispatch(loadProjects)
        return <Spin />
    }


  return <Redirect to={firstTopRoute(thisProject.modules)} />
}

export default Home