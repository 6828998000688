import * as r from "ramda";
import cookie from "react-cookies";
import Api from 'api';
import React from "react";
import moment from "moment";
import {Redirect, Route} from "react-router-dom";
import Util2  from 'src/util/util2';;
export const setPermission = permission =>  window.sessionStorage.setItem('permission', permission)
export const setRegionPermission = permission =>  window.sessionStorage.setItem('permission-region', permission)
export const setRegionProjects = data =>  window.sessionStorage.setItem('regionProjects', data)
export const setRegionProjects2 = data =>  window.sessionStorage.setItem('regionProjects2', data)
export const setRegion = data =>  window.sessionStorage.setItem('region', data)
export const getRegionProjects = () =>  window.sessionStorage.getItem('regionProjects')
export const getStorageByName = (name) =>  window.sessionStorage.getItem(name)
export const getRegionPermission = permission =>  window.sessionStorage.getItem('permission-region')
export const setProjectName = name =>  window.sessionStorage.setItem('projectName', name)
export const setUid = uid =>  window.sessionStorage.setItem('uid', uid)
//isRoot 增加一个是否是区域账号的判断
export const isRoot = () => (window.sessionStorage.getItem('permission') === 'ROOT')&&(!window.sessionStorage.getItem('permission-region') === 'REGION');
export const isRegion = () => window.sessionStorage.getItem('permission-region');

export const setCurrentProject = (id, title) => {
  const domain = window.location.hostname.replace(/^\w+\./, '')

    cookie.save('projectId', id, {path: '/', domain})
  cookie.save('projectName', title, {path: '/', domain})
    cookie.load('regionProjects')
    let prj = r.filter(y=>y.projectId === id)(Api.regionProjects);
  // console.log('setCurrentProject ------');
  //   console.log(moment(prj.addtime).format('YYYY-MM-DD'));
    cookie.save('projectCreateTime', (prj&&prj.length>0)?moment(prj[0].addtime).format('YYYY-MM-DD'):'', {path: '/', domain})
    Api.projectCreateTime=(prj&&prj.length>0)?moment(prj[0].addtime).format('YYYY-MM-DD'):'';
}

const regionJsonConverter = Util2.regionJsonConverter;

export const currentProject = projects => r.find(p => p._id === Api.projectId)(projects)

// 这里修改成可以获取区域列表的地方
// export const moduleNavigation = async () => {
//   return !Api.projectId ? [] : isRoot() ?
//     Api.get('/api/v3/project')
//       .then(r.propOr([], 'data'))
//     : Api.get('/api/v3/project/' + Api.projectId)
//       .then(r.prop('data')).then(x => [x])
// }

export const moduleNavigation = async () => {
    const [
        projects,
        regionProjects,
    ]=await Promise.all([Api.projectId ?
        Api.get('/api/v3/projectRegion')
              .then(r.propOr([], 'data')).then(data => {
            // super admin can access multiple projects
            setProjectName(cookie.load('uid'))
            return data
        }):[],
        Api.projectId ?
            Api.get('/api/v3/accountRegion/'+ cookie.load('uid'))
                .then(r.propOr([], 'data')).then(data => {
                // super admin can access multiple projects
                if(data&&data.length > 0){
                    //但是这的参数，在sensorchannel info 里面却无法获取到参数
                    let regData = r.map(y=>({building:y.building,projectId:y.projectId,projectName:y.projectName,isAnalyzeForRegion:y.isAnalyzeForRegion}))(data);
                    //20211214 排除一些不进行统计的
                    data = r.filter(y=>y.isAnalyzeForRegion!=='NO') (data);
                    regData = r.filter(y=>y.isAnalyzeForRegion!=='NO') (regData);

                    console.log('new accountRegion --- : ');
                    console.log(cookie.load('uid'));
                    console.log(data);
                    console.log('new accountRegion2 --- : ');
                    console.log('new accountRegion3 --- : ');
                    Api.region = cookie.load('uid')==='wenzhoushi'?'330300':data[0].region;
                    Api.regionProjects = data;
                    Api.regionProjects2 = regData;
                    setRegionPermission(Api.projectId);
                    setRegionProjects(data);//保存该区域下的项目列表
                    setRegionProjects2(JSON.stringify(regData));//保存该区域下的项目列表
                    setRegion((cookie.load('uid')==='wenzhoushi' || cookie.load('uid')==='jianzheng')?'330300':data[0].region);
                    cookie.save('isRegion', true);

                    cookie.save('regionProjects', data);

                    cookie.save('region', (cookie.load('uid')==='wenzhoushi' || cookie.load('uid')==='jianzheng')?'330300':data[0].region);//存储用户属于的region
                    if(cookie.load('uid')==='wenzhoushi'){
                        // data.push({id:1000,projectId:cookie.load('regionProjectId'),projectName:regionJsonConverter[cookie.load('uid')=='wenzhoushi'?'330300':data[0].region]});
                        data=[{id:1000,projectId:cookie.load('regionProjectId'),projectName:regionJsonConverter[cookie.load('uid')=='wenzhoushi'?'330300':data[0].region]}].concat(data)
                    }
                }

                return data
            }) : []
    ])
    return [
        projects,
        regionProjects,
    ];
}
//CarbonNeutral RegisterAdmin先去掉，安全监测出来说泄露敏感信息
const NoLoginArr = ['/Register','/RegisterPV'];


export function PrivateRoute({component: Component, ...rest}) {

    console.log('rest');
    console.log(rest);
    console.log('check ----- ');
    console.log(cookie.load('isLogin') === 'sbfui20' && cookie.load('projectId') && cookie.load('projectName'));
  //添加判断，如果是path里面包含了NoLogin字段，则直接跳过，进去该页面，但是一些初始化的数据都没有  {path: "/NoLoginRegisterInfo"}
    if(rest && rest.path && NoLoginArr.indexOf(rest.path)!== -1){
        console.log('find the value ----'+rest.path);
        return (
            <Route
                {...rest}
                render={props =>
                    (
                        <Component {...props} />
                    )
                }
            />
        );
    }


  return (
    <Route
      {...rest}
      render={props =>
        cookie.load('isLogin') === 'sbfui20' && cookie.load('projectId') && cookie.load('projectName') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/Login",
              state: {from: props.location}
            }}
          />
        )
      }
    />
  );
}
