import React from 'react';
import Top from './buildingRightTop/buildingRightTop';
import Bottom from './buildingRightBottom/buildingRightBottom';

const BuildingRight = () => (
  <div className='buildingRight'>
    <Top/>
    <Bottom/>
  </div>
)

export default BuildingRight