import React, {useContext} from 'react'
import moment from 'moment'
import TreeView from 'src/components/treeView/tree';

import DaylightUsage from './daylightUsage';
import NightUsage from './nightUsage';

import Api from 'api';
import * as r from 'ramda';
import {Spin} from 'antd';
import {from, of} from 'rxjs';
import withStates from 'src/context/withStates';
import './dayAndNight.scss';

const ROOT_NODE = '0'
const DAYLIGHT_RANGE = r.range(6, 19);
const NIGHT_RANGE = r.concat(r.range(18, 24), r.range(0, 7));
const axisFormat = r.map(t => `0${t}`.slice(-2) + ':00')
const dayTimeSeries = axisFormat(DAYLIGHT_RANGE)
const nightTimeSeries = axisFormat(NIGHT_RANGE)

const initState = {
  loading: true,
  daylightUsage: {
    timeSeries: dayTimeSeries,
    data: [],
  },
  nightUsage: {
    timeSeries: nightTimeSeries,
    data: [],
  }, tree: [],
  node: ROOT_NODE,
  usagePeriod: 'daily',
}

const treeViewData = async () => {
  const {data: {result = []}} = await Api.post('/customer/info', {
    project: Api.projectId,
  });
  const toChildren = r.compose(
    r.map(({id: key, title, key: did, child}) =>
      ({
        title, key, did,
        children: r.isNil(child) ? [] : toChildren(child),
      })),
    r.values)
  return {
    searchIsShow: true,
    loading: false,
    node: ROOT_NODE,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        children: toChildren(
          r.filter(
            r.where({
              type: r.equals('NODE'),
              parent: r.equals(0),
            }),
          )(result)),
      },
    ],
  }
}


const nodeUsage = async ({node, usagePeriod = 'daily'}) => {
  const {data: {current}} = await Api.get(
    `/api/v3/project/${Api.projectId}/hourlyUsage/${node}/${usagePeriod}`)
  return {
    daylightUsage: {
      timeSeries: dayTimeSeries,
      data: r.map(x => r.prop(x)(current))(DAYLIGHT_RANGE),
    },
    nightUsage: {
      timeSeries: nightTimeSeries,
      data: r.map(x => r.prop(x)(current))(NIGHT_RANGE),
    },
    loading: false,
  }
}

const onSelect = (node) => (event) => {
  console.log(`node ${r.head(event)} selected`)

  return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
    (state) => loadNodeUsage({...state, node: r.head(event)})
}

const DayAndNight = () => {
  const {state, dispatch} = useContext(context)
  const {tree = [], node, loading} = state;

  return (
    <div className='dayAndNight'>
      <div className="dayAndNightLeft">
        <div className="equipmentLeftTit">
          建筑结构
        </div>
        {
          r.isEmpty(tree) ?
            <Spin/> :
            <TreeView data={tree}
                      defaultExpandedKeys={[node]}
                      onSelect={e => {
                        dispatch(state => of(r.assoc('loading', true)(state)))
                        dispatch(onSelect(state.node)(e))
                      }}/>}
      </div>
      <div className="dayAndNightRight">
        <Spin spinning={loading} size='large'>
          <DaylightUsage/>
          <NightUsage/>
        </Spin>
      </div>
    </div>
  )
}

const loadAll = (state) => {
  const input = Promise.all(
    [treeViewData(state), nodeUsage(state)])
    .then(r.mergeAll)
  return from(input)
}

export const loadNodeUsage = (state) => {
  return from(nodeUsage(state)
    .then(res => ({...state, ...res})))
}

export const {context, Component} = withStates(
  DayAndNight,
  initState,
  loadAll,
)

export default Component
