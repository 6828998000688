import React from 'react';
import { Table, Tooltip } from 'antd';
import * as r from 'ramda';
import moment from 'moment';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableBottom from 'src/components/table/tableBottom/tableBottom';

import './dayReport.scss';
import Api from 'api';

class DayReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [],
            total: 0,
            tableLoading: false
        };
    }


    componentDidMount() {
        this.getData()
    }

    async getData() {
        this.setState({ tableLoading: true })
        const dayReport = await Api.post('/business/dailyreport', {
            project: [{ id: Api.projectId }],
            time: moment().format('YYYYMMDD')
        })
        const dayReportData = r.pathOr([], ['data', 'result', Api.projectId], dayReport);

        let data = [];
        dayReportData.forEach((i, k) => {
            let obj = {
                key: i.id + i,
                order: k + 1,
                name: i.name,
                instrumentId: i.channeldid.substr(-20, 12),
                eqType: i.devicetype,
                passageName: i.channelname,
                energy: i.dailysum,
                energyType: i.energy,
            }

            let newObj = [];
            const printKeyConcatValue = (value, key) => {
                let hourKey = moment(parseFloat(key)).hour() + 'hour';
                newObj.push(r.assoc(hourKey, value, {}), obj)
            }
            r.mapObjIndexed(printKeyConcatValue, i.usage);
            data.push(r.mergeAll(newObj))
        })

        this.setState({
            data: data,
            total: dayReportData.length,
            tableLoading: false
        })

    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            width: '70px',
            fixed: 'left',
        }, {
            title: '设备名称',
            dataIndex: 'name',
            width: '150px',
            fixed: 'left',
            render: (text, record) => (
                <Tooltip placement="top" title={text}>
                    <span className='tableName'>{text}</span>
                </Tooltip>
            ),
        }, {
            title: '设备ID',
            dataIndex: 'instrumentId',
            width: '160px',
        }, {
            title: '设备类型',
            dataIndex: 'eqType',
            width: '120px',
        }, {
            title: '通道名称',
            dataIndex: 'passageName',
            width: '120px',
        }, {
            title: '日能耗',
            dataIndex: 'energy',
            width: '100px',
        }, {
            title: '能耗类型',
            dataIndex: 'energyType',
            width: '130px',
        }];


        for (let i = 0; i < 24; i++) {
            columns.push(
                {
                    title: i + '时',
                    dataIndex: i + 'hour',
                    width: '90px',
                }
            )
        }
        const tableTop = {
            result: this.state.total,
            liData: ['时间', '选择', '刻度',]
        }
        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} />
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    scroll={{ x: 3000, y: 710 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    className='reportRightTableTab' />
                <div style={{ float: 'right', marginRight: '20px', color: '#1890ff' }}>
                    总消耗:1028202
               </div>
            </div >
        )
    }
}


export default DayReport;