import React, { useContext } from 'react';
import * as r from 'ramda';
import { Spin } from 'antd';
import TreeView from 'components/treeView/tree';
// import 'src/modules/energy/analysis/analysis_left/analysisNavigator.scss';
import { context, loadDevices } from '../equipment'
import { of } from 'rxjs'

const onSelect = (node) => (event) => {
  console.log(`node ${r.head(event)} selected`)

  return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
    (state) => loadDevices({ ...state, loading: true, node: r.head(event), devicetype: undefined })
}

const EquipmentNavigator = () => {
  const { state, dispatch } = useContext(context)
  return (
    <div className='equipmentLeft'>
      <div className="equipmentLeftTit">
        建筑结构
    </div>
      {
        r.isEmpty(state.tree) ?
          <Spin /> :
          <TreeView data={state.tree}
            defaultExpandedKeys={[state.node]}
            onSelect={e => {
              dispatch(state => of(r.assoc('loading', true)(state)))
              dispatch(onSelect(state.node)(e))
            }} />}
    </div>
  )
}

export default EquipmentNavigator;
