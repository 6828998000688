import React, { Component } from 'react';

import './indexBottom3.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import * as r from "ramda";

class IndexBottom3 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sum: 0,
            lightrcptclPCT: 0,
            airelePCT: 0,
            powerelePCT: 0,
            specialelePCT: 0,
            active: "day"
        };
    }

    async getData(api, active) {
        this.state = { getOption: { series: [] }, ...this.state };
        // const data = await Api.get(api);
        const data = await Api.postV3(api, {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });

        const sum = data.data.total ? data.data.total.toFixed(2) : 0.00;
        const lightrcptcl = data.data.lightrcptcl ? data.data.lightrcptcl.toFixed(2) : 0.00;
        const powerele = data.data.powerele ? data.data.powerele.toFixed(2) : 0.00;
        const airele = data.data.airele ? data.data.airele.toFixed(2) : 0.00;
        const specialele = data.data.specialele ? data.data.specialele.toFixed(2) : 0.00;

        const lightrcptclPCT = lightrcptcl ? ((lightrcptcl / sum) * 100).toFixed(2) : 0.00;
        const powerelePCT = powerele ? ((powerele / sum) * 100).toFixed(2) : 0.00;
        const airelePCT = airele ? ((airele / sum) * 100).toFixed(2) : 0.00;
        const specialelePCT = specialele ? ((specialele / sum) * 100).toFixed(2) : 0.00;


        this.setState({
            sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT,
            active: active
        })
    }


    componentDidMount() {
        // this.getData(`/api/v3/project/${Api.projectId}/subentryNew/ratioDaily/regionProjects/${(r.map(x => x.projectId)(Api.regionProjects).join('-')).toString()}`, 'day')
        // this.getData(`/project/${Api.projectId}/subentryNew/ratioDaily/regionProjects/`, 'day')
    }

    selected(params) {

        switch (params) {
            case 'day':
                // this.getData(`/api/v3/project/${Api.projectId}/subentryNew/ratioDaily/regionProjects/${(r.map(x => x.projectId)(Api.regionProjects).join('-')).toString()}`, 'day')
                this.getData(`/project/${Api.projectId}/subentryNew/ratioDaily/regionProjects/`, 'day')
                break;
            case 'month':
                // this.getData(`/api/v3/project/${Api.projectId}/subentryNew/ratioMonthly/regionProjects/${(r.map(x => x.projectId)(Api.regionProjects).join('-')).toString()}`, 'month')
                this.getData(`/project/${Api.projectId}/subentryNew/ratioMonthly/regionProjects/`, 'month')
                break;
            case 'year':
                // this.getData(`/api/v3/project/${Api.projectId}/subentryNew/ratioAnnually/regionProjects/${(r.map(x => x.projectId)(Api.regionProjects).join('-')).toString()}`, 'year')
                this.getData(`/project/${Api.projectId}/subentryNew/ratioAnnually/regionProjects/`, 'year')
                break;
            default:
                // this.getData(`/api/v3/project/${Api.projectId}/subentryNew/ratioDaily/regionProjects/${(r.map(x => x.projectId)(Api.regionProjects).join('-')).toString()}`, 'day')
                this.getData(`/project/${Api.projectId}/subentryNew/ratioDaily/regionProjects/`, 'day')
                break;

        }
        // console.log(this.state)
    }


    render() {
        const { sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT } = this.state;
        // const getOption = {
        //     title: {
        //         text: '\t\t\t\t\t总能耗',
        //         textStyle: {
        //             fontSize: 14,
        //             fontFamily: 'PingFang-SC-Medium',
        //             fontWeight: 500,
        //             // color: '#5b6b73',
        //             // color: '#b1b6c7',
        //             color: '#459dfd',
        //             lineHeight: 20
        //         },
        //         subtext: sum,
        //         subtextStyle: {
        //             fontSize: 26,
        //             fontFamily: 'PingFang-SC-Bold',
        //             fontWeight: 'bold',
        //             // color: '#5b6b73',
        //             color: '#01e8e9',
        //             lineHeight: 37,
        //         },
        //         // left: 70,
        //         // top: 90
        //             left: '22%',
        //             top: '40%'
        //     },
        //     legend: {
        //         // orient: 'vertical',
        //         orient: 'horizontal',
        //         x: '65%',
        //         y: '30%',
        //         icon: "circle",
        //         align: "right",
        //         formatter: '{name}',
        //         // textStyle: { color: '#5b6b73',  },
        //         // textStyle: { color: '#b1b6c7',  },
        //         textStyle: { color: '#459dfd',  },
        //     },
        //     series: {
        //         type: 'pie',
        //         center: ['32%', '50%'],
        //         radius: ['80%', '73%'],
        //         clockwise: false,
        //         hoverAnimation: false,
        //         stillShowZeroSum: false,
        //         // label: {
        //         //     normal: {
        //         //         show: false,
        //         //     }
        //         // },
        //         label: {
        //             // color:'#121212',
        //             normal: {
        //                 // color:function (params) {
        //                 //     console.log('color');
        //                 //     console.log(params);
        //                 //     return '#121212'
        //                 // },
        //                 // color:function (params) {
        //                 //     console.log('color');
        //                 //     var colorList = ['#97e7ff', '#75f5ff', '#00deff', '#0093ff', '#2a5fcf']
        //                 //     return '#97e7ff'
        //                 // },
        //                 // color:'#02b9fa',
        //                 show: true,
        //                 formatter: function (params) {
        //                     return params.name.substr(0,2)+'\r\n'+params.value+ '%';
        //                 },
        //             },
        //         },
        //         labelLine: {
        //             // lineStyle: {
        //             //     color: 'rgba(255, 0, 255, 0.3)'
        //             // },
        //             smooth: 0.2,
        //             length: 15,
        //             length2: 15
        //         },
        //         itemStyle:{
        //             normal: {
        //                borderWidth: 2,
        //                borderColor: '#0a1255',
        //                 // shadowBlur: 20,
        //                 // label:{
        //                 //    color:function (params) {
        //                 //        console.log('color');
        //                 //        console.log(params);
        //                 //        return '#121212'
        //                 //    }
        //                 // }
        //              },
        //        },
        //         data: [
        //             {
        //                 value: lightrcptclPCT,
        //                 name: "照明插座：" + lightrcptclPCT + " %",
        //                 itemStyle: {
        //                     normal: {
        //                         // color: '#F8E61A',
        //                         color: '#ff7436',
        //                         borderColor: '#ff7436',
        //                         borderWidth: 5,
        //                         shadowBlur: 30,
        //                         // shadowColor: '#ffaa78',
        //                         shadowColor: '#ff7436',
        //                         // shadowBlur: 20,
        //                     }
        //                 }
        //             },
        //             {
        //                 value: 2,
        //                 name: '',
        //                 itemStyle: {
        //                     normal: {
        //                         label: {
        //                             show: false
        //                         },
        //                         labelLine: {
        //                             show: false
        //                         },
        //                         color: 'rgba(0, 0, 0, 0)',
        //                         borderColor: 'rgba(0, 0, 0, 0)',
        //                         borderWidth: 0
        //                     }
        //                 }
        //             },
        //             {
        //                 value: airelePCT,
        //                 name: "空调用电：" + airelePCT + " %",
        //                 itemStyle: {
        //                     normal: {
        //                         color: '#c863ad',
        //                         // color: '#FFAD33',
        //                         borderColor: '#c863ad',
        //                         borderWidth: 5,
        //                         shadowBlur: 30,
        //                         shadowColor: '#c863ad'
        //                     }
        //                 }
        //             },
        //             {
        //                 value: 2,
        //                 name: '',
        //                 itemStyle: {
        //                     normal: {
        //                         label: {
        //                             show: false
        //                         },
        //                         labelLine: {
        //                             show: false
        //                         },
        //                         color: 'rgba(0, 0, 0, 0)',
        //                         borderColor: 'rgba(0, 0, 0, 0)',
        //                         borderWidth: 0
        //                     }
        //                 }
        //             },
        //             {
        //                 value: powerelePCT,
        //                 name: "动力用电：" + powerelePCT + " %",
        //                 itemStyle: {
        //                     normal: {
        //                         // color: '#87E6FF',
        //                         color: '#03b9fa',
        //                         borderColor: '#03b9fa',
        //                         borderWidth: 5,
        //                         shadowBlur: 30,
        //                         shadowColor: '#03b9fa'
        //                     }
        //                 }
        //             },
        //             {
        //                 value: 2,
        //                 name: '',
        //                 itemStyle: {
        //                     normal: {
        //                         label: {
        //                             show: false
        //                         },
        //                         labelLine: {
        //                             show: false
        //                         },
        //                         color: 'rgba(0, 0, 0, 0)',
        //                         borderColor: 'rgba(0, 0, 0, 0)',
        //                         borderWidth: 0
        //                     }
        //                 }
        //             },
        //             {
        //                 value: specialelePCT,
        //                 name: "特殊用电：" + specialelePCT + " %",
        //                 itemStyle: {
        //                     normal: {
        //                         // color: '#86CCFF',
        //                         color: '#2962e7',
        //                         borderColor: '#2962e7',
        //                         borderWidth: 5,
        //                         shadowBlur: 30,
        //                         shadowColor: '#2962e7'
        //                     }
        //                 }
        //             },
        //             {
        //                 value: 2,
        //                 name: '',
        //                 itemStyle: {
        //                     normal: {
        //                         label: {
        //                             show: false
        //                         },
        //                         labelLine: {
        //                             show: false
        //                         },
        //                         color: 'rgba(0, 0, 0, 0)',
        //                         borderColor: 'rgba(0, 0, 0, 0)',
        //                         borderWidth: 0
        //                     }
        //                 }
        //             },
        //         ]
        //     }
        // }
        const getOption  = {
            title: {
                // text: '折线图堆叠'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['煤品燃料', '天然气', '电力', '热力', '其他'],
                textStyle: {
                    color: '#578a3f',
                },
                color:'#578a3f',
                bottom:'0px'
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '20%',
                containLabel: true
            },
            // toolbox: {
            //     feature: {
            //         saveAsImage: {}
            //     }
            // },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
                axisLabel:{
                    textStyle:{
                        color:'#697a31'}
                },
            },
            yAxis: {
                type: 'value',
                axisLabel:{
                    textStyle:{
                        color:'#777a55'}
                },
            },
            series: [
                {
                    name: '煤品燃料',
                    type: 'line',
                    symbol: 'circle',     //设定为实心点
                    symbolSize: 15,   //设定实心点的大小
                    stack: '总量',
                    data: [120, 132, 101, 134, 90, 230, 210],
                    color:'#b1b0ff',
                },
                {
                    name: '天然气',
                    type: 'line',
                    symbol: 'circle',     //设定为实心点
                    symbolSize: 15,   //设定实心点的大小
                    stack: '总量',
                    data: [220, 182, 191, 234, 290, 330, 310],
                    color:'#509c38',
                },
                {
                    name: '电力',
                    type: 'line',
                    symbol: 'circle',     //设定为实心点
                    symbolSize: 15,   //设定实心点的大小
                    stack: '总量',
                    data: [150, 232, 201, 154, 190, 330, 410],
                    color:'#8d8135',
                },
                {
                    name: '热力',
                    type: 'line',
                    symbol: 'circle',     //设定为实心点
                    symbolSize: 15,   //设定实心点的大小
                    stack: '总量',
                    data: [320, 332, 301, 334, 390, 330, 320],
                    color:'#6f4e2f',
                },
                {
                    name: '其他',
                    type: 'line',
                    symbol: 'circle',     //设定为实心点
                    symbolSize: 15,   //设定实心点的大小
                    stack: '总量',
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    color:'#819233',
                }
            ]
        };

        return (
            <div className='indexBottom3V3'>
                <div className='indexBottomHead'>
                    <span style={{fontSize: '18px',
                        fontWeight: 'bold',top:'-42px',right:'57px',position:'absolute',color:'#92d271',fontFamily: 'monospace'}}>各品类能源在终端消费与政策关联分析</span>

                </div>
                {/*<ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} style={{ width: "530px", height: "270px" }} />*/}
                <ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} style={{ width: "400px", height: "220px" }} />
            </div>
        )
    }
}


export default IndexBottom3;
