import React, { Component } from 'react';
import * as r from 'ramda'
import { Tree, Input } from 'antd';
import './tree.scss'

const { TreeNode } = Tree;
const Search = Input.Search;

class TreeView extends React.Component {

  onSelect = (selectedKeys, info) => {
    console.log('selected', selectedKeys, info);
  }

  onCheck = (checkedKeys, info) => {
    console.log('onCheck', checkedKeys, info);
  }

  renderChildren = r.map(({ key, title, did, children }) =>
    (<TreeNode title={title.length > 6? `${title.slice(0,6)}...`:title}
      did={did}
      key={key}>{children ?
        this.renderChildren(children) :
        ''}</TreeNode>))

  onChange = (e) => {
    const value = e.target.value;
    console.log(`search for ${value}`);
  }

  render() {
    return (
      <div className='treeViewContainer'>
        {/* <Search style={{ marginBottom: 8 }} placeholder="Search"
          onChange={this.onChange} /> */}
        <Tree
          showLine
          blockNode
          multiple={false}
          defaultExpandedKeys={this.props.defaultExpandedKeys}
          defaultSelectedKeys={this.props.defaultSelectedKeys}
          defaultCheckedKeys={this.props.defaultCheckedKeys}
          onSelect={this.props.onSelect ? this.props.onSelect : this.onSelect}
          onCheck={this.props.onCheck ? this.props.onCheck : this.onCheck}
        >
          {this.renderChildren(this.props.data)}
        </Tree></div>
    )
  }
}

export default TreeView
