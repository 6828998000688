import React, { useContext } from 'react';
import { Row, Col } from 'antd'

import Echarts from 'src/components/echarts/echarts';
import './analysisKgce.scss';
import { context, loadNodeUsage } from '../../analysis'
import Img_down from 'src/img/down.png'
import Img_up from 'src/img/up.png'
import { of } from 'rxjs';
import * as r from 'ramda';


const AnalysisKgce = () => {
  const {
    state: {
      todayWater,
      todayWaterRatio,
      todayElectricity,
      todayElectricityRatio,
      monthWaterTotal,
      monthWaterRatio,
      monthElectricityTotal,
      monthElectricityRatio,
      kgceMonth = 0,
      kgceAnnual = 0,
      monthlyRatio = 0,
      annuallyRatio = 0,
      kgceData,
      kgcePeriod,
    }, dispatch
  } = useContext(context);

  const kgcePeriodName = (kgcePeriod=='annually'?'年':'月');
  const kgcePeriodNameDay = (kgcePeriod=='annually'?'月':'日');
  const echartsData = {
    tit: '能耗同比分析',
    ulShow: ['month', 'years', 'upload'],
    data: kgceData,
    selection: kgcePeriod,
    handleScaleChange: (kgcePeriod) => {
      dispatch(state => of({ ...state, loading: true }))
      return dispatch(state => loadNodeUsage({
        ...state, loading: true,
        kgcePeriod
      }))
    },
  }

  const abnormalNub = (x) => {
    return x === 'NaN' || x === 'Infinity' ? 0.00 : x;
  }

  return (
    <div className='analysis_right_botton'>
      <div className='analysis_right_botton_left'>
        <div className='analysis_right_botton_left_top'>
          <Row>
            <Col span={16}>当日用水：<b>{todayWater} m³</b></Col>
            <Col span={8}>
              昨日同期：<i>{abnormalNub(todayWaterRatio)}%</i><img
                src={todayWaterRatio > 0 ? Img_up : Img_down} alt="" />
            </Col>
          </Row>
          <Row>
            <Col span={16}>当日用电：<b>{todayElectricity} kwh</b></Col>
            <Col span={8}>昨日同期：<i>{abnormalNub(todayElectricityRatio)}%</i><img
              src={todayElectricityRatio > 0 ? Img_up : Img_down}
              alt="" /></Col></Row>
          <Row>
            <Col span={16}>当{kgcePeriodName}用水：<b>{monthWaterTotal} m³</b></Col>
            <Col span={8}>上{kgcePeriodName}同期：<i>{abnormalNub(monthWaterRatio)}%</i><img
              src={monthWaterRatio > 0 ? Img_up : Img_down} alt="" /></Col></Row>
          <Row>
            <Col span={16}>当{kgcePeriodName}用电：<b>{monthElectricityTotal} kwh</b></Col>
            <Col span={8}>上{kgcePeriodName}同期：<i>{abnormalNub(monthElectricityRatio)}%</i><img
              src={monthElectricityRatio > 0 ? Img_up : Img_down}
              alt="" /></Col></Row>
        </div>
        <div className='analysis_right_botton_left_bottom'>
          <Row>
            <Col span={12}>环比涨幅：<i>{monthlyRatio}%</i></Col>
            <Col span={12}>{kgcePeriodNameDay}综合能耗：<b>{kgceMonth} </b>kgce</Col>
          </Row>
          <Row>
            <Col span={12}>同比涨幅：<i>{annuallyRatio}%</i></Col>
            <Col span={12}>{kgcePeriodName}综合能耗：<b>{kgceAnnual} </b>kgce</Col>
          </Row>

        </div>
      </div>
      <div className='analysis_right_botton_right'>
        <Echarts props={echartsData} />
      </div>
    </div>
  )
}

export default AnalysisKgce;
