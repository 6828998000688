import React from 'react';

import './indexMid.scss';
import Border2 from '../ui/border2/border2';

import IndexMidLeft from './indexMidLeft/indexMidLeft';
import PartLeft1 from './partLeft1/partLeft1';
import IndexMidRight from './indexMidRight/indexMidRight';

class IndexMid extends React.Component {

    render() {
        return (
            <div className='indexMidV3'>
                <Border2>
                    <PartLeft1 />
                </Border2>
                    <IndexMidRight />
            </div>
        )
    }
}

export default IndexMid

