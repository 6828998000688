import React, {useContext, useState} from 'react';
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment'
import {Spin} from 'antd'
import {from, of} from 'rxjs'

import './reportWeather.scss';
import Api from 'api';

import Tree from 'src/components/treeView/tree';


import WeatherDateInteval from './weatherDate/weatherDateInterval';

import withStates from 'src/context/withStates';
import cookie from "react-cookies";
import {setProjectName} from "../../context/NavItem";

const ROOT_NODE = '0';
//建筑类型分类·
const projectTypeConverter = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}

const dateFormat = function (date, format) {
    date = new Date(date);
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour+8小时
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

    return format;
}

const WeatherDate = () => {
    const {state, dispatch} = useContext(context);
    const onSelect = (node) => (event) => {
        console.log(`node ${r.head(event)} selected`)

        return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
            (state) => loadNodeUsage({...state, loading: true, node: r.head(event)})
    }
    return (
        <div className="reportWeather">
            <div className="reportRightWeather">
                {/* <Router> */}
                <div>
                    <nav className='reportNav'>
                        <ul>

                        </ul>
                        <div>

                        </div>
                    </nav>
                    <WeatherDateInteval />

                </div>
                {/* </Router> */}

            </div>

        </div>

    )
}


const deviceNameMap = {
    temp: {name: 'temp', value: 'temp'},
    electricity: {name: '电能', value: 'electricity'},
    gas: {name: '燃气', value: 'gas'},
    water: {name: '水耗', value: 'water'},
}

const selectType = [
    {name: '温度', value: 'temp'},
    {name: '湿度', value: 'humi'},
    // {name: '露点', value: 'dew'},
    // {name: '大气压', value: 'atmos'},
    // {name: '辐射', value: 'radia'},
    {name: '风速', value: 'windspeed'},
    // {name: '风向', value: 'winddir'},
    {name: 'pm10', value: 'pm10'},
    {name: 'pm2d5', value: 'pm2d5'},
    {name: '负氧离子', value: 'negOxygen'}
    ];
const avgTrans =     {
    "all": "a_t", //
    "temp": "a_t", //
    "humi": "a_h", //
    "dew": "a_d", //
    "atmos": "a_a", //
    "radia": "a_r", //
    "windspeed": "a_w",   //
    "pm10": "a_pm10",   //
    "pm2d5": "a_pm2d5",   //
    "negOxygen": "a_negOxygen",   //

}
const avgTrans2 =     {
    "all": "温度", //
    "temp": "温度", //
    "humi": "湿度", //
    "dew": "露点", //
    "atmos": "大气压", //
    "radia": "辐射", //
    "windspeed": "风速",   //
    "pm10": "pm10",   //
    "pm2d5": "pm2d5",   //
    "negOxygen": "负氧离子",   //

}
var dirs = ['N','NNE','NE','ENE','E','ESE','SE','SSE','S','SSW','SW','WSW','W','WNW','NW','NNW'];

const transDataTypeForAvg = (dataType) =>avgTrans[dataType];
const transDataTypeForAvg2 = (dataType) =>avgTrans2[dataType];
const translateType = v => {
    return r.propOr(v)(v)(deviceNameMap)
}

const diff = (a,b)=>{
    return dirs.indexOf(a.wn)-dirs.indexOf(b.wn);
}

const nodeUsage = async ({node, from, to, deviceType, search}) => {
    const {data: dayReport} =deviceType == 'radia'?await Api.get(`/api/v3/energyNew/weatherDate2?project=${Api.projectId}&from=${from}&to=${to}&dataType=${deviceType}`):
        await Api.get(`/api/v3/energyNew/weatherDate?project=${Api.projectId}&from=${from}&to=${to}&dataType=${deviceType}`);

    if(dayReport.error){
        console.log('error');
        alert(dayReport.error);
        return;
    }

    const isFn = n => {
        const {sensor, title} = n;
        const re = new RegExp(search)
        return r.test(re)(sensor) || r.test(re)(title)
    }
    // console.log(dayReport)
    //增加一个累计平均值
    let avgData = [],dataX=[],reacordX = {
    },dataY=[];//非风向外的数据
    let sumAvg=0,i=0;
    let winddirdata = [];
    let windDirArr = [];// 风向数据
    let cnt = 0;//风向是实际天数
    if(deviceType == 'winddir'){
        // 风向频率值要用小时数，所以要把实际这么多天的频率数 /（天数*24h）
        cnt = dayReport[1][0].cnt;
        let arr2 = r.pipe(r.sort(diff),r.groupBy(r.prop('lev')))(dayReport[0]);

        for( let key in arr2){
            let unit = arr2[key];
            for(let i = 0,j=0;i< dirs.length; i++){
                if(key==1){
                    if(dirs[i] == unit[j].wn){
                        winddirdata.push([key-1,key-0,unit[j].a_w,Math.floor(unit[j].n/cnt)]);
                        // winddirdata.push([key-1,key-0,unit[j].n-0,Math.floor(unit[j].n/cnt)]);
                        (j<(unit.length-1))?j++:(j=j);// 因为有些方向没有15个，如果跟着dir继续index后移，会导致越界，所以这里就最多不会超过最后一个，最后累加需要确认

                    }   else{
                        winddirdata.push([0,0,0,0]);
                    }
                }else{
                    if(dirs[i] == unit[j].wn){
                        winddirdata[i].push(key-1,key-0,unit[j].a_w,Math.floor(unit[j].n/cnt));
                        // winddirdata[i].push(key-1,key-0,unit[j].n-0,Math.floor(unit[j].n/cnt));
                        (j<(unit.length-1))?j++:(j=j);
                    }   else{
                        winddirdata[i].push(0,0,0,0);
                    }
                }

            }


        }
        if(winddirdata.length > 0){
            // 添加总数的内容
            let arr3 = r.pipe(r.sort(diff),r.groupBy(r.prop('wn')))(dayReport[0]);
            // let arrfill = Array(16-(winddirdata[0].length>16?16:winddirdata[0].length)).fill(0);//如果结果没有达到4组，剩下自动补全
            // 应该是跟sql查询的结果组数对应，是5组，就是5*4=20的数量
            let arrfill = Array(20-(winddirdata[0].length>20?20:winddirdata[0].length)).fill(0);//如果结果没有达到4组，剩下自动补全

            // console.log(arr3);
            let j = 0;
            for(let key in arr3){
                winddirdata[j]=winddirdata[j].concat(arrfill);
                winddirdata[j].push(3,4,0,Math.floor((r.compose(r.sum, r.pluck('n'))(arr3[key]))/cnt));
                // console.log(r.compose(r.sum, r.pluck('n'))(arr3[key]));
                j++;
            }
        }


    }else{

        r.map(y=>{i++;sumAvg+=y[transDataTypeForAvg(deviceType)];
            avgData.push((sumAvg/i).toFixed(2));
            // dataX.push(dateFormat(y.caltime,'yyyy-MM-dd HH:mm:ss'));
            dataX.push(dateFormat(y.caltime,'yyyy-MM-dd-HH-mm'));
        })(dayReport);
        dataY = (r.pluck(transDataTypeForAvg(deviceType))(dayReport))
    //     添加每个月的月初和月末的数据，提供后续的图形颜色显示
        r.map(y=>{
            let month = y.substr(0,7);
            if(!reacordX[month]){
                //如果某个月只有一天的数据，那么这个月的首位区间只要一个了，所以这里一下子都加进去，如果后面有新的再来替换吧
                reacordX[month]=[y];
                reacordX[month]=[y];
            }else{
                reacordX[month][1]=y;
            }
        })(dataX);

        console.log('reacordX-----');
        console.log(reacordX);
    }





    const tableData = dayReport;

    // console.log('tableData')
    // console.log(tableData)


    return {
        loading: false,
        node,
        select: selectType,
        tableData,
        winddirdata,
        avgData,
        dataX,
        reacordX,
        dataY,
        from,
        to,
        cnt,
        deviceType,
        nameType:transDataTypeForAvg2(deviceType),
        // total: {
        //     usage: totalUsage.toFixed(2),
        //     cost: totalCost.toFixed(2),
        // }
        // total: {
        //     start: dayReport[0]?moment(dayReport[0]._min).format('YYYYMMDD'):'',
        //     end: dayReport[0]?moment(dayReport[0]._max).format('YYYYMMDD'):'',
        // }
    }
}

const loadReport = (state) => {
    const input = Promise.all(
        [/*treeViewData(state),*/ nodeUsage(state)])
        .then(r.mergeAll)
    return from(input)
}

export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))
}
const initState = {
    loading: true,
    node: ROOT_NODE,
    tree: [],
    reportType: 'day',
    nameType: '温度',
    from: moment().subtract(1, 'month').format('YYYYMMDD'),
    to: moment().format('YYYYMMDD'),
    select: selectType,
    tableData: [],
    winddirdata: [],
    avgData: [],
    tableData: [],
    dataX: [],
    reacordX:{
    },
    dataY: [],
    cnt:0,
    deviceType: 'temp',
    // deviceType: 'winddir',
    // deviceType: 'radia',
    total: {
        usage: 0,
        cost: 0,
    }
}

export const {context, Component} = withStates(
    WeatherDate,
    initState,
    loadReport,//这里包含有左边树的数据和其他数据
)

export default Component
