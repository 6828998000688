import React, { useContext } from 'react';
import CurrentUsage from './buildingCurrentUsage/buildingCurrentUsage';
import Percentage from './buildingPercentage/buildingPercentage';
import Rank from './buildingRank/buildingRank';

const BuildingRightBottom = () => (
    <div className='buildingRightBottom'>
        <CurrentUsage />
        <Rank />
        <Percentage />
    </div>
)

export default BuildingRightBottom