import React, {useContext} from 'react';
import {DatePicker,Button} from 'antd';
import * as r from 'ramda';
import './indexMidLeftForWeather.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import { timer } from 'rxjs';
import moment from 'moment';

import Border4 from '../../ui/border4/border4';
import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';
import {ColorContext} from "../../color";

const {MonthPicker, RangePicker} = DatePicker;

const dateFormat = function (date, format) {
    date = new Date(date);
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour+8小时
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

    return format;
}
let pvData;
const disabledDate = (current) => {
    return current > moment().endOf('day')
}
class IndexMidLeftForWeather extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            xAxisData: [],
            electricityData: [],
            airtimeData: [],
            airtimeData2: [],
            // 存储的顺序
            // `pm2d5`	0
            // 	`pm10`	1
            // 	`co2`	2
            // 	`tvoc`	3
            // 	`tvoc2`	4
            // 	`hcho`	5
            // 	`hcho2`	6
            // 	`temp`	7
            // 	`humi`	8
            // 	`addtime`	9
            airDataJson:{LanJu:[],QINDI:[]},
            pm2d5Data: [],
            millData: [],
            pm10Data: [],
            co2Data: [],
            tvocData: [],
            tvoc2Data: [],
            hchoData: [],
            hcho2Data: [],
            tempData: [],
            humiData: [],
            pm2d5Now: 0,
            pm10Now: 0,
            co2Now: 0,
            tvocNow: 0,
            tvoc2Now: 0,
            hchoNow: 0,
            hcho2Now: 0,
            tempNow: 0,
            humiNow: 0,

            notMerge: true,
            lazyUpdate: true,
            style: { width: "80%", height: "200px"},
            style2: { width: "50%", height: "200px",margin: '0',float: 'left' },
            style_temp: { fontSize:'30px',color:'#78bbfd',float:'left' },
            style_humi: { fontSize:'30px',color:'#d3fd8b',float:'left' },
            style_pm2d5: { fontSize:'30px',color:'#f19865',float:'left' },
            style_pm10: { fontSize:'30px',color:'#a6f16c',float:'left' },
            style_co2: { fontSize:'30px',color:'#56fddf',float:'left' },
            style_tvoc: { fontSize:'30px',color:'#7ab4ba',float:'left' },
            style_hcho: { fontSize:'30px',color:'#ba6190',float:'left' },
            active: "line",
            // from:moment().format('YYYYMMDD'),
            // to:moment().format('YYYYMMDD'),
            from:'20220901',
            to:'20220927',
            showData:[],
        };
    }



    onTimeInterval = (date, dateString) => {
        this.setState({
            from: moment(date[0]).format('YYYYMMDD'),
            to: moment(date[1]).format('YYYYMMDD')
        })

    }

    submit =async () => {
        console.log('--- pvData ----');
        pvData = await Api.postV3('/energyNew/pvData', {
            project: Api.projectId,
            from:this.state.from,
            to:this.state.to,
        });
        console.log('--- pvData ----');
        console.log(pvData);
        let data;
        if(pvData&&pvData.data&&pvData.data.result){

            console.log('begin to deal pvData ---');
             data = pvData.data.result;
             console.log('---- pvData2 ----');
             console.log(data);
        //     计算每个小时的数值，
            let showData = [];
            data.forEach(function (val,i) {


                let dataArr = val.data;

                let result={};//所有天数的数据都整合在一起
                let xAxisData = [];
                let yAxisData = [];
                if(dataArr){
                    dataArr.forEach(function (val,i) {

                        let timeIndex = moment(val.date,'YYYYMMDD');
                        let baseTotal = Number(val.total) - Number(val.value);

                        for(let i=0;i<24;i++){
                            timeIndex.hour(i);
                            // if(timeIndex.unix() > now.unix()){
                            //     continue;
                            // }

                            const key = 'hour.'+i;
                            if(val[key] === null) {
                                result[timeIndex.valueOf()] = {
                                    timepoint: timeIndex.valueOf(),
                                    timeStr: timeIndex.format('YYYYMMDD-HH-SS'),
                                    total: null
                                };
                                xAxisData.push(timeIndex.format('YYYYMMDD-HH-SS'))
                                yAxisData.push(null);
                            }
                            else {

                                baseTotal += Number(val[key]);
                                result[timeIndex.valueOf()] = {
                                    timepoint: timeIndex.valueOf(),
                                    timeStr:timeIndex.format('YYYYMMDD-HH-SS'),
                                    total: baseTotal.toFixed(2)
                                };

                                xAxisData.push(timeIndex.format('YYYYMMDD-HH-SS'))
                                yAxisData.push(baseTotal.toFixed(2));
                            }
                        }

                    })

                    // console.log('result ------ ');
                    // console.log(result);
                    showData.push({title:val.title,result:result,xAxisData:xAxisData,yAxisData:yAxisData});


                }



            });
            console.log(showData);
        //    showData 这里包含了汇聚之后的光伏这个参数的数据
            this.setState({showData:showData});



        }




    }


    async getData(active='bar') {
        this.state = { getOption: { series: [] }, ...this.state }
        const data = new Date();
        const hh = data.getHours();
        const airData = await Api.postV3('/energyNew/airData', {
            project: Api.projectId,
            from:this.state.from,
            to:this.state.to,
            num:240
        });


        const airDataForNow = await Api.postV3('/energyForNew/weatherRightNow', {
            project: Api.projectId
        });

        // console.log('data-------------------:' + electricity.data.result.length);
        // console.log('dataRightNow-------------------:' + weatherForNow.data);
        let activityTime = [];
        let airtimeData = [];
        let airtimeData2 = [];
        let electricityData = [];
        let airDataJson={LanJu:[],QINDI:[]};
        let pm2d5Now= 0,
            pm10Now= 0,
            co2Now= 0,
            tvocNow= 0,
            tvoc2Now= 0,
            hchoNow= 0,
            hcho2Now= 0,
            tempNow= 0,
            humiNow= 0;

        let params = ['pm2d5','pm10','co2','tvoc','tvoc2','hcho','hcho2','temp','humi'];

        if (airData.data.result) {
            let res = airData.data.result;
            airtimeData = r.map(y => dateFormat(y,'MM-dd HH:mm'))(r.pluck('addtime')(res[1]));
            airtimeData2 = r.map(y => dateFormat(y,'MM-dd HH:mm'))(r.pluck('addtime')(res[0]));
            // airtimeData2 = (r.pluck('addtime')(res[0]));

            r.map(
                y =>
                {
                    console.log(y);
                    airDataJson.LanJu.push(r.pluck(y)(res[1]));
                    airDataJson.QINDI.push(r.pluck(y)(res[0]));
                }
            )(params);
        }


        this.setState({
            airtimeData:airtimeData,
            airtimeData2:airtimeData2,
            airDataJson:airDataJson


        })
    }


    componentDidMount() {

        // const upDataTime = 1 * 60 * 1000;//分钟
        // const source = timer(0, upDataTime);
        // source.subscribe((x) => { console.log('indexTopLeft:' + x); this.getData() })
        // console.log('color ------');
        // console.log(this.props.color);

    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { airDataJson,airtimeData,airtimeData2,from,to,showData} = this.state;



        let getOption = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },

            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                // data: this.props.keyWord?airtimeData2:airtimeData,
                data: showData[0]?showData[0].xAxisData:[],
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                    type: 'line',
                    name: showData[0]?showData[0].title:'',
                    // name: color,
                    // data: this.props.keyWord?airDataJson.QINDI[0]:airDataJson.LanJu[0],
                    data: showData[0]?showData[0].yAxisData:[],
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#78bbfd'
                        }
                    },
                }]
        }

        let getOption2 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: showData[1]?showData[1].xAxisData:[],
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: showData[1]?showData[1].title:'',
                data: showData[1]?showData[1].yAxisData:[],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#d3fd8b'
                    }
                },
            }]
        }

        let getOption3 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: showData[2]?showData[2].xAxisData:[],
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: showData[2]?showData[2].title:'',
                data: showData[2]?showData[2].yAxisData:[],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#FD7F33'
                    }
                },
            }]
        }

        let getOption4 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },

            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: showData[3]?showData[3].xAxisData:[],
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: showData[3]?showData[3].title:'',
                data: showData[3]?showData[3].yAxisData:[],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#fd8993'
                    }
                },
            }]
        }

        let getOption5 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },

            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: showData[4]?showData[4].xAxisData:[],
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: showData[4]?showData[4].title:'',
                data: showData[4]?showData[4].yAxisData:[],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#a6f16c'
                    }
                },
            }]
        }

        let getOption6 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: showData[5]?showData[5].xAxisData:[],
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name:showData[5]?showData[5].title:'',
                data: showData[5]?showData[5].yAxisData:[],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#56fddf'
                    }
                },
            }]
        }

        let getOption7 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: [],
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '',
                data: [],
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#56fddf'
                    }
                },
            }]
        }






        // setInterval(function() {
        //     option.series[0].data[0].value = (Math.random() * 100).toFixed(2) - 0;
        //     myChart.setOption(option, true);
        // }, 2000);




            return (


            <div className='indexMidLeftForWeather'>
                <div className='indexMidLeftHeadForWeather'>
                    {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                    {/*    <i className='round'></i>*/}
                    {/*</Border4>*/}
                    <span>天气数据</span>
                    <RangePicker onChange={this.onTimeInterval}
                                 // defaultValue={[moment(from), moment(to)]}
                                 defaultValue={[moment(from), moment(to)]}
                                 disabledDate={disabledDate}/>
                    <Button type="primary" onClick={this.submit}>查询</Button>
                </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.electricityData[this.state.electricityData.length-1]}℃ </span>*/}
                        {/*<span className='bigWord' style={this.state.style_pm2d5}> {this.state.tempNow}℃ </span>*/}
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption2} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.humiData[this.state.humiData.length-1]}% </span>*/}
                        {/*<span className='bigWord' style={this.state.style_pm10}> {this.state.humiNow}% </span>*/}
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption3} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.dewData[this.state.dewData.length-1]}Td </span>*/}
                        {/*<span className='bigWord' style={this.state.style_co2}> {this.state.dewNow}Td </span>*/}
                    </div>

                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption4} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.radiaData[this.state.radiaData.length-1]}Si </span>*/}
                        {/*<span className='bigWord' style={this.state.style_tvoc}> {this.state.radiaNow}Si </span>*/}
                    </div>
                <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption5} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.radiaData[this.state.radiaData.length-1]}Si </span>*/}
                        {/*<span className='bigWord' style={this.state.style_hcho}> {this.state.radiaNow}Si </span>*/}
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption6} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.windspeedData[this.state.windspeedData.length-1]}m/s </span>*/}
                        {/*<span className='bigWord' style={this.state.style_temp}> {this.state.windspeedNow}m/s </span>*/}
                    </div>
                    {/*<div className='divEcharts' style={this.state.style2}>*/}
                        {/*<ReactEcharts option={getOption7} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />*/}
                        {/*/!*<span className='bigWord' style={this.state.style3}> {this.state.windspeedData[this.state.windspeedData.length-1]}m/s </span>*!/*/}
                        {/*/!*<span className='bigWord' style={this.state.style_humi}> {this.state.windspeedNow}m/s </span>*!/*/}
                    {/*</div>*/}

                {/*<ReactEcharts option={getOption7} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />*/}
            </div>
        )
    }
}


export default IndexMidLeftForWeather;
