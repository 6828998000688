import React from 'react';
import {
    Form, Select
} from 'antd';
// import './information.scss';
const { Option } = Select;
// const AutoCompleteOption = AutoComplete.Option;

class Drive extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            confirmDirty: false,
            autoCompleteResult: [],
        };
    }

    handleChange(value) {
        console.log(`selected ${value}`);
        console.log(this.props)
        console.log("!!!!!!!")
        // this.props.handleChange(value)
    }

    handleSubmit() {
        console.log("sun")
    }


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 4 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 20 },
            },
        };
        // const formTailLayout = {
        //     wrapperCol: { span: 20, offset: 4 },
        // };
        return (
            <Form onSubmit={this.handleSubmit} className='components_form'>
                <Form.Item
                    {...formItemLayout}
                    label="仪表驱动" >
                    <Select defaultValue="1" onChange={this.props.handleChange}>
                        <Option value="1">1</Option>
                        <Option value="2">2</Option>
                        <Option value="3" >3</Option>
                        <Option value="4">4</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="仪表驱动" >
                    <Select defaultValue="lucy" onChange={this.handleChange}>
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="disabled" disabled>Disabled</Option>
                        <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    {...formItemLayout}
                    label="仪表驱动" >
                    <Select defaultValue="lucy" onChange={this.handleChange}>
                        <Option value="jack">Jack</Option>
                        <Option value="lucy">Lucy</Option>
                        <Option value="disabled" disabled>Disabled</Option>
                        <Option value="Yiminghe">yiminghe</Option>
                    </Select>
                </Form.Item>
            </Form>
        );
    }
}

export default Drive;