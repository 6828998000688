import React, { useContext } from 'react';
import * as r from 'ramda';
import { Radio, Row, Col } from 'antd'

import Echarts from 'src/components/echarts/echarts';
import './energy.scss';
import { context } from 'src/modules/energy/energy/energy';
import { of } from 'rxjs';
import { loadAll } from './energy'

const { Group: RadioGroup, Button: RadioButton } = Radio

const EnergyRanking = () => {
  const { state, dispatch } = useContext(context)

  const echartsData2 = {
    tit: '分项能耗排名',
    ulShow: ['day', 'month', 'years', 'upload',],
    data: state.ranking,
    handleScaleChange: (subentryRankDate) => {
      switch (subentryRankDate) {
        case 'daily':
          subentryRankDate = 'rankingDaily'
          break;
        case 'monthly':
          subentryRankDate = 'rankingMonthly'
          break;
        case 'annually':
          subentryRankDate = 'rankingAnnually'
          break;
        default:
          subentryRankDate = 'rankingDaily'
          break;
      }
      dispatch(state => of({ ...state, loading: true }))
      return dispatch(state => loadAll({
        ...state, loading: true,
        subentryRankDate
      }))
    },
  }

  return (
    <Row className="energyDiv_b energyDiv2">
      <Col span="3" className='energyButton'>
        <RadioGroup onChange={(e) => {
          dispatch(state => of(r.assoc('loading', true)(state)))
          dispatch(state => loadAll({
            ...state, loading: true,
            subentryOption: e.target.value,
          }))
        }} value={state.subentryOption}>
          <RadioButton value="lightrcptcl">照明插座</RadioButton>
          <RadioButton value="airele">空调用电</RadioButton>
          <RadioButton value="powerele">动力用电</RadioButton>
          <RadioButton value="specialele">特殊用电</RadioButton>
        </RadioGroup>
      </Col>
      <Col span="21">
        <Echarts props={echartsData2} />
      </Col>
    </Row>
  )
}

export default EnergyRanking;