import React from 'react';
import { Modal, Input, Button } from 'antd';

import Api from 'src/api';
import './delete.scss'

const confirm = Modal.confirm;

class Delete extends React.Component {
    state = {
        ModalText: 'Content of the modal',
        visible: false,
        confirmLoading: false,
        passw: '',
    }

    passwordInput = (e) => {
        this.setState({ passw: e.target.value })
        console.log(this.state.passw)
    }

    showConfirm = () => {
        let id = this.props.record
        let pw = this.state.passw
        console.log(this.props.record)
        confirm({
            title: '您确定要删除该仪表吗！',
            content: <div><p>删除该仪表后所有历史数据将被清除请谨慎操作。</p> <Input.Password placeholder="请输入密码" onChange={this.passwordInput} /></div>,
            onOk() {
                return new Promise((resolve, reject) => {
                    Api.post('/user/DeleteOnemanger', {
                        Id: id,
                        password: pw
                    })
                }).catch((res) => console.log(res))
            },
            onCancel() { },
        });
    }

    render() {
        return (
            <div className="delete">
                <Button type="danger" onClick={this.showConfirm}>
                    删除
        </Button>
            </div>
        );
    }
}

export default Delete;