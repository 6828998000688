import React , { useContext } from 'react';

import moment from 'moment';
import './carbonNeutral.scss';
// import Api from 'api'

import Img_ScreenVersionIndexBottom from '../../img/ScreenVersionIndexBottom.png'

import IndexTop from './indexTop/indexTop';
import IndexMid from './indexMid/indexMid';
import IndexBottom from './indexBottom/indexBottom';
import AMapV3 from './map/map';
import AMap from 'src/modules/map/map';
import IndexLeftPointIndex from './ui/IndexLeftPoint/IndexLeftPoint'
import CarbonNeutralHead from './carbonNeutralHead';
import { Color,ColorContext , UPDATE_COLOR } from "./color";
import IndexMidLeft from "./indexMid/indexMidLeft/indexMidLeft";
import IndexMidLeftForWeather from "./indexMid/indexMidLeft/indexMidLeftForWeather";
import {setPermission, setProjectName, setRegion, setRegionPermission, setRegionProjects} from "../../context/NavItem";
import md5 from "md5";
import cookie from "react-cookies";
import {message} from "antd/lib/index";
import Api from "api";
import * as r from "ramda";
const DEFAULT_PROJECT = '5d6cd030a59c7200126be563'

// 新的投屏版
function defaultLoggedProject(project) {
    const resource = r.propOr(DEFAULT_PROJECT, '0', project);
    return resource === '*' ? DEFAULT_PROJECT : resource;
}

class CarbonNeutral extends React.Component {
    constructor(props){
        super(props);
        this.state={
            keyWord: false
        }
    }
//自动登录
    autoLogin =async(e) => {


        const userInfo = {
            LoginName: 'wenzhoushi',
            RequestTime: moment.now()};
        // return;
        if(!cookie.load('uid') || !cookie.load('regionProjects') || true){
            console.log(' no uid or regionProjects find, to req again --- ');
            await Api.post('/auth/login', {
                user: 'wenzhoushi',
                passwd: md5('admin2020').toUpperCase(),
            }).then((data) => {
                if (data.data.code === 20000001) {
                    alert('用户名错误');
                }
                if (data.data.code === 90000006) {
                    alert('密码错误');
                }
                if (data.data.code === 0) {
                    Api.post('/account/info', { id: data.data.result.uid })
                        .then(({ data: { result: { title: name, character: { id: permission }, resource: { project } } } }) => {
                            const id = defaultLoggedProject(project);
                            // this.props.Project.upProjectData({ name, id });
                            Api.projectId = id;
                            Api.projectName = name;
                            // document.title = name;
                            cookie.save(
                                'projectName',
                                name,
                                { path: '/', domain }
                            )
                            cookie.save(
                                'projectId',
                                id,
                                { path: '/', domain }
                            )
                            cookie.save(
                                'regionProjectId',
                                id,
                                { path: '/', domain }
                            )
                            setPermission(permission)
                            setProjectName(name)

                            // Api.get('/api/v3/accountRegion/'+ cookie.load('uid'))
                            //     .then(r.propOr([], 'data')).then(data => {
                            //     // super admin can access multiple projects
                            //     if(data&&data.length > 0){
                            //         //但是这的参数，在sensorchannel info 里面却无法获取到参数
                            //         console.log(data);
                            //         console.log('new accountRegion --- : ');
                            //         console.log(data);
                            //         let regData = r.map(y=>({building:y.building,projectId:y.projectId,projectName:y.projectName}))(data);
                            //         Api.region = cookie.load('uid')=='wenzhoushi'?'330300':data[0].region;
                            //         Api.regionProjects = regData;
                            //         setRegionPermission(Api.projectId);
                            //         setRegionProjects(data);//保存该区域下的项目列表
                            //         setRegion(cookie.load('uid')=='wenzhoushi'?'330300':data[0].region);
                            //
                            //
                            //         console.log('regData ------ ');
                            //         console.log(regData);
                            //         cookie.save('isRegion', true);
                            //         cookie.save('regionProjects', regData);
                            //         cookie.save('region', cookie.load('uid')=='wenzhoushi'?'330300':data[0].region);//存储用户属于的region
                            //         cookie.save('testCarbon',moment.now());
                            //         data.push({id:100,projectId:cookie.load('regionProjectId'),projectName:regionJsonConverter[cookie.load('uid')=='wenzhoushi'?'330300':data[0].region]});
                            //     }
                            //
                            //     // return data
                            // })

                            this.setState({
                                isLogin: true
                            })
                        })

                    const domain = window.location.hostname.replace(/^\w+\./, '')
                    cookie.save(
                        'token',
                        data.data.result.token,
                        { path: '/', domain }
                    )
                    cookie.save(
                        'uid',
                        data.data.result.uid,
                        { path: '/', domain }
                    )
                    cookie.save(
                        'isLogin',
                        'sbfui20',
                        { path: '/', domain }
                    )
                }


            })


        }




    }


    componentDidMount(){
        this.autoLogin();
    }

    //设置组件A input的查询参数
     setKeyWorld = async (keyWord) => {
        this.setState({
            keyWord
        })
    }



  render() {
      const btnStyle2 = {
          display:'none'
      };
      const Style3 = {
          display:this.state.keyWord?'none':'block'
      };

      // this.autoLogin();



    return (
     <Color>
      <div className="ScreenVersionIndexV3">
        {/*<PresentationV3Head  />*/}
        <div id='screenContainer' className='ScreenVersionIndexConainer' >
          <IndexLeftPointIndex />
          <div className='ScreenVersionIndexConainerLeft'>
            <IndexTop />
            <IndexMid />
            <AMapV3/>
              {/*<div style={{position:'absolute',width:'300px',height:'300px'}}>*/}

                  {/*<AMap/>*/}
              {/*</div>*/}
              <IndexBottom />
          </div>
          <div className='borderRightBorder'>
            <div className='borderRightBorder2 borderRightBorder2Top'></div>
            <div className='borderRightBorder2 borderRightBorder2Bottom'></div>
          </div>
        </div>
        {/*/!*这里填充新的页面样式*!/*/}
          {/*<div id='midLeftForWeather'>*/}
              {/*<IndexMidLeftForWeather  />*/}
          {/*</div>*/}



        <img src={Img_ScreenVersionIndexBottom} alt="" className='Img_ScreenVersionIndexBottom'/>
      </div>
     </Color>
    )
  }

};


export default CarbonNeutral;
