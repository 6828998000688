import * as r from "ramda";

export const ROOT_NODE = '0'
const joinKeys = r.pipe(r.filter(r.identity), r.join(','))
export const formatTree = root => r.compose(
  r.map(([title, {id: key, devices = [], formula, ...rest}]) =>
    ({
      title, key: joinKeys([root, key]), devices, id: key, formula,
      children: r.isEmpty(rest) ? [] : formatTree(joinKeys([root, key]))(rest),
    })),
  r.toPairs)

export const idAsKey = r.pipe(r.toPairs,
  r.map(([title, {id, devices = [], formula, ...rest}]) => ({
    [id]: {
      id,
      devices,
      title,
      formula, ...(r.isEmpty(rest) ? {} : idAsKey(rest))
    }
  })),
  r.mergeAll
)

export const flattenDevices = ({devices = [], id, formula,title, ...rest}) => {
  const [next, cur] = r.partition(r.is(Object), rest)
  return  r.isEmpty(next) ?
    devices : r.concat(devices, r.chain(flattenDevices)(r.values(next)))

}
export const flattenNodes = ({devices, formula, ...rest}) => {
  const [next, cur] = r.partition(r.is(Object), rest)
  return r.isEmpty(next) ?
    [{devices, formula, ...cur}] : r.concat([{devices, formula, ...cur}], r.chain(flattenNodes)(r.values(next)))
}