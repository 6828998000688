import React, {useContext} from 'react';

import './drawingMonitor.scss'
import withStates from 'src/context/withStates';
// import Api from 'api'

import IndexMidDrawingMonitor from './indexMid/indexMidDrawingMonitor'
import IntervalReport from './indexMid/intervalReport/intervalReport'
// import {context} from "../report/report";
import * as r from "ramda";
import moment from 'moment'
import {from, of} from 'rxjs'
import Api from 'api';
// class drawingMonitor extends React.Component {
    const drawingMonitor = () => {
        const {state, dispatch} = useContext(context);

    return (


        <div className="drawCheck">

            <div className="reporRight">
                    {/* <Router> */}
                <div>
                    <nav className='reportNav'>
                        <ul>
                        </ul>
                        <div>

                        </div>
                    </nav>
                    <IntervalReport />

                </div>

            </div>

    </div>

    )


};
const nodeUsage =async ({ from, to,}) => {
    const query = {
        // startDate:'20200101',
        // endDate:'20200103',
        // project:'5d7f6926d4135f0011188ffd',
        // sector:'123',
        startDate:(moment(from).format('YYYY-MM-DD')),
        endDate:(moment(to).format('YYYY-MM-DD')),
        // from:(moment(from).unix())*1000,
        // to:(moment(to).unix())*1000,
        area:10000
    };
    // console.log('from');
    console.log(from);
    let tableData=[];
    console.log('query');
    console.log(query);
    console.log(Api.regionProjects);
    //Address: "瑞安市瑞湖路398号"
    // Company: "浙江省瑞安中学"
    // Contacts: "王辉"
    // CountyName: "瑞安市"
    // CountyNum: 330381
    // Functions: "公用建筑"
    // PrjName: "瑞安中学塑胶运动场改造工程"
    // Telephone: "13868388889"
    // TotalArea: 11516.64
    await Api.postV_drawing('/GetPublicbuilding',query).then(res =>{

            console.log('statusDetail'+res.data);
            let resDatas = res.data;
            if(resDatas.code === '00'&&resDatas.datas&&resDatas.datas.length > 0){
                // console.log();
                let i = 0;
                r.map(y => (y.PrjName?tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    Address:y.Address,
                    Company:y.Company,
                    Contacts:y.Contacts,
                    CountyName:y.CountyName,
                    CountyNum:y.CountyNum,
                    Functions:y.Functions,
                    PrjName:y.PrjName,
                    Telephone:y.Telephone,
                    TotalArea:y.TotalArea,
                }):''))(resDatas.datas);
                console.log('tableData1');
                console.log(tableData);
                // setTableData(tableData);
            }

            // let resCollector = res.data.resCollectors;
            // console.log('res device collector ---- ');
            // console.log(resDevice);
            // console.log(resCollector);
            // console.log(Util2.regionJsonConverter);
            // let i = 0;
            //     r.map(y => (y.projectName?tableData.push({
            //         order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
            //         BuildName:y.projectName,
            //             BuildCode:y.building,
            //         region:Number(y.region),
            //         regionName:regionJsonConverter[y.region],
            //         sector:Number(projectTypeWeight[y.sector]),
            //         sectorName:projectTypeTransfer[y.sector],
            //         OnlineMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][0]:'-',
            //         ErrMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][1]:'-',
            //         MeterTime:'',
            //         OnlineCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][0]:'-',
            //         ErrCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][1]:'-',
            //         CollectorTime:'',
            //         }):''))(Api.regionProjects);
            //     console.log('tableData');
            //     console.log(tableData);
            //     setTableData(tableData);
        },err=>{
            alert('审图系统接口查询错误');
            console.log(err);
        }
    );
    return {
        loading: false,
        tableData,
        // from,
        // to,
    };
}
export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))
}

const loadReport = (state) => {
    const input = Promise.all(
        [
            // treeViewData(state),
            nodeUsage(state)])
        .then(r.mergeAll)
    return from(input)
}
const initState = {
    loading: true,
    // node: ROOT_NODE,
    tree: [],
    reportType: 'day',
    from: moment().subtract(1, 'day').format('YYYYMMDD'),
    to: moment().format('YYYYMMDD'),
    tableData: [],
    deviceType: 'all',
    total: {
        usage: 0,
        cost: 0,
    }
}

export const {context, Component} = withStates(
    drawingMonitor,
    initState,
    loadReport,//这里包含有左边树的数据和其他数据
)

export default Component