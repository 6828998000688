import React, {useContext} from 'react';

import './indexMidRight.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import * as r from 'ramda';
import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';
import {context} from "../../../report/weatherDate";
import moment from 'moment'


class IndexMidRight extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            chartsTime1: [],
            chartsTime2: [],
            chartsTime3: ['01','02','03','04','05','06','07','08','09','10','11','12'],
            charts1: {},
            charts2: {},
            standLine: {}, // the line for the normal thing
            standLine2: {}, // the line for the normal thing
            colorDealRes: [],
            totalDealRes: [],
            gov_d: 0.15,
            gov_m: 4.58,
            no_gov_d: 0.19,
            // no_gov_d: 0.003,
            no_gov_m: 5.83,
            notMerge: true,
            lazyUpdate: true,
            style: { width: "100%", height: "260px" },
            active: "month",
            active2: "bar",
            headCount: 1,
            totalArea: 1,
            sector: '',
            sectorOrigin: '',
            buildType: 1,//1:gov 2:not gov
        };
    }

    async getData() {
        await this.getInfo();
        const month = await Api.postV3('/costNew/dailyComparison24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });
        console.log('getData end: --------------------');
        let _this = this;
        // console.log(lastMonth)
        // console.log(year)
        let monthTime = [];
        let lastMonthTime = [];
        let monthData = [];
        let lastMonthData = [];
        if (month.data.current) {
            month.data.current.forEach(function (i, k) {
                let data = i.date.toString();
                // console.log(i.date.substr(i.date.length - 4))
                monthTime.push(data.slice(-2));
                // monthData.push(i.cost/100)
                monthData.push((i.cost/(100*_this.state.totalArea)).toFixed(4));
            })
        }

        if (month.data.last) {
            month.data.last.forEach(function (i, k) {
                let data = i.date.toString();

                lastMonthTime.push(data.slice(-2));
                // lastMonthData.push(i.cost/100)
                lastMonthData.push((i.cost/(100*_this.state.totalArea)).toFixed(4));
            })
        }
        let standLine =new Array(monthData.length).fill(0) ;
        let standLine2 =new Array(lastMonthData.length).fill(0) ;
        let st = this.state.gov_d;
        // r.map(y => {y>st?standLine.push(0):standLine.push((st-y).toFixed(2))})(monthData);
        // r.map(y => {y>st?standLine2.push(0):standLine2.push((st-y).toFixed(2))})(lastMonthData);
        // 日平均没有意义了，这里都显示0
        this.setState({
            chartsTime1: monthTime,
            chartsTime2: lastMonthTime,
            charts1: monthData,
            charts2: lastMonthData,
            standLine: standLine,
            standLine2: standLine2,
            active: "month"
        })
    }
    async getDataYear() {
        let _this = this;
        await this.getInfo();
        const year = await Api.postV3('/costNew/monthlyComparison', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });
        console.log('Api' + Api.projectId);
        const lastYear = moment().add(-1,'y').format('YYYY');
        const thisYear = moment().format('YYYY');

        const lastYearAverage = await Api.postV3('/energyNew/usageAverageMonthly', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            sector: _this.state.sectorOrigin,
            from: lastYear+'02',
            to: lastYear+'12',
        }).then(r.prop('data'));
        const thisYearAverage = await Api.postV3('/energyNew/usageAverageMonthly', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            sector: _this.state.sectorOrigin,
            from: thisYear+'02',
            to: thisYear+'12',
        }).then(r.prop('data'));
        console.log(lastYearAverage);
        let results = [lastYearAverage,thisYearAverage];
        // 处理结果
        const myAdd = function (a, b ) {
            return (b.max -b.min)*b.comi + a;
        };

        let dealRes = [];

        r.map(x => {
            let i = 0;
            let result = {sum:0,acreage: 0,
                headCount: 0,};
            r.map(y=>{
            console.log((++i)+'月');
            let sumMonth = 0;
            let z = r.groupBy(r.prop('bid'))(y);
            // console.log(y);
            // console.log(z);
            for(let key in z){
                if(result[key]){

                }else{
                    console.log(z[key][0].project==Api.projectId);
                    console.log(z[key][0].project);
                    console.log(Api.projectId);
                    result[key]={sum:0,project:(z[key]&&z[key].length>0&&z[key][0].project)?z[key][0].project:'',
                        headCount:(z[key]&&z[key].length>0&&z[key][0].headCount)?(z[key][0].headCount):0,
                        acreage:(z[key]&&z[key].length>0&&z[key][0].acreage)?(z[key][0].acreage):0};
                    result.acreage+=result[key].acreage;
                    result.headCount+=result[key].headCount;
                }
                let sum = r.reduce(myAdd,0)(z[key]);
                result[key][i]=sum.toFixed(4);
                result[key].sum+=parseFloat(sum.toFixed(4));
                result.sum+=sum;
                sumMonth+=sum;
            }
            result[i]=sumMonth.toFixed(4);
        })(x)
            result.sum=result.sum.toFixed(4);
            dealRes.push(result);
        })(results);


        console.log(dealRes[0]);

        // 选择这个项目下的所有的建筑,暂时汇总到这个项目下
        let prjDealRes = [];
        let totalDealRes = [];
        let standLineDealRes = [];
        let colorDealRes = [];

        r.map(result=> {
            let prjRes = new Array(12).fill(0);
            let area = 0;
            for (let key in result) {
                if (result[key].project == Api.projectId) {
                    area += parseInt(result[key].acreage);
                    for (let key2 in result[key]) {
                        if (parseInt(key2) < 13) {
                            prjRes[parseInt(key2) - 1] += parseFloat(result[key][key2]);
                        }
                    }
                }
            }
            prjRes.project = Api.project;
            console.log('area: ' + area);
            area = (area == 0) ? 1 : area;
            prjRes = r.map(y => (y / area).toFixed(4))(prjRes);

            // 计算总的平均值
            let areaTotal = result.acreage;
            let totalArr = new Array(12).fill(0);
            console.log('areaTotal' + areaTotal);
            for (let key in result) {
                if (parseInt(key) < 13) {
                    totalArr[parseInt(key) - 1] = (parseFloat(result[key]) / areaTotal).toFixed(4);
                }
            }

            let standLine = [];
            let colorArr = [];
            prjRes.forEach(function (v, index) {
                v>totalArr[index]?standLine.push(0):(v==0?standLine.push(0):(standLine.push((totalArr[index]-v).toFixed(3))));
                colorArr.push(v>totalArr[index]?1:0);
            })
            prjDealRes.push(prjRes);
            totalDealRes.push(totalArr);
            standLineDealRes.push(standLine);
            colorDealRes.push(colorArr);

        })(dealRes)

        // let yearTime = [];
        // let lastYearTime = [];
        // let yearhData = [];
        // let lastYearData = [];
        // if (year.data.current) {
        //     year.data.current.forEach(function (i, k) {
        //         let data = i.date.toString()
        //
        //         yearTime.push(data.slice(-2))
        //         // yearhData.push(i.cost/100)
        //         yearhData.push((i.cost/(100*_this.state.totalArea)).toFixed(4))
        //     })
        // }
        //
        // if (year.data.last) {
        //     year.data.last.forEach(function (i, k) {
        //         let data = i.date.toString()
        //
        //         lastYearTime.push(data.slice(-2))
        //         // lastYearData.push(i.cost/100)
        //         lastYearData.push((i.cost/(100*_this.state.totalArea)).toFixed(4))
        //     })
        // }

        let standLine = [];
        let standLine2 = [];
        let st = this.state.gov_d;



        // r.map(y => {y>st?standLine.push(0):(y==0?standLine.push(0):(standLine.push((st-y).toFixed(3))))})(prjRes);
        // r.map(y => {y>st?standLine2.push(0):(y==0?standLine2.push(0):(standLine2.push((st-y).toFixed(3))))})(prjRes);




        console.log('res1,2:------');
        console.log(prjDealRes[0]);
        console.log(totalDealRes[0]);
        console.log(standLineDealRes[0]);


        this.setState({
            chartsTime1: this.state.chartsTime3,
            chartsTime2: this.state.chartsTime3,
            charts1: prjDealRes[0],
            charts2: prjDealRes[1],
            standLine: standLineDealRes[0],
            standLine2: standLineDealRes[1],
            colorDealRes: colorDealRes,
            totalDealRes: totalDealRes,
            active: "year"
        })
    }
    async getInfo() {
        if(this.state.sector!=''){
            console.log('already get the info, return ---------');
            return;
        }
        const projectTypeConverter = {
            SPORT: '体育建筑',
            CBD: '综合建筑',
            HOSPITAL: '医疗卫生建筑',
            HOTEL: '宾馆饭店建筑',
            MARKET: '商场建筑',
            OFFICE: '办公建筑',
            TEACH: '文化教育建筑',
            OTHER: '其它建筑',
        }
        const data = await Api.postV3('/infoForUsRegion/statistics2', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        }).then(r.prop('data'))
        console.log('getInfo end: --------------------');
        this.setState({
            headCount: data[0].headCount?data[0].headCount:1,
            totalArea: data[0].totalArea?data[0].totalArea:1,
            // sector: projectTypeConverter[data[1][0].sector]+((data[1][0].sector == 'GOV')?'(党政机关办公建筑)':'(商业办公建筑)'),
            sector: projectTypeConverter[data[1][0].sector],
            sectorOrigin: data[1][0].sector,
            buildType: (data[1][0].sector == 'GOV')?1:2,
        })
    }


    componentDidMount() {
        this.getData();
    }

    selected(params) {

        if (params === "year") {
            this.getDataYear()
        } else {
            this.getData()
        }
        // console.log(this.state)
    }
    selected2(params) {

        if (params === "bar") {
            this.setState({
                // chartsTime1: yearTime,
                // chartsTime2: lastYearTime,
                // charts1: yearhData,
                // charts2: lastYearData,
                active2: "bar"
            })
            // this.getDataYear()
        } else {
            // this.getData()
            this.setState({
                // chartsTime1: yearTime,
                // chartsTime2: lastYearTime,
                // charts1: yearhData,
                // charts2: lastYearData,
                active2: "line"
            })
        }
        // console.log(this.state)
    }
    render() {
        // console.log(this.state.chartsTime1);
        // console.log(this.state.chartsTime2);
        const { active, active2, headCount, totalArea,sector,gov_d ,gov_m,no_gov_d ,no_gov_m,buildType, colorDealRes} = this.state;
        // const {state: {loading, tableData, avgData,nameType, dataX,dataY,winddirdata, from, to, deviceType, select, total,cnt}} = useContext(context);
        const colorlist=['#feeecd', '#fce9b1', '#fcde9e',  '#fcd786', '#fad06c','#fcc852', '#fbbf3d',  '#fab921', '#fbb20e', '#fbb110', '#fb5b44' ];
        const colorlist2=['#feeecd', '#fc8793'];
        let index = (active == 'year')?((buildType == 1)?gov_m:no_gov_m):((buildType == 1)?gov_d:no_gov_d);
        console.log(index);
        //下面是增加两个标准线，方便查看实际的平均线
        let lines = [{
            type: 'line',
            smooth: true,
            data: this.state.totalDealRes[0],
            tooltip: {
                trigger: 'axis',
                formatter:function (params, ticket, callback) {

                    return '去年平均线'+params.data
                }
            },
            itemStyle: {
                normal: {
                    color: '#c2fb5e'
                }
            }
        },{
            type: 'line',
            smooth: true,
            data: this.state.totalDealRes[1],
            tooltip: {
            trigger: 'axis',
                formatter:function (params, ticket, callback) {
                return '今年平均线'+params.data
            }
        },
        itemStyle: {
            normal: {
                // 定制显示（按顺序）
                color: '#84e0fb'
            }
        }
    }];
        let series = [
            {
                type: active2,
                stack:1,
                // name: '今年',
                smooth: true,
                data: this.state.charts1,
                tooltip: {
                    trigger: 'axis',
                    formatter:function (params, ticket, callback) {

                        return '今年'+params.data
                    }
                },
                // toolbox: {
                //     show : false,
                // },
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: function (params) {
                            var data = params.data;
                            var sid = params.dataIndex;
                            return (active == 'year')?colorlist2[colorDealRes[0][sid]]:colorlist2[0];


                        }
                    }
                }
            }, {
                type: active2,
                stack:1,
                // name: '距离标准',
                smooth: true,
                data: this.state.standLine,
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: '#fbedb5',
                    }
                }
            },{
                type: active2,
                stack:2,
                // name: '去年',
                smooth: true,
                data: this.state.charts2,
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: function (params) {
                            // return params.data<gov_d?'#fb9d7d':'#fb5b44';
                            if(active === 'year'){
                                return (active == 'year')?colorlist2[colorDealRes[1][params.dataIndex]]:colorlist2[0];

                            }else{
                                return '#fb5b44';

                            }
                        }
                    }
                },
                // data: this.state.charts1,
            }, {
                type: active2,
                stack:2,
                // name: '距离标准2',
                smooth: true,
                data: this.state.standLine2,
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: '#fbedb5',
                    }
                }
            }];

        if(active == 'year'){
            series.push(...lines);
        }
        const getOption = {
            color: ["#87E7FF", "#D3D7E3"],
            grid: { left: '3', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis',
            formatter:function (params, ticket, callback,datas) {
                if(active === 'month'){
                    let now = params.length>0?('上月'+params[0].data):'';
                    let old = params.length>2?('本月'+params[2].data):'';
                    return now+'<br/>'+old;
                }
                // console.log('params:year');
                // console.log(params);
                let thisYear = params.length>0?('去年'+params[0].data):'';
                // let lastYear = params.length>1?('去年少于平均'+params[1].data):'';
                let thisYearGap = params.length>2?('今年'+params[2].data):'';
                // let lastYearGap = params.length>3?('今年少于平均'+params[3].data):'';
                let lineThisYear = params.length>4?('去年平均'+params[4].data):'';
                let lineLastYear = params.length>5?('今年平均'+params[5].data):'';
                // return thisYear+'<br/>'+lastYear+'<br/>'+thisYearGap+'<br/>'+lastYearGap+'<br/>'+lineThisYear+'<br/>'+lineLastYear;
                return thisYear+'<br/>'+thisYearGap+'<br/>'+lineThisYear+'<br/>'+lineLastYear;

            }
                },
            // toolbox: {
            //     y : -30,
            //     show : false,
            //     // feature : {
            //     //     mark : '辅助线开关',
            //     //     markUndo : '删除辅助线',
            //     //     markClear : '清空辅助线',
            //     //     dataZoom : '区域缩放',
            //     //     dataZoomReset : '区域缩放后退',
            //     //     dataView : '数据视图',
            //     //     lineChart : '折线图切换',
            //     //     barChart : '柱形图切换',
            //     //     restore : '还原',
            //     //     saveAsImage : '保存为图片'
            //     // }
            //     },
            legend: {
            },
            xAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                data: r.isEmpty(this.state.chartsTime2) ? this.state.chartsTime1 : this.state.chartsTime2
            },
            yAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {       //y轴刻度线
                    show: false
                },
            },
            // 不用这个，因为颜色覆盖其他颜色
            // visualMap: {
            //     top: 10,
            //     right: 10,
            //     precision:2,
            //     pieces: [{
            //         gt: 0,
            //         lte: index,
            //         color: '#096'
            //     },{
            //         gt: index,
            //         color: '#7e0023'
            //     }],
            //     outOfRange: {
            //         color: '#999'
            //     }
            // },
            series: series
        }

        return (
            <div className='indexMidRight' >
                <div className='indexMidRightHead' style = {{ padding:'5px'}}>

                        <span>类型：{sector}</span>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;面积：{totalArea}</span><br/>
                        <span style = {{ float:'left'}}>人数：{headCount}</span>
                        <span style = {{ marginLeft:'420px'}}>能耗阈值：{index}</span>
                        {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                    <div>
                        <i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>
                        {/*因为年度统计会用当前项目的类型查找该类型平均值，但是区域用户是 其他类型，没有数据，所以这里就直接隐藏了*/}
                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                    </div>
                    <br/>
                    <div style = {{ float:'left'}}>
                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${this.state.active2 === "line" ? "active" : ""} hover`} onClick={this.selected2.bind(this, "line")}><img src={Img_line} alt="智慧云能源" /></i>
                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${this.state.active2 === "bar" ? "active" : ""} hover`} onClick={this.selected2.bind(this, "bar")}><img src={Img_bar} alt="智慧云能源" /></i>
                    </div>
                </div>
                <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
            </div>
        )
    }
}

export default IndexMidRight;