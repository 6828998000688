import React from 'react';
import { Modal, Tabs, Button } from 'antd';

import Information from './information/information';
import Drive from './drive/drive';

import Api from 'src/api'
import './management.scss'

const TabPane = Tabs.TabPane;



class Management extends React.Component {
    state = {
        visible: false,
        confirmLoading: false,
        record: this.props.record,
        information: '',
        drive: ''
    }

    showModal = () => {
        this.setState({
            visible: true,
            select: ''
        });
    }

    async pushInformation() {
        const { name, device_id, id, updatecyc, multipoint, principal, is_del } = this.state.information;
        await Api.post('/user/equimpentupdate', {
            name: name,
            device_id: device_id,
            id: id,
            updatecyc: updatecyc,
            multipoint: multipoint,
            principal: principal ? "0" : "1",
            is_del: is_del ? "0" : "1"
        })
        // console.log(pushData)
    }

    handleOk = () => {
        const { name, device_id, id, updatecyc, multipoint, principal, is_del } = this.state.information;
        const $this = this;
        this.setState({
            confirmLoading: true,
        });
        Api.post('/user/equimpentupdate', {
            name: name,
            device_id: device_id,
            id: id,
            updatecyc: updatecyc,
            multipoint: multipoint,
            principal: principal ? "0" : "1",
            is_del: is_del ? "0" : "1"
        }).then(function (result) {
            $this.setState({
                visible: false,
                confirmLoading: false,
            })
            $this.props.isUpData()
        }).catch(function (reason) {
            $this.setState({
                visible: false,
                confirmLoading: false,
            })
        })
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }


    handleChange(value) {
        this.setState({
            drive: value
        })
    }
    informationChange(arr) {
        this.setState({
            information: arr
        })
    }

    render() {
        const { visible, confirmLoading } = this.state;
        return (
            <div className='management'>
                <Button type="primary" onClick={this.showModal}>
                    管理
                </Button>

                <Modal
                    title="Title"
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                    destroyOnClose={true}
                >

                    <Tabs type="card">
                        <TabPane tab="信息" key="1">
                            <Information record={this.props.record} informationChange={this.informationChange.bind(this)} />
                        </TabPane>
                        <TabPane tab="驱动" key="2" >
                            <Drive handleChange={this.handleChange} />
                        </TabPane>
                    </Tabs>
                </Modal>
            </div>
        );
    }
}

export default Management;