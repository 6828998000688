import React from 'react';

import moment from 'moment'
import { from } from 'rxjs'
import DepartmentLeft
  from 'src/modules/energy/department/departmentLeft/departmentLeft';
import DepartmentRight
  from 'src/modules/energy/department/departmentRight/departmentRight';
import withStates from 'src/context/withStates';
import Api from 'api'
import * as r from 'ramda';
import './department.scss'

const ROOT_NODE = '0';

const Department = () => (
  <div className='department'>
    <DepartmentLeft />
    <DepartmentRight />
  </div>
)

const treeViewData = async () => {
  const { data: { result = [] } } = await Api.post('/eca/info', {
    projectid: Api.projectId,
    type: 'BYAPART',
  });

  const toChildren = r.compose(
    r.map(({ id: key, title, key: did, child }) =>
      ({
        title, key, did,

        // Only one level of nodes is required
        // children: r.isNil(child) ? [] : toChildren(child),
      })),
    r.values)
  const nodes = r.map(r.prop('id'))(result)

  return {
    searchIsShow: true,
    loading: false,
    node: ROOT_NODE,
    nodes,
    node2Name: {
      ...r.mergeAll(r.map(
        r.pipe(r.pickAll(['id', 'title']), ({ id, title }) => ({ [id]: title })))(
          result)), 0: '所有部门'
    },
    tree:
      [
        {
          title: Api.projectName,
          key: ROOT_NODE,
          children:
            toChildren(
              r.filter(
                r.where({
                  type: r.equals('BYAPART'),
                  parent: r.equals(0),
                }),
              )(result)),
        },
      ],
    details: result
  }
}

export const loadRatio = async ({ nodes, timetype = 'DAY', ...rest }) => {
  const { data: { result } } = await Api.post('/eca/analysis', {
    nodes,
    projectid: Api.projectId,
    timefrom: moment(),
    timeto: moment(),
    timetype,
  })
  return {
    ...rest,
    nodes,
    timetype,
    loading: false,
    departmentPie: r.map(({ title: name, data = {} }) => ({
      name,
      value: r.pathOr(0, ['kgcesum'])(data),
    }))(result),
  }
}
///
const translateTimeline = v => {
  const valueMap = {
    recent31Days: r.map(
      (idx) => moment().subtract(idx, 'days').format('MM-DD'))(
        r.reverse(r.range(1, 32))),
    daily: r.map(i => `0${i}`.slice(-2) + ':00')(r.range(0, 24)),
    monthly: r.map(i => `0${i}`.slice(-2))(
      r.range(1, moment().daysInMonth() + 1)),
    annually: r.map(i => `0${i}`.slice(-2))(r.range(1, 13)),
  }
  return r.propOr(v)(v)(valueMap)
}

function dataUrl(project, node, usagePeriod, usageDate) {
  const timely = r.prop(usagePeriod)(
    { daily: 'hourly', monthly: 'daily', annually: 'monthly' })
  return `/api/v3/project/${project}/${timely}Usage/${node}/${usagePeriod}${usageDate ?
    '?date=' + moment(usageDate, formatMap(usagePeriod).from)
      .format(formatMap(usagePeriod).to) :
    ''}`
}

const formatMap = x => r.propOr({ from: 'YYYY', to: 'YYYY' })(x)({
  monthly: { from: 'YYYY-MM', to: 'YYYYMM' },
  daily: { from: 'YYYY-MM-DD', to: 'YYYYMMDD' }
})

const deviceUsageUrl = (
  project, node, usagePeriod,
  meterType) => `/api/v3/project/${project}/deviceUsage/${node}/${usagePeriod}?type=${meterType}`

const currentLiteral = r.identity
const lastLiteral = (usageDate, usagePeriod) => {
  const date = moment(usageDate, formatMap(usagePeriod).from)
  const lastDate = r.propOr(usageDate)(usagePeriod)({
    daily: date.clone().subtract(1, 'day'),
    monthly: date.clone().subtract(1, 'year'),
    annually: date.clone().subtract(1, 'year'),
  })
  return lastDate.format(formatMap(usagePeriod).from)
}

async function nodeDeviceUsage(node, nodes, node2Name, usagePeriod, meterType) {
  return Api.get(deviceUsageUrl(Api.projectId, node, usagePeriod, meterType))
}

////
const nodeUsage = async ({ node, nodes, node2Name, usagePeriod = 'daily', usageDate = moment().format(formatMap(usagePeriod).from), departmentOption = 'kgce', meterType = 'ELECTRICITYMETER', details }) => {
  console.log(
    `loading for: node=${node}, nodes=${nodes}, ${usagePeriod}, usageDate: ${usageDate}, ${departmentOption}`)
  const [allData, { data: deviceUsage }] = await Promise.all([
    Api.get(dataUrl(Api.projectId, node, usagePeriod, usageDate, departmentOption)),
    nodeDeviceUsage(node, nodes, node2Name, usagePeriod, meterType)])

  const currentPeriod = departmentOption => ([id, data]) => {
    const option = departmentOption === 'kgce' ? r.prop('current') : r.path(['cost', 'current'])
    return {
      data: r.map(x => Number(x))(r.values(option(data))),
      stack: 2,
      type: 'bar',
      name: r.propOr(`部门${id}`)(id)(node2Name) + currentLiteral(usageDate, usagePeriod),
      barWidth: 15,
      label: {
        normal: {
          show: true,
          position: 'top',
          distance: 10,
          align: 'center',
          verticalAlign: 'middle',
        },
      }
    }
  }
  const previousPeriod = departmentOption => ([id, data]) => {
    const option = departmentOption === 'kgce' ? r.prop('last') : r.path(['cost', 'last'])
    return {
      data: r.map(x => Number(x))(r.values(option(data))),
      stack: 1,
      type: 'bar',
      name: r.propOr(`部门${id}`)(id)(node2Name) + lastLiteral(usageDate, usagePeriod),
      barWidth: 15,
      label: {
        normal: {
          show: true,
          position: 'top',
          distance: 10,
          align: 'center',
          verticalAlign: 'middle',
        },
      }
    }
  }
  const allUsage = r.zip([node])([r.prop('data')(allData)])
  // console.log(allUsage);

  const nodeUsage = {
    time: translateTimeline(usagePeriod),
    data: r.concat(r.map(currentPeriod(departmentOption))(allUsage),
      r.map(previousPeriod(departmentOption))(allUsage)),
  }
  const evaluateTheResults = (v) => {
    if (isNaN(v) || v === Infinity || v === 'Infinity') {
      return '0.00'
    } else {
      return v.toFixed(2)
    }
  }
  // console.log(nodeUsage);
  // console.log(details);
  const { PERSON, AREA } = r.reduce(r.mergeWith(r.add))({})(r.map(r.pathOr({ PERSON: 0, AREA: 0 })(['formula', 'variables']))(details))
  const headcount = r.isEmpty(PERSON) ? '0.00' : PERSON;
  const area = r.isEmpty(AREA) ? '0.00' : AREA;
  const [[, { current, last, cost, type }]] = allUsage
  const currentTotal = r.sum(r.values(current));
  const lastTotal = r.sum(r.values(last));
  const ele = r.pathOr(0)(['current', 'ELECTRICITYMETER'])(type)
  const water = r.pathOr(0)(['current', 'WATER'])(type)
  const eleLast = r.pathOr(0)(['last', 'ELECTRICITYMETER'])(type)
  const waterLast = r.pathOr(0)(['last', 'WATER'])(type)
  const costTotal = r.sum(r.values(r.propOr({})('current')(cost)));
  const costLast = r.sum(r.values(r.propOr({})('last')(cost)));
  const thisNodeUsage = {
    totalEnergy: currentTotal.toFixed(2),
    totalEnergyYoY: r.includes(usagePeriod)(['annually', 'daily']) ? evaluateTheResults((currentTotal - lastTotal) / lastTotal) : '0.00',
    totalEnergyMoM: r.includes(usagePeriod)(['annually', 'daily']) ? '0.00' : evaluateTheResults((currentTotal - lastTotal) / lastTotal),
    ele: Number(ele).toFixed(2),
    eleYoY: r.includes(usagePeriod)(['annually', 'daily']) ? evaluateTheResults((ele - eleLast) / eleLast) : '0.00',
    eleMoM: r.includes(usagePeriod)(['annually', 'daily']) ? '0.00' : evaluateTheResults((ele - eleLast) / eleLast),
    water: Number(water).toFixed(2),
    waterYoY: r.includes(usagePeriod)(['annually', 'daily']) ? evaluateTheResults((water - waterLast) / waterLast) : '0.00',
    waterMoM: r.includes(usagePeriod)(['annually', 'daily']) ? '0.00' : evaluateTheResults((water - waterLast) / waterLast),
    cost: costTotal.toFixed(2),
    costYoY: r.includes(usagePeriod)(['annually', 'daily']) ? evaluateTheResults((costTotal - costLast) / costLast) : '0.00',
    costMoM: r.includes(usagePeriod)(['annually', 'daily']) ? '0.00' : evaluateTheResults((costTotal - costLast) / costLast),
    headcount,
    area,
    perCapitaEnergy: evaluateTheResults(currentTotal / headcount),
    perCapitaArea: evaluateTheResults(area / headcount),
    perCapitaWater: evaluateTheResults(water / headcount),
    perCapitaEle: evaluateTheResults(ele / headcount),
  }
  return {
    loading: false,
    nodeUsage,
    thisNodeUsage,
    nodeRank: { data: r.propOr([])('current')(deviceUsage) },
    usagePeriod,
    usageDate
  }
}

const loadAll = (state) => {
  const input =
    treeViewData(state)
      .then(state => Promise.all([loadRatio(state), nodeUsage(state)])
        .then(r.mergeAll))
      .then(r.mergeAll)
  return from(input)
}

export const loadNodeUsage = (state) => {
  return from(nodeUsage(state)
    .then(res => ({ ...state, ...res })))
}
const initState = {
  loading: true,
  node: ROOT_NODE, //selected node on the tree (for v3 version api)
  nodes: [], //all nodes on the tree (for old version api)
  nodeUsage: { time: [], data: [] },
  tree: [],
  thisNodeUsage: [],
  nodeRank: [],
  departmentPie: [],
  usagePeriod: 'daily',
  kgcePeriod: 'annually',
  ratioPeriod: 'daily',
  departmentOption: 'kgce',
  meterType: 'ELECTRICITYMETER'
}

export const { context, Component } = withStates(
  Department,
  initState,
  loadAll,
)

export default Component