import React, {useContext} from 'react';
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment'
import {Spin} from 'antd'
import {from, of} from 'rxjs'

import './report.scss';
import Api from 'api';

import Tree from 'src/components/treeView/tree';

import DayReport from './dayReport/dayReport';
import MonthReport from './monthReport/monthReport';
import IntervalReport from './intervalReport/intervalReport';
import IntervalReportRegion from './intervalReportRegion/intervalReportRegion';

import withStates from 'src/context/withStates';
import cookie from "react-cookies";
import {setProjectName} from "../../context/NavItem";

const ROOT_NODE = '0';
//建筑类型分类·
const projectTypeConverter = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}

const ReportRegion = () => {
    const {state, dispatch} = useContext(context);
    const onSelect = (node) => (event) => {
        console.log(`node ${r.head(event)} selected`)

        return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
            (state) => loadNodeUsage({...state, loading: true, node: r.head(event)})
    }
    return (
        <div className="repor">

            <div className="reporLeft">
                <div className="reporLeftTit">
                    区域列表
                </div>
                {
                    r.isEmpty(state.tree) ?
                        <Spin/> :
                        <Tree data={state.tree}
                              defaultExpandedKeys={[ROOT_NODE]}
                              onSelect={e => {
                                  dispatch(state => of(r.assoc('loading', true)(state)))
                                  dispatch(onSelect(state.node)(e))
                              }}/>}
            </div>

            <div className="reporRight">
                {/* <Router> */}
                <div>
                    <nav className='reportNav'>
                        <ul>
                            {/* <li>
                                    <NavLink activeClassName='active' to='/Report/DayReport'><span>日报表</span></NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName='active' to='/Report/MonthReport'><span>月报表</span></NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName='active' to='/Report/IntervalReport'><span>区间报表</span></NavLink>
                                </li> */}
                        </ul>
                        <div>

                        </div>
                    </nav>
                    <IntervalReportRegion/>

                    {/* <Route path="/" exact component={DayReport} />
                        <Route path="/Report/DayReport" exact component={DayReport} />
                        <Route path="/Report/MonthReport" component={MonthReport} />
                        <Route path="/Report/IntervalReport" component={IntervalReport} /> */}
                </div>
                {/* </Router> */}

            </div>

        </div>

    )
}

const treeViewData = async () => {
    const {data: {result = []}} = await Api.post('/customer/infoRegion', {
        project: Api.projectId,
        region:cookie.load('region')
    });
    const toChildren = r.compose(
        r.map(({id: key, title, key: did, child}) =>
            ({
                title, key, did,
                children: r.isNil(child) ? [] : toChildren(child),
            })),
        r.values)
    return {
        searchIsShow: true,
        loading: false,
        node: ROOT_NODE,
        tree: [
            {
                title: Api.projectName,
                key: ROOT_NODE,
                children: toChildren(
                    r.filter(
                        r.where({
                            type: r.equals('NODE'),
                            parent: r.equals(0),
                        }),
                    )(result)),
            },
        ],
    }
}

const deviceNameMap = {
    ac: {name: 'ac', value: 'ac'},
    electricity: {name: '电能', value: 'electricity'},
    gas: {name: '燃气', value: 'gas'},
    water: {name: '水耗', value: 'water'},
}

const translateType = v => {
    return r.propOr(v)(v)(deviceNameMap)
}

const nodeUsage = async ({node, from, to, deviceType, search}) => {
    console.log('node');
    console.log(node);
    const {data: dayReport} = await Api.get(`/api/v3/energyNew/usageRatioMonthlyRegion?project=${Api.projectId}&region=${cookie.load('region')}&nodeId=${node}&from=${from}&to=${to}`)
    // 获取项目的人数和面积数量
    const projectInfos = await Api.getV3('/projectTotal', {
        project: Api.projectId
    });
    const projectRegion = await Api.get('/api/v3/projectRegion')
        .then(r.propOr([], 'data'))
    const projectNames = {};
    r.map(y=>projectNames[y['_id']]=y['title'])(projectRegion);


    let projectLocatTypeData = projectInfos.data;
    let listData = projectLocatTypeData[1]; // 类型数据，医院之类
    let projectHeadAreaData = projectLocatTypeData[0]; // 人数和面积等数据

    // 建筑类型数据 start ---------------------
    // 20200305 不用这里遍历，如果只是加面积和人数的数据，直接从projectHeadAreaData 取，只是这里要把OFFICE这种类型加入到 projectHeadAreaData 中，方便后面取值


    // 把projectid保存进projectTypeList中
    //同时把type的面积和人数也统计一下
    //listData[i] 就是{OFFICE:[4,[{112323:3},{},{},{}]]}
    //
    //
    for(let i =0;i<listData.length;i++){
        for(let key in listData[i]){// key 样式 HOSPITAL 之类，listData[i] 就是{OFFICE:[4,[{112323:3},{},{},{}]]}
            //y 样式 {'a':1} y就是 {1231232:2} 前面是projectid ，后面是数量
            r.map(y=> {

                for(let key2 in y){ // key2 是projectid,  23423 之类
                    if(y[key2]!=0){ // 当没有人数和面积的记录的时候，可能是脏数据，之前没有记录的
                        projectHeadAreaData[key2].push(projectTypeConverter[key]); //projectHeadAreaData[key2] 是 [1,null,"10000"]  建筑数量，人数，面积，后面插入类型
                        projectHeadAreaData[key2].push(projectNames[key2]); //补充名字
                       }

                }
            })((listData[i][key])[1]);  //listData[i][key] 就是  [4,[{112323:3},{},{},{}]]
        //    (listData[i][key])[1] 就是 [{112323:3},{},{},{}]
        }

    }
    console.log('projectHeadAreaData');
    console.log(projectHeadAreaData);

    // 建筑类型数据 end ------------------------


    //获取项目的title等信息，方便表格的数据填写
    const {data: {result = []}} = await Api.post('/customer/infoRegion', {
        project: Api.projectId,
        region:cookie.load('region')
    });
    const isFn = n => {
        const {sensor, title} = n;
        const re = new RegExp(search)
        return r.test(re)(sensor) || r.test(re)(title)
    }
    console.log(dayReport)
    // const all = r.filter(isFn)(translateDeviceType(deviceType, dayReport))

    // const tableData = dayReport.map(({water, electricity, ac, gas}, i) => ({
    //     water: water,
    //     electricity: electricity,
    //     ac: ac,
    //     gas: gas,
    //     project: i,
    // }))
    const tableData = [];
    for(var i in dayReport){
        if(i == 'total')
            continue;
        dayReport[i]['project'] = i;
        if(projectHeadAreaData[i]){
            dayReport[i]['headCount'] = projectHeadAreaData[i][1]?projectHeadAreaData[i][1]:0;
            dayReport[i]['totalArea'] = projectHeadAreaData[i][2]?projectHeadAreaData[i][2]:0;
            dayReport[i]['type'] = projectHeadAreaData[i][3];
            dayReport[i]['projectName'] = projectHeadAreaData[i][4];
        }
        tableData.push(dayReport[i]);
    }
    console.log('tableData')
    console.log(tableData)
    // const totalUsage = r.compose(r.sum, r.flatten, r.map(v => r.prop('usage')(v)))
    // (r.filter(n => !r.equals('-', r.prop('usage')(n)))(all))
    //
    // const totalCost = r.compose(r.sum, r.flatten, r.map(v => r.prop('cost')(v)))
    // (r.filter(n => !r.equals('-', r.prop('cost')(n)))(all))

    return {
        loading: false,
        node,
        select: r.map(([k, v]) => (translateType(k)))(r.toPairs(dayReport)),
        tableData,
        from,
        to,
        deviceType,
        // total: {
        //     usage: totalUsage.toFixed(2),
        //     cost: totalCost.toFixed(2),
        // }
        total: {
            usage: (dayReport.total).toFixed(2),
            cost: '',
        }
    }
}

const loadReport = (state) => {
    const input = Promise.all(
        [treeViewData(state), nodeUsage(state)])
        .then(r.mergeAll)
    return from(input)
}

export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))
}
const initState = {
    loading: true,
    node: ROOT_NODE,
    tree: [],
    reportType: 'day',
    from: moment().subtract(1, 'month').format('YYYYMMDD'),
    to: moment().format('YYYYMMDD'),
    tableData: [],
    deviceType: 'all',
    total: {
        usage: 0,
        cost: 0,
    }
}

export const {context, Component} = withStates(
    ReportRegion,
    initState,
    loadReport,//这里包含有左边树的数据和其他数据
)

export default Component
