import React, {useContext, useState} from 'react';
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment'
import {Spin} from 'antd'
import {from, of} from 'rxjs'

import './noise.scss';
import Api from 'api';

import Tree from 'src/components/treeView/tree';


import NoiseInterval from './indexMid/noiseInterval';

import withStates from 'src/context/withStates';

const ROOT_NODE = '0';
//建筑类型分类·
const projectTypeConverter = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}

const dateFormat = function (date, format) {
    date = new Date(date);
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour+8小时
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

    return format;
}

const Noise = () => {
    const {state, dispatch} = useContext(context);
    const onSelect = (node) => (event) => {
        console.log(`node ${r.head(event)} selected`)

        return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
            (state) => loadNodeUsage({...state, loading: true, node: r.head(event)})
    }
    return (
        <div className="reportNoise">
            <div className="reporLeft">
                <div className="reporLeftTit">
                    噪声建筑列表
                </div>
                {
                    r.isEmpty(state.tree) ?
                        <Spin/> :
                        <Tree data={state.tree}
                              defaultExpandedKeys={[ROOT_NODE]}
                              onSelect={e => {
                                  dispatch(state => of(r.assoc('loading', true)(state)))
                                  dispatch(onSelect(state.node)(e))
                              }}/>}
            </div>
            <div className="reporRight">
                {/* <Router> */}
                <div>
                    <nav className='reportNav'>
                        <ul>

                        </ul>
                        <div>

                        </div>
                    </nav>
                    <NoiseInterval />

                </div>
                {/* </Router> */}

            </div>

        </div>

    )
}


const deviceNameMap = {
    temp: {name: 'temp', value: 'temp'},
    electricity: {name: '电能', value: 'electricity'},
    gas: {name: '燃气', value: 'gas'},
    water: {name: '水耗', value: 'water'},
}

const selectType = [
    {name: '温度', value: 'temp'},
    {name: '湿度', value: 'humi'},
    {name: '露点', value: 'dew'},
    {name: '大气压', value: 'atmos'},
    {name: '辐射', value: 'radia'},
    {name: '风速', value: 'windspeed'},
    {name: '风向', value: 'winddir'}
];
var dirs = ['N','NNE','NE','ENE','E','ESE','SE','SSE','S','SSW','SW','WSW','W','WNW','NW','NNW'];

const translateType = v => {
    return r.propOr(v)(v)(deviceNameMap)
}

const treeViewData = async () => {
    const data = await Api.postV3('/noise/info', {
        project: Api.projectId,
    });

    const result =data.data?data.data:[];
    const toChildren = r.compose(
        r.map(({id: key, title, key: did, child}) =>
            ({
                title, key, did,
                children: r.isNil(child) ? [] : toChildren(child),
            })),
        r.values)
    return {
        searchIsShow: true,
        loading: false,
        node: ROOT_NODE,
        tree: [
            {
                title: Api.projectName,
                key: ROOT_NODE,
                children: toChildren(
                    r.filter(
                        r.where({
                            type: r.equals('NODE'),
                            parent: r.equals(0),
                        }),
                    )(result)),
            },
        ],
    }
}

const nodeUsage = async ({node, from, to,  search,page,size}) => {

    console.log('nodeUsage');
    console.log(page);
    const query = {from:from,to:to,project:'',sector:'123',node:node,page:page,size:size};
    // let tableData=[];

    const {data: dayReport} = await Api.postV3('/noise/data',query);

    if(dayReport.error){
        alert(dayReport.error);
        return;
    }
    let tableData=[];
    r.map(y => (y.building?tableData.push({building:y.building,
        sn:y.sn,
        pm2d5:y.pm2d5,
        pm10:y.pm10,
        co2:y.co2,
        tvoc:y.tvoc,
        tvoc2:y.tvoc2,
        hcho:y.hcho,
        hcho2:y.hcho2,
        temp:y.temp,
        humi:y.humi,
        addtime:y.addtime,
        projectId:y.projectId,
        noise:y.noise,
        windpower:y.windpower,
        windspeed:y.windspeed,
        winddir:y.winddir,
        tsp:y.tsp,
    }):''))(dayReport);

    const isFn = n => {
        const {sensor, title} = n;
        const re = new RegExp(search)
        return r.test(re)(sensor) || r.test(re)(title)
    }
    // console.log(dayReport)
    //增加一个累计平均值


    console.log('dayReport.page,');
    console.log(dayReport.page);
    return {
        loading: false,
        node,
        select: selectType,
        tableData:tableData,
        from,
        to,
        // page:dayReport.page,
        // size:dayReport.size,
        // nums:dayReport.count[0].total
        // deviceType,
        // nameType:transDataTypeForAvg2(deviceType),
        // total: {
        //     usage: totalUsage.toFixed(2),
        //     cost: totalCost.toFixed(2),
        // }
    }
}

const loadReport = (state) => {
    const input = Promise.all(
        [treeViewData(state), nodeUsage(state)])
        .then(r.mergeAll)
    return from(input)
}

export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    console.log('loadNodeUsage');
    console.log(state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))


}
const initState = {
    loading: true,
    node: ROOT_NODE,
    tree: [],
    reportType: 'day',
    nameType: '温度',
    from: moment().subtract(1, 'month').format('YYYYMMDD'),
    to: moment().format('YYYYMMDD'),
    // from: '20200101',
    // to:'20200105',
    select: selectType,
    tableData: [],
    winddirdata: [],
    avgData: [],
    tableData: [],
    dataX: [],
    dataY: [],
    cnt:0,
    nums:0,//总条数
    page:1,//页面index
    size:10,// 页面大小
    total: {
        usage: 0,
        cost: 0,
    }
}

export const {context, Component} = withStates(
    Noise,
    initState,
    loadReport,//这里包含有左边树的数据和其他数据
)

export default Component
