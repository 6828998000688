import React from 'react';
import {
    Form, Input, Checkbox,
} from 'antd';
import './information.scss';
// const { Option } = Select;
// const AutoCompleteOption = AutoComplete.Option;

const Information = Form.create({ name: 'InformationFrom' })(


    class InformationFrom extends React.Component {


        constructor(props) {
            super(props);

            this.state = {
                confirmDirty: false,
                autoCompleteResult: [],
                name: this.props.record.name,
                device_id: this.props.record.device_id,
                instrumentId: this.props.record.instrumentId,
                updatecyc: this.props.record.updatecyc,
                multipoint: this.props.record.multipoint,
                principal: this.props.record.principal,
                is_del: this.props.record.is_del,
                id: this.props.record.id
            };
        }


        componentDidMount() {
            const { device_id, instrumentId, id, name, updatecyc, principal, multipoint, is_del } = this.props.record
            this.props.form.setFieldsValue({
                // note: `Hi, ${value === 'male' ? 'man' : 'lady'}!`,
                device_id: device_id,
                instrumentId: instrumentId,
                id: id,
                name: name,
                updatecyc: updatecyc,
                multipoint: multipoint,
                principal: principal === 0 ? true : false,
                is_del: is_del === 0 ? true : false
            })
        }
        name = (e) => {
            this.setState({
                name: e.target.value
            })
            this.handleSubmit()
        }
        device_id = (e) => {
            this.setState({
                device_id: e.target.value
            })
            this.handleSubmit()
        }

        instrumentId = (e) => {
            this.setState({
                instrumentId: e.target.value
            })
            this.handleSubmit()
        }
        updatecyc = (e) => {
            this.setState({
                updatecyc: e.target.value
            })
            this.handleSubmit()
        }
        multipoint = (e) => {
            this.setState({
                multipoint: e.target.value
            })
            this.handleSubmit()
        }

        id = (e) => {
            this.setState({ id: e.target.value });
            this.handleSubmit()
        }
        updatecyc = (e) => {
            this.setState({ updatecyc: e.target.value });
            this.handleSubmit()
        }
        multipoint = (e) => {
            this.setState({ multipoint: e.target.value });
            this.handleSubmit()
        }
        principal = (e) => {
            this.setState({ principal: e.target.checked ? 1 : 0 });
            this.handleSubmit()
        }
        is_del = (e) => {
            this.setState({ is_del: e.target.checked ? 1 : 0 });
            this.handleSubmit()
        }

        handleSubmit = (e) => {
            const fromData = this.props.form.getFieldsValue()
            this.props.informationChange(fromData)
        }

        render() {
            const { getFieldDecorator } = this.props.form;

            const formItemLayout = {
                labelCol: {
                    xs: { span: 24 },
                    sm: { span: 4 },
                },
                wrapperCol: {
                    xs: { span: 24 },
                    sm: { span: 20 },
                },
            };
            const formTailLayout = {
                wrapperCol: { span: 20, offset: 4 },
            };

            return (
                <Form onChange={this.handleSubmit} className='components_form'>
                    <Form.Item
                        {...formItemLayout}
                        label="设备标识" >
                        {getFieldDecorator('device_id')(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="仪表ID"
                    >
                        {getFieldDecorator('instrumentId')(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="仪表序号"
                    >
                        {getFieldDecorator('id')(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="设备名称"
                    >
                        {getFieldDecorator('name')(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="更新频率"
                    >
                        {getFieldDecorator('updatecyc')(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="倍率"
                    >
                        {getFieldDecorator('multipoint')(
                            <Input />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formTailLayout}
                    >
                        {getFieldDecorator('principal')(
                            <Checkbox defaultChecked={this.state.principal === 0 ? true : false} onChange={this.principal}>设为总表</Checkbox>
                        )}
                        {getFieldDecorator('is_del')(
                            <Checkbox defaultChecked={this.state.is_del === 0 ? true : false} onChange={this.is_del}>屏蔽仪表</Checkbox>
                        )}
                    </Form.Item>

                </Form>
            );
        }
    });
export default Information;