import React from 'react';
import {DatePicker, Input, Button, Select, Radio, message} from 'antd';
import moment from 'moment';
import './tableTop.scss';
import * as r from 'ramda';
import {of} from 'rxjs';
import Api from 'api';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';
import Excel from 'src/components/excel/excel';

const Search = Input.Search;
const Option = Select.Option
const {MonthPicker, RangePicker} = DatePicker;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

class TableTop extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            deviceType: this.props.props.deviceType,
            from: this.props.props.from,
            to: this.props.props.to
        };
    }


    componentDidMount() {
        // this.getData()
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }
    onTimeInterval = (date, dateString) => {
        this.setState({
            from: moment(date[0]).format('YYYYMMDD'),
            to: moment(date[1]).format('YYYYMMDD')
        })

    }
    radioGroupOnChange = (e) => {
        console.log(`radio checked:${e.target.value}`);
    }
    selectHandleChange = (value) => {
        console.log(`selected ${value}`);
        this.setState({
            deviceType: value
        })
    }
    submit = () => {
        const {deviceType, from, to} = this.state;

        this.props.props.handleScaleChange && this.props.props.handleScaleChange({
            deviceType,
            from,
            to
        })
    }
    onSearch = value => {
        const {loadUp, dispatch} = this.props;
        dispatch(state => of({...state, loading: true, search: value}))
        return dispatch(state => loadUp({
            ...state, loading: true, search: value
        }))
    }

    exportFn = () => {
        console.log(this.props)
        if (this.props.api === 'Excel') {
            Excel(this.props.columns, this.props.tableData);

            return
        }
        Api.post(this.props.api, {
            projectid: Api.projectId
        }).then(res => {
            const dataPath = r.pathOr('')(['data', 'result', 'fn'])(res)
            if (!dataPath) {
                message.error('请求失败')
                return
            }
            const tempLink = document.createElement('a');
            tempLink.style.display = 'none';
            tempLink.href = `${process.env.REACT_APP_API_HOST}/docs/${dataPath}`;
            tempLink.setAttribute('download', `${Api.projectName}设备管理表.xlsx`);

            if (typeof tempLink.download === 'undefined') {
                tempLink.setAttribute('target', '_blank');
            }

            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
        })
    }

    render() {
        const {result, liData, from, to, select = [], deviceType, columns, tableData} = this.props.props;
        const liArr = [];
        const disabledDate = (current) => {
            return current > moment().endOf('day')
        }
        if (liData) {

            for (var i of liData) {
                switch (i) {
                    case '刻度':
                        liArr.push(<li key={i}>
                            <RadioGroup defaultValue="dosage" buttonStyle='solid' onChange={this.radioGroupOnChange}>
                                <RadioButton value="dosage">用量</RadioButton>
                                <RadioButton value="scale">刻度</RadioButton>
                            </RadioGroup>
                        </li>)
                        break;
                    case '导出':
                        liArr.push(<li key={i}><img src="" alt=""/><Button>导出</Button></li>)
                        break;
                    case '导入':
                        liArr.push(<li key={i}><img src="" alt=""/><span>导入</span></li>)
                        break;
                    case '时间':
                        liArr.push(<li key={i}><DatePicker onChange={this.onChange} defaultValue={moment()}/></li>)
                        break;
                    case 'M时间':
                        liArr.push(<li key={i}><MonthPicker onChange={this.onChange} defaultValue={moment()}/></li>)
                        break;
                    case '区间':
                        liArr.push(<li key={i}><RangePicker onChange={this.onTimeInterval}
                                                            defaultValue={[moment(from), moment(to)]}
                                                            disabledDate={disabledDate}/></li>)
                        break;
                    case '搜索':
                        liArr.push(<li key={i}><Search placeholder="请输入" onSearch={this.onSearch} style={{width: 200}}/>
                        </li>)
                        break;
                    case '选择':
                        liArr.push(
                            <li key={i}>
                                <Select defaultValue={deviceType} style={{width: 120}}
                                        onChange={this.selectHandleChange}>
                                    <Option value="all">类型选择</Option>
                                    {select.map(({name, value}) => (
                                        <Option value={value} key={`${name}${value}`}>{name}</Option>))}
                                </Select>
                            </li>)
                        break;
                    default:
                        break;
                }
            }
        }

        return (
            <div className='tableTop'>
                <div className={`${this.props.operationIsShow ? 'operationShow' : 'operationNone'}  operation`}>
                    {/*20210818 12去掉前端web的管理和删除功能*/}
                    {/*<Management/>*/}
                    {/*<Delete/>*/}
                </div>
                <Button onClick={this.exportFn}>导出</Button>
                <Button type="primary" onClick={this.submit}>查询</Button>
                <ul>
                    {liArr}
                </ul>
                <span>{result}个结果</span>

            </div>
        )
    }
}


export default TableTop;