import React, {useContext,useState,useEffect} from 'react';

import './indexMidRightAverage.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import * as r from 'ramda';
import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';
import {context} from "../indexMidAverage";
import moment from 'moment'

const projectTypeConverter = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}

const projectTypeName = [
    'SPORT',
    'CBD',
    'HOSPITAL',
    'HOTEL',
    'MARKET',
    'OFFICE',
    'TEACH',
    'OTHER',
]

const transArrForTable = (arr)=>{
    let res = '';
    arr.forEach(function (v,i) {
        res +=(i+1)+'月:'+v+' ';
    });
    return res;
}


const IndexMidRightAverage =()=>{

    const {state: { active, active2,active3,active4,active5,active6,active7,active8,active9,active10, headCount, totalArea,sector,gov_d ,gov_m,no_gov_d ,no_gov_m,buildType, colorDealRes,chartsDealArr,chartsDealArr2,standLineDealArr,
        standLineDealArr2,totalDealResArr,totalDealResArr2,chartsTime2,colorDealResArr,colorDealResArr2,totalAreaArr,totalAreaArr2,headCountArr,headCountArr2,totalNameArr,activeArr,style}, dispatch} = useContext(context);
    
    const [activeArrLocal,setActiveArrLocal] = useState([]);
    // async getData() {
    //     await this.getInfo();
    //     const month = await Api.postV3('/cost/dailyComparison', {
    //         project: Api.projectId,
    //     });
    //     console.log('getData end: --------------------');
    //     let _this = this;
    //     // console.log(lastMonth)
    //     // console.log(year)
    //     let monthTime = [];
    //     let lastMonthTime = [];
    //     let monthData = [];
    //     let lastMonthData = [];
    //     if (month.data.current) {
    //         month.data.current.forEach(function (i, k) {
    //             let data = i.date.toString();
    //             // console.log(i.date.substr(i.date.length - 4))
    //             monthTime.push(data.slice(-2));
    //             // monthData.push(i.cost/100)
    //             monthData.push((i.cost/(100*_this.state.totalArea)).toFixed(4));
    //         })
    //     }
    //
    //     if (month.data.last) {
    //         month.data.last.forEach(function (i, k) {
    //             let data = i.date.toString();
    //
    //             lastMonthTime.push(data.slice(-2));
    //             // lastMonthData.push(i.cost/100)
    //             lastMonthData.push((i.cost/(100*_this.state.totalArea)).toFixed(4));
    //         })
    //     }
    //     let standLine =new Array(monthData.length).fill(0) ;
    //     let standLine2 =new Array(lastMonthData.length).fill(0) ;
    //     let st = this.state.gov_d;
    //     // r.map(y => {y>st?standLine.push(0):standLine.push((st-y).toFixed(2))})(monthData);
    //     // r.map(y => {y>st?standLine2.push(0):standLine2.push((st-y).toFixed(2))})(lastMonthData);
    //     // 日平均没有意义了，这里都显示0
    //     this.setState({
    //         chartsTime1: monthTime,
    //         chartsTime2: lastMonthTime,
    //         charts1: monthData,
    //         charts2: lastMonthData,
    //         standLine: standLine,
    //         standLine2: standLine2,
    //         active: "month"
    //     })
    // }
    // async getDataYear() {
    //     let _this = this;
    //     await this.getInfo();
    //     const year = await Api.postV3('/cost/monthlyComparison', {
    //         project: Api.projectId,
    //     });
    //     console.log('Api' + Api.projectId);
    //     const lastYear = moment().add(-1,'y').format('YYYY');
    //     const thisYear = moment().format('YYYY');
    //     const lastYearArr = {
    //         OFCBLD: '',
    //         CBD: '',
    //         HOSPITAL: '',
    //         HOTEL: '',
    //         MARKET: '',
    //         GOV: '',
    //         OFFICE: '',
    //         TEACH: '',
    //         SCHOOL: '',
    //         APARTMENT: '',
    //     }
    //     for(let key in lastYearArr){
    //         let res = await Api.postV3('/energyNew/usageAverageMonthly', {
    //             project: Api.projectId,
    //             sector: key,
    //             from: lastYear+'02',
    //             to: lastYear+'12',
    //         }).then(r.prop('data'));
    //         lastYearArr[key]=res;
    //     }
    //     const thisYearArr = {
    //         OFCBLD: '',
    //         CBD: '',
    //         HOSPITAL: '',
    //         HOTEL: '',
    //         MARKET: '',
    //         GOV: '',
    //         OFFICE: '',
    //         TEACH: '',
    //         SCHOOL: '',
    //         APARTMENT: '',
    //     }
    //     for(let key in thisYearArr){
    //         let res = await Api.postV3('/energyNew/usageAverageMonthly', {
    //             project: Api.projectId,
    //             sector: key,
    //             from: thisYear+'02',
    //             to: thisYear+'12',
    //         }).then(r.prop('data'));
    //         thisYearArr[key]=res;
    //     }
    //
    //
    //     // const lastYearAverage = await Api.postV3('/energyNew/usageAverageMonthly', {
    //     //     project: Api.projectId,
    //     //     sector: _this.state.sectorOrigin,
    //     //     from: lastYear+'02',
    //     //     to: lastYear+'12',
    //     // }).then(r.prop('data'));
    //     // const thisYearAverage = await Api.postV3('/energyNew/usageAverageMonthly', {
    //     //     project: Api.projectId,
    //     //     sector: _this.state.sectorOrigin,
    //     //     from: thisYear+'02',
    //     //     to: thisYear+'12',
    //     // }).then(r.prop('data'));
    //     // console.log(lastYearAverage);
    //     // let results = [lastYearAverage,thisYearAverage];
    //     const myAdd = function (a, b ) {
    //         return (b.max -b.min)*b.comi + a;
    //     };
    //
    //     // 选择这个项目下的所有的建筑,暂时汇总到这个项目下
    //     // let prjDealRes = [];
    //     // let totalDealRes = [];
    //     // let standLineDealRes = [];
    //     // let colorDealRes = [];
    //     let chartsDealArr= {//保存所有建筑的数据
    //             OFCBLD: [],
    //             CBD: [],
    //             HOSPITAL: [],
    //             HOTEL: [],
    //             MARKET: [],
    //             GOV:[],
    //             OFFICE: [],
    //             TEACH: [],
    //             SCHOOL: [],
    //             APARTMENT: [],
    //         },
    //          // chartsDealArr2=[],
    //          standLineDealArr= {//保存所有建筑的数据
    //              OFCBLD: [],
    //              CBD: [],
    //              HOSPITAL: [],
    //              HOTEL: [],
    //              MARKET: [],
    //              GOV:[],
    //              OFFICE: [],
    //              TEACH: [],
    //              SCHOOL: [],
    //              APARTMENT: [],
    //          }, // the line for the normal thing
    //          // standLineDealArr2= [], // the line for the normal thing
    //          colorDealResArr= {//保存所有建筑的数据
    //              OFCBLD: [],
    //              CBD: [],
    //              HOSPITAL: [],
    //              HOTEL: [],
    //              MARKET: [],
    //              GOV:[],
    //              OFFICE: [],
    //              TEACH: [],
    //              SCHOOL: [],
    //              APARTMENT: [],
    //          },
    //          // colorDealResArr2= [],
    //          totalDealResArr= {//保存所有建筑的数据
    //              OFCBLD: [],
    //              CBD: [],
    //              HOSPITAL: [],
    //              HOTEL: [],
    //              MARKET: [],
    //              GOV:[],
    //              OFFICE: [],
    //              TEACH: [],
    //              SCHOOL: [],
    //              APARTMENT: [],
    //          },
    //          // totalDealResArr2= [],
    //          totalAreaArr= {//保存所有建筑的数据
    //              OFCBLD: [],
    //              CBD: [],
    //              HOSPITAL: [],
    //              HOTEL: [],
    //              MARKET: [],
    //              GOV:[],
    //              OFFICE: [],
    //              TEACH: [],
    //              SCHOOL: [],
    //              APARTMENT: [],
    //          },
    //         // totalAreaArr2= [],
    //         totalNameArr= {//保存所有建筑的数据
    //             OFCBLD: [],
    //             CBD: [],
    //             HOSPITAL: [],
    //             HOTEL: [],
    //             MARKET: [],
    //             GOV:[],
    //             OFFICE: [],
    //             TEACH: [],
    //             SCHOOL: [],
    //             APARTMENT: [],
    //         },
    //         // totalAreaArr2= [],
    //         headCountArr= {//保存所有建筑的数据
    //             OFCBLD: [],
    //             CBD: [],
    //             HOSPITAL: [],
    //             HOTEL: [],
    //             MARKET: [],
    //             GOV:[],
    //             OFFICE: [],
    //             TEACH: [],
    //             SCHOOL: [],
    //             APARTMENT: [],
    //         };
    //         // headCountArr2= [];
    //
    //     for(let key in thisYearArr){
    //         // let results = [thisYearArr[key],lastYearArr[key]]; // 后续如果需要历史和同期的，这里可以进行对比，如果不需要的，可以再看。
    //         let results = [thisYearArr[key]];// 这里是只有今年一年的
    //         // 处理结果
    //         let dealRes = [];
    //         let _key = key;
    //         r.map(x => {// x 是按照月份排列下来的数据，01：[{},{},{},{},{}]  02：
    //             console.log('一年的数据：');
    //             console.log(x);
    //             let i = 0;
    //             let result = {sum:0,acreage: 0,//存储今年或者去年的总数据，
    //                 headCount: 0,};
    //             r.map(y=>{// y 是今年，或者去年的数据数组里的一个具体项，是这个月的单个工程的数据
    //                 ++i;
    //                 // console.log(i+'月');
    //                 let sumMonth = 0;
    //                 let z = r.groupBy(r.prop('bid'))(y);// z 是按照建筑排序的数据
    //                 for(let key in z){  //
    //                     if(result[key]){
    //
    //                     }else{
    //                         console.log(z[key][0].project==Api.projectId);
    //                         console.log(z[key][0].project);
    //                         console.log(Api.projectId);
    //                         result[key]={sum:0,project:(z[key]&&z[key].length>0&&z[key][0].project)?z[key][0].project:'',
    //                             headCount:(z[key]&&z[key].length>0&&z[key][0].headCount)?(z[key][0].headCount):0,
    //                             acreage:(z[key]&&z[key].length>0&&z[key][0].acreage)?(z[key][0].acreage):0};
    //                         result.acreage+=result[key].acreage;
    //                         result.headCount+=result[key].headCount;
    //                         totalNameArr[_key].push(z[key][0].title);
    //                     }
    //                     let sum = r.reduce(myAdd,0)(z[key]);
    //                     result[key][i]=sum.toFixed(4);
    //                     result[key].sum+=parseFloat(sum.toFixed(4));
    //                     result.sum+=sum;
    //                     sumMonth+=sum;
    //                 }
    //                 result[i]=sumMonth.toFixed(4);
    //             })(x);
    //             result.sum=result.sum.toFixed(4);
    //             dealRes.push(result);
    //             // if(i){
    //             //     totalAreaArr2.push(result.acreage);
    //             //     headCountArr2.push(result.headCount);
    //             // }else{
    //             //     totalAreaArr.push(result.acreage);
    //             //     headCountArr.push(result.headCount);
    //             // }
    //         })(results);
    //             totalAreaArr[key].push(dealRes[0].acreage);
    //             headCountArr[key].push(dealRes[0].headCount);
    //         // console.log(dealRes[0]);
    //         let index = 0;
    //         r.map(result=> {
    //             //计算所有建筑的月份能耗，面积，人数，
    //             for (let key2 in result) {
    //                 if (result[key2].project) {
    //                     let prjRes = new Array(12).fill(0);
    //                     let area = 0;
    //                     area += parseInt(result[key2].acreage);
    //                     for (let key3 in result[key2]) {
    //                         if (parseInt(key3) < 13) {
    //                             prjRes[parseInt(key3) - 1] += parseFloat(result[key2][key3]);
    //                         }
    //                     }
    //
    //                     prjRes.project = result[key2].project;
    //                     console.log('area: ' + area);
    //                     area = (area == 0) ? 1 : area;
    //                     prjRes = r.map(y => (y / area).toFixed(2))(prjRes);
    //
    //                     // 计算总的平均值
    //                     let areaTotal = (result.acreage == 0) ? 1 : result.acreage;;
    //                     let totalArr = new Array(12).fill(0);
    //                     console.log('areaTotal' + areaTotal);
    //                     for (let key in result) {
    //                         if (parseInt(key) < 13) {
    //                             totalArr[parseInt(key) - 1] = (parseFloat(result[key]) / areaTotal).toFixed(2);
    //                         }
    //                     }
    //
    //                     let standLine = [];
    //                     let colorArr = [];
    //                     prjRes.forEach(function (v, index) {
    //                         v>totalArr[index]?standLine.push(0):(v==0?standLine.push(0):(standLine.push((totalArr[index]-v).toFixed(3))));
    //                         colorArr.push(v>totalArr[index]?1:0);
    //                     })
    //                     // prjDealRes.push(prjRes);
    //                     // totalDealRes.push(totalArr);
    //                     // standLineDealRes.push(standLine);
    //                     // colorDealRes.push(colorArr);
    //                     if(index){
    //                         // chartsDealArr2.push(prjRes);
    //                         // totalDealResArr2.push(totalArr);
    //                         // standLineDealArr2.push(standLine);
    //                         // colorDealResArr2.push(colorArr);
    //                     }else{
    //                         chartsDealArr[key].push(prjRes);
    //                         totalDealResArr[key].push(totalArr);// 这里其实有几个建筑，就增加几次，其实这里每次都是一样的，暂时放着，等后续再改
    //                         // totalDealResArr[key]=totalArr;
    //                         standLineDealArr[key].push(standLine);
    //                         colorDealResArr[key].push(colorArr);
    //                     }
    //
    //                 }
    //             }
    //
    //             index++;
    //         })(dealRes)
    //
    //
    //         // console.log('res1,2:------');
    //         // console.log(prjDealRes[0]);
    //         // console.log(totalDealRes[0]);
    //         // console.log(standLineDealRes[0]);
    //
    //     }
    //     //本来是今年，去年两年，数据都正常，如果只有今年一年，就会导致数据不太正常
    //     let defaultArr = new Array(12).fill(0);
    //
    //     //----- start 创建表格数据 -----
    //     let order = 1;
    //     let tableData = [];
    //     for(let key in chartsDealArr){
    //         if(chartsDealArr[key].length>0){
    //             // -- start ---添加该类型建筑总的平均数值
    //             // tableData.push({
    //             //     month_1:totalDealResArr[key][0][0],
    //             //     month_2:totalDealResArr[key][0][1],
    //             //     month_3:totalDealResArr[key][0][2],
    //             //     month_4:totalDealResArr[key][0][3],
    //             //     month_5:totalDealResArr[key][0][4],
    //             //     month_6:totalDealResArr[key][0][5],
    //             //     month_7:totalDealResArr[key][0][6],
    //             //     month_8:totalDealResArr[key][0][7],
    //             //     month_9:totalDealResArr[key][0][8],
    //             //     month_10:totalDealResArr[key][0][9],
    //             //     month_11:totalDealResArr[key][0][10],
    //             //     month_12:totalDealResArr[key][0][11],
    //             //     avg:(r.sum(totalDealResArr[key][0])/12).toFixed(2),
    //             //     // name: key,
    //             //     name: '类型总值',
    //             //     order: ''+order++,
    //             //     type:projectTypeConverter[key],
    //             //     area: r.sum(totalAreaArr[key]).toFixed(2),
    //             //     headCount: r.sum(headCountArr[key]).toFixed(2)
    //             // });
    //             // --- end --
    //             chartsDealArr[key].forEach(function (v,i) {
    //                 tableData.push({
    //                     month_1:chartsDealArr[key][i][0] ,
    //                     month_2:chartsDealArr[key][i][1],
    //                     month_3:chartsDealArr[key][i][2],
    //                     month_4:chartsDealArr[key][i][3],
    //                     month_5:chartsDealArr[key][i][4],
    //                     month_6:chartsDealArr[key][i][5],
    //                     month_7:chartsDealArr[key][i][6],
    //                     month_8:chartsDealArr[key][i][7],
    //                     month_9:chartsDealArr[key][i][8],
    //                     month_10:chartsDealArr[key][i][9],
    //                     month_11:chartsDealArr[key][i][10],
    //                     month_12:chartsDealArr[key][i][11],
    //                     //把同类型总的平均值也放进去，用于比较和效果的区别显示,因为重复的数据存储了很多，所以这里就用第一个组数的数据
    //                     total_1:totalDealResArr[key][0][0] ,
    //                     total_2:totalDealResArr[key][0][1],
    //                     total_3:totalDealResArr[key][0][2],
    //                     total_4:totalDealResArr[key][0][3],
    //                     total_5:totalDealResArr[key][0][4],
    //                     total_6:totalDealResArr[key][0][5],
    //                     total_7:totalDealResArr[key][0][6],
    //                     total_8:totalDealResArr[key][0][7],
    //                     total_9:totalDealResArr[key][0][8],
    //                     total_10:totalDealResArr[key][0][9],
    //                     total_11:totalDealResArr[key][0][10],
    //                     total_12:totalDealResArr[key][0][11],
    //                     avg:(r.sum(chartsDealArr[key][i])/12).toFixed(2),
    //                     name: totalNameArr[key][i],
    //                     order: ''+order++,
    //                     type:projectTypeConverter[key],
    //                     area: totalAreaArr[key][i],
    //                     headCount: headCountArr[key][i]});
    //             })
    //
    //         }
    //
    //     }
    //     console.log('tableData create ---------------');
    //     console.log(tableData);
    //     this.props.setData({tableData:tableData,totalDealResArr:totalDealResArr});
    //
    //
    //
    //
    //
    //     //------ end 表格数据创建完成
    //
    //     this.setState({
    //         chartsTime1: this.state.chartsTime3,
    //         chartsTime2: this.state.chartsTime3,
    //         // charts1: prjDealRes[0],
    //         // charts2: prjDealRes[1],
    //         // standLine: standLineDealRes[0],
    //         // standLine2: standLineDealRes[1],
    //         // colorDealRes: colorDealRes,
    //         // totalDealRes: totalDealRes,
    //         chartsDealArr:chartsDealArr,// 各种类型里面的建筑物的数据
    //         totalDealResArr:totalDealResArr,// 各种类型总的数据
    //         totalNameArr:totalNameArr,// 各种名字数据
    //         standLineDealArr:standLineDealArr,//标注线的数据，就是标准减去现有的差值，用于柱状图显示
    //         colorDealResArr:colorDealResArr,//显色，双值：1,0,  用于建筑的柱状图显示，1：高于，红色 0：低于，其他颜色
    //         // chartsDealArr2:chartsDealArr2,
    //         // totalDealResArr2:totalDealResArr2,
    //         // standLineDealArr2:standLineDealArr2,
    //         // colorDealResArr2:colorDealResArr2,
    //         totalAreaArr:totalAreaArr,//
    //         // totalAreaArr2:totalAreaArr2,
    //         headCountArr:headCountArr,
    //         // headCountArr2:headCountArr2,
    //         active: "year"
    //     })
    // }
    // async getInfo() {
    //     if(this.state.sector!=''){
    //         console.log('already get the info, return ---------');
    //         return;
    //     }
    //     const projectTypeConverter = {
    //         OFCBLD: '写字楼',
    //         CBD: '综合体',
    //         HOSPITAL: '医院',
    //         HOTEL: '酒店',
    //         MARKET: '商场',
    //         GOV: '政府机关',
    //         OFFICE: '办公',
    //         TEACH: '文教',
    //         SCHOOL: '学校',
    //         APARTMENT: '公寓',
    //     }
    //     const data = await Api.postV3('/infoForUs/statistics2', {
    //         project: Api.projectId
    //     }).then(r.prop('data'))
    //     console.log('getInfo end: --------------------');
    //     this.setState({
    //         headCount: data[0].headCount?data[0].headCount:1,
    //         totalArea: data[0].totalArea?data[0].totalArea:1,
    //         // sector: projectTypeConverter[data[1][0].sector]+((data[1][0].sector == 'GOV')?'(党政机关办公建筑)':'(商业办公建筑)'),
    //         sector: projectTypeConverter[data[1][0].sector],
    //         sectorOrigin: data[1][0].sector,
    //         buildType: (data[1][0].sector == 'GOV')?1:2,
    //     })
    // }
    //
    //
    // componentDidMount() {
    //     // this.getDataYear();
    // }
    //
    // selected(params) {
    //
    //     if (params === "year") {
    //         this.getDataYear()
    //     } else {
    //         this.getData()
    //     }
    //     // console.log(this.state)
    // }
    const selected2 = (params,index) =>{

        console.log('change '+ params);
        if (params === "bar") {
            activeArr[index-1]="bar";
            // this.getDataYear()
        } else {
            // this.getData()
            activeArr[index-1]="line";
        }
        let arr = activeArr;
        setActiveArrLocal(activeArr.slice());
        // console.log('activeArr');
        // console.log(activeArrLocal);
        // let arr2 = ['line','bar','bar','bar','bar','bar','bar','bar','bar','bar'];
        // setActiveArrLocal(arr2);
    }

     useEffect( ()=>{
        console.log('useEffect');
    },[activeArrLocal])

        // const { active, active2,active3,active4,active5,active6,active7,active8,active9,active10, headCount, totalArea,sector,gov_d ,gov_m,no_gov_d ,no_gov_m,buildType, colorDealRes,chartsDealArr,chartsDealArr2,standLineDealArr,
        // standLineDealArr2,totalDealResArr,totalDealResArr2,colorDealResArr,colorDealResArr2,totalAreaArr,totalAreaArr2,headCountArr,headCountArr2,totalNameArr,activeArr} = this.state;
        console.log('totalAreaArr-------------------------');
        console.log(totalAreaArr);
        if(!totalAreaArr){
            return (<div> no data</div>)
        }
        // const activeArr = [active, active2,active3,active4,active5,active6,active7,active8,active9,active10];
        const colorlist=['#feeecd', '#fce9b1', '#fcde9e',  '#fcd786', '#fad06c','#fcc852', '#fbbf3d',  '#fab921', '#fbb20e', '#fbb110', '#fb5b44' ];
        const colorlist2=['#a5a2fe', '#fc8793'];
        let index = (active == 'year')?((buildType == 1)?gov_m:no_gov_m):((buildType == 1)?gov_d:no_gov_d);
        console.log(index);
        const seriesArr = [],optionArr = new Array(10).fill(0);
        //下面是增加两个标准线，方便查看实际的平均线
        const lines = (key,i,name,index)=> [/*{
            type: 'line',
            smooth: true,
            data: totalDealResArr2[i],
            tooltip: {
                trigger: 'axis',
                formatter:function (params, ticket, callback) {

                    return '去年平均线'+params.data
                }
            },
            itemStyle: {
                normal: {
                    color: '#c2fb5e'
                }
            }
        },*/{
            // type: 'line',
            type: activeArr[index],
            smooth: true,
            data: totalDealResArr[key][i],
            tooltip: {
            trigger: 'axis',
                formatter:function (params, ticket, callback) {
                return '今年平均线'+params.data
            }
        },
        itemStyle: {
            normal: {
                // 定制显示（按顺序）
                color: '#84e0fb'
            }
        }
    }];

        const series =(key,i,name,index)=> [
            {
                type: activeArr[index],
                stack:i,
                // name: totalNameArr[key][i],
                // name: activeArr[index],
                smooth: true,
                data: chartsDealArr[key][i],
                tooltip: {
                    trigger: 'axis',
                    formatter:function (params, ticket, callback) {
                        return '今年'+params.data
                    }
                },
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: function (params) {
                            var data = params.data;
                            var sid = params.dataIndex;
                            return (active == 'year')?colorlist2[colorDealResArr[key][i][sid]]:colorlist2[0];
                        }
                    }
                }
            }, {
                type: activeArr[index],
                stack:i,
                // name: '距离标准',
                smooth: true,
                data: standLineDealArr[key][i],
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: '#c2fb5e',
                    }
                }
            },/*{
                type: active2,
                stack:2,
                // name: '去年',
                smooth: true,
                data: chartsDealArr2[i],
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: function (params) {
                            // return (active == 'year')?colorlist2[colorDealRes[1][params.dataIndex]]:colorlist2[0];
                            return (active == 'year')?colorlist2[colorDealResArr2[i][params.dataIndex]]:colorlist2[0];
                        }
                    }
                },
                // data: this.state.charts1,
            }, {
                type: active2,
                stack:2,
                // name: '距离标准2',
                smooth: true,
                data: standLineDealArr2[i],
                itemStyle: {
                    normal: {
                        color: '#fbedb5',
                    }
                }
            }*/];
        const defaultOption = {
            color: ["#87E7FF", "#D3D7E3"],
            grid: { left: '3', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis',
            },
            legend: {
            },
            xAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                // data: r.isEmpty(this.state.chartsTime2) ? this.state.chartsTime1 : this.state.chartsTime2
                data: chartsTime2
            },
            yAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {       //y轴刻度线
                    show: false
                },
            },
            // series: series
        }
        const getOption = (series,key)=> {return {
            color: ["#87E7FF", "#D3D7E3"],
            grid: { left: '3', top: '25', right: '30', bottom: '22' },
            tooltip: {
                trigger: 'axis',
                formatter:function (params, ticket, callback) {
                    let name = '平均:'+params[0].data+'</br>';
                    params.forEach(function (v,i) {
                        if(i>0 && (i%2!=0)){ // 项目好在技术，并且是大于1 的数量
                            let index = Math.floor(i/2);
                            name += totalNameArr[key][index]+':'+v.data+'</br>';
                        }

                    })
                    return name
                }
            },
            legend: {
            },
            xAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                // data: r.isEmpty(this.state.chartsTime2) ? this.state.chartsTime1 : this.state.chartsTime2
                data: chartsTime2
            },
            yAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {       //y轴刻度线
                    show: false
                },
            },
            series: series
        }}

        if(active == 'year'){
            // series.push(...lines);
            let i = 0;
            // for(let k =0;k<chartsDealArr.length;k++){
            //     let a = series(i);
            //     let b = lines(i);
            //     // seriesArr.push(a.concat(b));
            //     optionArr[i]=getOption(a.concat(b));
            //     i++;
            // };
            // 把所有种类的数据组合起来
            for(let key in chartsDealArr){
                let b = lines(key,0,'',i);//总的平均线
                //  这里添加所有建筑的线条，后面如果需要可以把这里的东西先去掉
                // for(let k =0;k<chartsDealArr[key].length;k++){
                //     // seriesArr.push(a.concat(b));
                //     b.push(...series(key,k,'',i));//添加所有建筑的线条
                // };
                optionArr[i]=getOption(b,key);//保存series
                i++;
            }

            // console.log('seriesArr-----------------');
            // console.log(seriesArr);
            console.log('optionArr-----------------');
            console.log(optionArr);

        }







        return (
            <div className='indexMidRightAverage' >
                <table className='indexMidRightTable'>
                    <tbody>
                        <tr >
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.SPORT}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.SPORT?totalAreaArr.SPORT[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[0]}</span>
                                    {/*<span  className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArrLocal[0] === "line" ? "active" : ""} hover`} onClick={()=>selected2( "line",1)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArrLocal[0] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",1)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[0]?optionArr[0]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.CBD}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.CBD?totalAreaArr.CBD[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[1]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[1] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",2)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[1] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",2)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[1]?optionArr[1]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.HOSPITAL}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.HOSPITAL?totalAreaArr.HOSPITAL[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[2]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[2] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",3)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[2] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",3)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[2]?optionArr[2]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.HOTEL}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.HOTEL?totalAreaArr.HOTEL[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[3]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[3] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",4)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[3] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",4)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[3]?optionArr[3]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>

                        </tr>
                        <tr >

                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.MARKET}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.MARKET?totalAreaArr.MARKET[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[4]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[4] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",5)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[4] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",5)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[4]?optionArr[4]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />

                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.OFFICE}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.OFFICE?totalAreaArr.OFFICE[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[5]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[5] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",6)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[5] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",6)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[5]?optionArr[5]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.TEACH}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.TEACH?totalAreaArr.TEACH[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[6]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[6] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",7)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[6] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",7)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[6]?optionArr[6]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.OTHER}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.OTHER?totalAreaArr.OTHER[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[7]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[7] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",8)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[7] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",8)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[7]?optionArr[7]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div>

        )

}

export default IndexMidRightAverage;