import React, {useContext} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import {context, loadNodeUsage} from '../report'
import {of, from} from 'rxjs'

import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';


const IntervalReport = () => {
    const {state: {loading, tableData, from, to, deviceType, select, total}, dispatch} = useContext(context);
    const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData))
    const columns = [{
        title: '序号',
        dataIndex: 'order',
        key: 'order',
        width: '70px',
    }, {
        title: '设备名称',
        dataIndex: 'name',
        key: 'name',
        width: '150px',
        render: (text, record) => (
            <Tooltip placement="top" title={text}>
                <span className='tableName'>{text}</span>
            </Tooltip>
        ),
    }, {
        title: '设备ID',
        dataIndex: 'instrumentId',
        key: 'instrumentId',
        width: '150px',
    }, {
        title: '设备类型',
        dataIndex: 'eqType',
        key: 'eqType',
        width: '130px',

    }, {
        title: '通道名称',
        dataIndex: 'channelName',
        key: 'channelName',
        width: '110px',
        filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        sortDirections: ['descend'],
    },
        {
            title: '起始刻度',
            dataIndex: 'startScale',
            key: 'startScale',
            width: '150px',

        },
        {
            title: '最终刻度',
            dataIndex: 'endScale',
            key: 'endScale',
            width: '150px',

        },
        {
            title: '倍率系数',
            dataIndex: 'comi',
            key: 'comi',
            width: '100px',
        },
        {
            title: '能耗总值',
            dataIndex: 'sum',
            key: 'sum',
            width: '150px',
            sorter: (a, b) => a.sum - b.sum,
        }, {
            title: '单价',
            dataIndex: 'price',
            key: 'price',
            width: '100px',
        },
        {
            title: '费用',
            dataIndex: 'cost',
            key: 'cost',
            sorter: (a, b) => a.cost - b.cost
        },
        {
            title: '能耗类型',
            dataIndex: 'energyType',
            key: 'energyType',
            width: '140px',
        }
    ];


    const tableTop = {
        result: tableData.length,
        liData: ['区间', '选择', '搜索',],
        from,
        to,
        select,
        deviceType,
        columns,
        tableData,
        handleScaleChange: ({deviceType, from, to}) => {
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                deviceType,
                from,
                to
            }))
        },
    };

    return (
        <div className='reportRightTable'>
            <TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}
                      tableData={tableData}/>
            <Table
                columns={columns}
                dataSource={tableData}
                scroll={{y: 710}}
                pagination={false}
                loading={loading}
                className='reportRightTableTab'/>
            <div style={{float: 'right', marginRight: '20px', color: '#1890ff'}}>
                <span style={{marginRight: '20px'}}>总消耗:{total.usage}</span>
                <span>总费用:{total.cost}</span>
            </div>
        </div>
    )
}


export default IntervalReport;