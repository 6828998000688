import React , { useContext } from 'react';

import './screenVersionIndex.scss';
// import Api from 'api'

import Img_ScreenVersionIndexBottom from '../../img/ScreenVersionIndexBottom.png'

import IndexTop from './indexTop/indexTop';
import IndexMid from './indexMid/indexMid';
import IndexBottom from './indexBottom/indexBottom';
import IndexLeftPointIndex from './ui/IndexLeftPoint/IndexLeftPoint'
import ScreenVersionIndexHead from './screenVersionIndexHead';
import { Color,ColorContext , UPDATE_COLOR } from "./color";
import IndexMidLeft from "./indexMid/indexMidLeft/indexMidLeft";
import IndexMidLeftForWeather from "./indexMid/indexMidLeft/indexMidLeftForWeather";


class ScreenVersionIndex extends React.Component {
    constructor(props){
        super(props);
        this.state={
            keyWord: false
        }
    }


    //设置组件A input的查询参数
    setKeyWorld = (keyWord) => {
        this.setState({
            keyWord
        })
    }



  render() {
      const btnStyle2 = {
          display:'none'
      };
      const Style3 = {
          display:this.state.keyWord?'none':'block'
      };
    return (
     <Color>
      <div className="ScreenVersionIndex">
        <ScreenVersionIndexHead  />
        <div id='screenContainer' className='ScreenVersionIndexConainer' >
          <IndexLeftPointIndex />
          <div className='ScreenVersionIndexConainerLeft'>
            <IndexTop />
            <IndexMid />
            <IndexBottom />
          </div>
          <div className='borderRightBorder'>
            <div className='borderRightBorder2 borderRightBorder2Top'></div>
            <div className='borderRightBorder2 borderRightBorder2Bottom'></div>
          </div>
        </div>
        {/*这里填充新的页面样式*/}
          <div id='midLeftForWeather'>
              <IndexMidLeftForWeather  />
          </div>



        <img src={Img_ScreenVersionIndexBottom} alt="" className='Img_ScreenVersionIndexBottom'/>
      </div>
     </Color>
    )
  }

};


export default ScreenVersionIndex;
