import React, { useContext } from 'react';
import CurrentUsage from './departmentCurrentUsage/departmentCurrentUsage';
import Percentage from './departmentPercentage/departmentPercentage';
import Rank from './departmentRank/departmentRank';

const DepartmentRightBottom = () => (
    <div className='departmentRightBottom'>
        <CurrentUsage />
        <Rank />
        <Percentage />
    </div>
)

export default DepartmentRightBottom