
// color.js

import React, { createContext, useReducer } from "react";
// 创建 context
export const ColorContext = createContext({});


// reducer
export const UPDATE_COLOR = "UPDATE_COLOR"
const reducer = (state, action) => {
    switch(action.type) {
        case UPDATE_COLOR:
            if(action.color.color == '1'){
                return '0'
            }
            return '1'
            // return action.color
        default:
            return state
    }
}



/**
 * 创建一个 Color 组件
 * Color 组件包裹的所有子组件都可以通过调用 ColorContext 访问到 value
 */
// export const Color = props => {
//     return (
//         <ColorContext.Provider value={{ color: "blue" }}>
//             {props.children}
//         </ColorContext.Provider>
//     );
// };

export const Color = props => {
    const [color, dispatch] = useReducer(reducer, '1')
    return (
        <ColorContext.Provider value={{color, dispatch}}>
            {props.children}
        </ColorContext.Provider>
    );
};
