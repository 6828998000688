import React, {useContext, useState} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import {context, loadNodeUsage} from '../weatherDate'
import {of, from} from 'rxjs'
import ReactEcharts from 'echarts-for-react';
import TableTop from 'src/components/table/tableTop/tableTop';
import  './weatherDateInterval.scss'

import Api from 'api';
const dateFormat = function (date, format) {
    date = new Date(date);
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour+8小时
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

    return format;
}


const weatherDateInteval = () => {
    const {state: {loading, tableData, avgData,nameType, dataX,reacordX,dataY,winddirdata, from, to, deviceType, select, total,cnt}, dispatch} = useContext(context);
    // let [dataX, setDataX] = useState([]);
    // let [dataY, setDataY] = useState([]);
    console.log('reacordX');
    console.log(reacordX);
    const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData))
    const unitWin = 11.25
    const transWinddDir = function(e) {
            if (Math.abs(e-0)<=unitWin){
                return "东";
            }
        if (Math.abs(e-22.5)<=unitWin){
            return "东东南";
        }
        if (Math.abs(e-45)<=unitWin){
            return "东南";
        }
        if (Math.abs(e-67.5)<=unitWin){
            return "南东南";
        }
        if (Math.abs(e-90)<=unitWin){
            return "南";
        }
        if (Math.abs(e-112.5)<=unitWin){
            return "南西南";
        }
        if (Math.abs(e-135)<=unitWin){
            return "西南";
        }
        if (Math.abs(e-157.5)<=unitWin){
            return "西西南";
        }
        if (Math.abs(e-180)<=unitWin){
            return "西";
        }
        if (Math.abs(e-202.5)<=unitWin){
            return "西西北";
        }
        if (Math.abs(e-225)<=unitWin){
            return "西北";
        }
        if (Math.abs(e-247.5)<=unitWin){
            return "北西北";
        }
        if (Math.abs(e-270)<=unitWin){
            return "北";
        }
        if (Math.abs(e-292.5)<=unitWin){
            return "北东北";
        }
        if (Math.abs(e-315)<=unitWin){
            return "东北";
        }
        if (Math.abs(e-337.5)<=unitWin){
            return "东东北";
        }


    }
    const transSid = (a) => (a/2-1);
    const transColor = (id,sid,winddirdata,colorlist) => {
        let index = Math.floor(winddirdata[id][sid*4+3]/5);
        return colorlist[index>9?9:index];
        };
    let monthArr=[];
    const getItemColors = (colorLevel,minarr,maxarr) =>{
        var colors= [];
        //默认的最深颜色
        var red = minarr[0],green = minarr[1], blue = minarr[2];
        //最浅颜色是239,239,239 比如：最浅颜色的red是 239 则差值为239-134=105
        var maxRed = maxarr[0],maxGreen = maxarr[1],maxBlue = maxarr[2];
        var level = colorLevel;
        while(level--) {
            colors.push( 'rgb('+red +','+green+','+blue+')');
            red += parseInt(maxRed/colorLevel);
            green += parseInt(maxGreen/colorLevel);
            blue += parseInt(maxBlue/colorLevel);
        }
        console.log('colors');
        console.log(colors);
        return colors;
    }
    // yellow:250 250 0,250 140 0
    //red:255 146 5, 239 243 3
    // blue 54 120 233 ,3 3 239

    // const colorsForRadia = getItemColors(3,[204,0,0],[255,215,0]);//yellow
    // const colorsForRadia2 = getItemColors(3,[254,5,181],[204,0,0]);//red
    // const colorsForRadia3 = getItemColors(3,[0,51,153],[254,5,181]);//blue
    const colorsForRadia = getItemColors(3,[204,0,0],[255,215,0]);//yellow
    const colorsForRadia2 = getItemColors(3,[124,51,137],[204,0,0]);//red
    const colorsForRadia3 = getItemColors(3,[255,255,255],[124,51,137]);//blue

    const colorsForRadia4 = getItemColors(10,[204,0,0],[254,230,81]);//blue
    const colorsForRadia5 = ['rgb(0,0,255)','rgb(53,0,202)','rgb(107,0,148)','rgb(160,0,95)','rgb(214,0,41)','rgb(255,12,0)','rgb(255,66,0)',
        'rgb(255,119,0)','rgb(255,173,0)','rgb(255,226,0)','rgb(255,255,0)'];
    //数值：100,200,300,400,500,600,700,800,900
    const getColor=(v)=>{

        let v1 = Math.floor(v/100);
        // console.log('v');
        // console.log(v);
        // console.log(v1);
        if(v1<3){
            return colorsForRadia3[v1];
        }
        if(v1<6){
            return colorsForRadia2[v1-3];
        }
        return (v1)<9?(colorsForRadia[(v1-6)]):'#ecc70d';
    }
    const getColor2=(v)=>colorsForRadia5[Math.floor(v/130)>10?10:Math.floor(v/130)]

    console.log('tableData');
    console.log(tableData);
    const tableData3 = {};
    const tableData4 = [];
    const keyNames = [];
    if(tableData&&tableData.length>0){
        r.map(y=>{
            if(tableData3[y.formatHour]){
                if(tableData3[y.formatHour][y.formatMonth]){
                    tableData3[y.formatHour][y.formatMonth].push(y.a_r);
                }else{
                    tableData3[y.formatHour][y.formatMonth]=[y.a_r];
                }
            }else{
                tableData3[y.formatHour]={[y.formatMonth]:[y.a_r]};
            }

        })(tableData[0]);
        console.log('tableData3');
        console.log(tableData3);

        for(let index =0;index<24;index++){
            let indexStr = index<10?('0'+index):(''+index);
            let arr = {hr:indexStr};
            for(let key2 in tableData3[indexStr]){
                if(monthArr.indexOf(key2)==-1){
                    monthArr.push(key2);
                }
                arr[key2]=tableData3[indexStr][key2];
            }
            tableData4.push(arr);
        }

        console.log('tableData4');
        console.log(tableData4);
//         let data2 = r.groupBy(r.prop('formatMonth'))(tableData?tableData[0]:[])
// // console.log(data2);
//         for(let key in data2){//data2:month 分类
//             let lineData = {hr:0,day:key,Jan:[],Feb:[],Mar:[],Apr:[]};
//             // console.log(key);
//             // console.log(data2[key]);
//             let data3 = r.groupBy(r.prop('formatDate'))(data2[key])
//             console.log('============');
//             for(let key in data3){// data3 date 分类
//
//                 // console.log('------------');
//                 // console.log(key);
//                 // console.log(data3[key]);
//                 r.map(y=>{
//
//
//                 })(data3[key]);
//             }
//             tableData3.push(monthData);
//         }
//         console.log('tableData3');
//         console.log(tableData3);
    }

    const columns = [
            {
            title: 'hr',
            dataIndex: 'hr',
            key: 'hr',
            width: '25px',
                // height:'50px'
        },
        // {
        //     title: 'Jan',
        //     dataIndex: 'Jan',
        //     key: 'Jan',
        //     width: '70px',
        //     render: (text, record) => {
        //         // let res = ``;
        //         // r.map(y=>(y>1?res+=`<span style={{backgroundColor:"#fbc9bf"}}>{text}</span>`:`<span style={{backgroundColor:"#508efb"}}>{text}</span>`))(text)
        //         if(!text){
        //             return;
        //         }
        //         let width = (100/text.length-0.01).toFixed(2)+'%';
        //         return <div>{r.map(y=>{
        //             return (y>10?<span style={{backgroundColor:"#fbc9bf" ,float:"left", width:width}}>{y}</span>:<span style={{backgroundColor:"#508efb",float:"left", width:width}}>{y}</span>)
        //         })(text)}</div>
        //         // if(text > 1){
        //         //     // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_1})</span>
        //         //     return '<div><span style={{backgroundColor:"#fbc9bf"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span></div>'
        //         // }else{
        //         //     return <div><span style={{backgroundColor:"#508efb"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span></div>
        //         // }
        //     },
        // }
        // ,{
        //     title: 'Feb',
        //     dataIndex: 'Feb',
        //     key: 'Feb',
        //     width: '70px',
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:"#fbc9bf" ,float:"left", width:width}}>{y}</span>:<span style={{backgroundColor:"#508efb",float:"left", width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },{
        //     title: 'Mar',
        //     dataIndex: 'Mar',
        //     key: 'Mar',
        //     width: '70px',
        // },{
        //     title: 'April',
        //     dataIndex: 'April',
        //     key: 'April',
        //     width: '70px',
        // },{
        //     title: 'May',
        //     dataIndex: 'May',
        //     key: 'May',
        //     width: '70px',
        // },{
        //     title: 'Jun',
        //     dataIndex: 'Jun',
        //     key: 'Jun',
        //     width: '70px',
        // },
        // {
        //     title: 'Jul',
        //     dataIndex: 'Jul',
        //     key: 'Jul',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Aug',
        //     dataIndex: 'Aug',
        //     key: 'Aug',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Sep',
        //     dataIndex: 'Sep',
        //     key: 'Sep',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Oct',
        //     dataIndex: 'Oct',
        //     key: 'Oct',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Nov',
        //     dataIndex: 'Nov',
        //     key: 'Nov',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Dec',
        //     dataIndex: 'Dec',
        //     key: 'Dec',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // }

    ];
    const tableData2 = [];
    // const tableData2 = [
    //     {hr:0,Jan:[12,332,4,3,2,5,6],Feb:[12,4,3,2,5,6],},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6],humi:[12,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6],humi:[12,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    //     {hr:0,Jan:[12,4,3,2,5,6],Feb:[12,4,3,2,5,6],winddir:[12,332,4,3,2,5,6]},
    // ];
    const columns2 = [
        {
            title: 'hr',
            dataIndex: 'hr',
            key: 'hr',
            width: '10px',
            // height:'50px'
        },
        {
            title: 'Jan',
            dataIndex: 'Jan',
            key: 'Jan',
            width: '10px',
            render: (text, record) => {
                // let res = ``;
                // r.map(y=>(y>1?res+=`<span style={{backgroundColor:"#fbc9bf"}}>{text}</span>`:`<span style={{backgroundColor:"#508efb"}}>{text}</span>`))(text)
                if(!text){
                    return;
                }
                let width = (100/text.length+0.01).toFixed(2)+'%';

                return <div style={{width:'50px'}}>{r.map(y=>{
                    return (y>10?<span style={{backgroundColor:"#fbc9bf" ,float:"left", width:width}}>{y}</span>:<span style={{backgroundColor:"#508efb",float:"left", width:width}}>{y}</span>)
                })(text)}</div>
                // if(text > 1){
                //     // return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}({record.total_1})</span>
                //     return '<div><span style={{backgroundColor:"#fbc9bf"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span></div>'
                // }else{
                //     return <div><span style={{backgroundColor:"#508efb"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span><span style={{backgroundColor:"#fbc9bf"}}>{text}</span></div>
                // }
            },
        }
        ,{
            title: 'Feb',
            dataIndex: 'Feb',
            key: 'Feb',
            width: '10px',
            render: (text, record) => {
                if(text){
                    let width = (100/text.length-0.01).toFixed(2)+'%';
                    return <div>{r.map(y=>{
                        return (y>10?<span style={{backgroundColor:"#fbc9bf" ,float:"left", width:width}}>{y}</span>:<span style={{backgroundColor:"#508efb",float:"left", width:width}}>{y}</span>)
                    })(text)}</div>
                }else{
                    return ''
                }

            },
        },
        {
            title: '234',
            dataIndex: 'memo',
            key: 'memo',
            // width: '10px',
            // height:'50px'
        },
        // {
        //     title: 'Mar',
        //     dataIndex: 'Mar',
        //     key: 'Mar',
        //     width: '70px',
        // },{
        //     title: 'April',
        //     dataIndex: 'April',
        //     key: 'April',
        //     width: '70px',
        // },{
        //     title: 'May',
        //     dataIndex: 'May',
        //     key: 'May',
        //     width: '70px',
        // },{
        //     title: 'Jun',
        //     dataIndex: 'Jun',
        //     key: 'Jun',
        //     width: '70px',
        // },
        // {
        //     title: 'Jul',
        //     dataIndex: 'Jul',
        //     key: 'Jul',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Aug',
        //     dataIndex: 'Aug',
        //     key: 'Aug',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Sep',
        //     dataIndex: 'Sep',
        //     key: 'Sep',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Oct',
        //     dataIndex: 'Oct',
        //     key: 'Oct',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Nov',
        //     dataIndex: 'Nov',
        //     key: 'Nov',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // },
        // {
        //     title: 'Dec',
        //     dataIndex: 'Dec',
        //     key: 'Dec',
        //     width: '70px',
        //     // filters: (item) => transWinddDir(item),
        //     render: (text, record) => {
        //         if(text){
        //             let width = (100/text.length-0.01).toFixed(2)+'%';
        //             return <div style={{height:'100%'}}>{r.map(y=>{
        //                 return (y>10?<span style={{backgroundColor:colorsForRadia[3] ,float:"left",height:'110%', width:width}}>{y}</span>:<span style={{backgroundColor:colorsForRadia[1],float:"left",height:'110%', width:width}}>{y}</span>)
        //             })(text)}</div>
        //         }else{
        //             return ''
        //         }
        //
        //     },
        // }

    ];
    let i = 0;
    r.map(y=>columns.push(
        {
        title: y,
        dataIndex: y,
        key: y+(i++),
        width: '100px',
        render: (text, record) => {
            // console.log('y');
            // console.log(y);
            if(!text){
                return;
            }
            let width = (100/(text.length)).toFixed(2)+'%';
            // console.log('width');
            // console.log(width);
            // width = '1%';
            return <div>{r.map(y=>{
                // console.log('y');
                // console.log(y);
                return <span style={{backgroundColor:getColor2(y) ,float:"left",height:'28px', width:width}}></span>
                // return <span style={{backgroundColor:getColor(y) ,float:"left",height:'28px', width:width}}>{y}</span>
            })(text)}</div>
        },
    }
    ))(monthArr);
    // const tableData2 = [{temp:12,winddir:2},{temp:3,winddir:2}];
    // antd 这个控件感觉不好用，这里就
    columns.push({
        title: '',
        dataIndex: 'memo',
        key: 'memo',
        // width: '10px',
        // height:'50px'
    });

    console.log('winddirdata-----');
    console.log(winddirdata);

    // rgb to hex
    function rgbToHex(r, g, b){
        var hex = ((r<<16) | (g<<8) | b).toString(16);
        return "#" + new Array(Math.abs(hex.length-7)).join("0") + hex;
    }

    // hex to rgb
    function hexToRgb(hex){
        var rgb = [];
        for(var i=1; i<7; i+=2){
            rgb.push(parseInt("0x" + hex.slice(i,i+2)));
        }
        return rgb;
    }

    // 计算渐变过渡色
    function gradient (step){
        // 将 hex 转换为rgb
        var eColor = hexToRgb('#97e7ff'),
            sColor = hexToRgb('#2a5fcf');
        var total = 100;
        // 计算R\G\B每一步的差值
        var rStep = (eColor[0] - sColor[0]) / total,
        gStep = (eColor[1] - sColor[1]) / total,
        bStep = (eColor[2] - sColor[2]) / total,
            st = (step>total)?total:step;
        // console.log('st | '+st + 'r:'+parseInt(rStep*st+sColor[0])+'| g: '+parseInt(gStep*st+sColor[1])+  '| b: ' + parseInt(bStep*st+sColor[2]));

            // 计算每一步的hex值
        return  (rgbToHex(parseInt(rStep*st+sColor[0]),parseInt(gStep*st+sColor[1]),parseInt(bStep*st+sColor[2])));


    }

    const tableTop = {
        result: tableData.length,
        liData: ['区间', '选择'],
        // liData: ['区间', '搜索',],
        from,
        to,
        select,
        deviceType,
        columns,
        tableData,
        handleScaleChange: ({deviceType, from, to}) => {
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                deviceType,
                from,
                to
            }))
        },
    };

    //创建温度等用的markarea颜色
    let getMarkColor = {
        '01':'rgb(0,0,128)',
        '02':'rgb(0,0,128)',
        '03':'rgb(0,0,0)',
        '04':'rgb(0,0,0)',
        '05':'rgb(0,0,0)',
        '06':'rgb(0,0,0)',
        '07':'rgb(0,0,128)',
        '08':'rgb(0,0,128)',
        '09':'rgb(0,0,128)',
        '10':'rgb(0,0,0)',
        '11':'rgb(0,0,0)',
        '12':'rgb(0,0,128)',

    }
    let markAearData = [];
    let lastkey;
    for(var key in reacordX){
        if(reacordX[key].length === 2){
            markAearData.push([{
                name: key,
                itemStyle:{
                    color:getMarkColor[key.substr(5,2)]
                },
                xAxis: lastkey?reacordX[lastkey][1]:reacordX[key][0]
            }, {
                xAxis: reacordX[key][1]
            }]);
        }
        lastkey=key;
    }
    console.log('markAearData-----');
    console.log(markAearData);
    //x 坐标轴，每个月仅仅显示一个，所以用一个来记录，如果已经存在了，就不再进行添加
    let xFormat = {
        '01':'1月',
        '02':'2月',
        '03':'3月',
        '04':'4月',
        '05':'5月',
        '06':'6月',
        '07':'7月',
        '08':'8月',
        '09':'9月',
        '10':'10月',
        '11':'11月',
        '12':'12月',
    };
    let xFormatRec = [];

    console.log('xFormatRec');
    console.log(xFormatRec);


    let getOption = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },
        legend: {
            textStyle: {
                color: '#443c22'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' ,backgroundColor: '#85542f'},
        xAxis: {
            axisLabel: {
                color: '#429eff',
                interval:10,
                formatter : function(params){
                    // console.log('params ----');
                    // console.log(params);
                    if(xFormatRec.indexOf(params.substr(0,7))!== -1){
                        return ''
                    }else{
                        xFormatRec.push(params.substr(0,7));
                        console.log('add: '+params.substr(0,4)+'年'+xFormat[params.substr(5,2)]);
                        return params.substr(0,4)+'年'+xFormat[params.substr(5,2)]
                    }
                }
            },

            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: dataX,
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#4b441a' }
        },
        series: [{
            type: 'line',
            name:nameType,
            // name: color,
            data: dataY,
            smooth: true,
            markArea: {
                data:markAearData
                    // r.map(y=>
                    // [{
                    //     name: '1月',
                    //     itemStyle:{
                    //         color:'#1fc677'
                    //     },
                    //     xAxis: '2020-05-01-00'
                    // }, {
                    //     xAxis: '2020-05-04-00'
                    // }]
                    // )(reacordX)


                    // [{
                    //     name: '1月',
                    //     itemStyle:{
                    //         color:'#1fc677'
                    //     },
                    //     xAxis: '2020-05-01-00'
                    // }, {
                    //     xAxis: '2020-05-04-00'
                    // }]

                // data: [
                //     [{
                //     name: '1月',
                //     itemStyle:{
                //         color:'#1fc677'
                //     },
                //     xAxis: '2020-00-01-00'
                // }, {
                //     xAxis: '2020-01-01-00'
                // }],[{
                //     name: '2月',
                //     itemStyle:{
                //         color:'#89c641'
                //     },
                //     xAxis: '2020-01-01-00'
                // }, {
                //     xAxis: '2020-02-01-00'
                // }],[{
                //     name: '3月',
                //     itemStyle:{
                //         color:'#c0c630'
                //     },
                //     xAxis: '2020-02-01-00'
                // }, {
                //     xAxis: '2020-03-01-00'
                // }],
                //     [{
                //     // name: '4月',
                //     itemStyle:{
                //         color:'#c6a04c'
                //     },
                //     xAxis: '2020-03-01-00'
                // }, {
                //     xAxis: '2020-04-01-00',
                // }],  [{
                //         xAxis: '2020-04-01-00',
                //         itemStyle:{
                //             color:'yellow'
                //
                //         },
                //     }, {
                //         xAxis: '2020-05-01-00',
                //
                //     }], [{
                //     name: '6月',
                //     itemStyle:{
                //         color:'#b6c5c6'
                //     },
                //     xAxis: '2020-05-01-00'
                // }, {
                //     xAxis: '2020-05-08-00'
                // }],
                //     [{
                //     name: '7月',
                //     itemStyle:{
                //         color:'#c645bf'
                //     },
                //     xAxis: '2020-06'
                // }, {
                //     xAxis: '2020-07'
                // }], [{
                //     name: '8月',
                //     itemStyle:{
                //         color:'#4369c6'
                //     },
                //     xAxis: '2020-07'
                // }, {
                //     xAxis: '2020-08'
                // }], [{
                //     name: '9月',
                //     itemStyle:{
                //         color:'#3aa9c6'
                //     },
                //     xAxis: '2020-08'
                // }, {
                //     xAxis: '2020-09'
                // }], [{
                //     name: '10月',
                //     itemStyle:{
                //         color:'#48c6b9'
                //     },
                //     xAxis: '2020-09'
                // }, {
                //     xAxis: '2020-10'
                // }], [{
                //     name: '11月',
                //     itemStyle:{
                //         color:'#84c6b9'
                //     },
                //     xAxis: '2020-10'
                // }, {
                //     xAxis: '2020-11'
                // }], [{
                //     name: '12月',
                //     itemStyle:{
                //         color:'#83c68b'
                //     },
                //     xAxis: '2020-11'
                // }, {
                //     xAxis: '2020-12'
                // }]
                // ]
            },
            itemStyle: {
                normal: {
                    color: '#78bbfd'
                }
            },
        },{
            type: 'line',
            name:nameType,
            // name: color,
            data: avgData,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#78bbfd'
                }
            },
        }]
    }
    let getOption2 = {
        backgroundColor: '#12cf96',
        angleAxis: {
            type: 'category',
            axisLine: {
                show: true,
                lineStyle: {
                    color: '#00567D'}
            },
            axisLabel: {
                color: '#fff'
            },
            splitLine: {
                lineStyle: {
                    color: '#00567D'
                }
            },
            data: ['N','NNE','NE','ENE','E','ESE','SE','SSE','S','SSW','SW','WSW','W','WNW','NW','NNW']
        },
        radiusAxis: {
        },
        polar: {
            // tooltip: {
            //     backgroundColor: '#121212',
            // }
        },
        tooltip: {
            trigger: 'item',
            formatter: function (params, ticket, callback) {
                return params.name + ' : '  + params.data + '%'
            }
        },
        series: [{
            type: 'bar',
            data: [1, 2, 3, 3, 3, 5, 1, 2, 3, 3, 3, 5, 1, 2, 3, 3],
            coordinateSystem: 'polar',
            name: 'A',
            stack: 'a',
            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var colorList = ['#12cf96', '#12cf96', '#f3f3f3', '#12cf96', '#2a5fcf', '#2a5fcf', '#2a5fcf']
                        return colorList[params.dataIndex]
                    }
                }
            }
        }, {
            type: 'bar',
            data: [2, 4, 6, 1, 3, 2, 1, 2, 3, 3, 3, 5, 1, 2, 3, 3],
            coordinateSystem: 'polar',
            name: 'B',
            stack: 'a',
            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var colorList = ['#97e7ff', '#75f5ff', '#00deff', '#0093ff', '#2a5fcf']
                        return colorList[params.dataIndex]
                    }
                }
            }
        }, {
            type: 'bar',
            data: [1, 2, 3, 4, 1, 2, 5, 2, 3, 3, 3, 5, 1, 2, 3, 3],
            coordinateSystem: 'polar',
            name: 'C',
            stack: 'a'
        }],
        legend: {
            show: true,
            data: ['A', 'B', 'C']
        }
    };

    // var windDirData = [
    //     [6000, 8000,7111],
    //     [4000, 10000, 6825],
    //     [3000, 6500, 4463.33],
    //     [2500, 5600, 3793.83],
    //     [2000, 4000, 3060],
    //     [2000, 4000, 3222.33],
    //     [2500, 4000, 3133.33],
    //     [1800, 4000, 3100],
    //     [2000, 3500, 2750],
    //     [2000, 3000, 2500],
    //     [1800, 3000, 2433.33],
    //     [2000, 2700, 2375],
    //     [1500, 2800, 2150],
    //     [1500, 2300, 2100],
    //     [1600, 3500, 2057.14],
    //     [1500, 2600, 2037.5],
    //     [1500, 2417.54, 1905.85],
    //     [1500, 2000, 1775],
    //     [1500, 1800, 1650]
    // ];
    // var cities = ['北京', '上海', '深圳', '广州', '苏州', '杭州', '南京', '福州', '青岛', '济南', '长春', '大连', '温州', '郑州', '武汉', '成都', '东莞', '沈阳', '烟台'];
    var dirs = ['N','NNE','NE','ENE','E','ESE','SE','SSE','S','SSW','SW','WSW','W','WNW','NW','NNW'];
    var barHeight = 50;

    let colorlist=['#feeecd', '#fce9b1', '#fcde9e',  '#fcd786', '#fad06c','#fcc852', '#fbbf3d',  '#fab921', '#fbb20e', '#fbb110' ];
    let colorlist2=['#d5d1fe', '#deaffc', '#ba6cfc',  '#b54afc', '#a821fa','#f7a3fc', '#fb98f3',  '#429eff', '#f557fb', '#fb38d5' ];
    var seriesLabel = {
        normal: {
            show: true,
            textBorderColor: '#333',
            textBorderWidth: 2
        }
    }
    let getOption3 = {
        title: {
            text: '                                                                               风向玫瑰图',
            subtext: '                                                                      每天的采集数据中，各风向的统计平均值作为分析数据'
        },
        legend: {
            show: true,
            data: ['风速范围', '均值']
        },
        grid: {
            top: 100
        },
        color:["#0001F7","#00B8FE","#00FFFF","#00FF68","#BEFE00","#FFFF00","#FFA800","#E10100"],
        angleAxis: {
            type: 'category',
            data: dirs,
            startAngle:101.25,
        },
        axisLabel:{
            show:false,
            interval:5,//坐标轴刻度标签的显示间隔，在类目轴中有效
        },
        // label: {
        //     show: true,
        //     position: 'inside',
        //     formatter: function (params) {
        //         return '23';
        //     }
        // },

        tooltip: {
            show: true,
            formatter: function (params) {
                var id = params.dataIndex;
                var sid = params.componentIndex;
                // return winddirdata[id] + '<br>最低：' + winddirdata[id][parseInt(sid/2)*4] + '<br>最高：' + winddirdata[id][parseInt(sid/2)*4+1] + '<br>平均：' + winddirdata[id][parseInt(sid/2)*4+2];
                // console.log('low' + sid);
                // console.log(parseInt(sid/2)*4);
                sid=transSid(sid);
                if(sid==4){
                    return '总频率：' + winddirdata[id][sid*4+3];
                }else{
                    return '<br>风速：' + winddirdata[id][sid*4] + '~' + winddirdata[id][sid*4+1] + 'm/s<br>平均风速：' + (winddirdata[id][sid*4+2]).toFixed(2)+ '<br>频率：' + winddirdata[id][sid*4+3];
                }

            }
        },
        radiusAxis: {
            axisLine: { //坐标轴轴线设置
                show: true,
                lineStyle: {
                color: 'rgba(128, 128, 128, 0.2)'
                }
                // symbolOffset : [500, 10]
            },
            minorTick:{
                show:true,
                length:0,
            },
            axisLabel:{
                show:true
            },
            axisTick:{
                show:true
            }
        },
        polar: {

        },
        series: [
            // 第一圈
            {
            type: 'bar',
            barCategoryGap:0,
            itemStyle: {
                color: 'transparent'
            },
            data: winddirdata.map(function (d) {
                // return d[0];
                // return (d.length > 0)?(d[0]+0.5):0;
                return 0.5;
            }),
            coordinateSystem: 'polar',
            stack: '一',
            silent: true,
            // yAxis:{
            // show:false
            // }
        },
            //第二圈的空白快
            {
            type: 'bar',
            barCategoryGap:0,
            itemStyle: {
                color: 'transparent'
            },
            data: winddirdata.map(function (d) {
                // return d[0];
                // return (d.length > 0)?(d[0]+0.5):0;
                return (d.length > 1)?((d[1]==0)?0.5:0):0;
                // return (d.length > 1)?((d[1] - d[0])==0?0.5:0):0;
            }),
            coordinateSystem: 'polar',
            stack: '一',
            silent: true,
                // yAxis:{
                //     show:false
                // }
        },
            //第1圈的数据块
            {
            type: 'bar',
            barCategoryGap:0,
            data: winddirdata.map(function (d) {
                // return d[1] - d[0];
                // console.log(d[1],d[0]);
                return (d.length > 1)?((d[1] == 0?0:0.5)):0;
                // return (d.length > 1)?(d[1] - d[0]):0;
            }),
                // tooltip: {
                //     show: true,
                //     formatter: function (params) {
                //         return '12312';
                //     }
                // },
            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var id = params.dataIndex;
                        var sid = params.componentIndex;
                        sid=transSid(sid);
                        return transColor(id,sid,winddirdata,colorlist);
                    }
                }
            },
            coordinateSystem: 'polar',
            name: '一',
            stack: '一',
                // yAxis:{
                //     show:false
                // }
        },
            //第2圈类型：1   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            itemStyle: {
                color: 'transparent'
            },
            data: winddirdata.map(function (d) {
                // return d[4];
                return (d.length > 5)?((d[5]==0)?1:0):0;
            }),
            coordinateSystem: 'polar',
            stack: '一',
            silent: true
        },
            //第2圈类型：2   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            data: winddirdata.map(function (d) {
                // return d[5] - d[4];
                return (d.length > 5)?(d[5] - d[4]):0;
            }),
            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var id = params.dataIndex;
                        var sid = params.componentIndex;
                        sid=transSid(sid);
                        return transColor(id,sid,winddirdata,colorlist);
                    }
                }
            },
            coordinateSystem: 'polar',
            name: '二',
            stack: '一'
        },
            //第3圈类型：1   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            itemStyle: {
                color: 'transparent'
            },
            data: winddirdata.map(function (d) {
                // return d[8];
                // return (d.length > 8)?(d[8] ):0;
                return (d.length > 9)?((d[9]==0)?1:0):0;
            }),
            coordinateSystem: 'polar',
            stack: '一',
            silent: true
        },
//第3圈类型：2   (1：空白块 ，2：数据块)

            {
            type: 'bar',
            barCategoryGap:0,
            data: winddirdata.map(function (d) {
                // return d[9] - d[8];
                return (d.length > 9)?(d[9] - d[8]):0;
            }),
            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var id = params.dataIndex;
                        var sid = params.componentIndex-1;
                        // console.log('floor' + Math.floor((sid*4+3)/100));
                        sid=transSid(sid);
                        return transColor(id,sid,winddirdata,colorlist);
                    }
                }
            },
            coordinateSystem: 'polar',
            name: '三',
            stack: '一'
        },
            //第4圈类型：1   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            itemStyle: {
                color: 'transparent'
            },
            data: winddirdata.map(function (d) {
                // return d[12];
                // return (d.length > 13)?(d[13] - d[12]):0;
                return (d.length > 13)?((d[13]==0)?1:0):0;
            }),
            coordinateSystem: 'polar',
            stack: '一',
            silent: true
        },
            //第4圈类型：2   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            data: winddirdata.map(function (d) {
                // return d[13] - d[12];
                return (d.length > 13)?(d[13] - d[12]):0;
            }),
            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var id = params.dataIndex;
                        var sid = params.componentIndex;
                        sid=transSid(sid);
                        return transColor(id,sid,winddirdata,colorlist);
                    }
                }
            },
            coordinateSystem: 'polar',
            name: '四',
            stack: '一'
        },

            //第5圈类型：1   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            itemStyle: {
                color: 'transparent'
            },
            data: winddirdata.map(function (d) {
                // return d[16];
                return (d.length > 17)?((d[17]==0)?1:0):0;
            }),
            coordinateSystem: 'polar',
            stack: '一',
            silent: true
        },
            //第5圈类型：2   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            data: winddirdata.map(function (d) {
                return (d.length > 17)?(d[17] - d[16]):0;
            }),

            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var id = params.dataIndex;
                        var sid = params.componentIndex;
                        sid=transSid(sid);
                        return transColor(id,sid,winddirdata,colorlist);
                    },
                    label:{
                        show:true,
                        position:'top',
                        formatter:function(params){ //标签内容
                            return  params.dataIndex
                        },
                        textStyle: {
                            fontSize: 14
                        }
                    },
                    // label: {
                    //     show: true,
                    //     position: 'inside'
                    // },
                }
            },
            coordinateSystem: 'polar',
            name: '五',
            stack: '一',
                // label: {
                //     show: true,
                //     position: 'inside'
                // },
        },
            //第6圈类型：1   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            itemStyle: {
                color: 'transparent'
            },
            data: winddirdata.map(function (d) {
                // return (d.length > 20)?(d[20]):0;
                return (d.length > 21)?((d[21]==0)?1:0):0;
            }),
            coordinateSystem: 'polar',
            stack: '一',
            silent: true
        },
            //第6圈类型：2   (1：空白块 ，2：数据块)
            {
            type: 'bar',
            barCategoryGap:0,
            data: winddirdata.map(function (d) {

                return (d.length > 21)?(d[21] - d[20]):0;

            }),
            itemStyle: {
                normal: {
                    // 定制显示（按顺序）
                    color: function (params) {
                        var id = params.dataIndex;
                        var sid = params.componentIndex;
                        sid=transSid(sid);
                        return transColor(id,sid,winddirdata,colorlist);
                    }
                }
            },
            coordinateSystem: 'polar',
            name: '六',
            stack: '一'
        },
        //     // 7 圈是统计圈
        //     //第7圈类型：1   (1：空白块 ，2：数据块)
        //     {
        //         type: 'bar',
        //         barCategoryGap:0,
        //         itemStyle: {
        //             color: 'transparent'
        //         },
        //         data: winddirdata.map(function (d) {
        //             return (d.length > 25)?(d[25]):0;
        //         }),
        //         coordinateSystem: 'polar',
        //         stack: '一',
        //         silent: true
        //     },
        //     //第7圈类型：2   (1：空白块 ，2：数据块)
        //     {
        //         type: 'bar',
        //         barCategoryGap:0,
        //         data: winddirdata.map(function (d) {
        //
        //             return (d.length > 25)?(d[25] - d[24]):0;
        //         }),
        //         itemStyle: {
        //             normal: {
        //                 // 定制显示（按顺序）
        //                 color: function (params) {
        //                     var id = params.dataIndex;
        //                     var sid = params.componentIndex;
        //                     sid=transSid(sid);
        //                     return transColor(id,sid,winddirdata,colorlist);
        //                 }
        //             }
        //         },
        //         coordinateSystem: 'polar',
        //         name: '七',
        //         stack: '一'
        //     }


        ]
    };


    if(deviceType == 'winddir'){
        console.log('winddir 1');

    }else{
        console.log('winddir 12');
    }


    return (
        <div className='reportRightTableWeather' >
            <TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}
                      tableData={tableData}/>
            {/*{/*notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate}*/}
            <div style={{width: "100%", height: "500px",display:(deviceType != 'temp')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'temp')?'block':'none'}} className='temp' />
            </div>
            <div style={{width: "100%", height: "500px",display:(deviceType != 'humi')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'humi')?'block':'none'}} className='humi' />
            </div>
            <div style={{width: "100%", height: "500px",display:(deviceType != 'dew')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'dew')?'block':'none'}} className='dew' />
            </div>
            <div style={{width: "100%", height: "500px",display:(deviceType != 'atmos')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'atmos')?'block':'none'}} className='atmos' />
            </div>
            <div style={{width: "100%", height: "500px",display:(deviceType != 'windspeed')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'windspeed')?'block':'none'}} className='windspeed' />
            </div>
            <div style={{display:(deviceType != 'radia')?'none':'block'}}>
                <div style={{width:'80%',height:'100%', float:'left'}}>
                    <Table
                    columns={columns}
                    dataSource={tableData4}
                    // columns={columns2}
                    // dataSource={tableData2}
                    scroll={{y: 710,x:100}}
                    pagination={false}
                    bordered
                    // loading={loading}
                    rowKey='hr'
                    className='reportRightTableTabWeather'/>

                </div>
                <div style={{width:'15%',height:'100%', float:'left',marginLeft:'5px'}}>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(255,255,0)',float:'left',marginTop:'2px',lineHeight: '60px'}}>1300</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(255,226,0)',float:'left',marginTop:'2px',lineHeight: '60px'}}>1170</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(255,173,0)',float:'left',marginTop:'2px',lineHeight: '60px'}}>1040</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(255,119,0)',float:'left',marginTop:'2px',lineHeight: '60px'}}>910</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(255,66,0)',float:'left',marginTop:'2px',lineHeight: '60px'}}>780</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(255,12,0)',float:'left',marginTop:'2px',lineHeight: '60px'}}>650</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(214,0,41)',float:'left',marginTop:'2px',lineHeight: '60px'}}>520</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(160,0,95)',float:'left',marginTop:'2px',lineHeight: '60px'}}>390</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(107,0,148)',float:'left',marginTop:'2px',lineHeight: '60px'}}>260</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(53,0,202)',float:'left',marginTop:'2px',lineHeight: '60px'}}>130</span>
                    <span style={{width:'60%',height:'60px', backgroundColor:'rgb(0,0,255)',float:'left',marginTop:'2px',lineHeight: '60px'}}>0</span>
                </div>

            </div>
            <div style={{width: "100%", height: "100%",display:(deviceType != 'winddir')?'none':'block' ,marginTop:'5%'}}>
                <div style={{ width: "80%", height: "800px",display:(deviceType != 'winddir')?'none':'block',float:'left'}}>
                    <ReactEcharts option={getOption3}  style={{ 'width': "100%", 'height': "80%",display:(deviceType != 'winddir')?'none':'block'}}  className='winddir' />
                </div>
                <div style={{ width: "16%", height: "100%",display:(deviceType != 'winddir')?'none':'block',float:'left'}}>
                    <p style={{ backgroundColor:'#e8dfeb', marginBottom:'17%'}}>风向玫瑰图,实际查询天数：{cnt} 天</p>
                    <span style={{ backgroundColor:'#feeecd',marginLeft:'38%',width:'30px'}}> {'hrs'}</span><br/>
                    <span style={{ backgroundColor:'#feeecd',marginLeft:'38%',width:'30px'}}> {'<5'}</span><br/>
                    <span style={{ backgroundColor:'#fcde9e',marginLeft:'38%',width:'30px'}}> {'5~10'}</span><br/>
                    <span style={{ backgroundColor:'#fad06c',marginLeft:'38%',width:'30px'}}> {'10~15'}</span><br/>
                    <span style={{ backgroundColor:'#fbbf3d',marginLeft:'38%',width:'30px'}}> {'15~20'}</span><br/>
                    <span style={{ backgroundColor:'#fab921',marginLeft:'38%',width:'30px'}}> {'20~25'}</span><br/>
                    <span style={{ backgroundColor:'#e7af30',marginLeft:'38%',width:'30px'}}> {'25~30'}</span><br/>
                    <span style={{ backgroundColor:'#fbb92d',marginLeft:'38%',width:'30px'}}> {'30~35'}</span><br/>
                    <span style={{ backgroundColor:'#faa51b',marginLeft:'38%',width:'30px'}}> {'35~40'}</span><br/>
                    <span style={{ backgroundColor:'#fb9b15',marginLeft:'38%',width:'30px'}}> {'40~45'}</span><br/>
                    <span style={{ backgroundColor:'#fb951e',marginLeft:'38%',width:'30px'}}> {'>45'}</span>
                </div>

            </div>
            <div style={{width: "100%", height: "500px",display:(deviceType != 'pm10')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'pm10')?'block':'none'}} className='temp' />
            </div>
            <div style={{width: "100%", height: "500px",display:(deviceType != 'pm2d5')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'pm2d5')?'block':'none'}} className='temp' />
            </div>
            <div style={{width: "100%", height: "500px",display:(deviceType != 'negOxygen')?'none':'block' ,marginTop:'5%'}}>
                <ReactEcharts option={getOption}  style={{ 'width': "100%", 'height': "80%",display:(deviceType == 'negOxygen')?'block':'none'}} className='temp' />
            </div>

        </div>
    )
}


export default weatherDateInteval;
