import React, { useContext } from 'react';
import * as r from 'ramda';
import { Spin } from 'antd';
import TreeView from 'src/components/treeView/tree';
// import 'src/modules/energy/analysis/analysis_left/analysisNavigator.scss';
import { context, loadNodeUsage } from '../building'
import { of } from 'rxjs'

const onSelect = (node) => (event) => {
    console.log(`node ${r.head(event)} selected`)

    return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
        (state) => loadNodeUsage({ ...state, loading: true, node: r.head(event) })
}

const BuildingNavigator = () => {
    const { state, dispatch } = useContext(context)
    console.log(state);
    return (
        <div className='buildingLeft'>
            <div className="equipmentLeftTit">
                建筑分析
            </div>
            {
                r.isEmpty(state.tree) ?
                    <Spin /> :
                    <TreeView data={state.tree}
                        defaultExpandedKeys={[state.node]}
                        onSelect={e => {
                            dispatch(state => of(r.assoc('loading', true)(state)))
                            dispatch(onSelect(state.node)(e))
                        }} />}
        </div>
    )
}

export default BuildingNavigator;