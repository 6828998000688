import React from 'react';
import * as r from 'ramda';
import { Spin, Row, Col } from 'antd'

import WaterMeter from './waterMeter/waterMeter'
import './networkDiagram.scss'
import Api from 'api';
import WaterJson from './water.js';

import Img_waterBg1 from 'img/water/index1.png';
import Img_waterBg2 from 'img/water/index2.png';
// import Index from '../../index';
const imgObj = {
    1: Img_waterBg1,
    2: Img_waterBg2
}

class NetworkDiagram extends React.Component {

    state = {
        waterMeterArr: [],
        img: '',
        loading: false,
        page2Arr: []
    }

    componentDidMount() {
        this.get();
    }

    get = () => {
        this.setState({
            loading: true
        })
        const urlParameterArr = this.props.location.pathname.split('/');
        const urlParameter = urlParameterArr[urlParameterArr.length - 1];
        this.setState({
            img: urlParameter
        })
        Api.post('/business/monitor', {
            devicetype: "ULTRACOLDWATERMETER",
            project: Api.projectId,
            ext: {
                enableMask: 1
            },
            mode: "SENSOR",
            pagesize: 1000
        }).then((data) => {
            const path = r.pathOr([], ['data', 'result', Api.projectId, 'detail'], data);
            const json = r.filter(n => n.id !== '')(WaterJson['page_' + urlParameter])
          
            const arr = r.map(v => r.pickAll(['position', 'channels', 'title', 'addrid'])(r.assoc('position', v.position)(r.find(x => x.addrid.indexOf(v.id) != -1)(path))))(json)

            const jsonD = [
                {
                    id: "330200A01303000068120449003", name: "一期公寓食堂低压"
                },
                {
                    id: "330200A01303000068120447002", name: "一期公寓食堂消防"
                },
                {
                    id: "330200A01303000068120446001", name: "一期公寓食堂高压"
                },
                {
                    id: "330200A01305000068120442005", name: "实训中心二行政低压DN200"
                },
                {
                    id: "330200A01305000068120451007", name: "实训中心二行政消防DN150"
                },
                {
                    id: "330200A01305000068120441006", name: "实训中心二行政高压DN200"
                },
                {
                    id: "330200A01304000068120450003", name: "二期二阶段消防管水表DN150"
                },
                {
                    id: "330200A01304000068120443001", name: "二期二阶段高压水管水表DN200"
                },
                {
                    id: "330200A01304000068120445002", name: "二期二阶段高压水管水表DN200"
                },
                {
                    id: "330200A01302000068120448001", name: "实训中心1环网表"
                },
                {
                    id: "051114331", name: "西养鱼池"
                },
                {
                    id: "051114333", name: "东养鱼池"
                },
            ]
            const page2Arr = r.map(v => r.pickAll(['position', 'channels', 'title', 'addrid', 'isExtra'])(r.assoc('isExtra', true)(r.find(x => x.id.indexOf(v.id) != -1)(path))))(jsonD)

            this.setState({
                waterMeterArr: arr,
                loading: false,
                page2Arr
            })
        })
    }

    render() {
        return (
            <div className="networkDiagram">
                <Spin spinning={this.state.loading} size="large" >
                    <div className='networkDiagramImgContainer'>
                        <img src={imgObj[this.state.img]} alt="" className='waterBg' />
                        {this.state.waterMeterArr.map(({
                            addrid,
                            channels,
                            position,
                            title
                        }, i) => <WaterMeter
                                position={position}
                                value={channels ? channels["04"].realdata : 0}
                                status={channels ? channels['04'].status : 0}
                                flowId={channels && channels['04'].id}
                                temperatureId={channels && channels['05'].id}
                                title={title}
                                id={addrid}
                                key={i}
                            />)}
                        {
                            this.state.img === '2' ?
                                <Row gutter={42} className='page2AdditionalData'>
                                    {
                                        this.state.page2Arr.map(({ addrid,
                                            channels,
                                            title, isExtra }, i) =>
                                            <Col key={i} span={8}>
                                                <WaterMeter
                                                    value={channels ? channels["04"].realdata : 0}
                                                    status={channels ? channels['04'].status : 0}
                                                    flowId={channels && channels['04'].id}
                                                    temperatureId={channels && channels['05'].id}
                                                    title={title}
                                                    id={addrid}
                                                    key={i}
                                                    isExtra={isExtra}
                                                    position={{ position: 'relative' }}
                                                />
                                            </Col>
                                        )
                                    }
                                </Row>
                                :
                                ''
                        }

                    </div>
                </Spin>

            </div >
        )
    }

};


export default NetworkDiagram;