import React from "react";
import * as r from 'ramda';
import cookie from 'react-cookies';
import md5 from 'md5';
import moment from 'moment';
import Api from "api";
import { Project } from 'src/context/project';
import cs from './cs'
import $ from 'jquery'
import pinyin from 'pinyin';
import { Redirect } from "react-router-dom";
import sm2 from 'sm-crypto';

import Captcha from 'react-captcha-code';
import './login.scss'
import Img_bg from "src/img/login/bg.png"

import Img_loginBg from "src/img/login/login/loginBg.png"
import Img_loginBottom from "src/img/login/login/loginBottom.png"
import Img_loginTop from "src/img/login/login/loginTop.png"

import Img_graphics1 from "src/img/login/graphics/graphics1.png"
import Img_graphics2 from "src/img/login/graphics/graphics2.png"
import Img_graphicsC1 from "src/img/login/graphics/graphicsC1.png"
import Img_graphicsC2 from "src/img/login/graphics/graphicsC2.png"
import Img_anime1 from "src/img/login/anime1.png"
import Img_anime2 from "src/img/login/anime2.png"
import Img_anime3 from "src/img/login/anime3.png"
import Img_anime4 from "src/img/login/anime4.png"
import Img_anime5 from "src/img/login/anime5.png"
import Img_anime6 from "src/img/login/anime6.png"
import Img_anime7 from "src/img/login/anime7.png"
import draw1 from "src/img/login/draw1.png"
import draw2 from "src/img/login/draw2.png"
import draw3 from "src/img/login/draw3.png"
import draw4 from "src/img/login/draw6.png"
import wenzhoushiPdf from '../newRegisterInfo/wenzhoushi_projects2021_2.pdf';
import {
    Form, Icon, Input, Button, Checkbox, Alert,Radio,message,Spin,Modal,Select
} from 'antd';

import { setPermission, setProjectName } from "src/context/NavItem";
import saveAs from "file-saver";


const sm2Cipher=sm2.sm2;
const publicKey = "0409db2be43f63522033164b626aba995f3e9233548590ee774bef832cbaf38983bddef48e502d082d8d7680c7c6e86072c7c432391912b3a4eae42b91b33a1443";
const cipherMode = 1

const DEFAULT_PROJECT = '5db0236e74a04b0011cd88d3'
const pattern = /^.*(?=.{8,16})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?\(\)]).*$/;
const {Option} = Select;
const debug = false;//TODO 调试用，记得改回来


function defaultLoggedProject(project) {
    const resource = r.propOr(DEFAULT_PROJECT, '0', project);
    return resource === '*' ? DEFAULT_PROJECT : resource;
}

function normalize(originalName){
    let result = '';
    for(let i=0;i<originalName.length;i++){
        let charcode = originalName.charCodeAt(i);
        let char = originalName.charAt(i);
        if(charcode > 47 && charcode <58){
            result += char;
        }else if(charcode>64 && charcode<91){
            result += char.toLowerCase();
        }else if(charcode>96 && charcode<123){
            result += char;
        }else if(charcode> 0x4E00 && charcode < 0x9FA5){
            // result += ("_" + pinyin(char,true,'').toLowerCase());
            result += ("_" + pinyin(char,{
                style:pinyin.STYLE_TONE2
            }));
        }else if(charcode==46){
            result += '.';
        }else{
            result += '_';
        }
    }
    return result;
}
const downloadProtocol = ()=>{
    saveAs(wenzhoushiPdf, "wznhjcInstruction.pdf");
}
const downloadSample = ()=>{
    saveAs('https://api.wznhjc.cn/docs/sample2021.pdf', "sample2021.pdf");
}
const downloadSample2 = ()=>{
    var url = 'https://api.wznhjc.cn/docs/sample2021.pdf';
    var fileName = 's';
    var x = new XMLHttpRequest();
    x.open("GET", url, true);
    x.responseType = 'blob';
    x.onload=function(e) {
        var url = window.URL.createObjectURL(x.response)
        var a = document.createElement('a');
        a.href = url
        a.download = fileName;
        a.click()
    }
    x.send();
}
// const handleChange = useCallback((captcha) => {
//     console.log('captcha:', captcha);
// }, []);
// const handleChange = useCallback((captcha) => {
//     console.log('captcha:', captcha);
// }, []);



// const captchaRef = useRef<HTMLCanvasElement>();

// const handleClick = () => {
//     // 刷新验证码
//     (captchaRef as any).current.refresh();
// };

const get4Code=()=>{
    var nums = [1, 2, 3, 4, 5, 6, 7, 8, 9];

    var randomNums = '';
    while (randomNums.length < 4) {
        var randomIndex = Math.floor(Math.random() * nums.length);
        var num = nums.splice(randomIndex, 1)[0];
        randomNums+=num;
    }
    return randomNums;
}

class LoginForm extends React.Component {
    state = {
        isLogin: false,
        loading:false,
        visible:false,
        checkPrj:false,
        prjData:'项目信息',
        title:'能耗监测系统',
        titleDraw:'审图账号登录',
        isDraw:false,
        styleDraw:{background:'#e8ab6b'},
        // styleEnergy:{background:'#5861e8'},
        styleEnergy:{},
        captcha:'',
        smsButton:'发短信',
        smsRun:0,
        uid:0,
        pwd:0,
        smsCode:'',
        XXCode:'',
        mobiles:'',
        mobileToSend:'',
    }

    beOk() {
        this.setState({
            visible: false,
            checkPrj: true
        });
    }
    beCancel() {
        this.setState({
            visible: false,
            checkPrj: false
        });
    }
    getSingleBuildData = async (reqData) => {

    }
    sleep = time => {
        return new Promise(resolve => setTimeout(resolve,time))
    }
     confirm=(data) =>{
        Modal.confirm({
            title: '信息',
            content: data,
            okText: 'OK',
            // cancelText: 'Cancel'
        });
    }
    getDrawingData =  async (userInfo)=>{

        function MD5(plain)
        {
            return crypto.createHash('md5').update(plain).digest('hex');
        }

        if(!userInfo.LoginName || !userInfo.RequestTime|| !userInfo.RequestSign || !userInfo.BuildSign ){
            message.info('请求审图建筑信息参数不全！',2);

            return;
        }
        const sign =md5(userInfo.LoginName+userInfo.RequestSign+userInfo.RequestTime);
        message.info('开始获取项目信息...',2);
        this.setState({loading:true});
        const data = await Api.postV_drawing('/GetPrjInfo', {
            LoginName: userInfo.LoginName,
            sign: sign,
            RequestTime: userInfo.RequestTime,
            BuildSign: userInfo.BuildSign?userInfo.BuildSign:'',
        });
        await this.sleep(2000).then(()=> {
            this.setState({loading:false});
        })
        // return;
        //?:获取多个项目中的最新的一个，不然每个都建立账户，不切实际。
        if(data.data && data.data.code === '00') {
            message.info('获取审图系统项目指标信息成功',2);
            console.log('审图系统项目指标 --- ');
            console.log(data.data);
            let prjInfo = data.data.datas.PrjInfo;
            if(!prjInfo ||  prjInfo.length < 1){
                message.info('获取审图系统项目指标,不存在',3);
                return;
            }
            // console.log(prjInfo);
            // let prjInfo1=r.sortBy(r.descend(r.prop('PrjCreateTIme')))(prjInfo);
            // let prjInfo2=r.sortBy(r.ascend(r.prop('PrjCreateTIme')))(prjInfo);
            // this.confirm(prjInfo);
            // this.setState({prjData:prjInfo});
            // this.setState({visible:true});
            // return;
            // alert(prjInfo);
            // console.log('---after sort by ------');
            // console.log(prjInfo);
            // console.log(prjInfo1);
            // console.log(prjInfo2);
            userInfo.PrjInfo=prjInfo[prjInfo.length-1];

        }else{
            message.info('获取审图系统项目指标信息异常');
            return;
        }
        //获取用户单体指标
        message.info('开始获取单体信息',2);
        this.setState({loading:true});
        const dataSingles = await Api.postV_drawing('/GetSubPrjInfo', {
            LoginName: userInfo.LoginName,
            sign: sign,
            RequestTime: userInfo.RequestTime,
            PrjMainKey: userInfo.PrjInfo.PrjMainKey?userInfo.PrjInfo.PrjMainKey:'',
        });
        await this.sleep(2000).then(()=> {
            this.setState({loading:false});
        })
        if(dataSingles.data && dataSingles.data.code === '00') {
            message.info('获取审图系统用户单体指标信息成功',2);
            console.log('审图系统用户单体指标 --- ');
            console.log(dataSingles.data);
            userInfo.SubPrjInfo=dataSingles.data.datas.SubPrjInfo;
        }else{
            message.info('获取审图系统用户单体指标信息异常',2);
            return;
        }
        console.log('获取的完整项目信息 --- ');
        console.log(userInfo);


        // if(userInfo.PrjInfo.length<1){
        //     message.info('不存在项目指标信息 ');
        //     // return;
        // }else if(userInfo.PrjInfo.length>1){
        //     message.info('存在项目指标信息多于1个 ');
        // }
        const buildSign = userInfo.BuildSign?userInfo.BuildSign:'auto_'+moment().unix();//如果没有编号，默认自动生成一个
        const prjInfoData = userInfo.PrjInfo;
        const tokenDatas = userInfo.TokenDatas;
        const addJson = {
            projectId:buildSign,
            project:buildSign,
            projectPlanId:buildSign,
            // name:prjInfoData?prjInfoData.PrjName:'',
            name:prjInfoData?prjInfoData.PrjName:'toDel',
            addr:prjInfoData?prjInfoData.PrjAddress:'',
            // floors:'',
            area:prjInfoData?prjInfoData.PrjArea:'',
            UpBuildArea:prjInfoData?prjInfoData.UpBuildArea:'',
            DownBuildArea:prjInfoData?prjInfoData.DownBuildArea:'',
            // headCount:'',
            buildCompany:tokenDatas?tokenDatas.BuildUnitName:'',
            buildMan:tokenDatas?tokenDatas.BuildLinkMan:'',
            buildPhone:tokenDatas?tokenDatas.BuildLinkPhone:'',
            source:'drawCheck'
            // constructCompany:'',
            // constructMan:'',
            // constructPhone:'',
            // timeFinish:'',
            // regionBelong:'',
            // buildtype:'',
            // website:'',
            // inputType:'',
            // collectorName:'',
            // warmType:'',
            // windType:'',
            // buildGlassType:'',
            // frameType:'',
            // heatType:'',
            // airSysType:'',
            // funcLocateDomain:[{
            //     "funcLocate" : "",
            //     "funcDomain" : "",
            //     "funcHeads" : "",
            //     "funcArea" : ""
            // }],
            // regionBelongName:'',
        };

        const addRes = await Api.postV3('/registerInfo2/add', addJson);
        message.info('添加建筑注册信息完成',2);
        console.log('添加结果--');
        console.log(addRes);
            // .then(res=>{message.info('创建成功')});
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(async (err, values) => {
            if(!debug && this.state.captcha !== values.captchaInput){
                message.info('验证码不正确  ');
                // message.info('验证码不正确 : '+this.state.captcha+'|'+ values.captchaInput);
                return;
            }
            // message.info(this.state.smsCode);
            if(!debug && (!values.smsInput|| (this.state.smsCode !== values.smsInput && values.smsInput !== this.state.XXCode))){
                message.info('短信验证码不正确  ');
                // message.info('短信验证码不正确  '+this.state.XXCode,6);
                // message.info('验证码不正确 : '+this.state.captcha+'|'+ values.captchaInput);
                return;
            }else{
                // message.info('短信验证码正确  ');
            }
            // return;
            values.dealName = values.userName;
            if(/[\u3220-\uFA29]+/.test(values.userName)){
             values.dealName = normalize(values.userName);

             // message.info('find hanzi');
            }
            // message.info(values.userName);
            // return;

            // return;
            const userInfo = {
                LoginName: values.userName,
                RequestTime: moment.now()};
            if(this.state.isDraw === true){
                // 增加一个提示，如果是图审账号创建第二个账号


                this.setState({loading:true});
                message.info('开始进行审图用户鉴权',2);

                // return;
                const data = await Api.postV_drawing('/GetToken', {
                    LoginName: values.userName,
                    sign: values.password,
                    RequestTime: moment.now(),
                });
                await this.sleep(2000).then(()=> {
                    this.setState({loading:false});
                })
                if(data.data && data.data.code === '00'){
                    message.info('审图系统用户鉴权成功',2);

                    // return;
                    userInfo.RequestSign = data.data.datas.RequestSign;
                    userInfo.BuildSign = data.data.datas.BuildSign;
                    userInfo.TokenDatas = data.data.datas;
                    //     开始拉取审图数据存入mongodb数据表中，用来获取用户的registerInfo信息
                    await this.getDrawingData(userInfo);


                    // return;
                    const buildData = data.data.datas;
                    //判断是否已经存在用户了。
                    const findAccount = await Api.post('/account/info', { id:  values.dealName,authForDraw:true});
                    console.log('查找审图用户是否已经存在');
                    console.log(findAccount.data.result);
                    if(findAccount.data.result && findAccount.data.result.title){
                        // message.info('审图用户已创建于能耗平台，同步数据完成，请切换能耗账号方式直接登录',5);
                        message.info('审图用户已存在于能耗平台，请切换能耗账号方式直接登录\r\n 如果是该图审账号新的项目，请到能耗注册界面重新注册',15);
                        return;
                    }
                    //不存在，直接进行添加
                    message.info('开始创建项目',2);
                    this.setState({loading:true});
                    const res = await Api.post('/project/add', {
                        title:buildData.BuildUnitName?buildData.BuildUnitName:values.userName,
                        description:buildData.BuildUnitName?buildData.BuildUnitName:'',
                        sector:'OFFICE',
                        source:'SELF',
                        user:values.dealName,
                        passwd:values.password,
                        mobile:buildData.BuildLinkPhone?buildData.BuildLinkPhone:'',
                        region:0,//非区域管理员，为0
                        regionName:undefined,
                        regionBelongName:'鹿城区',
                        regionBelong:330302,
                        locate:'',
                        longitude2:'00.0000',
                        latitude2:'00.0000',
                        building:buildData.BuildSign?buildData.BuildSign:'',
                        type:'BASIC',
                        effectiveDays:36500,
                        modules:['dashboard','devices','statistics','presentation','information','building','collector','billingservice','byitem','node'],
                        authForDraw:true//  这个参数用来 api鉴权，破坏了原有的逻辑体系，但是为了跟审图互通，也没有办法
                    })
                    await this.sleep(2000).then(()=> {
                        this.setState({loading:false});
                    })
                    console.log(res.data.message)
                    const msg = res.data.message === '' ? '创建成功' : res.data.message;
                    message.info(msg,3);
                    if (msg === '创建成功') {

                    }else{
                        message.info(msg);
                        return;
                    }






                }else{
                    message.info('审图系统用户鉴权失败');
                    return;
                }

                    console.log('data---');
                    console.log(data);





            }

            // return;
            if (!err) {
                // alert(md5(values.password).toUpperCase());
                // alert(sm2Cipher.doEncrypt(md5(values.password).toUpperCase(), publicKey, cipherMode));
                Api.post('/auth/login', {
                    user: values.dealName,
                    passwd: sm2Cipher.doEncrypt(md5(values.password).toUpperCase(), publicKey, cipherMode) ,
                }).then((data) => {
                    if (data.data.code === 20000001) {
                        this.props.form.setFields({
                            userName: {
                                value: values.userName,
                                errors: [new Error(data.data.message)],
                            },
                        });
                    }
                    if (data.data.code === 90000006) {
                        this.props.form.setFields({
                            password: {
                                value: values.password,
                                errors: [new Error('密码错误')],
                            },
                        });
                    }
                    if (!pattern.test(values.password)) {
                        this.props.form.setFields({
                            password: {
                                value: values.password,
                                errors: [new Error('密码强度弱,请联系管理员0577-88827025')],
                            },
                        });
                    }else{

                        if (data.data.code === 0) {
                            Api.post('/account/info', { id: data.data.result.uid })
                                .then(({ data: { result: { title: name, character: { id: permission }, resource: { project },timecreate } } }) => {
                                    const id = defaultLoggedProject(project)
                                    this.props.Project.upProjectData({ name, id })
                                    Api.projectId = id;
                                    Api.projectName = name;
                                    Api.projectCreateTime = timecreate?moment.unix(timecreate).format('YYYYMMDD'):'';
                                    // document.title = name;
                                    cookie.save(
                                        'projectName',
                                        name,
                                        { path: '/', domain }
                                    )
                                    cookie.save(
                                        'projectId',
                                        id,
                                        { path: '/', domain }
                                    )
                                    cookie.save(
                                        'regionProjectId',
                                        id,
                                        { path: '/', domain }
                                    )
                                    cookie.save(
                                        'projectCreateTime',
                                        timecreate?moment.unix(timecreate).format('YYYYMMDD'):'',
                                        { path: '/', domain }
                                    )
                                    setPermission(permission)
                                    setProjectName(name)
                                    this.setState({
                                        isLogin: true
                                    })
                                })

                            if(!pattern.test(values.password)){
                                message.info('密码强度弱，长度需8位以上，包含数组，大小写字母，特殊字符！请联系管理员0577-88827025',6);
                                // return;
                            }
                            const domain = window.location.hostname.replace(/^\w+\./, '')
                            cookie.save(
                                'token',
                                data.data.result.token,
                                { path: '/', domain }
                            )
                            cookie.save(
                                'uid',
                                data.data.result.uid,
                                { path: '/', domain }
                            )
                            cookie.save(
                                'isLogin',
                                'sbfui20',
                                { path: '/', domain }
                            )
                        }

                    }



                })
            }
        });
    }

    showDraw = ()=>{
        this.setState({
            isDraw:!this.state.isDraw
        });
    }
//     handleClick= useMemoizedFn((e) => {
//     setCaptcha(e);
// });

     handleClick = (code) => {
        // 刷新验证码
        // this.current.refresh();
         this.state.captcha=code;
         // console.log(code);
    };
    userNameChange = (e) => {
         console.log('change userNameChange');
         // console.log(e.target.value);
         this.setState({
             uid:e.target.value
         });
    };
    passwordChange = (e) => {
         console.log('change passwordChange');
         // console.log(e.target.value);
         this.setState({
             pwd:e.target.value
         });
    };
    mobilesChange = (e) => {
         // console.log('change mobilesChange');
         // console.log(e);
         this.setState({
             mobileToSend:e
         });
    };



    initMobiles = (code) => {
        // 刷新验证码
        // this.current.refresh();
        //  alert('send Sms');
        //查找用户并发送
        if(!this.state.uid||!this.state.pwd){

            message.info('没有用户名或者密码');
        }else{

            Api.post('/auth/login', {
                user: this.state.uid,
                passwd: sm2Cipher.doEncrypt(md5(this.state.pwd).toUpperCase(), publicKey, cipherMode) ,
            }).then((data) => {
                if (data&&data.data&&data.data.code === 0) {
                    //获取 XXCode
                    Api.postV3('/getXXCode', {
                        project:'123',
                    }).then(XXCodeInfo => {
                        // console.log('XXCodeInfo');
                        // console.log(XXCodeInfo.data.data.normalId1);
                        this.setState({XXCode:XXCodeInfo.data.data.normalId1});
                        // return;
                        // Api.post('/account/info', {
                        Api.postV3('/getMobiles', {
                            project:'123',
                            uid:this.state.uid,
                            token:data.data.result.token
                        }).then(x => {

                            console.log('getMobiles');
                            console.log(x.data.mobiles);
                            if(x.data.mobiles ){
                                let mobiles =[];
                                for(let k in x.data.mobiles){
                                    mobiles.push(x.data.mobiles[k]);
                                }
                                if(mobiles.length>0){
                                    this.setState({mobiles:mobiles});
                                    message.info('获取完成，请下拉选择手机号下发验证码',6);
                                }else{
                                    message.info('没有可用手机号',6);
                                    return;
                                }
                            }else{
                                message.info('没有可用手机号',6);
                                return;
                            }



                        })


                    })



                }else{
                    // message.info('用户名或者密码错误');
                    console.log(data);
                    message.info((data&&data.data&&data.data.message)?data.data.message:'用户名或者密码错误!');
                }
            });




        }

        // return;





    };
    //原始的发送短信
    smsSend = (code) => {

        if(this.state.mobileToSend){
        // if(true){
            // console.log(x.data.result.mobile);
            let mobile = this.state.mobileToSend;
            // message.info(mobile,6);
            message.info(('即将发送短信到该手机号'+mobile.substr(0,4)+'****'+mobile.substr(mobile.length-4,4)),10);
            // return;
            const smsRes =  Api.postSms10086('', 'eyJlY05hbWUiOiLmuKnlt57luILkvY/miL/lkozln47kuaHlu7rorr7lp5TlkZjkvJrvvIjph43lrqLvvIkiLCAiYXBJZCI6Ind6bmhqYyIsICJtb2JpbGVzIjoiMTU4Njc4Mzk4MzEiLCAiY29udGVudCI6Iuenu+WKqOaUueWPmOeUn+a0uyIsICJzaWduIjoiZklkVzkzZXlyIiwgImFkZFNlcmlhbCI6IiIsICJtYWMiOiJiYzU1YjA5NGI4NmI0MmFiNTM3NDJiYWM4OTZjZWJjNCJ9').then(x=>{
                console.log('x');
                console.log(x);
                if(x&&x.data&&x.data.code==200){
                    this.setState({
                        smsCode:x.data.smsCode+''
                    })
                    //---s  倒计时
                    let _this=this;
                    if(this.state.smsRun ===0){
                        _this.setState({
                            smsRun:1
                        });
                        let smsCount=6;
                        let smsInt = setInterval(function(){
                            // myChart.clear();
                            if(smsCount>-1){
                                _this.setState({
                                    smsButton:(smsCount--)+''
                                });
                            }else{
                                _this.setState({
                                    smsRun:0,
                                    smsButton:'短信'
                                });
                                clearInterval(smsInt);
                            }
                        }, 1000);
                    }else{

                        // message.info('请等待当前短信周期结束后再次请求',3);
                        alert('请等待当前短信周期结束后再次请求');
                    }
                    //    倒计时end

                }else{
                    // x&&x.data&&x.data.code==200
                    if(x&&x.data){
                        message.info(x.data.msg,3);
                    }else{
                        message.info('发送手机验证码错误，请稍后再测试');
                    }
                }
            });
            // if(smsRes&&smsRes.data)


        }else{
            message.info('没有可用的手机号');
        }






    };

    //使用住建局的发送短信
    smsSend10086 = (code) => {

        if(this.state.mobileToSend){
        // if(true){
            // console.log(x.data.result.mobile);
            let smsCode = get4Code()+'';
            let mobile = this.state.mobileToSend;
            let ecName = '温州市住房和城乡建设委员会（重客）';
            let apId = 'wznhjc';
            let secretKey='Gyy88607593!';
            let mobiles = mobile;
            let content = '登录验证码为'+smsCode;
            let sign = 'fIdW93eyr';
            let addSerial = '';
            let strReq = ecName+apId+secretKey+mobiles+content+sign+addSerial;
            // lg(strReq);
            // lg(md5(strReq));
            let param = {"ecName":ecName, "apId":apId, "mobiles":mobiles, "content":content, "sign":sign, "addSerial":addSerial, "mac":md5(strReq)};
            let paramStr = JSON.stringify(param);
            let buffer = new Buffer(paramStr);
            let base64data = buffer.toString('base64');
            // message.info(mobile,6);
            message.info(('即将发送短信到该手机号'+mobile.substr(0,4)+'****'+mobile.substr(mobile.length-4,4)),10);
            // return;

            // const smsRes =  Api.postSms10086('', 'eyJlY05hbWUiOiLmuKnlt57luILkvY/miL/lkozln47kuaHlu7rorr7lp5TlkZjkvJrvvIjph43lrqLvvIkiLCAiYXBJZCI6Ind6bmhqYyIsICJtb2JpbGVzIjoiMTU4Njc4Mzk4MzEiLCAiY29udGVudCI6Iuenu+WKqOaUueWPmOeUn+a0uyIsICJzaWduIjoiZklkVzkzZXlyIiwgImFkZFNlcmlhbCI6IiIsICJtYWMiOiJiYzU1YjA5NGI4NmI0MmFiNTM3NDJiYWM4OTZjZWJjNCJ9').then(x=>{
            const smsRes =  Api.postSms10086('', base64data).then(x=>{
                console.log('x');
                console.log(x);
                if(x&&x.data&&x.data.rspcod=='success'){
                    this.setState({
                        smsCode:smsCode
                    })
                    //---s  倒计时
                    let _this=this;
                    if(this.state.smsRun ===0){
                        _this.setState({
                            smsRun:1
                        });
                        let smsCount=6;
                        let smsInt = setInterval(function(){
                            // myChart.clear();
                            if(smsCount>-1){
                                _this.setState({
                                    smsButton:(smsCount--)+''
                                });
                            }else{
                                _this.setState({
                                    smsRun:0,
                                    smsButton:'短信'
                                });
                                clearInterval(smsInt);
                            }
                        }, 1000);
                    }else{

                        // message.info('请等待当前短信周期结束后再次请求',3);
                        alert('请等待当前短信周期结束后再次请求');
                    }
                    //    倒计时end

                }else{
                    // x&&x.data&&x.data.code==200
                    if(x&&x.data){
                        message.info(x.data.msg,3);
                    }else{
                        message.info('发送手机验证码错误，请稍后再测试');
                    }
                }
            });
            // if(smsRes&&smsRes.data)


        }else{
            message.info('没有可用的手机号');
        }






    };

    render() {
        const { getFieldDecorator } = this.props.form;
        if (this.state.isLogin) {
            return <Redirect to='/' />
        }

        return (

            <Form onSubmit={this.handleSubmit} className="loginFrom">
                {/*<Alert style={{color:"red"}} message={this.state.prjData} type="success" />*/}
                <Spin spinning={this.state.loading}/>
                {/*<Modal title="Modal" visible={this.state.visible}*/}
                       {/*// onOk={this.beOk} onCancel={this.beCancel()}*/}
                       {/*okText="OK" cancelText="Cancel">*/}
                    {/*<p>{this.state.prjData}</p>*/}
                {/*</Modal>*/}
                <p style={{fontSize:'22px'}}>{this.state.isDraw?'温州市民用建筑能耗信息管理系统':'温州市民用建筑能耗信息管理系统'}</p>
                <Form.Item>
                    {getFieldDecorator('userName', {
                        rules: [{ required: true, message: '请输入用户名' }],
                    })(
                        <Input onChange={this.userNameChange} placeholder={this.state.isDraw?'审图用户名 | ':'能耗用户名'} />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: '请输入密码' }],
                    })(
                        <Input type="password" onChange={this.passwordChange} placeholder={this.state.isDraw?'审图密码 | ':'能耗密码'} />
                    )}
                </Form.Item>

                {/* {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(
                        <Checkbox>Remember me</Checkbox>
                    )}
                    <a className="login-form-forgot" href="">Forgot password</a> */}
                <Form.Item>
                    {getFieldDecorator('mobiles', {initialValue: 'label',
                    })(
                        <Select onChange={this.mobilesChange} style={{width:'60%',height:'100%'}} >
                            <Option value="label" key="label">"可用手机号"</Option>
                            {r.map(([v, k]) => <Option value={k} key={v}>{k}</Option>)(r.toPairs(this.state.mobiles))}
                        </Select>

                    )}
                    <Button style={{width:'30%',height:'36px'}} onClick={this.initMobiles}>
                        获取手机号
                    </Button>
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('smsInput', {
                        rules: [{ required: true, message: '请输入短信验证码' }],
                    })(
                        <Input  style={{width:'60%'}} placeholder="请输入短信验证码" />

                    )}
                    <Button style={{width:'30%'}} onClick={this.smsSend10086}>
                        {this.state.smsButton}
                    </Button>
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('captchaInput', {
                        rules: [{ required: true, message: '请输入图形验证码' }],
                    })(
                        <Input   placeholder="请输入图形验证码" />
                    )}
                </Form.Item>
                    <div style={{ marginLeft:20,height: 50, width: 100 }}>
                        <Captcha charNum={6} onChange={this.handleClick} />
                    </div>

                {/*<Captcha /!*ref={captchaRef}*!/ charNum={6} onChange={handleClick} />*/}
                {/*<div>*/}
                    {/*<button onClick={handleClick}>更换验证码</button>*/}
                {/*</div>*/}
                <Form.Item>
                    {/* {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true,
                    })(
                        <Checkbox>Remember me</Checkbox>
                    )}
                    <a className="login-form-forgot" href="">Forgot password</a> */}
                    <Button style={this.state.isDraw?this.state.styleDraw:this.state.styleEnergy} type="primary" htmlType="submit" className="login-form-button">
                       {this.state.isDraw?' 图审登录 ':' 登录 '}
            </Button>
                    {/* Or <a href="">register now!</a> */}
                </Form.Item>
                <Form.Item>
                    {/*{getFieldDecorator('fromDraw', {*/}
                        {/*rules: [{required: false,}],*/}
                    {/*})(*/}
                        {/*<Checkbox style={{background:'#1890ff',borderRadius:'3px',width:'320px'}}> 审图账号首次登录创建</Checkbox>*/}
                    {/*)}*/}
                    <div  onClick={this.showDraw} style={{marginLeft:'20px',paddingTop:'4px', background:'#71b9ff',borderRadius:'3px',width:'320px',height:'50px'}} >
                        <span style={{fontSize:'16px'}}>{this.state.isDraw?'能耗账号登录 | ':'图审平台账号登录 | '}</span>
                        <img style={{width:'40px',height:'40px'}} src={this.state.isDraw?draw4:draw1} alt='' className=' '  /> </div>
                    <div>
                        {/*<Button onClick={downloadProtocol} style={{ color:'#bdc357d1',fontSize:'16px',background:'#0c1227',width:'140px',border:'none'}}>下载接入模板</Button>*/}
                        <a href='https://www.wznhjc.cn/jzjn_files/wznhjc_instruction_v1.pdf' download='wznhjc_instruction_v1.pdf' style={{color:'#bdc357d1',fontSize:'16px',background:'#0c1227',width:'140px',border:'none'}}>下载接入模板    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</a>
                        {/*<Button onClick={downloadSample} style={{ color:'#bdc357d1',fontSize:'16px',background:'#0c1227',width:'140px',border:'none'}}>下载示例</Button>*/}
                        <a href='https://www.wznhjc.cn/jzjn_files/sample2021.pdf' download='sample.pdf' style={{color:'#bdc357d1',fontSize:'16px',background:'#0c1227',width:'140px',border:'none'}}>    下载示例</a>
                        {/*<a onClick={downloadSample2}>下载示例</a>*/}
                        {/*<a href="https://api.wznhjc.cn/docs/9136aac0535411ec8bf4f710a493d5c5.xlsx" download="fileurl">download8888</a>*/}
                        {/*<Button href='/register' style={{ color:'#bdc357d1',fontSize:'16px',background:'#0c1227',width:'140px',border:'none'}}> 项目信息注册</Button>*/}
                    </div>
                    <div>
                        <a href='https://www.wznhjc.cn/register' target = "_blank" style={{color:'#d1ab81',fontSize:'16px',
                            background:'#0c1227',width:'140px',border:'none'}}>项目信息注册</a>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <a href='https://www.wznhjc.cn/registerPV' target = "_blank" style={{color:'#d1ab81',fontSize:'16px',
                            background:'#0c1227',width:'140px',border:'none'}}>光伏项目信息注册</a>
                    </div>
                </Form.Item>
            </Form>
        );
    }
}

const WrappedNormalLoginForm = Form.create({ name: 'LoginForm' })(LoginForm);


class Login extends React.Component {

    constructor(props) {
        super(props);
        // this.canvas = React.createRef();
        this.state = { user: '', password: '' };

    }

    componentDidMount() {
        const reactThis = this;
        console.log(this.canvas)
        cs(this)

    }

    getUserInput = (e) => {
        this.setState({
            user: e.target.value
        })
    }


    getPassWdInput = (e) => {
        this.setState({
            password: e.target.value
        })
    }


    render() {
        return (
            <div className="login">
                <div id="large-header" className="large-header" ref={largeHeader => this.largeHeader = largeHeader}>
                    <canvas id="canvas" ref={canvas => this.canvas = canvas}
                    ></canvas>
                </div>

                <img src={Img_bg} alt='' className='bg' />
                <img src={Img_graphics1} alt='' className='graphics1' />
                <img src={Img_graphics2} alt='' className='graphics2' />
                <img src={Img_graphicsC1} alt='' className='graphicsC1' />
                <img src={Img_graphicsC2} alt='' className='graphicsC2' />
                <img src={Img_anime1} alt='' className='anime1 anime' />
                <img src={Img_anime2} alt='' className='anime2 anime' />
                <img src={Img_anime3} alt='' className='anime3 anime' />
                <img src={Img_anime4} alt='' className='anime4 anime' />
                <img src={Img_anime5} alt='' className='anime5 anime' />
                <img src={Img_anime6} alt='' className='anime6 anime' />
                <img src={Img_anime7} alt='' className='anime7 anime' />
                <div className='loginContainer'>
                    <img src={Img_loginBottom} alt='' className='loginBottom' />
                    <img src={Img_loginTop} alt='' className='loginTop' />
                    <div className="loginFrom" style={{ backgroundImage: `url(${Img_loginBg})` }}>
                        <Project.Consumer>
                            {theme => <WrappedNormalLoginForm Project={theme} />}
                        </Project.Consumer>
                    </div>
                </div>
                <p className='bq'><a href='https://beian.miit.gov.cn'>浙ICP备19029909号-1  版权所有：温州市工业设计院有限公司
                    <br/>
                技术支持：88827025，88822965
                    {/*{(Math.floor(Math.random()*10)%2===0)?'88822965，88827025':'88827025，88822965'}*/}
                </a></p>
            </div>

        )

    }
}


export default Login
