import React from 'react';
import { BrowserRouter as Router, Route, NavLink } from "react-router-dom";

import './system.scss'
// import ReactEcharts from 'echarts-for-react'

import Device from './device/device';
// import Rank from './rank/rank'
// import Zenergy from './energy/energy'
// import DayAndNight from './dayAndNight/dayAndNight'
// import Building from './building/building'
// import Department from './department/department'

class System extends React.Component {

  render() {
    return (
      <div className="system">
        <Router>
          <div>
            <nav>
              <ul>
                <li>
                  <NavLink activeClassName='active' to='/System/Device'><span>机组设备</span></NavLink>
                </li>
                <li>
                  <NavLink activeClassName='active' to='/System/Zenergy'><span>热泵机组</span></NavLink>
                </li>
                <li>
                  <NavLink activeClassName='active' to='/System/DayAndNight'><span>空调机组</span></NavLink>
                </li>
              </ul>
            </nav>

            <Route path="/System/Device" exact component={Device} />
            {/* <Route path="/Energy/Rank" component={Rank} />
            <Route path="/Energy/Zenergy" component={Zenergy} />
            <Route path="/Energy/DayAndNight" component={DayAndNight} />
            <Route path="/Energy/Building" component={Building} />
            <Route path="/Energy/Department" component={Department} /> */}
          </div>
        </Router>
      </div>
    )
  }

};


export default System;