import React from 'react';

import './indexBottom.scss';
import Border2 from '../ui/border2/border2';
import BorderLeft from '../ui/borderLeft/borderLeft';
import IndexBottom1 from './indexBottom1/indexBottom1';
import IndexBottom2 from './indexBottom2/indexBottom2';
import IndexBottom3 from './indexBottom3/indexBottom3';
import IndexBottom4 from './indexBottom4/indexBottom4';
import Api from 'api';
import { timer } from 'rxjs';

class IndexBottom extends React.Component {

    state = {
        ac: 0,
        electricity: 0,
        gas: 0,
        total: 0,
        water: 0
    }

    async getData() {
        // this.state = {getOption: {series: []}, ...this.state};
        const data = await Api.postV3('/energyRegionNew/usageRatioDaily', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });

        const ac = data.data.ac.toFixed(2);
        const electricity = data.data.electricity.toFixed(2);
        const gas = data.data.gas.toFixed(2);
        const total = data.data.total.toFixed(2);
        const water = data.data.water.toFixed(2);

        this.setState({
            ac: ac,
            electricity: electricity,
            gas: gas,
            total: total,
            water: water
        })
    }


    componentDidMount() {
        
        const upDataTime = 5 * 60 * 1000;//分钟
        const source = timer(0, upDataTime);
        source.subscribe((x) => {
            this.getData()
        })
    }

    render() {
        const { ac, electricity, gas, total, water } = this.state;
        return (
            <div className='indexBottom'>
                <Border2 className='mr10'>
                    <IndexBottom1 ac={ac} electricity={electricity} gas={gas} total={total} water={water} />
                </Border2>
                <Border2 className='mr10'>
                    <IndexBottom2 electricity={electricity} total={total} water={water} />
                </Border2>
                <Border2 className='mr10'>
                    <IndexBottom3 />
                </Border2>
                <BorderLeft>
                    <IndexBottom4 />
                </BorderLeft>
            </div>
        )
    }
}


export default IndexBottom;