import React from 'react';

import './indexMid.scss';

import IndexMidLeft from './indexMidLeft/indexMidLeft';
import IndexMidRight from './indexMidRight/indexMidRight';

class IndexMid extends React.Component {

    render() {
        return (
            <div className='indexMid'>
                <IndexMidLeft />
                <IndexMidRight />
            </div>
        )
    }
}

export default IndexMid

