import React from 'react';

import './indexTop.scss';
import Api from 'api';
import Border2 from '../ui/border2/border2';
import BorderLeft from '../ui/borderLeft/borderLeft';


import IndexTopLeft from './indexTopLeft/indexTopLeft';
import PartMiddle1 from './partMiddle1/partMiddle1';
import IndexTopRight from './indexTopRight/indexTopRight';

class IndexTop extends React.Component {
    render() {
        return (
            <div className='indexTopV3'>
                <div style={{position: 'absolute', left: '35%'}}>
                    <h1 className='title'>温州碳排放监测平台</h1>
                </div>
                <div style={{position: 'absolute', right: '12%', top: '53px'}}>

                    {/*<h1 className='subTitle' style={{color:'#eff4f9'}}> {Api.projectName}</h1>*/}
                </div>
                <Border2>
                    {/*<IndexTopLeft />*/}
                    <PartMiddle1 />
                </Border2>
                <BorderLeft className='ml-10px'>
                    <IndexTopRight />
                </BorderLeft>
            </div>
        )
    }
}


export default IndexTop;