import React, { useContext, useState } from 'react';
import { Table, Switch, Pagination, message, Tooltip } from 'antd';
import * as r from 'ramda';
import { of } from 'rxjs'
import Api from 'api'


import TableTop from 'src/components/table/tableTop/tableTop';
import EditableCell, { EditableFormRow } from 'src/components/table/tableEdit/editbleCell';
import ModalEcharts from 'src/components/modal/modalEcharts/modalEcharts';
import { context, loadDevices } from "src/modules/equipment/equipment";

import gear1 from 'img/gear1.png';
import gear2 from 'img/gear2.png';
import gear3 from 'img/gear3.png';
import gearL from 'img/gear1.gif';
import gearM from 'img/gear2.gif';
import gearH from 'img/gear3.gif';
// import tableColums from './tableColums';
const columns = dispatch => [{
    title: '序号',
    dataIndex: 'order',
    key: 'order',
    width: 70
}, {
    title: '智能仪表名称',
    dataIndex: 'name',
    key: 'name',
    render: (text) => {
        return text.length > 16 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
            width: '200px', overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap', display: ' inline-block'
        }}>{text}</span></Tooltip > : <span>{text}</span>
    },
    onCell: record => ({
        record,
        editable: true,
        dataIndex: 'name',
        title: '智能仪表名称',
        handleSave: handleNameSave(dispatch),
    }),
    width: 150
}, {
    title: '智能仪表ID',
    dataIndex: 'instrumentId',
    key: 'instrumentId',
    width: 160
}, {
    title: '通道名称',
    dataIndex: 'channel',
    key: 'channel',
    width: 150
}, {
    title: '当前读数',
    dataIndex: 'reading',
    key: 'reading',
    width: 150
}, {
    title: '通讯状态',
    dataIndex: 'state',
    key: 'state',
    render: (text, record) => {
        return <span className={record.state ? 'colorY' : 'colorG'}>{record.state ? '• 异常' : '• 正常'}</span>
    },
    width: 50
}, {
    title: '开关状态',
    dataIndex: 'switch',
    key: 'switch',
    render: () => (
        <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={true} disabled={true} />
    ),
    width: 150
}, {
    title: '通讯时间',
    dataIndex: 'time',
    key: 'time',
    width: 190
}, {
    title: '采集器编号',
    dataIndex: 'number',
    key: 'number',
    width: 150
},
{
    title: '设备标识',
    dataIndex: 'device_id',
    key: 'device_id',
    className: 'hidden',
}, {
    title: '倍率',
    dataIndex: 'multipoint',
    key: 'multipoint',
    className: 'hidden',
}, {
    title: '更新频率',
    dataIndex: 'updatecyc',
    key: 'updatecyc',
    className: 'hidden',
}, {
    title: '是否删除',
    dataIndex: 'is_del',
    key: 'is_del',
    className: 'hidden',
}, {
    title: '是否为总表',
    dataIndex: 'principal',
    key: 'principal',
    className: 'hidden',
}, {
    title: '通道 id',
    dataIndex: 'channelId',
    key: 'channelId',
    className: 'hidden',
}];

const columnsOrder = {
    title: '序号',
    dataIndex: 'order',
    key: 'order',
    width: 70
}
const columnsName = dispatch => ({
    title: '智能仪表名称',
    dataIndex: 'name',
    key: 'name',
    render: (text) => {
        return text.length > 11 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
            width: '150px', overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap', display: ' inline-block'
        }}>{text}</span></Tooltip > : <span>{text}</span>
    },
    width: '200px'
})
const columnsInstrumentId = {
    title: '智能仪表ID',
    dataIndex: 'instrumentId',
    key: 'instrumentId',
    width: '150px'
}
const columnsChannel = {
    title: '通道名称',
    dataIndex: 'channel',
    key: 'channel',
    width: 150
}
const columnsReading = {
    title: '当前读数',
    dataIndex: 'reading',
    key: 'reading',
    width: 150
}
const columnsState = {
    title: '通讯状态',
    dataIndex: 'state',
    key: 'state',
    render: (text, record) => <span className={record.state ? 'colorY' : 'colorG'}>{record.state ? '• 异常' : '• 正常'}</span>,
    width: 150
}
const columnsSwitch = {
    title: '开关状态',
    dataIndex: 'switch',
    key: 'switch',
    render: () => (<
        Switch checkedChildren='开'
        unCheckedChildren='关'
        defaultChecked={
            true
        }
        disabled={
            true
        }
    />
    ),
    width: 150
}
const columnsTime = {
    title: '通讯时间',
    dataIndex: 'time',
    key: 'time',
    render: (text) => {
        return text.length > 5 ? <Tooltip title={text} overlayStyle={{ whiteSpace: 'nowrap' }}> <span style={{
            width: '100px', overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap', display: ' inline-block'
        }}>{text}</span></Tooltip > : <span>{text}</span>
    },
    width: '150px'
}
const columnsNumber = {
    title: '采集器编号',
    dataIndex: 'number',
    key: 'number',
    width: 150
}

const columnsPositive = {
    title: '正向有功',
    dataIndex: 'positive',
    key: 'positive',
        width: '100px',
}
const columnsVoltageA = {
    title: 'A相电压',
    dataIndex: 'voltageA',
    key: 'voltageA',
    width: '100px',
}
const columnsVoltageB = {
    title: 'B相电压',
    dataIndex: 'voltageB',
    key: 'voltageB',
    width: '100px',
}
const columnsVoltageC = {
    title: 'C相电压',
    dataIndex: 'voltageC',
    key: 'voltageC',
    width: '100px',
}

const columnsCurrentA = {
    title: 'A相电流',
    dataIndex: 'currentA',
    key: 'currentA',
    width: '100px',
}
const columnsCurrentB = {
    title: 'B相电流',
    dataIndex: 'currentB',
    key: 'currentB',
    width: '100px',
}
const columnsCurrentC = {
    title: 'C相电流',
    dataIndex: 'currentC',
    key: 'currentC',
    width: '100px',
}

const columnsCold = {
    title: '冷量系数',
    dataIndex: 'cold',
    key: 'cold',
    width: 150,
}
const columnsHot = {
    title: '热量系数',
    dataIndex: 'hot',
    key: 'hot',
    width: 150,
}
const columnsModel = {
    title: '模式',
    dataIndex: ' model',
    key: ' model',
    render: (text, record) => (
        <Switch checkedChildren='热' unCheckedChildren='冷' defaultChecked={record.onoff === '热' ? true : false} onChange={switchChange} />
    ),
    width: 150,
}
const columnsGear = {
    title: '档位',
    dataIndex: 'gear',
    key: 'gear',
    render: (text, record) => {
        let gear = ''
        switch (text) {
            case '高':
                gear = <ul className='gear'><li><img src={gear1} alt="" /></li><li><img src={gear1} alt="" /></li><li><img src={gearH} alt="" /></li></ul>;
                break;
            case '中':
                gear = <ul className='gear'><li><img src={gear1} alt="" /></li><li><img src={gearM} alt="" /></li><li><img src={gear1} alt="" /></li></ul>;
                break;
            case '低':
                gear = <ul className='gear'><li><img src={gearL} alt="" /></li><li><img src={gear1} alt="" /></li><li><img src={gear1} alt="" /></li></ul>;
                break;
            default:
                gear = <ul className='gear'><li><img src={gearL} alt="" /></li><li><img src={gear1} alt="" /></li><li><img src={gear1} alt="" /></li></ul>;
                break;
        }
        return gear
    },
    width: 150,
}
const columnsOperation = {
    title: '操作',
    dataIndex: 'operation',
    key: 'operation',
    render: (text, record) => (
        <div style={{ color: '#3E8FF8' }}>
            发送
        {/* <Management record={record} isUpData={this.upData} />
        <Delete record={record} /> */}
        </div>
    ),
    width: 150,
}
const columnsFlow = {
    title: '累计流量m³',
    dataIndex: 'flow',
    key: 'flow',
    width: 150
}
const columnsComi = {
    title: '倍率',
    dataIndex: 'comi',
    key: 'comi',
    width: '100px'
}
const columnsFlowVelocity = {
    title: '流速m³/s',
    dataIndex: 'flowVelocity',
    key: 'flowVelocity',
    // render: (text, record) => {
    //     return <span className={record.state ? 'colorY' : 'colorG'}>{text}</span>
    // },
    width: 150
}
const columnsTemperature = {
    title: '供水温度℃',
    dataIndex: 'temperature',
    key: 'temperature',
    width: 150
}
const columnsReturnWaterTemperature = {
    title: '回水温度℃',
    dataIndex: 'returnWaterTemperature',
    key: 'returnWaterTemperature',
    width: 150
}
const columnsTotalCold = {
    title: '总冷量',
    dataIndex: 'totalCold',
    key: 'totalCold',
    width: 150
}
const columnsTotalHot = {
    title: '总热量',
    dataIndex: 'totalHot',
    key: 'totalHot',
    width: 150
}
const columnsOxygen = {
    title: '氧气',
    dataIndex: 'oxygen',
    key: 'oxygen',
    width: 150
}
const columnsEnvironmentTemperature = {
    title: '环境温度',
    dataIndex: 'environmentTemperature',
    key: 'environmentTemperature',
    width: 150
}
const columnsEnvironmentHumidity = {
    title: '环境湿度',
    dataIndex: 'environmentHumidity',
    key: 'environmentHumidity',
    width: 150
}
const columnsEnergyNumber = {
    title: '能量系数',
    dataIndex: 'energyNumber',
    key: 'energyNumber',
    width: 150,
}
const columnsPanelSwitch = {
    title: '面板开关',
    dataIndex: 'panelSwitch',
    key: 'panelSwitch',
    render: (text, record) => (
        // onoff
        <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === 0 ? true : false} onChange={switchChange} />
    ),
    width: 150,
}
const columnsValveSwitch = {
    title: '阀门开关',
    dataIndex: 'valveSwitch',
    key: 'valveSwitch',
    render: (text, record) => (
        // onoff
        <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === 0 ? true : false} onChange={switchChange} />
    ),
    width: 150,
}
const columnsIndoorTemperature = {
    title: '室内温度(℃)',
    dataIndex: 'indoorTemperature',
    key: 'indoorTemperature',
    width: 150,
}
const columnsSetTemperature = {
    title: '设置温度(℃)',
    dataIndex: 'setTemperature',
    key: 'setTemperature',
    width: 150,
}

const columnsAllInformation = {
    title: 'allInformation',
    dataIndex: 'allInformation',
    key: 'allInformation',
    className: 'hidden',
}

const coldWater = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsReading,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation];
const NBCOLDWATERMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsReading,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation];
const PHOTOVOLTAIC = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsReading,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation];
const mElectricity = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsPositive,
    columnsComi,
    columnsVoltageA,
    columnsVoltageB,
    columnsVoltageC,
    columnsCurrentA,
    columnsCurrentB,
    columnsCurrentC,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation];

const time = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsCold,
    columnsHot,
    columnsComi,
    columnsState,
    columnsModel,
    columnsGear,
    columnsTime,
    columnsOperation,
    columnsAllInformation];

const ULTRACOLDWATERMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsFlow,
    columnsFlowVelocity,
    columnsTemperature,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation];
const HEATENERGYMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsTemperature,
    columnsFlow,
    columnsReturnWaterTemperature,
    columnsTotalCold,
    columnsTotalHot,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation
]
const OXYGENMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsOxygen,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation
]
const TEMPHUMMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsEnvironmentTemperature,
    columnsEnvironmentHumidity,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation
]
const ENERGYMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsTemperature,
    columnsReturnWaterTemperature,
    columnsFlow,
    columnsFlowVelocity,
    columnsTotalCold,
    columnsTotalHot,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation
]
const TEMPERATURECONTROL = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsEnergyNumber,
    columnsComi,
    columnsState,
    columnsPanelSwitch,
    columnsValveSwitch,
    columnsModel,
    columnsGear,
    columnsIndoorTemperature,
    columnsSetTemperature,
    columnsTime,
    columnsAllInformation];
const ELECTRICITYMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsPositive,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation];
const NBFEECONTROLMETER = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsPositive,
    columnsComi,
    columnsState,
    columnsTime,
    columnsNumber,
    columnsAllInformation];
const TTYPETEMPCONTROL = dispatch => [
    columnsOrder,
    columnsName(dispatch),
    columnsInstrumentId,
    columnsEnergyNumber,
    columnsComi,
    columnsState,
    columnsModel,
    columnsGear,
    columnsSetTemperature,
    columnsTime,
    columnsAllInformation]


const tableColumns = (type) => (dispatch) => {
    const columnsMap = {
        "COLDWATERMETER": coldWater(dispatch),
        "NBCOLDWATERMETER": NBCOLDWATERMETER(dispatch),
        "PHOTOVOLTAIC": PHOTOVOLTAIC(dispatch),
        "HOTWATERMETER": coldWater(dispatch),
        "MELECTRICITYMETER": mElectricity(dispatch),
        "ELECTRICITYMETER": ELECTRICITYMETER(dispatch),
        "TIMERMETER": time(dispatch),
        "ULTRACOLDWATERMETER": ULTRACOLDWATERMETER(dispatch),
        "HEATENERGYMETER": HEATENERGYMETER(dispatch),
        "OXYGENMETER": OXYGENMETER(dispatch),
        "TEMPHUMMETER": TEMPHUMMETER(dispatch),
        "ENERGYMETER": ENERGYMETER(dispatch),
        "TEMPERATURECONTROL": TEMPERATURECONTROL(dispatch),
        "NBFEECONTROLMETER": NBFEECONTROLMETER(dispatch),
        "TTYPETEMPCONTROL": TTYPETEMPCONTROL(dispatch)
    }
    return r.propOr([])(type)(columnsMap)
}

const switchChange = (checked) => {
    console.log(`switch to ${checked}`);
}

const handleNameSave = dispatch => row => {
    const { allInformation: { id } } = row;
    dispatch(r.pipe(r.assoc('loading', true), of))
    Api.post("/sensor/update", {
        did: id,
        projectid: Api.projectId,
        title: row.name,
    }).then(
        () => {
            message.success('修改成功')
            dispatch(loadDevices)
        },
        () => message.error('修改失败')
    )

}
const pageChange = dispatch => (currentPage, pageSize) => {
    dispatch(r.pipe(r.assoc('loading', true), of))
    dispatch(state => loadDevices({ ...state, loading: true, currentPage, pageSize }))
}

const pageSizeChange = dispatch => (currentPage, pageSize) => {
    dispatch(r.pipe(r.assoc('loading', true), of))
    dispatch(state => loadDevices({ ...state, loading: true, currentPage: 1, pageSize }))
}

const rowSelection = dispatch => ({
    onChange: (selectedRowKeys, selectedRows) => {
        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
        dispatch(r.pipe(r.assoc('operationIsShow', r.length(selectedRows) > 0), of))
    },
    getCheckboxProps: record => ({
        disabled: record.name === 'Disabled User',
        name: record.name,
    }),
})

const getRecordDates = async (devicetype,record)=>{
    console.log('getRecordDates --- ');
    console.log(devicetype);
    console.log(record);
    console.log(record.allInformation.channels['11'].id);
    const res = await Api.postV3('/energyNew/getRecordDates', {
        // projectId:'123',
        project: Api.projectId,
        // projectPlanId:projectPlanId?projectPlanId:fromData,
        meterAddr:record.allInformation.channels['11'].id,
        projectCreateTime:Api.projectCreateTime,
        range:'202112'
    })
    console.log('daysAndData ---');
    console.log(res);
    return res.data;


}

const components = {
    body: {
        row: EditableFormRow,
        cell: EditableCell,
    },
};

// const typeKey = 'HOTWATERMETER'
const TableTest = () => {
    const { state: { loading, devices, currentPage, pageSize, operationIsShow, devicetype }, dispatch } = useContext(context)
    const [visible, setVisible] = useState(false);
    const [recordDays, setRecordDays] = useState([]);
    const [record, setRecord] = useState({ allInformation: { title: '', channels: {} } });
    const handleCancel = (e) => {
        setVisible(false)
    }
    return (
        <div className='reportRightTable'>
            <TableTop props={{
                result: devices.pageCount,
                liData: ['搜索']
            }} operationIsShow={operationIsShow} dispatch={dispatch} loadUp={loadDevices} api={'/export/equipments'}/>
 
            <Table
                components={components}
                rowSelection={rowSelection(dispatch)}
                columns={tableColumns(devicetype)(dispatch)}
                dataSource={devices.data}
                scroll={{ y: 650 }}
                pagination={false}
                loading={loading}
                rowKey='hotWater'
                onRow={record => {
                    return {
                        onClick: event => {
                            console.log('table ---- ');
                            console.log(devicetype);
                            console.log(record);
                            let res = getRecordDates(devicetype,record)
                                .then(x=>{
                                console.log('recordData -----');
                                console.log(x);
                                if(x){
                                    let daysArr = [];
                                    r.map(y=>{
                                        r.map(yy=>daysArr.push(yy.date+''))(y)
                                    })(x);
                                    setRecordDays(daysArr)
                                }

                                // console.log(res);
                                // console.log(res.data);
                            });


                            setRecord(record)
                            setVisible(!visible)
                        },
                    };
                }}
                className='reportRightTableTab' />
            <div className='reportRightTableFoot'>
                <Pagination className='pagination' showSizeChanger={true}
                    onChange={pageChange(dispatch)} onShowSizeChange={pageSizeChange(dispatch)}
                    defaultCurrent={1} total={devices.pageCount} pageSize={pageSize} current={currentPage} />
            </div>
            <ModalEcharts visible={visible} devicetype={devicetype}  record={record} recordDays={recordDays} handleCancel={handleCancel} />
        </div>
    )
}
export default TableTest;