import React from 'react';

import './index.scss'
// import Api from 'api'

import IndexMidAverage from './indexMid/indexMidAverage'

class Average extends React.Component {

  render() {
    return (
      <div className="indexAverage">
        <IndexMidAverage />
      </div>
    )
  }

};


export default Average;