import React from 'react';

import './indexMidSms.scss';

import IntervalReport from './intervalReport/intervalReport';

class IndexMidSms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            view:1,
            style: { display: "block" },
            style1: { display: "none"},
            // tableData:[{
            //     average: "019010400616",
            //     name: "2F???-150/5",
            //     order: "01",
            //     type: "1",
            //     area: "344.14",
            //     headCount: "14.92"}],
            tableData:[]
        }
    }
    selected() {
        console.log('begin to change');
        this.setState({
            view:!this.state.view
        })
    }
    setData(tableData) {
        console.log('tableData');
        console.log(tableData);
        this.setState({
            tableData:tableData
        })
    }

    render() {
        return (
            <div className='indexMidAverage'>
                {/*<span onClick={this.selected.bind(this)} style={{fontSize:'30px'}}>切换浏览数据</span>*/}
                {/*<div style={this.state.view?this.state.style:this.state.style1}>*/}
                {/*<IndexMidRightOldData setData = {this.setData.bind(this)} />*/}
            {/*</div>*/}
                {/*<div style={this.state.view?this.state.style1:this.state.style}>*/}
                    {/*<IntervalReport   tableData = {this.state.tableData} />*/}
                {/*</div>*/}
                <IntervalReport />

            </div>
        )
    }
}

export default IndexMidSms

