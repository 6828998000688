import React, {useContext} from "react";
import Nav from "src/modules/nav/nav"
import Index from './modules/index/index';
import RegionDashboard from './modules/regionDashboard/regionDashboard';
import ReportTemplates from './modules/reportTemplates/reportTemplates';
import RegionPresentation from './modules/regionPresentation/regionPresentation';
import PresentationV2 from './modules/presentationV2/presentationV2';
import CarbonNeutral from './modules/carbonNeutral/carbonNeutral';
import Average from './modules/average_building/average';
import ScreenVersionIndex from './modules/screenVersionIndex/screenVersionIndex';
import AirData from './modules/airData/airData';
import PvData from './modules/pvData/pvData';
import Sms from './modules/sms/sms';
import StatisAllStates from './modules/statisAllStates/stateAllStates';
import DrawingMonitor from './modules/drawingMonitor/drawingMonitor';
import Water from './modules/water/water.jsx';
import Electrical from './modules/electrical/electrical.jsx';
import Equipment from './modules/equipment/equipment';
import EquipmentV2 from './modules/equipmentV2/equipmentV2';
import Report from './modules/report/report';
import RegisterInfo from './modules/registerInfo/registerInfo';
import RegisterInfo2 from './modules/registerInfo2/registerInfo';
import NewRegisterInfo from 'src/modules/newRegisterInfo/newRegisterInfo'
import NewRegisterInfoPV from 'src/modules/newRegisterInfo/newRegisterInfoPV'
import NewRegisterInfoAdmin from 'src/modules/newRegisterInfo/newRegisterInfoAdmin'
import NewRegisterInfoAdminRegion from 'src/modules/newRegisterInfo/newRegisterInfoAdminRegion'
import NewRegisterInfoAdminRegionPV from 'src/modules/newRegisterInfo/newRegisterInfoAdminRegionPV'
import ReportRegion from './modules/report/reportRegion';
import AMap from './modules/map/map';
import System from './modules/system/system';
import * as r from "ramda";
import {currentProject, isRoot, moduleNavigation, PrivateRoute} from "src/context/NavItem";
import Home from "src/Home";
import withStates from "src/context/withStates";
import {from} from 'rxjs'
import {Spin} from "antd";
import Energy from "src/modules/energy/energy";
import WeatherDate from "src/modules/report/weatherDate";
import OldData from "src/modules/report/oldDate";
import Noise from "src/modules/noise/noise";
import {Route} from "react-router-dom";

export const routeNames = {
  dashboard: '/Index',
  devices: '/Equipment/Manager',
  devicesV2: '/EquipmentV2/ManagerV2',
  analysis: '/Energy',
  map: '/Map',
  grid_monitor: '/Electrical',
  water_monitor: '/Water/NetworkDiagram/1',
  statistics: '/Report/IntervalReport',
  statisticsRegion: '/ReportRegion/IntervalReportRegion',
  weatherDate: '/WeatherDate/WeatherDateInterval',
  presentation: '/ScreenVersionIndex',
  reportTemplates: '/ReportTemplates',
  airdata: '/AirData',
  pvdata: '/PvData',
  sms: '/Sms',
  average_building: '/Average',
  old_data: '/OldData',
  noise: '/Noise',
  registerInfo: '/RegisterInfo',
  registerInfo2: '/RegisterInfo2',
  registerInfoPV: '/RegisterInfoPV',
    registerRegion: '/NewRegisterInfoAdminRegion',
    registerRegionPV: '/NewRegisterInfoAdminRegionPV',
  regionDashboard: '/RegionDashboard',
  regionPresentation: '/RegionPresentation',
  presentationV2: '/PresentationV2',
    carbonNeutral: '/carbonNeutral',
    drawingMonitor: '/DrawingMonitor',
  analysis_energy: '/Energy/Analysis',
  analysis_subentry: '/Energy/Zenergy',
  analysis_daynight: '/Energy/DayAndNight',
  analysis_building: '/Energy/Building',
  analysis_department: '/Energy/Department'
}

export const firstTopRoute = modules => {
  const filterRouteNames = r.reject(r.startsWith('analysis_'))(r.keys(routeNames));
  let m =  modules;
  let n = (r.reject(r.startsWith('analysis_'))(routeNames));
  let n1 =r.difference(filterRouteNames, modules);
  let n11 =r.omit(r.difference(filterRouteNames, modules));
  let n2 = r.values(n11(n));
  let n22 = r.head(r.values(n11(n)));
  // let n3 = r.head(r.values(r.omit(r.difference(filterRouteNames, modules))(r.reject(r.startsWith('analysis_'))(routeNames))));
  return isRoot() ? r.head(r.values(routeNames)) : r.head(r.values(r.omit(r.difference(filterRouteNames, modules))(r.reject(r.startsWith('analysis_'))(routeNames))))
}

export const firstAnalysisRoute = modules => {
  const routes = r.pickAll(r.filter(r.startsWith('analysis_'))(r.keys(routeNames)))(routeNames);
  return isRoot() ? '/Energy/Analysis' : r.head(r.values(r.omit(r.difference(r.keys(routes), modules))(routes)))
}


export const topRoutes = {
  dashboard: <PrivateRoute key='dashboard' path="/Index" exact component={Index}/>,
  analysis: <Route key='analysis' path="/Energy" component={Energy}/>,
  devices: <PrivateRoute key='devices' path="/Equipment" component={Equipment}/>,
  devicesV2: <PrivateRoute key='devicesV2' path="/EquipmentV2" component={EquipmentV2}/>,
  map: <PrivateRoute key='map' path="/Map" component={AMap}/>,
  grid_monitor: <PrivateRoute key='grid_monitor' path="/Electrical" component={Electrical}/>,
  water_monitor: <PrivateRoute key='water_monitor' path="/Water" component={Water}/>,
  statistics: <PrivateRoute key='statistics' path="/Report" component={Report}/>,
  statisticsRegion: <PrivateRoute key='statisticsRegion' path="/ReportRegion" component={ReportRegion}/>,
  weatherDate: <PrivateRoute key='weatherDate' path="/WeatherDate" component={WeatherDate}/>,
    average_building: <PrivateRoute key='average_building' path="/Average" component={Average}/>,
    old_data: <PrivateRoute key='old_data' path="/OldData" component={OldData}/>,
    noise: <PrivateRoute key='noise' path="/Noise" component={Noise}/>,
    regionDashboard: <PrivateRoute key='regionDashboard' path="/RegionDashboard" component={RegionDashboard}/>,
    regionPresentation: <PrivateRoute key='regionPresentation' path="/RegionPresentation" component={RegionPresentation}/>,
    presentationV2: <PrivateRoute key='presentationV2' path="/PresentationV2" component={PresentationV2}/>,
    carbonNeutral: <PrivateRoute key='carbonNeutral' path="/CarbonNeutral" component={CarbonNeutral}/>,
    reportTemplates: <PrivateRoute key='reportTemplates' path="/ReportTemplates" component={ReportTemplates}/>,
    registerInfo: <PrivateRoute key='registerInfo' path="/RegisterInfo" component={RegisterInfo}/>,
    // registerInfoPV: <PrivateRoute key='registerInfoPV' path="/RegisterInfoPV" component={NewRegisterInfoPV}/>,
    registerRegion: <PrivateRoute key='newRegisterInfoAdminRegion' path="/NewRegisterInfoAdminRegion" component={NewRegisterInfoAdminRegion}/>,
    registerRegionPV: <PrivateRoute key='newRegisterInfoAdminRegionPV' path="/NewRegisterInfoAdminRegionPV" component={NewRegisterInfoAdminRegionPV}/>,
    // registerInfo2: <PrivateRoute key='registerInfo2' path="/RegisterInfo2" component={RegisterInfo2}/>,
    // registerInfo2: [
    //     <PrivateRoute key='registerInfo-add' path="/NewRegisterInfo" component={NewRegisterInfo}/>,
    //     <PrivateRoute key='registerInfo2' path="/RegisterInfo2" component={RegisterInfo2}/>,],
  presentation: <PrivateRoute key='presentation' path="/ScreenVersionIndex" component={ScreenVersionIndex}/>,
  airdata: <PrivateRoute key='airdata' path="/AirData" component={AirData}/>,
  pvdata: <PrivateRoute key='pvdata' path="/PvData" component={PvData}/>,
  sms: <PrivateRoute key='sms' path="/Sms" component={Sms}/>,
    statisAllStates: <PrivateRoute key='statisAllStates' path="/StatisAllStates" component={StatisAllStates}/>,
    drawingMonitor: <PrivateRoute key='drawingMonitor' path="/DrawingMonitor" component={DrawingMonitor}/>
}

export const loadProjects = () => {
  // return from(moduleNavigation().then(projects => ({
  //   thisProject: currentProject(projects)
  // })))
    // 上面是原先的代码
    console.log('loadProjects:--------------');
    return from(moduleNavigation().then(data => ({// from 是从这里获取并赋值的方法
        projects:data[0],
        regionProjects:data[1],
        thisProject: currentProject(data[0])
    })))

}


const AppRouter = () => {
  const {state: {thisProject}} = useContext(context);
  return (
    <div>
      <Nav />
      <div className='container'>
        <PrivateRoute exact path="/" component={Home}/>
        {
          r.isNil(thisProject) ? <span /> :
            isRoot() ? r.values(topRoutes) : r.values(r.omit(r.difference(r.keys(topRoutes), thisProject.modules))(topRoutes))
        }
        <PrivateRoute path="/System" component={System}/>
        <PrivateRoute path="/NoLoginRegisterInfo" component={RegisterInfo2}/>
        <PrivateRoute path="/Register" component={NewRegisterInfo}/>
        <PrivateRoute path="/RegisterPV" component={NewRegisterInfoPV}/>
        <PrivateRoute path="/RegisterAdmin" component={NewRegisterInfoAdmin}/>
        <PrivateRoute path="/CarbonNeutral" component={CarbonNeutral}/>
      </div>
    </div>
  )
}

const initState = {
    thisProject: null,
    projects: [],
    regionProjects:[]//增加对用用户的区域项目列表--
}
export const { context, Component } = withStates(
  AppRouter,
  initState,
  loadProjects,
)

export default Component
