import React from 'react';
import { Table, Switch } from 'antd';
import moment from 'moment';
import * as r from 'ramda';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
// import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

// import './dayReport.scss';
import Api from 'api';
const mapIndexed = r.addIndex(r.map);

class Manager extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data: [{}],
            allData: null,
            isUpData: false,
            tableLoading: false
        };
    }

    async getData() {
        this.setState({
            tableLoading: true,
            data: []
        })

        const { data: { result: { detail } } } = await Api.post('/collector/info', {
            pagesize: 1000000,
            project: Api.projectId
        });
        const isconnectTransform = (v) => {
            const isconnectType = {
                ONLINE: '运行中',
                DEBUG: '调试中',
            }
            return r.propOr(v)(v)(isconnectType)
        }

        this.setState({
            data: mapIndexed((i, k) => (
                {
                    key: i.tag,
                    order: k + 1 < 10 ? `0${k + 1}` : k + 1,
                    id: i.tag,
                    name: i.title,
                    model: isconnectTransform(i.status),
                    cmnsta: i.isconnect,
                    time: moment(i.lastupdate * 1000).format('YYYY-MM-DD HH:mm:ss'),
                    ip: i.ip,
                    allInformation: i
                }
            ))(detail),
            total: detail.length > 0 ? detail.length : 0,
            tableLoading: false
        })

    }

    componentDidMount() {
        this.getData()
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }

    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 90,
        }, {
            title: '建筑编码',
            dataIndex: 'id',
            key: 'id',
            width: 150,
        }, {
            title: '设备名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} id={this.state.data.id} record={record} />
            ),
            width: 150,
        }, {
            title: '设备模式',
            dataIndex: 'model',
            key: 'model',
            width: 150,
        }, {
            title: '通讯状态',
            dataIndex: 'cmnsta',
            key: 'cmnsta',
            width: 150,
            render: (text, record) => {
                return <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked={text} />
            }
        }, {
            title: '最后通讯时间',
            dataIndex: 'time',
            key: 'time',
            width: 150,
        }, {
            title: '管理器IP地址',
            dataIndex: 'ip',
            key: 'ip',
            width: 190,
        }, {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <div>
                    {/* <Management record={record} isUpData={this.upData} /> */}
                    <Delete record={record} />
                </div>
            ),
            width: 150,
        },
        {
            title: 'allInformation',
            dataIndex: 'allInformation',
            key: 'allInformation',
            className: 'hidden'
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: this.state.total,
            liData: ['搜索']
        }
        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.state.data}
                    scroll={{ y: 580 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    rowKey='manager'
                    className='reportRightTableTab' />
                {/* <TableBottom number='10' /> */}
            </div >
        )
    }
}


export default Manager;