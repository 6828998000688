import React, {useContext} from 'react';
import Echarts from 'src/components/echarts/echarts';
import {context, loadNodeUsage} from '../../analysis'
import {of} from 'rxjs'
import './analysisNodeUsage.scss';

const AnalysisNodeUsage = () => {
  const {state, dispatch} = useContext(context)
  // console.log('AnalysisNodeUsage', state);
  const echartsData = {
    tit: '建筑用能消耗',
    ulShow: ['day', 'month', 'years', 'datePicker','exportExcel'],
      // tooltip:{},
      toolbox:{
      show:true
      },
    data: state.nodeUsage,
    handleScaleChange: (usagePeriod, usageDate) => {
      dispatch(state => of({...state, loading: true}))
      return dispatch(state => loadNodeUsage({...state, loading: true,
        usagePeriod,
        usageDate}))
    },
    selection: state.usagePeriod,
    pickedDate: state.usageDate
  }
  return (
    <div className='analysis_right_top'>
      <Echarts props={echartsData}/>
    </div>
  )
}

export default AnalysisNodeUsage;