import React, {useContext,useState,useEffect} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import iconv  from 'iconv-lite'
import moment from 'moment';
// import {context, loadNodeUsage} from '../report'
// import {context, loadNodeUsage} from '../indexMidAverage'
import {of, from} from 'rxjs'

import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';
import echarts from "echarts/lib/echarts";
import axios from 'axios';
import Util2  from 'src/util/util2';
import './intervalReport.scss'
import {setCurrentProject} from "../../../../context/NavItem";


export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))
}
const regExp = /cn[\d\w\/]*/g;
const DecodeGB2312 = function (val) {
    return iconv.decode(new Buffer(val, 'binary'), 'GB2312');
};

const regionJsonConverter = Util2.regionJsonConverter;

const projectTypeTransfer= Util2.projectTypeTransfer;

const projectTypeWeight= {
    SPORT: 1,
    CBD: 2,
    HOSPITAL: 3,
    HOTEL: 4,
    MARKET: 5,
    OFFICE: 6,
    TEACH: 7,
    OTHER: 8,
}
const onSelect = (record) => {
    // alert('999');
    console.log(record);
    console.log(record.BuildName);
    // return;
    let value = record.projectId;
    let title = record.BuildName;
    console.log('onSelect', title);
    setCurrentProject(value, title)
    // window.location.reload()
    console.log(window.location.href);
    console.log(window.location.hostname);
    console.log(window.location.pathname);

    let href =  window.location.href;
    if(href.indexOf('localhost')!=-1 || href.indexOf('3000')!=-1){
        window.location.href = 'http://localhost:3000/';
    }
    if(href.indexOf('wznhjc')!=-1){
        // console.log(url.replace(regExp,'cn/'));
        window.location.href = href.replace(regExp,'cn/');
    }

}
const nodeUsage = async ({node, from, to, deviceType, search}) => {
    const {data: dayReport} = await Api.get(`/api/v3/project/${Api.projectId}/report/${node}?from=${from}&to=${to}`)

    const isFn = n => {
        const {sensor, title} = n;
        const re = new RegExp(search)
        return r.test(re)(sensor) || r.test(re)(title)
    }
    // console.log(dayReport)
    // const all = r.filter(isFn)(translateDeviceType(deviceType, dayReport));
    // const tableData = all.map(({usage: sum, sensor: instrumentId, channel, cost, startScale = 0, endScale = 0, price, coding, title, type, comi = '-'}, i) => ({
    //     sum,
    //     instrumentId,
    //     channelName: translateChannel(channel),
    //     order: i + 1 < 10 ? `0${i + 1}` : i + 1,
    //     name: title,
    //     eqType: translateType(type).name,
    //     price,
    //     cost,
    //     energyType: translateSubentry(coding),
    //     startScale: Number(startScale).toFixed(2),
    //     endScale: Number(endScale).toFixed(2),
    //     comi,
    //
    // }))

    // const totalUsage = r.compose(r.sum, r.flatten, r.map(v => r.prop('usage')(v)))
    // (r.filter(n => !r.equals('-', r.prop('usage')(n)))(all))
    //
    // const totalCost = r.compose(r.sum, r.flatten, r.map(v => r.prop('cost')(v)))
    // (r.filter(n => !r.equals('-', r.prop('cost')(n)))(all))

    return {
        loading: false,
        node,
        // select: r.map(([k, v]) => (translateType(k)))(r.toPairs(dayReport)),
        tableData:dayReport,
        from,
        to,
        deviceType,
    }
}

const style1 = {width:'8%'}


const IntervalReport = (props) => {
    // const {state: {loading, tableData, from, to, deviceType, select, total}, dispatch} = useContext(context);
    const [tableData, setTableData] = useState([]);
    const [style1, setStyle1] = useState({width:'8%'});
    // const tableData = [{
    //     average: "019010400616",
    //     BuildName: "2F???-150/5",
    //     order: "01",
    //     type: "1",
    //     area: "344.14",
    //     headCount: "14.92"}];

    const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData));
    const columns = [
        {
        title: '序号',
        dataIndex: 'order',
        key: 'order',
            width: '8%',
            // width:{style1},
    },
        {
        title: '项目名称',
        dataIndex: 'BuildName',
        key: 'BuildName',
            width: '8%',
            // width:{style1},
        // render: (text, record) => (
        //     <Tooltip placement="top" title={text}>
        //         <span className='tableName'>{text}</span>
        //     </Tooltip>
        // ),
    }, {
        title: '区域',
        dataIndex: 'regionName',
        key: 'regionName',
            width: '8%',
            // width:{style1},
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
        sorter:(a,b)=>a.region > b.region
    },
        {
        title: '类型名称',
        dataIndex: 'sectorName',
        key: 'sectorName',
            width: '8%',
            // width:{style1},
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
        //    --
        sorter:(a,b)=>a.sector > b.sector
    },
        {
            title: '正常电表数',
            dataIndex: 'OnlineMeterCnt',
            key: 'OnlineMeterCnt',
            width: '8%',
            // width:{style1},
            sorter:(a,b)=>a.OnlineMeterCnt > b.OnlineMeterCnt,
            render: (text, record) => {
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>{text}</span>
            },
        },
        {
            title: '异常电表数',
            dataIndex: 'ErrMeterCnt',
            key: 'errMeterCnt',
            width: '8%',
            // width:{style1},
            // sorter: (a, b) => a.sum - b.sum,
            render: (text, record) => {
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>

            },
        },
        {
            title: '建筑单位',
            dataIndex: 'buildCompany',
            key: 'buildCompany',
            width: '8%',
        },
        {
            title: '建筑联系人',
            dataIndex: 'buildMan',
            key: 'buildMan',
            width: '8%',
        },
        {
            title: '建筑联系电话',
            dataIndex: 'buildPhone',
            key: 'buildPhone',
            width: '8%',
        },
        {
            title: '施工单位',
            dataIndex: 'constructCompany',
            key: 'constructCompany',
            width: '8%',
        },
        {
            title: '施工联系人',
            dataIndex: 'constructMan',
            key: 'constructMan',
            width: '8%',
        },
        {
            title: '施工联系电话',
            dataIndex: 'constructPhone',
            key: 'constructPhone',
            width: '8%',
        },
        {
            title: '电表通讯时间',
            dataIndex: 'MeterTime',
            key: 'MeterTime',
            width: '4%',
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,
        },
        {
            title: '正常采集器',
            dataIndex: 'OnlineCollectorCnt',
            key: 'OnlineCollectorCnt',
            // width: '4%',
            className: 'hide',
            // onFilter: (value, record) => value=='y'?'在线':'不在线',
            // sorter: (a, b) => a.sum - b.sum,
            render: (text, record) => {
                return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>{text}</span>

            },
        },
        {
            title: '异常采集器',
            dataIndex: 'ErrCollectorCnt',
            key: 'ErrCollectorCnt',
            // width: '4%',
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,

            render: (text, record) => {
                return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>

            },
        },

        {
            title: '采集器通讯时间',
            dataIndex: 'CollectorTime',
            key: 'CollectorTime',
            // width: '4%',
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,
        },
        {
            title: '区域编号',
            dataIndex: 'region',
            key: 'region',
            // width: '6%',
            // width:{style1},
            className: 'hide',
        },
        {
            title: '项目id',
            dataIndex: 'projectId',
            key: 'projectId',
            // width: '4%',
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,
        }, {
            title: '项目编码',
            dataIndex: 'BuildCode',
            key: 'BuildCode',
            // width: '6%',
            width:{style1},
            className: 'hide',
        },{
            title: '类型',
            dataIndex: 'sector',
            key: 'sector',
            // width: '6%',
            // width:{style1},
            className: 'hide',
        },
    ];

    const old_data_get = () => {
        const query = {from:'20200101',
            to:'20200103',
            project:'5d7f6926d4135f0011188ffd',
            sector:'123',
            regionProjects: Api.regionProjects?(r.filter((x)=>x !=null))(r.map(y=>{console.log(y);return y.projectId})(Api.regionProjects)):[],};
        let tableData=[];
        console.log('query');
        console.log(query);
        console.log(Api.regionProjects);
        const {data: dayReport} = Api.postV3('/deviceNew/statusDetailAndContact',query).then(res =>{
            console.log('statusDetail'+res.data);
            let resDevice = res.data.resDevices;
            let resCollector = res.data.resCollectors;
            let registerInfo = res.data.registerInfo;
            console.log('res device collector ---- ');
            console.log(resDevice);
            console.log(resCollector);
            console.log(registerInfo);
            console.log(Util2.regionJsonConverter);
            let i = 0;
                r.map(y => (y.projectName?tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    BuildName:y.projectName,
                        BuildCode:y.building,
                    region:Number(y.region),
                    regionName:regionJsonConverter[y.region],
                    sector:Number(projectTypeWeight[y.sector]),
                    sectorName:projectTypeTransfer[y.sector],
                    OnlineMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][0]:'-',
                    ErrMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][1]:'-',
                    MeterTime:'',
                    OnlineCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][0]:'-',
                    ErrCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][1]:'-',
                    CollectorTime:'',
                    projectId:y.projectId,
                    buildCompany:(registerInfo[y.projectId]&&registerInfo[y.projectId].length>0)?(registerInfo[y.projectId][0].buildCompany):'-',
                    buildMan:(registerInfo[y.projectId]&&registerInfo[y.projectId].length>0)?(registerInfo[y.projectId][0].buildMan):'-',
                    buildPhone:(registerInfo[y.projectId]&&registerInfo[y.projectId].length>0)?(registerInfo[y.projectId][0].buildPhone):'-',
                    constructCompany:(registerInfo[y.projectId]&&registerInfo[y.projectId].length>0)?(registerInfo[y.projectId][0].constructCompany):'-',
                    constructMan:(registerInfo[y.projectId]&&registerInfo[y.projectId].length>0)?(registerInfo[y.projectId][0].constructMan):'-',
                    constructPhone:(registerInfo[y.projectId]&&registerInfo[y.projectId].length>0)?(registerInfo[y.projectId][0].constructPhone):'-',
                    }):''))(Api.regionProjects);
                console.log('tableData');
                console.log(tableData);
                setTableData(tableData);
        }
        );
    }

    useEffect(() => {
        console.log('useEffectRight go to run ---------');
        //请求数据到表格里面
        let old_data = [];
        old_data_get();
        // axios({
        //     methods: 'post',
        //     url: 'http://192.168.0.132:8192/weather/',
        //     params: {
        //         id:12
        //     }
        // })
        // axios.post('http://192.168.0.132:8192/weather',{},{})
    }, [])

    const tableTop = {
        result: tableData.length,
        liData: ['区间', '选择', '搜索',],
        // from,
        // to,
        // select,
        // deviceType,
        columns,
        tableData,
        // handleScaleChange: ({deviceType, from, to}) => {
        //     dispatch(state => of({...state, loading: true}))
        //     return dispatch(state => loadNodeUsage({
        //         ...state, loading: true,
        //         deviceType,
        //         from,
        //         to
        //     }))
        // },
    };

    return (
        <div className='reportRightTable'>
            {/*<TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}*/}
                      {/*tableData={tableData}/>*/}
            <Table
                columns={columns}
                dataSource={tableData}
                scroll={{y: 710}}
                // pagination={true}
                pagination={{defaultPageSize: 10}}
                onRow={record => {
                    return {
                        onClick: event => {/*console.log(record);*/onSelect(record)
                            }, // 点击行
                        onDoubleClick: event => {},
                        onContextMenu: event => {},
                        onMouseEnter: event => {}, // 鼠标移入行
                        onMouseLeave: event => {},
                    };
                }}
                // loading={loading}
                className='reportRightTableTab'/>

        </div>
    )
}


export default IntervalReport;