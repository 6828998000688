import React, { Component } from 'react';
import * as r from 'ramda';
import { Row, Col } from 'antd';

import './indexBottom2.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';



const assistdata = [];
const center = ['50%', '50%'],
  radius = ['40%', '65%'],
  selectedOffset = 10,
  gridNumber = 16;
const waterColor = '#b0f0fe',
  eleColor = '#ffa348',
  sumColor = '#7accfc';

const charetsContext = ({ sum, showNub, color }) => {
  const iAdd = sum / gridNumber;
  let assistdata2 = [];
  for (let i = 0; i < showNub - iAdd; i += iAdd) {
    assistdata2.push({
      name: "1",
      value: iAdd,
      selected: true,
      itemStyle: {
        show: false,
        normal: {
          color: color
        }
      }
    });
  }
  if (showNub % iAdd !== 0) {
    assistdata2.push({
      name: "1",
      value: showNub % iAdd,
      selected: true,
      itemStyle: {
        show: false,
        normal: {
          color: color
        }
      },
    });
  }
  assistdata2.push({
    name: "2",
    value: sum - showNub,
    selected: true,
    itemStyle: { color: 'transparent' }
  });
  return assistdata2
}

class IndexBottom2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      areaDosage: 0,
      perCapitaDosage: 0,
      areaElectricDosage: 0,
      perCapitaElectricDosage: 0,
      areaWaterDosage: 0,
      perCapitaWaterDosage: 0,
      sum: 0,
      active: "day"
    }
    this.reactEcharts = null;
  }


  componentDidMount() {
    const { areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum, water, ele } = this.state;
    this.reactEcharts = echarts.init(document.getElementById('ReactEcharts'));
    let a = 0;
    let isTrue = true;

    this.reactEcharts.setOption({
      series: [
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['30%', '40%'],
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: water * 0.8, color: waterColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['45%', '55%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false,
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: ele * 0.8, color: eleColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['60%', '70%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            shadowColor: `rgba(134, 204, 255, ${a})`,
            shadowBlur: 10,
          },
          data: charetsContext({ sum, showNub: sum * 0.85, color: sumColor })
        },
      ]
    })

    setInterval(() => {

      if (isTrue) {
        a += 0.05;
        if (a >= 0.9) {
          isTrue = false;
        }
      }
      if (!isTrue) {
        a -= 0.05;
        if (a <= 0) {
          isTrue = true;
        }
      }

      // this.ReactEcharts.setOption(

      // )

    }, 100)

  }

  async componentWillReceiveProps(nextProps) {
    const { electricity, total, water } = nextProps;
    const sum = total;
    const ele = electricity;

    const { headCount: people, totalArea: area } = await Api.postV3('/info/statistics', {
      project: Api.projectId
    }).then(r.prop('data'))


    const areaDosage = sum && area ? (sum / area).toFixed(2) : 0;
    const perCapitaDosage = sum && people ? (sum / people).toFixed(2) : 0;

    const areaElectricDosage = ele && area ? (ele / area).toFixed(2) : 0;
    const perCapitaElectricDosage = ele && people ? (ele / people).toFixed(2) : 0;

    const areaWaterDosage = water && area ? (water / area).toFixed(2) : 0;
    const perCapitaWaterDosage = water && people ? (water / people).toFixed(2) : 0;

    this.reactEcharts.setOption({
      series: [
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['30%', '40%'],
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: water * 0.8, color: waterColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['45%', '55%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false,
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: ele * 0.8, color: eleColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['60%', '70%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            shadowBlur: 10,
          },
          data: charetsContext({ sum, showNub: sum * 0.85, color: sumColor })
        },
      ]
    })
    this.setState({
      areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum,
      water,
      ele
    })
  }

  selected(params) {

    switch (params) {
      case 'day':
        this.getData('/energyRegionNew/usageRatioDaily', 'day')
        break;
      case 'month':
        this.getData('/energyRegionNew/usageRatioMonthly', 'month')
        break;
      case 'year':
        this.getData('/energyRegionNew/usageRatioAnnually', 'year')
        break;
      default:
        this.getData('/energyRegionNew/usageRatioDaily', 'day')
        break;

    }
    // console.log(this.state)
  }


  render() {
    const { areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum, water, ele } = this.state;

    return (
      <div className='indexBottom2'>
        <div className='indexBottomHead'>
          <span>综合能耗</span>

        </div>

        <div id='ReactEcharts' style={{ width: "100%", height: "240px" }}>

        </div>
        <div className="legend">
          <p></p>
          <ul>
            <li><i className="icon" style={{ backgroundColor: waterColor }}></i><span>水能耗: {water} kecg</span></li>
            <li><i className="icon" style={{ backgroundColor: eleColor }}></i><span>电能耗: {ele} kecg</span></li>
            <li><i className="icon" style={{ backgroundColor: sumColor }}></i><span>总能耗: {sum} kecg</span></li>
          </ul>
        </div>
        <div className='indexBottom2Bottom'>
          <Row gutter={8} >
            <Col span={8}>面积能耗：{areaDosage}kecg</Col>
            <Col span={8}>面积水耗：{areaWaterDosage}kecg</Col>
            <Col span={8}>面积电耗：{areaElectricDosage}kecg</Col>
          </Row>
          <Row gutter={8} >
            <Col span={8}>人均能耗：{perCapitaDosage}kecg</Col>
            <Col span={8}>人均水耗：{perCapitaWaterDosage}kecg</Col>
            <Col span={8}>人均电耗：{perCapitaElectricDosage}kecg</Col>
          </Row>
        </div>

      </div>
    )
  }
}


export default IndexBottom2;
