import React , { useContext } from 'react';

import './pvData.scss';
// import Api from 'api'

import moment from 'moment';

import Img_ScreenVersionIndexBottom from '../../img/ScreenVersionIndexBottom.png'


import PvDataHead from './pvDataHead';
import { Color,ColorContext , UPDATE_COLOR } from "./color";
import IndexMidLeftForWeather from "./indexMid/indexMidLeft/indexMidLeftForWeather";
import {DatePicker} from "antd/lib/index";
const {MonthPicker, RangePicker} = DatePicker;
const disabledDate = (current) => {
    return current > moment().endOf('day')
}
class PvData extends React.Component {


    constructor(props){
        super(props);
        this.state={
            keyWord: true,

            from:moment().format('YYYYMMDD'),
            to:moment().format('YYYYMMDD'),
        }
    }


    //设置组件A input的查询参数
    setKeyWorld = (keyWord) => {
        this.setState({
            keyWord
        })
    }



  render() {
      const { from,to} = this.state;
      const btnStyle2 = {
          display:'none'
      };
      const Style3 = {
          display:this.state.keyWord?'none':'block'
      };
    return (
     <Color>
      <div className="ScreenVersionIndex">
        <PvDataHead  setKeyWord = {this.setKeyWorld} co='111'/>
        {/*<div id='screenContainer' className='ScreenVersionIndexConainer' >*/}
          {/*<IndexLeftPointIndex />*/}
          {/*<div className='ScreenVersionIndexConainerLeft'>*/}
            {/*<IndexTop />*/}
            {/*<IndexMid />*/}
            {/*<IndexBottom />*/}
          {/*</div>*/}
          {/*<div className='borderRightBorder'>*/}
            {/*<div className='borderRightBorder2 borderRightBorder2Top'></div>*/}
            {/*<div className='borderRightBorder2 borderRightBorder2Bottom'></div>*/}
          {/*</div>*/}
        {/*</div>*/}
        {/*这里填充新的页面样式*/}
          <div id='midLeftForWeather'>
              <IndexMidLeftForWeather keyWord = {this.state.keyWord} />
          </div>



        <img src={Img_ScreenVersionIndexBottom} alt="" className='Img_ScreenVersionIndexBottom'/>
      </div>
     </Color>
    )
  }

};


export default PvData;
