import React, {useContext,useState} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import ReactEcharts from 'echarts-for-react';
import {context, loadNodeUsage} from '../oldDate'
import {of, from} from 'rxjs'
import ModelEchartsOldData from './modelEchartsOldData';

import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';

const OldDataInterval = () => {
    const {state: {loading, tableData, from, to, deviceType, select, total,page,nums,size}, dispatch} = useContext(context);
    const [view,setView] = useState(true);
    const [style,setStyle] = useState({ display: "block",width:'100%',height:'100%' });
    const [style1,setStyle1] = useState({ display: "none",width:'100%',height:'100%'});
    const [style2,setStyle2] = useState({ width:'100%',height:'100%'});
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState({ allInformation: { title: '', channels: {} } });
    const handleCancel = (e) => {
        setVisible(false)
    }
    // const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData));
    const columns = [
    //     {
    //     title: '序号',
    //     dataIndex: 'order',
    //     key: 'order',
    //     width: '4%',
    // },
        {
        title: '项目名称',
        dataIndex: 'BuildName',
        key: 'BuildName',
        width: '6%',
        // render: (text, record) => (
        //     <Tooltip placement="top" title={text}>
        //         <span className='tableName'>{text}</span>
        //     </Tooltip>
        // ),
    },{
        title: '支路名称',
        dataIndex: 'EquipmentID',
        key: 'EquipmentID',
        width: '6%',
        // render: (text, record) => (
        //     <Tooltip placement="top" title={text}>
        //         <span className='tableName'>{text}</span>
        //     </Tooltip>
        // ),
    }, {
        title: '项目编码',
        dataIndex: 'BuildCode',
        key: 'BuildCode',
        width: '6%',
    }, {
        title: '地址',
        dataIndex: 'Address',
        key: 'Address',
        width: '6%',
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        // sortDirections: ['descend'],
    },
        {
            title: '面积',
            dataIndex: 'BuildArea',
            key: 'BuildArea',
            width: '6%',

        },
        // {
        //     title: '热面积',
        //     dataIndex: 'HeatArea',
        //     key: 'HeatArea',
        //     width: '4%',
        //     // sorter: (a, b) => a.sum - b.sum,
        // },
        // {
        //     title: '使用人数',
        //     dataIndex: 'UsedPerson',
        //     key: 'UsedPerson',
        //     width: '4%',
        //     // onFilter: (value, record) => value=='y'?'在线':'不在线'
        //     // sorter: (a, b) => a.sum - b.sum,
        // },
        // {
        //     title: '最后状态',
        //     dataIndex: 'online',
        //     key: 'online',
        //     width: '4%',
        //     // sorter: (a, b) => a.sum - b.sum,
        // },
        {
            title: '采集时间',
            dataIndex: 'CollectionTime',
            key: 'CollectionTime',
            width: '4%',
            // sorter: (a, b) => a.sum - b.sum,
        },
        {
            title: '能耗值',
            dataIndex: 'Qty',
            key: 'Qty',
            width: '4%',
            // sorter: (a, b) => a.sum - b.sum,
        }
    ];


    const tableTop = {
        result: tableData.length,
        // liData: ['区间', '选择', '搜索',],
        liData: ['区间' ],
        from,
        to,
        select,
        deviceType,
        columns,
        tableData,
        handleScaleChange: ({deviceType, from, to}) => {
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                deviceType,
                from,
                to
            }))
        },
    };

    const onChange=(page) => {
        console.log('page');
        console.log(page);
        dispatch(state => of({...state,page:page, loading: true}))
        return dispatch(state => loadNodeUsage({
            ...state, loading: true,
            page:page
        }))
    }
    const changeView=() => {
        setView(!view);
        console.log('view-----');
        console.log(view);
    }
    //将数据按照支路进行分类


    const option1 = {
        color: ["#87E7FF", "#D3D7E3"],
        grid: { left: '3', top: '25', right: '30', bottom: '22' },
        tooltip: { trigger: 'axis',
        },
        legend: {
        },
        xAxis: {
            axisLine: {       //y轴
                show: true,
                lineStyle: { color: '#ccc' }
            },
            // data: r.isEmpty(this.state.chartsTime2) ? this.state.chartsTime1 : this.state.chartsTime2
            data: [...Array(tableData.length).keys()]
        },
        yAxis: {
            axisLine: {       //y轴
                show: true,
                lineStyle: { color: '#ccc' }
            },
            axisTick: {       //y轴刻度线
                show: false
            },
        },
    series:{
        type: 'line',
            smooth: true,
        data: r.map(y=>y.Qty)(tableData),
        tooltip: {
        trigger: 'axis',
            formatter:function (params, ticket, callback) {
            return '今年平均线'+params.data
        }
    },
        itemStyle: {
            normal: {
                // 定制显示（按顺序）
                color: '#84e0fb'
            }
        }
    }
    }

    return (
        <div className='reportRightTable'>
            <TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}
                      tableData={tableData}/>
            {/*<div>*/}
            {/*<p onClick={changeView}>*/}
                {/*点击切换曲线*/}
            {/*</p>*/}
            {/*</div>*/}
            <div  style={view?style:style1}>
                <Table
                    columns={columns}
                    dataSource={tableData}
                    scroll={{y: 710}}
                    // pagination={false}
                    pagination={{defaultPageSize: 10,
                        current:page,
                        total:nums,
                        onChange:onChange,
                    }}
                    loading={loading}
                    onRow={record => {
                        return {
                            onClick: event => {/*console.log(record);*/setRecord(record)
                            setVisible(!visible)}, // 点击行
                            onDoubleClick: event => {},
                            onContextMenu: event => {},
                            onMouseEnter: event => {}, // 鼠标移入行
                            onMouseLeave: event => {},
                        };
                    }}
                    // onRow={record => {
                    //     return {
                    //         onClick: event => {
                    //             console.log(record);
                    //             // setRecord(record)
                    //             setVisible(!visible)
                    //         },
                    //     };
                    // }}
                    className='reportRightTableTab'/>
            </div>
            <ModelEchartsOldData visible={visible} record={record} handleCancel={handleCancel} />
            {/*<div   style={view?style1:style}>*/}
                {/*<ReactEcharts option={option1} notMerge={true} lazyUpdate={true} style={style2} />*/}
            {/*</div>*/}

        </div>
    )
}


export default OldDataInterval;
