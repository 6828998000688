import React from 'react';
import './barCode.scss';

const BarCode = () => (
    <ul className="barCode">
        <li className="barCode1"></li>
        <li className="barCode2"></li>
        <li className="barCode3"></li>
        <li className="barCode4"></li>
    </ul>
)


export default BarCode