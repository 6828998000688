import React, {useContext} from 'react';
import Echarts from 'src/components/echarts/echarts'
import {context} from '../../../building';
import {Spin} from 'antd';
import * as r from 'ramda';
import {of} from 'rxjs';
import {loadRatio} from 'src/modules/energy/department/department';

const timeMap = {
  daily: 'DAY',
  monthly: 'MONTH',
  annually: 'YEAR',
}

const translateTime = date => {
  return r.propOr(date)(date)(timeMap)
}

const BuildingPercentage = () => {
  const {state: {loading, buildingPie, ratioPeriod: selection}, dispatch} = useContext(
    context)
  const echartsData = {
    tit: '建筑用能占比',
    ulShow: ['day', 'month', 'years'],
    data: {
      color: [
        '#73b3ef',
        '#00c1c4',
        '#7397c1',
        '#98f2f0',
        '#fb9bee',
        '#ff9e4f',
        '#98f2f0'],
      grid: {left: '20', top: '5', right: '10', bottom: '22'},
      legend: {
        show: false,
      },
      tooltip:{},
      series: [
        {
          data: buildingPie,
          type: 'pie',
          radius: ['40%', '75%'],
        }],
    },
    selection,
    handleScaleChange: (ratioPeriod) => {
      dispatch(state => of({...state, loading: true}))
      return dispatch(state => loadRatio({
        ...state, loading: true,
        timetype: translateTime(ratioPeriod),
        ratioPeriod,
      }))
    },
  }
  return (
    <div className="buildingRightBottom2">
      <Spin spinning={loading} size='large'>
        <Echarts props={echartsData}/>
      </Spin>
    </div>
  )
}

export default BuildingPercentage;