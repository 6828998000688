import React, {useContext,useState,useEffect} from 'react';
import {Table, Tooltip,Button,DatePicker,Input,Select} from 'antd';

import * as r from 'ramda';
import iconv  from 'iconv-lite'
import moment from 'moment';
// import {context, loadNodeUsage} from '../report'
// import {context, loadNodeUsage} from '../indexMidAverage'
import {of, from} from 'rxjs'

import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';
import echarts from "echarts/lib/echarts";
import axios from 'axios';
import Util2  from 'src/util/util2';
import './intervalReport.scss'
import {setCurrentProject} from "../../../../context/NavItem";
const { Option } = Select
const {RangePicker} = DatePicker
export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))
}
const regExp = /cn[\d\w\/]*/g;
const DecodeGB2312 = function (val) {
    return iconv.decode(new Buffer(val, 'binary'), 'GB2312');
};

const regionJsonConverter = Util2.regionJsonConverter;

const projectTypeTransfer= Util2.projectTypeTransfer;

const projectTypeWeight= {
    SPORT: 1,
    CBD: 2,
    HOSPITAL: 3,
    HOTEL: 4,
    MARKET: 5,
    OFFICE: 6,
    TEACH: 7,
    OTHER: 8,
}


const onSelect = (record) => {
    // alert('999');
    console.log(record);
    console.log(record.BuildName);
    // return;
    let value = record.projectId;
    let title = record.BuildName;
    console.log('onSelect', title);
    setCurrentProject(value, title)
    // window.location.reload()
    console.log(window.location.href);
    console.log(window.location.hostname);
    console.log(window.location.pathname);

    let href =  window.location.href;
    if(href.indexOf('localhost')!=-1 || href.indexOf('3000')!=-1){
        window.location.href = 'http://localhost:3000/';
    }
    if(href.indexOf('wznhjc')!=-1){
        // console.log(url.replace(regExp,'cn/'));
        window.location.href = href.replace(regExp,'cn/');
    }

}
const nodeUsage = async ({node, from, to, deviceType, search}) => {
    const {data: dayReport} = await Api.get(`/api/v3/project/${Api.projectId}/report/${node}?from=${from}&to=${to}`)

    const isFn = n => {
        const {sensor, title} = n;
        const re = new RegExp(search)
        return r.test(re)(sensor) || r.test(re)(title)
    }

    return {
        loading: false,
        node,
        // select: r.map(([k, v]) => (translateType(k)))(r.toPairs(dayReport)),
        tableData:dayReport,
        from,
        to,
        deviceType,
    }
}

const sms_style1 = {width:'8%'}

let pageStore=1 ;// 不知道为什么，forrun里 currentpage 获取到的是点击前的数值。另用一个来保存最新的数值

const IntervalReport = (props) => {
    // const {state: {loading, tableData, from, to, deviceType, select, total}, dispatch} = useContext(context);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [sms_style1, setSms_style1] = useState({width:'8%'});
    const [sms_style2, setSms_style2] = useState({width:'6%'});
    const [sms_style3, setSms_style3] = useState({width:'520px'});
    const [total, setTotal] = useState(100);
    const [currentPage, setCurrentPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [from, setFrom] = useState(`${moment().format('YYYYMM')}01`);
    const [to, setTo] = useState(moment().format('YYYYMMDD'));
    const [projectSearch, setProjectSearch] = useState('');
    const [singleSearch, setSingleSearch] = useState(1);
    const [type, setType] = useState('1');// 1.显示最近项目状态 2.显示短信 3.显示历史项目状态
    // const tableData = [{
    //     average: "019010400616",
    //     BuildName: "2F???-150/5",
    //     order: "01",
    //     type: "1",
    //     area: "344.14",
    //     headCount: "14.92"}];

    const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData));
    const columns1 = [
        {
        title: '序号',
        dataIndex: 'order',
        key: 'order',
            // width: '8%',
            width:sms_style3,
    },
        {
        title: '项目名称',
        dataIndex: 'BuildName',
        key: 'BuildName',
            width:sms_style3,
            // width:{sms_style1},
        // render: (text, record) => (
        //     <Tooltip placement="top" title={text}>
        //         <span className='tableName'>{text}</span>
        //     </Tooltip>
        // ),
    }, {
        title: '区域',
        dataIndex: 'regionName',
        key: 'regionName',
            width:sms_style3,
            // width:{sms_style1},
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
        sorter:(a,b)=>a.region > b.region
    },
        {
        title: '类型名称',
        dataIndex: 'sectorName',
        key: 'sectorName',
            width:sms_style3,
            // width:{sms_style1},
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
        //    --
        sorter:(a,b)=>a.sector > b.sector
    },
        {
            title: '正常电表数',
            dataIndex: 'OnlineMeterCnt',
            key: 'OnlineMeterCnt',
            width:sms_style3,
            // width:{sms_style1},
            sorter:(a,b)=>a.OnlineMeterCnt > b.OnlineMeterCnt,
            render: (text, record) => {
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>{text}</span>
            },
        },
        {
            title: '异常电表数',
            dataIndex: 'ErrMeterCnt',
            key: 'errMeterCnt',
            width:sms_style3,
            // width:{sms_style1},
            // sorter: (a, b) => a.sum - b.sum,
            render: (text, record) => {
                    return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>

            },
        },
        {
            title: '电表通讯时间',
            dataIndex: 'MeterTime',
            key: 'MeterTime',
            width:sms_style3,
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,
        },
        {
            title: '正常采集器',
            dataIndex: 'OnlineCollectorCnt',
            key: 'OnlineCollectorCnt',
            // width: '4%',
            className: 'hide',
            // onFilter: (value, record) => value=='y'?'在线':'不在线',
            // sorter: (a, b) => a.sum - b.sum,
            render: (text, record) => {
                return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>{text}</span>

            },
        },
        {
            title: '异常采集器',
            dataIndex: 'ErrCollectorCnt',
            key: 'ErrCollectorCnt',
            // width: '4%',
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,

            render: (text, record) => {
                return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>

            },
        },

        {
            title: '采集器通讯时间',
            dataIndex: 'CollectorTime',
            key: 'CollectorTime',
            // width: '4%',
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,
        },
        {
            title: '区域编号',
            dataIndex: 'region',
            key: 'region',
            // width: '6%',
            // width:{sms_style1},
            className: 'hide',
        },
        {
            title: '项目id',
            dataIndex: 'projectId',
            key: 'projectId',
            // width: '4%',
            className: 'hide',
            // sorter: (a, b) => a.sum - b.sum,
        }, {
            title: '项目编码',
            dataIndex: 'BuildCode',
            key: 'BuildCode',
            // width: '6%',
            // width:{sms_style1},
            className: 'hide',
        },{
            title: '类型',
            dataIndex: 'sector',
            key: 'sector',
            width:sms_style3,
            // width: '6%',
            // width:{sms_style1},
            className: 'hide',
        },
        {
            title: '短信状态开关',
            dataIndex: 'smsStatusSwitch',//
            key: 'smsSwitch1',
            width:sms_style3,
            render: (text, record) => {

                return text==='1'?<span onClick={()=>smsSwitchForRun(record)} style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>开启</span>:
                    <span onClick={()=>smsSwitchForRun(record)} style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'blue'}}>关闭</span>

            },
            // width: '8%',
            // sorter: (a, b) => a.sum - b.sum,

        },
        {
            title: '短信申请开关',
            dataIndex: 'smsApplySwitch',
            key: 'smsApplySwitch',
            width:sms_style3,
            render: (text, record) => {

                return text==='1'?<span onClick={()=>smsSwitchForRun(record)} style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>开启</span>:
                    <span onClick={()=>smsSwitchForRun(record)} style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'blue'}}>关闭</span>

            },
            className: 'hide',
            // width: '8%',
            // sorter: (a, b) => a.sum - b.sum,

        },
    ];
    const columns2 = [
        {
        title: '序号2',
        dataIndex: 'order',
        key: 'order',
            width: '8%',
            // width:{sms_style1},
    },
        {
        title: '项目',
        dataIndex: 'project',
        key: 'project',
            width: '8%',
            className: 'hide',
            // width:{sms_style1},
        // render: (text, record) => (
        //     <Tooltip placement="top" title={text}>
        //         <span className='tableName'>{text}</span>
        //     </Tooltip>
        // ),
    }, {
        title: '名称',
        dataIndex: 'project_name',
        key: 'project_name',
            width: '8%',
            // width:{sms_style1},
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
    },
        {
        title: '人员',
        dataIndex: 'man',
        key: 'man',
            width: '8%',
            // width:{sms_style1},
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
        //    --
    },
        {
            title: '电话',
            dataIndex: 'phone',
            key: 'phone',
            width: '8%',
            // width:{sms_style1},
        },
        {
            title: '日期',
            dataIndex: 'date',
            key: 'date',
            width: '8%',
            // width:{sms_style1},
            // sorter: (a, b) => a.sum - b.sum,

        },
        {
            title: '短信类型',
            dataIndex: 'sms_type',
            key: 'sms_type',
            width: '4%',
            // sorter: (a, b) => a.sum - b.sum,
        },
        {
            title: '短信流程',
            dataIndex: 'sms_proc',
            key: 'sms_proc',
            width: '8%',
            // className: 'hide',
            // onFilter: (value, record) => value=='y'?'在线':'不在线',
            // sorter: (a, b) => a.sum - b.sum,
            // render: (text, record) => {
            //     return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>{text}</span>
            //
            // },
        },
        {
            title: '短信内容',
            dataIndex: 'sms_content',
            key: 'sms_content',
            width: '8%',
            // sorter: (a, b) => a.sum - b.sum,

        },
    ];

    const projectHisStatusCol = [
        {
            title: '序号2',
            dataIndex: 'order',
            key: 'order',
            width: '8%',
            // width:{sms_style1},
        },
        {
            title: '项目',
            dataIndex: 'project',
            key: 'project',
            width: '8%',
            // width:{sms_style1},
            // render: (text, record) => (
            //     <Tooltip placement="top" title={text}>
            //         <span className='tableName'>{text}</span>
            //     </Tooltip>
            // ),
        }, {
            title: '名称',
            dataIndex: 'project_name',
            key: 'project_name',
            width: '8%',
            // width:{sms_style1},
            // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
            // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
            //sortDirections: ['descend'],
        },
        {
            title: '时间',
            dataIndex: 'time',
            key: 'time',
            width: '8%',
            // width:{sms_style1},
            // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
            // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
            //sortDirections: ['descend'],
            //    --
        },
        {
            title: '正常仪表',
            dataIndex: 'device_normal',
            key: 'device_normal',
            width: '8%',
            // width:{sms_style1},
        },
        {
            title: '异常仪表',
            dataIndex: 'device_abnormal',
            key: 'device_abnormal',
            width: '8%',
            // width:{sms_style1},
        },
        {
            title: '正常采集器',
            dataIndex: 'collector_normal',
            key: 'collector_normal',
            width: '8%',
            // width:{sms_style1},
        },
        {
            title: '异常采集器',
            dataIndex: 'collector_abnormal',
            key: 'collector_abnormal',
            width: '8%',
            // width:{sms_style1},
        },
    ];

    const old_data_get = () => {
        const query = {from:'20200101',
            to:'20200103',
            project:'5d7f6926d4135f0011188ffd',
            sector:'123',
            regionProjects: Api.regionProjects?(r.filter((x)=>x !=null))(r.map(y=>{console.log(y);return y.projectId})(Api.regionProjects)):[],};
        let tableData=[];
        console.log('query');
        console.log(query);
        console.log(Api.regionProjects);
        const {data: dayReport} = Api.postV3('/deviceNew/statusDetail',query).then(res =>{
            console.log('statusDetail'+res.data);
            let resDevice = res.data.resDevices;
            let resCollector = res.data.resCollectors;
            console.log('res device collector ---- ');
            console.log(resDevice);
            console.log(resCollector);
            console.log(Util2.regionJsonConverter);
            let i = 0;
                r.map(y => (y.projectName?tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    BuildName:y.projectName,
                        BuildCode:y.building,
                    region:Number(y.region),
                    regionName:regionJsonConverter[y.region],
                    sector:Number(projectTypeWeight[y.sector]),
                    sectorName:projectTypeTransfer[y.sector],
                    OnlineMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][0]:'-',
                    ErrMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][1]:'-',
                    MeterTime:'',
                    OnlineCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][0]:'-',
                    ErrCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][1]:'-',
                    CollectorTime:'',
                    projectId:y.projectId,
                    }):''))(Api.regionProjects);
                console.log('tableData');
                console.log(tableData);
                setTableData(tableData);
                setColumns(columns1);
        }
        );
    }
    const  onTimeInterval = (date, dateString) => {
        setFrom(moment(date[0]).format('YYYYMMDD'));
        setTo(moment(date[1]).format('YYYYMMDD'));


    }
    const  projectChange = (e) => {
        setProjectSearch(e.target.value);
        console.log('projectSearch :  ' + e.target.value);

    }
    const projectHisStatusSwitch = ()=>{

        //显示短信1
        // console.log('22');
        // return;

        const query = {from:`${from}`,
            to:`${to}`,
            project:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            projectSearch:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            singleSearch:singleSearch,
            skip:0,
            limit:10,};
        let tableData=[];
        console.log('projectHisStatusSwitch query===> ');
        console.log(query);
        console.log(Api.regionProjects);
        const {data: dayReport} = Api.postV3('/projectNew/projectHisStatus',query).then(res =>{
                console.log('projectHisStatus'+res.data);
                let total = res.data.total[0].cnt;
                let list = res.data.list;
                console.log('projectHisStatus ---- ');
                console.log(total);
                console.log(list);

                let i = 0;

                r.map(y => tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    project:y.project,
                    project_name:y.project_name,
                    time:y.time,
                    device_normal:y.device_normal,
                    device_abnormal:y.device_abnormal,
                    collector_normal:y.collector_normal,
                    collector_abnormal:y.collector_abnormal,
                }))(list);
                console.log('tableData-onChange');
                console.log(tableData);
                setTotal(total);
                setColumns(projectHisStatusCol);
                setTableData(tableData);
                // setTableData([{BuildName:'23423432'}]);
                setCurrentPage(1);

            }
        );

        setType('3');
    }
    const projectStatusSwitch = (skip,limit)=>{

        const allRegionProjectIds = Api.regionProjects?(r.filter((x)=>x !=null))(r.map(y=>{console.log(y);return y.projectId})(Api.regionProjects)):[];
        const partRegionProjectIds = allRegionProjectIds.slice((skip-1)*limit,skip*limit);
        const regionProjectsInfoPart =(r.filter((x)=>partRegionProjectIds.indexOf(x.projectId)!==-1))(Api.regionProjects);
        const query = {
            // from:'20200101',
            // to:'20200103',
            project:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            sector:'123',
            singleSearch:singleSearch,
            skip:skip,
            limit:limit,
            regionProjects: allRegionProjectIds.slice((skip-1)*limit,skip*limit),};
        console.log('projectStatusSwitch query :===>' + query);
        let tableData=[];
        console.log('query');
        console.log(query);
        console.log(query.regionProjects);
        const {data: dayReport} = Api.postV3('/deviceNew/statusDetailForSms',query).then(res =>{
                console.log('statusDetail'+res.data);
                let resDevice = res.data.resDevices;
                let resCollector = res.data.resCollectors;
                let projectSmsSwitch = res.data.projectSmsSwitch;
                console.log('res device collector ---- ');
                console.log(resDevice);
                console.log(resCollector);
                console.log(projectSmsSwitch);
                console.log(Util2.regionJsonConverter);
                let i = 0;

                // let regionProjectsPart = Api.regionProjects.slice((1-1)*limit,1*limit);
                // console.log('regionProjectsPart --');
                // console.log(regionProjectsPart);
                r.map(y => (y.projectName?tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    BuildName:y.projectName,
                    BuildCode:y.building,
                    region:Number(y.region),
                    regionName:regionJsonConverter[y.region],
                    sector:Number(projectTypeWeight[y.sector]),
                    sectorName:projectTypeTransfer[y.sector],
                    OnlineMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][0]:'-',
                    ErrMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][1]:'-',
                    MeterTime:'',
                    OnlineCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][0]:'-',
                    ErrCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][1]:'-',
                    CollectorTime:'',
                    projectId:y.projectId,
                    smsApplySwitch:projectSmsSwitch[y.projectId]?projectSmsSwitch[y.projectId][0].smsApplySwitch:'-',
                    smsStatusSwitch:projectSmsSwitch[y.projectId]?projectSmsSwitch[y.projectId][0].smsStatusSwitch:'-',
                }):''))(regionProjectsInfoPart);
                console.log('tableData-onChange');
                console.log(tableData);
                setTotal(allRegionProjectIds?allRegionProjectIds.length:0);
                setColumns(columns1);
                setTableData(tableData);
                // setTableData([{BuildName:'23423432'}]);
                setCurrentPage(skip);

            }
        );

        setType('1');
    }

    //切换状态
    const smsSwitchForRun = (record)=>{
        console.log('smsSwitchForRun---');
        console.log(record);
        console.log(record.regionName);
        console.log(record.BuildName);

        if(!record.projectId)
            return;
        console.log('currentPage -- ');
        console.log(currentPage);
        console.log('pageStore -- ');
        console.log(pageStore);
        // return;
    //    切换状态
        const query = {
            project:record.projectId,
            smsApplySwitch:record.smsApplySwitch==='1'?'0':'1',
            smsStatusSwitch:record.smsStatusSwitch==='1'?'0':'1',

        };
        let tableData=[];
        console.log('query');
        console.log(query);
        // console.log(Api.regionProjects);
        const {data: dayReport} = Api.postV3('/projectNew/smsSwitchForRun',query).then(res =>{
            console.log('smsSwitchForRun'+res.data);
            //刷新下数据
            projectStatusSwitch(pageStore,limit);
        })
    }



    const smsSwitch = ()=>{
        //显示短信
        // console.log('22');
        // return;

        const query = {from:`${from}000000`,
            to:`${to}235959`,
            project:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            projectSearch:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            singleSearch:singleSearch,
            skip:0,
            limit:10,};
        let tableData=[];
        console.log('smsSwitch query ====> ');
        console.log(query);
        console.log(Api.regionProjects);
        const {data: dayReport} = Api.postV3('/projectNew/smsHistory',query).then(res =>{
                console.log('smsHistory'+res.data);
                let total = res.data.total[0].cnt;
                let list = res.data.list;
                console.log('smsHistory ---- ');
                console.log(total);
                console.log(list);

                let i = 0;

                r.map(y => tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    project:y.project,
                    title:y.title,
                    project_name:y.project_name,
                    man:y.man,
                    phone:y.phone,
                    date:y.date,
                    sms_type:y.sms_type,
                    sms_proc:y.sms_proc,
                    sms_content:y.sms_content,
                }))(list);
                console.log('tableData-onChange');
                console.log(tableData);
                setTotal(total);
                setColumns(columns2);
                setTableData(tableData);
                // setTableData([{BuildName:'23423432'}]);
                setCurrentPage(1);

            }
        );

        setType('2');


    }
    const Search = ()=>{
        //显示短信
        // console.log('22');
        // return;

        const query = {from:`${from}000000`,
            to:`${to}235959`,
            project:'5d7f6926d4135f0011188ffd',
            projectSearch:projectSearch,
            skip:0,
            limit:10,};
        let tableData=[];
        console.log('query');
        console.log(query);
        console.log(Api.regionProjects);
        const {data: dayReport} = Api.postV3('/projectNew/smsHistory',query).then(res =>{
                console.log('smsHistory'+res.data);
                let total = res.data.total[0].cnt;
                let list = res.data.list;
                console.log('smsHistory ---- ');
                console.log(total);
                console.log(list);

                let i = 0;

                r.map(y => tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    project:y.project,
                    title:y.title,
                    man:y.man,
                    phone:y.phone,
                    date:y.date,
                    sms_type:y.sms_type,
                    sms_proc:y.sms_proc,
                    sms_content:y.sms_content,
                }))(list);
                console.log('tableData-onChange');
                console.log(tableData);
                setColumns(columns2);
                setTableData(tableData);
                // setTableData([{BuildName:'23423432'}]);
                setCurrentPage(1);

            }
        );

    }

    useEffect(() => {
        console.log('useEffectRight go to run ---------');
        setTotal(Api.regionProjects?((r.filter((x)=>x !=null))(r.map(y=>{console.log(y);return y.projectId})(Api.regionProjects))).length:0);
        //请求数据到表格里面
        let old_data = [];
        // old_data_get();

    }, [])


    const smsChange = (pagination)=>{
        const query = {from:`${from}000000`,
            to:`${to}235959`,
            project:'5d7f6926d4135f0011188ffd',
            projectSearch:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            singleSearch:singleSearch,
            skip:pagination.current-1,
            limit:10,};
        let tableData=[];
        console.log('query');
        console.log(query);
        const {data: dayReport} = Api.postV3('/projectNew/smsHistory',query).then(res =>{
                console.log('smsHistory'+res.data);
                let total = res.data.total[0].cnt;
                let list = res.data.list;
                console.log('smsHistory ---- ');
                console.log(total);
                console.log(list);

                let i = 0;

                r.map(y => tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    project:y.project,
                    title:y.title,
                    project_name:y.project_name,
                    man:y.man,
                    phone:y.phone,
                    date:y.date,
                    sms_type:y.sms_type,
                    sms_proc:y.sms_proc,
                    sms_content:y.sms_content,
                }))(list);
                console.log('tableData-onChange');
                console.log(tableData);
                setTotal(total);
                setColumns(columns2);
                setTableData(tableData);
                // setTableData([{BuildName:'23423432'}]);
                setCurrentPage(pagination.current);

            }
        );

    }
    const projectHisStatusChange = (pagination)=>{

        //显示短信
        // console.log('22');
        // return;

        const query = {from:`${from}`,
            to:`${to}`,
            project:'5d7f6926d4135f0011188ffd',

            projectSearch:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            singleSearch:singleSearch,
            skip:pagination.current,
            limit:10,};
        let tableData=[];
        console.log('query');
        console.log(query);
        console.log(Api.regionProjects);
        const {data: dayReport} = Api.postV3('/projectNew/projectHisStatus',query).then(res =>{
                console.log('projectHisStatus'+res.data);
                let total = res.data.total[0].cnt;
                let list = res.data.list;
                console.log('projectHisStatus ---- ');
                console.log(total);
                console.log(list);

                let i = 0;

                r.map(y => tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    project:y.project,
                    project_name:y.project_name,
                    time:y.time,
                    device_normal:y.device_normal,
                    device_abnormal:y.device_abnormal,
                    collector_normal:y.collector_normal,
                    collector_abnormal:y.collector_abnormal,
                }))(list);
                console.log('projectStatusHis-onChange');
                console.log(tableData);
                setTotal(total);
                setColumns(projectHisStatusCol);
                setTableData(tableData);
                // setTableData([{BuildName:'23423432'}]);
                setCurrentPage(pagination.current);

            }
        );

    }
    const projectStatusChange = (pagination)=>{
        console.log('pagination.current-----');
        console.log(pagination.current);

        const allRegionProjectIds = Api.regionProjects?(r.filter((x)=>x !=null))(r.map(y=>{console.log(y);return y.projectId})(Api.regionProjects)):[];
        const partRegionProjectIds = allRegionProjectIds.slice((pagination.current-1)*limit,pagination.current*limit);
        const regionProjectsInfoPart =(r.filter((x)=>partRegionProjectIds.indexOf(x.projectId)!==-1))(Api.regionProjects);
        const query = {
            // from:'20200101',
            // to:'20200103',
            project:'5d7f6926d4135f0011188ffd',
            projectSearch:projectSearch?projectSearch:'5d7f6926d4135f0011188ffd',
            singleSearch:singleSearch,
            sector:'123',
            skip:pagination.current,
            limit:10,
            regionProjects: partRegionProjectIds,};
        let tableData=[];
        console.log('query');
        console.log(query);
        console.log(query.regionProjects);
        const {data: dayReport} = Api.postV3('/deviceNew/statusDetailForSms',query).then(res =>{
                console.log('statusDetail'+res.data);
                let resDevice = res.data.resDevices;
                let resCollector = res.data.resCollectors;
                let projectSmsSwitch = res.data.projectSmsSwitch;
                console.log('res device collector ---- ');
                console.log(resDevice);
                console.log(resCollector);
                console.log(projectSmsSwitch);
                console.log(Util2.regionJsonConverter);
                let i = 0;


                // let regionProjectsPart = Api.regionProjects.slice((1-1)*limit,1*limit);
                // console.log('regionProjectsPart --');
                // console.log(regionProjectsPart);
                r.map(y => (y.projectName?tableData.push({
                    order: i + 1 < 10 ? `0${(i++) + 1}` : (i++) + 1,
                    BuildName:y.projectName,
                    BuildCode:y.building,
                    region:Number(y.region),
                    regionName:regionJsonConverter[y.region],
                    sector:Number(projectTypeWeight[y.sector]),
                    sectorName:projectTypeTransfer[y.sector],
                    OnlineMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][0]:'-',
                    ErrMeterCnt:resDevice[y.projectId]?resDevice[y.projectId][1]:'-',
                    MeterTime:'',
                    OnlineCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][0]:'-',
                    ErrCollectorCnt:resCollector[y.projectId]?resCollector[y.projectId][1]:'-',
                    CollectorTime:'',
                    projectId:y.projectId,
                    smsApplySwitch:projectSmsSwitch[y.projectId]?projectSmsSwitch[y.projectId][0].smsApplySwitch:'-',
                    smsStatusSwitch:projectSmsSwitch[y.projectId]?projectSmsSwitch[y.projectId][0].smsStatusSwitch:'-',
                }):''))(regionProjectsInfoPart);
                console.log('tableData-onChange');
                console.log(tableData);
                setTotal(allRegionProjectIds?allRegionProjectIds.length:0);
                setColumns(columns1);
                setTableData(tableData);
                // setTableData([{BuildName:'23423432'}]);
            console.log('pagination.current -- ');
            console.log(pagination.current);
            pageStore=pagination.current;
                setCurrentPage(pagination.current);
                console.log('after set -- ');
                console.log(currentPage);
            }
        );

    }


    const onChange = (pagination) => {
        switch (type){
            case '1':
                projectStatusChange(pagination);
                break;
            case '2':
                smsChange(pagination);
                break;
            case '3':
                projectHisStatusChange(pagination);
                break;
            default:

        }



    }
    const onSelect = (value, key) => {
        console.log('onSelect sms ====>', key);
        console.log('onSelect sms ====>', value);
        setProjectSearch(value);
    }
    const onSelectSingle = (value, key) => {
        console.log('onSelectSingle sms ====>', key);
        console.log('onSelectSingle sms ====>', value);
        setSingleSearch(value);
    }

    const tableTop = {
        result: tableData.length,
        liData: ['区间', '选择', '搜索',],

        columns,
        tableData,

    };

    return (
        <div className='reportRightTable'>
            {/*<TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}*/}
                      {/*tableData={tableData}/>*/}
            <div>
                <Button onClick={()=>projectStatusSwitch(1,10)}> 最近项目状态（所有项目，不限定特定查询） </Button>
                <Button onClick={smsSwitch}> 短信 </Button>
                <Button onClick={projectHisStatusSwitch}> 历史项目状态 </Button>
                <RangePicker
                    defaultValue={[moment(from), moment(to)]}
                    onChange={onTimeInterval}
                    format={'YYYYMMDD'}
                    // value={() => console.log(getFieldValue('time'))}
                />
                <Select
                    showSearch
                    // style={{ width:'200px',minWidth: '200px', float: 'left', marginLeft: '20px', marginTop: '14px',background:'#6188e4',color:'#6188e4' }}
                    style={{ width:'200px',minWidth: '200px', background:'#6188e4',color:'#6188e4' }}
                    placeholder="Select a project"
                    optionFilterProp="children"
                    onSelect={onSelect}
                    defaultValue={Api.projectId}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {Api.regionProjects?r.map(r.pick(['projectId', 'projectName']))(r.flatten([Api.regionProjects])).map((k, i) => <Option value={k.projectId}   key={i}>{(i+1)+k.projectName}</Option>):<Option value={1} key={1}>--</Option>}
                    {/*{regionProjects?r.map(r.pick(['projectId', 'projectName']))(r.flatten([regionProjects])).map((k, i) => <Option value={k.projectId} key={Math.random()}>{i}</Option>):<Option value={1} key={Math.random()}>--</Option>}*/}
                </Select>
                <Select
                    showSearch
                    // style={{ width:'200px',minWidth: '200px', float: 'left', marginLeft: '20px', marginTop: '14px',background:'#6188e4',color:'#6188e4' }}
                    style={{ width:'200px',minWidth: '200px', background:'#6188e4',color:'#6188e4' }}
                    placeholder="Select a project"
                    optionFilterProp="children"
                    onSelect={onSelectSingle}
                    defaultValue={1}
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                     <Option value={0}   key={0}>启用单个查询</Option>
                     <Option value={1}   key={1}>不启用单个查询</Option>
                </Select>

                {/*TODO 后续再把根据项目的查询放上去*/}
                {/*<Input onChange={projectChange} style={{width:'300px'}}/>*/}
                {/*<Button onClick={Search}> 查询 </Button>*/}
            </div>
            <Table
                columns={columns}
                dataSource={tableData}
                scroll={{y: 710}}
                // pagination={true}
                // pagination={{defaultPageSize: 10}}
                // onRow={record => {
                //     return {
                //         onClick: event => {/*console.log(record);*/onSelect(record)
                //             }, // 点击行
                //         onDoubleClick: event => {},
                //         onContextMenu: event => {},
                //         onMouseEnter: event => {}, // 鼠标移入行
                //         onMouseLeave: event => {},
                //     };
                // }}
                // loading={loading}
                // 分页设置
                pagination={{
                    pageSize: 10,  //每页几条数据
                    current:currentPage,
                    total: Number(total),  //多少条数据，默认给你分配多少页
                }}
                rowKey={v => v.id}
                onChange={onChange}

                className='reportRightTableTab'/>

        </div>
    )
}


export default IntervalReport;