import React from 'react';
import { Table, Switch, Pagination } from 'antd';
import * as r from 'ramda';
import moment from 'moment';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

// import './dayReport.scss';
import Api from 'api';

class Electricity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            data: [{}],
            allData: null,
            isUpData: false,
            tableLoading: false,
            pagesize: 10,
            pageindex: 1,
            pageCount: 1,
            total: 0
        };
    }

    async getData(pageindex, pagesize) {
        this.setState({
            tableLoading: true,
            data: []
        })



        const electricity = await Api.post('/business/monitor', {
            devicetype: "ELECTRICITYMETER",
            project: Api.projectId,
            ext: { enableMask: 1 },
            mode: "SENSOR",
            pageindex: pageindex,
            pagesize: pagesize,
        });
        if (electricity.data.code === 90000005) {
            this.setState({
                data: [],
                total: 0,
                tableLoading: false,
                pageCount: 0

            })
            return;
        }
        let data = [];
        const orderAlgorithm = (x) => (x + 1) + ((pageindex - 1) * pagesize);
        let electricityPath = r.pathOr([], ['data', 'result', Api.projectId, 'detail'], electricity)
        electricityPath.forEach((i, k) => {
            let order = orderAlgorithm(k) < 10 ? '0' + orderAlgorithm(k) : orderAlgorithm(k);
            data.push({
                key: k,
                order: order,
                name: i.title,
                // id: i.id,
                instrumentId: i.addrid,
                // channal: i.passageName,
                reading: i.channels[11].realdata,
                state: i.channels[11].status,
                number: i.id.slice(-5, -3),
                device_id: i.id.slice(0, 12),
                multipoint: i.channels[11].comi,
                updatecyc: i.channels[11].freq,
                is_del: i.is_del,
                principal: i.principal,
                onoff: 1,
                time: moment(i.channels['11'].lastupdate).format('YYYY-MM-DD HH:mm:ss'),
                channal: i.channels[11].channel,
                allInformation: i
            })

        })

        this.setState({
            data: data,
            total: electricityPath.length,
            tableLoading: false,
            pageCount: electricity.data.result[Api.projectId].paging.count
        })
    }

    componentDidMount() {
        this.getData(this.state.pageindex, this.state.pagesize)
    }



    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }
    pageChange = (current, pageSize) => {
        console.log(pageSize)
        this.getData(current, pageSize)
    }

    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 70,
        }, {
            title: '智能仪表名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} id={this.state.data.id} record={record} />
            ),
            width: 150,
        }, {
            title: '智能仪表ID',
            dataIndex: 'instrumentId',
            key: 'instrumentId',
            width: 150,
        }, {
            title: '通道名称',
            dataIndex: 'channal',
            key: 'channal',
            width: 150,
        }, {
            title: '当前读数',
            dataIndex: 'reading',
            key: 'reading',
            width: 150,
        }, {
            title: '通讯状态',
            dataIndex: 'state',
            key: 'state',
            render: (text, record) => {
                return <span className={record.state ? 'colorY' : 'colorG'}>{record.state ? '• 异常' : '• 正常'}</span>
            },
            width: 90,
        },
        {
            title: '开关状态',
            dataIndex: 'switch',
            key: 'switch',
            render: (text, record) => (
                // onoff
                <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === 0 ? true : false} onChange={this.onChange} />
            ),
            width: 150,
        },
        {
            title: '通讯时间',
            dataIndex: 'time',
            key: 'time',
            width: 190,
        }, {
            title: '采集器编号',
            dataIndex: 'number',
            key: 'number',
            width: 150,
        },
        //  {
        //     title: '操作',
        //     dataIndex: 'operation',
        //     key: 'operation',
        //     render: (text, record) => (
        //         <div>
        //             <Management record={record} isUpData={this.upData} />
        //             <Delete record={record} />
        //         </div>
        //     ),
        //     width: 150,
        // },
        {
            title: '设备标识',
            dataIndex: 'device_id',
            key: 'device_id',
            className: 'hidden',
        }, {
            title: '倍率',
            dataIndex: 'multipoint',
            key: 'multipoint',
            className: 'hidden',
        }, {
            title: '更新频率',
            dataIndex: 'updatecyc',
            key: 'updatecyc',
            className: 'hidden',
        }, {
            title: '是否删除',
            dataIndex: 'is_del',
            key: 'is_del',
            className: 'hidden',
        }, {
            title: '是否为总表',
            dataIndex: 'principal',
            key: 'principal',
            className: 'hidden',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: this.state.total,
            liData: ['搜索']
        }
        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.state.data}
                    scroll={{ y: 580 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    rowKey='Elecricity'
                    className='reportRightTableTab' />
                <div className='reportRightTableFoot'>
                    <Pagination className='pagination' showSizeChanger={true} onChange={this.pageChange} onShowSizeChange={this.pageChange} defaultCurrent={1} total={this.state.pageCount} />
                </div>
            </div >
        )
    }
}


export default Electricity;