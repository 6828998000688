import React from 'react';

import './indexTop.scss';

import IndexTopLeft from './indexTopLeft/indexTopLeft';
import IndexTopRight from './indexTopRight/indexTopRight';

class IndexTop extends React.Component {
    render() {
        return (
            <div className='indexTop'>
                <IndexTopLeft />
                <IndexTopRight />
            </div>
        )
    }
}


export default IndexTop;