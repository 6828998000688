import React, { Component } from 'react';
import './mapPopup.scss'

class MapPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {

        };
    }


    render() {
        const { name, visible } = this.props;
        // console.log(this.props)
        return (
            <div id="mapPopup" style={{ display: true }}>
                <span className="text" >{name}</span>
                <div className="arrow"></div>
            </div>

        )
    }

};


export default MapPopup;
