import React, {useContext} from 'react';
import { Table, Switch } from 'antd';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

// import './dayReport.scss';
import Api from 'api';
import {context} from "src/modules/equipment/equipment";

class Water extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data: null,
            allData: null,
            isUpData: false,
            tableLoading: false
        };
    }

    async getData() {
        this.setState({
            tableLoading: true,
            data: []
        })

        const water = await Api.post('/sensors/getTypeSensors', {
            type: "COLDWATERMETER",
            project: Api.projectId
        });
        // console.log(water)
        let data = []
        water.data.data.forEach((i, k) => {

            data.push({
                key: k,
                order: k < 10 ? '0' + k : k,
                name: i.title,
                // id: i.id,
                instrumentId: i.addrid,
                channal: i.passageName,
                reading: i.sList[0].realdata,
                state: i.sList[0].realfreq > i.sList[0].freq,
                time: i.lastupdate,
                number: i.id.slice(-5, -3),
                device_id: i.id.slice(0, 12),
                multipoint: i.sList[0].comi,
                updatecyc: i.sList[0].freq,
                is_del: i.is_del,
                principal: i.principal,
                onoff: 1,
                allInformation: i
            })
        })

        this.setState({
            data: data,
            total: water.data.data.length,
            tableLoading: false
        })
    }

    componentDidMount() {
        this.getData()
    }



    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }


    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 150
        }, {
            title: '智能仪表名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} id={this.state.data.id} record={record} />
            ),
            width: 150
        }, {
            title: '智能仪表ID',
            dataIndex: 'instrumentId',
            key: 'instrumentId',
            width: 150
        }, {
            title: '通道名称',
            dataIndex: 'channal',
            key: 'channal',
            width: 150
        }, {
            title: '当前读数',
            dataIndex: 'reading',
            key: 'reading',
            render: (text, record) => {
                return <span className={record.state ? 'colorY' : 'colorG'}>{text}</span>
            },
            width: 150
        }, {
            title: '通讯状态',
            dataIndex: 'state',
            key: 'state',
            render: (text, record) => {
                return <span className={record.state ? 'colorY' : 'colorG'}>{record.state ? '• 异常' : '• 正常'}</span>
            },
            width: 150
        }, {
            title: '开关状态',
            dataIndex: 'switch',
            key: 'switch',
            render: (text, record) => (
                // onoff
                <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={true} disabled={true} />
            ),
            width: 150
        }, {
            title: '通讯时间',
            dataIndex: 'time',
            key: 'time',
            width: 150
        }, {
            title: '采集器编号',
            dataIndex: 'number',
            key: 'number',
            width: 150
        }, {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <div>
                    <Management record={record} isUpData={this.upData} />
                    <Delete record={record} />
                </div>
            ),
        }, {
            title: '设备标识',
            dataIndex: 'device_id',
            key: 'device_id',
            className: 'hidden',
        }, {
            title: '倍率',
            dataIndex: 'multipoint',
            key: 'multipoint',
            className: 'hidden',
        }, {
            title: '更新频率',
            dataIndex: 'updatecyc',
            key: 'updatecyc',
            className: 'hidden',
        }, {
            title: '是否删除',
            dataIndex: 'is_del',
            key: 'is_del',
            className: 'hidden',
        }, {
            title: '是否为总表',
            dataIndex: 'principal',
            key: 'principal',
            className: 'hidden',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: this.state.total,
            liData: ['搜索']
        }

        const { state }  = useContext(context)
        console.log('water tree', state);

        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    // dataSource={data}
                    dataSource={this.state.data}
                    scroll={{ y: 580 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    className='reportRightTableTab' />
                <TableBottom number='10' />
            </div >
        )
    }
}


export default Water;