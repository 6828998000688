import React from 'react';
import { Table, Switch } from 'antd';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

import gear1 from 'img/gear1.png';
import gear2 from 'img/gear2.png';
import gear3 from 'img/gear3.png';
import gearL from 'img/gear1.gif';
import gearM from 'img/gear2.gif';
import gearH from 'img/gear3.gif';
// import './dayReport.scss';
import Api from 'api';

class Time extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data: [{}],
            allData: null,
            isUpData: false,
            tableLoading: false
        };
    }

    async getData() {
        this.setState({
            tableLoading: true,
            data: []
        })

        const time = await Api.post('/user/TimeEquipment', {

        });
        // cmnsta: 0
        // coolnumber: "3.88"
        // gear: 3
        // hotnumber: "0"
        // instrumentId: 823625412
        // messageTime: "2019-02-20 14:53:05"
        // mode: 0
        // name: "0401房间"
        // tId: 1
        // valve: 0
        let data = []
        if (time.data.data) {
            time.data.data.forEach((i, k) => {
                data.push({
                    key: i.id,
                    order: k,
                    name: i.name,
                    id: i.instrumentId,
                    coolnumber: i.coolnumber,
                    hotnumber: i.hotnumber,
                    state: i.messageType === "1" ? '●正常' : '●异常',
                    time: i.messageTime,
                    allInformation: i,
                    //     title: '智能仪表名称',
                    // dataIndex: 'name',
                    key: 'name',
                    // title: '智能仪表ID',
                    // dataIndex: 'instrumentId',
                    key: 'instrumentId',
                    // title: '冷量系数',
                    // dataIndex: 'channal',
                    key: 'channal',
                    // title: '热量系数',
                    // dataIndex: 'reading',
                    key: 'reading',
                    // title: '通讯状态',
                    // dataIndex: 'state',
                    key: 'state',
                    // title: '开关状态',
                    // dataIndex: 'switch',
                    key: 'switch',
                    // title: '开关状态',
                    // dataIndex: 'switch',
                    key: 'switch',
                    // title: '档位',
                    // title: '通讯时间',
                    // dataIndex: 'time',
                    key: 'time',
                    // title: '操作',
                    // dataIndex: 'operation',
                    key: 'operation',


                    // instrumentId: i.instrumentId,
                    // channal: i.passageName,
                    // reading: i.nowNumber,
                    // number: i.collectorNumber,
                    // device_id: i.device_id,
                    // multipoint: i.multipoint,
                    // updatecyc: i.updatecyc,
                    // is_del: i.is_del,
                    // principal: i.principal,
                    // onoff: i.onoff
                })
            })
        }


        this.setState({
            data: data,
            total: time.data.data ? time.data.data.length : 0,
            tableLoading: false
        })
        // // 采集器编号
        // collectorNumber: 1
        // // 标识 id 
        // device_id: "188789"
        // //  设备 id
        // equimpentId: 166875
        // // 序号
        // id: 10001
        // // 仪表 id
        // instrumentId: 12344587
        // // 是否显示
        // is_del: 1
        // // 通讯状态 0正常 1异常
        // messageType: 0
        // // 通讯时间
        // messagetime: "2019-01-24"
        // // 倍率
        // multipoint: 1.23
        // //  
        // name: "新水表01"
        // // 
        // newState: { id: 1, tittle: "在线设备" }
        // // 
        // newUsetype: { usetypeId: 101, usertypeName: "水" }
        // // 当前读数
        // nowNumber: "0000.00"
        // //开关 0开 1关
        // onoff: 1
        // // 通道
        // passageName: "正向有功"
        // // 是否为主表 是0 否1
        // principal: 1
        // // 更新频率
        // updatecyc: "11"
    }

    componentDidMount() {
        this.getData()
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }

    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 150,
        }, {
            title: '智能仪表名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} id={this.state.data.id} record={record} />
            ),
            width: 150,
        }, {
            title: '智能仪表ID',
            dataIndex: 'instrumentId',
            key: 'instrumentId',
            width: 150,
        }, {
            title: '冷量系数',
            dataIndex: 'cold',
            key: 'cold',
            width: 150,
        }, {
            title: '热量系数',
            dataIndex: 'hot',
            key: 'hot',
            width: 150,
        }, {
            title: '通讯状态',
            dataIndex: 'state',
            key: 'state',
            width: 150,
        }, {
            title: '阀门开关',
            dataIndex: 'switch',
            key: 'switch',
            render: (text, record) => (
                // onoff
                <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === '开' ? true : false} onChange={this.onChange} />
            ),
            width: 150,
        }, {
            title: '模式',
            dataIndex: ' model',
            key: ' model',
            render: (text, record) => (
                <Switch checkedChildren='热' unCheckedChildren='冷' defaultChecked={record.onoff === '热' ? true : false} onChange={this.onChange} />
            ),
            width: 150,
        }, {
            title: '档位',
            dataIndex: 'gear',
            key: 'gear',
            render: (text, record) => {
                let gear = ''
                switch (text) {
                    case '高':
                        gear = <ul className='gear'><li><img src={gear1} alt="" /></li><li><img src={gear1} alt="" /></li><li><img src={gearH} alt="" /></li></ul>;
                        break;
                    case '中':
                        gear = <ul className='gear'><li><img src={gear1} alt="" /></li><li><img src={gearM} alt="" /></li><li><img src={gear1} alt="" /></li></ul>;
                        break;
                    case '低':
                        gear = <ul className='gear'><li><img src={gearL} alt="" /></li><li><img src={gear1} alt="" /></li><li><img src={gear1} alt="" /></li></ul>;
                        break;
                    default:
                        gear = <ul className='gear'><li><img src={gearL} alt="" /></li><li><img src={gear1} alt="" /></li><li><img src={gear1} alt="" /></li></ul>;
                        break;
                }
                return gear
            },
            width: 150,
        }, {
            title: '通讯时间',
            dataIndex: 'time',
            key: 'time',
            width: 150,
        }, {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <div style={{ color: '#3E8FF8' }}>
                    发送
                    {/* <Management record={record} isUpData={this.upData} />
                    <Delete record={record} /> */}
                </div>
            ),
            width: 150,
        }];
        const testData = [{
            order: '01',
            name: '0401房间',
            instrumentId: '82363848721312',
            cold: '3.88',
            hot: '0',
            state: '正常',
            switch: '开',
            model: '冷',
            gear: '中',
            time: '2018-08-18 12:00:00',
        }, {
            order: '01',
            name: '0401房间',
            instrumentId: '82363848721312',
            cold: '3.88',
            hot: '0',
            state: '正常',
            switch: '开',
            model: '冷',
            gear: '低',
            time: '2018-08-18 12:00:00',
        }]
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: this.state.total,
            liData: ['搜索']
        }
        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    // dataSource={testData}
                    dataSource={this.state.data}
                    scroll={{ y: 580 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    rowKey='time'
                    className='reportRightTableTab' />
                <TableBottom number='10' />
            </div >
        )
    }
}


export default Time;