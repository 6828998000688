import React from 'react';
import { Pagination, Button } from 'antd';
import * as r from 'ramda';
import { Spin } from 'antd';
import './electrical.scss';
import electricalJson from './electrical.json'
import Api from 'src/api';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/from';
import 'rxjs/add/observable/combineLatest';

import Img_electrical_1_1 from 'img/electrical/1_1.png';
import Img_electrical_1_2 from 'img/electrical/1_2.png';
import Img_electrical_2_1 from 'img/electrical/2_1.png';
import Img_electrical_2_2 from 'img/electrical/2_2.png';
import Img_electrical_3_1 from 'img/electrical/3_1.png';
import Img_electrical_3_2 from 'img/electrical/3_2.png';
import Img_electrical_4_1 from 'img/electrical/4_1.png';
import Img_electrical_4_2 from 'img/electrical/4_2.png';
import Img_electrical_5_1 from 'img/electrical/5_1.png';
import Img_electrical_5_2 from 'img/electrical/5_2.png';
import Img_electrical_6_1 from 'img/electrical/6_1.png';
import Img_electrical_6_2 from 'img/electrical/6_2.png';
import Img_electrical_7_1 from 'img/electrical/7_1.png';
import Img_electrical_7_2 from 'img/electrical/7_2.png';
import Img_electrical_8_1 from 'img/electrical/8_1.png';
import Img_electrical_8_2 from 'img/electrical/8_2.png';
import Img_electrical_9_1 from 'img/electrical/9_1.png';
import Img_electrical_9_2 from 'img/electrical/9_2.png';
import Img_electrical_10_1 from 'img/electrical/10_1.png';
import Img_electrical_10_2 from 'img/electrical/10_2.png';
import Img_electrical_11_1 from 'img/electrical/11_1.png';
import Img_electrical_11_2 from 'img/electrical/11_2.png';
import Img_electrical_12_1 from 'img/electrical/12_1.png';
import Img_electrical_12_2 from 'img/electrical/12_2.png';
import Img_electrical_13_1 from 'img/electrical/13_1.png';
import Img_electrical_13_2 from 'img/electrical/13_2.png';
import Img_electrical_err from 'img/electrical/err.png';
import Img_electrical_err2 from 'img/electrical/err2.png';
import Img_electrical_path from 'img/electrical/path.png';
import Img_electrical_suc from 'img/electrical/suc.png';
import Img_electrical_wsd from 'img/electrical/wsd.png';


const ElectricalImgArr = {
  Img_electrical_1_1,
  Img_electrical_1_2,
  Img_electrical_2_1,
  Img_electrical_2_2,
  Img_electrical_3_1,
  Img_electrical_3_2,
  Img_electrical_4_1,
  Img_electrical_4_2,
  Img_electrical_5_1,
  Img_electrical_5_2,
  Img_electrical_6_1,
  Img_electrical_6_2,
  Img_electrical_7_1,
  Img_electrical_7_2,
  Img_electrical_8_1,
  Img_electrical_8_2,
  Img_electrical_9_1,
  Img_electrical_9_2,
  Img_electrical_10_1,
  Img_electrical_10_2,
  Img_electrical_11_1,
  Img_electrical_11_2,
  Img_electrical_12_1,
  Img_electrical_12_2,
  Img_electrical_13_1,
  Img_electrical_13_2,
}


const ImgData = (deviceState) => {
  const { i: { top, left, title, status, K = 0, A1 = 0, A2 = 0, A3 = 0, V1 = 0, V2 = 0, V3 = 0 } } = deviceState;
  const isShow = (dom, v) => {
    return v === 0 ? dom : null
  }
  return (
    <div className="dataContainer" key={top + left} style={{ top, left }}>

      {title === '备用' ?
        <ul>
          <li>
            <span> {title} </span>
          </li>
        </ul>
        :
        <ul>
          <li>
            <span> {title} </span>
          </li>
          <li>
            <span> 正向有功： </span>
            <span> {K} A </span>
          </li>
          <li>
            <span> A项电压： </span>
            <span> {A1} A </span>
          </li>
          <li>
            <span> B项电压： </span>
            <span> {A2} A </span>
          </li>
          <li>
            <span> C项电压： </span>
            <span> {A3} A </span>
          </li>
          <li>
            <span> A项电流： </span>
            <span> {V1} V </span>
          </li>
          <li>
            <span> B项电流： </span>
            <span> {V2} V </span>
          </li>
          <li>
            <span> C项电流： </span>
            <span> {V3} V </span>
          </li>
        </ul>
      }

      <img src={status === 0 ? Img_electrical_suc : (status == 1 ? Img_electrical_err : Img_electrical_err2)} alt="" className='electricalStateImg' />
      <img src={Img_electrical_path} alt="" className='electricalPathImg' />
    </div >
  )
}




class Electrical extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      imgUrl: ElectricalImgArr.Img_electrical_1_1,
      deviceData: [],
      page: 1,
      pageTitle: 1,
    };
  }

  onChangePage = (page, pageSize) => {
    this.setState({
      imgUrl: ElectricalImgArr['Img_electrical_' + page + '_1'],
      page,
      pageTitle:1
    }, this.getData)

  }
  onChangeTit = (t) => {
    this.setState({
      imgUrl: ElectricalImgArr['Img_electrical_' + this.state.page + '_' + t],
      pageTitle: t
    }, this.getData)

  }
  async getData() {
    let deviceData = [];
    this.setState({
      deviceData: []
    })


    for (let i = 0; i < electricalJson['event' + this.state.page]['page_' + this.state.pageTitle].length; i++) {
      const { id, position: { left, top } } = electricalJson['event' + this.state.page]['page_' + this.state.pageTitle][i];
      if (id) {
        await Api.post('/business/monitor', {
          devicetype: "MELECTRICITYMETER",
          project: Api.projectId,
          key: id,
          ext: {
            enableMask: 1
          },
          mode: "SENSOR"
        }).then(
          (apiData) => {
            const dataPath = r.pathOr('')(['data', 'result', Api.projectId, 'detail', '0'])(apiData)
            deviceData.push({
              title: dataPath.title,
              left,
              top,
              status: r.pathOr(1)(['channels', '11', 'status'])(dataPath),
              k: r.pathOr('0.00')(['channels', '11', 'lasttotal'])(dataPath),
              A1: r.pathOr('0.00')(['channels', '15', 'lasttotal'])(dataPath),
              A2: r.pathOr('0.00')(['channels', '16', 'lasttotal'])(dataPath),
              A3: r.pathOr('0.00')(['channels', '17', 'lasttotal'])(dataPath),
              V1: r.pathOr('0.00')(['channels', '18', 'lasttotal'])(dataPath),
              V2: r.pathOr('0.00')(['channels', '19', 'lasttotal'])(dataPath),
              V3: r.pathOr('0.00')(['channels', '20', 'lasttotal'])(dataPath)
            })
          }
        )
      } else {
        deviceData.push({ title: '备用', left, top, status: 2 })
      }
    }
    this.setState({
      deviceData
    })
  }

  componentDidMount() {
    this.getData()
  }



  render() {
    const { imgUrl, deviceData,pageTitle } = this.state;
    return (
      <div className="electrical">
        <div className="electricalPage">
          <Button className={pageTitle===1?'active':null} onClick={() => { this.onChangeTit(1) }}>1</Button>
          <Button className={pageTitle===2?'active':null} onClick={() => { this.onChangeTit(2) }}>2</Button>
        </div>

        <div className='electricalImgContainer'>
          <div className="electricalImgCenter">
            <img src={imgUrl} alt="" className='electricalImg' />
            {
              deviceData.length ? deviceData.map((k,i) =>
                <ImgData i={k} key={i}/>
              ) : <Spin className='spin' size="large" />
            }
          </div>
        </div>
        <Pagination defaultCurrent={1} total={13} pageSize={1} onChange={this.onChangePage} />
      </div>
    )
  }
}

export default Electrical;