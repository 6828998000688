import React from 'react';

import moment from 'moment'
import { from } from 'rxjs'
import WaterBalance from './waterBalance';

import withStates from 'src/context/withStates'
import { typesInCategory } from 'src/context/typeCategory'
import Api from 'api'
import * as r from 'ramda'

const WaterBalanceData = () => (
    <div className='WaterBalance'>
        <WaterBalance />
    </div>
)
const a = (data) => {
    let valArr = [];
    let valArrTime = [];
    r.map((item) => {
        valArr.push(parseFloat(r.propOr(0)('value')(item)))
        valArrTime.push(`0${r.propOr(NaN)('hour')(item)}`.slice(-2) + ':00')
    })(r.isEmpty(data) ? [] : data)

    return { valArr, valTotal: r.sum(valArr).toFixed(2), valArrTime }
}
const treeViewData = async ({
    waterSTimeType = 'daily',
    levelOneTimeType = 'daily',
    levelTowTimeType = 'daily',
}) => {
    const { data: { current: { ULTRACOLDWATERMETER: waterS = [] } } } = await Api.get(`/api/v3/project/${Api.projectId}/nodeUsage/84006/${waterSTimeType}`)
    const { data: { current: { ULTRACOLDWATERMETER: levelOne = [] } } } = await Api.get(`/api/v3/project/${Api.projectId}/nodeUsage/84007/${levelOneTimeType}`)
    const { data: { current: { ULTRACOLDWATERMETER: levelTow = [] } } } = await Api.get(`/api/v3/project/${Api.projectId}/nodeUsage/84008/${levelTowTimeType}`)
    return {
        loading: true,
        waterS: a(waterS).valArr,
        waterSTime: a(waterS).valArrTime,
        levelOne: a(levelOne).valArr,
        levelOneTime: a(levelOne).valArrTime,
        levelTow: a(levelTow).valArr,
        levelTowTime: a(levelTow).valArrTime,
        waterSTimeType: 'daily',
        levelOneTimeType: 'daily',
        levelTowTimeType: 'daily',
    }
}


const dateFormatOf = period => r.propOr('YYYYMMDD')(period)({
    daily: 'YYYYMMDD',
    mongthly: 'YYYYMM',
    annually: 'YYYY',
})
const extraValue = [
    { id: "7096743", name: "学生公寓水箱西" },
    { id: "7096807", name: "学生公寓水箱东" },
    { id: "68120454", name: "屋顶消防水箱DN50" },
    { id: "68120452", name: "水泵房消防水箱DN150" },
    { id: "68120444", name: "水泵房生活总水表DN200" }
]
const nodeUsage = async ({
    waterSTimeType = 'daily',
    levelOneTimeType = 'daily',
    levelTowTimeType = 'daily', }) => {
    const { data: { current: { ULTRACOLDWATERMETER: waterS = [] } } } = await Api.get(`/api/v3/project/${Api.projectId}/nodeUsage/84006/${waterSTimeType}`)
    const { data: { current: { ULTRACOLDWATERMETER: levelOne = [] } } } = await Api.get(`/api/v3/project/${Api.projectId}/nodeUsage/84007/${levelOneTimeType}`)
    const { data: { current: { ULTRACOLDWATERMETER: levelTow = [] } } } = await Api.get(`/api/v3/project/${Api.projectId}/nodeUsage/84008/${levelTowTimeType}`)
    const getExtraValue = await Api.post('/business/monitor', {
        devicetype: "ULTRACOLDWATERMETER",
        project: Api.projectId,
        ext: {
            enableMask: 1
        },
        mode: "SENSOR",
        pagesize: 1000
    })
    const path = r.pathOr([], ['data', 'result', Api.projectId, 'detail'], getExtraValue);
    console.log(
    )
    return {
        loading: true,
        waterS: a(waterS).valArr,
        waterSTime: translateTimeline(waterSTimeType),
        levelOne: a(levelOne).valArr,
        levelOneTime: translateTimeline(levelOneTimeType),
        levelTow: a(levelTow).valArr,
        levelTowTime: translateTimeline(levelTowTimeType),
        waterSTimeType,
        levelOneTimeType,
        levelTowTimeType,
        extraData: r.map(
            v =>
                (r.pickAll(['channels', 'title', 'addrid', 'isExtra', 'name'])
                    (r.assoc("name", v.name)(r.assoc('isExtra', true)
                        (r.find(x => x.id.indexOf(v.id) != -1)(path)

                        )))
                )
        )(extraValue)
    }
}

const translateTimeline = v => {
    const valueMap = {
        daily: r.map(i => `0${i}`.slice(-2) + ':00')(r.range(0, moment().hour() + 1)),
        monthly: r.map(i => `0${i}`.slice(-2) + '日')(
            r.range(1, moment().date() + 1)),
        annually: r.map(i => `0${i}`.slice(-2) + '月')(r.range(1, moment().months() + 1)),
    }
    return r.propOr(v)(v)(valueMap)
}

const loadAnalysis = (state) => {
    const input = Promise.all(
        [treeViewData(state), nodeUsage(state)])
        .then(r.mergeAll)
    return from(input)
}

export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({ ...state, ...res })))
}
const initState = {
    loading: true,
    waterS: [],
    waterSTime: [],
    levelOne: [],
    levelOneTime: [],
    levelTow: [],
    levelTowTime: [],
    waterSTimeType: 'daily',
    levelOneTimeType: 'daily',
    levelTowTimeType: 'daily',
    extraData: []
}

export const { context, Component } = withStates(
    WaterBalanceData,
    initState,
    loadAnalysis,
)

export default Component