import React, { Component } from 'react';
import * as r from 'ramda';
import { Row, Col } from 'antd';

import './indexBottom2.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts-liquidfill'
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';



const assistdata = [];
const center = ['50%', '50%'],
  radius = ['40%', '65%'],
  selectedOffset = 10,
  gridNumber = 16;
//
// 				4a0fc8
//
// 				03b9fa
//
// 				2962e7
const waterColor = '#b0f0fe',
  eleColor = '#ffa348',
  sumColor = '#7accfc',
eleLiquidColorInside=['#dc49fe',
    '#d187e7',
    '#f3b8ff'],
eleBorderColor = '#ad67e7',
    eleWordColor = '#ffffff',
    eleInsideWordColor = '#ffffff',
waterLiquidColorInside=['#2962e7',
    '#12b9fa',
    '#5a8ffa'],
waterBorderColor = '#12b9fa',
    waterWordColor='#ffffff',
    waterInsideWordColor='#ffffff',
sumLiquidColorInside=['#7273fa',
    '#474ec8',
    '#2962e7'],
sumBorderColor = '#2962e7',
// sumWorDColor = '#0c56af',//
// sumInsideWorDColor = '#0c56af';//
sumWorDColor = '#ffffff',//
sumInsideWorDColor = '#ffffff';//

const charetsContext = ({ sum, showNub, color }) => {
  const iAdd = sum / gridNumber;
  let assistdata2 = [];
  for (let i = 0; i < showNub - iAdd; i += iAdd) {
    assistdata2.push({
      name: "1",
      value: iAdd,
      selected: true,
      itemStyle: {
        show: false,
        normal: {
          color: color
        }
      }
    });
  }
  if (showNub % iAdd !== 0) {
    assistdata2.push({
      name: "1",
      value: showNub % iAdd,
      selected: true,
      itemStyle: {
        show: false,
        normal: {
          color: color
        }
      },
    });
  }
  assistdata2.push({
    name: "2",
    value: sum - showNub,
    selected: true,
    itemStyle: { color: 'transparent' }
  });
  return assistdata2
}

class IndexBottom2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      areaDosage: 0,
      perCapitaDosage: 0,
      areaElectricDosage: 0,
      perCapitaElectricDosage: 0,
      areaWaterDosage: 0,
      perCapitaWaterDosage: 0,
      sum: 0,
        water: 0,
        ele: 0,
      active: "day",
        people:1,
        area:1,
    }
    this.reactEcharts = null;
  }


  componentDidMount() {
    const { areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum, water, ele } = this.state;



    this.reactEcharts = echarts.init(document.getElementById('ReactEchartsBottom2'));
    let a = 0;
    let isTrue = true;



    //改成水球形式
      //创建百分比数值
      const elePer = ele/(sum>0?sum:1);
      const waterPer = water/(sum>0?sum:1);
      const totalPer = sum>0?1:0;

      this.reactEcharts.setOption({
          series: [
              {
                  type: 'liquidFill',
                  //data: [0.6, 0.5, 0.4, 0.3],
                  // data: [0.6, 0.5, 0.4, 0.3],
                  data: [totalPer, 0.5*totalPer, 0.4*totalPer, 0.3*totalPer],
                  radius: '55%',
                  // 水球颜色
                  // color: ['#49d088', '#38b470', '#2aaf66'],
                  color: sumLiquidColorInside,
                  center: ['15%', '50%'],
                  // outline  外边
                  outline: {
                      // show: false
                      borderDistance: 5,
                      itemStyle: {
                          borderWidth: 5,
                          // borderColor: '#13FDCE',
                          borderColor: sumBorderColor,
                      },
                  },
                  label: {
                      normal: {
                          formatter: '总能耗\r\n\r\n'+sum?sum.toFixed(2):0,
                          // textStyle: {
                          // color: '#83c68b',
                          // insideColor: '#c68874',
                          color: sumWorDColor,
                          insideColor:sumInsideWorDColor,
                          fontSize: 17
                          // }
                      }
                  },
                  // 内图 背景色 边
                  backgroundStyle: {
                      color: 'rgba(4,24,74,0.8)',
                      // borderWidth: 5,
                      // borderColor: 'red',
                  }
              },{
                  type: 'liquidFill',
                  //data: [0.6, 0.5, 0.4, 0.3],
                  data: [waterPer, 0.2*waterPer, 0.1*waterPer, 0.05*waterPer],
                  radius: '55%',
                  // 水球颜色
                  // color: ['#FE5555', '#F07581', '#FB5E61'],
                  color: waterLiquidColorInside,
                  center: ['49%', '50%'],
                  // outline  外边
                  outline: {
                      // show: false
                      borderDistance: 5,
                      itemStyle: {
                          borderWidth: 5,
                          // borderColor: '#9997fe',
                          borderColor: waterBorderColor,
                      },
                  },
                  label: {
                      normal: {
                          formatter: '水\r\n\r\n'+(water?water.toFixed(2):0),
                          textStyle: {
                              // color: '#6d73c6',
                              // insideColor: 'yellow',
                              color: waterWordColor,
                              insideColor: waterInsideWordColor,
                              fontSize: 17
                          },
                      },
                  },
                  // 内图 背景色 边
                  backgroundStyle: {
                      color: 'rgba(4,24,74,0.8)',
                      // borderWidth: 5,
                      // borderColor: 'red',
                  }
              },{
                  type: 'liquidFill',
                  //data: [0.6, 0.5, 0.4, 0.3],
                  data: [elePer, 0.5*elePer,0.25*elePer],
                  radius: '55%',
                  amplitude: '15%',
        waveLength:'20%',
                  // 水球颜色
                  // color: ['#FFBF11', '#F4B30E', '#EACE36'],
                  color: eleLiquidColorInside,
                  center: ['82%', '50%'],
                  // outline  外边
                  outline: {
                      // show: false
                      borderDistance: 5,
                      itemStyle: {
                          borderWidth: 5,
                          // borderColor: '#FFBF11',
                          borderColor: eleBorderColor,
                      },
                  },
                  label: {
                      normal: {
                          formatter: '电\r\n\r\n'+(ele?ele.toFixed(2):0),
                          textStyle: {
                              // color: '#fdec5b',
                              // color: '#fdbd61',
                              // insideColor: 'yellow',
                              color: eleWordColor,
                              insideColor: eleInsideWordColor,
                              // color: '#e7e74b',
                              // insideColor: '#fd5d35',
                              fontSize: 17
                          }
                      }
                  },
                  // 内图 背景色 边
                  backgroundStyle: {
                      color: 'rgba(4,24,74,0.8)',
                      // borderWidth: 5,
                      // borderColor: 'red',
                  }
              }
          ]
      })



    // setInterval(() => {
    //
    //   if (isTrue) {
    //     a += 0.05;
    //     if (a >= 0.9) {
    //       isTrue = false;
    //     }
    //   }
    //   if (!isTrue) {
    //     a -= 0.05;
    //     if (a <= 0) {
    //       isTrue = true;
    //     }
    //   }
    //
    //   // this.ReactEcharts.setOption(
    //
    //   // )
    //
    // }, 100)
      this.getData('/energyRegionNew/usageRatioDaily', 'day')


  }

  async componentWillReceiveProps(nextProps) {
    const { electricity, total, water } = nextProps;
    const sum = total;
    const ele = electricity?electricity:0;
    //创建百分比数值
      const elePer = electricity?(electricity/(total>0?total:1)):0;
      const waterPer = water?(water/(total>0?total:1)):0;
      const totalPer = total>0?1:0;
      console.log('head area ---');
    const { headCount: people, totalArea: area } = await Api.postV3('/info/statistics', {
      project: Api.projectId
    }).then(r.prop('data'))


    const areaDosage = sum && area ? (sum / area).toFixed(2) : 0;
    const perCapitaDosage = sum && people ? (sum / people).toFixed(2) : 0;

    const areaElectricDosage = ele && area ? (ele / area).toFixed(2) : 0;
    const perCapitaElectricDosage = ele && people ? (ele / people).toFixed(2) : 0;

    const areaWaterDosage = water && area ? (water / area).toFixed(2) : 0;
    const perCapitaWaterDosage = water && people ? (water / people).toFixed(2) : 0;

      this.reactEcharts.setOption({
          series: [
              {
                  type: 'liquidFill',
                  //data: [0.6, 0.5, 0.4, 0.3],
                  // data: [0.6, 0.5, 0.4, 0.3],
                  data: [totalPer, 0.5*totalPer, 0.4*totalPer, 0.3*totalPer],
                  radius: '55%',
                  // 水球颜色
                  // color: ['#49d088', '#38b470', '#2aaf66'],
                  color: sumLiquidColorInside,
                  center: ['15%', '50%'],
                  // outline  外边
                  outline: {
                      // show: false
                      borderDistance: 5,
                      itemStyle: {
                          borderWidth: 5,
                          // borderColor: '#13FDCE',
                          borderColor: sumBorderColor,
                      },
                  },
                  label: {
                      normal: {
                          formatter: '总能耗\r\n\r\n'+total,
                          // textStyle: {
                          color: sumWorDColor,
                          insideColor: sumInsideWorDColor,
                          fontSize: 17
                          // }
                      }
                  },
                  // 内图 背景色 边
                  backgroundStyle: {
                      color: 'rgba(4,24,74,0.8)',
                      // borderWidth: 5,
                      // borderColor: 'red',
                  }
              },{
                  type: 'liquidFill',
                  //data: [0.6, 0.5, 0.4, 0.3],
                  data: [waterPer, 0.2*waterPer, 0.1*waterPer, 0.05*waterPer],
                  radius: '55%',
                  // 水球颜色
                  // color: ['#FE5555', '#F07581', '#FB5E61'],
                  color: waterLiquidColorInside,
                  center: ['49%', '50%'],
                  // outline  外边
                  outline: {
                      // show: false
                      borderDistance: 5,
                      itemStyle: {
                          borderWidth: 5,
                          // borderColor: '#9997fe',
                          borderColor: waterBorderColor,
                      },
                  },
                  label: {
                      normal: {
                          formatter: '水\r\n\r\n'+water?water:0,
                          textStyle: {
                              color: waterWordColor,
                              insideColor: waterInsideWordColor,
                              fontSize: 17
                          },
                      },
                  },
                  // 内图 背景色 边
                  backgroundStyle: {
                      color: 'rgba(4,24,74,0.8)',
                      // borderWidth: 5,
                      // borderColor: 'red',
                  }
              },{
                  type: 'liquidFill',
                  //data: [0.6, 0.5, 0.4, 0.3],
                  data: [elePer, 0.5*elePer,0.25*elePer],
                  radius: '55%',
                  amplitude: '15%',
        waveLength:'20%',
                  // 水球颜色
                  // color: ['#FFBF11', '#F4B30E', '#EACE36'],
                  // color: ['#919eff', '#6d85f4', '#234cea'],
                  color: eleLiquidColorInside,
                  center: ['82%', '50%'],
                  // outline  外边
                  outline: {
                      // show: false
                      borderDistance: 5,
                      itemStyle: {
                          borderWidth: 5,
                          // borderColor: '#FFBF11',
                          borderColor: eleBorderColor,
                      },
                  },
                  label: {
                      normal: {
                          formatter: '电\r\n\r\n'+(electricity?electricity:0),
                          textStyle: {
                              color: eleWordColor,
                              insideColor: eleInsideWordColor,
                              // color: '#e7e74b',
                              // insideColor: '#fd5d35',
                              fontSize: 17
                          }
                      }
                  },
                  // 内图 背景色 边
                  backgroundStyle: {
                      color: 'rgba(4,24,74,0.8)',
                      // borderWidth: 5,
                      // borderColor: 'red',
                  }
              }
          ]
      })
    // this.reactEcharts.setOption({
    //   series: [
    //     {
    //       type: 'pie',
    //       center: ['30%', '50%'],
    //       radius: ['30%', '40%'],
    //       clockwise: false,
    //       hoverAnimation: false,
    //       silent: true,
    //       stillShowZeroSum: false,
    //       minAngle: 10,
    //       label: {
    //         normal: {
    //           show: false
    //         }
    //       },
    //       labelLine: {
    //         normal: {
    //           show: false
    //         }
    //       },
    //       data: charetsContext({ sum, showNub: water * 0.8, color: waterColor })
    //     },
    //     {
    //       type: 'pie',
    //       center: ['30%', '50%'],
    //       radius: ['45%', '55%'],//环的位置
    //       clockwise: false,
    //       hoverAnimation: false,
    //       silent: true,
    //       stillShowZeroSum: false,
    //       minAngle: 10,
    //       label: {
    //         normal: {
    //           show: false,
    //         }
    //       },
    //       labelLine: {
    //         normal: {
    //           show: false
    //         }
    //       },
    //       data: charetsContext({ sum, showNub: ele * 0.8, color: eleColor })
    //     },
    //     {
    //       type: 'pie',
    //       center: ['30%', '50%'],
    //       radius: ['60%', '70%'],//环的位置
    //       clockwise: false,
    //       hoverAnimation: false,
    //       silent: true,
    //       stillShowZeroSum: false,
    //       minAngle: 10,
    //       label: {
    //         normal: {
    //           show: false
    //         }
    //       },
    //       labelLine: {
    //         normal: {
    //           show: false
    //         }
    //       },
    //       itemStyle: {
    //         shadowBlur: 10,
    //       },
    //       data: charetsContext({ sum, showNub: sum * 0.85, color: sumColor })
    //     },
    //   ]
    // })
    this.setState({
      areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum,
      water,
      ele,
      people,
      area,
    })
  }

    async getData(api, active) {
        console.log('begin to usageratiodaily ---- ');
        // this.state.waterEcharts.series = [];
        // this.state.eleEcharts.series = [];
        // this.state.acEcharts.series = [];
        // this.state.gasEcharts.series = [];


        const data = await Api.postV3(api, {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });

        const sum = data.data.total || 0;
        const water = data.data.water || 0;
        const ele = data.data.electricity || 0;
        // const ac = data.data.ac || 0;
        // const gas = data.data.gas || 0;
        // const colorSet = {
        //     color: '#468EFD'
        // };

        const elePer = ele/(sum>0?sum:1);
        const waterPer = water/(sum>0?sum:1);
        const totalPer = sum>0?1:0;


        // this.setState({
        //     sum,
        //     water,
        //     electricity,
        //     ac,
        //     gas,
        //     active
        // })


        this.reactEcharts.setOption({
            series: [
                {
                    type: 'liquidFill',
                    //data: [0.6, 0.5, 0.4, 0.3],
                    // data: [0.6, 0.5, 0.4, 0.3],
                    data: [totalPer, 0.5*totalPer, 0.4*totalPer, 0.3*totalPer],
                    radius: '55%',
                    // 水球颜色
                    // color: ['#49d088', '#38b470', '#2aaf66'],
                    color: sumLiquidColorInside,
                    center: ['15%', '50%'],
                    // outline  外边
                    outline: {
                        // show: false
                        borderDistance: 5,
                        itemStyle: {
                            borderWidth: 5,
                            // borderColor: '#13FDCE',
                            borderColor: sumBorderColor,
                        },
                    },
                    label: {
                        normal: {
                            formatter: '总能耗\r\n\r\n'+(sum?sum.toFixed(2):0),
                            // textStyle: {
                            // color: '#83c68b',
                            // insideColor: '#c68874',
                            color: sumWorDColor,
                            insideColor:sumInsideWorDColor,
                            fontSize: 17
                            // }
                        }
                    },
                    // 内图 背景色 边
                    backgroundStyle: {
                        color: 'rgba(4,24,74,0.8)',
                        // borderWidth: 5,
                        // borderColor: 'red',
                    }
                },{
                    type: 'liquidFill',
                    //data: [0.6, 0.5, 0.4, 0.3],
                    data: [waterPer, 0.2*waterPer, 0.1*waterPer, 0.05*waterPer],
                    radius: '55%',
                    // 水球颜色
                    // color: ['#FE5555', '#F07581', '#FB5E61'],
                    color: waterLiquidColorInside,
                    center: ['49%', '50%'],
                    // outline  外边
                    outline: {
                        // show: false
                        borderDistance: 5,
                        itemStyle: {
                            borderWidth: 5,
                            // borderColor: '#9997fe',
                            borderColor: waterBorderColor,
                        },
                    },
                    label: {
                        normal: {
                            formatter: '水\r\n\r\n'+(water?water.toFixed(2):0),
                            textStyle: {
                                // color: '#6d73c6',
                                // insideColor: 'yellow',
                                color: waterWordColor,
                                insideColor: waterInsideWordColor,
                                fontSize: 17
                            },
                        },
                    },
                    // 内图 背景色 边
                    backgroundStyle: {
                        color: 'rgba(4,24,74,0.8)',
                        // borderWidth: 5,
                        // borderColor: 'red',
                    }
                },{
                    type: 'liquidFill',
                    //data: [0.6, 0.5, 0.4, 0.3],
                    data: [elePer, 0.5*elePer,0.25*elePer],
                    radius: '55%',
                    amplitude: '15%',
                    waveLength:'20%',
                    // 水球颜色
                    // color: ['#FFBF11', '#F4B30E', '#EACE36'],
                    color: eleLiquidColorInside,
                    center: ['82%', '50%'],
                    // outline  外边
                    outline: {
                        // show: false
                        borderDistance: 5,
                        itemStyle: {
                            borderWidth: 5,
                            // borderColor: '#FFBF11',
                            borderColor: eleBorderColor,
                        },
                    },
                    label: {
                        normal: {
                            formatter: '电\r\n\r\n'+(ele?ele.toFixed(2):0),
                            textStyle: {
                                // color: '#fdec5b',
                                // color: '#fdbd61',
                                // insideColor: 'yellow',
                                color: eleWordColor,
                                insideColor: eleInsideWordColor,
                                // color: '#e7e74b',
                                // insideColor: '#fd5d35',
                                fontSize: 17
                            }
                        }
                    },
                    // 内图 背景色 边
                    backgroundStyle: {
                        color: 'rgba(4,24,74,0.8)',
                        // borderWidth: 5,
                        // borderColor: 'red',
                    }
                }
            ]
        })

    //
        const { headCount: people=1, totalArea: area=1 } = await Api.postV3('/info/statistics', {
            project: Api.projectId
        }).then(r.prop('data'))
        const areaDosage = sum && area ? (sum / area).toFixed(2) : 0;
        const perCapitaDosage = sum && people ? (sum / people).toFixed(2) : 0;

        const areaElectricDosage = ele && area ? (ele / area).toFixed(2) : 0;
        const perCapitaElectricDosage = ele && people ? (ele / people).toFixed(2) : 0;

        const areaWaterDosage = water && area ? (water / area).toFixed(2) : 0;
        const perCapitaWaterDosage = water && people ? (water / people).toFixed(2) : 0;
        this.setState({
            areaDosage,
            perCapitaDosage,
            areaElectricDosage,
            perCapitaElectricDosage,
            areaWaterDosage,
            perCapitaWaterDosage,
        });
    }

  selected(params) {

    switch (params) {
      case 'day':
        this.getData('/energyRegionNew/usageRatioDaily', 'day')
        break;
      case 'month':
        this.getData('/energyRegionNew/usageRatioMonthly', 'month')
        break;
      case 'year':
        this.getData('/energyRegionNew/usageRatioAnnually', 'year')
        break;
      default:
        this.getData('/energyRegionNew/usageRatioDaily', 'day')
        break;

    }
    // console.log(this.state)
  }


  render() {
    const { areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum, water, ele } = this.state;

    return (
      <div className='indexBottom2V2'>
        <div className='indexBottomHead'>
          <span style={{fontSize: '18px',
              fontWeight: 'bold',top:'-25px',left:'200px',position:'absolute',color:'white'}}>综合能耗</span>

        </div>

        <div id='ReactEchartsBottom2' style={{ width: "100%", height: "240px", margin: "0" }}>
        <div>

        </div>
        <div>

        </div>
        <div>

        </div>
        </div>
        {/*<div className="legend">*/}
          {/*<p></p>*/}
          {/*<ul>*/}
            {/*<li><i className="icon" style={{ backgroundColor: waterColor }}></i><span>水能耗: {water} kecg</span></li>*/}
            {/*<li><i className="icon" style={{ backgroundColor: eleColor }}></i><span>电能耗: {ele} kecg</span></li>*/}
            {/*<li><i className="icon" style={{ backgroundColor: sumColor }}></i><span>总能耗: {sum} kecg</span></li>*/}
          {/*</ul>*/}
        {/*</div>*/}
        {/*<div className='indexBottom2Bottom'>*/}
          {/*<Row gutter={8} >*/}
            {/*<Col span={8}>面积能耗：{areaDosage}kwh</Col>*/}
            {/*<Col span={8}>面积水耗：{areaWaterDosage}t</Col>*/}
            {/*<Col span={8}>面积电耗：{areaElectricDosage}kwh</Col>*/}
          {/*</Row>*/}
          {/*<Row gutter={8} >*/}
            {/*<Col span={8}>人均能耗：{perCapitaDosage}kwh</Col>*/}
            {/*<Col span={8}>人均水耗：{perCapitaWaterDosage}t</Col>*/}
            {/*<Col span={8}>人均电耗：{perCapitaElectricDosage}kwh</Col>*/}
          {/*</Row>*/}
        {/*</div>*/}

      </div>
    )
  }
}


export default IndexBottom2;
