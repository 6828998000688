import * as r from 'ramda'

const typeMapping = {
  ELECTRICITYMETER: [
    'ELECTRICITYMETER',
    'MELECTRICITYMETER',
    'NBELECTRICITYMETER'],
  WATER: [
    'WATER',// ltx 20201001 因为综合分析里面没有对应的water类型数据，所以这里添加进来，服务端是总的，但是前段又根据water 遍历子的名称下数据，结果导致没有数据。
    'COLDWATERMETER',
    'HOTWATERMETER',
    'DDWATERMETER',
    'ULTRACOLDWATERMETER',
    'NBCOLDWATERMETER'],
  GAS: [
    'GASMETER',
  ],
  AC: [
    'TIMERMETER',
    'HEATENERGYMETER',
    'TEMPERATURECONTROL',
    'TTYPETEMPCONTROL',
    'ZTYPETEMPCONTROL',
    'ENERGYMETER',
  ],
  PV: [
    'PHOTOVOLTAIC',
  ],
}

export const typesInCategory = category => r.propOr([])(category)(typeMapping)