import React from 'react';
import { Table, Switch, Slider } from 'antd';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

// import './dayReport.scss';
import Api from 'api';

class Temperature extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data: [{}],
            allData: null,
            isUpData: false,
            tableLoading: false
        };
    }

    async getData() {
        this.setState({
            tableLoading: true,
            data: []
        })

        const temphumMeter = await Api.post('/sensors/getTypeSensors', {
            type: "TEMPHUMMETER",
            project: Api.projectId
        });

        let data = []
        temphumMeter.data.list.forEach((i, k) => {
            data.push({
                key: k,
                order: k < 10 ? '0' + k : k,
                name: i.title,
                // id: i.id,
                instrumentId: i.addrid,
                channal: i.passageName,
                reading: i.sList[0].realdata,
                state: i.sList[0].realfreq > i.sList[0].freq ? '●异常' : '●正常',
                time: i.lastupdate,
                number: i.id.slice(-5, -3),
                device_id: i.id.slice(0, 12),
                multipoint: i.sList[0].comi,
                updatecyc: i.sList[0].freq,
                is_del: i.is_del,
                principal: i.principal,
                onoff: 1,
                allInformation: i
            })
        })

        this.setState({
            data: data,
            total: temphumMeter.data.list.length,
            tableLoading: false
        })
        // // 采集器编号
        // collectorNumber: 1
        // // 标识 id 
        // device_id: "188789"
        // //  设备 id
        // equimpentId: 166875
        // // 序号
        // id: 10001
        // // 仪表 id
        // instrumentId: 12344587
        // // 是否显示
        // is_del: 1
        // // 通讯状态 0正常 1异常
        // messageType: 0
        // // 通讯时间
        // messagetime: "2019-01-24"
        // // 倍率
        // multipoint: 1.23
        // //  
        // name: "新水表01"
        // // 
        // newState: { id: 1, tittle: "在线设备" }
        // // 
        // newUsetype: { usetypeId: 101, usertypeName: "水" }
        // // 当前读数
        // nowNumber: "0000.00"
        // //开关 0开 1关
        // onoff: 1
        // // 通道
        // passageName: "正向有功"
        // // 是否为主表 是0 否1
        // principal: 1
        // // 更新频率
        // updatecyc: "11"
    }

    componentDidMount() {
        this.getData()
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }

    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 150,
        }, {
            title: '智能仪表名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} id={this.state.data.id} record={record} />
            ),
            width: 150,
        }, {
            title: '智能仪表ID',
            dataIndex: 'instrumentId',
            key: 'instrumentId',
            width: 150,
        }, {
            title: '能量系数',
            dataIndex: 'energynumber',
            key: 'energynumber',
            width: 150,
        }, {
            title: '通讯状态',
            dataIndex: 'cmnsta',
            key: 'cmnsta',
            width: 150,
        }, {
            title: '面板开关',
            dataIndex: 'switchof',
            key: 'switchof',
            render: (text, record) => (
                // onoff
                <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === 0 ? true : false} onChange={this.onChange} />
            ),
            width: 150,
        }, {
            title: '阀门开关',
            dataIndex: 'valveswitch',
            key: 'valveswitch',
            render: (text, record) => (
                // onoff
                <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === 0 ? true : false} onChange={this.onChange} />
            ),
            width: 150,
        }, {
            title: '模式',
            dataIndex: 'mode',
            key: 'mode',
            render: (text, record) => (
                // onoff
                <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === 0 ? true : false} onChange={this.onChange} />
            ),
            width: 150,
        }, {
            title: '档位',
            dataIndex: 'gear',
            key: 'gear',
            width: 150,
        }, {
            title: '室内温度(℃)',
            dataIndex: 'roomtem',
            key: 'roomtem',
            width: 150,
        }, {
            title: '设置温度(℃)',
            dataIndex: 'settem',
            key: 'settem',
            width: 150,
        }, {
            title: '通讯时间',
            dataIndex: 'messageTime',
            key: 'messageTime',
            render: (text, record) => (
                <div>
                    <Slider defaultValue={text} min={15} max={30} />
                </div>
            ),
            width: 150,
        }, {
            title: '操作',
            dataIndex: 'operation',
            key: 'operation',
            render: (text, record) => (
                <div>
                    <Management record={record} isUpData={this.upData} />
                    <Delete record={record} />
                </div>
            ),
            width: 150,
        }, {
            title: '设备标识',
            dataIndex: 'device_id',
            key: 'device_id',
            className: 'hidden',
        }, {
            title: '倍率',
            dataIndex: 'multipoint',
            key: 'multipoint',
            className: 'hidden',
        }, {
            title: '更新频率',
            dataIndex: 'updatecyc',
            key: 'updatecyc',
            className: 'hidden',
        }, {
            title: '是否删除',
            dataIndex: 'is_del',
            key: 'is_del',
            className: 'hidden',
        }, {
            title: '是否为总表',
            dataIndex: 'principal',
            key: 'principal',
            className: 'hidden',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: this.state.total,
            liData: ['搜索']
        }
        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={this.state.data}
                    scroll={{ y: 580 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    rowKey='temperature'
                    className='reportRightTableTab' />
                <TableBottom number='10' />
            </div >
        )
    }
}


export default Temperature;