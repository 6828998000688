import React, { Component } from 'react';
import * as r from 'ramda';
import { Row, Col } from 'antd';

import './indexBottom2.scss';
import Api from 'api';

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
// 引入提示框和标题组件
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';


const gridNumber = 16;
const waterColor = '#b0f0fe',
  eleColor = '#ffa348',
  sumColor = '#7accfc';

const charetsContext = ({ sum, showNub, color }) => {
  const iSum = sum > 0 ? sum : 100;
  const iShowNub = showNub > 0 ? showNub : 0
  const iAdd = iSum / gridNumber;
  const showTab = isNaN(iShowNub) || iSum === 0 && iShowNub === 0 ? 80 : iShowNub
  let assistdata2 = [];
  for (let i = 0; i < showTab - iAdd; i += iAdd) {
    assistdata2.push({
      name: "1",
      value: iAdd,
      selected: true,
      itemStyle: {
        show: false,
        normal: {
          color: color
        }
      }
    });
  }
  if (showTab % iAdd !== 0) {
    assistdata2.push({
      name: "1",
      value: showTab % iAdd,
      selected: true,
      itemStyle: {
        show: false,
        normal: {
          color: color
        }
      },
    });
  }
  assistdata2.push({
    name: "2",
    value: iSum - showTab,
    selected: true,
    itemStyle: { color: 'transparent' }
  });
  return assistdata2
}

class IndexBottom2 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      areaDosage: 0,
      perCapitaDosage: 0,
      areaElectricDosage: 0,
      perCapitaElectricDosage: 0,
      areaWaterDosage: 0,
      perCapitaWaterDosage: 0,
      sum: 0,
      active: "day",
      spanStyle: {
        // display: 'block',
          width: '70px',
          // float: 'right',
          textAlign: 'right',
          // marginRight: '50px'
      },
      spanStyle2: {
        // display: 'block',
          width: '100%',
          // float: 'right',
          textAlign: 'right',
          // marginRight: '50px'
      }
    }
    this.reactEcharts = null;
  }

  formatNub = (nub) => {
    if (10000 > nub && nub >= 1000) {
      return (nub / 1000).toFixed(2) + '千'
    }
    if (1000000 > nub && nub >= 10000) {
      return (nub / 10000).toFixed(2) + '万'
    }
    if (10000000 > nub && nub >= 1000000) {
      return (nub / 1000000).toFixed(2) + '百万'
    }
    if (nub >= 10000000) {
      return (nub / 10000000).toFixed(2) + '千万'
    }
    return nub.toFixed(2)

  }

  componentDidMount() {
    const { sum, water, ele } = this.state;
    // console.log('init bottom2 : -- ' + sum + '|' + water + '|' + ele);
    this.reactEcharts = echarts.init(document.getElementById('ReactEcharts'));
    let a = 0;

    this.reactEcharts.setOption({
      series: [
        {
          type: 'pie',
          center: ['30%', '50%'],
          // radius: ['30%', '40%'],
          radius: ['20%', '35%'],
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: water * 0.8, color: waterColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          // radius: ['45%', '55%'],//环的位置
          radius: ['40%', '55%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false,
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: ele * 0.8, color: eleColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['60%', '70%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            shadowColor: `rgba(134, 204, 255, ${a})`,
            shadowBlur: 10,
          },
          data: charetsContext({ sum, showNub: sum * 0.85, color: sumColor })
        },
      ]
    })
  }
  async getData(api) {
    console.log('begin to getData for bottom2 ---');
    this.state = { getOption: { series: [] }, ...this.state };
    let { total, electricity: ele, water } = await Api.postV3(api, {
      project: Api.projectId
    }).then(r.prop('data'));
      //水的能耗标煤不统计进去，因为它能量是抽水或者加热，这部分能量已经统计进了电表中。 0902

      // water = 10;// 测试
    const sum = (Number(total)-Number(water?water:0)).toFixed(2);
    ele = (Number(ele)-Number(water?water:0)).toFixed(2);
    console.log('sum ele water ' + sum + '|' + ele + '|' + water);

    const { headCount: people, totalArea: area } = await Api.postV3('/info/statistics', {
      project: Api.projectId
    }).then(r.prop('data'))

    const areaDosage = sum && area ? this.formatNub(sum / area) : 0;
    const perCapitaDosage = sum && people ? this.formatNub(sum / people) : 0;

    const areaElectricDosage = ele && area ? this.formatNub(ele / area) : 0;
    const perCapitaElectricDosage = ele && people ? this.formatNub(ele / people) : 0;

    const areaWaterDosage = water && area ? this.formatNub(water / area) : 0;
    const perCapitaWaterDosage = water && people ? this.formatNub(water / people) : 0;

    this.reactEcharts.setOption({
      series: [
        {
          type: 'pie',
          center: ['30%', '50%'],
          // radius: ['30%', '40%'],
          radius: ['20%', '35%'],
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: water * 0.8, color: waterColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          // radius: ['45%', '55%'],//环的位置
          radius: ['40%', '55%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false,
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: ele * 0.8, color: eleColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['60%', '70%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            shadowBlur: 10,
          },
          data: charetsContext({ sum, showNub: sum * 0.85, color: sumColor })
        },
      ]
    })
    this.setState({
      areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum,
      water,
      ele
    })
  }

  async componentWillReceiveProps(nextProps) {
    const { electricity, total, water } = nextProps;
    const sum = total;
    const ele = electricity;
    console.log('receive props : ' + sum + '|' + ele);
    const { headCount: people, totalArea: area } = await Api.postV3('/info/statistics', {
      project: Api.projectId
    }).then(r.prop('data'))


    const areaDosage = sum && area ? this.formatNub(sum / area) : 0;
    const perCapitaDosage = sum && people ? this.formatNub(sum / people) : 0;

    const areaElectricDosage = ele && area ? this.formatNub(ele / area) : 0;
    const perCapitaElectricDosage = ele && people ? this.formatNub(ele / people) : 0;

    const areaWaterDosage = water && area ? this.formatNub(water / area) : 0;
    const perCapitaWaterDosage = water && people ? this.formatNub(water / people) : 0;

    this.reactEcharts.setOption({
      grid: { containLabel: true },
      series: [
        {
          type: 'pie',
          center: ['30%', '50%'],
          // radius: ['30%', '40%'],
          radius: ['20%', '35%'],
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: water * 0.8, color: waterColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          // radius: ['45%', '55%'],//环的位置
          radius: ['40%', '55%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false,
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          data: charetsContext({ sum, showNub: ele * 0.8, color: eleColor })
        },
        {
          type: 'pie',
          center: ['30%', '50%'],
          radius: ['60%', '70%'],//环的位置
          clockwise: false,
          hoverAnimation: false,
          silent: true,
          stillShowZeroSum: false,
          minAngle: 10,
          label: {
            normal: {
              show: false
            }
          },
          labelLine: {
            normal: {
              show: false
            }
          },
          itemStyle: {
            shadowBlur: 10,
          },
          data: charetsContext({ sum, showNub: sum * 0.85, color: sumColor })
        },
      ]
    })
    this.setState({
      areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum,
      water,
      ele
    })
  }

  selected(params) {
    this.setState({ active: params })
    switch (params) {
      case 'day':
        this.getData('/energy/usageRatioDaily')
        break;
      case 'month':
        this.getData('/energy/usageRatioMonthly')
        break;
      case 'year':
        this.getData('/energy/usageRatioAnnually')
        break;
      default:
        this.getData('/energy/usageRatioDaily')
        break;

    }
  }


  render() {
    const { areaDosage,
      perCapitaDosage,
      areaElectricDosage,
      perCapitaElectricDosage,
      areaWaterDosage,
      perCapitaWaterDosage,
      sum, water, ele } = this.state;
    const dataFormatting = (v) => {
      const nub = Number(v)
      return (nub ? nub : 0).toFixed(2)
    }

    return (
      <div className='indexBottom2'>
        <div className='indexBottomHead'>
          <span>综合能耗</span>
          <div>
            <i className={`${this.state.active === "day" ? "active" : ""} hover`} onClick={this.selected.bind(this, "day")}>日</i>
            <i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月</i>
            <i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年</i>
          </div>
        </div>
        <div id='ReactEcharts' style={{ width: "100%", height: "210px" ,marginTop:0}}></div>
        <div className="legend">
          <p></p>
          <ul>
            <li><i className="icon" style={{ backgroundColor: waterColor }}></i><span>水用量: {dataFormatting(water)} t</span></li>
            <li><i className="icon" style={{ backgroundColor: eleColor }}></i><span>电能耗: {dataFormatting(ele)} kecg</span></li>
            <li><i className="icon" style={{ backgroundColor: sumColor }}></i><span>总能耗: {dataFormatting(sum)} kecg</span></li>
          </ul>
        </div>
        <div className='indexBottom2Bottom'>
          <Row gutter={12} >
              <Col span={24}><div style={{width:'100%',textAlign:'center',alignContent:'center'}}>面积能耗：<span style={this.state.spanStyle}>{areaDosage}kecg</span></div></Col>
            {/*<Col span={8}>面积水耗：<span style={this.state.spanStyle}>{areaWaterDosage}kecg</span></Col>*/}
            {/*<Col span={8}>面积电耗：<span style={this.state.spanStyle}>{areaElectricDosage}kecg</span></Col>*/}
          </Row>
          <Row gutter={12} >
            <Col span={24}><div style={{width:'100%',textAlign:'center',alignContent:'center'}}>人均能耗：<span style={this.state.spanStyle2}>{perCapitaDosage}kecg</span></div></Col>
            {/*<Col span={8}>人均水耗：<span style={this.state.spanStyle}>{perCapitaWaterDosage}kecg</span></Col>*/}
            {/*<Col span={8}>人均电耗：<span style={this.state.spanStyle}>{perCapitaElectricDosage}kecg</span></Col>*/}
          </Row>
        </div>

      </div>
    )
  }
}


export default IndexBottom2;
