import React, { Component,useContext } from 'react';
import Tree, {Drawer, Spin} from 'antd';
import 'ol/ol.css'
import './map.scss'
import Api from 'api';

// require
// import { Map, View, Overlay } from 'ol';
import TileLayer from 'ol/layer/Tile';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileImage from 'ol/source/TileImage';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import sourceVector from 'ol/source/Vector';
import layerVector from 'ol/layer/Vector';
// import GeoJSON from 'ol/format/GeoJSON';
import * as Extent from 'ol/extent';
import ReactEcharts from 'echarts-for-react';
// import Geojson from './geojson.json'
// import OSM from 'ol/source/OSM';
import Popup from './mapPopup/mapPopup';
import MapTable from './mapTable/mapTable';
import MapTableRight from './mapTable/mapTableRight';
import MapTableRegion from './mapTable/mapTableRegion';

import echarts from 'echarts'
import $ from 'jquery'
import Geojson from './wenzhoushi.json'
// import maps from '../../../public/wenzhoushi'
// import './index.scss'
// import ReactEcharts from 'echarts-for-react'

// import IndexTop from './indexTop/indexTop';
// import IndexMid from './indexMid/indexMid'

// import IndexBottom from './indexBottom/indexBottom'
// const requireContext = require.context("src/img/map/3dmap", true, /^\.\/.*\.png$/);
// const images = requireContext.keys().map(requireContext);
//导入百度地图
// import { Map,Marker, NavigationControl, InfoWindow} from 'react-bmap'
import MapNavigator from "./mapNavigator/mapNavigator";
import * as r from "ramda";
import {of} from "rxjs";
import withStates from "../../context/withStates";
import moment from "moment";
import Util2  from 'src/util/util2';

class AMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupVisible: 'initial',
      left: 0,
      top: 0,
      name: '',
      mapTableIsShow: false,//点
      mapTableIsShow2: false,//区域
      mapTableIsShow3: false,//右侧总的数据
      visible: false,
      //这里的id默认为0，每个项目的初始node id 是0，下面的节点的id则是不同的。后续如果需要详细到楼栋的，可以获取下面的node，
      //现在仅仅是大楼的数据，先默认用0，就是整栋大楼的id
      id: 0,
      projectId: '',
      wenzhoushiVisible: false,
      projectName: '',
      regionId: '',
      regionName: '',
      projectLocate:[],
      projectLocateType:[],
      geoJson:Geojson,
      style: { width: '100%', height: '100%'},
      mapStyle: {
        features: ["road", "building","water","land"],//隐藏地图上的poi
        style : "dark"  //设置地图风格为高端黑
      },
    };
  }

  async getProjectLocate() {
    this.state = { getOption: { series: [] }, ...this.state }
    const data = new Date();
    const hh = data.getHours();

    const projectLocate = await Api.postV3('/energy/projectLocate', {
      project: Api.projectId
    });

    // const geoJson = await Api.getV3('/maps/wenzhoushi.json', {
    //   project: Api.projectId
    // });
    // const geoJson = maps;
    let projectLoc = [],extent=500;
    if (projectLocate.data.result) {
      let res = projectLocate.data.result;
      for (let i = 0; i < res.length; i++) {
        let dataunit = [];
        // electricityTime.push(`0${i}`.slice(-2) + ':00')
        dataunit.push(res[i].longitude);//log
        dataunit.push(res[i].latitude);//lat
        dataunit.push(8);//default size for circle to drow
        dataunit.push(res[i].longitude2);//projectid
        dataunit.push(res[i].latitude2);//projectid
        dataunit.push(res[i].projectId);//projectid
        dataunit.push(res[i].projectName);//projectid
        dataunit.push(res[i].region);//projectid
        dataunit.push(res[i].building);//projectid
        dataunit.push(res[i].total?(Number(res[i].total)).toFixed(2):0);//projectid
        dataunit.push(res[i].max);//projectid
          extent=parseInt(res[i].max)*1.1;
        projectLoc.push(dataunit);
      }
    }
    // console.log(projectLoc);
    this.setState({
      projectLocate: projectLoc,
        extent:extent
      // geoJson:"%PUBLIC_URL%/wenzhoushi.json"
    })
  }

//  根据医院等类型来进行区分
async getProjectForType() {
this.state = {getOption: {series: []}, ...this.state}
const projectLocate2 = await Api.getV3('/projectTotal', {
project: Api.projectId
});


this.setState({
projectLocateType: projectLocate2,
})
}


  componentDidMount() {
    // console.log('signal from mount : -------');
    const $this = this;
    const precision = 0.0006;
    let map = this.map;
    let datas = this.state.projectLocate;
    let myChart = echarts.init(document.getElementById('map'));
    let point = null;
    let pointConter = null;
    let flag = true;
    let nameArr = [];
    // let nameArr = ["yanshiguan"];
    let regionJson = Util2.regionJson;
    // let pointJson = {"yanshiguan":'5d6cd030a59c7200126be563'};
    let pointJson = {};
    // let data2=[
    //   {name: "yanshiguan", value: 65},
    // ];
    let data2=[];
    // let geoCoordMap = {
    //   "yanshiguan":[120.85,28.51]
    // };
    let geoCoordMap = {};
    //加载百度地图
    $(document).ready(function(){
      $this.getProjectForType().then(function (result) {
        console.log('projectForType data is finished');
      });
      $this.getProjectLocate().then(function (result) {
        // console.log('geojson----: ' + $this.state.geoJson);
        echarts.registerMap('wenzhoushi', $this.state.geoJson);

        let datas = $this.state.projectLocate;
        for (let i = 0, len = datas.length; i < len; i++) {
          let item = datas[i];
          nameArr.push(item[6]);
          pointJson[item[6]]=item[5];
          geoCoordMap[item[6]]=[item[3], item[4]];
          data2.push({name: item[6], value: item[9]});
        }

        //  data2=[
        //   {name: "wenzhou1", value: 111},
        //   {name: "wenzhou2", value: 125},
        //   {name: "wenzhou3", value: 135},
        //   {name: "wenzhou4", value: 155},
        //   {name: "wenzhou5", value: 155},
        //   {name: "wenzhou6", value: 165},
        //   {name: "wenzhou7", value: 175},
        //   {name: "wenzhou8", value: 195},
        //   {name: "wenzhou9", value: 500},
        // ];

        //所关注的点的位置
        // 将对应的数据值以json格式封装返回，这样地图上的点就可以显示名字和数值
        let convertData = function (data) {
          let res = [];
          for (let i = 0; i < data.length; i++) {
            let geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {

              if(geoCoord[0]>0 && geoCoord[1]>0){
                  res.push({
                      name: data[i].name,
                      value: geoCoord.concat(data[i].value)
                  });
              }else{
                console.log('该项目的经纬度异常'+data[i].name+'|'+geoCoord[0]+'|'+geoCoord[1]);
              }
            }
          }
          return res;
        };

        //随机生成大量数据
        let convertData2 = function (data) {
          let res = [];
          geoCoordMap=[];

          for (let i = 0; i < data.length; i++) {
            let geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              res.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value)
              });
            }
          }
          return res;
        };

        let option = {
          backgroundColor: 'rgba(18,19,32,0.98)',//#404a59
          title: [{
            text: '温州市能耗监测',
              // subtext: '分类统计',
            //subtext: 'data from PM25.in',
            //sublink: 'http://www.pm25.in',
            x:'center',
              left:'45%',
            textStyle: {
              color: '#fff'
            },
            triggerEvent:true,
              // textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              //     fontFamily: 'Arial, Verdana, sans...',
              //     fontSize: 12,
              //     fontStyle: 'normal',
              //     fontWeight: 'normal',
              // },
              subtextStyle: {//副标题文本样式{"color": "#aaa"}
                  fontFamily: 'Arial, Verdana, sans...',
                  fontSize: 20,
                  color: '#fff',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
              },
          },{
              text: '|  分类统计',
              // subtext: '分类统计2',
              left:'52%',
              //subtext: 'data from PM25.in',
              //sublink: 'http://www.pm25.in',
              x:'center',
              textStyle: {
                  color: '#fff'
              },
              triggerEvent:true,
              // textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              //     fontFamily: 'Arial, Verdana, sans...',
              //     fontSize: 12,
              //     fontStyle: 'normal',
              //     fontWeight: 'normal',
              // },
              subtextStyle: {//副标题文本样式{"color": "#aaa"}
                  fontFamily: 'Arial, Verdana, sans...',
                  fontSize: 20,
                  color: '#fff',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
              },
          },],
          //显示对应的鼠标悬停时的一些样式，出发时点，然后格式是下面的格式
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              return params.name + ' : ' + params.value[2];
            }
          },
          // legend 是说明的吗？
          legend: {
            orient: 'vertical',
            y: 'bottom',
            x:'right',
            data:['pm2.5'],//Legend data should be same with series name or data name.
            textStyle: {
              color: '#fff'
            }
          },
          //地图的一些视觉效果，左下角的显示条，制定颜色渐变范围。
          visualMap: {
            min: 0,
            max: $this.state.extent?$this.state.extent:500,
            calculable: true,
            inRange: {
              color: ['#fff9f0', '#ea5e61', '#d91324'] //color: ['#50a3ba', '#eac736', '#d94e5d']
            },
            textStyle: {
              color: '#fff'
            }
          },
          geo: {
            map: 'wenzhoushi',
            roam:true,//鼠标缩放和漫游
            // zoom:1,// 当前视角缩放比例
            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: true
              }
            },
            itemStyle: {
              normal: {
                areaColor: '#323c48',//#323c48 地图颜色
                borderColor: '#111'//#111
              },
              //在高亮模式下的样式
              emphasis: {
                areaColor: '#d6a9ff'
              }
            },
            regions: [      //对不同的区块进行着色
              {
                name: '平阳县', //区块名称
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(60,169,196,0.3)'
                  }
                }
              },{
                name: '苍南县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(182,253,108,0.3)'
                  }
                }
              },{
                name: '永嘉县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(202,186,153,0.3)'
                  }
                }
              },{
                name: '市辖区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(184,222,253,0.3)'
                  }
                }
              },{
                name: '鹿城区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(180,161,253,0.3)'
                  }
                }
              },{
                name: '龙湾区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,152,238,0.3)'
                  }
                }
              },{
                name: '瓯海区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,189,164,0.3)'
                  }
                }
              },{
                name: '洞头区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,239,145,0.3)'
                  }
                }
              },{
                name: '苍南县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(175,253,213,0.3)'
                  }
                }
              },{
                name: '文成县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(171,226,253,0.3)'
                  }
                }
              },{
                name: '泰顺县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(228,229,253,0.3)'
                  }
                }
              },{
                name: '温州高新区(经开区)',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,214,252,0.3)'
                  }
                }
              },{
                name: '瑞安市',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(251,253,208,0.3)'
                  }
                }
              },{
                name: '乐清市',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,237,244,0.3)'
                  }
                }
              }]

          },
          series: [
            {
              //name: 'pm2.5',
              type: 'scatter',
              coordinateSystem: 'geo',
              // coordinateSystem: 'bmap',
              data: convertData(data2),
              rippleEffect: {             //涟漪闪烁效果
                period: 1,
                scale: 5,
                brushType: 'stroke'
              },
              symbolSize: 8,// 点的大小
              label: {
                normal: {
                  show: false//点的信息是否显示或隐藏
                },
                emphasis: {
                  show: false
                }
              },
              itemStyle: {
                emphasis: {
                  borderColor: '#fff',
                  borderWidth: 1
                }
              },
            }
          ]
        }
        myChart.setOption(option);
        //    ------- end
        // 闪烁点
        // setInterval(function(){
        //   // myChart.clear();
        //   if(flag){
        //     option.series[0].data = convertData(data2);
        //   }else{
        //     option.series[0].data = null;
        //   }
        //   flag=!flag;
        //   myChart.setOption(option);
        // }, 3000);
        myChart.on('click', function (e) {


          // e.color = '#d50000'
          // e.event.target.style.fill = '#d50000'
          // console.log(e.color)
          //点data: {name: "温州2", value: Array(3)}
          // dataIndex: 1
          if(e.data && nameArr.includes(e.data.name)){
            // console.log('find point : ' + pointJson[e.data.name]);
            // 同一个就关，不同个就继续开
            if($this.state.projectId === pointJson[e.data.name]){
              $this.setState({
                visible: true,
                projectId:pointJson[e.data.name],
                projectName:e.data.name,
                mapTableIsShow: !$this.state.mapTableIsShow,
                mapTableIsShow2: false,
              });
            }else{
              $this.setState({
                visible: true,
                projectId:pointJson[e.data.name],
                projectName:e.data.name,
                mapTableIsShow: true,
                mapTableIsShow2: false,
              });
            }


            return;
          }
          // 区域的模块geoIndex: 0
          //
          if(e.region){
            let regionName = e.region.name;
            // console.log('find region : ' + e.region.name + '|' + regionJson[e.region.name]);
            // 同一个就关，不同个就继续开
            if($this.state.regionId === regionJson[e.region.name]){
              $this.setState({
                visible: true,
                regionId:regionJson[e.region.name],
                regionName:e.region.name,
                mapTableIsShow: false,
                mapTableIsShow2: !$this.state.mapTableIsShow2,
              });
            }else{
              $this.setState({
                visible: true,
                regionId:regionJson[e.region.name],
                regionName:e.region.name,
                mapTableIsShow: false,
                mapTableIsShow2: true,
                wenzhoushiVisible:false
              });
            }

            return;
          }
          //用于全部温州市的数据统计，第一个标题
          if(e.componentType && e.componentType == 'title' && e.componentIndex == '0'){
            // console.log('title click,show the total data');
              $this.setState({wenzhoushiVisible:!$this.state.wenzhoushiVisible});
              if($this.state.wenzhoushiVisible){
                  $this.setState({
                      visible: true,
                      regionId:'330300',
                      regionName:'温州市',
                      mapTableIsShow: false,
                      mapTableIsShow2: true,
                  });
              }else{
                  $this.setState({
                      visible: true,
                      regionId:'330300',
                      regionName:'温州市',
                      mapTableIsShow: false,
                      mapTableIsShow2: false,
                  });
              }

          }
          //用于分类统计
          if(e.componentType && e.componentType == 'title' && e.componentIndex == '1'){
            // console.log('title click,show the total data');
            $this.setState({
              visible: true,
              mapTableIsShow3: !$this.state.mapTableIsShow3,
            });

          }

        });
        // myChart.on('mouseover', function (e) {
        //   console.log('mouseover event-----------');
        // });


      });
    });

    // $(document).on('click','div',function(){
    //   console.log('jquery click------------');
    // })
    // $(document).click(function (event) {
    //   console.log('jquery click 2 ------------');
    //   $this.setState({
    //     visible: true,
    //     mapTableIsShow: false,
    //   });
    // })


  }
  onClose = () => {
    this.setState({
      visible: false,
    });
  };


  render() {


    return (
        <div id="map-bg" className='map-bg'>
          <div id="map" style={this.state.style}>
            {/* <div ></div> */}
            {/*<div id="popup2"></div>*/}
            {/*<Popup visible={this.state.popupVisible} name={this.state.name} />*/}
          </div>
          <MapTable mapTableIsShow={this.state.mapTableIsShow} id={this.state.id} projectId={this.state.projectId} projectName={this.state.projectName}/>
          <MapTableRegion mapTableIsShow={this.state.mapTableIsShow2} nodeid={this.state.id} region={this.state.regionId} regionName={this.state.regionName} projectId={Api.projectId}/>
          <MapTableRight mapTableIsShow={this.state.mapTableIsShow3} nodeid={this.state.id} region={this.state.regionId} regionName={this.state.regionName} projectId={Api.projectId} projectLocate={this.state.projectLocate} projectLocateType={this.state.projectLocateType} />
        </div >

    )
  }

};



export default AMap;
