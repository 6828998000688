import React from 'react';

import './indexBottom1.scss';
import Api from 'api';
import Border2 from '../../ui/border2/border2';
import Border4 from '../../ui/border4/border4';

import echarts from 'echarts/lib/echarts';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/title';

const assistdata = [];
const center = ['50%', '50%'],
    radius = ['40%', '65%'],
    selectedOffset = 10,
    gridNumber = 10;
for (let i = 0; i < 100; i += gridNumber) {
    assistdata.push({
        name: "1",
        value: 10,
        selected: true
    });
}
const charetsContainer = {
    name: "半透明格子",
    type: "pie",
    silent: true,
    radius: radius,
    center: center,
    startAngle: 270,
    selectedOffset: selectedOffset,
    avoidLabelOverlap: false,
    color: ["rgba(255,255,255,0.2)"],
    selectedMode: true,
    hoverAnimation: false,
    label: {
        show: false
    },
    labelLine: {
        show: false
    },
    data: assistdata
}

const charetsContext = ({ sum, showNub, color }) => {
    const iAdd = sum / gridNumber;
    let assistdata2 = [];
    for (let i = iAdd; i < showNub; i += iAdd) {
        assistdata2.push({
            name: "1",
            value: iAdd,
            selected: true,
            itemStyle: {
                show: false,
                normal: {
                    color: color
                }
            }
        });
    }
    if (showNub % iAdd !== 0) {
        assistdata2.push({
            name: "1",
            value: showNub % iAdd,
            selected: true,
            itemStyle: {
                show: false,
                normal: {
                    color: color
                }
            },
        });
    } else {
        assistdata2.push({
            name: "1",
            value: iAdd,
            selected: true,
            itemStyle: {
                show: false,
                normal: {
                    color: color
                }
            }
        });
    }
    assistdata2.push({
        name: "2",
        value: sum - showNub,
        selected: true,
        itemStyle: { color: 'transparent' }
    });
    return assistdata2
}


class IndexBottom1 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            sum: 0,
            water: 0,
            electricity: 0,
            ac: 0,
            gas: 0,
            style: { height: '100%' },
            active: "day"
        };
        this.waterEcharts = null;
        this.eleEcharts = null;
        this.acEcharts = null;
        this.gasEcharts = null;
    }

    componentDidMount() {
        const { sum, water, electricity, ac, gas } = this.state;
        // 基于准备好的dom，初始化echarts实例
        this.waterEcharts = echarts.init(document.getElementById('waterEcharts'));
        this.eleEcharts = echarts.init(document.getElementById('eleEcharts'));
        this.acEcharts = echarts.init(document.getElementById('acEcharts'));
        this.gasEcharts = echarts.init(document.getElementById('gasEcharts'));
        // 绘制图表

        this.waterEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (water ? (water / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: water, color: '#03A9FF' })
                },
            ]
        })
        this.eleEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (water ? (water / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: electricity, color: '#FC60AF' })
                },
            ]
        })
        this.acEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (ac ? (ac / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: ac, color: '#2DFAD8' })
                },
            ]
        })
        this.gasEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (gas ? (gas / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: gas, color: '#F6E258' })
                },
            ]
        })

        let a = 0;
        let isTrue = true;
        setInterval(() => {

            if (isTrue) {
                a += 0.05;
                if (a >= 0.9) {
                    isTrue = false;
                }
            }
            if (!isTrue) {
                a -= 0.05;
                if (a <= 0) {
                    isTrue = true;
                }
            }

            this.waterEcharts.setOption(
                {
                    series: [{
                        itemStyle: {
                            shadowColor: `rgba(134, 204, 255, ${a})`,
                            shadowBlur: 10
                        },
                    },
                    ]
                }
            )
            this.eleEcharts.setOption(
                {
                    series: [{
                        itemStyle: {
                            shadowColor: `rgba(255, 173, 51, ${a})`,
                            shadowBlur: 10
                        },
                    },
                    ]
                }
            )
            this.acEcharts.setOption(
                {
                    series: [{
                        itemStyle: {
                            shadowColor: `rgba(135, 230, 255, ${a})`,
                            shadowBlur: 10
                        },
                    },
                    ]
                }
            )
            this.gasEcharts.setOption(
                {
                    series: [{
                        itemStyle: {
                            shadowColor: `rgba(248, 230, 26, ${a})`,
                            shadowBlur: 10
                        },
                    },
                    ]
                }
            )
        }, 1000)

    }

    componentWillReceiveProps(nextProps) {
        const { ac, electricity, gas, total, water } = nextProps;
        this.setState({
            waterEcharts: { series: [] },
            eleEcharts: { series: [] },
            acEcharts: { series: [] },
            gasEcharts: { series: [] },
        })
        // this.state.waterEcharts.series = [];
        // this.state.eleEcharts.series = [];
        // this.state.acEcharts.series = [];
        // this.state.gasEcharts.series = [];

        const sum = total;

        this.waterEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (water ? (water / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: water, color: '#03A9FF' })
                },
            ]
        })
        this.eleEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (water ? (water / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: electricity, color: '#FC60AF' })
                },
            ]
        })
        this.acEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (ac ? (ac / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: ac, color: '#2DFAD8' })
                },
            ]
        })
        this.gasEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (gas ? (gas / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: gas, color: '#F6E258' })
                },
            ]
        })

        this.setState({
            sum,
            water,
            electricity,
            ac,
            gas,

        })
    }
    async getData(api, active) {
        this.state.waterEcharts.series = [];
        this.state.eleEcharts.series = [];
        this.state.acEcharts.series = [];
        this.state.gasEcharts.series = [];
        const data = await Api.postV3(api, {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });

        const sum = data.data.total || 0;
        const water = data.data.water || 0;
        const electricity = data.data.electricity || 0;
        const ac = data.data.ac || 0;
        const gas = data.data.gas || 0;

        this.waterEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (water ? (water / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: water, color: '#FFAD33' })
                },
            ]
        })
        this.eleEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (water ? (water / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: electricity, color: '#FFAD33' })
                },
            ]
        })
        this.acEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (ac ? (ac / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: ac, color: '#FFAD33' })
                },
            ]
        })
        this.gasEcharts.setOption({
            series: [
                charetsContainer,
                {
                    startAngle: 270,
                    type: 'pie',
                    center,
                    radius,
                    selectedOffset,
                    silent: true,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                            textStyle: {
                                color: '#FEFEFF',

                            },
                            formatter: (gas ? (gas / sum) * 100 : 0).toFixed(2) + '%',
                            rich: {
                                c: {
                                    fontSize: 14,
                                    color: '#ddd',
                                    marginTop: '15px'
                                },
                                b: {
                                    fontSize: 12,
                                    color: '#eee'
                                }
                            }
                        }
                    },
                    data: charetsContext({ sum, showNub: gas, color: '#FFAD33' })
                },
            ]
        })

        this.setState({
            sum,
            water,
            electricity,
            ac,
            gas,
            active
        })
    }

    selected(params) {

        switch (params) {
            case 'day':
                this.getData('/energyRegionNew/usageRatioDaily', 'day')
                break;
            case 'month':
                this.getData('/energyRegionNew/usageRatioMonthly', 'month')
                break;
            case 'year':
                this.getData('/energyRegionNew/usageRatioAnnually', 'year')
                break;
            default:
                this.getData('/energyRegionNew/usageRatioDaily', 'day')
                break;

        }
        // console.log(this.state)
    }

    render() {
        const { sum,
            water,
            electricity,
            ac,
            gas, } = this.state;
        return (
            <div className='indexBottom1' >
                <div className='indexBottomHead' >
                    <Border4 className='Angle' sideLength={'5px'}>
                        <i className='round'></i>
                    </Border4>
                    <span> 用能占比 </span>

                </div>
                <ul>
                    <li>
                        <div id='eleEcharts' style={this.state.style}></div>
                        <p>
                            电
                            <br />
                            {(electricity ? (electricity / sum) * 100 : 0).toFixed(2) + '%'}
                        </p>
                    </li>
                    <li>
                        <div id='gasEcharts' style={this.state.style}></div>
                        <p>
                            燃气
                        <br />
                            {(gas ? (gas / sum) * 100 : 0).toFixed(2) + '%'}
                        </p>

                    </li>
                    <li>
                        <div id='waterEcharts' style={this.state.style}></div>
                        <p>
                            水
                            <br />
                            {(water ? (water / sum) * 100 : 0).toFixed(2) + '%'}
                        </p>
                    </li>
                    <li>
                        <div id='acEcharts' style={this.state.style}></div>
                        <p>
                            空调
                        <br />
                            {(ac ? (ac / sum) * 100 : 0).toFixed(2) + '%'}
                        </p>
                    </li>
                </ul>
            </div >
        )
    }
}


export default IndexBottom1;