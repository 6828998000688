import React, {useContext} from 'react';
import {BrowserRouter as Router, Route, NavLink} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment'
import {Spin} from 'antd'
import {from, of} from 'rxjs'


import './report.scss';
import Api from 'api';

import Tree from 'src/components/treeView/tree';

import DayReport from './dayReport/dayReport';
import MonthReport from './monthReport/monthReport';
import IntervalReport from './intervalReport/intervalReport';

import withStates from 'src/context/withStates';

const ROOT_NODE = '0';

const Report = () => {
    const {state, dispatch} = useContext(context);
    const onSelect = (node) => (event) => {
        console.log(`node ${r.head(event)} selected`)

        return (r.isEmpty(event) || r.head(event) === node) ? (state) => of(state) :
            (state) => loadNodeUsage({...state, loading: true, node: r.head(event)})
    }
    return (
        <div className="repor">

            <div className="reporLeft">
                <div className="reporLeftTit">
                    建筑结构
                </div>
                {
                    r.isEmpty(state.tree) ?
                        <Spin/> :
                        <Tree data={state.tree}
                              defaultExpandedKeys={[ROOT_NODE]}
                              onSelect={e => {
                                  dispatch(state => of(r.assoc('loading', true)(state)))
                                  dispatch(onSelect(state.node)(e))
                              }}/>}
            </div>

            <div className="reporRight">
                {/* <Router> */}
                <div>
                    <nav className='reportNav'>
                        <ul>
                            {/* <li>
                                    <NavLink activeClassName='active' to='/Report/DayReport'><span>日报表</span></NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName='active' to='/Report/MonthReport'><span>月报表</span></NavLink>
                                </li>
                                <li>
                                    <NavLink activeClassName='active' to='/Report/IntervalReport'><span>区间报表</span></NavLink>
                                </li> */}
                        </ul>
                        <div>

                        </div>
                    </nav>
                    <IntervalReport/>

                    {/* <Route path="/" exact component={DayReport} />
                        <Route path="/Report/DayReport" exact component={DayReport} />
                        <Route path="/Report/MonthReport" component={MonthReport} />
                        <Route path="/Report/IntervalReport" component={IntervalReport} /> */}
                </div>
                {/* </Router> */}

            </div>

        </div>

    )
}

const treeViewData = async () => {
    const {data: {result = []}} = await Api.post('/customer/info', {
        project: Api.projectId,
    });
    const toChildren = r.compose(
        r.map(({id: key, title, key: did, child}) =>
            ({
                title, key, did,
                children: r.isNil(child) ? [] : toChildren(child),
            })),
        r.values)
    return {
        searchIsShow: true,
        loading: false,
        node: ROOT_NODE,
        tree: [
            {
                title: Api.projectName,
                key: ROOT_NODE,
                children: toChildren(
                    r.filter(
                        r.where({
                            type: r.equals('NODE'),
                            parent: r.equals(0),
                        }),
                    )(result)),
            },
        ],
    }
}
///

const deviceNameMap = {
    ELECTRICITYMETER: {name: '电表', value: 'ELECTRICITYMETER'},
    COLDWATERMETER: {name: '冷水表', value: 'COLDWATERMETER'},
    NBCOLDWATERMETER: {name: 'NB冷水表', value: 'NBCOLDWATERMETER'},
    PHOTOVOLTAIC: {name: '光伏', value: 'PHOTOVOLTAIC'},
    ENERGYMETER: {name: '能量表', value: 'ENERGYMETER'},
    HOTWATERMETER: {name: '热水表', value: 'HOTWATERMETER'},
    MELECTRICITYMETER: {name: '多功能电表', value: 'MELECTRICITYMETER'},
    ULTRACOLDWATERMETER: {name: '超声波水表', value: 'ULTRACOLDWATERMETER'},
    TIMERMETER: {name: '时间采集器', value: 'TIMERMETER'},
    TEMPERATURECONTROL: {name: '温控器', value: 'TEMPERATURECONTROL'},
    HEATENERGYMETER: {name: '热能表', value: 'HEATENERGYMETER'},
    GASMETER: {name: '燃气表', value: 'GASMETER'},
}

const translateType = v => {
    return r.propOr(v)(v)(deviceNameMap)
}

const translateSubentry = v => {
    const valueMap = {
        '01000': '总表用电',
        '01A00': '照明与插座用电',
        '01A10': '照明与插座',
        '01A20': '走廊与应急',
        '01A30': '室外景观',
        '01B00': '空调用电',
        '01B10': '冷热站',
        '01B1A': '冷冻泵',
        '01B1B': '冷却泵',
        '01B1C': '冷机',
        '01B1D': '冷塔',
        '01B1E': '热水循环泵',
        '01B1F': '电锅炉',
        '01B20': '空调末端',
        '01B2A': '全空气机组',
        '01B2B': '新风机组',
        '01B2C': '排风机组',
        '01B2D': '风机盘管',
        '01B2E': '分体式空调',
        '01C00': '动力用电',
        '01C10': '电梯',
        '01C20': '水泵',
        '01C30': '通风机',
        '01D00': '特殊用电',
        '01D10': '信息中心',
        '01D20': '洗衣房',
        '01D30': '厨房餐厅',
        '01D40': '游泳池',
        '01D50': '健身房',
        '02000': '水',
        '03000': '管道煤气',
        '04000': '集中供热量',
        '05000': '集中供冷量',
        '01D90': '其他分项',
        '80001': '开关量输入',
        '90001': '继电器控制',
    }
    return r.propOr(v)(v)(valueMap)
}

const translateDeviceType = (v, d) => {
    const valueMap = {
        ...r.compose(r.mergeAll, r.map(x => ({[x]: [x]})), r.keys)(deviceNameMap),
        all: r.keys(deviceNameMap)
    }
    //TODOs: shit pile, first add unit tests, then kill this mutating shit
    const returnArr = [];
    r.map(
        (item) => {
            return (
                d[item] ? r.map(
                    (a) => {
                        returnArr.push(r.mergeAll([a, {type: item}]))
                    }
                )(d[item]) : null
            )
        }
    )(r.propOr(v)(v)(valueMap))
    return returnArr
}

const translateChannel = v => {
    const allChannel = {
        "01": "正向有功",
        "02": "A相电压",
        "03": "B相电压",
        "04": "C相电压",
        "05": "A相电流",
        "06": "B相电流",
        "07": "C相电流",
        "08": "能量系数",
        "09": "总冷量",
        "10": "总热量",
        "11": "累计流量",
        "12": "冷量系数",
        "13": "热量系数",
        "14": "瞬时流量",
        "15": "冷水刻度",
        "16": "热水刻度",
        "17": "供水温度",
        "18": "回水温度",
        "19": "设定温度",
        "20": "室内温度",
        "21": "面板状态",
        "22": "56状态",
        "23": "空调模式",
        "24": "流速"
    }
    return r.propOr(v)(v)(allChannel)
}

const nodeUsage = async ({node, from, to, deviceType, search}) => {
    const {data: dayReport} = await Api.get(`/api/v3/project/${Api.projectId}/report/${node}?from=${from}&to=${to}`)

    const isFn = n => {
        const {sensor, title} = n;
        const re = new RegExp(search)
        return r.test(re)(sensor) || r.test(re)(title)
    }
    console.log(dayReport)
    const all = r.filter(isFn)(translateDeviceType(deviceType, dayReport))
    const tableData = all.map(({usage: sum, sensor: instrumentId, channel, cost, startScale = 0, endScale = 0, price, coding, title, type, comi = '-'}, i) => ({
        sum,
        instrumentId,
        channelName: translateChannel(channel),
        order: i + 1 < 10 ? `0${i + 1}` : i + 1,
        name: title,
        eqType: translateType(type).name,
        price,
        cost,
        energyType: translateSubentry(coding),
        startScale: Number(startScale).toFixed(2),
        endScale: Number(endScale).toFixed(2),
        comi,

    }))

    const totalUsage = r.compose(r.sum, r.flatten, r.map(v => r.prop('usage')(v)))
    (r.filter(n => !r.equals('-', r.prop('usage')(n)))(all))

    const totalCost = r.compose(r.sum, r.flatten, r.map(v => r.prop('cost')(v)))
    (r.filter(n => !r.equals('-', r.prop('cost')(n)))(all))

    return {
        loading: false,
        node,
        select: r.map(([k, v]) => (translateType(k)))(r.toPairs(dayReport)),
        tableData,
        from,
        to,
        deviceType,
        total: {
            usage: totalUsage.toFixed(2),
            cost: totalCost.toFixed(2),
        }
    }
}

const loadReport = (state) => {
    const input = Promise.all(
        [treeViewData(state), nodeUsage(state)])
        .then(r.mergeAll)
    return from(input)
}

export const loadNodeUsage = (state) => {
    // console.log('loadDevices', state);
    return from(nodeUsage(state)
        .then(res => ({...state, ...res})))
}
const initState = {
    loading: true,
    node: ROOT_NODE,
    tree: [],
    reportType: 'day',
    from: moment().subtract(1, 'month').format('YYYYMMDD'),
    to: moment().format('YYYYMMDD'),
    tableData: [],
    deviceType: 'all',
    total: {
        usage: 0,
        cost: 0,
    }
}

export const {context, Component} = withStates(
    Report,
    initState,
    loadReport,//这里包含有左边树的数据和其他数据
)

export default Component







