import React, { Component,useContext } from 'react';
import Tree, {Drawer, Spin} from 'antd';
import 'ol/ol.css'
import './reportTemplates.scss'
import Api from 'api';

import Docxtemplater from'docxtemplater';
import ImageModule  from'docxtemplater-image-module-free';
import PizZip from'pizzip';
import fs from 'fs';
// import Fse from 'fs-extra';
import path from 'path';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';

// require
// import { Map, View, Overlay } from 'ol';
import TileLayer from 'ol/layer/Tile';
import TileGrid from 'ol/tilegrid/TileGrid';
import TileImage from 'ol/source/TileImage';
import Style from 'ol/style/Style';
import Stroke from 'ol/style/Stroke';
import Fill from 'ol/style/Fill';
import sourceVector from 'ol/source/Vector';
import layerVector from 'ol/layer/Vector';
// import GeoJSON from 'ol/format/GeoJSON';
import * as Extent from 'ol/extent';
import ReactEcharts from 'echarts-for-react';
// import Geojson from './geojson.json'
// import OSM from 'ol/source/OSM';
import Popup from './mapPopup/mapPopup';
import MapTable from './mapTable/mapTable';
import MapTableRight from './mapTable/mapTableRight';
import MapTableRegion from './mapTable/mapTableRegion';

import echarts from 'echarts'
import $ from 'jquery'
import Geojson from './wenzhoushi.json'
import IndexMidAverage from './indexMid/indexMidAverage'
import wenzhoushiDoc from './wenzhoushi_projects.docx'
// import maps from '../../../public/wenzhoushi'
// import './index.scss'
// import ReactEcharts from 'echarts-for-react'

// import IndexTop from './indexTop/indexTop';
// import IndexMid from './indexMid/indexMid'

// import IndexBottom from './indexBottom/indexBottom'
// const requireContext = require.context("src/img/map/3dmap", true, /^\.\/.*\.png$/);
// const images = requireContext.keys().map(requireContext);
//导入百度地图
// import { Map,Marker, NavigationControl, InfoWindow} from 'react-bmap'
import MapNavigator from "./mapNavigator/mapNavigator";
import * as r from "ramda";
import {of} from "rxjs";
import withStates from "../../context/withStates";
import moment from "moment";

var storeData;

class ReportTemplates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popupVisible: 'initial',
      left: 0,
      top: 0,
      name: '',
      mapTableIsShow: false,//点
      mapTableIsShow2: false,//区域
      mapTableIsShow3: true,//右侧总的数据，默认显示存在
      visible: false,
      //这里的id默认为0，每个项目的初始node id 是0，下面的节点的id则是不同的。后续如果需要详细到楼栋的，可以获取下面的node，
      //现在仅仅是大楼的数据，先默认用0，就是整栋大楼的id
      id: 0,
      projectId: '',
      wenzhoushiVisible: false,
      projectName: '',
      regionId: '',
      regionName: '',
      projectLocate:[],
      projectLocateType:[],
      geoJson:Geojson,
      style: { width: '100%', height: '1000px'},
      mapStyle: {
        features: ["road", "building","water","land"],//隐藏地图上的poi
        style : "dark"  //设置地图风格为高端黑
      },
        mychart:'',//能耗地图
        echart_type:'',//类型图形
        echart_region:'',//区域图形
        type_table:'',//类型图表

        allProjectInfo:'',//类型图表
        func_locate:[],//类型图表
        func_name:[],//类型图表
    };
  }

  async getProjectLocate() {
    this.state = { getOption: { series: [] }, ...this.state }
    const data = new Date();
    const hh = data.getHours();

    const projectLocate = await Api.postV3('/energy/projectLocate', {
      project: Api.projectId
    });

    // const geoJson = await Api.getV3('/maps/wenzhoushi.json', {
    //   project: Api.projectId
    // });
    // const geoJson = maps;
    let projectLoc = [],extent=500;
    if (projectLocate.data.result) {
      let res = projectLocate.data.result;
      for (let i = 0; i < res.length; i++) {
        let dataunit = [];
        // electricityTime.push(`0${i}`.slice(-2) + ':00')
        dataunit.push(res[i].longitude);//log
        dataunit.push(res[i].latitude);//lat
        dataunit.push(8);//default size for circle to drow
        dataunit.push(res[i].longitude2);//longitude2  实际是用这个的
        dataunit.push(res[i].latitude2);//latitude2
        dataunit.push(res[i].projectId);//projectId
        dataunit.push(res[i].projectName);//projectName
        dataunit.push(res[i].region);//region
        dataunit.push(res[i].building);//building
        dataunit.push(res[i].total);//total
        dataunit.push(res[i].max);//max
          extent=parseInt(res[i].max)*1.1;
        projectLoc.push(dataunit);
      }
    }
    // console.log(projectLoc);
    this.setState({
      projectLocate: projectLoc,
        extent:extent
      // geoJson:"%PUBLIC_URL%/wenzhoushi.json"
    })
  }

//  根据医院等类型来进行区分
// --- s
//  projectTotal
//  数据1：
//  数据2：
//  --- e
async getProjectForType() {
this.state = {getOption: {series: []}, ...this.state}
const projectLocate2 = await Api.getV3('/projectTotal', {
project: Api.projectId
});


this.setState({
projectLocateType: projectLocate2,
})
}

async getAllProjectInfo() {
    const allProjectInfo = await Api.getV3('/allProjectInfo', {
        project: Api.projectId
    });


    this.setState({
        allProjectInfo: allProjectInfo.data,
    })
}

  //330301
  // 市辖区
  //              330302	鹿城区
  // 330303	龙湾区
  // 330304	瓯海区
  // 330305	洞头区
  // 330324	永嘉县
  // 330326	平阳县
  // 330327	苍南县
  // 330328	文成县
  // 330329	泰顺县
  // 330371	温州高新区(经开区)
  // 330381	瑞安市
  // 330382	乐清市
  componentDidMount() {
    // console.log('signal from mount : -------');
    const $this = this;
    const precision = 0.0006;
    let map = this.map;
    let datas = this.state.projectLocate;

    // let myChart = echarts.init(document.getElementById('map'));
    //   this.setState({
    //       mychart:myChart
    //   })
    let point = null;
    let pointConter = null;
    let flag = true;
    let nameArr = [];
    // let nameArr = ["yanshiguan"];
    // let pointJson = {"yanshiguan":'5d6cd030a59c7200126be563'};
    let pointJson = {};
    // let data2=[
    //   {name: "yanshiguan", value: 65},
    // ];
    let data2=[];
    // let geoCoordMap = {
    //   "yanshiguan":[120.85,28.51]
    // };
    let geoCoordMap = {};
    //加载百度地图
    $(document).ready(function(){
      $this.getProjectForType().then(function (result) {
        console.log('projectForType data is finished');
      });

      $this.getAllProjectInfo().then(function (result) {
        console.log('getAllProjectInfo ----');
      });


      $this.getProjectLocate().then(function (result) {
        // console.log('geojson----: ' + $this.state.geoJson);
        echarts.registerMap('wenzhoushi', $this.state.geoJson);

        let datas = $this.state.projectLocate;
        for (let i = 0, len = datas.length; i < len; i++) {
          let item = datas[i];
          nameArr.push(item[6]);
          pointJson[item[6]]=item[5];
          geoCoordMap[item[6]]=[item[3], item[4]];
          data2.push({name: item[6], value: item[9]});
          data2.push({name: item[6], value: item[9]});
        }

        let data1=[
          {name: "wenzhou1", value: 111},
          {name: "wenzhou2", value: 125},
          {name: "wenzhou3", value: 135},
          {name: "wenzhou4", value: 155},
          {name: "wenzhou5", value: 155},
          {name: "wenzhou6", value: 165},
          {name: "wenzhou7", value: 175},
          {name: "wenzhou8", value: 195},
          {name: "wenzhou9", value: 500},
        ];

        //所关注的点的位置
        // 将对应的数据值以json格式封装返回，这样地图上的点就可以显示名字和数值
        let convertData = function (data) {
          let res = [];
          for (let i = 0; i < data.length; i++) {
            let geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              res.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value)
              });
            }
          }
          return res;
        };

        //随机生成大量数据
        let convertData2 = function (data) {
          let res = [];
          geoCoordMap=[];

          for (let i = 0; i < data.length; i++) {
            let geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              res.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value)
              });
            }
          }
          return res;
        };

        let option = {
          backgroundColor: 'rgba(18,19,32,0.98)',//#404a59
          title: [{
            text: '温州市能耗监测',
              // subtext: '分类统计',
            //subtext: 'data from PM25.in',
            //sublink: 'http://www.pm25.in',
            x:'center',
              left:'45%',
            textStyle: {
              color: '#fff'
            },
            triggerEvent:true,
              // textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              //     fontFamily: 'Arial, Verdana, sans...',
              //     fontSize: 12,
              //     fontStyle: 'normal',
              //     fontWeight: 'normal',
              // },
              subtextStyle: {//副标题文本样式{"color": "#aaa"}
                  fontFamily: 'Arial, Verdana, sans...',
                  fontSize: 20,
                  color: '#fff',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
              },
          },{
              text: '|  分类统计',
              // subtext: '分类统计2',
              left:'52%',
              //subtext: 'data from PM25.in',
              //sublink: 'http://www.pm25.in',
              x:'center',
              textStyle: {
                  color: '#fff'
              },
              triggerEvent:true,
              // textStyle: {//主标题文本样式{"fontSize": 18,"fontWeight": "bolder","color": "#333"}
              //     fontFamily: 'Arial, Verdana, sans...',
              //     fontSize: 12,
              //     fontStyle: 'normal',
              //     fontWeight: 'normal',
              // },
              subtextStyle: {//副标题文本样式{"color": "#aaa"}
                  fontFamily: 'Arial, Verdana, sans...',
                  fontSize: 20,
                  color: '#fff',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
              },
          },],
          //显示对应的鼠标悬停时的一些样式，出发时点，然后格式是下面的格式
          tooltip: {
            trigger: 'item',
            formatter: function (params) {
              return params.name + ' : ' + params.value[2];
            }
          },
          // legend 是说明的吗？
          legend: {
            orient: 'vertical',
            y: 'bottom',
            x:'right',
            data:['pm2.5'],//Legend data should be same with series name or data name.
            textStyle: {
              color: '#fff'
            }
          },
          //地图的一些视觉效果，左下角的显示条，制定颜色渐变范围。
          visualMap: {
            min: 0,
            max: $this.state.extent?$this.state.extent:500,
            calculable: true,
            inRange: {
              color: ['#fff9f0', '#ea5e61', '#d91324'] //color: ['#50a3ba', '#eac736', '#d94e5d']
            },
            textStyle: {
              color: '#fff'
            }
          },
          geo: {
            map: 'wenzhoushi',
            roam:true,//鼠标缩放和漫游
            // zoom:1,// 当前视角缩放比例
            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: true
              }
            },
            itemStyle: {
              normal: {
                areaColor: '#323c48',//#323c48 地图颜色
                borderColor: '#111'//#111
              },
              //在高亮模式下的样式
              emphasis: {
                areaColor: '#d6a9ff'
              }
            },
            regions: [      //对不同的区块进行着色
              {
                name: '平阳县', //区块名称
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(60,169,196,0.3)'
                  }
                }
              },{
                name: '苍南县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(182,253,108,0.3)'
                  }
                }
              },{
                name: '永嘉县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(202,186,153,0.3)'
                  }
                }
              },{
                name: '市辖区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(184,222,253,0.3)'
                  }
                }
              },{
                name: '鹿城区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(180,161,253,0.3)'
                  }
                }
              },{
                name: '龙湾区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,152,238,0.3)'
                  }
                }
              },{
                name: '瓯海区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,189,164,0.3)'
                  }
                }
              },{
                name: '洞头区',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,239,145,0.3)'
                  }
                }
              },{
                name: '苍南县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(175,253,213,0.3)'
                  }
                }
              },{
                name: '文成县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(171,226,253,0.3)'
                  }
                }
              },{
                name: '泰顺县',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(228,229,253,0.3)'
                  }
                }
              },{
                name: '温州高新区(经开区)',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,214,252,0.3)'
                  }
                }
              },{
                name: '瑞安市',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(251,253,208,0.3)'
                  }
                }
              },{
                name: '乐清市',
                itemStyle: {
                  normal: {
                    areaColor: 'rgba(253,237,244,0.3)'
                  }
                }
              }]

          },
          series: [
            {
              //name: 'pm2.5',
              type: 'scatter',
              coordinateSystem: 'geo',
              // coordinateSystem: 'bmap',
              data: convertData(data2),
              rippleEffect: {             //涟漪闪烁效果
                period: 1,
                scale: 5,
                brushType: 'stroke'
              },
              symbolSize: 8,// 点的大小
              label: {
                normal: {
                  show: false//点的信息是否显示或隐藏
                },
                emphasis: {
                  show: true
                }
              },
              itemStyle: {
                emphasis: {
                  borderColor: '#fff',
                  borderWidth: 1
                }
              },
            }
          ]
        }
        // myChart.setOption(option);
        //    ------- end
        // 闪烁点
        // setInterval(function(){
        //   // myChart.clear();
        //   if(flag){
        //     option.series[0].data = convertData(data2);
        //   }else{
        //     option.series[0].data = null;
        //   }
        //   flag=!flag;
        //   myChart.setOption(option);
        // }, 3000);
        // myChart.on('click', function (e) {
        //
        //
        //   // e.color = '#d50000'
        //   // e.event.target.style.fill = '#d50000'
        //   // console.log(e.color)
        //   //点data: {name: "温州2", value: Array(3)}
        //   // dataIndex: 1
        //   if(e.data && nameArr.includes(e.data.name)){
        //     // console.log('find point : ' + pointJson[e.data.name]);
        //     // 同一个就关，不同个就继续开
        //     if($this.state.projectId === pointJson[e.data.name]){
        //       $this.setState({
        //         visible: true,
        //         projectId:pointJson[e.data.name],
        //         projectName:e.data.name,
        //         mapTableIsShow: !$this.state.mapTableIsShow,
        //         mapTableIsShow2: false,
        //       });
        //     }else{
        //       $this.setState({
        //         visible: true,
        //         projectId:pointJson[e.data.name],
        //         projectName:e.data.name,
        //         mapTableIsShow: true,
        //         mapTableIsShow2: false,
        //       });
        //     }
        //
        //
        //     return;
        //   }
        //   // 区域的模块geoIndex: 0
        //   //
        //   if(e.region){
        //     let regionName = e.region.name;
        //     // console.log('find region : ' + e.region.name + '|' + regionJson[e.region.name]);
        //     // 同一个就关，不同个就继续开
        //     if($this.state.regionId === regionJson[e.region.name]){
        //       $this.setState({
        //         visible: true,
        //         regionId:regionJson[e.region.name],
        //         regionName:e.region.name,
        //         mapTableIsShow: false,
        //         mapTableIsShow2: !$this.state.mapTableIsShow2,
        //       });
        //     }else{
        //       $this.setState({
        //         visible: true,
        //         regionId:regionJson[e.region.name],
        //         regionName:e.region.name,
        //         mapTableIsShow: false,
        //         mapTableIsShow2: true,
        //         wenzhoushiVisible:false
        //       });
        //     }
        //
        //     return;
        //   }
        //   //用于全部温州市的数据统计，第一个标题
        //   if(e.componentType && e.componentType == 'title' && e.componentIndex == '0'){
        //     // console.log('title click,show the total data');
        //       $this.setState({wenzhoushiVisible:!$this.state.wenzhoushiVisible});
        //       if($this.state.wenzhoushiVisible){
        //           $this.setState({
        //               visible: true,
        //               regionId:'330300',
        //               regionName:'温州市',
        //               mapTableIsShow: false,
        //               mapTableIsShow2: true,
        //           });
        //       }else{
        //           $this.setState({
        //               visible: true,
        //               regionId:'330300',
        //               regionName:'温州市',
        //               mapTableIsShow: false,
        //               mapTableIsShow2: false,
        //           });
        //       }
        //
        //   }
        //   //用于分类统计
        //   if(e.componentType && e.componentType == 'title' && e.componentIndex == '1'){
        //     // console.log('title click,show the total data');
        //     $this.setState({
        //       visible: true,
        //       mapTableIsShow3: !$this.state.mapTableIsShow3,
        //     });
        //
        //   }
        //
        // });
        // myChart.on('mouseover', function (e) {
        //   console.log('mouseover event-----------');
        // });


      });
    });

    $(document).on('click','div',function(){
      console.log('jquery click------------');
    })
    $(document).click(function (event) {
      console.log('jquery click 2 ------------');
      $this.setState({
        visible: true,
        mapTableIsShow: false,
      });
    })


  }
  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  setEchart_type_region = (e1, e2) =>{
      this.setState({
          echart_type:e1,
          echart_region:e2
      })
  }
  setType_table = (tableData) =>{
    console.log('tableData from multi level child : ----- ' + tableData);
      storeData=tableData;
      // this.setState({
      //     type_table:tableData,
      // })

  }

    // 导出文档
    exportWord = ()=>{

        var _this = this;

        var dataJson2 = {
            // table: _this.tableData,
            allProjectInfo:_this.state.allProjectInfo,
            project_name:Api.projectName,
            compile_date:'2020年07月01日',
            building_type:'',
            location:'',
            first_name: 'John',
            last_name: 'Doe',
            phone: '0652455478',
            description: 'New Website',
            // type_builds:_this.state.echart_type.getDataURL(), // 获取echarts图片
            // region_builds:_this.state.echart_region.getDataURL(), // 获取echarts图片
            // energy_map:_this.state.mychart.getDataURL(), // 获取echarts图片
            clients: [
                {
                    "first_name": "John",
                    "last_name": "Doe",
                    "phone": "+44546546454"
                },
                {
                    "first_name": "Jane",
                    "last_name": "Doe",
                    "phone": "+445476454"
                }
            ],
            // tb:tb,
            rawXml:'<w:p><w:pPr><w:rPr><w:color w:val="FF0000"/></w:rPr></w:pPr><w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>My custom</w:t></w:r><w:r><w:rPr><w:color w:val="00FF00"/></w:rPr><w:t>XML</w:t></w:r></w:p>',
            // loop:true,
            "loop": [
                {inner:'one'},
                {inner:'two'},
                {inner:'three'},
            ]
        };
        console.log(dataJson2);
        return;


        console.log('click the export word button : ------ ');
        // 点击导出word
        function replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        }

        function errorHandler(error) {
            console.log(JSON.stringify({error: error}, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors.map(function (error) {
                    return error.properties.explanation;
                }).join("\n");
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }

// image start ---
        var opts = {}
        opts.centered = false; //Set to true to always center images
        opts.fileType = "docx"; //Or pptx


// 导出echarts图片，格式转换
        const base64DataURLToArrayBuffer =(dataURL) => {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }

//Pass your image loader
// opts.getImage = function(tagValue, tagName) {
//     //tagValue is 'examples/image.png'
//     //tagName is 'image'
//     return fs.readFileSync(tagValue);
// }
        opts.getImage = function(chartId){
            return base64DataURLToArrayBuffer(chartId);
        }


//Pass the function that return image size
        opts.getSize = function(img, tagValue, tagName,widX,widY) {
            //img is the image returned by opts.getImage()
            //tagValue is 'examples/image.png'
            //tagName is 'image'
            //tip: you can use node module 'image-size' here
            return [widX?widX:150, widY?widY:150];
        }

        var imageModule = new ImageModule(opts);

// image end -----




//Load the docx file as a binary
//         var content = fs
//             .readFileSync('src/reportTemplates/wenzhoushi_projects.docx', 'binary');
        //Load the docx file as a binary
        // var content =fs
        //     .readFile(path.resolve(__dirname, 'wenzhoushi_projectsdocx'), 'binary');
        // var content = JSZipUtils.getBinaryContent("../../../../static/docTemplate/exportTemplate.docx",function(error, content) {
        //
        // })
        // var content = Fse.read(path.resolve(__dirname, 'tag-example.docx'), 'binary');
        JSZipUtils.getBinaryContent(wenzhoushiDoc,function(error, content) {
            // exportTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            // 抛出异常
            if (error) {
                throw error;
            }
            // 图片处理
            let opts = {}
            opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
            opts.fileType = "docx";
            opts.getImage = function(chartId){
                return base64DataURLToArrayBuffer(chartId);
            }
            opts.getSize = function(){
                return [80,80]
            }
            let imageModule = new ImageModule(opts);
            // 创建一个PizZip实例，内容为模板的内容
            let zip = new PizZip(content);
            // 创建并加载docxtemplater实例对象
            let doc = new Docxtemplater();
            doc.attachModule(imageModule);
            doc.loadZip(zip);

            // 设置模板变量的值
            var i = 1;
            // var tb = r.map(y=>{return {'nm':y.name,'tp':y.type,'ar':y.area,'cn':y.count,
            //     'm1':y.month_1,'m2':y.month_2,'m3':y.month_3,'m4':y.month_4,'m5':y.month_5,
            //     'm6':y.month_6,'m7':y.month_7,'m8':y.month_8,'m9':y.month_9,'m10':y.month_10,
            //     'm11':y.month_11,'m12':y.month_12,}})(storeData);
            console.log('tb');
            // console.log(tb);
            var dataJson = {
                // table: _this.tableData,
                project_name:Api.projectName,
                first_name: 'John',
                last_name: 'Doe',
                phone: '0652455478',
                description: 'New Website',
                // type_builds:_this.state.echart_type.getDataURL(), // 获取echarts图片
                // region_builds:_this.state.echart_region.getDataURL(), // 获取echarts图片
                // energy_map:_this.state.mychart.getDataURL(), // 获取echarts图片
                clients: [
                    {
                        "first_name": "John",
                        "last_name": "Doe",
                        "phone": "+44546546454"
                    },
                    {
                        "first_name": "Jane",
                        "last_name": "Doe",
                        "phone": "+445476454"
                    }
                ],
                // tb:tb,
                rawXml:'<w:p><w:pPr><w:rPr><w:color w:val="FF0000"/></w:rPr></w:pPr><w:r><w:rPr><w:color w:val="FF0000"/></w:rPr><w:t>My custom</w:t></w:r><w:r><w:rPr><w:color w:val="00FF00"/></w:rPr><w:t>XML</w:t></w:r></w:p>',
                // loop:true,
                "loop": [
                    {inner:'one'},
                    {inner:'two'},
                    {inner:'three'},
                ]
            };
            console.log(dataJson);
            doc.setData(dataJson);

            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                throw error;
            }
            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            let out = doc.getZip().generate({
                type: "blob",
                mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            // 将目标文件对象保存为目标类型的文件，并命名
            saveAs(out, "exportDocx.docx");
        });


    }





  render() {


    return (
        <div>
          <div>
          <h1> 报告预览  </h1>
            {/*<input type="button" onClick={this.exportWord}>导出word文档</input>*/}
            <button type="button" onClick={this.exportWord}>导出word文档</button>
            <h2>温州市公共建筑能耗情况报告（2019年度）</h2>

                按照《温州市建筑节能条例》、《XXX》等文件要求，本市推进公共建筑节能监管体系建设，XXXX年至XXXX年期间已建成“国家机关办公建筑和大型公共建筑能耗监测平台”。<br/>
            <h3>2019年度监测情况</h3><br/>
            载止2020年5月1日，温州市公共建筑能耗监测平台（以下简称“市能耗监测平台”）累计共有XXXX幢建筑完成能耗监测系统的安装并实现了与市级平台的数据联网，覆盖建筑面积达XX万m2，<br/>
            其中政府办公建筑XX栋，占监测总量的XX%，覆盖建筑面积约XX万m2，大型公共建筑XXX栋，占监测总量的XX%，覆盖建筑面积约XXX万m2。与2019年度相比，新增联网建筑XX栋，建筑面积达XX万m2，<br/>
            其中新增联网政府办公建筑XX栋，建筑面积XX万m2；新增联网大型公共建筑XX栋，建筑面积XX万m2。<br/>
            各类型建筑占比：<br/>
        </div>
          {/*<div>*/}
            {/*<MapTableRight mapTableIsShow={this.state.mapTableIsShow3} nodeid={this.state.id} region={this.state.regionId} regionName={this.state.regionName} projectId={Api.projectId} projectLocate={this.state.projectLocate} projectLocateType={this.state.projectLocateType}*/}
                           {/*setEchart_type_region = {this.setEchart_type_region}/>*/}
            {/*图1：各类型建筑占比<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}
              {/*<br/>*/}

          {/*</div>*/}
          {/*<div style={{width:'100%'}}>*/}
            {/*2020年，本市11个区县级公共建筑能耗监测分平台均建设完成，运行情况良好，鹿城区、龙湾区、瓯海区接入数量均超过XX栋。<br/>*/}

            {/*图2：各区县联网建筑占比<br/>*/}
          {/*</div>*/}
          {/*<div>*/}
            {/*<h3>2019年度能耗情况</h3><br/>*/}
            {/*纳入本市建筑能耗监测平台的主要建筑类型为政府办公建筑、办公建筑、旅游饭店建筑、商场建筑、综合建筑五类，占比达87%。2015年度主要类型建筑年能耗分别为：<br/>*/}
            {/*政府办公建筑，68.3 kWh/m2；<br/>*/}
            {/*办公建筑，86.2kWh/m2；<br/>*/}
            {/*旅游饭店建筑，120.7kWh/m2；<br/>*/}
            {/*商场建筑，139.5kWh/m2；<br/>*/}
            {/*综合建筑，101.0kWh/m2。<br/>*/}
          {/*</div>*/}
          {/*<div>*/}
              {/*<div className="indexAverage">*/}
                  {/*<IndexMidAverage setType_table = {this.setType_table}/>*/}
              {/*</div>*/}
            {/*图3：主要类型建筑逐月能耗<br/>*/}

            {/*图4：温州市能耗地图展示<br/>*/}
            {/*<h3>历年能耗情况</h3><br/>*/}

            {/*图4：温州市过去三年能耗情况<br/>*/}
          {/*</div>*/}
          {/*<div>*/}
            {/*经分析，过去三年全市公共建筑单位面积能耗水平呈下降趋势，其中2019年度降幅较大。分析其原因，首先是政策引导，本市在“十二五”期间实施了《温州市建筑节能项目专项扶持办法》，<br/>*/}
            {/*每年印发《节能减排和应对气候变化重点工作安排》，不断加大建筑节能工作的推进力度；其次是市场推动，XXX-XXX期间完成既有公共建筑节能改造面积超过XXX万平方米，其中国家级重点项目示<br/>*/}
            {/*范面积XXX万平方米，改造后单位建筑面积能耗下降20%以上，合同能源管理是主要采取的改造方式，节能改造在一定程度上也导致了能源结构的变化，部分类型的建筑如旅游饭店，用电比例增加但总<br/>*/}
            {/*能耗下降明显；再者，气温的影响也是不可忽视的因素之一。<br/>*/}
                {/*<h3>市级平台近况</h3><br/>*/}
            {/*截止2020年5月1日，市级平台累计接入建筑XXX栋，覆盖建筑面积达XXX万m2。其中政府办公建筑XXX栋，覆盖建筑面积XXX万m2；大型公共建筑XXX栋，覆盖建筑面积XXX万m2。较2019年增加了XX栋，<br/>*/}
            {/*覆盖面积增加了XXX万m2。XXX-XXX期间，本市建筑能耗监测平台将进一步扩大公共建筑运行能耗数据监测范围，同时不断优化平台功能、强化能耗监测数据应用。市级平台专为楼宇用户开发的手机端<br/>*/}
            {/*能效管理软件“温州能耗”也即将与广大楼宇用户见面，方便用户实时了解楼宇能耗状况，实现精准管理，不断促进能效管理水平的提升。<br/>*/}
          {/*</div>*/}


            {/*<div id="map-bg" className='map-bg'>*/}
                {/*<div id="map" style={this.state.style}>*/}
                    {/*/!* <div ></div> *!/*/}
                    {/*/!*<div id="popup2"></div>*!/*/}
                    {/*/!*<Popup visible={this.state.popupVisible} name={this.state.name} />*!/*/}
                {/*</div>*/}
                {/*/!*单个项目点，区域项目统计，温州市分类统计*!/*/}
                {/*<MapTable mapTableIsShow={this.state.mapTableIsShow} id={this.state.id} projectId={this.state.projectId} projectName={this.state.projectName}/>*/}
                {/*<MapTableRegion mapTableIsShow={this.state.mapTableIsShow2} nodeid={this.state.id} region={this.state.regionId} regionName={this.state.regionName} projectId={Api.projectId}/>*/}
                {/*/!*<MapTableRight mapTableIsShow={this.state.mapTableIsShow3} nodeid={this.state.id} region={this.state.regionId} regionName={this.state.regionName} projectId={Api.projectId} projectLocate={this.state.projectLocate} projectLocateType={this.state.projectLocateType} />*!/*/}
            {/*</div >*/}
        </div>

    )
  }

};



export default ReportTemplates;
