import React from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Input, message, Radio, Row, Select, Modal,Steps,Popover } from 'antd';
import {Link} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment';
import './newRegisterInfo.scss';
import Api from 'api';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import JsZip from 'jszip';
import wznhjcRegister from './wznhjcRegister2.docx';
import wenzhoushiPdf from './wenzhoushi_projects4.pdf';
import ImageModule from "docxtemplater-image-module-free";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import QRCode from "qrcode";
import sm2 from 'sm-crypto';

import {
    formItemLayout,
    groupFormItemLayout, joinAllModules,
    projectDefaultModules,
    projectModules,
    projectSource,
    projectType,
    rightFormItemLayout,
    rightRadioFormItemLayout,
    tailFormItemLayout
} from "src/context/project";


import Util2  from 'src/util/util2';
import md5 from "md5";

const extend = '.pdf'
const sm2Cipher=sm2.sm2;
const publicKey = "0409db2be43f63522033164b626aba995f3e9233548590ee774bef832cbaf38983bddef48e502d082d8d7680c7c6e86072c7c432391912b3a4eae42b91b33a1443";
const cipherMode = 1

const {Option} = Select;
const {textArea} = Input;
const { Step } = Steps;

const confirm = Modal.confirm;
const {RangePicker} = DatePicker
const regionJsonConverter = Util2.regionJsonConverter;

const buildType = Util2.projectTypeTransfer;
const pvtype = Util2.pvType;



const warmType = {
    INWARM: '内保温',
    OUTWARM: '外保温',
    INSIDEWARM: '夹芯保温',
    OTHER: '其他',
};
const windType = {
    SIGSIG: '单玻单层窗',
    SIGDOUB: '单玻双层窗',
    SIGSIGSIGDOUB: '单玻单层窗+单玻双层窗',
    EMPDOUB: '中空双层玻璃窗',
    EMPTHREE: '中空三层玻璃窗',
    EMPLAZY: '中空充惰性气体',
    OTHER: '其他',
};
const buildGlassType = {
    NORMAL: '普通玻璃',
    DUMO: '镀膜玻璃',
    LOWE: 'Low-E玻璃',
    OTHER: '其他',
};
const frameType = {
    IRON: '钢窗',
    ALUM: '铝合金窗',
    WOOD: '木窗',
    STOPHOT: '断热窗',
    OTHER: '其他',
};
const heatType = {
    RAD: '散热器采暖',
    FLOOR: '地板辐射采暖',
    ELECTR: '电辐射采暖',
    OTHER: '其他',
};
const airSysType = {
    RAD: '全空气系统',
    FLOOR: '风机盘管+新风系统',
    ELECTR: '分体式空调或变制冷剂流量多联式分体空调机组',
    OTHER: '其他',
};
const checkType = {
    OK: '审核完成',
    ING: '审核中',
};


// console.log(regionJsonConverter['330301']);
// 'use strict';
// import JsZip from 'jszip'
// import { saveAs } from 'file-saver'
export class DownloadUtil {

    constructor() {
        this.zip = new JsZip();
    }



}




const initialValue='15888878787';
const initFlag = false;




class NewProjectFrom extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        // informWay:[
        //     // {funcLocate:'1',funcDomain:'1',funcHeads:'1',funcArea:'1'},//功能区位置 功能区
        //     {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        // ],
        widSpan:5,
        name:initFlag?initialValue:'',
        addr:initFlag?initialValue:'',
        floors:initFlag?initialValue:'',
        // upFloors:initFlag?initialValue:'',
        // downFloors:initFlag?initialValue:'',
        area:initFlag?initialValue:'',
        capacity:initFlag?initialValue:'',
        headCount:initFlag?initialValue:'',
        buildMan:initFlag?initialValue:'',
        buildPhone:initFlag?initialValue:'',
        constructCompany:initFlag?initialValue:'',
        constructMan:initFlag?initialValue:'',
        constructPhone:initFlag?initialValue:'',
        timeFinish:initFlag?initialValue:moment(),
        latitude2:initFlag?initialValue:'',
        longitude2:initFlag?initialValue:'',
        regionBelong:initFlag?initialValue:'330302',
        buildtype:initFlag?initialValue:'SPORT',
        pvtype:initFlag?initialValue:'ICRoof',
        website:initFlag?initialValue:'',
        inputType:initFlag?initialValue:'',
        collectorName:initFlag?initialValue:'',
        device:initFlag?initialValue:'',
        inverter:initFlag?initialValue:'',
        collector:initFlag?initialValue:'',
        warmType:initFlag?initialValue:'INWARM',
        windType:initFlag?initialValue:'SIGSIG',
        buildCompany:initFlag?initialValue:'',
        buildGlassType:initFlag?initialValue:'NORMAL',
        frameType:initFlag?initialValue:'IRON',
        heatType:initFlag?initialValue:'RAD',
        airSysType:initFlag?initialValue:'RAD',
        informWay:initFlag?[
            // {funcLocate:'1',funcDomain:'1',funcHeads:'1',funcArea:'1'},//功能区位置 功能区
            {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        ]:[
            {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        ],
        checkType:'',
        checkStyle:{color:'black',fontSize:30,marginRight:'30px'},
        // content:'本表所填资料完全属实，且保证建筑能耗监测系统计量数据正常、可靠、稳定上传，并承担建筑能耗计量装置的管理和维护工作。' +
        // '    若未能保证建筑能耗计量装置运行正常导致平台数据异常、缺失等情况的，一切法律责任与后果由我公司自行承担。 ',
        qrCodeData:'',
        dealBuildCode:'',
        projectPlanId:'',
        modifyCount:0,
        year:moment().format('YYYY'),
        month:moment().format('MM'),
        day:moment().format('DD'),
        editable:true,
        checkName:'',
        checkPwd:'',
        isAgree:'',
        steps:0,
        pv:1,
    };
    componentDidMount() {
        // 隐藏对应的格式
        document.getElementById("navDiv").style.cssText="display:none";
        const query_params = new URLSearchParams(this.props.location.search);
        const dealBuildCode = query_params .get('dealBuildCode');
        const projectPlanId = query_params .get('projectPlanId');
        const buildPhone = query_params .get('buildPhone');
        console.log(projectPlanId);
        if(projectPlanId){
            // this.setState({projectPlanId:projectPlanId});
            this.findRegisterInfo(projectPlanId,buildPhone);
            this.setState({editable:false});
        }


    }
     infoArr =(idx)=> {
         const arr = ['当前为注册阶段，请填写信息提交', '当前为准备阶段，请务必在本地电表和采集器准备好后，再点击“申请账号”按钮，申请平台账号',
             '当前为账号下发阶段，请等待平台查看，下发账号',
             "当前为数据接入阶段，请参照接入文档，完成数据上传，并在前台设备管理，看到所有设备状态正常，再申请开通数据统计。之后请发送采集器资料和全套强电系统图到15867839831@@163.com",
             '当前为统计开通阶段，等待平台开通，如长时间未开通，请电话联系咨询',
             '当前为7天试运行阶段，请确保期间数据正常，资料还未提交的请尽快提交', '接入完成，请让建设方登录查看，无误后填写已确认，之后可查询并下载接入证明'];
        return arr[idx];
        console.log('infoArr----');
        console.log(idx);
        console.log(arr[idx]);
     }



// 从数据库中找到对应的数据然后展示
     findRegisterInfo =async (projectPlanId,buildPhoneParam)=>{

         const fromData = this.props.form.getFieldValue('projectPlanId');
         const nameData = this.props.form.getFieldValue('name');
         let buildPhone = buildPhoneParam?buildPhoneParam:this.props.form.getFieldValue('buildPhone');
         const constructPhone = this.props.form.getFieldValue('constructPhone');



         if(!fromData){
             message.info('请输入预编建筑编号进行查询');
             return;
         }
         //加了跳转后，原本的查询buildphone 会变成一个类，导致下面以为是有号码
         if(buildPhone && buildPhone.length !== 11){
             buildPhone='';
         }

         if(buildPhone || constructPhone){
            // console.log('buildPhone constructPhone ----');
            // console.log(buildPhone);
            //  console.log('buildPhone constructPhone ----2');
            // console.log(constructPhone);
         }else{
             message.info('请输入注册时建设单位和施工单位手机号进行查询');
             return;
         }
         // if(!fromData&&!nameData){
         //     message.info('请输入预编建筑编号或者建筑名称进行查询');
         // }

         // alert(fromData);
         console.log('--- to find the plan');
         if(fromData||nameData){
             const res =  Api.postV3('/registerInfo2/get', {
                 projectId:'123',
                 project:'123',
                 projectPlanId:projectPlanId?projectPlanId:fromData,
                 buildPhone:buildPhone,
                 constructPhone:constructPhone,
                 isNormal:true,
                 // name:nameData,
             }).then(x=>{
                 if(x.data && x.data.name){

                     // message.info('已经存在项目 ' + x.data.name);
                     let data = x.data;
                     if(data.pv != 1){//查询的不是光伏项目，给出提示
                         message.info('该项目不是光伏项目，请到一般能耗项目注册申请界面查询操作！ ' + x.data.name);
                     }else{
                         let dataJSON = {
                             name:data.name,
                             addr:data.addr,
                             area:data.area,
                             floors:data.floors,
                             upFloors:data.upFloors,
                             downFloors:data.downFloors,
                             headCount:data.headCount,
                             buildMan:data.buildMan,
                             buildPhone:data.buildPhone,
                             buildCompany:data.buildCompany,
                             constructCompany:data.constructCompany,
                             constructMan:data.constructMan,
                             constructPhone:data.constructPhone,
                             timeFinish:data.timeFinish?moment(data.timeFinish,'YYYY-MM-DD'):moment(),
                             regionBelong:data.regionBelong,
                             latitude2:data.latitude2,
                             longitude2:data.longitude2,
                             regionBelongExport:data.regionBelong?regionJsonConverter[data.regionBelong]:'',
                             buildtype:data.buildtype,
                             buildtypeExport:data.buildtype?buildType[data.buildtype]:'',
                             website:data.website,
                             inputType:data.inputType,
                             inputTypeExport:data.inputType===1?'采集器接入':'平台接入',
                             collectorName:data.collectorName,
                             device:data.device,
                             collector:data.collector,
                             warmType:data.warmType,
                             warmTypeExport:data.warmType?warmType[data.warmType]:'',
                             windType:data.windType,
                             windTypeExport:data.windType?windType[data.windType]:'',
                             buildGlassType:data.buildGlassType,
                             buildGlassTypeExport:data.buildGlassType?buildGlassType[data.buildGlassType]:'',
                             frameType:data.frameType,
                             frameTypeExport:data.frameType?frameType[data.frameType]:'',
                             heatType:data.heatType,
                             heatTypeExport:data.heatType?heatType[data.heatType]:'',
                             airSysType:data.airSysType,
                             airSysTypeExport:data.airSysType?airSysType[data.airSysType]:'',
                             informWay:(data.funcLocateDomain && data.funcLocateDomain.length>0)?data.funcLocateDomain:[{funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''}],//功能区位置 功能区],
                             informWayExport:{"informWay":[{funcLocate:'5',funcDomain:'5',funcHeads:'',funcArea:''}]},//功能区位置 功能区],
                             checkType:data.checkType,
                             checkStyle:(data.checkType && data.checkType==='OK')?{color:'green',fontSize:30,marginRight:'20%'}:{color:'red',fontSize:30,marginRight:'20%'},
                             dealBuildCode:data.dealBuildCode,
                             projectPlanId:data.projectPlanId,
                             modifyCount:data.modifyCount,
                             isAgree:data.isAgree,
                             inverter:data.inverter

                         };

                         //     查询最新的steps状态
                         let stepsRes = Api.postV3('/registerInfo2/getChatRecord', {
                             projectId:'123',
                             project:'123',
                             projectPlanId:projectPlanId?projectPlanId:fromData,
                             // name:nameData,
                         }).then(x=>{
                             console.log('get chatRecods ---- ');
                             console.log(x);
                             if(x&&x.data&&x.data.length>0){
                                 console.log('存在chatrecord 数据');
                                 const diff = (a,b)=>(b-a);
                                 let stepsArr = r.sort(diff,r.pluck('steps')(x.data));
                                 dataJSON.steps=Number(stepsArr[0]);
                                 console.log('stepsArr');
                                 console.log(stepsArr);
                             }else{
                                 console.log('不存在chatrecord 数据');
                                 dataJSON.steps=1;
                             }
                             this.setState(dataJSON);
                         })
                     }


                 }else{
                     if(x.data&&x.data.errMsg){

                         message.info(x.data.errMsg,6);
                     }else{

                         message.info('没有找到对应的项目，请检查建筑编号或其他参数',6);
                     }

                 }
                 console.log(x)});
         }
        // saveAs(wenzhoushiPdf, "exportDocx.pdf");
    }
    //开通账号申请
    reqAccount = ()=>{
        if(!this.state.projectPlanId){
            message.info('没有该项目的信息,请先查询');
            return;
        }

        const res =  Api.postV3('/registerInfo2/chatRecord', {
            projectId:'123',
            project:'123',
            projectPlanId:this.state.projectPlanId,
            name:this.state.name,
            chatRecord:'reqAccount',
            steps:2,
            memo1:'',
            memo2:'',
            date:moment().format('YYYYMMDDHHmmss')
            // name:nameData,
        }).then(x=>{
            if(x&&x.data)
            message.info(x.data);
            this.findRegisterInfo(this.state.projectPlanId);
        })
    }
    //开通统计申请
    reqSatistics = ()=>{
        if(!this.state.projectPlanId){
            message.info('没有该项目的信息,请先查询');
            return;
        }

        const res =  Api.postV3('/registerInfo2/chatRecord', {
            projectId:'123',
            project:'123',
            projectPlanId:this.state.projectPlanId,
            name:this.state.name,
            chatRecord:'reqAccount',
            steps:4,
            memo1:'',
            memo2:'',
            date:moment().format('YYYYMMDDHHmmss')
            // name:nameData,
        }).then(x=>{
            if(x&&x.data)
                message.info(x.data);
            this.findRegisterInfo(this.state.projectPlanId);
        })

    }


     exportInfo = ()=>{

         const fromData = this.props.form.getFieldValue('projectPlanId');
         const nameData = this.props.form.getFieldValue('name');
         let fileType = 'pdf';
         if(!fromData||!nameData){
             message.info('请输入查询编号查询,确认该项目存在',3);
             return;
         }
         if(!this.state.checkType) {
             message.info('未开始审核或在审核中');
             return;
         }
         let _this = this;


             if(!_this.state.isAgree || _this.state.isAgree!=='已确认') {
                 message.info('业主未确认意，请整改确认后再申请接入证明');
                 return;
             }
             if(_this.state.checkType&&_this.state.checkType==='OK'){
                 // this.exportWord(this.state);
                 const res =  Api.getV3(`/exportRegisterPdf/download?projectId=123&projectPlanId=${fromData}&name=${nameData}`, {
                     projectId:'123',
                     project:'123',
                     projectPlanId:fromData,
                     name:nameData,
                     fileType:fileType,
                     isAgree:_this.state.isAgree,
                 }).then(x=>{
                     // if(x.data && x.data.code===200){
                     if(x.data ){
                         // message.info('已经存在项目 ' + x.data.name);
                         // 请求下载对应文档

                         // Api.getV3(`/exportRegisterPdf/download?projectId=123&projectPlanId=${fromData}&name=${nameData}`, {
                         //     projectId:'123',
                         //     project:'123',
                         //     projectPlanId:fromData,
                         //     name:nameData,
                         // })
                         let buf = x.data.data;
                         if(!buf){
                             message.info('获取文档数据异常！',5);
                             return;
                         }
                         if('pdf' === fileType){

                             let ab = Buffer.from(buf,'binary');
                             // console.log(buf);
                             // console.log(ab);
                             const blob = new Blob([ab],{
                                 type:"application/pdf"
                             })

                             saveAs(blob, "exportRegister.pdf");
                         }else{
                             let ab = Buffer.from(buf,'binary');
                             // console.log(buf);
                             // console.log(ab);
                             const blob = new Blob([ab],{
                                 type:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                             })

                             saveAs(blob, "exportRegisterDocx.docx");
                         }


                     }else{
                         message.info('没有找到对应的项目，请检查建筑编号或其他参数',6);

                     }
                     console.log(x)});
             }else{
                 message.info('未通过审核，暂无法导出')
             }









    }

    // 导出文档
     exportWord =async (dataJSon)=>{

        var _this = this;
         dataJSon.QRCode = await QRCode.toDataURL('https://www.wznhjc.cn/register?projectPlanId='+dataJSon.projectPlanId,{width:80});
         // dataJSon.QRCode = await QRCode.toDataURL('http://192.168.10.109:3000/register?projectPlanId='+dataJSon.projectPlanId,{width:80});
         console.log(dataJSon.QRCode);
        console.log('click the export word button : ------ ');
        // 点击导出word
        function replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        }

        function errorHandler(error) {
            console.log(JSON.stringify({error: error}, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors.map(function (error) {
                    return error.properties.explanation;
                }).join("\n");
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }

// image start ---
        var opts = {}
        opts.centered = false; //Set to true to always center images
        opts.fileType = "docx"; //Or pptx


// 导出echarts图片，格式转换
        const base64DataURLToArrayBuffer =(dataURL) => {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }

        opts.getImage = function(chartId){
            return base64DataURLToArrayBuffer(chartId);
        }


//Pass the function that return image size
        opts.getSize = function(img, tagValue, tagName,widX,widY) {
            return [widX?widX:150, widY?widY:150];
        }

        var imageModule = new ImageModule(opts);

// image end -----


         let out='';
        await JSZipUtils.getBinaryContent(wznhjcRegister,function(error, content) {
            // exportTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            // 抛出异常
            if (error) {
                throw error;
            }
            // 图片处理
            let opts = {}
            opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
            opts.fileType = "docx";


            opts.getImage = function(chartId){
                return base64DataURLToArrayBuffer(chartId);
            }
            opts.getSize = function(){
                return [200,200]
            }
            let imageModule = new ImageModule(opts);
            // 创建一个PizZip实例，内容为模板的内容
            let zip = new PizZip(content);
            // 创建并加载docxtemplater实例对象
            let doc = new Docxtemplater();
            doc.attachModule(imageModule);
            doc.loadZip(zip);

            doc.setData(dataJSon);

            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                throw error;
            }
            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            out = doc.getZip().generate({
                type: "blob",
                mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            //转成pdf

            // 将目标文件对象保存为目标类型的文件，并命名
                       saveAs(out, "exportRegisterDocx.docx");
        });

         // var pdfBuffer = await toPdf('./wznhjcRegister2.docx');
         // saveAs(pdfBuffer, "exportRegisterDocx.pdf");

     }

    sleep = time => {
        return new Promise(resolve => setTimeout(resolve,time))
    }

    addOrCreateRegister = (values)=>{

        const {	 name,
            projectPlanId,
            modifyCount,
            addr,
            floors,
            // upFloors,
            // downFloors,
            area,
            capacity,
            headCount,
            buildCompany,
            buildMan,
            buildPhone,
            constructCompany,
            constructMan,
            constructPhone,
            timeFinish,
            latitude2,
            longitude2,
            regionBelong,
            buildtype,
            pvtype,
            pv,
            website,
            inputType,
            collectorName,
            device,
            inverter,
            collector,
            warmType,
            windType,
            buildGlassType,
            frameType,
            heatType,
            airSysType,
            funcLocateDomain,
            isAgree} = values;
        // return;
        console.log('Received values of form: ', values);

        let regionBelongName = regionJsonConverter[regionBelong];
        // const modules = joinAllModules(web, admin, analysis, extra)
        const  testFunc = /^(funcLocate_)\d$/;
        var funcLocateDomainArr=[];
        var funcLocate={};
        var funcDomain={};
        var funcHeads={};
        var funcArea={};
        for(var key in values){
            if(testFunc.test(key)){
                //检查funcLocate和funcDomain是否存在空的情况
                var index = key.lastIndexOf('_');
                var num = key.substr(index+1,key.length);
                if(num && values[key] && values['funcDomain_'+num] && values['funcHeads_'+num] && values['funcArea_'+num]){
                    // funcLocate[key]=allValues[key];
                    // funcDomain['funcDomain_'+num]=allValues['funcDomain_'+num];
                    // funcHeads['funcHeads_'+num]=allValues['funcHeads_'+num];
                    // funcArea['funcArea_'+num]=allValues['funcArea_'+num];
                    funcLocateDomainArr.push({funcLocate:values[key],
                        funcDomain:values['funcDomain_'+num],
                        funcHeads:values['funcHeads_'+num],
                        funcArea:values['funcArea_'+num],

                    });
                }
            }
        }



        return Api.postV3('/registerInfo2/add', {
            projectId:'123',
            project:'123',
            projectPlanId:projectPlanId,
            modifyCount:modifyCount+1,
            pv:1,
            name,
            addr,
            floors,
            // upFloors,
            // downFloors,
            area,
            capacity,
            headCount,
            buildCompany,
            buildMan,
            buildPhone,
            constructCompany,
            constructMan,
            constructPhone,
            timeFinish,
            latitude2,
            longitude2,
            regionBelong,
            buildtype,
            pvtype,
            website,
            inputType,
            collectorName,
            device,
            inverter,
            collector,
            warmType,
            windType,
            buildGlassType,
            frameType,
            heatType,
            airSysType,
            isAgree,
            funcLocateDomain:funcLocateDomainArr,
            regionBelongName,
            source:'registerPage',
        }).then(res=>{message.info('操作成功,请点击查询查看状态！')
        // this.findRegisterInfo(projectPlanId);
        });


    }

    handleSubmit = (e) => {
        let _this = this;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            console.log(err);


            if (!err  ) {
                console.log(values);
                // 这里查询，是否已经存在了这个号码
                confirm({
                    title: '请确认填写的信息准确无误，本页面仅用于注册光伏项目，已注册项目勿重复注册，是否提交？',
                    // content:<div><p>&nbsp;&nbsp;&nbsp;&nbsp;本表所填资料完全属实，且保证建筑能耗监测系统计量数据正常、可靠、稳定上传，并承担建筑能耗计量装置的管理和维护工作。
                    //     <br/>&nbsp;&nbsp;&nbsp;&nbsp;若未能保证建筑能耗计量装置运行正常导致平台数据异常、缺失等情况的，一切法律责任与后果由我公司自行承担。  </p> </div>,
                    onOk() {
                        const {	 name,
                            projectPlanId,
                            modifyCount,
                            addr,
                            floors,
                            // upFloors,
                            // downFloors,
                            area,
                            capacity,
                            headCount,
                            buildCompany,
                            buildMan,
                            buildPhone,
                            constructCompany,
                            constructMan,
                            constructPhone,
                            timeFinish,
                            latitude2,
                            longitude2,
                            regionBelong,
                            buildtype,
                            pvtype,
                            pv,
                            website,
                            inputType,
                            collectorName,
                            device,
                            inverter,
                            collector,
                            warmType,
                            windType,
                            buildGlassType,
                            frameType,
                            heatType,
                            airSysType,
                            funcLocateDomain,
                            isAgree} = values;
                        // message.info(isAgree);
                        // if(isAgree && isAgree!=='已确认'){
                        //     message.info("若业主确认能耗数据正常，请填写 已确认,否则请填写空值");
                        //     return;
                        // }

                        const res =  Api.postV3('/registerInfo2/get', {
                            projectId:'123',
                            project:'123',
                            projectPlanId:projectPlanId,
                            // name:nameData,
                        }).then(x=>{
                            if(x.data && x.data.name){
                                // message.info('已经存在项目 ' + x.data.name);
                                // 已经存在这个项目，进行账号验证
                                let dealName = x.data.dealName;
                                confirm({
                                    title: '请输入项目('+x.data.name+')的账号和密码进行修改',
                                    content: ( <div>
                                        <div className=''>
                                            <Input
                                                placeholder='请输入项目账号:'
                                                onChange={(e) => _this.setState({checkName:e.target.value })}
                                            />
                                            <Input
                                                placeholder='请输入项目密码:'
                                                onChange={(e) => _this.setState({checkPwd:e.target.value  })}
                                            />
                                        </div>
                                    </div>),
                                    onOk() {
                                        if(!_this.state.checkName || !_this.state.checkPwd){
                                            message.info('账号或密码不能为空！');
                                            return;
                                        }
                                        if(_this.state.checkName !== dealName){
                                            message.info('输入账号不是该注册项目的账号！',6);
                                            return;
                                        }
                                        // message.info(_this.state.checkName);
                                        // message.info(_this.state.checkPwd);
                                        // return;

                                        //如果是修改已有项目，在这里增加注册验证，只有通过的

                                        Api.post('/auth/login', {
                                            user: _this.state.checkName,
                                            // passwd: md5(_this.state.checkPwd).toUpperCase(),
                                            passwd: sm2Cipher.doEncrypt(md5(_this.state.checkPwd).toUpperCase(), publicKey, cipherMode) ,

                                        }).then((data) => {
                                            if (data.data.code === 20000001) {
                                                message.info(data.data.message,5)
                                                return;
                                            }
                                            if (data.data.code === 90000006) {
                                                message.info('密码错误',5);
                                                return;
                                            }

                                            _this.addOrCreateRegister(values);
                                            //    进行修改
                                            return;
                                            console.log('Received values of form: ', values);

                                            let regionBelongName = regionJsonConverter[regionBelong];
                                            // const modules = joinAllModules(web, admin, analysis, extra)
                                            const  testFunc = /^(funcLocate_)\d$/;
                                            var funcLocateDomainArr=[];
                                            var funcLocate={};
                                            var funcDomain={};
                                            var funcHeads={};
                                            var funcArea={};
                                            for(var key in values){
                                                if(testFunc.test(key)){
                                                    //检查funcLocate和funcDomain是否存在空的情况
                                                    var index = key.lastIndexOf('_');
                                                    var num = key.substr(index+1,key.length);
                                                    if(num && values[key] && values['funcDomain_'+num] && values['funcHeads_'+num] && values['funcArea_'+num]){
                                                        // funcLocate[key]=allValues[key];
                                                        // funcDomain['funcDomain_'+num]=allValues['funcDomain_'+num];
                                                        // funcHeads['funcHeads_'+num]=allValues['funcHeads_'+num];
                                                        // funcArea['funcArea_'+num]=allValues['funcArea_'+num];
                                                        funcLocateDomainArr.push({funcLocate:values[key],
                                                            funcDomain:values['funcDomain_'+num],
                                                            funcHeads:values['funcHeads_'+num],
                                                            funcArea:values['funcArea_'+num],

                                                        });
                                                    }
                                                }
                                            }

                                            console.log('isAgree'+isAgree);

                                            return Api.postV3('/registerInfo2/add', {
                                                projectId:'123',
                                                project:'123',
                                                projectPlanId:projectPlanId,
                                                modifyCount:modifyCount+1,
                                                pv:1,
                                                name,
                                                addr,
                                                floors,
                                                // upFloors,
                                                // downFloors,
                                                area,
                                                capacity,
                                                headCount,
                                                buildCompany,
                                                buildMan,
                                                buildPhone,
                                                constructCompany,
                                                constructMan,
                                                constructPhone,
                                                timeFinish,
                                                latitude2,
                                                longitude2,
                                                regionBelong,
                                                buildtype,
                                                pvtype,
                                                website,
                                                inputType,
                                                collectorName,
                                                device,
                                                inverter,
                                                collector,
                                                warmType,
                                                windType,
                                                buildGlassType,
                                                frameType,
                                                heatType,
                                                airSysType,
                                                funcLocateDomain:funcLocateDomainArr,
                                                regionBelongName,
                                                isAgree,
                                                source:'registerPage',
                                            }).then(res=>{message.info('修改成功')});
                                            // console.log(res.data)
                                            // const msg = res.data === 'ok' ? '创建成功' : res.data;
                                            // message.info(msg);
                                            // if (msg === '创建成功') {
                                            //     this.props.history.push('/Project')
                                            // }




                                        })
                                        return;

                                    },
                                    onCancel() {
                                        return;
                                    },
                                })




                                // if(data.checkType&&data.checkType==='OK'){
                                //     this.exportWord(dataJSON);
                                // }else{
                                //     message.info('未通过审核，暂无法导出')
                                // }

                            }else{
                                // message.info('没有找到对应建筑编号的项目',6);
                                _this.addOrCreateRegister(values);

                            }
                            console.log(x)});





                        return;
                    },
                    onCancel() {
                        return;
                    },
                });



            }else{

            }
        });
    }


    handleWebsiteChange = (value) => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
        }
        this.setState({autoCompleteResult});
    }
     onLinkWay = (index, cmd)=>{
        console.log(index + '|' + cmd);
        var informWay = this.state.informWay;
         const form = this.props.form;
        if('add' == cmd){
            informWay.push({funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''});
        }
        if('remove' == cmd){
            if(informWay.length>=1){
                informWay.splice(index, 1);

            }
        }
        console.log('informWay ---- ');
        console.log(informWay);
        var i = 0;
        r.map(y=>{
            form.setFieldsValue({['funcLocate_'+i]:informWay[i].funcLocate});
            form.setFieldsValue({['funcDomain_'+i]:informWay[i].funcDomain});
            form.setFieldsValue({['funcHeads_'+i]:informWay[i].funcHeads});
            form.setFieldsValue({['funcArea_'+i]:informWay[i].funcArea});
            i++;
        })(informWay);
        this.setState({informWay:informWay});
    }
     dealInformWay = (type,index,value)=>{
        console.log('dealINformWay---');
        console.log(type + '|' + index + '|' + value);
         var informWay = this.state.informWay;
         const form = this.props.form;
        informWay[index][type]=value;
        console.log(informWay);
         this.setState({informWay:informWay});
        // setInformWay([...informWay]);
    }
    //                                <Step title="注册" description="填写项目信息" />
    //                                 <Step title="准备硬件，申请账号"    description="1.本地电表采集器安装完毕 \n 2.总表和分支电表已梳理完毕" />
    //                                 <Step title="平台下发账号和建筑编码" description="" />
    //                                 <Step title="数据接入" description="根据接入文档，使用账号和建筑编号，发送数据到能耗系统，完成后台相关操作" />
    //                                 <Step title="申请开通统计，发送核查资料" description="1.核对总表和分支电表是否完整 2.首页-设备管理 状态正常 3.申请开通统计 4.发送采集器资料和强电系统图给系统" />
    //                                 <Step title="7天试运行及相关核查" description="1.试运行期间无异常 2.总表数值，分表数值之和，两者误差在5%以内" />
    //                                 <Step title="下发接入证明，完成" description="1.运行无异常，平台审核通过 2.项目方通过注册编号，查询下载接入证明" />
    descriptionArr=["填写项目信息","1.本地电表采集器安装完毕 \n 2.电表已梳理完毕","以短信形式发送到建设单位和施工单位联系人" ,"根据接入文档，使用账号和建筑编号，发送数据到能耗系统，完成后台相关操作。申请开通统计 。发送采集器资料和强电系统图给系统",
        "1.试运行期间无异常 ",
        "1.运行无异常，平台审核通过 2.项目方通过注册编号，查询下载接入证明"
    ];

     customDot = (dot, { status, index }) => (

        <Popover
            content={
                <span>
        {this.descriptionArr[index]}
      </span>
            }
        >
            {dot}
        </Popover>
    );
    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '86',
        })(
            <Select style={{width: 70}}>
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        );
        const disabledDate = (current) => {
            return current && current < moment().subtract(1, 'days');
        }
        console.log('modules', r.toPairs(r.propOr({})('web')(projectModules)))
        return (
            <div className="newRegisterInfo">
                {/*<h2 style={{color:'#abb1ac'}}>温州市能耗监测系统项目接入申请表</h2>*/}
                <h2 style={{color:'#abb1ac'}}>太阳能光伏发电计量系统接入申请表</h2>
                <Form onSubmit={this.handleSubmit} className='newProjectForm'>
                    <Row>
                        <Col span={10}>
                            <div className="border-nice" style={{height:'230px'}}>
                                <h2 style={{color:'#a65ac8'}}>建设单位信息</h2>

                                <Form.Item {...formItemLayout}
                                           label='建设单位'
                                >
                                    {getFieldDecorator('buildCompany', {
                                        rules: [{required: true, message: '建设单位不能为空', whitespace: true},],
                                        initialValue: this.state.buildCompany,
                                    })(
                                        <Input  placeholder="请输入建设单位"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label='建设单位联系人'
                                >
                                    {getFieldDecorator('buildMan', {
                                        rules: [{required: true, message: '建设单位联系人不能为空', whitespace: true},],
                                        initialValue: this.state.buildMan,
                                    })(
                                        <Input  placeholder="请输入建设单位联系人"/>
                                    )}
                                </Form.Item>



                                <Form.Item {...formItemLayout}
                                           label="建设单位联系电话"
                                >
                                    {getFieldDecorator('buildPhone', {
                                        rules: [{required: true, message: '联系电话不能为空'}, {
                                            // 由于工信部放号段不定时，所以建议使用泛解析
                                            pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
                                        }],
                                        initialValue: this.state.buildPhone,
                                    })(
                                        <Input style={{width: '100%'}}/>
                                    )}
                                </Form.Item>
                            </div>

                            <div className="border-nice"  style={{height:'760px'}}>
                                <h2 style={{color:'#a65ac8'}}>项目信息</h2>
                                <Form.Item {...formItemLayout}
                                           label="项目名称"
                                >
                                    {getFieldDecorator('name', {
                                        rules: [{
                                            required: true, message: '项目名称不能为空',
                                        }],
                                        initialValue: this.state.name,
                                    })(
                                        <Input placeholder="请输入项目名称"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label='申请编号(请保存)'
                                >
                                    {getFieldDecorator('projectPlanId', {
                                        rules: [{
                                            required: true, message: '项目编号不能为空',
                                        }],
                                        // initialValue: Math.floor(100000000+10000000000000*Math.random()),
                                        initialValue: this.state.projectPlanId?this.state.projectPlanId:moment.now(),
                                    })(
                                        <Input placeholder="请输入8位以上编号"/>
                                    )} <label style={{color:'#f0897b'}}>（申请编号，建设单位手机号，施工单位手机号 3个数据用于查询，更新信息，下载接入证明！）</label>
                                </Form.Item>

                                <Form.Item {...formItemLayout}
                                           label="项目地址"
                                >
                                    {getFieldDecorator('addr', {
                                        rules: [{
                                            required: true, message: '项目地址不能为空',
                                        }],initialValue: this.state.addr,
                                    })(
                                        <Input placeholder="请输入项目地址"/>
                                    )}
                                </Form.Item>


                                {/*<Form.Item {...formItemLayout}*/}
                                           {/*label="地上楼层数"*/}
                                {/*>*/}
                                    {/*{getFieldDecorator('upFloors', {*/}
                                        {/*rules: [{*/}
                                            {/*required: true,*/}
                                            {/*message:'只能输入数字',*/}
                                            {/*pattern: /^[0-9]+$/*/}
                                        {/*}],initialValue: this.state.upFloors,*/}
                                    {/*})(*/}
                                        {/*<Input placeholder="请输入地上楼层数"/>*/}
                                    {/*)}*/}
                                {/*</Form.Item>*/}
                                {/*<Form.Item {...formItemLayout}*/}
                                           {/*label="地下楼层数"*/}
                                {/*>*/}
                                    {/*{getFieldDecorator('downFloors', {*/}
                                        {/*rules: [{*/}
                                            {/*required: true,*/}
                                            {/*message:'只能输入数字',*/}
                                            {/*pattern: /^[0-9]+$/*/}
                                        {/*}],initialValue: this.state.downFloors,*/}
                                    {/*})(*/}
                                        {/*<Input placeholder="请输入地下楼层数"/>*/}
                                    {/*)}*/}
                                {/*</Form.Item>*/}


                                <Form.Item {...formItemLayout}
                                           label="总建筑面积(m²)"
                                >
                                    {getFieldDecorator('area', {
                                        rules: [{
                                            required: true,
                                            message:'输入数字，精确小数点2位',
                                            pattern: /^[0-9]*[.][0-9]{2}$/
                                        }],initialValue: this.state.area,
                                    })(
                                        <Input placeholder="XXXX.XX"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="组件总容量kwp"
                                >
                                    {getFieldDecorator('capacity', {
                                        rules: [{
                                            required: true,
                                            message:'输入数字，精确小数点2位',
                                            pattern: /^[0-9]*[.][0-9]{2}$/
                                        }],
                                        initialValue: this.state.capacity,
                                    })(
                                        <Input placeholder="组件总容量kwp"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="竣工时间"
                                >
                                    {getFieldDecorator('timeFinish', {
                                        initialValue: this.state.timeFinish,
                                        rules: [{  required: true, message: '请选择时间!' }]
                                    })(
                                        <DatePicker  style={{width:'100%'}} />
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="经度"
                                >
                                    {getFieldDecorator('latitude2', {
                                        initialValue: this.state.latitude2,
                                        rules: [{    required: true, message: '请输入经度!' },{pattern: /^[0-9]*[.][0-9]{4}/, message: '经度不能为空，小数保留4位', whitespace: true}]
                                    })(
                                        <Input   style={{width:'60%'}} placeholder="XX.XXXX"/>
                                    )}
                                    <Button   style={{width:'40%', color:'#33bdb7'}} target = "_blank" href = "http://api.map.baidu.com/lbsapi/getpoint/index.html" >取经纬度</Button>
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="纬度"
                                >
                                    {getFieldDecorator('longitude2', {
                                        initialValue: this.state.longitude2,
                                        rules: [{   required: true, message: '请输入纬度!' },{pattern: /^[0-9]*[.][0-9]{4}/, message: '经度不能为空，小数保留4位', whitespace: true}]
                                    })(
                                        <Input placeholder="XX.XXXX"/>
                                    )}
                                </Form.Item>
                            </div>
                        </Col>
                        <Col span={14}>
                            <div  className="border-nice"  style={{height:'230px'}}>

                                <h2 style={{color:'#a65ac8'}}>施工单位信息</h2>
                                <Form.Item {...formItemLayout}
                                           label='能耗系统施工单位'
                                >
                                    {getFieldDecorator('constructCompany', {
                                        rules: [{required: true, message: '能耗系统施工单位不能为空', whitespace: true},],
                                        initialValue: this.state.constructCompany,
                                    })(
                                        <Input  placeholder="请输入能耗系统施工单位"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label='能耗系统施工单位联系人'
                                >
                                    {getFieldDecorator('constructMan', {
                                        rules: [{required: true, message: '能耗系统施工单位联系人不能为空', whitespace: true},],
                                        initialValue: this.state.constructMan,
                                    })(
                                        <Input  placeholder="请输入能耗系统施工单位联系人"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="能耗系统施工单位联系电话"
                                >
                                    {getFieldDecorator('constructPhone', {
                                        rules: [{required: true, message: '能耗系统联系电话不能为空'}, {
                                            // 由于工信部放号段不定时，所以建议使用泛解析
                                            pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
                                        }],
                                        initialValue: this.state.constructPhone,
                                    })(
                                        <Input style={{width: '100%'}}/>
                                    )}
                                </Form.Item>
                            </div>

                            <div className="border-nice"  style={{height:'760px'}}>
                                {/*<h2>模块管理</h2>*/}
                                <h2 style={{color:'#a65ac8'}}>系统信息</h2>
                                <Form.Item {...formItemLayout}
                                           label="系统平台信息1(逆变器数)"
                                >
                                    {getFieldDecorator('inverter', {
                                        initialValue: this.state.inverter,
                                        rules: [{  required: true, pattern: /^[0-9]*$/,message: '请输入项目逆变器数量!' }]
                                    })(
                                        <Input placeholder="光伏逆变器数量"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="系统平台信息2(仪表数)"
                                >
                                    {getFieldDecorator('device', {
                                        initialValue: this.state.collector,
                                        rules: [{  required: true, pattern: /^[0-9]*$/,message: '请输入项目设备数量!' }]
                                    })(
                                        <Input placeholder="项目设备（电表等）数量"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="系统平台信息3(终端数)"
                                >
                                    {getFieldDecorator('collector', {
                                        initialValue: this.state.collector,
                                        rules: [{  required: true,
                                            pattern: /^[0-9]*$/, message: '请输入项目终端数量!' }]
                                    })(
                                        <Input placeholder="项目终端数量"/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="归属区域"
                                >
                                    {getFieldDecorator('regionBelong', {
                                        rules: [{
                                            required: true
                                        }],
                                        initialValue: this.state.regionBelong,

                                    })(
                                        <Select>
                                            {/*<Option value={0}>不可查看</Option>*/}
                                            {/*<Option value={330300}>温州市</Option>*/}
                                            <Option value={"330301"}>市辖区</Option>
                                            <Option value={"330302"}>鹿城区</Option>
                                            <Option value={"330303"}>龙湾区</Option>
                                            <Option value={"330304"}>瓯海区</Option>
                                            <Option value={"330305"}>洞头区</Option>
                                            <Option value={"330324"}>永嘉县</Option>
                                            <Option value={"330326"}>平阳县</Option>
                                            <Option value={"330327"}>苍南县</Option>
                                            <Option value={"330328"}>文成县</Option>
                                            <Option value={"330329"}>泰顺县</Option>
                                            <Option value={"330371"}>温州高新区(经开区)</Option>
                                            <Option value={"330381"}>瑞安市</Option>
                                            <Option value={"330382"}>乐清市</Option>
                                            <Option value={"330354"}>温州生态园</Option>
                                            <Option value={"330355"}>瓯江口</Option>
                                            <Option value={"330383"}>龙港市</Option>
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="建筑类型"
                                >
                                    {getFieldDecorator('buildtype', {
                                        rules: [{
                                            required: true, message: '建筑类型不能为空',
                                        }],
                                        // initialValue: 'SPORT',
                                        initialValue: this.state.buildtype,
                                    })(
                                        <Select placeholder="请选择建筑类型">
                                            {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(buildType))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="电站类型"
                                >
                                    {getFieldDecorator('pvtype', {
                                        rules: [{
                                            required: true, message: '电站类型不能为空',
                                        }],
                                        // initialValue: 'SPORT',
                                        initialValue: this.state.pvtype,
                                    })(
                                        <Select placeholder="请选择电站类型">
                                            {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(pvtype))}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="项目描述"
                                >
                                    {getFieldDecorator('website', {
                                        rules: [{required: true, message: '项目描述不能为空'}],
                                        initialValue: this.state.website,
                                    })(
                                        <Input.TextArea/>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="接入方式"
                                >
                                    {getFieldDecorator('inputType', {
                                        rules: [{
                                            required: true
                                        }],
                                        initialValue: this.state.inputType,

                                    })(
                                        <Select>
                                            <Option value={1}>采集器接入</Option>
                                            {/*<Option value={2}>平台接入</Option>*/}
                                        </Select>
                                    )}
                                </Form.Item>
                                <Form.Item {...formItemLayout}
                                           label="采集器类型(采集器接入选填)"
                                >
                                    {getFieldDecorator('collectorName', {
                                        initialValue: this.state.collectorName,
                                    })(
                                        <Input placeholder="请输入采集器类型" />
                                    )}
                                </Form.Item>
                                <Form.Item  {...formItemLayout}
                                           label="业主确认(完成接入后)"

                                >
                                    {getFieldDecorator('isAgree', {
                                        initialValue: this.state.isAgree,
                                        rules: [{required: false,pattern: /^(已确认)$/, message: '请输入 已确认 或 保留空值', whitespace: true},],
                                        disabled:true
                                    })(
                                        <Input  disabled={this.state.isAgree ==='已确认'} placeholder="接入能耗数据准确无误后，由业主输入“已确认”" />
                                    )}
                                    <label style={{color:'#f0897b',textAlign:'left'}}>注：业主登录温州能耗系统，查看项目分支信息，确认能耗数据符合设计要求和实际使用情况，并对数据真实性负责。业主确认后手动填写"已确认"。系统方审核无误后，方可下载接入证明</label>
                                </Form.Item>
                            </div>

                        </Col>
                    </Row>


                    <Form.Item  {...formItemLayout}
                                label=""

                    >
                        <div className='stepDiv' style={{marginLeft:'20%',}}>
                            <Steps current={this.state.steps} className='steps'  progressDot={this.customDot}  >

                                <Step title="注册" description="" />
                                <Step title="准备硬件 申请账号"    description="" />
                                <Step title="下发账号 建筑编码" description="" />
                                <Step title="数据接入 申请统计" description="" />
                                <Step title="检查状态 开通统计" description="" />
                                <Step title="7天试运行 " description="" />
                                <Step title="下发证明，结束" description="" />
                            </Steps>
                            <span style={{marginLeft:'40%',color:'#b5b0f6'}}>鼠标悬停流程节点，显示详细信息</span>
                            <span style={{marginLeft:'40%',color:'#b5b0f6'}}>获取项目最新状态，请刷新页面，输入申请编号查询</span>
                            <p style={{marginLeft:'40%',color:'#aa65f0'}}>提示：{this.infoArr(this.state.steps)}</p>
                        </div>

                    </Form.Item>

                    <Form.Item {...tailFormItemLayout}>
                        <div><span style={this.state.checkStyle}>{(this.state.checkType)?checkType[this.state.checkType]:''}</span></div>
                        <div style={this.state.editable?{display:'block',marginRight:'20%'}:{display:'none',marginRight:'20%'}}>
                        <Button className='newProjectFormButton' onClick={this.findRegisterInfo.bind(this,null)}>查询</Button>
                        <Button  htmlType="submit" className='newProjectFormButton' >注册/修改</Button>
                        <Button  onClick={this.reqAccount} className='newProjectFormButton' disabled={this.state.steps!==1?true:false}>申请账号</Button>
                        <Button  onClick={this.reqSatistics} className='newProjectFormButton' disabled={this.state.steps!==3?true:false} >申请统计</Button>
                        {/*<Button className='newProjectFormButton'>取消</Button>*/}
                        <Button className='newProjectFormButton' onClick={this.exportInfo}  disabled={this.state.steps!==6?true:false} >导出证明</Button>
                        </div>
                    </Form.Item>



                </Form>


            </div>



        );
    }
}

const NewRegisterInfoPV = Form.create({name: 'registerPV'})(NewProjectFrom);


export default NewRegisterInfoPV;
