import React from 'react';
import * as r from 'ramda'

import './indexMidRight.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import BorderLeft from '../../ui/borderLeft/borderLeft';
import Border4 from '../../ui/border4/border4';
import { timer } from 'rxjs';


class IndexMidRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            monthTime: [],
            lastMonthTime: [],
            monthData: [],
            lastMonthData: [],
            yearTime: [],
            lastYearTime: [],
            yearhData: [],
            lastYearData: [],
            notMerge: true,
            lazyUpdate: true,
            style: { width: "100%", height: "160px" },
            active: "month"
        };
    }

    async getData() {
        const month = await Api.postV3('/cost/dailyComparison', {
            project: Api.projectId,
        });
        // console.log(lastMonth)
        // console.log(year)
        let monthTime = [];
        let lastMonthTime = [];
        let monthData = [];
        let lastMonthData = [];
        if (month.data.current) {
            month.data.current.forEach(function (i, k) {
                let data = i.date.toString()
                // console.log(i.date.substr(i.date.length - 4))
                monthTime.push(r.concat(data.slice(-2), '日'))
                monthData.push((i.cost / 10000).toFixed(2))
            })
        }

        if (month.data.last) {
            month.data.last.forEach(function (i, k) {
                let data = i.date.toString()

                lastMonthTime.push(r.concat(data.slice(-2), '日'))
                lastMonthData.push((i.cost / 10000).toFixed(2))
            })
        }

        this.setState({
            monthTime,
            lastMonthTime,
            monthData,
            lastMonthData,
        })
    }
    async getDataYear() {
        const year = await Api.postV3('/cost/monthlyComparison', {
            project: Api.projectId,
        });
        let yearTime = [];
        let lastYearTime = [];
        let yearhData = [];
        let lastYearData = [];
        if (year.data.current) {
            year.data.current.forEach(function (i, k) {
                let data = i.date.toString()

                yearTime.push(r.concat(data.slice(-2), '月'))
                yearhData.push((i.cost / 10000).toFixed(2))
            })
        }

        if (year.data.last) {
            year.data.last.forEach(function (i, k) {
                let data = i.date.toString()

                lastYearTime.push(r.concat(data.slice(-2), '月'))
                lastYearData.push((i.cost / 10000).toFixed(2))
            })
        }

        this.setState({
            yearTime,
            lastYearTime,
            yearhData,
            lastYearData,
        })
    }

    componentDidMount() {

        const upDataTime = 5 * 60 * 1000;//分钟
        const source = timer(0, upDataTime);
        source.subscribe((x) => {
            this.getData();
            this.getDataYear()
        })
    }


    render() {
        const { monthTime = [],
            lastMonthTime = [],
            monthData = [],
            lastMonthData = [],
            yearTime = [],
            lastYearTime = [],
            yearhData = [],
            lastYearData = [], } = this.state;
        const getOption = {
            grid: { left: '43', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis', },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'rect'
            },

            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: r.gte(monthTime.length, lastMonthTime.length) ? monthTime : lastMonthTime
            },
            yAxis: {
                name: '单位:万',
                nameGap: 10,
                nameTextStyle: { color: '#FEFEFF' },

                axisLabel: {
                    color: '#FEFEFF'
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
            },
            series: [{
                type: 'line',
                name: '本月',
                smooth: true,
                data: monthData,
                itemStyle: {
                    normal: {
                        color: '#2DFAD8'
                    }
                },
            }, {
                type: 'line',
                name: '上月',
                smooth: true,
                data: lastMonthData,
                itemStyle: {
                    normal: {
                        color: '#FFFFFF'
                    }
                },
            }]
        }
        const getOption2 = {
            grid: { left: '43', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis', },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'rect'
            },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: r.gte(yearTime.length, lastYearTime.length) ? yearTime : lastYearTime
            },
            yAxis: {
                name: '单位:万',
                nameGap: 10,
                nameTextStyle: { color: '#FEFEFF' },
                axisLabel: {
                    color: '#FEFEFF'
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
            },
            series: [{
                type: 'line',
                name: '今年',
                smooth: true,
                data: yearhData,
                itemStyle: {
                    normal: {
                        color: '#2DFAD8'
                    }
                },

            }, {
                type: 'line',
                name: '去年',
                smooth: true,
                data: lastYearData,
                itemStyle: {
                    normal: {
                        color: '#ffffff'
                    }
                },
            }]
        }

        return (
            <div className="indexMidRightContainer">
                <BorderLeft className='mb20'>
                    <div className='indexMidRight' >
                        <div className='indexMidRightHead'>
                            <Border4 className='Angle' sideLength={'5px'}>
                                <i className='round'></i>
                            </Border4>
                            <span>月度费用</span>
                        </div>
                        <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                    </div>
                </BorderLeft>
                <BorderLeft>
                    <div className='indexMidRight' >
                        <div className='indexMidRightHead'>
                            <Border4 className='Angle' sideLength={'5px'}>
                                <i className='round'></i>
                            </Border4>
                            <span>年度费用</span>
                        </div>
                        <ReactEcharts option={getOption2} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                    </div>
                </BorderLeft>
            </div>
        )
    }
}

export default IndexMidRight;