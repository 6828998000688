import React from 'react';
import {Button, Checkbox, Col, DatePicker, Form, Input, message, Radio, Row, Select, Modal,Table} from 'antd';
import {Link} from "react-router-dom";
import * as r from 'ramda';
import moment from 'moment';
import './newRegisterInfo.scss'
import Api from 'api';
import JSZipUtils from 'jszip-utils';
import saveAs from 'file-saver';
import JsZip from 'jszip'
import wznhjcRegister from './wznhjcRegister2.docx'
import wenzhoushiPdf from './wenzhoushi_projects4.pdf'
import ImageModule from "docxtemplater-image-module-free";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
// import fsE from "fs-extra";
// import convert from "libreoffice-convert";
// import toPdf from 'office-to-pdf'
import Util2  from 'src/util/util2';

import {
    formItemLayout,
    groupFormItemLayout, joinAllModules,
    projectDefaultModules,
    projectModules,
    projectSource,
    projectType,
    rightFormItemLayout,
    rightRadioFormItemLayout,
    tailFormItemLayout
} from "src/context/project";


const {Option} = Select;
const confirm = Modal.confirm;
const {RangePicker} = DatePicker
const regionJsonConverter = Util2.regionJsonConverter;
const buildType = {
    SPORT: "体育建筑",
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
};
const strucType = {
    BRICKCONCRETE: '砖混结构',
    CONCRETE: '混凝土结构',
    IRON: '钢结构',
    WOOD: '木结构',
    OTHER: '其他',
};
const outWallType = {
    SOLIDCLAY: '实心黏土砖',
    HOLLOWCLAY: '空心黏土砖(多孔)',
    DINASBRICKS: '灰砂砖',
    AIRCONCRETE: '加气混凝土砌块',
    GLASS: '玻璃幕墙',
    OTHER: '其他',
};
const warmType = {
    INWARM: '内保温',
    OUTWARM: '外保温',
    INSIDEWARM: '夹芯保温',
    OTHER: '其他',
};
const windType = {
    SIGSIG: '单玻单层窗',
    SIGDOUB: '单玻双层窗',
    SIGSIGSIGDOUB: '单玻单层窗+单玻双层窗',
    EMPDOUB: '中空双层玻璃窗',
    EMPTHREE: '中空三层玻璃窗',
    EMPLAZY: '中空充惰性气体',
    OTHER: '其他',
};
const buildGlassType = {
    NORMAL: '普通玻璃',
    DUMO: '镀膜玻璃',
    LOWE: 'Low-E玻璃',
    OTHER: '其他',
};
const frameType = {
    IRON: '钢窗',
    ALUM: '铝合金窗',
    WOOD: '木窗',
    STOPHOT: '断热窗',
    OTHER: '其他',
};
const heatType = {
    RAD: '散热器采暖',
    FLOOR: '地板辐射采暖',
    ELECTR: '电辐射采暖',
    OTHER: '其他',
};
const airSysType = {
    RAD: '全空气系统',
    FLOOR: '风机盘管+新风系统',
    ELECTR: '分体式空调或变制冷剂流量多联式分体空调机组',
    OTHER: '其他',
};
const checkType = {
    OK: '审核完成',
    ING: '审核中',
};
// console.log(regionJsonConverter['330301']);
// 'use strict';
// import JsZip from 'jszip'
// import { saveAs } from 'file-saver'
export class DownloadUtil {

    constructor() {
        this.zip = new JsZip();
    }
    // 添加文件到zip
    addFileInZip(fileName, content, option) {
        this.zip.file(fileName, content, option)
    }
    // 添加文件夹到zip
    addFolderInZip(folderName) {
        return this.zip.folder(folderName)
    }
    // 打包成blob（二进制数据）
    packageZip2blob() {
        return this.zip.generateAsync({ type: 'blob' })
    }
    // 打包并下载(默认打包成blob)
    packageZipAndDownload(zipName, type = 'blob') {
        this.zip.generateAsync({ type }).then(content => {
            saveAs(content, zipName)
        })
    }
}
// 导出echarts图片，格式转换
const base64DataURLToArrayBuffer =(dataURL) => {
    const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
    if (!base64Regex.test(dataURL)) {
        return false;
    }
    const stringBase64 = dataURL.replace(base64Regex, "");
    let binaryString;
    if (typeof window !== "undefined") {
        binaryString = window.atob(stringBase64);
    } else {
        binaryString = new Buffer(stringBase64, "base64").toString("binary");
    }
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    return bytes.buffer;
}

const downloadProtocol = ()=>{
    saveAs(wenzhoushiPdf, "exportDocx.pdf");
}

// 从数据库中找到对应的数据然后展示
const  handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            console.log(err)
            console.log(values)

            if (!err) {
                console.log('Received values of form: ', values);
                const {	 name,
                    addr,
                    floors,
                    area,
                    headCount,
                    buildCompany,
                    buildMan,
                    buildPhone,
                    constructCompany,
                    constructMan,
                    constructPhone,
                    timeFinish,
                    regionBelong,
                    buildtype,
                    website,
                    inputType,
                    collectorName,
                    warmType,
                    windType,
                    buildGlassType,
                    frameType,
                    heatType,
                    airSysType,
                    funcLocateDomain} = values;
                // let regionName = regionJsonConverter[region];
                // let regionBelongName = regionJsonConverter[regionBelong];
                // let effectiveDays = time;
                // if (time === "自定义") {
                //     effectiveDays = timeRange[1].diff(timeRange[0], 'days')
                // }
               return;

                const res = await Api.post('/project/add', {
                    name,
                    addr,
                    floors,
                    area,
                    headCount,
                    buildCompany,
                    buildMan,
                    buildPhone,
                    constructCompany,
                    constructMan,
                    constructPhone,
                    timeFinish,
                    regionBelong,
                    buildtype,
                    website,
                    inputType,
                    collectorName,
                    warmType,
                    windType,
                    buildGlassType,
                    frameType,
                    heatType,
                    airSysType,
                    funcLocateDomain,
                })
                console.log(res.data.message)
                const msg = res.data.message === '' ? '创建成功' : res.data.message;
                message.info(msg);
                if (msg === '创建成功') {
                    this.props.history.push('/Project')
                }
            }
        });
    }

const initialValue='15888878787';
const initFlag = false;
const mobileTest='';
//                                <Step title="注册" description="" />
//                                 <Step title="准备硬件 申请账号"    description="" />
//                                 <Step title="下发账号 建筑编码" description="" />
//                                 <Step title="数据接入 申请统计" description="" />
//                                 <Step title="检查状态 开通统计" description="" />
//                                 <Step title="7天试运行 " description="" />
//                                 <Step title="下发证明，结束" description="" />



class NewProjectFrom extends React.Component {
    state = {
        confirmDirty: false,
        autoCompleteResult: [],
        // informWay:[
        //     // {funcLocate:'1',funcDomain:'1',funcHeads:'1',funcArea:'1'},//功能区位置 功能区
        //     {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        // ],
        widSpan:5,
        projectPlanId:initFlag?initialValue:'',
        name:initFlag?initialValue:'',
        addr:initFlag?initialValue:'',
        floors:initFlag?initialValue:'',
        area:initFlag?initialValue:'',
        headCount:initFlag?initialValue:'',
        buildMan:initFlag?initialValue:'',
        buildPhone:initFlag?initialValue:'',
        constructCompany:initFlag?initialValue:'',
        constructMan:initFlag?initialValue:'',
        constructPhone:initFlag?initialValue:'',
        timeFinish:initFlag?initialValue:moment(),
        regionBelong:initFlag?initialValue:'330302',
        buildtype:initFlag?initialValue:'SPORT',
        website:initFlag?initialValue:'',
        inputType:initFlag?initialValue:'',
        collectorName:initFlag?initialValue:'',
        warmType:initFlag?initialValue:'INWARM',
        windType:initFlag?initialValue:'SIGSIG',
        buildCompany:initFlag?initialValue:'',
        buildGlassType:initFlag?initialValue:'NORMAL',
        frameType:initFlag?initialValue:'IRON',
        heatType:initFlag?initialValue:'RAD',
        airSysType:initFlag?initialValue:'RAD',
        informWay:initFlag?[
            // {funcLocate:'1',funcDomain:'1',funcHeads:'1',funcArea:'1'},//功能区位置 功能区
            {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        ]:[
            {funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''},//功能区位置 功能区
        ],
        tableData:[],
        checkType:'',
        showForm:false,
    };
     columns = [
//     {
//     title:' 序号',
//     dataIndex: 'order',
//     key: 'order',
//     width: '6%',
// },
        {
            title: '建筑编号',
            dataIndex: 'projectPlanId',
            key: 'projectPlanId',
            width: '6%',
            // render: (text, record) => (
            //     <Tooltip placement="top" title={text}>
            //         <span className='tableName'>{text}</span>
            //     </Tooltip>
            // ),
        },{
            title: '项目名称',
            dataIndex: 'name',
            key: 'name',
            width: '6%',
            // render: (text, record) => (
            //     <Tooltip placement="top" title={text}>
            //         <span className='tableName'>{text}</span>
            //     </Tooltip>
            // ),
        }, {
            title: '项目类型',
            dataIndex: 'buildtype',
            key: 'buildtype',
            width: '6%',
            render: (text, record) => (text?buildType[text]:''
            ),
        }, {
            title: '面积',
            dataIndex: 'area',
            key: 'area',
            width: '6%',
            // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
            // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
            // sortDirections: ['descend'],
        },
        {
            title: '人数',
            dataIndex: 'headCount',
            key: 'headCount',
            width: '6%',

        },
        {
            title: '最后申请时间',
            dataIndex: 'lastupdate',
            key: 'lastupdate',
            width: '6%',
            render: (text, record) => (text?moment(text).format('YYYY-MM-DD'):''),
        },
        {
            title: '审核时间',
            dataIndex: 'checkDate',
            key: 'checkDate',
            width: '6%',
            render: (text, record) => (text?moment(text).format('YYYY-MM-DD'):''),
        },
        {
            title: '审核状态',
            dataIndex: 'checkType',
            key: 'checkType',
            width: '6%',
            render: (text, record) => (text?checkType[text]:''),
        },
        {
            title: '状态切换',
            dataIndex: 'opera',
            key: 'opera',
            width: '6%',
            render: (text,record) =>  <Button   onClick={()=>this.switchCheckType(record.projectPlanId,record.checkType)}>切换</Button>
        }
    ];


    componentDidMount() {
        // 隐藏对应的格式
        document.getElementById("navDiv").style.cssText="display:none";
    }

    switchCheckType = (projectPlanId,checkType)=>{


        // alert('switch');
         Api.postV3('/registerInfo2/switch', {
            projectId:'123',
            project:'123',
            projectPlanId:projectPlanId,
            checkType:(checkType==='ING')?'OK':'ING'
        }).then(res=>{message.info('切换成功');
        this.findAllRegisterInfo();
        });


    }

     showRow = (record)=>{
        // alert(record.projectPlanId);
         this.setState({showForm:true})
        this.findRegisterInfo2(record.projectPlanId,record.name);
    }

// 从数据库中找到对应的数据然后展示
     findRegisterInfo = ()=>{
         const fromData = this.props.form.getFieldValue('projectPlanId');
         const nameData = this.props.form.getFieldValue('name');
         if(!fromData&&!nameData){
             message.info('请输入预编建筑编号进行查询');
         }

         // alert(fromData);
         console.log('--- to find the plan');
         if(fromData || nameData){
             const res =  Api.postV3('/registerInfo2/get', {
                 projectId:'123',
                 project:'123',
                 projectPlanId:fromData,
                 name:nameData,
             }).then(x=>{
                 if(x.data && x.data.name){
                     // message.info('已经存在项目 ' + x.data.name);
                     let data = x.data;
                     let dataJSON = {
                         projectPlanId:data.projectPlanId,
                         name:data.name,
                         addr:data.addr,
                         area:data.area,
                         floors:data.floors,
                         headCount:data.headCount,
                         buildMan:data.buildMan,
                         buildPhone:data.buildPhone,
                         buildCompany:data.buildCompany,
                         constructCompany:data.constructCompany,
                         constructMan:data.constructMan,
                         constructPhone:data.constructPhone,
                         timeFinish:data.timeFinish?moment(data.timeFinish,'YYYY-MM-DD'):moment(),
                         regionBelong:data.regionBelong,
                         regionBelongExport:data.regionBelong?regionJsonConverter[data.regionBelong]:'',
                         buildtype:data.buildtype,
                         buildtypeExport:data.buildtype?buildType[data.buildtype]:'',
                         website:data.website,
                         inputType:data.inputType,
                         inputTypeExport:data.inputType===1?'采集器接入':'平台接入',
                         collectorName:data.collectorName,
                         warmType:data.warmType,
                         warmTypeExport:data.warmType?warmType[data.warmType]:'',
                         windType:data.windType,
                         windTypeExport:data.windType?windType[data.windType]:'',
                         buildGlassType:data.buildGlassType,
                         buildGlassTypeExport:data.buildGlassType?buildGlassType[data.buildGlassType]:'',
                         frameType:data.frameType,
                         frameTypeExport:data.frameType?frameType[data.frameType]:'',
                         heatType:data.heatType,
                         heatTypeExport:data.heatType?heatType[data.heatType]:'',
                         airSysType:data.airSysType,
                         airSysTypeExport:data.airSysType?airSysType[data.airSysType]:'',
                         informWay:data.funcLocateDomain?data.funcLocateDomain:[{funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''}],//功能区位置 功能区],
                         informWayExport:{"informWay":[{funcLocate:'5',funcDomain:'5',funcHeads:'',funcArea:''}]},//功能区位置 功能区],
                         checkType:data.checkType?checkType[data.checkType]:''
                     };
                this.setState(dataJSON);
                // this.exportWord(dataJSON);
                 }else{
                     message.info('没有找到对应建筑编号的项目');

                 }
                 console.log(x)});
         }
        // saveAs(wenzhoushiPdf, "exportDocx.pdf");
    }
     findRegisterInfo2 = (fromData,nameData)=>{
         // const fromData = this.props.form.getFieldValue('projectPlanId');
         // const nameData = this.props.form.getFieldValue('name');
         if(!fromData&&!nameData){
             message.info('请输入预编建筑编号进行查询');
         }

         // alert(fromData);
         console.log('--- to find the plan');
         if(fromData || nameData){
             const res =  Api.postV3('/registerInfo2/get', {
                 projectId:'123',
                 project:'123',
                 projectPlanId:fromData,
                 name:nameData,
             }).then(x=>{
                 if(x.data && x.data.name){
                     // message.info('已经存在项目 ' + x.data.name);
                     let data = x.data;
                     let dataJSON = {
                         projectPlanId:data.projectPlanId,
                         name:data.name,
                         addr:data.addr,
                         area:data.area,
                         floors:data.floors,
                         headCount:data.headCount,
                         buildMan:data.buildMan,
                         buildPhone:data.buildPhone,
                         buildCompany:data.buildCompany,
                         constructCompany:data.constructCompany,
                         constructMan:data.constructMan,
                         constructPhone:data.constructPhone,
                         timeFinish:data.timeFinish?moment(data.timeFinish,'YYYY-MM-DD'):moment(),
                         regionBelong:data.regionBelong,
                         regionBelongExport:data.regionBelong?regionJsonConverter[data.regionBelong]:'',
                         buildtype:data.buildtype,
                         buildtypeExport:data.buildtype?buildType[data.buildtype]:'',
                         website:data.website,
                         inputType:data.inputType,
                         inputTypeExport:data.inputType===1?'采集器接入':'平台接入',
                         collectorName:data.collectorName,
                         warmType:data.warmType,
                         warmTypeExport:data.warmType?warmType[data.warmType]:'',
                         windType:data.windType,
                         windTypeExport:data.windType?windType[data.windType]:'',
                         buildGlassType:data.buildGlassType,
                         buildGlassTypeExport:data.buildGlassType?buildGlassType[data.buildGlassType]:'',
                         frameType:data.frameType,
                         frameTypeExport:data.frameType?frameType[data.frameType]:'',
                         heatType:data.heatType,
                         heatTypeExport:data.heatType?heatType[data.heatType]:'',
                         airSysType:data.airSysType,
                         airSysTypeExport:data.airSysType?airSysType[data.airSysType]:'',
                         informWay:data.funcLocateDomain?data.funcLocateDomain:[{funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''}],//功能区位置 功能区],
                         informWayExport:{"informWay":[{funcLocate:'5',funcDomain:'5',funcHeads:'',funcArea:''}]},//功能区位置 功能区],
                         checkType:data.checkType?checkType[data.checkType]:''

                     };
                this.setState(dataJSON);
                // this.exportWord(dataJSON);
                 }else{
                     message.info('没有找到对应建筑编号的项目');

                 }
                 console.log(x)});
         }
        // saveAs(wenzhoushiPdf, "exportDocx.pdf");
    }

    //获取所有的申请数据
    // 从数据库中找到对应的数据然后展示
    findAllRegisterInfo = ()=>{


        // alert(fromData);
        console.log('--- to find the plan');
            const res =  Api.postV3('/registerInfo2/getAll', {
                projectId:'123',
                project:'123',
            }).then(x=>{
                if(x.data){
                    // message.info('已经存在项目 ' );
                    let data = x.data;
                    this.setState({tableData:data})
                    // this.exportWord(dataJSON);
                }else{
                    message.info('没有找到项目');

                }
                console.log(x)});

    }


    // 导出文档
     exportWord = (dataJSon)=>{
        var _this = this;
        console.log('click the export word button : ------ ');
        // 点击导出word
        function replaceErrors(key, value) {
            if (value instanceof Error) {
                return Object.getOwnPropertyNames(value).reduce(function(error, key) {
                    error[key] = value[key];
                    return error;
                }, {});
            }
            return value;
        }

        function errorHandler(error) {
            console.log(JSON.stringify({error: error}, replaceErrors));

            if (error.properties && error.properties.errors instanceof Array) {
                const errorMessages = error.properties.errors.map(function (error) {
                    return error.properties.explanation;
                }).join("\n");
                console.log('errorMessages', errorMessages);
                // errorMessages is a humanly readable message looking like this :
                // 'The tag beginning with "foobar" is unopened'
            }
            throw error;
        }

// image start ---
        var opts = {}
        opts.centered = false; //Set to true to always center images
        opts.fileType = "docx"; //Or pptx


// 导出echarts图片，格式转换
        const base64DataURLToArrayBuffer =(dataURL) => {
            const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
            if (!base64Regex.test(dataURL)) {
                return false;
            }
            const stringBase64 = dataURL.replace(base64Regex, "");
            let binaryString;
            if (typeof window !== "undefined") {
                binaryString = window.atob(stringBase64);
            } else {
                binaryString = new Buffer(stringBase64, "base64").toString("binary");
            }
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                const ascii = binaryString.charCodeAt(i);
                bytes[i] = ascii;
            }
            return bytes.buffer;
        }

//Pass your image loader
// opts.getImage = function(tagValue, tagName) {
//     //tagValue is 'examples/image.png'
//     //tagName is 'image'
//     return fs.readFileSync(tagValue);
// }
        opts.getImage = function(chartId){
            return base64DataURLToArrayBuffer(chartId);
        }


//Pass the function that return image size
        opts.getSize = function(img, tagValue, tagName,widX,widY) {
            //img is the image returned by opts.getImage()
            //tagValue is 'examples/image.png'
            //tagName is 'image'
            //tip: you can use node module 'image-size' here
            return [widX?widX:150, widY?widY:150];
        }

        var imageModule = new ImageModule(opts);

// image end -----




//Load the docx file as a binary
//         var content = fs
//             .readFileSync('src/reportTemplates/wenzhoushi_projects.docx', 'binary');
        //Load the docx file as a binary
        // var content =fs
        //     .readFile(path.resolve(__dirname, 'wenzhoushi_projectsdocx'), 'binary');
        // var content = JSZipUtils.getBinaryContent("../../../../static/docTemplate/exportTemplate.docx",function(error, content) {
        //
        // })
        // var content = Fse.read(path.resolve(__dirname, 'tag-example.docx'), 'binary');
        JSZipUtils.getBinaryContent(wznhjcRegister,function(error, content) {
            // exportTemplate.docx是模板。我们在导出的时候，会根据此模板来导出对应的数据
            // 抛出异常
            if (error) {
                throw error;
            }
            // 图片处理
            let opts = {}
            opts.centered = true;  // 图片居中，在word模板中定义方式为{%%image}
            opts.fileType = "docx";
            opts.getImage = function(chartId){
                return base64DataURLToArrayBuffer(chartId);
            }
            opts.getSize = function(){
                return [200,200]
            }
            let imageModule = new ImageModule(opts);
            // 创建一个PizZip实例，内容为模板的内容
            let zip = new PizZip(content);
            // 创建并加载docxtemplater实例对象
            let doc = new Docxtemplater();
            // doc.attachModule(imageModule);
            doc.loadZip(zip);

            // 设置模板变量的值
            // doc.setData({
            //     // table: _this.tableData,
            //     first_name: 'John',
            //     last_name: 'Doe',
            //     phone: '0652455478',
            //     description: 'New Website',
            //     // type_builds:_this.state.echart_type.getDataURL(), // 获取echarts图片
            //     // region_builds:_this.state.echart_region.getDataURL(), // 获取echarts图片
            //     // energy_map:_this.state.mychart.getDataURL() // 获取echarts图片
            //     // table:tableData.toString(),
            //
            //
            // });
            doc.setData(dataJSon);

            try {
                // 用模板变量的值替换所有模板变量
                doc.render();
            } catch (error) {
                // 抛出异常
                let e = {
                    message: error.message,
                    name: error.name,
                    stack: error.stack,
                    properties: error.properties
                };
                throw error;
            }
            // 生成一个代表docxtemplater对象的zip文件（不是一个真实的文件，而是在内存中的表示）
            let out = doc.getZip().generate({
                type: "blob",
                mimeType:"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            });
            // 将目标文件对象保存为目标类型的文件，并命名
            saveAs(out, "exportRegisterDocx.docx");
        });
    }



    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            console.log(err)
            console.log(values)

            if (!err) {
                confirm({
                    title: '请确认填写的信息准确无误，是否提交？',
                    content: <div><p>  </p> </div>,
                    onOk() {
                        console.log('Received values of form: ', values);
                        const {	 name,
                            projectPlanId,
                            checkType,
                            addr,
                            floors,
                            area,
                            headCount,
                            buildCompany,
                            buildMan,
                            buildPhone,
                            constructCompany,
                            constructMan,
                            constructPhone,
                            timeFinish,
                            regionBelong,
                            buildtype,
                            website,
                            inputType,
                            collectorName,
                            warmType,
                            windType,
                            buildGlassType,
                            frameType,
                            heatType,
                            airSysType,
                            funcLocateDomain,} = values;
                        let regionBelongName = regionJsonConverter[regionBelong];
                        // const modules = joinAllModules(web, admin, analysis, extra)
                        const  testFunc = /^(funcLocate_)\d$/;
                        var funcLocateDomainArr=[];
                        var funcLocate={};
                        var funcDomain={};
                        var funcHeads={};
                        var funcArea={};
                        for(var key in values){
                            if(testFunc.test(key)){
                                //检查funcLocate和funcDomain是否存在空的情况
                                var index = key.lastIndexOf('_');
                                var num = key.substr(index+1,key.length);
                                if(num && values[key] && values['funcDomain_'+num] && values['funcHeads_'+num] && values['funcArea_'+num]){
                                    // funcLocate[key]=allValues[key];
                                    // funcDomain['funcDomain_'+num]=allValues['funcDomain_'+num];
                                    // funcHeads['funcHeads_'+num]=allValues['funcHeads_'+num];
                                    // funcArea['funcArea_'+num]=allValues['funcArea_'+num];
                                    funcLocateDomainArr.push({funcLocate:values[key],
                                        funcDomain:values['funcDomain_'+num],
                                        funcHeads:values['funcHeads_'+num],
                                        funcArea:values['funcArea_'+num],

                                    });
                                }
                            }
                        }



                        return Api.postV3('/registerInfo2/add', {
                            projectId:'123',
                            project:'123',
                            projectPlanId:projectPlanId,
                            name,
                            checkType,
                            addr,
                            floors,
                            area,
                            headCount,
                            buildCompany,
                            buildMan,
                            buildPhone,
                            constructCompany,
                            constructMan,
                            constructPhone,
                            timeFinish,
                            regionBelong,
                            buildtype,
                            website,
                            inputType,
                            collectorName,
                            warmType,
                            windType,
                            buildGlassType,
                            frameType,
                            heatType,
                            airSysType,
                            funcLocateDomain:funcLocateDomainArr,
                            regionBelongName,
                        }).then(res=>{message.info('创建成功')});
                        // console.log(res.data)
                        // const msg = res.data === 'ok' ? '创建成功' : res.data;
                        // message.info(msg);
                        // if (msg === '创建成功') {
                        //     this.props.history.push('/Project')
                        // }
                    },
                    onCancel() {
                        return;
                    },
                });


            }
        });
    }

    handleConfirmBlur = (e) => {
        const value = e.target.value;
        this.setState({confirmDirty: this.state.confirmDirty || !!value});
    }

    compareToFirstPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('Two passwords that you enter is inconsistent!');
        } else {
            callback();
        }
    }

    validateToNextPassword = (rule, value, callback) => {
        const form = this.props.form;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], {force: true});
        }
        callback();
    }

    handleWebsiteChange = (value) => {
        let autoCompleteResult;
        if (!value) {
            autoCompleteResult = [];
        } else {
            autoCompleteResult = ['.com', '.org', '.net'].map(domain => `${value}${domain}`);
        }
        this.setState({autoCompleteResult});
    }
     onLinkWay = (index, cmd)=>{
        console.log(index + '|' + cmd);
        var informWay = this.state.informWay;
         const form = this.props.form;
        if('add' == cmd){
            informWay.push({funcLocate:'',funcDomain:'',funcHeads:'',funcArea:''});
        }
        if('remove' == cmd){
            if(informWay.length>=1){
                informWay.splice(index, 1);

            }
        }
        console.log('informWay ---- ');
        console.log(informWay);
        var i = 0;
        r.map(y=>{
            form.setFieldsValue({['funcLocate_'+i]:informWay[i].funcLocate});
            form.setFieldsValue({['funcDomain_'+i]:informWay[i].funcDomain});
            form.setFieldsValue({['funcHeads_'+i]:informWay[i].funcHeads});
            form.setFieldsValue({['funcArea_'+i]:informWay[i].funcArea});
            i++;
        })(informWay);
        this.setState({informWay:informWay});
    }
     dealInformWay = (type,index,value)=>{
        console.log('dealINformWay---');
        console.log(type + '|' + index + '|' + value);
         var informWay = this.state.informWay;
         const form = this.props.form;
        informWay[index][type]=value;
        console.log(informWay);
         this.setState({informWay:informWay});
        // setInformWay([...informWay]);
    }


    render() {
        const {getFieldDecorator, getFieldValue} = this.props.form;
        const prefixSelector = getFieldDecorator('prefix', {
            initialValue: '86',
        })(
            <Select style={{width: 70}}>
                <Option value="86">+86</Option>
                <Option value="87">+87</Option>
            </Select>
        );
        const disabledDate = (current) => {
            return current && current < moment().subtract(1, 'days');
        }
        console.log('modules', r.toPairs(r.propOr({})('web')(projectModules)))
        return (<div>asdfad</div>);
        // return (
        //     <div className="newRegisterInfo">
        //         <h2 style={{color:'#abb1ac'}}>温州市能耗监测系统项目接入申请表(审核)</h2>
        //         <Form onSubmit={this.handleSubmit} className={this.state.showForm?'registerAdminOpen':'registerAdminClose'}>
        //             <Row>
        //                 <Col span={10}>
        //                     <div className="">
        //                         <h4>添加建筑信息</h4>
        //                         <Form.Item {...formItemLayout}
        //                                    label="预编建筑编号"
        //                         >
        //                             {getFieldDecorator('projectPlanId', {
        //                                 rules: [{
        //                                     required: true, message: '项目名称不能为空',
        //                                 }],
        //                                 initialValue: this.state.projectPlanId,
        //                                 // initialValue: Math.floor(100000000+10000000000000*Math.random()),
        //                             })(
        //                                 <Input placeholder="请输入8位以上编号"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="建筑名称"
        //                         >
        //                             {getFieldDecorator('name', {
        //                                 rules: [{
        //                                     required: true, message: '项目名称不能为空',
        //                                 }],
        //                                 initialValue: this.state.name,
        //                             })(
        //                                 <Input placeholder="请输入项目名称"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="建筑地址"
        //                         >
        //                             {getFieldDecorator('addr', {
        //                                 rules: [{
        //                                     required: true, message: '建筑地址不能为空',
        //                                 }],initialValue: this.state.addr,
        //                             })(
        //                                 <Input placeholder="请输入建筑地址"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="楼层数(地上+地下)"
        //                         >
        //                             {getFieldDecorator('floors', {
        //                                 rules: [{
        //                                     required: true,
        //                                     message:'只能输入数字',
        //                                     pattern: /^[0-9]+$/
        //                                 }],initialValue: this.state.floors,
        //                             })(
        //                                 <Input placeholder="请输入建筑层数地下"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="总建筑面积(m²)"
        //                         >
        //                             {getFieldDecorator('area', {
        //                                 rules: [{
        //                                     required: true,
        //                                     message:'只能输入数字',
        //                                     pattern: /^[0-9]+$/
        //                                 }],initialValue: this.state.area,
        //                             })(
        //                                 <Input placeholder="请输入总建筑面积"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="使用人数"
        //                         >
        //                             {getFieldDecorator('headCount', {
        //                                 rules: [{
        //                                     // required: true,
        //                                     message:'只能输入数字',
        //                                     pattern: /^[0-9]+$/
        //                                 }],
        //                                 initialValue: this.state.headCount,
        //                             })(
        //                                 <Input placeholder="总建筑人数"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label='建设单位'
        //                         >
        //                             {getFieldDecorator('buildCompany', {
        //                                 rules: [{required: true, message: '建设单位不能为空', whitespace: true},],
        //                                 initialValue: this.state.buildCompany,
        //                             })(
        //                                 <Input  placeholder="请输入建设单位"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label='建设单位联系人'
        //                         >
        //                             {getFieldDecorator('buildMan', {
        //                                 rules: [{required: true, message: '建设单位联系人不能为空', whitespace: true},],
        //                                 initialValue: this.state.buildMan,
        //                             })(
        //                                 <Input  placeholder="请输入建设单位联系人"/>
        //                             )}
        //                         </Form.Item>
        //
        //
        //
        //                         <Form.Item {...formItemLayout}
        //                                    label="建设单位联系电话"
        //                         >
        //                             {getFieldDecorator('buildPhone', {
        //                                 rules: [{required: true, message: '联系电话不能为空'}, {
        //                                     // 由于工信部放号段不定时，所以建议使用泛解析
        //                                     pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
        //                                 }],
        //                                 initialValue: this.state.buildPhone,
        //                             })(
        //                                 <Input style={{width: '100%'}}/>
        //                             )}
        //                         </Form.Item>
        //
        //                         <Form.Item {...formItemLayout}
        //                                    label='施工单位'
        //                         >
        //                             {getFieldDecorator('constructCompany', {
        //                                 rules: [{required: true, message: '施工单位不能为空', whitespace: true},],
        //                                 initialValue: this.state.constructCompany,
        //                             })(
        //                                 <Input  placeholder="请输入施工单位"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label='施工单位联系人'
        //                         >
        //                             {getFieldDecorator('constructMan', {
        //                                 rules: [{required: true, message: '施工单位联系人不能为空', whitespace: true},],
        //                                 initialValue: this.state.constructMan,
        //                             })(
        //                                 <Input  placeholder="请输入施工单位联系人"/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="施工单位联系电话"
        //                         >
        //                             {getFieldDecorator('constructPhone', {
        //                                 rules: [{required: true, message: '联系电话不能为空'}, {
        //                                     // 由于工信部放号段不定时，所以建议使用泛解析
        //                                     pattern: /^([1][3,4,5,6,7,8,9])\d{9}$/, message: '请输入正确的手机号'
        //                                 }],
        //                                 initialValue: this.state.constructPhone,
        //                             })(
        //                                 <Input style={{width: '100%'}}/>
        //                             )}
        //                         </Form.Item>
        //
        //
        //                     </div>
        //                 </Col>
        //                 <Col span={14}>
        //                     <div className="">
        //                         {/*<h4>模块管理</h4>*/}
        //
        //                         <Form.Item {...formItemLayout}
        //                                    label="竣工时间"
        //                         >
        //                             {getFieldDecorator('timeFinish', {
        //                                 initialValue: this.state.timeFinish,
        //                                 rules: [{  required: true, message: '请选择时间!' }]
        //                             })(
        //                                 <DatePicker  style={{width:'100%'}} />
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="归属区域"
        //                         >
        //                             {getFieldDecorator('regionBelong', {
        //                                 rules: [{
        //                                     required: true
        //                                 }],
        //                                 initialValue: this.state.regionBelong,
        //
        //                             })(
        //                                 <Select>
        //                                     {/*<Option value={0}>不可查看</Option>*/}
        //                                     {/*<Option value={330300}>温州市</Option>*/}
        //                                     <Option value={"330301"}>市辖区</Option>
        //                                     <Option value={"330302"}>鹿城区</Option>
        //                                     <Option value={"330303"}>龙湾区</Option>
        //                                     <Option value={"330304"}>瓯海区</Option>
        //                                     <Option value={"330305"}>洞头区</Option>
        //                                     <Option value={"330324"}>永嘉县</Option>
        //                                     <Option value={"330326"}>平阳县</Option>
        //                                     <Option value={"330327"}>苍南县</Option>
        //                                     <Option value={"330328"}>文成县</Option>
        //                                     <Option value={"330329"}>泰顺县</Option>
        //                                     <Option value={"330371"}>温州高新区(经开区)</Option>
        //                                     <Option value={"330381"}>瑞安市</Option>
        //                                     <Option value={"330382"}>乐清市</Option>
        //                                     <Option value={"330354"}>温州生态园</Option>
        //                                     <Option value={"330355"}>瓯江口</Option>
        //                                     <Option value={"330383"}>龙港市</Option>
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="建筑类型"
        //                         >
        //                             {getFieldDecorator('buildtype', {
        //                                 rules: [{
        //                                     required: true, message: '建筑类型不能为空',
        //                                 }],
        //                                 // initialValue: 'SPORT',
        //                                 initialValue: this.state.buildtype,
        //                             })(
        //                                 <Select placeholder="请选择建筑类型">
        //                                     {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(buildType))}
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="项目描述"
        //                         >
        //                             {getFieldDecorator('website', {
        //                                 rules: [{required: true, message: '项目描述不能为空'}],
        //                                 initialValue: this.state.website,
        //                             })(
        //                                 <Input.TextArea/>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="接入方式"
        //                         >
        //                             {getFieldDecorator('inputType', {
        //                                 rules: [{
        //                                     required: true
        //                                 }],
        //                                 initialValue: this.state.inputType,
        //
        //                             })(
        //                                 <Select>
        //                                     <Option value={1}>采集器接入</Option>
        //                                     <Option value={2}>平台接入</Option>
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="采集器类型(采集器接入选填)"
        //                         >
        //                             {getFieldDecorator('collectorName', {
        //                                 initialValue: this.state.collectorName,
        //                             })(
        //                                 <Input placeholder="请输入采集器类型" />
        //                             )}
        //                         </Form.Item>
        //
        //                         <Form.Item {...formItemLayout}
        //                                    label="外墙保温形式"
        //                         >
        //                             {getFieldDecorator('warmType', {
        //                                 rules: [{
        //                                     // required: true,
        //                                     message: '外墙保温形式不能为空',
        //                                 }],
        //                                 // initialValue: 'INWARM',
        //                                 initialValue: this.state.warmType,
        //                             })(
        //                                 <Select placeholder="请选择外墙保温形式">
        //                                     {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(warmType))}
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="外窗类型"
        //                         >
        //                             {getFieldDecorator('windType', {
        //                                 rules: [{
        //                                     // required: true,
        //                                     message: '外窗类型不能为空',
        //                                 }],
        //                                 // initialValue: 'SIGSIG',
        //                                 initialValue: this.state.windType,
        //                             })(
        //                                 <Select placeholder="请选择外窗类型">
        //                                     {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(windType))}
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="玻璃类型"
        //                         >
        //                             {getFieldDecorator('buildGlassType', {
        //                                 rules: [{
        //                                     // required: true,
        //                                     message: '玻璃类型不能为空',
        //                                 }],
        //                                 // initialValue: 'NORMAL',
        //                                 initialValue: this.state.buildGlassType,
        //                             })(
        //                                 <Select placeholder="请选择玻璃类型">
        //                                     {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(buildGlassType))}
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="窗框材料类型"
        //                         >
        //                             {getFieldDecorator('frameType', {
        //                                 rules: [{
        //                                     // required: true,
        //                                     message: '窗宽材料类型不能为空',
        //                                 }],
        //                                 // initialValue: 'IRON',
        //                                 initialValue: this.state.frameType,
        //                             })(
        //                                 <Select placeholder="请选择窗框材料类型">
        //                                     {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(frameType))}
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="采暖形式"
        //                         >
        //                             {getFieldDecorator('heatType', {
        //                                 rules: [{
        //                                     // required: true,
        //                                     message: '采暖形式不能为空',
        //                                 }],
        //                                 // initialValue: 'RAD',
        //                                 initialValue: this.state.heatType,
        //                             })(
        //                                 <Select placeholder="请选择采暖形式">
        //                                     {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(heatType))}
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //                         <Form.Item {...formItemLayout}
        //                                    label="空调形式"
        //                         >
        //                             {getFieldDecorator('airSysType', {
        //                                 rules: [{
        //                                     // required: true,
        //                                     message: '空调形式不能为空',
        //                                 }],
        //                                 // initialValue: 'RAD',
        //                                 initialValue: this.state.airSysType,
        //                             })(
        //                                 <Select placeholder="请选择空调形式">
        //                                     {r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(airSysType))}
        //                                 </Select>
        //                             )}
        //                         </Form.Item>
        //
        //                         <Form.Item {...formItemLayout}
        //                                    label="楼层部门信息">
        //                             <div>
        //                                 <div style={{width:'21%' , float:'left'}} > <span style={{fontSize:'20px',color:'#9cb1bb'}}>楼层</span></div>
        //                                 <div style={{width:'21%' , float:'left'}} > <span style={{fontSize:'20px',color:'#9cb1bb'}}>部门</span></div>
        //                                 <div style={{width:'21%' , float:'left'}} > <span style={{fontSize:'20px',color:'#9cb1bb'}}>人数</span></div>
        //                                 <div style={{width:'21%' , float:'left'}} > <span style={{fontSize:'20px',color:'#9cb1bb'}}>面积</span></div>
        //                                 {/*<span style={{width:'45%'}}><text style={{fontSize:'20px',color:'#9cb1bb'}}>楼层</text></span>*/}
        //                                 {/*<span style={{width:'45%'}}><text style={{fontSize:'20px',color:'#9cb1bb'}}>楼层</text></span>*/}
        //                             </div>
        //                             <div className="informWay" >
        //
        //                                 {/*{ project.informWay && project.informWay.map((element, index) => (*/}
        //                                 { this.state.informWay && this.state.informWay.map((element, index) => (
        //                                     <span key={index} style={{width:'100%'}}>
			// 				<Col span={this.state.widSpan}>
			// 					    <Form.Item  >
			// 						{getFieldDecorator(`funcLocate_${index}`, {
        //                                 initialValue: element.funcLocate,
        //                                 rules: [{
        //                                     required: true,
        //                                     message: '请输入楼层',
        //                                 }],
        //                             })(
        //                                 <Input placeholder="请输入楼层"  onChange={e => {
        //                                     this.dealInformWay('funcLocate',index,e.target.value);}} />,
        //                             )}
			// 						</Form.Item>
			// 				</Col>
        //
			// 				<Col span={this.state.widSpan}>
			// 					   <Form.Item >
			// 						{getFieldDecorator(`funcDomain_${index}`, {
        //                                 initialValue: element.funcDomain,
        //                                 // initialValue: '23',
        //                                 rules: [{
        //                                     required: true,
        //                                     message: '请输入部门',
        //                                 }],
        //                             })(
        //                                 <Input   placeholder= '请输入部门'  onChange={e => {
        //                                     this.dealInformWay('funcDomain',index,e.target.value);}}/>,
        //                             )}
			// 						</Form.Item>
			// 					</Col>
			// 				<Col span={this.state.widSpan}>
			// 					   <Form.Item >
			// 						{getFieldDecorator(`funcHeads_${index}`, {
        //                                 initialValue: element.funcHeads,
        //                                 // initialValue: '23',
        //                                 rules: [{
        //                                     required: true,
        //                                     message: '请输入人数',
        //                                 }],
        //                             })(
        //                                 <Input   placeholder= '请输入人数'  onChange={(e) => this.dealInformWay('funcHeads',index,e.target.value)}/>,
        //                             )}
			// 						</Form.Item>
			// 					</Col>
			// 				<Col span={this.state.widSpan}>
			// 					   <Form.Item >
			// 						{getFieldDecorator(`funcArea_${index}`, {
        //                                 initialValue: element.funcArea,
        //                                 // initialValue: '23',
        //                                 rules: [{
        //                                     required: true,
        //                                     message: '请输入面积',
        //                                 }],
        //                             })(
        //                                 <Input   placeholder= '请输入面积'  onChange={e => {
        //                                     this.dealInformWay('funcArea',index,e.target.value);}}/>,
        //                             )}
			// 						</Form.Item>
			// 					</Col>
        //
			// 				<Col span={3} offset={1} className='operaCol'>
			// 					{index < 10&& <Button shape="circle" size="small" icon="plus" type="primary"  onClick={() => this.onLinkWay(index, 'add')} className='operaButtom'/>}
        //                         {index >=0 && <Button shape="circle" size="small" icon="minus" type="default"  onClick={() => this.onLinkWay(index, 'remove')}  className='operaButtom' />}
			// 				</Col>
        //                  </span>
        //
        //                                 ))}
        //                             </div>
        //
        //                         </Form.Item>
        //                         {/*<Form.Item {...formItemLayout}*/}
        //                                    {/*label="审核状态"*/}
        //                         {/*>*/}
        //                             {/*{getFieldDecorator('checkType', {*/}
        //                                 {/*rules: [{*/}
        //                                     {/*// required: true,*/}
        //                                     {/*message: '审核状态不能为空',*/}
        //                                 {/*}],*/}
        //                                 {/*// initialValue: 'RAD',*/}
        //                                 {/*initialValue: this.state.checkType,*/}
        //                             {/*})(*/}
        //                                 {/*<Select placeholder="请选择审核状态">*/}
        //                                     {/*{r.map(([v, k]) => <Option value={v} key={v}>{k}</Option>)(r.toPairs(checkType))}*/}
        //                                 {/*</Select>*/}
        //                             {/*)}*/}
        //                         {/*</Form.Item>*/}
        //                     </div>
        //                 </Col>
        //             </Row>
        //             <Form.Item {...tailFormItemLayout}>
        //                 {/*<Button type="primary" htmlType="submit" className='newProjectFormButton'>确认</Button>*/}
        //                 {/*<Button className='newProjectFormButton'><Link to='/Project'>取消</Link></Button>*/}
        //                 <Button type="primary" htmlType="submit" className='newProjectFormButton' >确认</Button>
        //                 {/*<Button className='newProjectFormButton'>取消</Button>*/}
        //                 <Button className='newProjectFormButton' onClick={this.findRegisterInfo}>查询</Button>
        //                 <Button className='newProjectFormButton' onClick={downloadProtocol} style={{width:'10%'}}>下载接入标准</Button>
        //             </Form.Item>
        //         </Form>
        //
        //         <Button className='newProjectFormButton' onClick={this.findAllRegisterInfo}>查询全部</Button>
        //
        //         <Table
        //             columns={this.columns}
        //             dataSource={this.state.tableData}
        //             scroll={{y: 710}}
        //             pagination={false}
        //             // loading={loading}
        //             onRow={(record)=>{
        //                 return{
        //                     onClick:event=>{
        //                         this.showRow(record);
        //                     }
        //                 }
        //             }}
        //             rowKey={record => record.projectPlanId}
        //             className='reportRightTableTabAvg'/>
        //
        //
        //
        //     </div>
        // );
    }
}

const NewRegisterInfo = Form.create({name: 'register'})(NewProjectFrom);


export default NewRegisterInfo;
