import React, {useState, useEffect} from 'react';
import {Modal, Radio, DatePicker,Spin} from 'antd';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import * as r from 'ramda';

import './modalEcharts.scss';
import Api from 'api';
import {of} from "rxjs/index";

const sleep = time => {
    return new Promise(resolve => setTimeout(resolve,time))
}

const echartsData = {
    color: ["#77d3f6", "#D3D7E3"],
    grid: {left: '20', top: '25', right: '20', bottom: '22'},
    tooltip: {trigger: 'axis'},
    legend: {},
    xAxis: {
        type: 'category'
    },
    yAxis: {
        show: false,
        type: 'value'
    },
    series: [{
        type: 'bar',
        name: '今年',
        smooth: true,
        data: []
    }]
}

const timeFormat = (v) => {
    const typeMap = {
        DAY: 'HH:00',
        WEEK: 'dddd',
        MONTH: 'DD[日]',
        YEAR: 'Mo',
    }
    return r.propOr(v)(v)(typeMap)
}

const ModalEcharts = (props) => {
    const {visible, record: {allInformation: {channels, title}},recordDays,devicetype} = props;
    const channelsKey = r.isEmpty(r.keys(channels)) ? '' : r.keys(channels)[0];
    const channelButtom = r.map(v => r.pick(['id', 'channel'])(v))(r.values(channels))
    const id = r.pathOr([])([channelsKey, 'id'])(channels)
    console.log(channelButtom)
    const [echarData, setEcharData] = useState(echartsData);
    const [isBusy, setIsBusy] = useState(false);
    const [rangeDate, setRangeDate] = useState({start:'202111',end:'202112'});
    const [dataState, setDataState] = useState({
        channelId: id,
        timeType: 'DAY',
        dataType: 'difference',
        echartType: 'bar',
        date: moment().format('YYYYMMDD')
    });
    const [loading,setLoading]=useState(false);

    useEffect(() => {
        console.log('recordDays ----- ');
        console.log(recordDays);
        if (visible) {
            updata(dataState)
            // getRecordData();
        }

    }, [dataState, visible])
    useEffect(() => {
        if (visible) {
            setDataState({...dataState, channelId: id})
        }
    }, [id])

    const getRecordData = async ()=>{
        //开始请求数据记录，请求到之后就可以
        console.log('get RecordData -- ');
        console.log(devicetype);
        console.log(channels);
        console.log(title);
    }

    const updata = async ({timeType, dataType, echartType, date, channelId = channelButtom[0].id}) => {
        console.log('begin to update data --- ');
        setLoading(true);
        const {data: {result: {detail = []}}} = await Api.post('/business/channeldetail', {
            from: date,
            id: channelId || id,
            timeformat: timeType,
            to: date,
            projectId:Api.projectId
        })

        const color = {
            lineStyle: {
                color: '#d9d9d9',
            }
        }
        setEcharData(
            {
                color: ["#77d3f6", "#D3D7E3"],
                grid: {left: '10', top: '25', right: '20', bottom: '22', containLabel: true},
                tooltip: {trigger: 'axis'},
                legend: {
                    show: false
                },
                xAxis: {
                    type: 'category',
                    axisLine: color,
                    axisTick: color,
                    axisLabel: color,
                    splitLine: color,
                    data: r.values(r.map(v => (moment(v.timepoint).format(timeFormat(timeType))))(detail))
                },
                yAxis: {
                    show: false,
                    type: 'value'
                },
                series: [{
                    type: echartType,
                    name: title,
                    smooth: true,
                    data: r.values(r.map(v => (dataType === 'difference' ? v.value : v.total))(detail)),
                    label: {
                        show: true,
                        position: 'top'
                    }
                }]

            }
        )
        await sleep(1000).then(()=> {
            setLoading(false);
        })



    }


    const channelId = e => {
        setDataState({...dataState, channelId: e.target.value})
    }
    const timeType = e => {

        setDataState({...dataState, timeType: e.target.value})
    }
    const echartType = e => {
        setDataState({...dataState, echartType: e.target.value})
    }
    const dataType = e => {
        setDataState({...dataState, dataType: e.target.value})
    }
    const onChange = (date) => {
        setDataState({...dataState, date: date ? date.format('YYYYMMDD') : moment()})
    }

    return (
        <div className='waterMeter' style={{pointerEvents:'none'}}>
            <Modal
                title={title}
                visible={visible}
                onCancel={props.handleCancel}
                width={'70%'}
                footer={null}
                destroyOnClose={true}
                className='waterMeterModal'
                style={{pointerEvents:loading?'none':'all'}}
            >
                <Spin  className="modalSpin" spinning={loading}>
                <div className='modalBodyHead clearfix'>

                    <div className='modalBodyHeadLeft'>
                        <Radio.Group defaultValue={r.isEmpty(channelButtom) ? '' : channelButtom[0].id}
                                     buttonStyle="solid" onChange={channelId}>
                            {
                                channelButtom.map(i =>
                                    <Radio.Button value={i.id} key={i.id}>{i.channel}</Radio.Button>
                                )
                            }
                        </Radio.Group>
                    </div>

                    <div className='modalBodyHeadRight'>
                        <DatePicker onChange={onChange} defaultValue={moment()}
                                    dateRender={current => {
                                        const style = {};

                                        console.log('current-----');
                                        console.log(current.format('YYYYMMDD'));
                                        if (recordDays.indexOf(current.format('YYYYMMDD')) !== -1) {
                                            console.log('current-----');
                                            console.log(current);
                                            style.border = '1px solid #1890ff';
                                            style.borderRadius = '50%';
                                        }
                                        return (
                                            <div className="ant-picker-cell-inner" style={style}>
                                                {current.date()}
                                            </div>
                                        );
                                    }}
                                    disabledDate={current => current && current > moment().endOf('day')}/>

                        <Radio.Group defaultValue="DAY" buttonStyle="solid" onChange={timeType}>
                            <Radio.Button value="DAY">日</Radio.Button>
                            <Radio.Button value="WEEK">周</Radio.Button>
                            <Radio.Button value="MONTH">月</Radio.Button>
                            <Radio.Button value="YEAR">年</Radio.Button>
                        </Radio.Group>

                        <Radio.Group defaultValue="bar" buttonStyle="solid" onChange={echartType}>
                            <Radio.Button value="line">折线</Radio.Button>
                            <Radio.Button value="bar">柱状</Radio.Button>
                        </Radio.Group>

                        <Radio.Group defaultValue="difference" buttonStyle="solid" onChange={dataType}>
                            <Radio.Button value="difference">差值</Radio.Button>
                            <Radio.Button value="scale">刻度</Radio.Button>
                        </Radio.Group>
                    </div>

                </div>
                </Spin>
                <ReactEcharts option={echarData} style={{height: '340px'}}/>
            </Modal>
        </div>
    )
}

export default ModalEcharts;