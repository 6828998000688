import React, { Component } from 'react';

import './indexBottom3.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';

class IndexBottom3 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sum: 0,
            lightrcptclPCT: 0,
            airelePCT: 0,
            powerelePCT: 0,
            specialelePCT: 0,
            active: "day"
        };
    }

    async getData(api, active) {
        this.state = { getOption: { series: [] }, ...this.state };
        const data = await Api.get(api);

        const sum = data.data.total ? data.data.total.toFixed(2) : 0.00;
        const lightrcptcl = data.data.lightrcptcl ? data.data.lightrcptcl.toFixed(2) : 0.00;
        const powerele = data.data.powerele ? data.data.powerele.toFixed(2) : 0.00;
        const airele = data.data.airele ? data.data.airele.toFixed(2) : 0.00;
        const specialele = data.data.specialele ? data.data.specialele.toFixed(2) : 0.00;

        const lightrcptclPCT = lightrcptcl ? ((lightrcptcl / sum) * 100).toFixed(2) : 0.00;
        const powerelePCT = powerele ? ((powerele / sum) * 100).toFixed(2) : 0.00;
        const airelePCT = airele ? ((airele / sum) * 100).toFixed(2) : 0.00;
        const specialelePCT = specialele ? ((specialele / sum) * 100).toFixed(2) : 0.00;


        this.setState({
            sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT,
            active: active
        })
    }


    componentDidMount() {
        this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioDaily`, 'day')
    }

    selected(params) {

        switch (params) {
            case 'day':
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioDaily`, 'day')
                break;
            case 'month':
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioMonthly`, 'month')
                break;
            case 'year':
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioAnnually`, 'year')
                break;
            default:
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioDaily`, 'day')
                break;

        }
        // console.log(this.state)
    }


    render() {
        const { sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT } = this.state;
        const getOption = {
            title: {
                text: '总能耗',
                textStyle: {
                    fontSize: 14,
                    fontFamily: 'PingFang-SC-Medium',
                    fontWeight: 500,
                    color: '#5b6b73',
                    lineHeight: 20
                },
                subtext: sum,
                subtextStyle: {
                    fontSize: 26,
                    fontFamily: 'PingFang-SC-Bold',
                    fontWeight: 'bold',
                    color: '#5b6b73',
                    lineHeight: 37,
                },
                right: 30,
                top: 40
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 100,
                icon: "circle",
                align: "left",
                formatter: '{name}',
                textStyle: { color: '#5b6b73', }
            },
            series: {
                type: 'pie',
                center: ['25%', '50%'],
                radius: ['80%', '50%'],
                clockwise: false,
                hoverAnimation: false,
                stillShowZeroSum: false,
                label: {
                    normal: {
                        show: false,
                    }
                },
                itemStyle: {
                    normal: {
                        borderWidth: 2,
                        borderColor: '#fff',
                    }
                },
                data: [
                    {
                        value: lightrcptclPCT,
                        name: "照明插座：" + lightrcptclPCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#87E6FF'
                            }
                        }
                    },
                    {
                        value: airelePCT,
                        name: "空调用电：" + airelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#86CCFF'
                            }
                        }
                    },
                    {
                        value: powerelePCT,
                        name: "动力用电：" + powerelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#F8E61A'
                            }
                        }
                    },
                    {
                        value: specialelePCT,
                        name: "特殊用电：" + specialelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#FFAD33'
                            }
                        }
                    }
                ]
            }
        }
        const getOption4 = {
            title: {
                text: '总能耗',
                textStyle: {
                    fontSize: 14,
                    fontFamily: 'PingFang-SC-Medium',
                    fontWeight: 300,
                    color: '#5b6b73',
                    lineHeight: 23
                },
                subtext: sum,
                subtextStyle: {
                    fontSize: 23,
                    fontFamily: 'PingFang-SC-Bold',
                    fontWeight: 'bold',
                    color: '#5b6b73',
                    lineHeight: 35,
                },
                right: 20,
                top: 40
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 150,
                icon: "circle",
                align: "left",
                formatter: '{name}',
                textStyle: { color: '#5b6b73', }
            },
            series: {
                type: 'pie',
                center: ['35%', '50%'],
                radius: ['40%', '70%'],
                roseType:'radius',
                clockwise: false,
                hoverAnimation: false,
                stillShowZeroSum: false,
                label: {
                    normal: {
                        show: true,
                        formatter: function (params) {
                            console.log(params);
                            return params.name.substr(0,2)+'\r\n'+params.value+ '%';
                        },
                    },
                },
                labelLine: {
                    lineStyle: {
                        color: 'rgba(255, 0, 255, 0.3)'
                    },
                    smooth: 0.2,
                    length: 6,
                    length2: 10
                },
                itemStyle: {
                    normal: {
                        borderWidth: 2,
                        borderColor: '#fff',
                    }
                },
                data: [
                    {
                        value: lightrcptclPCT,
                        name: "照明插座：" + lightrcptclPCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#87E6FF'
                            }
                        }
                    },
                    {
                        value: airelePCT,
                        name: "空调用电：" + airelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#86CCFF'
                            }
                        }
                    },
                    {
                        value: powerelePCT,
                        name: "动力用电：" + powerelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#F8E61A'
                            }
                        }
                    },
                    {
                        value: specialelePCT,
                        name: "特殊用电：" + specialelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#FFAD33'
                            }
                        }
                    }
                ]
            }
        }
        const getOption3 = {
            title: {
                text: '总能耗:'+sum,
                textStyle: {
                    fontSize: 12,
                    fontFamily: 'PingFang-SC-Medium',
                    fontWeight: 400,
                    color: '#5b6b73',
                    lineHeight: 20
                },
                subtext: sum,
                subtextStyle: {
                    fontSize: 22,
                    fontFamily: 'PingFang-SC-Bold',
                    fontWeight: 'bold',
                    color: '#5b6b73',
                    lineHeight: 30,
                },
                // right: 30,
                // top: 40
                bottom: 10
            },
            // legend: {
            //     orient: 'vertical',
            //     x: 'right',
            //     y: 100,
            //     icon: "circle",
            //     align: "left",
            //     formatter: '{name}',
            //     textStyle: { color: '#5b6b73', }
            // },
            //更改条目的说明位置
            // legend: {
            //     left: 'center',
            //     top: 'bottom',
            //     icon: "circle",
            //     formatter: '{name}',
            //     textStyle: { color: '#5b6b73', }
            //     // data: ['rose1', 'rose2', 'rose3', 'rose4', 'rose5', 'rose6', 'rose7', 'rose8']
            // },
            // // 增加toolbox，显示
            // toolbox: {
            //     show: true,
            //     feature: {
            //         mark: {show: true},
            //         dataView: {show: true, readOnly: false},
            //         magicType: {
            //             show: true,
            //             type: ['pie', 'funnel']
            //         },
            //         restore: {show: true},
            //         saveAsImage: {show: true}
            //     }
            // },
            series: {
                type: 'pie',
                center: ['50%', '45%'],
                radius: ['40%', '70%'],
                // radius: [70, 100],
                // center: ['25%', '50%'],
                clockwise: false,
                // roseType:'area',
                roseType:'radius',
                hoverAnimation: false,
                stillShowZeroSum: false,
                label: {
                    normal: {
                        show: true
                    },

                },
                labelLine: {
                    lineStyle: {
                        color: 'rgba(255, 0, 255, 0.3)'
                    },
                    smooth: 0.2,
                    length: 8,
                    length2: 16
                },
                itemStyle: {
                    normal: {
                        borderWidth: 2,
                        borderColor: '#fff',
                    }
                },
                data: [
                    {
                        value: lightrcptclPCT,
                        name: "照明插座：" + lightrcptclPCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#87E6FF'
                            }
                        }
                    },
                    {
                        // value: airelePCT,
                        value: airelePCT,
                        name: "空调用电：" + airelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#86CCFF'
                            }
                        }
                    },
                    {
                        value: powerelePCT,
                        name: "动力用电：" + powerelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#F8E61A'
                            }
                        }
                    },
                    {
                        value: specialelePCT,
                        name: "特殊用电：" + specialelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#FFAD33'
                            }
                        }
                    }
                ]
            }
        }
        const getOption2 = {
            title: {
                text: '总能耗',
                textStyle: {
                    fontSize: 14,
                    fontFamily: 'PingFang-SC-Medium',
                    fontWeight: 500,
                    color: '#5b6b73',
                    lineHeight: 20
                },
                subtext: 0,
                subtextStyle: {
                    fontSize: 26,
                    fontFamily: 'PingFang-SC-Bold',
                    fontWeight: 'bold',
                    color: '#5b6b73',
                    lineHeight: 37,
                },
                right: 30,
                top: 40
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 100,
                icon: "circle",
                align: "left",
                formatter: '{name}',
                textStyle: { color: '#5b6b73', }
            },
            series: {
                type: 'pie',
                center: ['25%', '50%'],
                radius: ['80%', '50%'],
                clockwise: false,
                hoverAnimation: false,
                stillShowZeroSum: false,
                label: {
                    normal: {
                        show: false,
                    }
                },
                itemStyle: {
                    normal: {
                        borderWidth: 2,
                        borderColor: '#fff',
                    }
                },
                data: [
                    {
                        value: 1,
                        name: "照明插座：0 %",
                        itemStyle: {
                            normal: {
                                color: '#87E6FF'
                            }
                        }
                    },
                    {
                        value: 1,
                        name: "空调用电： 0 %",
                        itemStyle: {
                            normal: {
                                color: '#86CCFF'
                            }
                        }
                    },
                    {
                        value: 1,
                        name: "动力用电：0 %",
                        itemStyle: {
                            normal: {
                                color: '#F8E61A'
                            }
                        }
                    },
                    {
                        value: 1,
                        name: "特殊用电：0 %",
                        itemStyle: {
                            normal: {
                                color: '#FFAD33'
                            }
                        }
                    }
                ]
            }
        }

        return (
            <div className='indexBottom3'>
                <div className='indexBottomHead'>
                    <span>分项能耗</span>
                    <div>
                        <i className={`${this.state.active === "day" ? "active" : ""} hover`} onClick={this.selected.bind(this, "day")}>日</i>
                        <i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月</i>
                        <i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年</i>
                    </div>
                </div>
                {/*<ReactEcharts option={sum === 0 && lightrcptclPCT === 0 && airelePCT === 0 && powerelePCT === 0 && specialelePCT === 0 ? getOption2 : getOption} notMerge={true} lazyUpdate={true} style={{ width: "100%", height: "240px" }} />*/}
                <ReactEcharts option={getOption4} notMerge={true} lazyUpdate={true} style={{ width: "100%", height: "260px" ,marginTop:'0'}} />
            </div>
        )
    }
}


export default IndexBottom3;
