import React, { useContext } from 'react';
import Echarts from 'src/components/echarts/echarts';
import { context } from './dayAndNight'
import './dayAndNight.scss';

const nightUsage = () => {
  const { state: { nightUsage: { timeSeries, data } } } = useContext(
    context)
  console.log('nightUsage', data);
  const echartsData = {
    tit: '建筑夜间用能',
    data: {
      color: ['#86CCFF', '#FFAD33'],
      grid: { left: '20', top: '5', right: '10', bottom: '22', containLabel: true },
      legend: {},
      tooltip: { trigger: 'axis' },
      xAxis: {
        type: 'category',
        data: timeSeries,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data,
          type: 'bar',
          itemStyle: { color: '#63696d' },
          label: {
            normal: {
              show: true,
              position: 'top',
              distance: 10,
              align: 'center',
              verticalAlign: 'middle',
            },
          }
        }],
    },
  }
  return (
    <div className="dayAndNightRightBottom">
      <Echarts props={echartsData} />
    </div>
  )
}

export default nightUsage;