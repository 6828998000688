 const a = {
     "page_1": [{
         "type": "b",
         "name": "",
         "id": "000068120453",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "5%",
             "left": "45.3%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304026",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "21.5%",
             "left": "0.7%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304033",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "30%",
             "left": "0.7%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304011",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304017",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "23.3%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "73065369",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "28.6%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304934",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304032",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "17.4%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304021",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "17.4%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304035",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "17.4%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304016",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304027",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "73065314",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304007",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "34.2%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79234135",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "34.2%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "78024727",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "34.2%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564008",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564016",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77215419",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79234138",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "51%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79234131",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "51%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "78024729",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "51%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564014",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "59.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304008",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "59.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77215413",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "59.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304003",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "67.8%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304020",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "67.8%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77215416",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "67.8%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564013",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "76.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564007",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "76.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79234140",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "76.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304012",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "21.5%",
             "left": "84.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304022",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "30%",
             "left": "84.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304013",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "17.8%",
             "left": "93%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304028",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "26%",
             "left": "93%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304019",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "34.2%",
             "left": "93%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564009",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "51.8%",
             "left": "0.7%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304004",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "60.5%",
             "left": "0.7%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564012",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "48%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79234133",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "56.2%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "64.4%",
             "left": "9.3%"
         },
         "status": 1
     }, {
         "type": "b",
         "name": "",
         "id": "79304006",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "51.8%",
             "left": "17.4%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79234132",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "60.5%",
             "left": "17.4%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "71564015",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "51.8%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79234136",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "60.5%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304024",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "51.8%",
             "left": "34.2%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "79304005",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "60.5%",
             "left": "34.2%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304036",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "48%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304023",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "56.2%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065376",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "64.4%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77215408",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "51.8%",
             "left": "51%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77215411",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "60.5%",
             "left": "51%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "77215407",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "51.8%",
             "left": "59.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "77215397",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "60.5%",
             "left": "59.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "78024744",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "48%",
             "left": "67.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065174",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "48%",
             "left": "76.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065177",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "64.4%",
             "left": "67.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73085122",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "64.4%",
             "left": "76.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73085132",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "48%",
             "left": "84.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065171",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "48%",
             "left": "93%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065181",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "64.4%",
             "left": "84.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73085108",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "64.4%",
             "left": "93%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "70130301",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "0.7%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77290416",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "86.7%",
             "left": "0.7%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77290418",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "0.7%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304025",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304009",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "9.3%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "78024694",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "17.4%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304029",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "17.4%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304018",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304010",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "86.7%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304015",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "26.1%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "77290417",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "34.2%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "77215390",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "34.2%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79234143",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "78024696",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "42.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "78024721",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "51%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "77290419",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "51%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "73065378",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "59.5%"
         },
         "status": 0
     }, {
         "type": "b",
         "name": "",
         "id": "73085095",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "59.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065183",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "67.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065175",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "76.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73085118",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "67.8%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "73065186",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "76.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304030",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "84.5%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79304027",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "78.5%",
             "left": "93%"
         },
         "status": 0
     }, {
         "type": "d",
         "name": "",
         "id": "79234134",
         "flow": "",
         "velocity": "",
         "temperature": "",
         "value": "0.0",
         "position": {
             "top": "94.8%",
             "left": "93%"
         },
         "status": 0
     }],
     "page_2": [
         // {
         //     "type": "b",
         //     "name": "",
         //     "id": "000068120453",
         //     "flow": "",
         //     "velocity": "",
         //     "temperature": "",
         //     "value": "0.0",
         //     "position": {
         //         "top": "0%",
         //         "left": "50.4%"
         //     },
         //     "status": 0
         // },
         {
             "type": "b",
             "name": "",
             "id": "78024699",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "73065155",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "14.3%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024698",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024743",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024717",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "14.3%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024749",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024738",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "18%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024728",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "18%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024747",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "25.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024713",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "25.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024703",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "34.4%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114467",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "color": "#333",
                 "top": "14.3%",
                 "left": "34.4%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024748",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "34.4%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024740",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "42.3%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024742",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "42.3%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024700",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "51%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "79234130",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "59.2%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "79234137",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "59.2%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "77215402",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "67.9%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024722",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "67.9%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024708",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "76.5%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024750",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "85%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024706",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "76.5%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024710",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "85%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "051114309",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "5.3%",
                 "left": "93%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "051114319",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "23.1%",
                 "left": "93%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024718",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024751",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "44.7%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024709",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024714",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "62.3%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024695",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024702",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "44.7%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024746",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024693",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "62.3%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024697",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "18%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024707",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "44.7%",
                 "left": "18%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024712",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "18%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024715",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "25.8%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024745",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "34.4%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024716",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "42.3%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024701",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "51%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024719",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "25.8%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024705",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "34.4%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024711",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "42.3%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024704",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "51%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "77215393",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "59.2%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "77215391",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "44.7%",
                 "left": "59.2%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "151114333",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "59.2%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "151114331",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "67.9%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024736",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "44.7%",
                 "left": "67.9%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "78024741",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "67.9%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "77215404",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "76.5%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "77215386",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "44.7%",
                 "left": "76.5%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024737",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "53.5%",
                 "left": "76.5%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "78024739",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "35.7%",
                 "left": "85%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "7096807",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "80.4%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "7096743",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "90%",
                 "left": "0.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "79234139",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114463",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "85.3%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114474",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "94%",
                 "left": "9.5%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114447",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "18%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114449",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "85.3%",
                 "left": "18%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "79304002",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "25.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114441",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "85.3%",
                 "left": "25.8%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114471",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "34.4%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "051114430",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "85.3%",
                 "left": "34.4%"
             },
             "status": 0
         }, {
             "type": "b",
             "name": "",
             "id": "79304037",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "42.3%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "77215400",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "51%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "73085039",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "67.9%"
             },
             "status": 0
         }, {
             "type": "d",
             "name": "",
             "id": "77215395",
             "flow": "",
             "velocity": "",
             "temperature": "",
             "value": "0.0",
             "position": {
                 "top": "76.3%",
                 "left": "85%"
             },
             "status": 0
         }
     ]
 }

 export default a