import React from 'react';
import './border2.scss'
const Border2 = (props) => {
    return (
        <div className={`border2Container ${props.className}`}>
            <div className="border2 border2Left"></div>
            {props.children}
            <div className="border2 border2Right"></div>
        </div>
    )
}

export default Border2