import React, { useContext } from 'react';
import Top from './departmentRightTop/departmentRightTop';
import Bottom from './departmentRightBottom/departmentRightBottom';

const DepartmentRight = () => (
    <div className='departmentRight'>
        <Top />
        <Bottom />
    </div>
)

export default DepartmentRight