import React, {useState, useEffect} from 'react';
import {Modal, Radio, DatePicker} from 'antd';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import * as r from 'ramda';

import './modelEchartsOldData.scss';
import Api from 'api';

const { RangePicker } = DatePicker;

const echartsData = {
    color: ["#77d3f6", "#D3D7E3"],
    grid: {left: '20', top: '25', right: '20', bottom: '22'},
    // tooltip: {trigger: 'axis',
    //     formatter: function (params) {
    //         console.log(params);
    //         return 'hahah';
    //     }},
    legend: {},
    xAxis: {
        type: 'category'
    },
    yAxis: {
        // show: false,
        // type: 'value'
    },
    series: [{
        type: 'line',
        name: '今年',
        smooth: true,
        data: []
    }]
}

const timeFormat = (v) => {
    const typeMap = {
        DAY: 'HH:00',
        WEEK: 'dddd',
        // MONTH: 'DD[日]',
        // YEAR: 'MM-DD',
        MONTH: 'YY-MM-DD',
        YEAR: 'YY-MM-DD',
    }
    return r.propOr(v)(v)(typeMap)
}

const ModalEcharts = (props) => {
    const {visible, record: {BuildName,EquipmentID,BuildCode,CollectionTime}} = props;
    const monthFormat = 'YYYY/MM/DD';
    const yearFormat = 'YYYY';
    // const channelsKey = r.isEmpty(r.keys(channels)) ? '' : r.keys(channels)[0];
    // const channelButtom = r.map(v => r.pick(['id', 'channel'])(v))(r.values(channels))
    // const id = r.pathOr([])([channelsKey, 'id'])(channels)
    // console.log(channelButtom)
    // console.log('item data:-----');
    // console.log(BuildName,EquipmentID,BuildCode,CollectionTime);
    const [echarData, setEcharData] = useState(echartsData);
    const [picker, setPicker] = useState('month');
    const [format, setFormat] = useState(monthFormat);
    const [dataState, setDataState] = useState({
        // channelId: id,
        timeType: 'MONTH',
        // dataType: 'difference',
        echartType: 'line',
        BuildCode: '',
        EquipmentID: '',
        // month: moment().format('YYYYMM')
        month: '',
        year:'',
        date:['2020/01/01','2020/01/31']
    });
    useEffect(() => {
        console.log('visible');
        console.log(visible);
        if (visible) {
            // setDataState({...dataState,BuildCode: BuildCode,EquipmentID: EquipmentID})
            console.log('dataState--------------');
            console.log(dataState);
            updata(dataState)
        }

    }, [dataState,visible,format,picker])
    useEffect(() => {
        if (visible) {
            setDataState({...dataState,BuildCode: BuildCode,EquipmentID: EquipmentID})
            console.log('dataState2 --------------');
            console.log(dataState);
        }
    }, [EquipmentID]);

    const updata = async ({timeType, /*dataType,*/ echartType, month,BuildCode,EquipmentID,date /*channelId = channelButtom[0].id*/}) => {
        // console.log('item data2:-----');
        // console.log(timeType,month,BuildName,EquipmentID,BuildCode,CollectionTime);
        if( !timeType || !BuildCode|| !EquipmentID || !date){
            console.log('error:存在参数为空');
            return;
        }
        const detail =await  Api.postV3('/oldDataBranch/data', {
            project:Api.projectId,
            timeType: timeType,
            month: '202002',
            year: '2020',
            BuildCode: BuildCode,
            EquipmentID: EquipmentID,
            date:date
        }).then(r.prop('data'));
        // const detail = [];
        // const result={detail : []};
        const color = {
            lineStyle: {
                color: '#d9d9d9',
            }
        }
        setEcharData(
            {
                color: ["#77d3f6", "#D3D7E3"],
                grid: {left: '10', top: '25', right: '20', bottom: '22',containLabel: true},
                tooltip: {trigger: 'axis'},
                legend: {
                    show: false
                },
                xAxis: {
                    type: 'category',
                    axisLine: color,
                    axisTick: color,
                    axisLabel: color,
                    // axisLabel: {
                    //     formatter: function (val) {
                    //         return  val+ ' ms';
                    //     }
                    // },
                    splitLine: color,
                    data: r.values(r.map(v => (moment(v.CollectionTime).format(timeFormat(timeType))))(detail))
                },
                yAxis: {
                    show: true,
                    // type: 'value'
                },
                series: [{
                    type: echartType,
                    name: EquipmentID,
                    smooth: true,
                    data: r.values(r.map(v => v.Qty)(detail)),

                    label: {
                        // show: echartType == 'bar'?false:true,
                        // show: true,
                        position: 'top'
                    }
                }]

            }
        )
    }


    const channelId = e => {
        setDataState({...dataState, channelId: e.target.value})
    }
    const timeType = e => {
        setDataState({...dataState, timeType: e.target.value});
        setPicker(e.target.value=='MONTH'?'month':'year');
        setFormat(e.target.value=='MONTH'?monthFormat:yearFormat);
    }
    const echartType = e => {
        setDataState({...dataState, echartType: e.target.value})
    }
    const dataType = e => {
        setDataState({...dataState, dataType: e.target.value})
    }
    const onChange = (date) => {
        console.log('date');
        console.log(date);
        setDataState({...dataState, date: [date[0] ? date[0].format('YYYYMMDD') : moment(),date[1] ? date[1].format('YYYYMMDD') : moment()]})
    }

    return (
        <div className='waterMeter'>
            <Modal
                title={EquipmentID}
                visible={visible}
                onCancel={props.handleCancel}
                width={'70%'}
                footer={null}
                destroyOnClose={true}
                className='waterMeterModal'
            >
                <div className='modalBodyHead clearfix'>

                    <div className='modalBodyHeadRight'>
                        {/*<DatePicker onChange={onChange} defaultValue={moment('2020/02', monthFormat)} format={monthFormat} picker="month"*/}
                                    {/*disabledDate={current => current && current > moment().endOf('day')}/>*/}

                        {/*<DatePicker onChange={onChange} defaultValue={moment('2020/02', format)} format={format} mode={picker} />*/}
                        {/*<DatePicker onChange={onChange} defaultValue={moment('2020/02', format)} format={format} mode='month' />*/}
                        <RangePicker
                            defaultValue={[moment('2020/01/01', monthFormat), moment('2020/01/31', monthFormat)]}
                            onChange={onChange}
                        />


                        <Radio.Group defaultValue="MONTH" value={dataState.timeType}  buttonStyle="solid" onChange={timeType}>
                            {/*<Radio.Button value="DAY">日</Radio.Button>*/}
                            {/*<Radio.Button value="WEEK">周</Radio.Button>*/}
                            {/*<Radio.Button value="MONTH">月</Radio.Button>*/}
                            {/*<Radio.Button value="YEAR">年</Radio.Button>*/}
                        </Radio.Group>

                        <Radio.Group defaultValue="line" buttonStyle="solid" onChange={echartType}>
                            <Radio.Button value="line">折线</Radio.Button>
                            <Radio.Button value="bar">柱状</Radio.Button>
                        </Radio.Group>

                        {/*/!*<Radio.Group defaultValue="difference" buttonStyle="solid" onChange={dataType}>*!/*/}
                            {/*/!*<Radio.Button value="difference">差值</Radio.Button>*!/*/}
                            {/*/!*<Radio.Button value="scale">刻度</Radio.Button>*!/*/}
                        {/*/!*</Radio.Group>*!/*/}
                    </div>

                </div>
                <div>
                    <ReactEcharts option={echarData} style={{left:'10px',height: '340px'}}/>
                </div>

            </Modal>
        </div>
    )
}

export default ModalEcharts;