import React from 'react';
import { Table, Switch, Pagination } from 'antd';
import * as r from 'ramda';
import moment from 'moment';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

// import './dayReport.scss';
import Api from 'api';

class UltraWater extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data: null,
            allData: null,
            isUpData: false,
            tableLoading: false,
            total: 0,
            operationIsShow: false,
            pageindex: 1,
            pagesize: 10,
            pageCount: 1
        };
    }

    async getData(pageindex, pagesize) {
        this.setState({
            tableLoading: true,
            data: []
        })

        const ultraWater = await Api.post('/business/monitor', {
            devicetype: "ULTRACOLDWATERMETER",
            project: Api.projectId,
            ext: { enableMask: 1 },
            mode: "SENSOR",
            pageindex: pageindex,
            pagesize: pagesize,
        },
            { withCredentials: true }
        );
        let data = []
        ultraWater.data.result[Api.projectId].detail.forEach((i, k) => {
            const orderAlgorithm = (x) => (x + 1) + ((pageindex - 1) * pagesize);
            let order = orderAlgorithm(k) < 10 ? '0' + orderAlgorithm(k) : orderAlgorithm(k);
            data.push({
                key: i.addrid,
                order: order,
                name: i.title,
                instrumentId: i.addrid,
                flow: i.channels['04'].realdata,
                flowVelocity: i.channels['09'].realdata,
                temperature: i.channels['05'].realdata,
                state: i.channels['04'].status,
                time: moment(i.channels['04'].lastupdate).format('YYYY-MM-DD HH:mm:ss'),
                number: i.gatewayid,
                allInformation: i
            })
        })

        this.setState({
            data: data,
            total: ultraWater.data.result[Api.projectId].detail.length,
            tableLoading: false,
            pageCount: ultraWater.data.result[Api.projectId].paging.count

        })
    }

    componentDidMount() {
        this.getData(this.state.pageindex, this.state.pagesize)
    }



    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }
    pageChange = (current, pageSize) => {
        console.log(pageSize)
        this.getData(current, pageSize)
    }

    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 70
        }, {
            title: '智能仪表名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} record={record} />
            ),
            width: 150
        }, {
            title: '智能仪表ID',
            dataIndex: 'instrumentId',
            key: 'instrumentId',
            width: 150
        }, {
            title: '累计流量m³',
            dataIndex: 'flow',
            key: 'flow',
            width: 150
        }, {
            title: '流速m³/s',
            dataIndex: 'flowVelocity',
            key: 'flowVelocity',
            // render: (text, record) => {
            //     return <span className={record.state ? 'colorY' : 'colorG'}>{text}</span>
            // },
            width: 150
        }, {
            title: '供水温度℃',
            dataIndex: 'temperature',
            key: 'temperature',
            width: 150
        }, {
            title: '通讯状态',
            dataIndex: 'state',
            key: 'state',
            render: (text, record) => {
                return <span className={record.state ? 'colorY' : 'colorG'}>{record.state ? '• 异常' : '• 正常'}</span>
            },
            width: 150
        },
        //  {
        //     title: '开关状态',
        //     dataIndex: 'switch',
        // key: 'switch',
        //     render: (text, record) => (
        //         // onoff
        //         <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={true} disabled={true} />
        //     ),
        //     width: 150
        // }, 
        {
            title: '通讯时间',
            dataIndex: 'time',
            key: 'time',
            width: 200
        }, {
            title: '采集器编号',
            dataIndex: 'number',
            key: 'number',
            width: 150
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        // key: 'operation',
        //     render: (text, record) => (
        //         <div>
        //             <Management record={record} isUpData={this.upData} />
        //             <Delete record={record} />
        //         </div>
        //     ),
        // }, 
        {
            title: '设备标识',
            dataIndex: 'device_id',
            key: 'device_id',
            className: 'hidden',
        }, {
            title: '倍率',
            dataIndex: 'multipoint',
            key: 'multipoint',
            className: 'hidden',
        }, {
            title: '更新频率',
            dataIndex: 'updatecyc',
            key: 'updatecyc',
            className: 'hidden',
        }, {
            title: '是否删除',
            dataIndex: 'is_del',
            key: 'is_del',
            className: 'hidden',
        }, {
            title: '是否为总表',
            dataIndex: 'principal',
            key: 'principal',
            className: 'hidden',
        }];

        const testData = [{
            order: '01',
            name: '智能仪表名称',
            instrumentId: '1245',
            flow: '124',
            flowVelocity: '80',
            temperature: '15',
            state: '正常',
            switch: '开',
            time: '2019-03-26 16:30:33',
            number: '17',
            device_id: '设备标识',
            multipoint: '倍率',
            updatecyc: '更新频率',
            is_del: '0',
            principal: '1',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                if (r.length(selectedRows)) {
                    this.setState({ operationIsShow: true })
                }
                else {
                    this.setState({ operationIsShow: false })
                }
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: this.state.pageCount,
            liData: ['搜索']
        }


        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} operationIsShow={this.state.operationIsShow} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    // dataSource={testData}
                    dataSource={this.state.data}
                    scroll={{ y: 580 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    rowKey='ultraWater'
                    className='reportRightTableTab' />
                <div className='reportRightTableFoot'>
                    <Pagination className='pagination' showSizeChanger={true} onChange={this.pageChange} onShowSizeChange={this.pageChange} defaultCurrent={1} total={this.state.pageCount} />
                </div>
            </div >
        )
    }
}


export default UltraWater;