import React from 'react';

import moment from 'moment'
import { Spin, Row, Col, Icon } from 'antd'
import { from } from 'rxjs'
import AnalysisNavigator
  from 'src/modules/energy/analysis/analysis_left/analysisNavigator';
import AnalysisPanels
  from 'src/modules/energy/analysis/analysis_right/analysisPanels';
import withStates from 'src/context/withStates';
import Api from 'api'
import * as r from 'ramda';
import ReactEcharts from 'echarts-for-react'
import './device.scss'


const ROOT_NODE = '0';
const getHour = r.map(i => `0${i}`.slice(-2) + ':00')(r.range(0, moment().hour()))
const option = {
  grid: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '10'
  },
  xAxis: {
    // type: 'value',
    axisLine: {       //y轴
      show: false,
      lineStyle: { color: '#ccc' }
    },
    axisTick: { show: false },

    axisLabel: {
      show: false
    },
    data: getHour

  },
  yAxis: {
    axisLine: {       //y轴
      show: true,
      lineStyle: { color: '#ccc' }
    },
    axisTick: {       //y轴刻度线
      show: false
    },
    axisLabel: {
      show: false
    }
  },
  series: [{
    data: [820, 932, 901, 934, 1290, 1330, 1320],
    type: 'line'
  }]
};

const Device = () => (
  <div className='device'>
    <div>
      <Row gutter={16} style={{ margin: '10px' }}>
        <Col span={6}>
          <div className='deviceContainer' >
            <Icon type="thunderbolt" className='icon' />
            <div>
              <ReactEcharts option={option} style={{ height: '80%' }} />
              <ul>
                <li>1</li>
                <li>2</li>
                <li>3</li>
                <li>4</li>
              </ul>
            </div>

          </div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>

      </Row>
      <Row gutter={16} style={{ margin: '10px' }}>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
      </Row>
      <Row gutter={16} style={{ margin: '10px' }}>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
      </Row>
      <Row gutter={16} style={{ margin: '10px' }}>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>
        <Col span={6}>
          <div style={{ backgroundColor: 'red', height: '200px' }}>1</div>
        </Col>

      </Row>
    </div>

  </div>
)

const treeViewData = async () => {
  const { data: { result = [] } } = await Api.post('/business/monitor ', {
    project: Api.projectId,
    pagesize: 10000
  });
  const toChildren = r.compose(
    r.map(({ id: key, title, key: did, child }) =>
      ({
        title, key, did,
        children: r.isNil(child) ? [] : toChildren(child),
      })),
    r.values)
  return {
    searchIsShow: true,
    loading: false,
    node: ROOT_NODE,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        children: toChildren(
          r.filter(
            r.where({
              type: r.equals('NODE'),
              parent: r.equals(0),
            }),
          )(result)),
      },
    ],
  }
}
///
const ratioOf = type => r.compose(
  ([today = 0, yesterday = 0]) => yesterday === 0 || r.isNil(yesterday) ?
    0 :
    (100 * (Number(today) - Number(yesterday)) /
      Number(yesterday)).toFixed(2),
  r.take(2), r.pluck('value'), r.propOr([])(type))

const translateFormat = r.map(r.pipe(r.prop('value'), x => isNaN(x) || r.isNil(x) ? NaN : Number(x).toFixed(2)))
const translateTimeline = v => {
  const valueMap = {
    daily: r.map(i => `0${i}`.slice(-2) + ':00')(r.range(0, 24)),
    monthly: r.map(i => `0${i}`.slice(-2))(
      r.range(1, moment().daysInMonth() + 1)),
    annually: r.map(i => `0${i}`.slice(-2))(r.range(1, 13)),
  }
  return r.propOr(v)(v)(valueMap)
}

const yearMeasure = type => r.pipe(r.propOr([])(type), r.pluck('value'),
  r.map(x => Number(x)), r.sum, x => x.toFixed(2))

const todayMeasure = type => r.pipe(r.pathOr(0)([type, '0', 'value']),
  x => Number(x).toFixed(2))

const translateType = v => {
  const valueMap = {
    ELECTRICITYMETER: '电表',
    WATER: '水表',
    AC: '空调',
  }
  return r.propOr(v)(v)(valueMap)
}

const translateKgce = (type) => {
  return type === 'annually' ? moment().format('YYYY') : moment().format('YYYYMM')
}

const translateLastKgce = (type) => {
  const time = moment().subtract(1, 'year');
  return type === 'annually' ? time.format('YYYY') : time.format('YYYYMM')
}
const formatDateParam = date => r.isNil(date) ? '' : `?date=${date.format('YYYYMMDD')}`

const validTypes = ['ELECTRICITYMETER', 'WATER', 'AC'];
const legendName = { ELECTRICITYMETER: '电表', WATER: '水表', AC: '空调' };
////
const nodeUsage = async ({ node, usagePeriod = 'daily', kgcePeriod = 'annually', usageDate }) => {
  const [
    { data: { current: today } },
    { data: { current: thisYear, last: lastYear } },
  ] = await Promise.all([
    Api.get(
      `/api/v3/project/${Api.projectId}/nodeUsage/${node}/${usagePeriod}${formatDateParam(usageDate)}`),
    Api.get(
      `/api/v3/project/${Api.projectId}/nodeKgce/${node}/${kgcePeriod}`)])

  const nodeUsage = {
    color: ['#FFAD33', '#86CCFF', '#77e6fd'],
    grid: { left: '50', top: '25', right: '30', bottom: '22' },
    legend: {
      data: r.map(([k, x]) => ({
        name: translateType(k),
      }))(r.toPairs(r.pick(validTypes)(today))),
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: translateTimeline(usagePeriod),
    },
    yAxis: {
      type: 'value',
    },
    series: r.map(([k, x]) => ({
      name: translateType(k),
      type: 'bar',
      data: translateFormat(x || []),
    }))(r.toPairs(r.pickAll(validTypes)(today))),
  }
  //////////////

  // console.log(thisYear, lastYear);
  const formatMonthlyKgce = r.compose(
    r.values,
    r.map(r.pipe(
      r.pluck('kgce'),
      ([w = 0, e = 0]) => Number(w) + Number(e))),
    r.groupBy(r.prop('date')),
    r.flatten,
    r.values)
  // console.log(formatMonthlyKgce(thisYear))
  // console.log(formatMonthlyKgce(lastYear))
  //TODO: fix this
  const seriesTime = r.map(({ date }) => date.toString().slice(-2))(
    thisYear['ELECTRICITYMETER'] || thisYear['WATER'] || [])
  const monthlyKgce = formatMonthlyKgce(thisYear);
  const seriesData = r.zip(r.range(0, 12))(monthlyKgce)
  const lastYearMonthlyKgce = formatMonthlyKgce(lastYear);
  const seriesDataLast = r.zip(r.range(0, 12))(lastYearMonthlyKgce)
  // console.log('monthlyKgce', monthlyKgce);

  const kgceData = {
    color: ['#86CCFF', '#FFAD33'],
    grid: { left: '30', top: '25', right: '30', bottom: '22' },
    legend: {
      data: [translateKgce(kgcePeriod), translateLastKgce(kgcePeriod)],
      align: 'left',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: seriesTime,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: translateKgce(kgcePeriod),
        data: seriesData,
        type: 'bar',
      }, {
        name: translateLastKgce(kgcePeriod),
        data: seriesDataLast,
        type: 'bar',
      }],
  }

  return {
    loading: false,
    todayWater: todayMeasure('WATER')(today),
    todayWaterRatio: ratioOf('WATER')(today),
    todayElectricity: todayMeasure('ELECTRICITYMETER')(today),
    todayElectricityRatio: ratioOf('ELECTRICITYMETER')(today),
    monthWaterTotal: yearMeasure('WATER')(thisYear),
    monthWaterRatio: ratioOf('WATER')(thisYear), //TODOs: should use usage monthly
    monthElectricityTotal: yearMeasure('ELECTRICITYMETER')(thisYear),
    monthElectricityRatio: ratioOf('ELECTRICITYMETER')(thisYear), //TODOs: should use usage monthly
    kgceMonth: (r.last(monthlyKgce) || 0).toFixed(2),
    kgceAnnual: r.sum(monthlyKgce).toFixed(2),
    monthlyRatio: ((r.last(monthlyKgce) || 0 - r.last(lastYearMonthlyKgce) ||
      0) / (r.last(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
    annuallyRatio: ((r.sum(monthlyKgce) - r.sum(lastYearMonthlyKgce)) /
      (r.sum(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
    usagePeriod,
    usageDate,
    kgcePeriod,
    nodeUsage,
    kgceData,
  }
}

const loadAnalysis = (state) => {
  const input = Promise.all(
    [treeViewData(state), nodeUsage(state)])
    .then(r.mergeAll)
  return from(input)
}

export const loadNodeUsage = (state) => {
  // console.log('loadDevices', state);
  return from(nodeUsage(state)
    .then(res => ({ ...state, ...res })))
}
const initState = {
  loading: true,
  node: ROOT_NODE,
  nodeUsage: {
    color: ['#86CCFF', '#FFAD33'],
    grid: { left: '50', top: '25', right: '30', bottom: '22' },
    legend: {},
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
    },
    series: [],
  },
  tree: [],
  kgceData: {
    color: ['#86CCFF', '#FFAD33'],
    grid: { left: '30', top: '25', right: '30', bottom: '22' },
    legend: {},
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
    },
    series: [],
  },
  usagePeriod: 'daily',
  kgcePeriod: 'annually',
}

export const { context, Component } = withStates(
  Device,
  initState,
  loadAnalysis,
)

export default Component