import React, { Component } from 'react';
import * as r from 'ramda'
import './indexBottom4.scss'
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import Api from 'api';

// import Img_line from 'img/line.png';
// import Img_bar from 'img/bar.png';

class IndexBottom4 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rankData: [],
            notMerge: true,
            lazyUpdate: true,
            // style: { width: "100%", height: "260px" },
            style: { width: "400px", height: "260px" },
            active: { time: 'day', type: 'building' },
            api: '/energyNew/rankingDailyForRegion'
        };
    }

    async getData(obj) {
        console.log(obj)
        const api = obj.api ? obj.api : this.state.api;
        const time = obj.time ? obj.time : this.state.active.time;
        const type = obj.type ? obj.type : this.state.active.type;
        this.state = { getOption: { series: [] }, ...this.state }
        const rank = await Api.postV3(api, {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: type
        });
        let rankName = [];
        let rankData = [];
        let rankings = []

        r.take(10)(rank.data).forEach(function (i, k) {
            rankName.push(i.title?(i.title.substr(0,5)+'..'):'');
            rankData.push({ value: i.value.toFixed(2), name: i.title })
            if (k < 10) {
                rankings.push(k + 1)
            }
        })


        this.setState({
            rankData,
            rankings,
            rankName,
            active: { time, type }
        })
    }


    componentDidMount() {
        this.getData({ api: '/energyNew/rankingDailyForRegion', time: 'day', type: 'building' })
    }

    selectedType(params) {
        switch (params) {
            case 'building':
                this.getData({ type: 'building' })
                break;
            case 'department':
                this.getData({ type: 'department' })
                break;
            default:
                this.getData({ type: 'building' })
                break;
        }
    }
    selectedTime(params) {
        switch (params) {
            case 'day':
                this.getData({ api: '/energyNew/rankingDaily', time: 'day' })
                break;
            case 'month':
                this.getData({ api: '/energyNew/rankingMonthly', time: 'month' })
                break;
            case 'year':
                this.getData({ api: '/energyNew/rankingAnnually', time: 'year' })
                break;
            default:
                this.getData({ api: '/energyNew/rankingDaily', time: 'day' })
                break;
        }
    }


    render() {

        const { rankData, rankings, rankName } = this.state;
        const getOption = {
            // tooltip: {},
            xAxis: [{
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    color: '#459dfd'
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                type: 'value'
            },
                // {axisTick: {
                //     show: false,
                // },
                // axisLabel: {
                //     color: '#459dfd'
                // },
                // splitLine: {
                //     show: false,
                //     lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                // },}
                ],
            grid: { left: '80', top: '5', right: '50', bottom: '22' },
            yAxis: [{
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#459dfd' },

                type: 'category',
                data: rankName,
                inverse: true,
                // axisLabel: { color: '#FEFEFF' }
            },
                {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#459dfd' },

                type: 'category',
                data: r.map((i) => (r.prop('value')(i)))(rankData),
                inverse: true,
                // axisLabel: { color: '#FEFEFF' }
            }
            ],
            series: [{
                type: 'bar',
                label: {
                    show: false,
                    color: '#ffffff'
                    // formatter: '{b}'
                },
                itemStyle: { color:  function (params) {
                        var data = params.data;
                        var sid = params.dataIndex;
                        console.log('params----');
                        console.log(params);
                            return new echarts.graphic.LinearGradient(
                                0, 0, 1, 0,
                                [
                                    // {offset: 0, color: '#83bff6'},
                                    // {offset: 0.5, color: '#188df0'},
                                    // {offset: 1, color: '#188df0'},
                                    {offset: 0, color: 'rgba(169,202,72,1)'},
                                    {offset: 0.2, color: 'rgba(186,192,56,1)'},
                                    {offset: 0.5, color: 'rgba(203,182,41,1)'},
                                    {offset: 0.8, color: 'rgba(226,168,21,1)'},
                                    {offset: 1, color: 'rgba(239,162,8,1)'},
                                ]
                            )
                        //前3名变成橘红色#ff9c01
                        // #feb802
                        // #f9c806
                        // if(sid < 3){
                        //     return new echarts.graphic.LinearGradient(
                        //         1, 0, 0, 0,
                        //         [
                        //             {offset: 0, color: '#f9c806'},
                        //             {offset: 0.5, color: '#feb802'},
                        //             {offset: 1, color: '#ff9c01'}
                        //         ]
                        //     )
                        // }else{
                        //     return new echarts.graphic.LinearGradient(
                        //         0, 0, 0, 1,
                        //         [
                        //             {offset: 0, color: '#83bff6'},
                        //             {offset: 0.5, color: '#188df0'},
                        //             {offset: 1, color: '#188df0'}
                        //         ]
                        //     )
                        // }

                        return '#131224';
                    },


                            // barBorderRadius: 14,
                }, barWidth: 14,
                data: rankData
            }]
        }

        return (
            <div className='indexBottom4V2'>
                <div className='indexBottomHead '>
                    <span  style={{fontSize: '18px',
                        fontWeight: 'bold',top:'-39px',left:'199px',position:'absolute',color:'white'}}>建筑能耗排名</span>
                </div>
                <ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} style={{ width: "500px", height: "240px" }} />
            </div>
        )
    }
}


export default IndexBottom4;
