import React from 'react';
import { Input, Button, } from 'antd';
import './projectHead.scss'
class ProjectHead extends React.Component {
    handleChange(value) {
        console.log(`selected ${value}`);
    }
    render() {
        return (
            <div className="indexHead">
                <ul>
                    <li>
                        <span>项目名称</span>
                        <Input placeholder="请输入" className='input' />
                    </li>
                    <li><Button type="primary">查询</Button></li>
                </ul>
            </div>
        )
    }

};


export default ProjectHead;