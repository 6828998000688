import React from 'react';
import { Table, Switch, Pagination } from 'antd';
import * as r from 'ramda';
import moment from 'moment';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

import Api from 'api';

class TemperatureAndHumidity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data: [{}],
            allData: null,
            isUpData: false,
            tableLoading: false,
            tableLoading: false,
            total: 0,
            pageindex: 1,
            pagesize: 10,
            pageCount: 1
        };
    }

    async getData(pageindex, pagesize) {
        this.setState({
            tableLoading: true,
            data: []
        })

        const temperatureAndHumidity = await Api.post('/business/monitor', {
            devicetype: "TEMPHUMMETER",
            project: Api.projectId,
            ext: { enableMask: 1 },
            mode: "SENSOR",
            pageindex: pageindex,
            pagesize: pagesize,
            // socitynode:
        }
        );
        const path = r.pathOr([], ['data', 'result', Api.projectId], temperatureAndHumidity)
        let data = []
        const orderAlgorithm = (x) => (x + 1) + ((pageindex - 1) * pagesize);

        path.detail.forEach((i, k) => {

            let order = orderAlgorithm(k) < 10 ? '0' + orderAlgorithm(k) : orderAlgorithm(k);

            data.push({
                key: "22" + k,
                order: order,
                name: i.title,
                instrumentId: i.addrid,
                temperature: i.channels['48'] ? i.channels['48'].realdata : 0,
                humidity: i.channels['49'] ? i.channels['49'].realdata : 0,
                state: i.channels['48'] ? i.channels['48'].status : i.channels['49'].status,
                time: i.channels['48'] ? moment(i.channels['48'].lastupdate).format('YYYY-MM-DD HH:mm:ss') : moment(i.channels['49'].lastupdate).format('YYYY-MM-DD HH:mm:ss'),
                allInformation: i
            })
        })


        this.setState({
            data: data,
            total: path.detail.length,
            tableLoading: false,
            pageCount: path.paging.count
        })

    }

    componentDidMount() {
        this.getData(this.state.pageindex, this.state.pagesize)
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }

    pageChange = (current, pageSize) => {
        console.log(pageSize)
        this.getData(current, pageSize)
    }



    render() {
        const { operationIsShow, pageCount, data, tableLoading, } = this.state;

        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 70,
        }, {
            title: '智能仪表名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} id={this.state.data.id} record={record} />
            ),
            width: 150,
        }, {
            title: '智能仪表ID',
            dataIndex: 'instrumentId',
            key: 'instrumentId',
            width: 180,
        }, {
            title: '环境温度',
            dataIndex: 'temperature',
            key: 'temperature',
            width: 150,
        }, {
            title: '环境湿度',
            dataIndex: 'humidity',
            key: 'humidity',
            width: 150,
        }, {
            title: '通讯状态',
            dataIndex: 'state',
            key: 'state',
            render: (text, record) => {
                return <span className={record.state ? 'colorY' : 'colorG'}>{record.state ? '• 异常' : '• 正常'}</span>
            },
            width: 150,
        },
        // {
        //     title: '阀门开关',
        //     dataIndex: 'switch',
        //     render: (text, record) => (
        //         // onoff
        //         <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === '开' ? true : false} onChange={this.onChange} />
        //     ),
        //     width: 150,
        // }, 
        {
            title: '通讯时间',
            dataIndex: 'time',
            key: 'time',
            width: 190,
        },
            // {
            //     title: '操作',
            //     dataIndex: 'operation',
            //     render: (text, record) => (
            //         <div style={{ color: '#3E8FF8' }}>
            //             发送
            //             {/* <Management record={record} isUpData={this.upData} />
            //             <Delete record={record} /> */}
            //         </div>
            //     ),
            //     width: 150,
            // }
        ];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: pageCount,
            liData: ['搜索']
        }
        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} operationIsShow={operationIsShow} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    dataSource={data}
                    scroll={{ y: 580 }}
                    pagination={false}
                    loading={tableLoading}
                    rowKey='temperatureAndHumidity'
                    className='reportRightTableTab' />
                <div className='reportRightTableFoot'>
                    <Pagination className='pagination' showSizeChanger={true} onChange={this.pageChange} onShowSizeChange={this.pageChange} defaultCurrent={1} total={pageCount} />
                </div>
            </div >
        )
    }
}


export default TemperatureAndHumidity;