import React, { useContext } from 'react';
import Echarts from 'src/components/echarts/echarts';
import { context, loadNodeUsage } from '../../department'
import { of } from 'rxjs'
import { Col, Radio, Row, Spin } from 'antd';
import * as r from 'ramda';
import './departmentRightTop.scss';

const { Group: RadioGroup, Button: RadioButton } = Radio
const DepartmentUsage = () => {
  const {state: {departmentOption, loading, nodeUsage: {time=[], data=[]}, usagePeriod: selection}, dispatch} = useContext(
    context)
  const echartsData = {
    tit: '部门用能同比',
    ulShow: ['day', 'month', 'years', 'datePicker'],
    data: {
      color: [
        '#006cfa',
        '#d2ddec',
      ],
      grid: { left: '20', top: '5', right: '10', bottom: '22' },
      legend: {},
      tooltip: { trigger: 'axis' },
      xAxis: {
        axisLine: {       //y轴
          show: true,
          lineStyle: { color: '#ccc' }
        },
        type: 'category',
        data: time,
      },
      yAxis: {
        type: 'value',
        axisLine: {       //y轴
          show: true,
          lineStyle: { color: '#ccc' }
        },
        axisTick: {       //y轴刻度线
          show: false
        },
        axisLabel: {
          show: false
        }
      },
      series: data,
    },
    handleScaleChange: (usagePeriod, usageDate) => {
      console.log(usagePeriod, usageDate)
      dispatch(state => of({ ...state, loading: true }))
      return dispatch(state => loadNodeUsage({
        ...state, loading: true,
        usagePeriod,
        usageDate,
        nodeUsage: {time: [], data:[]}
      }))
    },
    selection,
  }
  return (
    <div className="departmentRightTop">
      <Spin spinning={loading} size='large'>
        <RadioGroup size='small' className='extraButtons' onChange={(e) => {
          dispatch(state => of(r.assoc('loading', true)(state)))
          dispatch(state => loadNodeUsage({
            ...state, loading: true,
            departmentOption: e.target.value,
          }))
        }} value={departmentOption}>
          <RadioButton size='small' value="kgce">能耗</RadioButton>
          <RadioButton size='small' value="cost">费用</RadioButton>
        </RadioGroup>
        <Echarts props={echartsData} />
      </Spin>
    </div>
  )
}

export default DepartmentUsage;