import React from 'react';
import * as r from 'ramda'

import './indexMidRight.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import BorderLeft from '../../ui/borderLeft/borderLeft';
import Border4 from '../../ui/border4/border4';
import { timer } from 'rxjs';
import echarts from "echarts";


class IndexMidRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            monthTime: [],
            lastMonthTime: [],
            monthData: [],
            lastMonthData: [],
            yearTime: [],
            lastYearTime: [],
            yearhData: [],
            lastYearData: [],
            notMerge: true,
            lazyUpdate: true,
            // style: { width: "100%", height: "160px" },
            style: { width: "80%", height: "230px", left: "460px", top: "350px" },
            active: "month"
        };
    }

    async getData() {
        const month = await Api.postV3('/energyNew/dailyComparisonV3', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });
        // console.log(lastMonth)
        // console.log(year)
        let monthTime = [];
        let lastMonthTime = [];
        let monthData = [];
        let lastMonthData = [];
        if (month.data.current) {
            month.data.current.forEach(function (i, k) {
                let data = i.date.toString()
                // console.log(i.date.substr(i.date.length - 4))
                monthTime.push(r.concat(data.slice(-2), '日'))
                // monthData.push((i.cost / 10000).toFixed(2))
                monthData.push((i.value / 10000).toFixed(2))
            })
        }

        if (month.data.last) {
            month.data.last.forEach(function (i, k) {
                let data = i.date.toString()

                lastMonthTime.push(r.concat(data.slice(-2), '日'))
                // lastMonthData.push((i.cost / 10000).toFixed(2))
                lastMonthData.push((i.value / 10000).toFixed(2))
            })
        }

        this.setState({
            monthTime,
            lastMonthTime,
            monthData,
            lastMonthData,
        })
    }
    async getDataYear() {
        const year = await Api.postV3('/costNew/monthlyComparison', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });
        let yearTime = [];
        let lastYearTime = [];
        let yearhData = [];
        let lastYearData = [];
        if (year.data.current) {
            year.data.current.forEach(function (i, k) {
                let data = i.date.toString()

                yearTime.push(r.concat(data.slice(-2), '月'))
                yearhData.push((i.cost / 10000).toFixed(2))
            })
        }

        if (year.data.last) {
            year.data.last.forEach(function (i, k) {
                let data = i.date.toString()

                lastYearTime.push(r.concat(data.slice(-2), '月'))
                lastYearData.push((i.cost / 10000).toFixed(2))
            })
        }

        this.setState({
            yearTime,
            lastYearTime,
            yearhData,
            lastYearData,
        })
    }
    // 区域的数据请求时间太久，不再自动进行请求 -----
    componentDidMount() {

        // this.getData();
        // this.getDataYear();

    }


    render() {
        const { monthTime = [],
            lastMonthTime = [],
            monthData = [],
            lastMonthData = [],
            yearTime = [],
            lastYearTime = [],
            yearhData = [],
            lastYearData = [], } = this.state;
        const getOption = {
            grid: { left: '43', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis', },
            legend: {
                textStyle: {
                    color: '#459dfd'
                },
                icon: 'rect',
                left:'70%',
            },

            xAxis: {
                axisLabel: {
                    color: '#459dfd'
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: r.gte(monthTime.length, lastMonthTime.length) ? monthTime : lastMonthTime
            },
            yAxis: {
                // name: '单位:kwh/m2',
                nameGap: 10,
                nameTextStyle: { color: '#459dfd' },

                axisLabel: {
                    color: '#459dfd'
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
            },
            series: [{
                type: 'line',
                name: '本月',
                // smooth: true,
                symbol:'circle',  //这句false就是去掉点的
                symbolSize:6,
                smooth:true,  //这句就是让曲线变平滑的
                data: monthData,
                // itemStyle: {
                //     normal: {
                //         color: '#2DFAD8'
                //     }
                // },
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            {offset: 0, color: '#1bec99'},
                            {offset: 0.5, color: '#7fb83c'},
                            {offset: 1, color: '#a8c869'}
                        ]
                    )
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                        [
                        {offset: 0, color: 'rgb(186,188,51,0.6)'},
                        {offset: 0.5, color: 'rgb(148,200,63,0.3)'},
                        {offset: 1, color: 'rgb(40,235,119,0.1)'}
                    ])
                },
            }, {
                type: 'line',
                name: '上月',
                // smooth: true,
                symbol:'circle',  //这句false就是去掉点的
                symbolSize:6,
                smooth:true,  //这句就是让曲线变平滑的
                data: lastMonthData,
                // itemStyle: {
                //     normal: {
                //         color: '#FFFFFF'
                //     }
                // },
                itemStyle: {
                    color: new echarts.graphic.LinearGradient(
                        0, 0, 0, 1,
                        [
                            {offset: 0, color: '#83bff6'},
                            {offset: 0.5, color: '#188df0'},
                            {offset: 1, color: '#188df0'}
                        ]
                    )
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1,
                        [
                            {offset: 0, color: 'rgb(70,252,208,0.6)'},
                            {offset: 0.5, color: 'rgb(38,188,226,0.3)'},
                            {offset: 1, color: 'rgb(11,164,246,0.1)'}
                        ])
                },
            }]
        }
        const getOption2 = {
            grid: { left: '43', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis', },
            legend: {
                textStyle: {
                    color: '#459dfd'
                },
                icon: 'rect'
            },
            xAxis: {
                axisLabel: {
                    color: '#459dfd'
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: r.gte(yearTime.length, lastYearTime.length) ? yearTime : lastYearTime
            },
            yAxis: {
                // name: '单位:kwh/m2',
                nameGap: 10,
                nameTextStyle: { color: '#459dfd' },
                axisLabel: {
                    color: '#459dfd'
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
            },
            series: [{
                type: 'line',
                name: '今年',
                // smooth: true,
                symbol:'none',  //这句就是去掉点的
                smooth:true,  //这句就是让曲线变平滑的
                data: yearhData,
                itemStyle: {
                    normal: {
                        color: '#2DFAD8'
                    }
                },

            }, {
                type: 'line',
                name: '去年',
                // smooth: true,
                symbol:'none',  //这句就是去掉点的
                smooth:true,  //这句就是让曲线变平滑的
                data: lastYearData,
                itemStyle: {
                    normal: {
                        color: '#ffffff'
                    }
                },
            }]
        }

        return (
            <div className="indexMidRightContainerV3">
                <BorderLeft className='mb20'>
                    <div className='indexMidRightV3' >
                        <div className='indexMidRightHead'>
                            {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                                {/*<i className='round'></i>*/}
                            {/*</Border4>*/}
                            <span   style={{fontSize: '18px',
                                fontWeight: 'bold',top:'302px',width:'200px',left:'-26px',position:'absolute',color:'#92d271',fontFamily: 'monospace'}}>碳中和事项</span>
                            <div style={{fontSize: '18px',
                                fontWeight: 'bold',top:'432px',width:'200px',left:'46px',position:'absolute',color:'#92d271',fontFamily: 'monospace'}}>
                                <span style={{color:'#92d271'}}>二氧化碳</span>
                                <br/>
                                <span style={{color:'#bfd45d'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;24</span>
                            </div>
                            <div style={{fontSize: '18px',
                                fontWeight: 'bold',top:'432px',width:'200px',left:'186px',position:'absolute',color:'#92d271',fontFamily: 'monospace'}}>
                                <span style={{color:'#92d271'}}>二氧化氮</span>
                                <br/>
                                <span style={{color:'#bfd45d'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;36</span>
                            </div>
                            <div style={{fontSize: '18px',
                                fontWeight: 'bold',top:'432px',width:'200px',left:'326px',position:'absolute',color:'#92d271',fontFamily: 'monospace'}}>
                                <span style={{color:'#92d271'}}>能源消耗</span>
                                <br/>
                                <span style={{color:'#bfd45d'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;50</span>
                            </div>
                        </div>
                        {/*<ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />*/}
                    </div>
                </BorderLeft>
                {/*<BorderLeft>*/}
                    {/*<div className='indexMidRight' >*/}
                        {/*<div className='indexMidRightHead'>*/}
                            {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                                {/*<i className='round'></i>*/}
                            {/*</Border4>*/}
                            {/*<span>年度费用</span>*/}
                        {/*</div>*/}
                        {/*<ReactEcharts option={getOption2} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />*/}
                    {/*</div>*/}
                {/*</BorderLeft>*/}
            </div>
        )
    }
}

export default IndexMidRight;