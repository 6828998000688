import React from 'react';

import './indexMidLeft.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import { timer } from 'rxjs';
import Border4 from '../../ui/border4/border4';

import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';

class IndexMidLeft extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            xAxisData: [],
            waterData: [],
            electricityData: [],
            notMerge: true,
            lazyUpdate: true,
            style: { width: "100%", height: "334px" },
            active: "line"
        };
    }

    async getData(active='bar') {
        this.state = { getOption: { series: [] }, ...this.state }
        const data = new Date();
        const hh = data.getHours();
        const water = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: 'WATER'

        });

        const electricity = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: 'ELECTRICITYMETER'
        });

        let waterTime = [];
        let electricityTime = [];
        let waterData = [];
        let electricityData = [];

        // if (water.data.data) {
        //     for (let i = 0; i < hh; i++) {
        //         waterTime.push(`0${i}`.slice(-2) + ':00')
        //         waterData.push(water.data.data['hour' + i] || 0)
        //     }
        // }
        //
        // if (electricity.data.data) {
        //     for (let i = 0; i < hh; i++) {
        //         electricityTime.push(`0${i}`.slice(-2) + ':00')
        //         electricityData.push(electricity.data.data['hour' + i] || 0)
        //     }
        // }



        //改成24h后，返回的数组有两个数据，进行重新处理

        let waterRes = water.data.data;
        if (waterRes&&waterRes[0]) {
            for (let key in waterRes[0]) {
                waterTime.push('昨日'+key.replace('ys_hour','')+':00')
                waterData.push(Number(waterRes[0][key]).toFixed(2) || '0.00')
            }
        }
        if (waterRes&&waterRes[1]) {
            for (let key in waterRes[1]) {
                waterTime.push(key.replace('hour','')+':00')
                waterData.push(Number(waterRes[1][key]).toFixed(2) || '0.00')
            }
        }


        let eleRes = electricity.data.data;
        if (eleRes&&eleRes[0]) {
            for (let key in eleRes[0]) {
                electricityTime.push('昨日'+key.replace('ys_hour','')+':00')
                electricityData.push(Number(eleRes[0][key]).toFixed(2) || '0.00')
            }
        }
        if (eleRes&&eleRes[1]) {
            for (let key in eleRes[1]) {
                electricityTime.push(key.replace('hour','')+':00')
                electricityData.push(Number(eleRes[1][key]).toFixed(2) || '0.00')
            }
        }

        this.setState({
            xAxisData: waterTime.length > electricityTime.length ? waterTime : electricityTime,
            waterData,
            electricityData,
            active: active
        })
    }

    componentDidMount() {

        const upDataTime = 5 * 60 * 1000;//分钟
        const source = timer(0, upDataTime);
        source.subscribe((x) => { console.log('indexTopLeft:' + x); this.getData() })
    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { xAxisData, active, waterData, electricityData } = this.state;


        let getOption = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [
                {
                    name: '水',
                    type: 'line',
                    data: waterData,
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#03A9FF'
                        }
                    },
                    // emphasis: { label: { show: true } }
                }, {
                    type: 'line',
                    name: '电',
                    data: electricityData,
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#FD7F33'
                        }
                    },
                }]
        }


        return (
            <div className='indexMidLeft'>
                <div className='indexMidLeftHead'>
                    <Border4 className='Angle' sideLength={'5px'}>
                        <i className='round'></i>
                    </Border4>
                    <span>分类用量(kwh)</span>

                </div>
                <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
            </div>
        )
    }
}


export default IndexMidLeft;
