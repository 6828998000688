import React from 'react';
import {Checkbox, Col, DatePicker, Form, Radio, Row,Select,Input} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import './newRegisterInfo.scss'
import {
	groupFormItemLayout,
	projectDefaultModules,
	projectModules,
	rightFormItemLayout,
	updateProjectFormLayout
} from "src/context/project";
import {formItemLayout} from "../../context/project";

const {RangePicker} = DatePicker

const {Option} = Select;
const filterModulesByType = type => r.filter(r.flip(r.includes)(r.keys(r.propOr({}, type, projectModules))))
const UpdateProjectForm = props => {
	const {getFieldDecorator, getFieldValue} = props.form;
	const {project} = props;
	const disabledDate = (current) => {
		return current && current < moment().subtract(1, 'days');
	}
	const getTimeValue = (start, end) => {
		const span = Math.floor(moment.duration(moment(end).diff(moment(start))).asDays())
		return r.includes(span, [31, 90, 36500]) ? span : '自定义'
	}
	return (
		<div className="newProject">
			<Form className='newProjectForm'>
				<Row>
					<div>
						<h4>模块管理</h4>
						<Form.Item {...updateProjectFormLayout}
											 label="有效日期"
						>
							{getFieldDecorator('time', {
								initialValue: project.time || getTimeValue(project.createTime, project.stopTime),
							})(
								<Radio.Group>
									<Radio value={31}>一个月</Radio>
									<Radio value={90}>三个月</Radio>
									<Radio value={36500}>永久</Radio>
									<Radio value="自定义">自定义</Radio>
								</Radio.Group>
							)}
						</Form.Item>
						<Form.Item {...updateProjectFormLayout}
						>
							{getFieldDecorator('timeRange', {
								initialValue: [moment(project.createTime),
									getFieldValue('time') === '自定义' ?
										moment(project.stopTime) :
										moment(project.createTime).add(getFieldValue('time'), 'day')],
							})(
								<RangePicker
									disabled={getFieldValue('time') !== '自定义'}
									disabledDate={disabledDate}
									format={'YYYY-MM-DD'}
									// value={() => console.log(getFieldValue('time'))}
								/>
							)}
						</Form.Item>
						<Form.Item {...updateProjectFormLayout}
											 label="项目类别"
						>
							{getFieldDecorator('category', {
								initialValue: project.category || project.originalType,
							})(
								<Radio.Group>
									<Radio value="BASIC" >计量基础版</Radio>
									<Radio value="ANALYSIS" >能源计量分析版本</Radio>
								</Radio.Group>
							)}
						</Form.Item>
						<Form.Item {...rightFormItemLayout}
											 label="网页前台模块："
						>
							{getFieldDecorator('web', {
								initialValue: filterModulesByType('web')(project.modules) || r.map(([v, k]) => (
									v
								))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'web'])(projectDefaultModules)))
							})(
								<Checkbox.Group style={{width: "100%"}}>
									<Row>
										{
											r.map(([v, k]) => (
												<Col {...groupFormItemLayout} key={v}>
													<Checkbox value={v}>{k}</Checkbox>
												</Col>
											))(r.toPairs(r.propOr({})('web')(projectModules)))
										}
									</Row>
								</Checkbox.Group>
							)}
						</Form.Item>
						<Form.Item {...rightFormItemLayout}
											 label="综合分析二级："
						>
							{getFieldDecorator('analysis', {
								initialValue: filterModulesByType('analysis')(project.modules) || r.map(([v, k]) => (
									v
								))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'analysis'])(projectDefaultModules)))
							})(
								<Checkbox.Group style={{width: "100%"}}>
									<Row>
										{
											r.map(([v, k]) => (
												<Col {...groupFormItemLayout} key={v}>
													<Checkbox disabled={!r.includes('analysis', getFieldValue('web'))}
																		value={v}>{k}</Checkbox>
												</Col>
											))(r.toPairs(r.propOr({})('analysis')(projectModules)))
										}
									</Row>
								</Checkbox.Group>
							)}
						</Form.Item>

						<Form.Item {...rightFormItemLayout}
											 label="网页后台模块："
						>
							{getFieldDecorator('admin', {
								initialValue: filterModulesByType('admin')(project.modules) || r.map(([v, k]) => (
									v
								))(r.toPairs(r.pathOr({})([getFieldValue('category'), 'admin'])(projectDefaultModules)))
							})(
								<Checkbox.Group style={{width: "100%"}}>
									<Row>
										{
											r.map(([v, k]) => (
												<Col {...groupFormItemLayout} key={v}>
													<Checkbox value={v}>{k}</Checkbox>
												</Col>
											))(r.toPairs(r.propOr({})('admin')(projectModules)))
										}
									</Row>
								</Checkbox.Group>
							)}
						</Form.Item>

						<Form.Item {...rightFormItemLayout}
											 label="定制功能："
						>
							{getFieldDecorator('extra', {
								initialValue: filterModulesByType('extra')(project.modules) || [] })(
								<Checkbox.Group style={{width: "100%"}}>
									<Row>
										{
											r.map(([v, k]) => (
												<Col {...groupFormItemLayout} key={v}>
													<Checkbox value={v}>{k}</Checkbox>
												</Col>
											))(r.toPairs(r.propOr({})('extra')(projectModules)))
										}
									</Row>
								</Checkbox.Group>
							)}
						</Form.Item>
                        <Form.Item {...rightFormItemLayout}
                                   label="归属区域"
                        >
                            {getFieldDecorator('region', {
                                initialValue: project.region,
                            })(
                                <Select>
                                    {/*<Option value={0}>不可查看</Option>*/}
                                    {/*<Option value={330300}>温州市</Option>*/}
                                    <Option value={"330301"}>市辖区</Option>
                                    <Option value={"330302"}>鹿城区</Option>
                                    <Option value={"330303"}>龙湾区</Option>
                                    <Option value={"330304"}>瓯海区</Option>
                                    <Option value={"330305"}>洞头区</Option>
                                    <Option value={"330324"}>永嘉县</Option>
                                    <Option value={"330326"}>平阳县</Option>
                                    <Option value={"330327"}>苍南县</Option>
                                    <Option value={"330328"}>文成县</Option>
                                    <Option value={"330329"}>泰顺县</Option>
                                    <Option value={"330371"}>温州高新区(经开区)</Option>
                                    <Option value={"330381"}>瑞安市</Option>
                                    <Option value={"330382"}>乐清市</Option>
                                    <Option value={"330354"}>温州生态园</Option>
                                    <Option value={"330355"}>瓯江口</Option>
                                    <Option value={"330383"}>龙港市</Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item {...rightFormItemLayout}
                                   label="项目经度"
                        >
                            {getFieldDecorator('longitude2', {
                                initialValue: project.longitude2,
                                rules: [{required: true, message: '经度不能为空，小数保留4位'}, {
                                    // min: 6,
                                    message: '经度不能为空，小数保留4位',
                                    // whitespace: true
                                }, {pattern: /^[0-9]*[.][0-9]{4}/, message: '经度不能为空，小数保留4位', whitespace: true}],
                            })(
                                <Input placeholder="xx.xxxx"/>
                            )}

                        </Form.Item>
                        <Form.Item {...formItemLayout}
                                   label="项目纬度"
                        >
                            {getFieldDecorator('latitude2', {
                                initialValue: project.latitude2,
                                rules: [{required: true, message: '纬度不能为空，小数保留4位'}, {
                                    // min: 6,
                                    message: '纬度不能为空，小数保留4位',
                                    // whitespace: true
                                }, {pattern: /^[0-9]*[.][0-9]{4}/, message: '纬度不能为空，小数保留4位', whitespace: true}],
                            })(
                                <Input placeholder="xx.xxxx"/>
                            )}
                        </Form.Item>
                        <Form.Item {...formItemLayout}
                                   label="建筑编号"
                        >
                            {getFieldDecorator('building', {
                                initialValue: project.building,
                                rules: [{required: true, message: '只能为数字和字母'}, {
                                    // min: 6,
                                    message: '只能为数字和字母',
                                    // whitespace: true
                                }, {pattern: /^[0-9a-zA-Z]*$/, message: '只能为数字和字母', whitespace: true}],
                            })(
                                <Input/>
                            )}

                        </Form.Item>
                        <Form.Item {...formItemLayout}
                                   label="父节点编号"
                        >
                            {getFieldDecorator('parent', {
                                initialValue: project.parent,
                                rules: [{required: true, message: '只能为数字'}, {
                                    // min: 6,
                                    message: '只能为数字',
                                    // whitespace: true
                                }, {pattern: /^[0-9]*$/, message: '只能为数字', whitespace: true}],
                            })(
                                <Input/>
                            )}

                        </Form.Item>
                        <Form.Item {...formItemLayout}
                                   label="路径编号"
                        >
                            {getFieldDecorator('path', {
                                initialValue: project.path,
                                rules: [{required: true, message: '只能为数字'}, {
                                    // min: 6,
                                    message: '只能为数字',
                                    // whitespace: true
                                }, {pattern: /^[0-9]*$/, message: '只能为数字', whitespace: true}],
                            })(
                                <Input/>
                            )}

                        </Form.Item>
					</div>

				</Row>
			</Form>
		</div>
	);
}

export default UpdateProjectForm;