import React, { useState, useEffect } from 'react';
import { Modal, Radio, DatePicker } from 'antd';
import RractEcharts from 'echarts-for-react';
import Api from 'api';
import * as r from 'ramda';
import './waterMeter.scss';

import Img_waterSuc from 'img/water/groupSuc.png';
import Img_waterErr from 'img/water/groupErr.png';
import moment from 'moment';

const echartsData = {
    color: ["#77d3f6", "#D3D7E3"],
    grid: { left: '20', top: '25', right: '20', bottom: '22' },
    tooltip: { trigger: 'axis' },
    legend: {
    },
    xAxis: {
        type: 'category'
    },
    yAxis: {
        show: false,
        type: 'value'
    },
    series: [{
        type: 'bar',
        name: '今年',
        smooth: true,
        data: []
    }]
}

const timeFormat = (v) => {
    const typeMap = {
        DAY: 'HH:00',
        WEEK: 'dddd',
        MONTH: 'DD[日]',
        YEAR: 'Mo',
    }
    return r.propOr(v)(v)(typeMap)
}


const WaterMeter = (props) => {
    const { value, position, status, flowId, temperatureId, title, isExtra, id } = props;
    const [modalIsShow, setModalIsShow] = useState(false);
    const [echarData, setEcharData] = useState(echartsData);
    const [dataState, setDataState] = useState({ flowAndTemperature: 'flow', timeType: 'DAY', dataType: 'difference', echartType: 'bar', date: moment().format('YYYYMMDD') });
    useEffect(() => { updata(dataState) }, [dataState])

    const handleOk = () => {
        setModalIsShow(false)
    }
    const handleCancel = () => {
        setModalIsShow(false)
    }

    const updata = async ({ flowAndTemperature, timeType, dataType, echartType, date }) => {
        const { data: { result: { detail = [] } } } = await Api.post('/business/channeldetail', {
            from: date,
            id: flowAndTemperature === 'flow' ? flowId : temperatureId,
            timeformat: timeType,
            to: date,
        })


        setEcharData(
            {
                color: ["#77d3f6", "#D3D7E3"],
                grid: { left: '10', top: '25', right: '20', bottom: '22', containLabel: true },
                tooltip: { trigger: 'axis' },
                legend: {
                },
                xAxis: {
                    type: 'category',
                    data: r.values(r.map(v => (moment(v.timepoint).format(timeFormat(timeType))))(detail))
                },
                yAxis: {
                    show: false,
                    type: 'value'
                },
                series: [{
                    type: echartType,
                    name: title,
                    smooth: true,
                    data: r.values(r.map(v => (dataType === 'difference' ? v.value : v.total))(detail)),
                    label: {
                        show: true,
                        position: 'top'
                    }
                }]

            }
        )
    }



    const flowAndTemperature = e => {
        setDataState({ ...dataState, flowAndTemperature: e.target.value })
    }
    const timeType = e => {
        setDataState({ ...dataState, timeType: e.target.value })
    }
    const echartType = e => {
        setDataState({ ...dataState, echartType: e.target.value })
    }
    const dataType = e => {
        setDataState({ ...dataState, dataType: e.target.value })
    }
    const onChange = (date, dateString) => {
        setDataState({ ...dataState, date: date ? date.format('YYYYMMDD') : moment() })
    }
    const modalTitle = <p><span>{title}</span> <span style={{ fontSize: '12px', fontWeight: 'normal' }}>id:{id}</span></p>


    return (
        <div className='waterMeter' style={position} >
            <div onClick={() => { updata(dataState); setModalIsShow(true) }} >
                {
                    isExtra ?
                        (
                            <div><span>{title}</span> <span>{value} m³</span></div>)
                        :
                        status === 0 ? (<div><img src={Img_waterSuc} alt="" /> <p style={position}>{value} m³</p></div>) : (<div><img src={Img_waterErr} alt="" /> <p style={position}> {value} m³</p></div>)
                }
            </div>

            <Modal
                title={modalTitle}
                visible={modalIsShow}
                onOk={handleOk}
                onCancel={handleCancel}
                width={'90%'}
                footer={null}
                className='waterMeterModal'
            >
                <div className='modalBodyHead clearfix'>

                    <div className='modalBodyHeadLeft'>
                        <Radio.Group defaultValue="flow" buttonStyle="solid" onChange={flowAndTemperature}>
                            <Radio.Button value="flow">累积流量</Radio.Button>
                            <Radio.Button value="temperature">供水温度</Radio.Button>
                        </Radio.Group>
                    </div>

                    <div className='modalBodyHeadRight'>
                        <DatePicker onChange={onChange} defaultValue={moment()} disabledDate={current => current && current > moment().endOf('day')} />

                        <Radio.Group defaultValue="DAY" buttonStyle="solid" onChange={timeType}>
                            <Radio.Button value="DAY">日</Radio.Button>
                            <Radio.Button value="WEEK">周</Radio.Button>
                            <Radio.Button value="MONTH">月</Radio.Button>
                            <Radio.Button value="YEAR">年</Radio.Button>
                        </Radio.Group>

                        <Radio.Group defaultValue="bar" buttonStyle="solid" onChange={echartType}>
                            <Radio.Button value="line">折线</Radio.Button>
                            <Radio.Button value="bar">柱状</Radio.Button>
                        </Radio.Group>

                        <Radio.Group defaultValue="difference" buttonStyle="solid" onChange={dataType}>
                            <Radio.Button value="difference">差值</Radio.Button>
                            <Radio.Button value="scale">刻度</Radio.Button>
                        </Radio.Group>
                    </div>

                </div>
                <RractEcharts option={echarData} />
            </Modal>
        </div >
    )
}




export default WaterMeter;