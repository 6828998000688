import XLSX from 'xlsx';
import * as r from 'ramda';
function Excel(headers, data, fileName = '能源监测系统报表.xlsx') {

    const _headers = r.mergeAll(headers.map(({ dataIndex, title:v }, i) => ({ [String.fromCharCode(65 + i) + 1]: { v, dataIndex } })))

    const _data = data.map(
        (item, i) => (
            headers.map((dataIndex, j) => (
                { [String.fromCharCode(65 + j) + (i + 2)]: { v: item[dataIndex.dataIndex] } }
            )
            )
        )
    )
    const dataArr = r.mergeAll(r.map((v) => (v))(r.unnest(_data)))
    // // 合并 headers 和 data
    const output = r.mergeAll([_headers, dataArr])
    // // 获取所有单元格的位置
    const outputPos = r.keys(output);

    // // 计算出范围 ,["A1",..., "H2"]
    const ref = `${outputPos[0]}:${outputPos[outputPos.length - 1]}`;

    // // 构建 workbook 对象
    const wb = {
        SheetNames: ['mySheet'],
        Sheets: {
            mySheet: Object.assign(
                {},
                output,
                {
                    '!ref': ref,
                    // '!cols': [{ wpx: 45 }, { wpx: 100 }, { wpx: 200 }, { wpx: 80 }, { wpx: 150 }, { wpx: 100 }, { wpx: 300 }, { wpx: 300 }],
                },
            ),
        },
    };

    // 导出 Excel
    XLSX.writeFile(wb, fileName);
}


export default Excel;
