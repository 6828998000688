import React, {useContext} from 'react';
import * as r from 'ramda';
import moment from 'moment';

import {Col, Row, Spin} from 'antd';

import Echarts from 'src/components/echarts/echarts';
import EnergyRanking from './energyRanking';
import ReactEcharts from 'echarts-for-react';
import './energy.scss';
import Api from 'api';
import withStates from 'src/context/withStates';
import {from} from 'rxjs';

const translateSubentry = subentry => {
    const subentryMap = {
        lightrcptcl: '照明插座',
        specialele: '特殊用电',
        airele: '空调用电',
        powerele: '动力用电',
    }
    return r.propOr(subentry)(subentry.toLowerCase())(subentryMap)
}

function readSubentry(api) {
    return Api.get(
        api)
        .then(
            ({
                 data: {
                     total = 0, lightrcptcl = 0,
                     powerele = 0, airele = 0, specialele = 0,
                 },
             }) => ({
                lightRcptclEle: lightrcptcl.toFixed(2),
                airEle: airele.toFixed(2),
                powerEle: powerele.toFixed(2),
                specialEle: specialele.toFixed(2),
                total: total.toFixed(2),
            }));
}

const initState = {
    loading: true,
    subentryData: {
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: ['50%', '70%'],
                avoidLabelOverlap: false,
                stillShowZeroSum: false,
                label: {
                    normal: {
                        show: false,
                        position: 'center',
                    },
                    emphasis: {
                        show: true,
                        textStyle: {
                            fontSize: '30',
                            fontWeight: 'bold',
                        },
                    },
                },
                labelLine: {
                    normal: {
                        show: false,
                    },
                },
                data: [],
            }],
    },
    ranking: {
        color: ['#86CCFF', '#FFAD33'],
        grid: {left: '30', top: '25', right: '30', bottom: '22'},
        legend: {},
        xAxis: {
            type: 'category',
            data: [],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [],
                type: 'bar',
            }],
    },
    subentryComparison: {
        color: ['#86CCFF', '#FFAD33'],
        grid: {left: '30', top: '25', right: '30', bottom: '22'},
        legend: {},
        xAxis: {
            type: 'category',
            data: ['照明插座', '特殊用电', '空调用电', '动力用电'],
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [],
                type: 'bar',
            }, {
                data: [],
                type: 'bar',
            }],
    },
    dataOverDays: {
        color: ['#86CCFF', '#FFAD33'],
        grid: {left: '30', top: '25', right: '30', bottom: '22'},
        legend: {},
        xAxis: {
            type: 'category',
            data: [],
        },
        yAxis: {
            type: 'value',
        },
        series: [],
    },
}

export const loadAll = (state) => from(fetchData(state))

const fetchData = async ({subentryOption = 'lightrcptcl', subentryRankDate = 'rankingDaily'}) => {
    const {
        lightRcptclEle, airEle, powerEle,
        specialEle, total: dailyTotal,
    } = await readSubentry(
        `/api/v3/project/${Api.projectId}/subentry/ratioDaily`)
    const yesterday = await readSubentry(
        `/api/v3/project/${Api.projectId}/subentry/ratioDaily?date=${moment()
            .subtract(1, 'day')
            .format('YYYYMMDD')}`)

    const {data: ranking} = await Api.get(`/api/v3/project/${Api.projectId}/subentry/${subentryOption}/${subentryRankDate}`);

    const {data: subentryOverDays} = await Api.get(
        `/api/v3/project/${Api.projectId}/subentry/monthly`)

    const itemStyle = {
        normal: {
            show: true,
            position: 'top',
            // distance: app.config.distance,
            align: 'center',
            verticalAlign: 'middle',
            // rotate: app.config.rotate,
            formatter: '{c}  {name|{a}}',
            fontSize: 16,
            rich: {
                name: {
                    textBorderColor: '#fff'
                }
            }
        },
        emphasis: {
            barBorderWidth: 1,
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowColor: 'rgba(0,0,0,0.5)',
        },
    };
    return {
        loading: false,
        dailyTotal,
        subentryOption,
        subentryRankDate,
        subentryData: {
            legend: {
                // orient: 'horizontal',
                x: 'center',
                y: 'bottom',
                icon: 'circle',
                // formatter: '{name}0',
            },
            series: [
                {
                    type: 'pie',
                    radius: ['50%', '70%'],
                    avoidLabelOverlap: false,
                    stillShowZeroSum: false,
                    label: {
                        normal: {
                            show: false,
                            position: 'center',
                        },
                        emphasis: {
                            show: true,
                            textStyle: {
                                fontSize: '30',
                                fontWeight: 'bold',
                            },
                        },
                    },
                    labelLine: {
                        normal: {
                            show: false,
                        },
                    },
                    data: [
                        {
                            value: lightRcptclEle === '0.00' ? '1' : lightRcptclEle,
                            name: '照明插座',
                            itemStyle: {color: '#87E6FF'},
                        },
                        {value: airEle === '0.00' ? '1' : airEle, name: '空调用电', itemStyle: {color: '#86CCFF'}},
                        {value: powerEle === '0.00' ? '1' : powerEle, name: '动力用电', itemStyle: {color: '#F8E61A'}},
                        {
                            value: specialEle === '0.00' ? '1' : specialEle,
                            name: '特殊用电',
                            itemStyle: {color: '#FFAD33'},
                        },
                    ],
                }],
        },
        subentryComparison: {
            color: ['#86CCFF', '#FFAD33'],
            grid: {left: '30', top: '25', right: '30', bottom: '22', containLabel: true},
            legend: {
                data: ['今天', '昨天'],
                align: 'left',
            },
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'category',
                data: ['照明插座', '空调用电', '动力用电', '特殊用电'],
            },
            yAxis: {
                type: 'value',
                max: (value) => Math.ceil(value.max / 100) * 100 + 500
            },
            series: [
                {
                    name: '今天',
                    data: [lightRcptclEle, airEle, powerEle, specialEle],
                    type: 'bar',
                    itemStyle,
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            distance: 10,
                            align: 'center',
                            verticalAlign: 'middle',
                        },
                    }
                }, {
                    name: '昨天',
                    data: r.values(r.omit(['total'])(yesterday)),
                    type: 'bar',
                    itemStyle,
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            distance: 10,
                            align: 'center',
                            verticalAlign: 'middle',
                        },
                    }
                }],
        },
        ranking: {
            color: ['#86CCFF', '#FFAD33'],
            grid: {left: '30', top: '25', right: '30', bottom: '22', containLabel: true},
            legend: {},
            tooltip: {trigger: 'axis', formatter: '{b}: {c}'},
            xAxis: {
                type: 'category',
                data: r.range(1, 21),
            },
            yAxis: {
                type: 'value',
            },
            series: [
                {
                    data: r.map(x => ({value: Number(x.value).toFixed(2), name: x.title}))(ranking),
                    type: 'bar',
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            distance: 10,
                            align: 'center',
                            verticalAlign: 'middle',
                        },
                    }
                }],
        },
        dataOverDays: {
            color: ['#87E6FF', '#86CCFF', '#F8E61A', '#FFAD33'],
            grid: {left: '30', top: '25', right: '30', bottom: '22', containLabel: true},
            legend: {
                data: r.map(translateSubentry)(r.keys(subentryOverDays)),
                align: 'left',
            },
            tooltip: {trigger: 'axis',},
            xAxis: {
                type: 'category',
                data: r.map(x => `${x.slice(-4, -2)}月${x.slice(-2)}日`)(
                    r.keys(r.prop(r.head(r.keys(subentryOverDays)), subentryOverDays))),
                // silent: false,
                // axisLine: { onZero: true },
                // splitLine: { show: false },
                // splitArea: { show: false },
            },
            yAxis: {
                type: 'value',
                splitArea: {show: false},
            },
            series: r.map(([name, data]) => ({
                name: translateSubentry(name),
                type: 'bar',
                stack: 'one',
                data: r.map(x => Number(x).toFixed(2))(r.values(data)),
                itemStyle,

            }))(r.toPairs(subentryOverDays)),
        },
    }
}

const SubentryAnalysis = () => {
    const {state, dispatch} = useContext(context)
    const {loading, dailyTotal, subentryData, subentryComparison, dataOverDays} = state;

    const echartsData3 = {
        tit: '日分项用能对比',
        ulShow: ['upload'],
        data: subentryComparison,
    }
    const echartsData4 = {
        tit: '近期能耗同比分析',
        ulShow: ['upload'],
        data: dataOverDays,
    }

    return (
        <div className='zEnergy'>
            <Spin spinning={loading} size='large'>
                <div className='energyTop'>
                    <div className="energyDiv_s energyDiv1">
                        <Row>
                            <Col span={4}>分项能耗占比</Col>
                            <Col span={3} offset={17}>单位：kWh</Col>
                        </Row>
                        <ReactEcharts option={subentryData}/>
                        <div className='summary'>
                            <Row>
                                <Col span={3} offset={16}>总能耗</Col>
                            </Row>
                            <Row>
                                <Col span={3} offset={16}
                                     className='daily-total-bold'>{dailyTotal}</Col>
                            </Row>
                        </div>

                    </div>
                    <EnergyRanking/>
                </div>
                <div className='energyBottom'>
                    <div className="energyDiv_s energyDiv3">
                        <Echarts props={echartsData3}/>
                    </div>
                    <div className="energyDiv_b energyDiv4">
                        <Echarts props={echartsData4}/>
                    </div>
                </div>
            </Spin></div>
    )
}

export const {context, Component} = withStates(
    SubentryAnalysis,
    initState,
    loadAll,
)

export default Component