import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, NavLink, hashHistory, Redirect } from "react-router-dom";
import * as r from 'ramda';

import Water from './water/water';
import ULTRACOLDWATERMETER from './ultraWater/ultraWater';
import ENERGYMETER from './energymener/energymener';
import COLDWATERMETER from './coldWater/coldWater2';
import HOTWATERMETER from './hotWater/hotWater2';
import ELECTRICITYMETER from './electricity/electricity';
import MELECTRICITYMETER from './mElectricity/mElectricity';
import Manager from './manager/manager';
import TIMERMETER from './time/time';
import TEMPERATURECONTROL from './temperature/temperature';
import PRESSUREMETER from './pressure/pressure';
import TEMPHUMMETER from './temperatureAndHumidity/temperatureAndHumidity';
import TableTest from './table';

import { Spin } from 'antd';
import { context, loadDevices } from '../equipment';
import { of } from "rxjs";

const navClick = dispatch => devicetype => e => {
    console.log('nav clicked', e);
    console.log(devicetype)
    console.log(dispatch)
    dispatch(r.pipe(r.assoc('loading', true), of))
    dispatch(state => loadDevices({ ...state, loading: true, devicetype }))
}


const EquipmentTable = () => {
    const { state: { nav = [], devicetype }, dispatch } = useContext(context)
    return (
        <div className="equipmentRight">
            <Router>
                <div>
                    <nav className='reportNav'>
                        <ul>
                            {nav.map((k, i) => (
                                <li key={k.id} onClick={navClick(dispatch)(k.id)} className={devicetype === k.id ? 'active' : ''}>
                                    <span>{k.name}</span>
                                </li >))
                            }
                        </ul>
                    </nav>

                    <TableTest />
                    {/* {r.isEmpty(nav) ? null : <Redirect to={`/Equipment/${nav[0].id}`} />} */}
                    {/* <Route path="/Equipment/Water" exact component={Water} />
                    <Route path="/Equipment/COLDWATERMETER" component={COLDWATERMETER} />
                    <Route path="/Equipment/HOTWATERMETER" component={HOTWATERMETER} />
                    <Route path="/Equipment/ENERGYMETER" exact component={ENERGYMETER} />
                    <Route path="/Equipment/ULTRACOLDWATERMETER" component={ULTRACOLDWATERMETER} />
                    <Route path="/Equipment/ELECTRICITYMETER" component={ELECTRICITYMETER} />
                    <Route path="/Equipment/MELECTRICITYMETER" component={MELECTRICITYMETER} /> */}
                    {/*<Route path="/Equipment/Manager" component={Manager} />*/}
                    {/* <Route path="/Equipment/TIMERMETER" component={TIMERMETER} />
                    <Route path="/Equipment/TEMPERATURECONTROL" component={TEMPERATURECONTROL} />
                    <Route path="/Equipment/PRESSUREMETER" component={PRESSUREMETER} />
                    <Route path="/Equipment/TEMPHUMMETER" component={TEMPHUMMETER} /> */}
                </div>
            </Router>

        </div>

    )
}

export default EquipmentTable;