import React from 'react';

import './stateAllStates.scss'
// import Api from 'api'

import IndexMidStateAllStates from './indexMid/indexMidStateAllStates'

class StateAllStates extends React.Component {

  render() {
    return (
      <div className="indexAverage">
        <IndexMidStateAllStates />
      </div>
    )
  }

};


export default StateAllStates;