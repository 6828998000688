import React from 'react';

import './sms.scss'
// import Api from 'api'

import IndexMidSms from './indexMid/indexMidSms'

class Sms extends React.Component {

  render() {
    return (
      <div className="indexAverage">
        <IndexMidSms />
      </div>
    )
  }

};


export default Sms;