import React from 'react';

import './indexMidLeft.scss';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts'
import Api from 'api';
import { timer } from 'rxjs';
import Border4 from '../../ui/border4/border4';
import * as r from 'ramda';

import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';

class IndexMidLeft extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            xAxisData: [],
            waterData: [],
            electricityData: [],
            notMerge: true,
            lazyUpdate: true,
            style: { width: "100%", height: "230px" },
            active: "bar"
        };
    }

    async getData(active='bar') {
        this.state = { getOption: { series: [] }, ...this.state }
        const data = new Date();
        const hh = data.getHours();
        const water = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: 'WATER'

        });

        const electricity = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: 'ELECTRICITYMETER'
        });

        let waterTime = [];
        let electricityTime = [];
        let waterData = [];
        let electricityData = [];

        // if (water.data.data) {
        //     for (let i = 0; i < hh; i++) {
        //         waterTime.push(`0${i}`.slice(-2) + ':00')
        //         waterData.push(water.data.data['hour' + i] || 0)
        //     }
        // }
        //
        // if (electricity.data.data) {
        //     for (let i = 0; i < hh; i++) {
        //         electricityTime.push(`0${i}`.slice(-2) + ':00')
        //         electricityData.push(electricity.data.data['hour' + i] || 0)
        //     }
        // }



        //改成24h后，返回的数组有两个数据，进行重新处理

        let waterRes = water.data.data;
        // console.log('waterRes: ' + waterRes);
        if (waterRes&&waterRes[0]) {
            for (let key in waterRes[0]) {
                // waterTime.push('昨日'+key.replace('ys_hour','')+':00')
                waterTime.push(key.replace('ys_hour','')+':00')
                waterData.push(Number(waterRes[0][key]).toFixed(2) || '0.00')
            }
        }
        if (waterRes&&waterRes[1]) {
            for (let key in waterRes[1]) {
                waterTime.push(key.replace('hour','')+':00')
                waterData.push(Number(waterRes[1][key]).toFixed(2) || '0.00')
            }
        }


        let eleRes = electricity.data.data;
        if (eleRes&&eleRes[0]) {
            for (let key in eleRes[0]) {
                // electricityTime.push('昨日'+key.replace('ys_hour','')+':00')
                electricityTime.push(key.replace('ys_hour','')+':00')
                electricityData.push(Number(eleRes[0][key]).toFixed(2) || '0.00')
            }
        }
        if (eleRes&&eleRes[1]) {
            for (let key in eleRes[1]) {
                electricityTime.push(key.replace('hour','')+':00')
                electricityData.push(Number(eleRes[1][key]).toFixed(2) || '0.00')
            }
        }

        this.setState({
            xAxisData: waterTime.length > electricityTime.length ? waterTime : electricityTime,
            waterData,
            electricityData,
            active: active
        })
    }


    componentDidMount() {
        this.getData();
        // 区域的数据请求时间太久，不再自动进行请求 -----
        // const upDataTime = 5 * 60 * 1000;//分钟
        // const source = timer(0, upDataTime);
        // source.subscribe((x) => { console.log('indexTopLeft:' + x); this.getData() })
    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { xAxisData, active, waterData, electricityData } = this.state;


        let getOption = {
            grid: { left: '55', top: '35', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#459dfd'
                },
                icon: 'rect',
                top:'1%',
                left:'73%'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    color: '#459dfd'
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#459dfd' }
            },
            dataZoom: [
                {
                    type: 'inside'
                }
            ],
            series: [
                //    背景颜色
                { // For shadow
                    type: 'bar',
                    itemStyle: {
                        // color: '#193f75',
                        color: 'rgb(25,63,117,0.4)',
                    },
                    // barWidth:40,
                    barGap: '-100%',
                    barCategoryGap: '40%',
                    data: Array(electricityData.length).fill(r.apply(Math.max)(electricityData)),
                    animation: false
                },
                {
                    name: '水',
                    type: 'bar',
                    data: waterData,
                    smooth: true,
                    // itemStyle: {
                    //     normal: {
                    //         color: '#03A9FF'
                    //     }
                    // },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#83bff6'},
                                {offset: 0.5, color: '#188df0'},
                                {offset: 1, color: '#188df0'}
                            ]
                        )
                    },
                    // emphasis: { label: { show: true } }
                }, {
                    type: 'bar',
                    name: '电',
                    data: electricityData,

                    smooth: true,
                    // itemStyle: {
                    //     normal: {
                    //         color: '#FD7F33'
                    //     }
                    // },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 1, 0, 0,
                            [
                                {offset: 0, color: '#13a1ed'},
                                {offset: 0.5, color: '#25c2d9'},
                                {offset: 1, color: '#3beccf'}
                            ]
                        )
                    },
                },
            ]
        }


        return (
            <div className='indexMidLeftV3'>
                <div className='indexMidLeftHead'>
                    {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                        {/*<i className='round'></i>*/}
                    {/*</Border4>*/}
                    <span style={{fontSize: '18px',
                        fontWeight: 'bold',left:'10px',top:'1px',position:'absolute',  color: '#92d271',
                        fontFamily: 'monospace'}}>分类用量</span>

                </div>
                <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
            </div>
        )
    }
}


export default IndexMidLeft;
