import React, {useContext,useState,useEffect} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import iconv  from 'iconv-lite'
import moment from 'moment';
import {context, loadNodeUsage} from '../../drawingMonitor'
// import {context, loadNodeUsage} from '../indexMidAverage'
import {of, from} from 'rxjs'

import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';
import echarts from "echarts/lib/echarts";
import axios from 'axios';
import Util2  from 'src/util/util2';
import './intervalReport.scss'


const DecodeGB2312 = function (val) {
    return iconv.decode(new Buffer(val, 'binary'), 'GB2312');
};



const projectTypeTransfer= {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}

const projectTypeWeight= {
    SPORT: 1,
    CBD: 2,
    HOSPITAL: 3,
    HOTEL: 4,
    MARKET: 5,
    OFFICE: 6,
    TEACH: 7,
    OTHER: 8,
}


const IntervalReport = () => {
    const {state: {loading, tableData, from, to, deviceType, select, total}, dispatch} = useContext(context);
    // const [tableData, setTableData] = useState([]);
    console.log('tableData-interval');
    console.log(tableData);

    const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData));
    const columns = [
        {
        title: '序号',
        dataIndex: 'order',
        key: 'order',
        width: '4%',
    }, {
            title: '项目名称',
            dataIndex: 'PrjName',
            key: 'PrjName',
            width: '8%',
            // render: (text, record) => (
            //     <Tooltip placement="top" title={text}>
            //         <span className='tableName'>{text}</span>
            //     </Tooltip>
            // ),
        },
        {
        title: '立项地(立项编码)',
        dataIndex: 'CountyNum',
        key: 'CountyNum',
        width: '8%',
        // render: (text, record) => (
        //     <Tooltip placement="top" title={text}>
        //         <span className='tableName'>{text}</span>
        //     </Tooltip>
        // ),
    },{
        title: '立项地(立项地址)',
        dataIndex: 'CountyName',
        key: 'CountyName',
        width: '6%',
        // className: 'hide',
    }, {
        title: '项目地址',
        dataIndex: 'Address',
        key: 'Address',
        width: '6%',
        // className: 'hide',
    },{
        title: '总建筑面积(m²)',
        dataIndex: 'TotalArea',
        key: 'TotalArea',
        width: '10%',
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
        // sorter:(a,b)=>a.region > b.region
    },{
        title: '功能(公共建筑) ',
        dataIndex: 'Functions',
        key: 'Functions',
        width: '6%',
        // className: 'hide',
    },{
        title: '建设单位',
        dataIndex: 'Company',
        key: 'Company',
        width: '6%',
        // filters: r.map((item) => ({text: item, value: item}))(channelNameFilters),
        // onFilter: (value, record) => record.channelName.indexOf(value) === 0,
        //sortDirections: ['descend'],
        //    --
        // sorter:(a,b)=>a.sector > b.sector
    },
        {
            title: '建设单位联系人',
            dataIndex: 'Contacts',
            key: 'Contacts',
            width: '6%',
            // sorter:(a,b)=>a.OnlineMeterCnt > b.OnlineMeterCnt,
            // render: (text, record) => {
            //         return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'green'}}>{text}</span>
            // },
        },
        {
            title: '建设单位联系电话',
            dataIndex: 'Telephone',
            key: 'Telephone',
            width: '4%',
            // sorter: (a, b) => a.sum - b.sum,
            // render: (text, record) => {
            //         return <span style={{/*backgroundColor:"#fbc9bf",*/ fontSize: '20px', color: 'red'}}>{text}</span>
            //
            // },
        },

    ];


    const tableTop = {
        result: tableData.length,
        liData: ['区间',/* '选择',*/ /*'搜索',*/],
        from,
        to,
        // select,
        // deviceType,
        columns,
        tableData,
        handleScaleChange: ({/*deviceType,*/ from, to}) => {
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                // deviceType,
                from,
                to
            }))
        },
    };

    return (
        <div className='reportRightTable'>
            {/*loadUp={loadNodeUsage} */}
            <TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}
                      tableData={tableData}/>
            {/*<Table*/}
                {/*columns={columns}*/}
                {/*dataSource={tableData}*/}
                {/*scroll={{y: 710}}*/}
                {/*pagination={false}*/}
                {/*// loading={loading}*/}
                {/*className='reportRightTableTab'/>*/}
            <Table
                columns={columns}
                dataSource={tableData}
                scroll={{y: 710}}
                pagination={false}
                loading={loading}
                className='reportRightTableTab'/>
        </div>
    )
}


export default IntervalReport;