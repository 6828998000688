import React, { Component } from 'react';
import * as r from 'ramda'
import './indexBottom4.scss'
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import Api from 'api';

// import Img_line from 'img/line.png';
// import Img_bar from 'img/bar.png';
const CARBON_FACTOR= 0.49;
class IndexBottom4 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rankData: [],
            rankings: [],
            rankName: [],
            notMerge: true,
            lazyUpdate: true,
            // style: { width: "100%", height: "260px" },
            style: { width: "400px", height: "260px" },
            active: { time: 'day', type: 'building' },
            api: '/energyNew/rankingDailyForRegion'
        };
    }

    async getData(obj) {
        console.log(obj)
        const api = obj.api ? obj.api : this.state.api;
        const time = obj.time ? obj.time : this.state.active.time;
        const type = obj.type ? obj.type : this.state.active.type;
        this.state = { getOption: { series: [] }, ...this.state }
        const rank = await Api.postV3(api, {
            project: Api.projectId,
            region: Api.region,
            // regionProjects: Api.regionProjects,
            regionProjects: JSON.parse(window.sessionStorage.getItem('regionProjects2')),
            type: type
        });
        let rankName = [];
        let rankData = [];
        let rankings = []

        r.take(10)(rank.data).forEach(function (i, k) {
            rankName.push(i.title?(i.title.substr(0,5)+'..'):'');
            rankData.push({ value: i.value*CARBON_FACTOR.toFixed(2), name: i.title })
            if (k < 10) {
                rankings.push(k + 1)
            }
        })


        this.setState({
            rankData,
            rankings,
            rankName,
            active: { time, type }
        })
    }


    componentDidMount() {
        this.getData({ api: '/energyNew/rankingDailyForRegion', time: 'day', type: 'building' })
    }

    selectedType(params) {
        switch (params) {
            case 'building':
                this.getData({ type: 'building' })
                break;
            case 'department':
                this.getData({ type: 'department' })
                break;
            default:
                this.getData({ type: 'building' })
                break;
        }
    }
    selectedTime(params) {
        switch (params) {
            case 'day':
                this.getData({ api: '/energyNew/rankingDaily', time: 'day' })
                break;
            case 'month':
                this.getData({ api: '/energyNew/rankingMonthly', time: 'month' })
                break;
            case 'year':
                this.getData({ api: '/energyNew/rankingAnnually', time: 'year' })
                break;
            default:
                this.getData({ api: '/energyNew/rankingDaily', time: 'day' })
                break;
        }
    }


    render() {

        const { rankData, rankings, rankName } = this.state;

        console.log('rank carbon ------');
        console.log(rankData);
        console.log(rankings);
        console.log(rankName);
        let data = [['product', '2015', '2016']];
        r.map(y=>{
            data.push([y.name,y.value]);
        })(rankData);
        console.log('data');
        console.log(data);
        const getOption = {
            legend: {
                textStyle: {
                    color: '#578a3f',
                },
            },
            tooltip: {},
            // dataset: {
                // source: [
                //     // ['product', '2015', '2016', '2017'],
                //     ['全国', 43.3, 85.8, 93.7],
                //     // ['温州', 83.1, 73.4, 55.1],
                //     // ['全国平均', 86.4, 65.2, 82.5],
                //     // ['温州平均', 72.4, 53.9, 39.1]
                // ],
                // source: data,
                // source:[
                //     ['wencheng',12],
                //     ['wenzhou',23]
                // ]
            // },
            xAxis: {type: 'category',
                data:rankName,
                axisLabel:{
                    textStyle:{
                        color:'#777a55'}
                },

                color:'#8d6f17',
                axisLine: {onZero: true},
                splitLine: {show: false},
                splitArea: {show: false}
            }
                ,
            yAxis: {axisLabel:{
                    textStyle:{
                        color:'#777a55'}
                },},
            // Declare several bar series, each will be mapped
            // to a column of dataset.source by default.
            series: [
                // {type: 'bar',
                //     color:'#7aaf3c',
                //     // color:'#9d9869',
                // },
                // {type: 'bar',
                //     color:'#839630',
                //     // color:'#82964f',
                // },
                // {type: 'bar',
                //     color:'#969319',
                //     // color:'#719683',
                // }
                {
                    type: 'bar',
                    label: {
                        show: false,
                        color: '#ffffff'
                        // formatter: '{b}'
                    },
                    itemStyle: { color:  function (params) {
                            var data = params.data;
                            var sid = params.dataIndex;
                            console.log('params----');
                            console.log(params);
                            return new echarts.graphic.LinearGradient(
                                0, 0, 1, 0,
                                [
                                    // {offset: 0, color: '#83bff6'},
                                    // {offset: 0.5, color: '#188df0'},
                                    // {offset: 1, color: '#188df0'},
                                    {offset: 0, color: 'rgba(169,202,72,1)'},
                                    {offset: 0.2, color: 'rgba(186,192,56,1)'},
                                    {offset: 0.5, color: 'rgba(203,182,41,1)'},
                                    {offset: 0.8, color: 'rgba(226,168,21,1)'},
                                    {offset: 1, color: 'rgba(239,162,8,1)'},
                                ]
                            )
                            //前3名变成橘红色#ff9c01
                            // #feb802
                            // #f9c806
                            // if(sid < 3){
                            //     return new echarts.graphic.LinearGradient(
                            //         1, 0, 0, 0,
                            //         [
                            //             {offset: 0, color: '#f9c806'},
                            //             {offset: 0.5, color: '#feb802'},
                            //             {offset: 1, color: '#ff9c01'}
                            //         ]
                            //     )
                            // }else{
                            //     return new echarts.graphic.LinearGradient(
                            //         0, 0, 0, 1,
                            //         [
                            //             {offset: 0, color: '#83bff6'},
                            //             {offset: 0.5, color: '#188df0'},
                            //             {offset: 1, color: '#188df0'}
                            //         ]
                            //     )
                            // }

                            return '#131224';
                        },


                        // barBorderRadius: 14,
                    }, barWidth: 14,
                    data: rankData
                }
            ]
        };

        return (
            <div className='indexBottom4V3'>
                <div className='indexBottomHead '>
                    <span  style={{fontSize: '18px',
                        fontWeight: 'bold',top:'-88px',left:'39px',position:'absolute',fontFamily: 'monospace',color:'#92d271'}}>重大碳排放建筑</span>
                </div>
                <ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} style={{ width: "500px", height: "300px" }} />
            </div>
        )
    }
}


export default IndexBottom4;
