import React, {useContext} from 'react';
import AnalysisRightTop from 'src/modules/energy/analysis/analysis_right/analysis_right_top/analysisNodeUsage';
import AnalysisRightBottom from 'src/modules/energy/analysis/analysis_right/analysis_right_botton/analysisKgce';
import './analysisPanels.scss';
import {Spin} from 'antd';
import {context} from 'src/modules/energy/analysis/analysis';

const AnalysisPanels = () => {
  const {
    state: {
      loading,
    },
  } = useContext(context);
  return (
  <div className='analysis_right'>
    <Spin spinning={loading} size='large'>
      <AnalysisRightTop/>
      <AnalysisRightBottom/>
    </Spin>

  </div>
)}

export default AnalysisPanels;