import React from 'react';
import { BrowserRouter as Router, Route, NavLink, hashHistory, Switch } from "react-router-dom";

import './water.scss'
import NetworkDiagram from './networkDiagram/networkDiagram'
import WaterBalance from './waterBalance/waterBalanceData'

import Img_waterBg1 from 'img/water/index1.png';

class Water extends React.Component {

  render() {
    return (
      <div className="water">
        <Router>
          <div>
            <nav className='waterNav'>
              <ul>
                <li>
                  <NavLink activeClassName='active' to='/Water/NetworkDiagram/1'><span>一期管网图</span></NavLink>
                </li>
                <li>
                  <NavLink activeClassName='active' to='/Water/NetworkDiagram/2'><span>二期管网图</span></NavLink>
                </li>
                <li>
                  <NavLink activeClassName='active' to='/Water/WaterBalance'><span>水平衡</span></NavLink>
                </li>
              </ul>
            </nav>
            <Route path="/" exact component={NetworkDiagram} />
            <Route path="/Water/NetworkDiagram/1" component={NetworkDiagram} />
            <Route path="/Water/NetworkDiagram/2" component={NetworkDiagram} />
            <Route path="/Water/WaterBalance" component={WaterBalance} />
          </div>
        </Router>
      </div>
    )
  }

};


export default Water;