import React from 'react';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';

import Api from 'api';
import './indexTopRight.scss';

import Img_onlinEquipment from 'img/onlinequipment.png';
import Img_errEquipment from 'img/errequipment.png';

TweenOne.plugins.push(Children);

class IndexTopRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onlineNumber: 0,
            errNumber: 0,
            onlineManager: 0,
            errManager: 0,
        };
    }


    async getData() {

        const NewEquipment = await Api.postV3('/device/status', {
            project: Api.projectId
        });

        return NewEquipment.data && this.setState(NewEquipment.data)
    }


    componentDidMount() {
        this.getData()
    }

    render() {
        return (
            <ul className='indexTopRight'>
                <li>
                    <div className='indexTopRightTextImg'>
                        <img src={Img_onlinEquipment} alt="智慧云能源" />
                    </div>
                    <div className='indexTopRightTextDiv exception'>
                        <p>设备状态(个）</p>
                        <div className='b'>
                            <span>正常:&nbsp;&nbsp;&nbsp;</span>
                            <TweenOne
                                animation={
                                    {
                                        Children: {
                                            value: this.state.onlineNumber, floatLength: 0,
                                        },
                                        duration: 3000,
                                    }
                                }
                            >
                                0
          </TweenOne>
                        </div>
                        <div className='b'>
                            <span>异常:&nbsp;&nbsp;&nbsp;</span>
                            <TweenOne
                                animation={
                                    {
                                        Children: {
                                            value: this.state.errNumber, floatLength: 0,
                                        },
                                        duration: 3000,
                                    }
                                }
                            >
                                0
          </TweenOne>
                        </div>

                    </div>
                </li>
                <li>
                    <div className='indexTopRightTextImg'>
                        <img src={Img_onlinEquipment} alt="智慧云能源" />
                    </div>
                    <div className='indexTopRightTextDiv exception'>
                        <p>终端状态(个）</p>
                        <div className='b'>
                            <span>正常:&nbsp;&nbsp;&nbsp;</span>
                            <TweenOne
                                animation={
                                    {
                                        Children: {
                                            value: this.state.onlineManager, floatLength: 0,
                                        },
                                        duration: 3000,
                                    }
                                }
                            >
                                0
          </TweenOne>
                        </div>
                        <div className='b'>
                            <span>异常:&nbsp;&nbsp;&nbsp;</span>
                            <TweenOne
                                animation={
                                    {
                                        Children: {
                                            value: this.state.errManager, floatLength: 0,
                                        },
                                        duration: 3000,
                                    }
                                }
                            >
                                0
          </TweenOne>
                        </div>

                    </div>
                </li>
            </ul>
        )
    }
}
export default IndexTopRight;