let  Util =
    {};
    Util.regionJsonConverter = {'330300':"������",
        '330301':"��Ͻ��",
        '330302':"¹����",
        '330303':"������",
        '330304':"걺���",
        '330305':"��ͷ��",
        '330324':"������",
        '330326':"ƽ����",
        '330327':"������",
        '330328':"�ĳ���",
        '330329':"̩˳��",
        '330371':"���ݸ�����(������)",
        '330381':"����",
        '330382':"������",
        '330383':"������",
        '330354':"��̬԰",
        '330355':"걽���",
    };

Util.regionJson = {"��Ͻ��":330301,"¹����":330302,"������":330303,"걺���":330304,"��ͷ��":330305,"������":330324,"ƽ����":330326
    ,"������":330327,"�ĳ���":330328,"̩˳��":330329,"���ݸ�����(������)":330371,"����":330381,"������":330382,"걽���":330355,"��̬԰":330354,"������":330383};
Util.projectTypeTransfer= {
    SPORT: "��������",
    CBD: '�ۺϽ���',
    HOSPITAL: 'ҽ����������',
    HOTEL: '���ݷ��꽨��',
    MARKET: '�̳�����',
    OFFICE: '�칫����',
    TEACH: '�Ļ���������',
    OTHER: '��������',
}

    export default Util;
