import React, { Component } from 'react';
import { Button, DatePicker, Radio } from 'antd';
import ReactEcharts from 'echarts-for-react';
import moment from 'moment';
import * as r from 'ramda'
import Excel from 'src/components/excel/excel';
import './echarts.scss';

class Echarts extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            selection: 'daily',
            date: moment(),
            isOpen: false,
            mode: 'date'
        };
    }
    toMode = period => {
        return r.propOr('date')(period)({
            'monthly': 'month',
            'annually': 'year',
        })
    }
    modeTranslate = mode => {
        return r.propOr('daily')(mode)({
            'month': 'monthly',
            'year': 'annually',
        })
    }
    modeFormat = mode => r.propOr('YYYY-MM-DD')(mode)({
        'month': 'YYYY-MM',
        'year': 'YYYY',
    })
    onPanelChange = value => {
        console.log(this.state.mode, value);
        this.props.props.handleScaleChange && this.props.props.handleScaleChange(this.modeTranslate(this.state.mode), value.format(this.modeFormat(this.state.mode)))
        this.setState({
            date: value,
            isOpen: false
        })
    }
    onChange = (date, dateString) => {
        console.log(date, dateString, this.state.mode);
        this.props.props.handleScaleChange && this.props.props.handleScaleChange(this.modeTranslate(this.state.mode), date)
        this.setState({
            date,
            isOpen: false
        })
    }
    onExport = (date, dateString) => {
        console.log('click----' + date, dateString, this.state.mode);
        console.log(this.props.props.data);
        let edata = this.props.props.data;
        let column=edata.xAxis.data,data=edata.series[0].data;
        let column2=[],data2=[],dataJson={};
        // if(column.length !== 12){
        //     console.log();
        // }
        for(let i =0;i<column.length;i++){
            column2.push({title:column[i],dataIndex:'col_'+i,key:'col_'+i,width:'100px'});
            dataJson['col_'+i]=data[i]?data[i]:'';
        }
        data2.push(dataJson);
        console.log('data--------');
        console.log(column2);
        console.log(data2);
        Excel(column2, data2);
        // let columns = r.map(y=>({title:'能耗',dataIndex:'energy',key:'energy',width:'100px'}))(edata.xAxis.data);
        // let data = r.map(y=>({sum:'能耗',dataIndex:'energy',key:'energy',width:'100px'}))(edata.series[0].data);
        // console.log(edata.xAxis.data);
        // console.log('--------');
        // console.log(edata.series[0].data);
    }
    handleScaleChange = (e) => {
        if(e.target.value === 'exportExcel'){
            this.onExport();
            return;
        }


        this.setState({ mode: this.toMode(e.target.value) })
        this.props.props.handleScaleChange && this.props.props.handleScaleChange(e.target.value)
    }

    disabledFuture = (date) => {
        return date.valueOf() > new Date().valueOf() || date.valueOf() < new Date(2015, 1, 1).valueOf();
    }
    render() {
        let props = this.props.props;
        let title = props.tit;
        let echartsData = props.data;
        let ulShowData = props.ulShow;
        let ulShowData2 = props.ulShow2;
        let ulShow = [];
        let ulLeft = props.ulLeft;
        let ulTop = props.ulTop;
        const mode = this.toMode(props.selection)

        if (ulShowData) {
            for (var i of ulShowData) {
                switch (i) {
                    case 'day':
                        ulShow.push(<Radio.Button key="daily" size='small' value="daily">日</Radio.Button>)
                        break;
                    case 'week':
                        ulShow.push(<Radio.Button size='small' value="weekly">周</Radio.Button>)
                        break;
                    case 'month':
                        ulShow.push(<Radio.Button key="month" size='small' value="monthly">月</Radio.Button>)
                        break;
                    case 'season':
                        ulShow.push(<Radio.Button size='small' value="seasonally">季</Radio.Button>)
                        break;
                    case 'years':
                        ulShow.push(<Radio.Button key="year" size='small' value="annually">年</Radio.Button>)
                        break;
                    case 'upload':
                        ulShow.push(<Button key='upload' icon="upload" size='small' />)
                        break;
                    case 'datePicker':
                        ulShow.push(<DatePicker
                            key="datepicker"
                            size='small'
                            mode={mode}
                            format={this.modeFormat(mode)}  // 天，月，年的格式修改。
                            // value={this.state.date}
                            // open={this.state.isOpen}
                            // onFocus={() => {this.setState({isOpen: true})}}
                            // onBlur={() => {this.setState({isOpen: false})}}
                            disabledDate={this.disabledFuture}
                            onPanelChange={this.onPanelChange} // 恢复这里的，进行月份的查询
                            defaultValue={moment()}
                            onChange={this.onChange} />)
                        break;
                    case 'recent31Days':
                        ulShow.push(<Radio.Button key="recent31Days" size='small' value="recent31Days">最近31天</Radio.Button>)
                        break;
                    case 'ele':
                        ulShow.push(<Radio.Button key="ele" size='small' value="ele">电</Radio.Button>)
                        break;
                    case 'water':
                        ulShow.push(<Radio.Button key="water" size='small' value="water">水</Radio.Button>)
                        break;
                    case 'exportExcel':
                        ulShow.push(<Radio.Button key="exportExcel" size='small' value="exportExcel"  >导出</Radio.Button>)
                        break;
                    default:
                        break;
                }
            }
        }

        return (
            <div className='components_echarts' >
                <div className="components_echarts_head">
                    <p>{title}</p>
                    <Radio.Group className='components_echarts_head_radio' value={props.selection} size="small"   onChange={this.handleScaleChange} style={{ top: ulTop, left: ulLeft }}>
                        {ulShow}
                    </Radio.Group>
                    {/*{ulShowData?*/}
                    {/*<Radio.Group className='components_echarts_head_radio' value={props.selection} size="small"  style={{ top: ulTop, left: ulLeft }}>*/}
                        {/*<Radio.Button key="exportExcel" size='small' value="exportExcel" onClick={this.onExport} >导出</Radio.Button>*/}
                    {/*</Radio.Group>*/}
                    {/*:''}*/}

                </div>
                <ReactEcharts option={echartsData} notMerge={true} />
            </div >
        )
    }
}


export default Echarts;
