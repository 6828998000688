import React, { useState,useContext ,useEffect} from 'react';
import BarCode from './ui/barCode/barCode'
import moment from 'moment';
import { timer } from 'rxjs';
import Api from 'api';
import { Color,ColorContext , UPDATE_COLOR } from "./color";
// let lasttime = 0;
let reqflag = true;
const AirData = (props) => {



    let [time, setTime] = useState(0);
    let [temp, setTemp] = useState(0.00);
    let [humi, setHumi] = useState(0.00);
    let [dew, setDew] = useState(0.00);
    let [atmos, setAtmos] = useState(0.00);
    let [radia, setRadia] = useState(0.00);
    let [windspeed, setWindspeed] = useState(0.00);
    let [winddir, setWinddir] = useState(0.00);
    let [date, setDate] = useState('');
    let [swView, setSwView] = useState(0);
    let [lasttime, setLasttime] = useState(0);
    let [headName, setHeadName] = useState(0);
    // const source = timer(0, 1000);
    const source2 = timer(0, 1000*50);

    const getdate =  ()=>{
        console.log('getdata----------'+lasttime);
        if (Date.now() - lasttime < 1000*60*1){
                return;
        }
        // console.log('getdata----------');
        lasttime=Date.now();
        setLasttime(lasttime);
        console.log(Date.now()+'|'+lasttime);
        Api.postV3('/energy/weather', {
            project: Api.projectId
        }).then(({ data }) => {
            if(!data||!data.result){
                return;
            }
            console.log((data.result)[0].temp);
            let res = (data.result)[0];
            setTemp(res.temp);
            setHumi(res.humi);
            setDew(res.dew);
            setAtmos(res.atmos);
            setRadia(res.radia);
            setWindspeed(res.windspeed);
            setWinddir(res.winddir);
            setDate(res.date);

        })
    }






    // source.subscribe(() => setTime(moment().format('YYYY[年]MoDo HH:mm:ss')))
    // source2.subscribe(() =>  getdate())
    //点击查询按钮，将值传给父组件

    //
    const { color } = useContext(ColorContext);
    const { dispatch } = useContext(ColorContext);

    useEffect(() => {
        // document.getElementById('screenContainer').style.display=(color=='1'?'block':'none' );
        // document.getElementById('midLeftForWeather').style.display=(color=='1'?'none':'block' );
        // document.getElementById('headName').innerHTML=(color=='1'?'智慧能源监测平台':'温州设计集团工程咨询平台气象科普站' );
        // console.log(color=='1'?'QINDI show ----- ':'LanJu show ----------');
        console.log(swView);
        console.log(props.co);
        // props.setKeyWord(swView);
    });

    const setKeyWord = () =>{
        // props.setKeyWorld(!reqflag);
        swView=(swView===3?0:(1+swView));
        setSwView(swView);
        console.log('swView --');
        console.log(swView);
        props.setKeyWord(swView);
    }
    const typeJson = {
        0:'蓝居',
        1:'勤迪',
        2:'七楼气象站',
        3:'七楼花园玻璃'
    };
    const airDeviceJson = (type)=>{
       return typeJson[type];
    }
    const search =  ()=>{
        setSwView(!swView);
        console.log('switch view ================' + swView +'|' );
        // this.setKeyWorld(swView);

    }

    return (
        <div className='ScreenVersionIndexHead'>
            <div className="ScreenVersionIndexHeadLeftBorder"></div>
            <div className="ScreenVersionIndexHeadNotHaveLeftBorder">
                <div className="ScreenVersionIndexHeadTop">
                    <div className="ScreenVersionIndexHeadTopLeft">
                        <div className='angletou'>
                            <i className="angleRound"></i>
                        </div>
                        <span id='headName'
                              onClick={setKeyWord}

                        >温州设计集团工程咨询平台空气监测站</span>
                        <span style={{ fontSize:'20px',color:'#dfd433'}}>{typeJson[swView]} </span>
                    </div>

                    <div className="ScreenVersionIndexHeadTopRight">
                        <div className="ScreenVersionIndexHeadTopRightTop">
                            <span className='ScreenVersionIndexHeadTopTime'>
                                {time}
                            </span>
                            <div className='ScreenVersionIndexHeadTopRightDiv'></div>
                        </div>
                        <BarCode />
                    </div>

                </div>
                <div className="ScreenVersionIndexHeadBottom">
                    <div className='ScreenVersionIndexHeadBottomLeft'>
                        <BarCode />
                        <span>
                            INTELLIGENT ENERGY MONITORING PLATFORM
              </span>
                    </div>
                    <div className="ScreenVersionIndexHeadBottomRight">
                        <i className="ScreenVersionIndexHeadBottomRightRound1"></i>
                        <i className="ScreenVersionIndexHeadBottomRightRound2"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirData
