import React from 'react';

import moment from 'moment'
import { from } from 'rxjs'
import AnalysisNavigator
  from 'src/modules/energy/analysis/analysis_left/analysisNavigator';
import AnalysisPanels
  from 'src/modules/energy/analysis/analysis_right/analysisPanels'
import withStates from 'src/context/withStates'
import { typesInCategory } from 'src/context/typeCategory'
import Api from 'api'
import * as r from 'ramda'

const ROOT_NODE = '0';

const Analysis = () => (
  <div className='Analysis'>
    <AnalysisNavigator />
    <AnalysisPanels />
  </div>
)

const treeViewData = async () => {
  const { data: { result = [] } } = await Api.post('/customer/info', {
    project: Api.projectId,
  });
  const toChildren = r.compose(
    r.map(({ id: key, title, key: did, child }) =>
      ({
        title, key, did,
        children: r.isNil(child) ? [] : toChildren(child),
      })),
    r.values)
  return {
    searchIsShow: true,
    loading: false,
    node: ROOT_NODE,
    tree: [
      {
        title: Api.projectName,
        key: ROOT_NODE,
        children: toChildren(
          r.filter(
            r.where({
              type: r.equals('NODE'),
              parent: r.equals(0),
            }),
          )(result)),
      },
    ],
  }
}
///
const ratioOf = category => (current, last) => {
  const thisPhrase = categoryMeasure(category)(current)
  const lastPhrase = categoryMeasure(category)(last)
  return lastPhrase === 0 || r.isNil(lastPhrase) ?
    0 :
    (100 * (Number(thisPhrase) - Number(lastPhrase)) /
      Number(lastPhrase)).toFixed(2)
}

const translateFormat = r.map(r.pipe(r.prop('value'),
  x => isNaN(x) || r.isNil(x) ? NaN : Number(x).toFixed(2)))
const translateTimeline = v => {
  const valueMap = {
    daily: r.map(i => `0${i}`.slice(-2) + ':00')(r.range(0, 24)),
    monthly: r.map(i => `0${i}`.slice(-2))(
      // r.range(1, moment().daysInMonth() + 2)),
      r.range(1, 32)),
    annually: r.map(i => `0${i}`.slice(-2))(r.range(1, 13)),
  }
  return r.propOr(v)(v)(valueMap)
}

const categoryMeasure = category => data => r.pipe(
  r.map(type => r.pipe(r.propOr([])(type), r.pluck('value'),
    r.map(x => Number(x)), r.sum)(data)),
  r.sum,
  x => x.toFixed(2))(typesInCategory(category))

const translateType = v => {
  const valueMap = {
    ELECTRICITYMETER: '电表',
    WATER: '水表',
    AC: '空调',
    COLDWATERMETER: '冷水',
    ENERGYMETER_COLD: '总冷量',
    ENERGYMETER_HOT: '总热量',
    HOTWATERMETER: '热水',
    MELECTRICITYMETER: '多功能电表',
    ULTRACOLDWATERMETER: '超声波水表',
    TIMERMETER: '时间采集器',
    TEMPERATURECONTROL: '温控器',
    HEATENERGYMETER: '热能表'
  }
  return r.propOr(v)(v)(valueMap)
}

const translateKgce = (type) => {
  return type === 'annually' ?
    moment().format('YYYY') :
    moment().format('YYYYMM')
}

const translateLastKgce = (type) => {
  const time = moment().subtract(1, 'year');
  return type === 'annually' ? time.format('YYYY') : time.format('YYYYMM')
}

const dateFormatOf = period => r.propOr('YYYYMMDD')(period)({
  daily: 'YYYYMMDD',
  mongthly: 'YYYYMM',
  annually: 'YYYY',
})

const formatDateParam = (date, usagePeriod = 'daily') =>
  r.isNil(date) ? '' : `?date=${moment(date).format(dateFormatOf(usagePeriod))}`

const validTypes = ['ELECTRICITYMETER', 'WATER', 'AC'];
const legendName = { ELECTRICITYMETER: '电表', WATER: '水表', AC: '空调' };
const echartsLabel = {
  normal: {
    show: true,
    position: 'top',
    distance: 10,
    align: 'center',
    verticalAlign: 'middle',
  },
}
////
const nodeUsage = async ({ node, usagePeriod = 'daily', kgcePeriod = 'annually', usageDate }) => {
  const [
    { data: { current: today, last: yesterday } },
    { data: { current: thisYear, last: lastYear } },
  ] = await Promise.all([
    Api.get(
      `/api/v3/project/${Api.projectId}/nodeUsage/${node}/${usagePeriod}${formatDateParam(
        usageDate, usagePeriod)}`),
    Api.get(
      `/api/v3/project/${Api.projectId}/nodeKgce/${node}/${kgcePeriod}`)])

    console.log('thisYear: '+thisYear);
  const nodeUsage = {
    color: ['#86CCFF', '#77e6fd', '#FFAD33', '#FBE641'],
    grid: { left: '50', top: '25', right: '30', bottom: '22', containLabel: true },
    legend: {
      data: r.map(([k, x]) => ({
        name: translateType(k),
      }))(r.toPairs(today)),
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: translateTimeline(usagePeriod),
    },
    yAxis: {
      type: 'value',
    },
    series: r.map(([k, x]) => ({
      name: translateType(k),
      type: 'bar',
      data: translateFormat(x || []),
      label: echartsLabel
      // itemStyle: { barBorderRadius: 5 },
      // barWidth: 10,
    }))(r.toPairs(today)),
  }
  //////////////

  // console.log(thisYear, lastYear);
  const formatMonthlyKgce = r.compose(
    r.values,
    r.map(r.pipe(
      r.pluck('kgce'),
      ([w = 0, e = 0]) => Number(w) + Number(e))),
    r.groupBy(r.prop('date')),
    r.flatten,
    r.values)
  // console.log(formatMonthlyKgce(thisYear))
  // console.log(formatMonthlyKgce(lastYear))
  //TODO: fix this
  const seriesTime = r.map(({ date }) => date.toString().slice(-2))(
    thisYear['ELECTRICITYMETER'] || thisYear['WATER'] || [])
  const monthlyKgce = formatMonthlyKgce(thisYear);
  const seriesData = r.zip(r.range(0, 12))(r.map(v => v.toFixed(2))(monthlyKgce))
  const lastYearMonthlyKgce = formatMonthlyKgce(lastYear);
  const seriesDataLast = r.zip(r.range(0, 12))(r.map(v => v.toFixed(2))(lastYearMonthlyKgce))
  // console.log('monthlyKgce', monthlyKgce);

  const kgceData = {
    color: ['#86CCFF', '#FFAD33'],
    grid: { left: '30', top: '25', right: '30', bottom: '22', containLabel: true },
    legend: {
      data: [translateKgce(kgcePeriod), translateLastKgce(kgcePeriod)],
      align: 'left',
    },
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: seriesTime,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: translateKgce(kgcePeriod),
        data: seriesData,
        type: 'bar',
        label: echartsLabel
      }, {
        name: translateLastKgce(kgcePeriod),
        data: seriesDataLast,
        type: 'bar',
        label: echartsLabel
      }],
  }

  return {
    loading: false,
    todayWater: categoryMeasure('WATER')(today),
    todayWaterRatio: ratioOf('WATER')(today, yesterday),
    todayElectricity: categoryMeasure('ELECTRICITYMETER')(today),
    todayElectricityRatio: ratioOf('ELECTRICITYMETER')(today, yesterday),
    monthWaterTotal: categoryMeasure('WATER')(thisYear),
    monthWaterRatio: ratioOf('WATER')(thisYear, lastYear),
    monthElectricityTotal: categoryMeasure('ELECTRICITYMETER')(thisYear),
    monthElectricityRatio: ratioOf('ELECTRICITYMETER')(thisYear, lastYear),
    kgceMonth: (r.last(monthlyKgce) || 0).toFixed(2),
    kgceAnnual: r.sum(monthlyKgce).toFixed(2),
    monthlyRatio: ((r.last(monthlyKgce) || 0 - r.last(lastYearMonthlyKgce) ||
      0) / (r.last(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
    annuallyRatio: ((r.sum(monthlyKgce) - r.sum(lastYearMonthlyKgce)) /
      (r.sum(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
    usagePeriod,
    usageDate,
    kgcePeriod,
    nodeUsage,
    kgceData,
  }
}

const loadAnalysis = (state) => {
  const input = Promise.all(
    [treeViewData(state), nodeUsage(state)])
    .then(r.mergeAll)
  return from(input)
}

export const loadNodeUsage = (state) => {
  // console.log('loadDevices', state);
  return from(nodeUsage(state)
    .then(res => ({ ...state, ...res })))
}
const initState = {
  loading: true,
  node: ROOT_NODE,
  nodeUsage: {
    color: ['#86CCFF', '#FFAD33'],
    grid: { left: '50', top: '25', right: '30', bottom: '22' },
    legend: {},
    tooltip: {
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
    },
    series: [],
  },
  tree: [],
  kgceData: {
    color: ['#86CCFF', '#FFAD33'],
    grid: { left: '30', top: '25', right: '30', bottom: '22' },
    legend: {},
    xAxis: {
      type: 'category',
      data: [],
    },
    yAxis: {
      type: 'value',
    },
    series: [],
  },
  usagePeriod: 'daily',
  kgcePeriod: 'annually',
}

export const { context, Component } = withStates(
  Analysis,
  initState,
  loadAnalysis,
)

export default Component