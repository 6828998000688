import React, { Component } from 'react';
import * as r from 'ramda'
import './indexBottom4.scss'
import ReactEcharts from 'echarts-for-react';
import Api from 'api';

// import Img_line from 'img/line.png';
// import Img_bar from 'img/bar.png';

class IndexBottom4 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            rankData: [],
            notMerge: true,
            lazyUpdate: true,
            style: { width: "100%", height: "260px" },
            active: { time: 'day', type: 'building' },
            api: '/energyNew/rankingDaily'
        };
    }

    async getData(obj) {
        console.log(obj)
        const api = obj.api ? obj.api : this.state.api;
        const time = obj.time ? obj.time : this.state.active.time;
        const type = obj.type ? obj.type : this.state.active.type;
        this.state = { getOption: { series: [] }, ...this.state }
        const rank = await Api.postV3(api, {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: type
        });
        let rankName = [];
        let rankData = [];
        let rankings = []

        r.take(10)(rank.data).forEach(function (i, k) {
            rankName.push(i.title)
            rankData.push({ value: i.value.toFixed(2), name: i.title })
            if (k < 10) {
                rankings.push(k + 1)
            }
        })


        this.setState({
            rankData,
            rankings,
            rankName,
            active: { time, type }
        })
    }


    componentDidMount() {
        this.getData({ api: '/energyNew/rankingDaily', time: 'day', type: 'building' })
    }

    selectedType(params) {
        switch (params) {
            case 'building':
                this.getData({ type: 'building' })
                break;
            case 'department':
                this.getData({ type: 'department' })
                break;
            default:
                this.getData({ type: 'building' })
                break;
        }
    }
    selectedTime(params) {
        switch (params) {
            case 'day':
                this.getData({ api: '/energyNew/rankingDaily', time: 'day' })
                break;
            case 'month':
                this.getData({ api: '/energyNew/rankingMonthly', time: 'month' })
                break;
            case 'year':
                this.getData({ api: '/energyNew/rankingAnnually', time: 'year' })
                break;
            default:
                this.getData({ api: '/energyNew/rankingDaily', time: 'day' })
                break;
        }
    }


    render() {

        const { rankData, rankings, rankName } = this.state;
        const getOption = {
            // tooltip: {},
            xAxis: [{
                type: 'value'
            }],
            grid: { left: '50', top: '5', right: '50', bottom: '22' },
            yAxis: [{
                type: 'category',
                data: rankName,
                inverse: true,
                axisLabel: { color: '#FEFEFF' }
            }, {
                type: 'category',
                data: r.map((i) => (r.prop('value')(i)))(rankData),
                inverse: true,
                axisLabel: { color: '#FEFEFF' }
            }],
            series: [{
                type: 'bar',
                // label: {
                //     show: true,
                //     formatter: '{b}'

                // },
                itemStyle: { color: "#3ac3f7", barBorderRadius: 14, }, barWidth: 14,
                data: rankData
            }]
        }
        return (
            <div className='indexBottom4'>
                <div className='indexBottomHead '>
                    <span>能耗排名</span>
                </div>
                <ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} style={{ width: "100%", height: "240px" }} />
            </div>
        )
    }
}


export default IndexBottom4;
