import React from 'react';
import './App.css';
import { Project, projectState } from 'src/context/project'


import { BrowserRouter as Router, Route, NavLink, Redirect } from "react-router-dom";

import AppRouter from './router'
import Api from './api'

import Login from './modules/login/login'


class App extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      projectId: projectState.id,
      projectName: projectState.name,
      upProjectData: this.upProjectData,
    }
  }

  upProjectData = ({ id, name }) => {
    this.setState({
      projectId: id,
      projectName: name
    })
  }


  render() {
    return (
      <Router >
        <Project.Provider value={this.state}>
          <div className="App">
            <Route path="/Login" exact component={Login} />
            <AppRouter />
          </div>
        </Project.Provider>
      </Router >
    )
  }
}
export default App;
