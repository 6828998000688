import React from 'react';

import './index.scss'
// import Api from 'api'

import IndexTop from './indexTop/indexTop';
import IndexMid from './indexMid/indexMid'
import IndexBottom from './indexBottom/indexBottom'

class Index extends React.Component {

  render() {
    return (
      <div className="index">
        <IndexTop />
        <IndexMid />
        <IndexBottom />
      </div>
    )
  }

};


export default Index;