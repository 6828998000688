import React from 'react';
import { Select, InputNumber, Button } from 'antd';
// import moment from 'moment';
// import TableTop from 'src/components/table/tableTop/tableTop';

import './tableBottom.scss';
// import ReactEcharts from 'echarts-for-react';
// import Api from 'api';
const Option = Select.Option;
// const dateFormat = 'YYYY/MM/DD';

class DayReport extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }


    componentDidMount() {
        // this.getData()
    }

    handleChange = (value) => {
        console.log(`selected ${value}`);
    }
    onChange = (value) => {
        console.log('changed', value);
    }



    render() {
        // const pageNumber = this.props.number
        console.log(this.props.number)
        return (
            <div className='tableBottom'>
                <div className='reportRightTableFootLeft'>
                    <span>共1条</span>
                    <span>第1/1页</span>
                    <div className='pageSelect'>
                        <span>每页</span>
                        <Select defaultValue="lucy" onChange={this.handleChange} className='select'>
                            <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="disabled" disabled>Disabled</Option>
                            <Option value="Yiminghe">yiminghe</Option>
                        </Select>
                        <span>条</span>
                    </div>
                </div>
                <div className='reportRightTableFootRight'>
                    <img src="" alt="" />
                    <img src="" alt="" />
                    <img src="" alt="" />
                    <img src="" alt="" />
                    <div className='pageInput'>
                        <span>第</span>
                        <InputNumber min={1} max={10} defaultValue={3} onChange={this.onChange} />
                        <span>页</span>
                    </div>
                    <Button>GO</Button>
                </div>
            </div>
        )
    }
}


export default DayReport;