import React from 'react';

import './partLeft2.scss';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts'
import Api from 'api';
import { timer } from 'rxjs';
import Border4 from '../../ui/border4/border4';
import * as r from 'ramda';

import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';
const CARBON_FACTOR= 0.49;
class PartLeft2 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            xAxisData: [],
            waterData: [],
            electricityData: [],
            notMerge: true,
            lazyUpdate: true,
            style: { width: "90%", height: "260px" },
            // active: "bar",

            sum: 0,
            day_lightrcptclPCT: 0,
            day_airelePCT: 0,
            day_powerelePCT: 0,
            day_specialelePCT: 0,
            month_lightrcptclPCT: 0,
            month_airelePCT: 0,
            month_powerelePCT: 0,
            month_specialelePCT: 0,
            year_lightrcptclPCT: 0,
            year_airelePCT: 0,
            year_powerelePCT: 0,
            year_specialelePCT: 0,
            active: "day"

        };
    }

    // async getData(active='bar') {
    //     this.state = { getOption: { series: [] }, ...this.state }
    //     const data = new Date();
    //     const hh = data.getHours();
    //     const water = await Api.postV3('/energyNew/usageDaily24hMulti', {
    //         project: Api.projectId,
    //         region: Api.region,
    //         regionProjects: Api.regionProjects,
    //         type: 'WATER'
    //
    //     });
    //
    //     const electricity = await Api.postV3('/energyNew/usageDaily24hMulti', {
    //         project: Api.projectId,
    //         region: Api.region,
    //         regionProjects: Api.regionProjects,
    //         type: 'ELECTRICITYMETER'
    //     });
    //
    //     let waterTime = [];
    //     let electricityTime = [];
    //     let waterData = [];
    //     let electricityData = [];
    //
    //     // if (water.data.data) {
    //     //     for (let i = 0; i < hh; i++) {
    //     //         waterTime.push(`0${i}`.slice(-2) + ':00')
    //     //         waterData.push(water.data.data['hour' + i] || 0)
    //     //     }
    //     // }
    //     //
    //     // if (electricity.data.data) {
    //     //     for (let i = 0; i < hh; i++) {
    //     //         electricityTime.push(`0${i}`.slice(-2) + ':00')
    //     //         electricityData.push(electricity.data.data['hour' + i] || 0)
    //     //     }
    //     // }
    //
    //
    //
    //     //改成24h后，返回的数组有两个数据，进行重新处理
    //
    //     let waterRes = water.data.data;
    //     // console.log('waterRes: ' + waterRes);
    //     if (waterRes&&waterRes[0]) {
    //         for (let key in waterRes[0]) {
    //             // waterTime.push('昨日'+key.replace('ys_hour','')+':00')
    //             waterTime.push(key.replace('ys_hour','')+':00')
    //             waterData.push(Number(waterRes[0][key]).toFixed(2) || '0.00')
    //         }
    //     }
    //     if (waterRes&&waterRes[1]) {
    //         for (let key in waterRes[1]) {
    //             waterTime.push(key.replace('hour','')+':00')
    //             waterData.push(Number(waterRes[1][key]).toFixed(2) || '0.00')
    //         }
    //     }
    //
    //
    //     let eleRes = electricity.data.data;
    //     if (eleRes&&eleRes[0]) {
    //         for (let key in eleRes[0]) {
    //             // electricityTime.push('昨日'+key.replace('ys_hour','')+':00')
    //             electricityTime.push(key.replace('ys_hour','')+':00')
    //             electricityData.push(Number(eleRes[0][key]).toFixed(2) || '0.00')
    //         }
    //     }
    //     if (eleRes&&eleRes[1]) {
    //         for (let key in eleRes[1]) {
    //             electricityTime.push(key.replace('hour','')+':00')
    //             electricityData.push(Number(eleRes[1][key]).toFixed(2) || '0.00')
    //         }
    //     }
    //
    //     this.setState({
    //         xAxisData: waterTime.length > electricityTime.length ? waterTime : electricityTime,
    //         waterData,
    //         electricityData,
    //         active: active
    //     })
    // }

    async getData(api, active) {
        this.state = { getOption: { series: [] }, ...this.state };
        // const data = await Api.get(api);
        let regionProjects2 = JSON.parse(window.sessionStorage.getItem('regionProjects2'));
        if(regionProjects2&&regionProjects2.length>0){
            const data = await Api.postV3(api, {
                project: Api.projectId,
                region: Api.region,
                // regionProjects: Api.regionProjects,
                regionProjects: regionProjects2,
            });

            const sum = data.data.total ? data.data.total.toFixed(2) : 1.00;
            const lightrcptcl = data.data.lightrcptcl ? data.data.lightrcptcl.toFixed(2) : 0.00;
            const powerele = data.data.powerele ? data.data.powerele.toFixed(2) : 0.00;
            const airele = data.data.airele ? data.data.airele.toFixed(2) : 0.00;
            const specialele = data.data.specialele ? data.data.specialele.toFixed(2) : 0.00;

            const lightrcptclPCT = lightrcptcl ? ((lightrcptcl / sum) * 100).toFixed(2) : 0.00;
            const powerelePCT = powerele ? ((powerele / sum) * 100).toFixed(2) : 0.00;
            const airelePCT = airele ? ((airele / sum) * 100).toFixed(2) : 0.00;
            const specialelePCT = specialele ? ((specialele / sum) * 100).toFixed(2) : 0.00;


            // this.setState({
            //     sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT,
            //     active: active
            // })
            return ({
                sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT
            });
        }else{
            console.log('this condition for subentry is empty,not to req -------' + active);
            return ({
                sum:1, lightrcptclPCT:0, airelePCT:0, powerelePCT:0, specialelePCT:0
            });
        }

    }
     setData  (dayData,monthData,yearData,_this){
         _this.state = { getOption: { series: [] }, ..._this.state };
        // console.log('----setData ----');
        // console.log(dayData);
         _this.setState({
            day_lightrcptclPCT:dayData.lightrcptclPCT,
            day_airelePCT:dayData.airelePCT,
            day_powerelePCT:dayData.powerelePCT,
            day_specialelePCT: dayData.specialelePCT,
            month_lightrcptclPCT: monthData.lightrcptclPCT,
            month_airelePCT: monthData.airelePCT,
            month_powerelePCT: monthData.powerelePCT,
            month_specialelePCT: monthData.specialelePCT,
            year_lightrcptclPCT: yearData.lightrcptclPCT,
            year_airelePCT: yearData.airelePCT,
            year_powerelePCT: yearData.powerelePCT,
            year_specialelePCT: yearData.specialelePCT,
        })
    }

    componentDidMount() {
        let dayItem,monthItem,yearItem;
        let _this = this;
        this.getData(`/project/${Api.projectId}/subentryNew/ratioDaily/regionProjects/`, 'day').then(res =>{
            // console.log('---day ---');
            // console.log(res);
            dayItem=res;
            this.getData(`/project/${Api.projectId}/subentryNewCarbon/ratioMonthly/regionProjects/`, 'month').then(
                res =>{
                    // console.log('--- month ---');
                    // console.log(res);
                    monthItem=res;
                    this.getData(`/project/${Api.projectId}/subentryCarbonYearNew/ratioAnnually/regionProjects/`, 'year').then(
                        res=>{
                            // console.log('--- year ---');
                            // console.log(res);
                            yearItem=res;
                            this.setData(dayItem,monthItem,yearItem,_this);
                        }
                    )
                }
            )
        });




        // 区域的数据请求时间太久，不再自动进行请求 -----
        // const upDataTime = 5 * 60 * 1000;//分钟
        // const source = timer(0, upDataTime);
        // source.subscribe((x) => { console.log('indexTopLeft:' + x); this.getData() })
    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { sum,
            day_lightrcptclPCT,
            day_airelePCT,
            day_powerelePCT,
            day_specialelePCT,
            month_lightrcptclPCT,
            month_airelePCT,
            month_powerelePCT,
            month_specialelePCT,
            year_lightrcptclPCT,
            year_airelePCT,
            year_powerelePCT,
            year_specialelePCT,
        } = this.state;
        // console.log(' ---- struct --- ');
        // console.log(day_lightrcptclPCT);
        // console.log(day_airelePCT);
        // console.log(day_powerelePCT);
        // console.log(day_specialelePCT);

        var xAxisData2 = ['当日','当月','当年'];
        var data1 = [day_lightrcptclPCT,month_lightrcptclPCT,year_lightrcptclPCT];
        var data2 = [day_airelePCT,month_airelePCT,year_airelePCT];
        var data3 = [day_powerelePCT,month_powerelePCT,year_powerelePCT];
        var data4 = [day_specialelePCT,month_specialelePCT,year_specialelePCT];

        // for (var i = 0; i < 10; i++) {
        //     xAxisData2.push((2009 + i)+'年');
        //     data1.push((Math.random() * 2).toFixed(2));
        //     data2.push((Math.random() * 5).toFixed(2));
        //     data3.push((Math.random() + 0.3).toFixed(2));
        //     data4.push(Math.random().toFixed(2));
        // }

        var emphasisStyle = {
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0,0,0,0.3)'
            }
        };

        let getOption = {
            legend: {
                data: ['照明插座', '空调用电', '动力用电', '特殊用电'],
                // color:'#59e280',
                textStyle: {
                    color: '#e5ff70',
                }
            },
            // brush: {
            //     toolbox: ['rect', 'polygon', 'lineX', 'lineY', 'keep', 'clear'],
            //     xAxisIndex: 0
            // },
            // toolbox: {
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {}
            //     }
            // },
            // tooltip: {},
            xAxis: {
                data: xAxisData2,
                axisLabel:{
                    textStyle:{
                        color:'#777a55'}
                },
                name: 'X Axis',
                color:'#8d6f17',
                axisLine: {onZero: true},
                splitLine: {show: false},
                splitArea: {show: false}
            },
            yAxis: {
                axisLabel:{
                    testStyle:{
                        color:'red'}
                },
                color:'red',
                // data:[1,2,3,4]


                },
            grid: { left: '55', top: '35', right: '30', bottom: '22' },
            series: [
                {
                    name: '照明插座',
                    color:'#873785',
                    type: 'bar',
                    stack: 'one',
                    emphasis: emphasisStyle,
                    data: data1,
                    label: {
                        // color:'#121212',
                        normal: {
                            show: true,
                            formatter: function (params) {
                                return params.value+ '%';
                                // return params.name.substr(0,2)+'\r\n'+params.value+ '%';
                            },
                        },
                    },
                    // name: "照明插座：" + day_lightrcptclPCT + " %",
                },
                {
                    name: '空调用电',
                    color:'#775d09',
                    type: 'bar',
                    stack: 'one',
                    emphasis: emphasisStyle,
                    data: data2,
                    label: {
                        // color:'#121212',
                        normal: {
                            show: true,
                            formatter: function (params) {
                                return params.value+ '%';
                                // return params.name.substr(0,2)+'\r\n'+params.value+ '%';
                            },
                        },
                    },
                },
                {
                    name: '动力用电',
                    color:'#00634c',
                    type: 'bar',
                    stack: 'one',
                    emphasis: emphasisStyle,
                    data: data3,
                    label: {
                        // color:'#121212',
                        normal: {
                            show: true,
                            formatter: function (params) {
                                return params.value+ '%';
                                // return params.name.substr(0,2)+'\r\n'+params.value+ '%';
                            },
                        },
                    },
                },
                {
                    name: '特殊用电',
                    color:'#4e5563',
                    type: 'bar',
                    stack: 'one',
                    emphasis: emphasisStyle,
                    data: data4,
                    label: {
                        // color:'#121212',
                        normal: {
                            show: true,
                            formatter: function (params) {
                                return params.value+ '%';
                                // return params.name.substr(0,2)+'\r\n'+params.value+ '%';
                            },
                        },
                    },
                }
            ]
        };



        return (
            <div className='indexMidLeftV3'>
                <div className='indexMidLeftHead'>
                    {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                        {/*<i className='round'></i>*/}
                    {/*</Border4>*/}
                    <span style={{fontSize: '18px',
                        fontWeight: 'bold',left:'10px',top:'1px',position:'absolute',  color: '#92d271',
                        fontFamily: 'monospace'}}>温州市分项能耗碳排放消费结构趋势</span>

                </div>
                <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
            </div>
        )
    }
}


export default PartLeft2;
