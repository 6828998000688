import React from 'react';
import { Table, Switch, Pagination } from 'antd';
import * as r from 'ramda';
import moment from 'moment';

import TableTop from 'src/components/table/tableTop/tableTop';
import TableEdit from 'src/components/table/tableEdit/tableEdit';
import TableBottom from 'src/components/table/tableBottom/tableBottom';
import Management from 'src/components/modal/management/management';
import Delete from 'src/components/modal/delete/delete';

// import './dayReport.scss';
import Api from 'api';

class Melectricity extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            data: [{}],
            allData: null,
            isUpData: false,
            tableLoading: false,
            total: 0,
            operationIsShow: false, pageindex: 1,
            pagesize: 10,
            pageCount: 1
        };
    }

    async getData(pageindex, pagesize) {
        this.setState({
            tableLoading: true,
            data: []
        })


        const mElectricity = await Api.post('/business/monitor', {
            devicetype: "MELECTRICITYMETER",
            project: Api.projectId,
            ext: { enableMask: 1 },
            mode: "SENSOR",
            pageindex: pageindex,
            pagesize: pagesize,
        }
        );


        let data = [];
        const orderAlgorithm = (x) => (x + 1) + ((pageindex - 1) * pagesize);

        mElectricity.data.result[Api.projectId].detail.forEach((i, k) => {

            let order = orderAlgorithm(k) < 10 ? '0' + orderAlgorithm(k) : orderAlgorithm(k);

            data.push({
                key: 'mElectricity' + k,
                //  '序号',
                order: order,
                // '智能仪表名称',
                name: i.title,
                // '智能仪表ID',
                instrumentId: i.addrid,
                //  '正向有功',
                positive: i.channels['11'].realdata,
                // 'A相电压',
                voltageA: i.channels['15'].realdata,
                // 'B相电压',
                voltageB: i.channels['16'].realdata,
                //    'C相电压',
                voltageC: i.channels['17'].realdata,
                //  'A相电流',
                currentA: i.channels['18'].realdata,
                //    'B相电流',
                currentB: i.channels['19'].realdata,
                // 'C相电流',
                currentC: i.channels['20'].realdata,
                // '通讯状态',
                state: i.channels['11'].status,
                // '通讯时间',
                time: moment(i.channels['11'].lastupdate).format('YYYY-MM-DD HH:mm:ss'),
                //  '采集器编号',
                number: i.gatewayid,
                allInformation: i
            })
        })

        this.setState({
            data: data,
            total: mElectricity.data.result[Api.projectId].detail.length,
            tableLoading: false,
            pageCount: mElectricity.data.result[Api.projectId].paging.count

        })
        
    }

    componentDidMount() {
        this.getData(this.state.pageindex, this.state.pagesize)
    }


    handleChange = (value) => {
        console.log(`selected ${value}`);
    }

    managementShow = () => {
        this.setState({
            modal: true
        })
    }
    upData = () => {
        this.getData()
    }

    pageChange = (current, pageSize) => {
        console.log(pageSize)
        this.getData(current, pageSize)
    }
    render() {
        const columns = [{
            title: '序号',
            dataIndex: 'order',
            key: 'order',
            width: 70,
        }, {
            title: '智能仪表名称',
            dataIndex: 'name',
            key: 'name',
            render: (text, record) => (
                <TableEdit text={text} record={record} />
            ),
            width: 155,
        }, {
            title: '智能仪表ID',
            dataIndex: 'instrumentId',
            key: 'instrumentId',
            width: 125,
        }, {
            title: '正向有功',
            dataIndex: 'positive',
            key: 'positive',
            width: 90,
        }, {
            title: 'A相电压',
            dataIndex: 'voltageA',
            key: 'voltageA',
            width: 90,
        }, {
            title: 'B相电压',
            dataIndex: 'voltageB',
            key: 'voltageB',
            width: 90,
        }, {
            title: 'C相电压',
            dataIndex: 'voltageC',
            key: 'voltageC',
            width: 90,
        }, {
            title: 'A相电流',
            dataIndex: 'currentA',
            key: 'currentA',
            width: 90,
        }, {
            title: 'B相电流',
            dataIndex: 'currentB',
            key: 'currentB',
            width: 90,
        }, {
            title: 'C相电流',
            dataIndex: 'currentC',
            key: 'currentC',
            width: 90,
        }, {
            title: '通讯状态',
            dataIndex: 'state',
            key: 'state',
            render: (text, record) => {
                return <span className={record.state ? 'colorY' : 'colorG'}>{record.state ? '• 异常' : '• 正常'}</span>
            },
            width: 90,
        }, {
            title: '通讯时间',
            dataIndex: 'time',
            key: 'time',
            width: 190,
        },
        //  {
        //     title: '开关状态',
        //     dataIndex: 'switch',
        // key: 'switch',
        //     render: (text, record) => (
        //         // <div>发送</div>
        //         // onoff
        //         <Switch checkedChildren='开' unCheckedChildren='关' defaultChecked={record.onoff === 0 ? true : false} onChange={this.onChange} />
        //     ),
        //     width: 90,
        // }, 
        {
            title: '采集器编号',
            dataIndex: 'number',
            key: 'number',
            width: 110,
        },
        // {
        //     title: '操作',
        //     dataIndex: 'operation',
        // key: 'operation',
        //     render: (text, record) => (
        //         <div>
        //             <Management record={record} isUpData={this.upData} />
        //             <Delete record={record} />
        //         </div>
        //     ),
        // }, 
        {
            title: '设备标识',
            dataIndex: 'device_id',
            key: 'device_id',
            className: 'hidden',
        }, {
            title: '倍率',
            dataIndex: 'multipoint',
            key: 'multipoint',
            className: 'hidden',
        }, {
            title: '更新频率',
            dataIndex: 'updatecyc',
            key: 'updatecyc',
            className: 'hidden',
        }, {
            title: '是否删除',
            dataIndex: 'is_del',
            key: 'is_del',
            className: 'hidden',
        }, {
            title: '是否为总表',
            dataIndex: 'principal',
            key: 'principal',
            className: 'hidden',
        }];

        const testData = [{
            order: '01',
            name: 'asndl',
            instrumentId: '12123098',
            positive: '12.2',
            voltageA: '1.2',
            voltageB: '2.41',
            voltageC: '7,12',
            currentA: '9.21',
            currentB: '9.21',
            currentC: '9.21',
            state: '正常',
            time: '2019-03-26 16:17:16',
            switch: '开',
            number: '15',
            device_id: '设备标识123',
            multipoint: '倍率123',
            updatecyc: '更新频率123',
            is_del: '1',
            principal: '1',
        }];

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                if (r.length(selectedRows)) {
                    this.setState({ operationIsShow: true })
                }
                else {
                    this.setState({ operationIsShow: false })
                }
                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            },
            getCheckboxProps: record => ({
                disabled: record.name === 'Disabled User', // Column configuration not to be checked
                name: record.name,
            }),
        };

        const tableTop = {
            result: this.state.pageCount,
            liData: ['搜索']
        }
        return (
            <div className='reportRightTable' >
                <TableTop props={tableTop} operationIsShow={this.state.operationIsShow} />
                <Table
                    rowSelection={rowSelection}
                    columns={columns}
                    // dataSource={testData}
                    dataSource={this.state.data}
                    scroll={{ y: 580, x: 1600 }}
                    pagination={false}
                    loading={this.state.tableLoading}
                    rowKey='MElecricity'
                    className='reportRightTableTab' />
                <div className='reportRightTableFoot'>
                    <Pagination className='pagination' showSizeChanger={true} onChange={this.pageChange} onShowSizeChange={this.pageChange} defaultCurrent={1} total={this.state.pageCount} />
                </div>
            </div >
        )
    }
}


export default Melectricity;