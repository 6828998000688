import React from 'react';

import './indexTop.scss';

import Border2 from '../ui/border2/border2';
import BorderLeft from '../ui/borderLeft/borderLeft';


import IndexTopLeft from './indexTopLeft/indexTopLeft';
import IndexTopRight from './indexTopRight/indexTopRight';

class IndexTop extends React.Component {
    render() {
        return (
            <div className='indexTop'>
                <Border2>
                    <IndexTopLeft />
                </Border2>
                <BorderLeft className='ml-10px'>
                    <IndexTopRight />
                </BorderLeft>
            </div>
        )
    }
}


export default IndexTop;