import React, { useState, useEffect } from 'react';
import ReactEcharts from 'echarts-for-react';
import * as r from 'ramda';
import Api from "api";
import moment from 'moment'
import { Row, Col } from 'antd'
import './mapTable.scss';
import Analysis from '../../energy/analysis/analysis';
import { typesInCategory } from 'src/context/typeCategory'
import Img_down from 'src/img/down.png'
import Img_up from 'src/img/up.png'

//地域用

const translateType = v => {
    const valueMap = {
        ELECTRICITYMETER: '电表',
        PHOTOVOLTAIC: '光伏',
        WATER: '水表',
        AC: '空调',
        COLDWATERMETER: '冷水',
        ENERGYMETER_COLD: '总冷量',
        ENERGYMETER_HOT: '总热量',
        HOTWATERMETER: '热水',
        MELECTRICITYMETER: '多功能电表',
        ULTRACOLDWATERMETER: '超声波水表',
    }
    return r.propOr(v)(v)(valueMap)
}
const translateFormat = r.map(r.pipe(r.prop('value'),
    x => isNaN(x) || r.isNil(x) ? NaN : Number(x).toFixed(2)))

const translateTimeline = v => {
    const valueMap = {
        daily: r.map(i => `0${i}`.slice(-2) + ':00')(r.range(0, moment().hours())),
        monthly: r.map(i => `0${i}`.slice(-2))(
            r.range(1, moment().date())),
        annually: r.map(i => `0${i}`.slice(-2))(r.range(1, 13)),
    }
    return r.propOr(v)(v)(valueMap)
}
const translateKgce = (type) => {
    return type === 'annually' ?
        moment().format('YYYY') :
        moment().format('YYYYMM')
}
const translateLastKgce = (type) => {
    const time = moment().subtract(1, 'year');
    return type === 'annually' ? time.format('YYYY') : time.format('YYYYMM')
}

const formatMonthlyKgce = r.compose(
    r.values,
    r.map(r.pipe(
        r.pluck('kgce'),
        ([w = 0, e = 0]) => Number(w) + Number(e))),
    r.groupBy(r.prop('date')),
    r.flatten,
    r.values)
const categoryMeasure = category => data => r.pipe(
    r.map(type => r.pipe(r.propOr([])(type), r.pluck('value'),
        r.map(x => Number(x)), r.sum)(data)),
    r.sum,
    x => x.toFixed(2))(typesInCategory(category))

const ratioOf = category => (current, last) => {
    const thisPhrase = categoryMeasure(category)(current)
    const lastPhrase = categoryMeasure(category)(last)
    return lastPhrase === 0 || r.isNil(lastPhrase) ?
        0 :
        (100 * (Number(thisPhrase) - Number(lastPhrase)) /
            Number(lastPhrase)).toFixed(2)
}


const echartsLabel = {
    normal: {
        show: false,
        position: 'top',
        distance: 10,
        align: 'center',
        verticalAlign: 'middle',
    },
}
const textStyle = {
    color: '#fff'
}
const mapTableRegion = (props) => {
    const [nodeUsage, setNodeUsage] = useState({})
    const [kgceData, setKgceData] = useState({})
    const [listData, setListData] = useState({})
    const [monthListData, setMonthListData] = useState({})
    const { mapTableIsShow, nodeid ,region,regionName,projectId} = props;
    useEffect(() => {
        // console.log('useEffectRegion go to run ---------');
        usagePeriodRegion(nodeid,region,projectId, 'daily')
        kgcePeriodRegion(nodeid,region, projectId,'monthly')
    }, [nodeid,region])

    const usagePeriodRegion = (node,region,projectId, usagePeriod) => {
        // console.log(node,projectId, usagePeriod);
        if(typeof node === 'undefined' || node==null || typeof region === 'undefined' || region==null|| region=='' || typeof projectId === 'undefined' || projectId==null|| projectId==''|| typeof usagePeriod === 'undefined' || usagePeriod==null ){
            return;
        }
        // console.log('to req the usageregion ------');
        Api.get(
            `/api/v3/energy/project/${projectId}/region/${region}/nodeUsageRegion/${node}/${usagePeriod}`).then(({ data: { current: today, last: yesterday } }) => {
                setNodeUsage({
                    color: ['#86CCFF', '#77e6fd', '#FFAD33', '#FBE641'],
                    textStyle,
                    grid: { left: '0', top: '25', right: '30', bottom: '22', containLabel: true },
                    legend: {
                        data: r.map(([k, x]) => ({
                            name: translateType(k),
                        }))(r.toPairs(today)),
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                    },
                    xAxis: {
                        type: 'category',
                        data: translateTimeline(usagePeriod),
                    },
                    yAxis: {
                        type: 'value',
                    },
                    series: r.map(([k, x]) => ({
                        name: translateType(k),
                        type: 'bar',
                        data: translateFormat(x || []),
                        label: echartsLabel
                        // itemStyle: { barBorderRadius: 5 },
                        // barWidth: 10,
                    }))(r.toPairs(today)),
                })
                setListData({
                    todayElectricity: categoryMeasure('ELECTRICITYMETER')(today),
                    todayElectricityRatio: ratioOf('ELECTRICITYMETER')(today, yesterday),
                    todayWater: categoryMeasure('WATER')(today),
                    todayWaterRatio: ratioOf('WATER')(today, yesterday),
                })

            })
    }
    const kgcePeriodRegion = (node,region,projectId, kgcePeriod) => {
        // console.log(node,projectId, usagePeriod);
        if(typeof node === 'undefined' || node==null || typeof region === 'undefined' || region==null|| region=='' || typeof projectId === 'undefined' || projectId==null|| projectId==''|| typeof kgcePeriod === 'undefined' || kgcePeriod==null ){
            return;
        }

        // console.log('to req the kgceregion ------');
        Api.get(
            `/api/v3/energy/project/${projectId}/region/${region}/nodeKgceRegion/${node}/${kgcePeriod}`).then(
                ({ data: { current: thisYear, last: lastYear } }) => {
                    //针对多种water type情况下，数据超出了31天导致显示异常，这里进行处理
                    if(thisYear.WATER && thisYear.WATER.length > 0){
                        console.log('begin to deal water data ---- ');
                        thisYear.WATER=r.map(y=>({date:y[0].date,value:r.sum(r.map(r.prop('value'))(y))}))(r.groupWith((a,b)=>a.date == b.date)(thisYear.WATER));
                        console.log('thisYear.WATER');
                        console.log(thisYear.WATER);
                    }
                    if(lastYear.WATER && lastYear.WATER.length > 0){
                        console.log('begin to deal water data ---- ');
                        lastYear.WATER=r.map(y=>({date:y[0].date,value:r.sum(r.map(r.prop('value'))(y))}))(r.groupWith((a,b)=>a.date == b.date)(lastYear.WATER));
                        console.log('lastYear.WATER');
                        console.log(lastYear.WATER);
                    }



                    const seriesTime = r.map(({ date }) => date.toString().slice(-2))(
                        thisYear['ELECTRICITYMETER'] || thisYear['WATER'] || [])
                    // console.log('before --');
                    // console.log(thisYear);
                    // let newThisYear = {WATER:thisYear.WATER,ELECTRICITYMETER:thisYear.ELECTRICITYMETER};
                    const monthlyKgce = formatMonthlyKgce(thisYear);
                    // console.log('after --');
                    // console.log(monthlyKgce);
                    const lastYearMonthlyKgce = formatMonthlyKgce(lastYear);

                    const seriesData = r.zip(r.range(0, 31))(r.map(v => v.toFixed(2))(monthlyKgce))
                    const seriesDataLast = r.zip(r.range(0, 31))(r.map(v => v.toFixed(2))(lastYearMonthlyKgce))



                    setKgceData({
                        color: ['#86CCFF', '#FFAD33'],
                        textStyle,
                        grid: { left: '0', top: '25', right: '30', bottom: '22', containLabel: true },
                        legend: {
                            data: [translateKgce(kgcePeriod), translateLastKgce(kgcePeriod)],
                            align: 'left',
                            textStyle: {
                                color: '#fff'
                            }
                        },
                        tooltip: {
                            trigger: 'axis',
                        },
                        xAxis: {
                            type: 'category',
                            data: seriesTime,
                        },
                        yAxis: {
                            type: 'value',
                        },
                        series: [
                            {
                                name: translateKgce(kgcePeriod),
                                data: seriesData,
                                type: 'bar',
                                label: echartsLabel
                            }, {
                                name: translateLastKgce(kgcePeriod),
                                data: seriesDataLast,
                                type: 'bar',
                                label: echartsLabel
                            }],
                    })
                    setMonthListData(
                        {
                            monthWaterTotal: categoryMeasure('WATER')(thisYear),
                            monthWaterRatio: ratioOf('WATER')(thisYear, lastYear),
                            monthElectricityTotal: categoryMeasure('ELECTRICITYMETER')(thisYear),
                            monthElectricityRatio: ratioOf('ELECTRICITYMETER')(thisYear, lastYear),
                            monthlyRatio: ((r.last(monthlyKgce) || 0 - r.last(lastYearMonthlyKgce) ||
                                0) / (r.last(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
                            annuallyRatio: ((r.sum(monthlyKgce) - r.sum(lastYearMonthlyKgce)) /
                                (r.sum(lastYearMonthlyKgce) || 1) * 100).toFixed(2),
                            kgceMonth: (r.last(monthlyKgce) || 0).toFixed(2),
                            kgceAnnual: r.sum(monthlyKgce).toFixed(2),
                        }
                    )

                })
    }

    const { todayElectricity,
        todayElectricityRatio,
        todayWater,
        todayWaterRatio } = listData
    const { monthWaterTotal,
        monthWaterRatio,
        monthElectricityTotal,
        monthElectricityRatio,
        monthlyRatio,
        annuallyRatio,
        kgceMonth,
        kgceAnnual } = monthListData

    const abnormalNub = (x) => {
        return x === 'NaN' || x === 'Infinity' ? 0.00 : x;
    }

    //显示时重新请求
    if(mapTableIsShow){
        // console.log('to show ');
        // usagePeriod(id,projectId, 'daily')
        // kgcePeriod(id,projectId, 'monthly')
    }else{
        // console.log('not to show');
    }

    return (

        <ul className={`chartsContainer ${mapTableIsShow ? 'chartsOpen' : 'chartsClose'}`}>
            <li>
                <div className='liTopLeft'>
                    <Row>
                        <Col span={26}><b>{regionName}区域</b></Col>

                    </Row>
                    <Row>
                        <Col span={16}>当日用水：<b>{todayWater} m³</b></Col>
                        <Col span={8}>
                            环比涨幅：<i>{monthlyRatio}%</i>
                            {/*昨日同期：<i>{abnormalNub(todayWaterRatio)}%</i><img*/}
                            {/*src={todayWaterRatio > 0 ? Img_up : Img_down} alt="" />*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>当日用电：<b>{todayElectricity} kwh</b></Col>
                        <Col span={8}>

                            同比涨幅：<i>{annuallyRatio}%</i>
                            {/*昨日同期：<i>{abnormalNub(todayElectricityRatio)}%</i><img*/}
                            {/*src={todayElectricityRatio > 0 ? Img_up : Img_down}*/}
                            {/*alt="" />*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>当月用水：<b>{monthWaterTotal} m³</b></Col>
                        <Col span={8}>
                            月综合能耗：<b>{kgceMonth} </b>kgce
                            {/*上月同期：<i>{abnormalNub(monthWaterRatio)}%</i><img*/}
                            {/*src={monthWaterRatio > 0 ? Img_up : Img_down} alt="" />*/}
                        </Col>
                    </Row>
                    <Row>
                        <Col span={16}>当月用电：<b>{monthElectricityTotal} kwh</b></Col>
                        <Col span={8}>
                            年综合能耗：<b>{kgceAnnual} </b>kgce
                            {/*上月同期：<i>{abnormalNub(monthElectricityRatio)}%</i><img*/}
                            {/*src={monthElectricityRatio > 0 ? Img_up : Img_down}*/}
                            {/*alt="" />*/}
                        </Col>
                    </Row>
                </div>
                {/*<div className='liTopLeft'>*/}
                    {/*<Row>*/}
                        {/*<Col span={26}><b>{regionName}区域</b></Col>*/}

                    {/*</Row>*/}
                    {/*<Row>*/}
                        {/*<Col span={16}>当日用水：<b>{todayWater} m³</b></Col>*/}
                        {/*<Col span={8}>*/}
                            {/*昨日同期：<i>{abnormalNub(todayWaterRatio)}%</i><img*/}
                                {/*src={todayWaterRatio > 0 ? Img_up : Img_down} alt="" />*/}
                        {/*</Col>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                        {/*<Col span={16}>当日用电：<b>{todayElectricity} kwh</b></Col>*/}
                        {/*<Col span={8}>昨日同期：<i>{abnormalNub(todayElectricityRatio)}%</i><img*/}
                            {/*src={todayElectricityRatio > 0 ? Img_up : Img_down}*/}
                            {/*alt="" /></Col></Row>*/}
                    {/*<Row>*/}
                        {/*<Col span={16}>当月用水：<b>{monthWaterTotal} m³</b></Col>*/}
                        {/*<Col span={8}>上月同期：<i>{abnormalNub(monthWaterRatio)}%</i><img*/}
                            {/*src={monthWaterRatio > 0 ? Img_up : Img_down} alt="" /></Col></Row>*/}
                    {/*<Row>*/}
                        {/*<Col span={16}>当月用电：<b>{monthElectricityTotal} kwh</b></Col>*/}
                        {/*<Col span={8}>上月同期：<i>{abnormalNub(monthElectricityRatio)}%</i><img*/}
                            {/*src={monthElectricityRatio > 0 ? Img_up : Img_down}*/}
                            {/*alt="" /></Col></Row>*/}
                {/*</div>*/}
                {/*<div className='liTopRight'>*/}
                    {/*<Row>*/}
                        {/*<Col span={16}>环比涨幅：<i>{monthlyRatio}%</i></Col>*/}
                        {/*<Col span={8}>月综合能耗：<b>{kgceMonth} </b>kgce</Col>*/}
                    {/*</Row>*/}
                    {/*<Row>*/}
                        {/*<Col span={16}>同比涨幅：<i>{annuallyRatio}%</i></Col>*/}
                        {/*<Col span={8}>年综合能耗：<b>{kgceAnnual} </b>kgce</Col>*/}
                    {/*</Row>*/}

                {/*</div>*/}
                <i className='border'></i>
            </li>
            <li>
                <ReactEcharts option={nodeUsage} notMerge={true} style={{ width: '100%', height: '100%' }} />
            </li>
            <li>
                <ReactEcharts option={kgceData} notMerge={true} style={{ width: '100%', height: '100%' }} />
            </li>
        </ul >
    )
}

export default mapTableRegion;
