import React, {useContext} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import {context, loadNodeUsage} from '../reportRegion'
import {of, from} from 'rxjs'

import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';


const IntervalReportRegion = () => {
    const {state: {loading, tableData, from, to, deviceType, select, total,node}, dispatch} = useContext(context);
    const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData))
    const columns = [
    //     {
    //     title: '项目号',
    //     dataIndex: 'project',
    //     key: 'project',
    //     width: '100px',
    // },
        {
        title: '项目名称',
        dataIndex: 'projectName',
        key: 'projectName',
        width: '70px',
    },{
        title: '项目类型',
        dataIndex: 'type',
        key: 'type',
        width: '70px',
    },{
        title: '面积',
        dataIndex: 'totalArea',
        key: 'totalArea',
        width: '70px',
    },{
        title: '人数',
        dataIndex: 'headCount',
        key: 'headCount',
        width: '70px',
    },
        {
            title: '电能总值',
            dataIndex: 'electricity',
            key: 'electricity',
            width: '70px',
            sorter: (a, b) => a.electricity - b.electricity,
        }

    ];


    const tableTop = {
        result: tableData.length,
        // liData: ['区间', '选择', '搜索',],
        liData: ['区间', '搜索',],
        from,
        to,
        select,
        deviceType,
        columns,
        tableData,
        handleScaleChange: ({deviceType, from, to}) => {
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                deviceType,
                from,
                to
            }))
        },
    };

    return (
        <div className='reportRightTable'>
            <TableTop props={tableTop} loadUp={loadNodeUsage} dispatch={dispatch} api={'Excel'} columns={columns}
                      tableData={tableData}/>
            <Table
                columns={columns}
                dataSource={tableData}
                scroll={{y: 710}}
                pagination={false}
                loading={loading}
                className='reportRightTableTab'/>
            <div style={{float: 'right', marginRight: '20px', color: '#1890ff'}}>
                <span style={{marginRight: '20px'}}>总消耗:{total.usage}</span>
                {/*<span>总费用:{total.cost}</span>*/}
            </div>
        </div>
    )
}


export default IntervalReportRegion;
