import React from 'react';
import './borderLeft.scss'
const BorderLeft = (props) => {
    return(
    <div className={`border2Container ${props.className}`}>
        <div className="border2 border2Left"></div>
        {props.children}
    </div>
)}

export default BorderLeft