import React, { useContext } from 'react';
import * as r from 'ramda'
import Echarts from 'src/components/echarts/echarts';
import { context, loadNodeUsage } from '../../../department';
import { Spin } from 'antd';
import {of} from 'rxjs';

const DepartmentRank = () => {
    const { state: { nodeRank: { data }, meterType, loading}, dispatch } = useContext(context)

    const echartsData = {
        tit: '部门分项用能',
        ulShow: ['ele', 'water'],
        data: {
            color: ['#a6c5f7', '#00c1c4', '#7397c1', '#98f2f0', '#fb9bee', '#ff9e4f', '#98f2f0'],
            grid: { left: '20', top: '5', right: '10', bottom: '22' },
            legend: { show: false },
            tooltip:{},
            series: [
                {
                    data,
                    type: 'pie',
                    label: { width: '18px' },
                }],
        },
        selection: meterType === 'ELECTRICITYMETER'  ? 'ele' : 'water',
        handleScaleChange: (meterType) => {
            console.log(meterType)
            if (!r.includes(meterType)(['ele', 'water'])) {
                return
            }
            dispatch(state => of({ ...state, loading: true }))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                meterType: meterType === 'ele' ? 'ELECTRICITYMETER' : 'WATER'
            }))
        },
    }
    return (
        <div className="departmentRightBottom3">
            <Spin spinning={loading} size='large'>
                <Echarts props={echartsData} />
            </Spin>
        </div>
    )
}

export default DepartmentRank;