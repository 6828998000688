import React, {useContext} from 'react';
import {context} from '../../../building'
import {Icon} from 'antd';

const BuildingCurrentUsage = () => {
  const {state: {thisNodeUsage: {area, cost, costYoY, costMoM, ele, eleYoY, eleMoM, headcount, perCapitaArea, perCapitaEle, perCapitaEnergy, perCapitaWater, totalEnergy, totalEnergyMoM, totalEnergyYoY, water, waterYoY, waterMoM}}} = useContext(
    context)

  return (
    <div className="buildingRightBottom1">
      <ul>
        <li><span>总能耗:<b>{totalEnergy}</b></span>
          <span>同比:<b className={totalEnergyYoY > 0 ?
            'colorR' :
            'colorG'}>{totalEnergyYoY}<Icon
            type={totalEnergyYoY > 0 ? 'arrow-up' : 'arrow-down'}/></b> </span>
          <span>环比:<b className={totalEnergyMoM > 0 ?
            'colorR' :
            'colorG'}>{totalEnergyMoM}<Icon
            type={totalEnergyMoM > 0 ? 'arrow-up' : 'arrow-down'}/></b> </span>
        </li>
        <li>
          <span>电消耗:<b>{ele}</b></span>
          <span>同比:<b className={eleYoY > 0 ? 'colorR' : 'colorG'}>{eleYoY}<Icon
            type={eleYoY > 0 ? 'arrow-up' : 'arrow-down'}/></b> </span>
          <span>环比:<b className={eleMoM > 0 ? 'colorR' : 'colorG'}>{eleMoM}<Icon
            type={eleMoM > 0 ? 'arrow-up' : 'arrow-down'}/></b> </span>
        </li>
        <li>
          <span>水消耗:<b>{water}</b></span>
          <span>同比:<b
            className={waterYoY > 0 ? 'colorR' : 'colorG'}>{waterYoY}<Icon
            type={waterYoY > 0 ? 'arrow-up' : 'arrow-down'}/></b> </span>
          <span>同比:<b
            className={waterMoM > 0 ? 'colorR' : 'colorG'}>{waterMoM}<Icon
            type={waterMoM > 0 ? 'arrow-up' : 'arrow-down'}/></b> </span>
        </li>
        <li>
          <span>总费用:<b>{cost}</b></span>
          <span>同比:<b
            className={costYoY > 0 ? 'colorR' : 'colorG'}>{costYoY}<Icon
            type={costYoY > 0 ? 'arrow-up' : 'arrow-down'}/> </b></span>
          <span>同比:<b
            className={costMoM > 0 ? 'colorR' : 'colorG'}>{costMoM}<Icon
            type={costMoM > 0 ? 'arrow-up' : 'arrow-down'}/> </b></span>
        </li>

        <li className='between'>
          <span>总人数:<b>{headcount}</b></span><span>总面积:<b>{area}</b></span></li>
        <li>
          <span>人均能耗:<b>{perCapitaEnergy}</b></span><span>人均面积:<b>{perCapitaArea}</b></span>
        </li>
        <li>
          <span>人均水耗:<b>{perCapitaWater}</b></span><span>人均电耗:<b>{perCapitaEle}</b></span>
        </li>
      </ul>
    </div>
  )
}

export default BuildingCurrentUsage;