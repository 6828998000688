import React from 'react';

import './indexMidLeftForWeather.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import { timer } from 'rxjs';
import Border4 from '../../ui/border4/border4';
import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';


const dateFormat = function (date, format) {
    date = new Date(date);
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour+8小时
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

    return format;
}

class IndexMidLeftForWeather extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            xAxisData: [],
            electricityData: [],
            humiData: [],
            dewData: [],
            atmosData: [],
            radiaData: [],
            windspeedData: [],
            winddirData: [],
            tempNow: '',
            humiNow: '',
            dewNow: '',
            atmosNow: '',
            radiaNow: '',
            windspeedNow: '',
            winddirNow: '',

            notMerge: true,
            lazyUpdate: true,
            style: { width: "80%", height: "260px"},
            style2: { width: "50%", height: "280px",margin: '0',float: 'left' },
            style_temp: { fontSize:'30px',color:'#78bbfd',float:'left' },
            style_humi: { fontSize:'30px',color:'#d3fd8b',float:'left' },
            style_dew: { fontSize:'30px',color:'#f19865',float:'left' },
            style_radia: { fontSize:'30px',color:'#a6f16c',float:'left' },
            style_windspeed: { fontSize:'30px',color:'#56fddf',float:'left' },
            style_winddir: { fontSize:'30px',color:'#7ab4ba',float:'left' },
            active: "line"
        };
    }

    async getData(active='bar') {
        this.state = { getOption: { series: [] }, ...this.state }
        const data = new Date();
        const hh = data.getHours();

        const electricity = await Api.postV3('/energy/weather2', {
            project: Api.projectId,
            num:480
        });
        const weatherForNow = await Api.postV3('/energyForNew/weatherRightNow', {
            project: Api.projectId
        });

        // console.log('data-------------------:' + electricity.data.result.length);
        // console.log('dataRightNow-------------------:' + weatherForNow.data);
        let electricityTime = [];
        let electricityData = [];
        let dewData = [];
        let atmosData = [];
        let radiaData = [];
        let windspeedData = [];
        let winddirData = [];
        let humiData = [];
        let tempNow= '';
        let humiNow= '';
        let dewNow= '';
        let atmosNow= '';
        let radiaNow= '';
        let windspeedNow= '';
        let winddirNow= '';



        if (electricity.data.result) {
            let res = electricity.data.result;
            for (let i = 0; i < res.length; i++) {
                // electricityTime.push(`0${i}`.slice(-2) + ':00')
                electricityTime.push(dateFormat(res[res.length-i-1].addtime,'MM-dd HH:mm'))
                electricityData.push(res[res.length-i-1].temp || 0)
                humiData.push(res[res.length-i-1].humi || 0)
                dewData.push(res[res.length-i-1].dew || 0)
                atmosData.push(res[res.length-i-1].atmos || 0)
                radiaData.push(res[res.length-i-1].radia || 0)
                windspeedData.push(res[res.length-i-1].windspeed || 0)
                winddirData.push(res[res.length-i-1].winddir || 0)
            }
        }

        if (weatherForNow && weatherForNow.data) {
            let res = weatherForNow.data;
             tempNow= res.temp_value;
             humiNow= res.humi_value;
             dewNow= res.dew_value;
             atmosNow= res.atmos_value;
             radiaNow= res.radia_value;
             windspeedNow= res.windspeed_value;
             winddirNow= res.winddir_value;

        }

        this.setState({
            xAxisData: electricityTime,
            electricityData:electricityData,
            humiData:humiData,
            dewData:dewData,
            atmosData:atmosData,
            radiaData:radiaData,
            windspeedData:windspeedData,
            winddirData:winddirData,
            tempNow: tempNow,
            humiNow: humiNow,
            dewNow: dewNow,
            atmosNow: atmosNow,
            radiaNow: radiaNow,
            windspeedNow: windspeedNow,
            winddirNow: winddirNow,
            active: active
        })
    }

    componentDidMount() {

        const upDataTime = 1 * 60 * 1000;//分钟
        const source = timer(0, upDataTime);
        source.subscribe((x) => { console.log('indexTopLeft:' + x); this.getData() })
    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { xAxisData, active, electricityData, humiData, dewData, atmosData, radiaData, windspeedData, winddirData,winddirNow } = this.state;


        let getOption = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                    type: 'line',
                    name: '温度',
                    data: electricityData,
                    smooth: true,
                    itemStyle: {
                        normal: {
                            color: '#78bbfd'
                        }
                    },
                }]
        }

        let getOption2 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '湿度',
                data: humiData,
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#d3fd8b'
                    }
                },
            }]
        }

        let getOption3 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '露点',
                data: dewData,
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#FD7F33'
                    }
                },
            }]
        }

        let getOption4 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '大气压',
                data: atmosData,
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#fd8993'
                    }
                },
            }]
        }

        let getOption5 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '辐射',
                data: radiaData,
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#a6f16c'
                    }
                },
            }]
        }

        let getOption6 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '风速',
                data: windspeedData,
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#56fddf'
                    }
                },
            }]
        }

        let getOption7 = {
            grid: { left: '55', top: '20', right: '30', bottom: '22' },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
                textStyle: {
                    color: '#FEFEFF'
                },
                icon: 'circle'
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLabel: {
                    color: '#FEFEFF'
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                data: xAxisData
            },
            yAxis: {
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: true,
                    lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
                },
                axisLabel: { color: '#FEFEFF' }
            },
            series: [{
                type: 'line',
                name: '风向',
                data: winddirData,
                smooth: true,
                itemStyle: {
                    normal: {
                        color: '#fd334a'
                    }
                },
            }]
        }

        //风速仪表盘

        // 基于准备好的dom，初始化echarts实例
        // let myChart = echarts.init(document.getElementById('main'));
        // 指定图表的配置项和数据
        // myChart.setOption({
        let getOption8 ={
            //backgroundColor: '#000000',
            backgroundColor: 'rgba(8,8,8,0.1)', //rgba设置透明度0.1
            tooltip: {
                formatter: "{a} <br/>{b} : {c}%"
            },
            // toolbox: {
            //     feature: {
            //         restore: {},
            //         saveAsImage: {}
            //     }
            // },
            series: [{
                startAngle: 359.8, //开始角度 左侧角度
                endAngle: 0, //结束角度 右侧
                min:0,
                max:360,
                splitNumber: 16,
                name: '',
                type: 'gauge',
                clockwise: true,
                title: {                // 仪表盘标题。
                    show: false,             // 是否显示标题,默认 true。
                    offsetCenter: [0,"20%"],//相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
                    color: "#fff",          // 文字的颜色,默认 #333。
                    fontSize: 20,           // 文字的字体大小,默认 15。
                },
                detail: {               // 仪表盘详情，用于显示数据。
                    show: false,             // 是否显示详情,默认 true。
                    offsetCenter: [0,"50%"],// 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
                    color: "auto",          // 文字的颜色,默认 auto。
                    fontSize: 30,           // 文字的字体大小,默认 15。
                    formatter: "{value}%",  // 格式化函数或者字符串
                },
                pointer: {              // 仪表盘指针。
                    show: true,             // 是否显示指针,默认 true。
                    length: "60%",          // 指针长度，可以是绝对数值，也可以是相对于半径的百分比,默认 80%。
                    width: 5,               // 指针宽度,默认 8。
                },
                axisLine: { // 坐标轴线
                    lineStyle: { // 属性lineStyle控制线条样式
                        color: [
                            [0.25, '#c29839'],
                            [0.5, '#4f9e43'],
                            [0.75, '#79769e'],
                            [1, '#91c7ae']
                        ]
                    }
                },
                axisTick: {            // 坐标轴小标记
                    show: false,        // 属性show控制显示与否，默认不显示
                        splitNumber: 5,    // 每份split细分多少段
                        length :8,         // 属性length控制线长
                        lineStyle: {       // 属性lineStyle控制线条样式
                        color: '#eee',
                            width: 1,
                            type: 'solid'
                    }
                },
                axisLabel: {distance:-55,
                    formatter: function(e) {
                        switch (e + "") {
                            case "0":
                                return "东";
                            case "22.5":
                                return "东东南";
                            case "45":
                                return "东南";
                            case "67.5":
                                return "南东南";
                            case "90":
                                return "南";
                            case "112.5":
                                return "南西南";
                            case "135":
                                return "西南";
                            case "157.5":
                                return "西西南";
                            case "180":
                                return "西";
                            case "202.5":
                                return "西西北";
                            case "225":
                                return "西北";
                            case "247.5":
                                return "北西北";
                            case "270":
                                return "北";
                            case "292.5":
                                return "北东北";
                            case "315":
                                return "东北";
                            case "337.5":
                                return "东东北";
                            default:
                                return "";
                        }
                    },
                    textStyle: {fontSize: 10,}
                },
                data: [{ value:(winddirNow>90)?(winddirNow-90):(360-winddirNow), name: '风向' }
                ]
            }]
        };

        // setInterval(function() {
        //     option.series[0].data[0].value = (Math.random() * 100).toFixed(2) - 0;
        //     myChart.setOption(option, true);
        // }, 2000);




            return (
            <div className='indexMidLeftForWeather'>
                <div className='indexMidLeftHeadForWeather'>
                    {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                    {/*    <i className='round'></i>*/}
                    {/*</Border4>*/}
                    <span>天气数据</span>

                </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.electricityData[this.state.electricityData.length-1]}℃ </span>*/}
                        <span className='bigWord' style={this.state.style_temp}> {this.state.tempNow}℃ </span>
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption2} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.humiData[this.state.humiData.length-1]}% </span>*/}
                        <span className='bigWord' style={this.state.style_humi}> {this.state.humiNow}% </span>
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption3} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.dewData[this.state.dewData.length-1]}Td </span>*/}
                        <span className='bigWord' style={this.state.style_dew}> {this.state.dewNow}Td </span>
                    </div>

                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption5} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.radiaData[this.state.radiaData.length-1]}Si </span>*/}
                        <span className='bigWord' style={this.state.style_radia}> {this.state.radiaNow}Si </span>
                    </div>
                    <div className='divEcharts' style={this.state.style2}>
                        <ReactEcharts option={getOption6} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                        {/*<span className='bigWord' style={this.state.style3}> {this.state.windspeedData[this.state.windspeedData.length-1]}m/s </span>*/}
                        <span className='bigWord' style={this.state.style_windspeed}> {this.state.windspeedNow}m/s </span>
                    </div>
                <div className='divEcharts' style={this.state.style2}>
                    {/*<ReactEcharts option={getOption4} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />*/}
                    {/*<span className='bigWord' style={this.state.style3}> {this.state.atmosData[this.state.atmosData.length-1]}Pa </span>*/}
                    <ReactEcharts option={getOption8} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
                    <span className='bigWord' style={this.state.style_winddir}> 风向 </span>
                </div>
                {/*<ReactEcharts option={getOption7} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />*/}
            </div>
        )
    }
}


export default IndexMidLeftForWeather;
