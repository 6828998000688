import React, { Component } from 'react';

import './indexBottom3.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';

class IndexBottom3 extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sum: 0,
            lightrcptclPCT: 0,
            airelePCT: 0,
            powerelePCT: 0,
            specialelePCT: 0,
            active: "day"
        };
    }

    async getData(api, active) {
        this.state = { getOption: { series: [] }, ...this.state };
        const data = await Api.get(api);

        const sum = data.data.total ? data.data.total.toFixed(2) : 0.00;
        const lightrcptcl = data.data.lightrcptcl ? data.data.lightrcptcl.toFixed(2) : 0.00;
        const powerele = data.data.powerele ? data.data.powerele.toFixed(2) : 0.00;
        const airele = data.data.airele ? data.data.airele.toFixed(2) : 0.00;
        const specialele = data.data.specialele ? data.data.specialele.toFixed(2) : 0.00;

        const lightrcptclPCT = lightrcptcl ? ((lightrcptcl / sum) * 100).toFixed(2) : 0.00;
        const powerelePCT = powerele ? ((powerele / sum) * 100).toFixed(2) : 0.00;
        const airelePCT = airele ? ((airele / sum) * 100).toFixed(2) : 0.00;
        const specialelePCT = specialele ? ((specialele / sum) * 100).toFixed(2) : 0.00;


        this.setState({
            sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT,
            active: active
        })
    }


    componentDidMount() {
        this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioDaily`, 'day')
    }

    selected(params) {

        switch (params) {
            case 'day':
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioDaily`, 'day')
                break;
            case 'month':
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioMonthly`, 'month')
                break;
            case 'year':
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioAnnually`, 'year')
                break;
            default:
                this.getData(`/api/v3/project/${Api.projectId}/subentry/ratioDaily`, 'day')
                break;

        }
        // console.log(this.state)
    }


    render() {
        const { sum, lightrcptclPCT, airelePCT, powerelePCT, specialelePCT } = this.state;
        const getOption = {
            title: {
                text: '总能耗',
                textStyle: {
                    fontSize: 14,
                    fontFamily: 'PingFang-SC-Medium',
                    fontWeight: 500,
                    color: '#5b6b73',
                    lineHeight: 20
                },
                subtext: sum,
                subtextStyle: {
                    fontSize: 26,
                    fontFamily: 'PingFang-SC-Bold',
                    fontWeight: 'bold',
                    color: '#5b6b73',
                    lineHeight: 37,
                },
                right: 30,
                top: 40
            },
            legend: {
                orient: 'vertical',
                x: 'right',
                y: 100,
                icon: "circle",
                align: "left",
                formatter: '{name}',
                textStyle: { color: '#5b6b73',  }
            },
            series: {
                type: 'pie',
                center: ['25%', '50%'],
                radius: ['80%', '50%'],
                clockwise: false,
                hoverAnimation: false,
                stillShowZeroSum: false,
                label: {
                    normal: {
                        show: false,
                    }
                },
                itemStyle:{
                    normal: {
                       borderWidth: 2, 
                       borderColor: '#0a1255',
                     }
               },
                data: [
                    {
                        value: lightrcptclPCT,
                        name: "照明插座：" + lightrcptclPCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#F8E61A'
                            }
                        }
                    },
                    {
                        value: airelePCT,
                        name: "空调用电：" + airelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#FFAD33'
                            }
                        }
                    },
                    {
                        value: powerelePCT,
                        name: "动力用电：" + powerelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#87E6FF'
                            }
                        }
                    },
                    {
                        value: specialelePCT,
                        name: "特殊用电：" + specialelePCT + " %",
                        itemStyle: {
                            normal: {
                                color: '#86CCFF'
                            }
                        }
                    }
                ]
            }
        }

        return (
            <div className='indexBottom3'>
                <div className='indexBottomHead'>
                    <span>分项能耗</span>
                    
                </div>
                <ReactEcharts option={getOption} notMerge={true} lazyUpdate={true} style={{ width: "100%", height: "240px" }} />
            </div>
        )
    }
}


export default IndexBottom3;
