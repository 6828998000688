import React, { useContext } from 'react';
import './waterBalance.scss';
import Echarts from 'src/components/echarts/echarts';
import { Tree, Row, Col } from 'antd';
import * as r from 'ramda';
import WaterMeter from '../networkDiagram/waterMeter/waterMeter';

import Img_waterBox from 'src/img/waterBox.png';

import Api from 'api';

import { context, loadNodeUsage } from './waterBalanceData'
import { of } from 'rxjs'
import './waterBalance.scss';
const { TreeNode } = Tree;
const WaterBalance = () => {
    const { state: {
        levelOne,
        levelOneTime,
        levelOneTimeType,
        levelTow,
        levelTowTime,
        levelTowTimeType,
        loading,
        waterS,
        waterSTime,
        waterSTimeType, extraData }, dispatch } = useContext(context)

    const translateTimeType = v => {
        const valueMap = {
            daily: '日',
            monthly: '月',
            annually: '年',
        }
        return r.propOr(v)(v)(valueMap)
    }
    const echartsData = {
        tit: '市政供水',
        ulShow: ['day', 'month', 'years'],
        data: {
            color: ["#006cfa", "#3ac3f7"],
            grid: { left: '5', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLine: {
                    show: true,
                    lineStyle: { color: '#5b6b73' }
                },
                data: waterSTime
            },
            yAxis: {
                axisLine: {
                    show: false,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {
                    show: false
                },

            },
            series: [{
                data: waterS,
                type: 'bar',
                name: '市政供水',
                itemStyle: { barBorderRadius: 5 }, barWidth: 10,
            }]
        },
        selection: waterSTimeType,
        handleScaleChange: (usagePeriod, usageDate) => {
            dispatch(state => of({ ...state, loading: true }))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                waterSTimeType: usagePeriod,
                usagePeriod
            }))
        }
    }
    const echartsData2 = {
        tit: '一级管网',
        ulShow: ['day', 'month', 'years'],
        data: {
            color: ["#006cfa", "#3ac3f7"],
            grid: { left: '5', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLine: {
                    show: true,
                    lineStyle: { color: '#5b6b73' }
                },
                data: levelOneTime
            },
            yAxis: {
                axisLine: {
                    show: false,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {
                    show: false
                },

            },
            series: [{
                data: levelOne,
                type: 'bar',
                name: '一级供水',
                itemStyle: { barBorderRadius: 5 }, barWidth: 10,
            }]
        },
        selection: levelOneTimeType,
        handleScaleChange: (usagePeriod, usageDate) => {
            dispatch(state => of({ ...state, loading: true }))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                levelOneTimeType: usagePeriod,
            }))
        }
    }
    const echartsData3 = {
        tit: '二级管网',
        ulShow: ['day', 'month', 'years'],
        data: {
            color: ["#006cfa", "#3ac3f7"],
            grid: { left: '5', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLine: {
                    show: true,
                    lineStyle: { color: '#5b6b73' }
                },
                data: levelTowTime
            },
            yAxis: {
                axisLine: {
                    show: false,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {
                    show: false
                },

            },
            series: [{
                data: levelTow,
                type: 'bar',
                name: '二级供水',
                itemStyle: { barBorderRadius: 5 }, barWidth: 10,
            }]
        },
        selection: levelTowTimeType,
        handleScaleChange: (usagePeriod, usageDate) => {
            dispatch(state => of({ ...state, loading: true }))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                levelTowTimeType: usagePeriod,
            }))
        }
    }


    return (
        <div className="waterBalance">
            {/* <div className="waterBalanceLeft">
                    <Tree
                        showLine={true}
                        onSelect={this.onSelect}
                        onCheck={this.onCheck}
                        defaultExpandedKeys={['0']}
                    >
                        <TreeNode title={Api.projectName} key="0">
                            {this.state.treeData.length ? this.froObj(this.state.treeData) : 'loading tree'}}
                        </TreeNode>
                    </Tree>
                </div> */}
            <div className="waterBalanceRight">
                <ul gutter={42} className='page2AdditionalData'>
                    {
                        extraData.map(({ addrid,
                            channels,
                            title, isExtra, name }, i) =>
                            <li key={i} >
                                <WaterMeter
                                    value={channels ? channels["04"].realdata : 0}
                                    status={channels ? channels['04'].status : 0}
                                    flowId={channels && channels['04'].id}
                                    temperatureId={channels && channels['05'].id}
                                    title={title || name}
                                    id={addrid}
                                    key={i}
                                    isExtra={isExtra}
                                    position={{ position: 'relative' }}
                                />
                            </li>
                        )
                    }
                </ul>
                <Row gutter={20}>
                    <Col span={8}>
                        <div className='gutter-box'>
                            <img src={Img_waterBox} alt="" />

                            <div className='chartsContainerInWaterBalance'>
                                <ul>
                                    <li>本{translateTimeType(waterSTimeType)}用水:{r.sum(waterS).toFixed(2)}</li>
                                </ul>
                                <Echarts props={echartsData} />
                            </div>

                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='gutter-box'>
                            <img src={Img_waterBox} alt="" />

                            <div className="chartsContainerInWaterBalance">
                                <ul>
                                    <li>本{translateTimeType(levelOneTimeType)}用水:{r.sum(levelOne).toFixed(2)}</li>
                                </ul>
                                <Echarts props={echartsData2} />
                            </div>
                        </div>
                    </Col>
                    <Col span={8}>
                        <div className='gutter-box'>
                            <img src={Img_waterBox} alt="" />
                            <div className="chartsContainerInWaterBalance">
                                <ul>
                                    <li>本{translateTimeType(levelTowTimeType)}用水:{r.sum(levelTow).toFixed(2)}</li>
                                </ul>
                                <Echarts props={echartsData3} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

        </div>
    )
}



export default WaterBalance;