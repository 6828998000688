import React from 'react';

import './indexBottom.scss';

import IndexBottom1 from './indexBottom1/indexBottom1';
import IndexBottom2 from './indexBottom2/indexBottom2';
import IndexBottom3 from './indexBottom3/indexBottom3';
import IndexBottom4 from './indexBottom4/indexBottom4';
import Api from 'api';


class IndexBottom extends React.Component {

    state = {
        ac: 0,
        electricity: 0,
        gas: 0,
        total: 0,
        water: 0
    }

    async getData() {
        // this.state = {getOption: {series: []}, ...this.state};
        console.log('energyRegionNew ------ 1');
        const { data: { ac, electricity, gas, total, water } } = await Api.postV3('/energyRegionNew/usageRatioDaily', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });

        this.setState({
            ac: ac,
            electricity: electricity,
            gas: gas,
            total: total,
            water: water
        })
    }


    componentDidMount() {
        this.getData()
    }

    render() {
        const { ac, electricity, gas, total, water } = this.state;
        return (
            <div className='indexBottom'>
                <IndexBottom1 ac={ac} electricity={electricity} gas={gas} total={total} water={water} />
                <IndexBottom2 electricity={electricity} total={total} water={water} />
                <IndexBottom3 />
                <IndexBottom4 />
            </div>
        )
    }
}


export default IndexBottom;