import React from 'react';
import './border4.scss'
const Border4 = (props) => {
    // console.log(props)
    const { children, sideLength = '21px' } = props;
    const styleShee = {
        width: sideLength, 
        height: sideLength
    }
    return (
        <div className='border4Container'>
            <div className="border4 border4LeftTop" style={styleShee}></div>
            <div className="border4 border4LeftBottom" style={styleShee}></div>
            {children}
            <div className="border4 border4RightTop" style={styleShee}></div>
            <div className="border4 border4RightBottom" style={styleShee}></div>
        </div>
    )
}

export default Border4