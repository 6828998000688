import React from 'react';

import './indexMidLeft.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';

import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';

class IndexMidLeft extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            xAxisData: [],
            waterData: [],
            electricityData: [],
            notMerge: true,
            lazyUpdate: true,
            style: { width: "100%", height: "260px" },
            active: "line"
        };
    }

    async getData(active) {
        this.state = { getOption: { series: [] }, ...this.state }
        const data = new Date();
        const hh = data.getHours();
        const water = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: 'WATER'

        });

        const electricity = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
            type: 'ELECTRICITYMETER'
        });

        let waterTime = [];
        let electricityTime = [];
        let waterData = [];
        let electricityData = [];
        // if (water.data.data) {
        //     for (let i = 0; i < hh; i++) {
        //         waterTime.push(`0${i}`.slice(-2) + ':00')
        //         waterData.push(Number(water.data.data['hour' + i]).toFixed(2) || '0.00')
        //     }
        // }
        //
        // if (electricity.data.data) {
        //     for (let i = 0; i < hh; i++) {
        //         electricityTime.push(`0${i}`.slice(-2) + ':00')
        //         electricityData.push(Number(electricity.data.data['hour' + i]).toFixed(2) || '0.00')
        //     }
        // }

        //24h 内的数据
        let waterRes = water.data.data;
        if (waterRes&&waterRes[0]) {
            for (let key in waterRes[0]) {
                waterTime.push('昨日'+key.replace('ys_hour','')+':00')
                waterData.push(Number(waterRes[0][key]).toFixed(2) || '0.00')
            }
        }
        if (waterRes&&waterRes[1]) {
            for (let key in waterRes[1]) {
                waterTime.push(key.replace('hour','')+':00')
                waterData.push(Number(waterRes[1][key]).toFixed(2) || '0.00')
            }
        }


        let eleRes = electricity.data.data;
        if (eleRes&&eleRes[0]) {
            for (let key in eleRes[0]) {
                electricityTime.push('昨日'+key.replace('ys_hour','')+':00')
                electricityData.push(Number(eleRes[0][key]).toFixed(2) || '0.00')
            }
        }
        if (eleRes&&eleRes[1]) {
            for (let key in eleRes[1]) {
                electricityTime.push(key.replace('hour','')+':00')
                electricityData.push(Number(eleRes[1][key]).toFixed(2) || '0.00')
            }
        }



        this.setState({
            xAxisData: waterTime.length ? waterTime : electricityTime,
            waterData,
            electricityData,
            active: active
        })
    }

    componentDidMount() {
        this.getData("bar")
    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { xAxisData, active, waterData, electricityData } = this.state;


        let getOption = {
            color: ["#43dec3", "#3ac3f7"],
            grid: { left: '5', top: '20', right: '30', bottom: '22', containLabel: true },
            // toolbox: {
            //     // y: 'bottom',
            //     feature: {
            //         magicType: {
            //             type: ['stack', 'tiled']
            //         },
            //         dataView: {},
            //         saveAsImage: {
            //             pixelRatio: 2
            //         }
            //     }
            // },
            legend: {
            },
            tooltip: { trigger: 'axis' },
            xAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                data: xAxisData
            },
            yAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {       //y轴刻度线
                    show: false
                },
            },
            series: [
                {
                    name: '水',
                    type: active,
                    data: waterData,
                    smooth: true,
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            distance: 10,
                            align: 'center',
                            verticalAlign: 'middle',
                        },
                    }
                    // emphasis: { label: { show: true } }
                }, {
                    type: active,
                    name: '电',
                    data: electricityData,
                    smooth: true,
                    label: {
                        normal: {
                            show: true,
                            position: 'top',
                            distance: 10,
                            align: 'center',
                            verticalAlign: 'middle',
                        },
                    }
                }]
        }


        return (
            <div className='indexMidLeft'>
                <div className='indexMidLeftHead'>
                    <span>分类用量(kwh)</span>
                    <div>
                        <i className={`${this.state.active === "line" ? "active" : ""} hover`} onClick={this.selected.bind(this, "line")}><img src={Img_line} alt="智慧云能源" /></i>
                        <i className={`${this.state.active === "bar" ? "active" : ""} hover`} onClick={this.selected.bind(this, "bar")}><img src={Img_bar} alt="智慧云能源" /></i>
                    </div>
                </div>
                <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
            </div>
        )
    }
}


export default IndexMidLeft;
