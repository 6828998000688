import React, { useContext } from 'react';
import Echarts from 'src/components/echarts/echarts';
import { context, loadNodeUsage } from './dayAndNight'
import { of } from 'rxjs'
import './dayAndNight.scss';

const DaylightUsage = () => {
  const { state: { daylightUsage: { timeSeries, data }, usagePeriod: selection }, dispatch } = useContext(context)
  console.log('DaylightUsage', data);
  const echartsData = {
    tit: '建筑日间用能',
    ulShow: ['day', 'month', 'season', 'years'],
    data: {
      color: ['#86CCFF', '#FFAD33'],
      grid: { left: '20', top: '5', right: '10', bottom: '22', containLabel: true },
      legend: {},
      tooltip: { trigger: 'axis' },
      xAxis: {
        type: 'category',
        data: timeSeries,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data,
          type: 'bar',
          itemStyle: { color: '#5d9ede' },
          label: {
            normal: {
              show: true,
              position: 'top',
              distance: 10,
              align: 'center',
              verticalAlign: 'middle',
            },
          }
        }],
    },
    handleScaleChange: (usagePeriod, usageDate) => {
      console.log(usagePeriod, usageDate)
      dispatch(state => of({ ...state, loading: true }))
      return dispatch(state => loadNodeUsage({
        ...state, loading: true,
        usagePeriod,
        usageDate,
      }))
    },
    selection,
  }
  return (
    <div className="dayAndNightRightTop">
      <Echarts props={echartsData} />
    </div>
  )
}

export default DaylightUsage;