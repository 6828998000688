import React, {useContext} from 'react';
import {BrowserRouter as Router, NavLink, Redirect} from "react-router-dom";

import './energy.scss'
import Analysis from './analysis/analysis';
import Zenergy from './energy/energy'
import DayAndNight from './dayAndNight/dayAndNight'
import Building from './building/building'
import Department from './department/department'
import * as r from "ramda";
import {Spin} from "antd";
import {isRoot, PrivateRoute} from "src/context/NavItem";
import {context, firstAnalysisRoute} from "src/router";

const navItems = {
  analysis_energy: <li key='analysis_energy'>
    <NavLink activeClassName='active' to='/Energy/Analysis'><span>能耗分析</span></NavLink>
  </li>,
  analysis_subentry: <li key='analysis_subentry'>
    <NavLink activeClassName='active' to='/Energy/Zenergy'><span>分项用能</span></NavLink>
  </li>,
  analysis_daynight: <li key='analysis_daynight'>
    <NavLink activeClassName='active' to='/Energy/DayAndNight'><span>日夜间</span></NavLink>
  </li>,
  analysis_building: <li key='analysis_building'>
    <NavLink activeClassName='active' to='/Energy/Building'><span>建筑</span></NavLink>
  </li>,
  analysis_department: <li key='analysis_department'>
    <NavLink activeClassName='active' to='/Energy/Department'><span>部门</span></NavLink>
  </li>
}

const routeItems = {
  analysis_energy: <PrivateRoute key='analysis_energy' path="/Energy/Analysis" exact component={Analysis}/>,
  analysis_subentry: <PrivateRoute key='analysis_subentry' path="/Energy/Zenergy" exact component={Zenergy}/>,
  analysis_daynight: <PrivateRoute key='analysis_daynight' path="/Energy/DayAndNight" exact component={DayAndNight}/>,
  analysis_building: <PrivateRoute key='analysis_building' path="/Energy/Building" exact component={Building}/>,
  analysis_department: <PrivateRoute key='analysis_department' path="/Energy/Department" exact component={Department}/>
}

export const Energy = (props) => {
  const {state: {thisProject}} = useContext(context)
  if (r.isNil(thisProject)) {
    return <Spin />
  }
  if(r.path(['location', 'pathname'])(props) === '/Energy')
    return <Redirect to={firstAnalysisRoute(thisProject.modules)} />
  return (
    <div className="energy">
      <Router>
        <div>
          <nav>
            <ul>{
              isRoot() ? r.values(navItems) : r.values(r.omit(r.difference(r.keys(navItems), thisProject.modules))(navItems))
            }</ul>
          </nav>
          {
            isRoot() ? r.values(routeItems) : r.values(r.omit(r.difference(r.keys(routeItems), thisProject.modules))(routeItems))
          }
        </div>
      </Router>
    </div>
  )
}

export default Energy