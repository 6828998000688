import React, { useState,useContext ,useEffect} from 'react';
import BarCode from './ui/barCode/barCode'
import moment from 'moment';
import { timer } from 'rxjs';
import Api from 'api';
import { Color,ColorContext , UPDATE_COLOR } from "./color";
// let lasttime = 0;
let reqflag = true;
const RegionPresentationHead = () => {



    let [time, setTime] = useState(0);
    let [temp, setTemp] = useState(0.00);
    let [humi, setHumi] = useState(0.00);
    let [dew, setDew] = useState(0.00);
    let [atmos, setAtmos] = useState(0.00);
    let [radia, setRadia] = useState(0.00);
    let [windspeed, setWindspeed] = useState(0.00);
    let [winddir, setWinddir] = useState(0.00);
    let [date, setDate] = useState('');
    let [swView, setSwView] = useState(true);
    let [lasttime, setLasttime] = useState(0);
    let [headName, setHeadName] = useState(0);
    const source = timer(0, 1000);
    const source2 = timer(0, 1000*50);

    const getdate =  ()=>{
        console.log('getdata----------'+lasttime);
        if (Date.now() - lasttime < 1000*30*1){
                return;
        }
        // console.log('getdata----------');
        lasttime=Date.now();
        setLasttime(lasttime);
        console.log(Date.now()+'|'+lasttime);
        Api.postV3('/energy/weather', {
            project: Api.projectId
        }).then(({ data }) => {
            if(!data||!data.result){
                return;
            }
            console.log((data.result)[0].temp);
            let res = (data.result)[0];
            setTemp(res.temp);
            setHumi(res.humi);
            setDew(res.dew);
            setAtmos(res.atmos);
            setRadia(res.radia);
            setWindspeed(res.windspeed);
            setWinddir(res.winddir);
            setDate(res.date);

        })
    }

    const switchView =  ()=>{
        setSwView(!swView);
        console.log('switch view ================' + swView +'|' );
        // this.setKeyWorld(swView);

    }




    source.subscribe(() => setTime(moment().format('YYYY[年]MoDo HH:mm:ss')))
    // source2.subscribe(() =>  getdate())

    //
    const { color } = useContext(ColorContext);
    const { dispatch } = useContext(ColorContext);

    useEffect(() => {
        document.getElementById('screenContainer').style.display=(color=='1'?'block':'none' );
        document.getElementById('midLeftForWeather').style.display=(color=='1'?'none':'block' );
        document.getElementById('headName').innerHTML=(color=='1'?'智慧能源监测平台':'温州设计集团工程咨询平台气象科普站' );

    });

    return (
        <div className='ScreenVersionIndexHead'>
            <div className="ScreenVersionIndexHeadLeftBorder"></div>
            <div className="ScreenVersionIndexHeadNotHaveLeftBorder">
                <div className="ScreenVersionIndexHeadTop">
                    <div className="ScreenVersionIndexHeadTopLeft">
                        <div className='angletou'>
                            <i className="angleRound"></i>
                        </div>
                        <span id='headName' onClick={() => {
                            dispatch({ type: UPDATE_COLOR, color: { color } });
                        }}>智慧能源监测平台</span>
                        {/*<span>      </span>*/}
                        {/*    <span className='weather'>&nbsp;温度:&nbsp;{temp}</span>*/}
                        {/*    <span className='weather'>&nbsp;湿度:&nbsp;{humi}</span>*/}
                        {/*    <span className='weather'>&nbsp;露点:&nbsp;{dew}</span>*/}
                        {/*    <span className='weather'>&nbsp;大气压:&nbsp;{atmos}</span>*/}
                        {/*    <span className='weather'>&nbsp;辐射:&nbsp;{radia}</span>*/}
                        {/*    <span className='weather'>&nbsp;风速:&nbsp;{windspeed}</span>*/}
                        {/*    <span className='weather'>&nbsp;风向:&nbsp;{winddir}</span>*/}
                        {/*    <span className='weather'>&nbsp;采集时间:&nbsp;{date} </span>*/}
                        {/*    /!*<span onClick={switchView}>点击切换{ color=='1'?1:0 }</span>*!/*/}
                        {/*    <span className='weather' onClick={() => {*/}
                        {/*        dispatch({ type: UPDATE_COLOR, color: { color } });*/}
                        {/*    }}>  点击切换</span>*/}
                    </div>

                    <div className="ScreenVersionIndexHeadTopRight">
                        <div className="ScreenVersionIndexHeadTopRightTop">
                            <span className='ScreenVersionIndexHeadTopTime'>
                                {time}
                            </span>
                            <div className='ScreenVersionIndexHeadTopRightDiv'></div>
                        </div>
                        <BarCode />
                    </div>

                </div>
                <div className="ScreenVersionIndexHeadBottom">
                    <div className='ScreenVersionIndexHeadBottomLeft'>
                        <BarCode />
                        <span>
                            INTELLIGENT ENERGY MONITORING PLATFORM
              </span>
                    </div>
                    <div className="ScreenVersionIndexHeadBottomRight">
                        <i className="ScreenVersionIndexHeadBottomRightRound1"></i>
                        <i className="ScreenVersionIndexHeadBottomRightRound2"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RegionPresentationHead
