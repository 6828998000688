import React, {useContext,useState} from 'react';
import {Table, Tooltip} from 'antd';
import * as r from 'ramda';
import moment from 'moment';
import ReactEcharts from 'echarts-for-react';
import {context, loadNodeUsage} from '../noise'
import {of, from} from 'rxjs'
// import ModelEchartsOldData from './modelEchartsOldData';

import TableTop from 'src/components/table/tableTop/tableTop';

import Api from 'api';

const dirJson = {
    0:'北风',
    1:'东北风',
    2:'东风',
    3:'东南风',
    4:'南风',
    5:'西南风',
    6:'西风',
    7:'西北风',
};
const dateFormat = function (date, format) {
    date = new Date(date);
    var o = {
        'M+' : date.getMonth() + 1, //month
        'd+' : date.getDate(), //day
        'H+' : date.getHours(), //hour+8小时
        'm+' : date.getMinutes(), //minute
        's+' : date.getSeconds(), //second
        'q+' : Math.floor((date.getMonth() + 3) / 3), //quarter
        'S' : date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(format))
        format = format.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));

    for (var k in o)
        if (new RegExp('(' + k + ')').test(format))
            format = format.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));

    return format;
}

let startRGB ;//转换为rgb数组模式
let startR ;
let startG ;
let startB;

let endRGB;
let endR ;
let endG ;
let endB ;

let sR ;//总差值
let sG ;
let sB ;

// 将hex表示方式转换为rgb表示方式(这里返回rgb数组模式)
function colorToRgb(sColor){
    var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    var sColor = sColor.toLowerCase();
    if(sColor && reg.test(sColor)){
        if(sColor.length === 4){
            var sColorNew = "#";
            for(var i=1; i<4; i+=1){
                sColorNew += sColor.slice(i,i+1).concat(sColor.slice(i,i+1));
            }
            sColor = sColorNew;
        }
        //处理六位的颜色值
        var sColorChange = [];
        for(var i=1; i<7; i+=2){
            sColorChange.push(parseInt("0x"+sColor.slice(i,i+2)));
        }
        return sColorChange;
    }else{
        return sColor;
    }
};

// 将rgb表示方式转换为hex表示方式
function colorToHex(rgb){
    var _this = rgb;
    var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/;
    if(/^(rgb|RGB)/.test(_this)){
        var aColor = _this.replace(/(?:\(|\)|rgb|RGB)*/g,"").split(",");
        var strHex = "#";
        for(var i=0; i<aColor.length; i++){
            var hex = Number(aColor[i]).toString(16);
            hex = hex<10 ? 0+''+hex :hex;// 保证每个rgb的值为2位
            if(hex === "0"){
                hex += hex;
            }
            strHex += hex;
        }
        if(strHex.length !== 7){
            strHex = _this;
        }

        return strHex;
    }else if(reg.test(_this)){
        var aNum = _this.replace(/#/,"").split("");
        if(aNum.length === 6){
            return _this;
        }else if(aNum.length === 3){
            var numHex = "#";
            for(var i=0; i<aNum.length; i+=1){
                numHex += (aNum[i]+aNum[i]);
            }
            return numHex;
        }
    }else{
        return _this;
    }
}

function gradientColor(startColor,endColor,step,idx){
    startRGB = colorToRgb(startColor);//转换为rgb数组模式
    startR = startRGB[0];
    startG = startRGB[1];
    startB = startRGB[2];

    endRGB = colorToRgb(endColor);
    endR = endRGB[0];
    endG = endRGB[1];
    endB = endRGB[2];

    sR = (endR-startR)/step;//总差值
    sG = (endG-startG)/step;
    sB = (endB-startB)/step;

    // 下面的是生成颜色数组
    // var colorArr = [];
    // for(var i=0;i<step;i++){
    //     //计算每一步的hex值
    //     var hex = colorToHex('rgb('+parseInt((sR*i+startR))+','+parseInt((sG*i+startG))+','+parseInt((sB*i+startB))+')');
    //     colorArr.push(hex);
    // }
    // return colorArr;

    //     这里的是生成单个颜色 ----
    return colorToHex('rgb('+parseInt((sR*idx+startR))+','+parseInt((sG*idx+startG))+','+parseInt((sB*idx+startB))+')');


}




const NoiseInterval = () => {
    const {state: {loading, tableData, from, to, deviceType, select, total,page,nums,size}, dispatch} = useContext(context);
    const [view,setView] = useState(true);
    const [style,setStyle] = useState({ display: "block",width:'100%',height:'100%' });
    const [style1,setStyle1] = useState({ display: "none",width:'100%',height:'100%'});
    //            style: { width: "80%", height: "260px"},
    //             style2: { width: "50%", height: "280px",margin: '0',float: 'left' },
    const [style3,setStyle3] = useState({ display: "block",width:'100%',height:'100%' });
    const [style4,setStyle4] = useState({ float: 'left',display: "block",width:'50%',height:'33%'});
    const [style2,setStyle2] = useState({ width:'100%',height:'100%'});
    const [visible, setVisible] = useState(false);
    const [record, setRecord] = useState({ allInformation: { title: '', channels: {} } });
    const handleCancel = (e) => {
        setVisible(false)
    }

    console.log('tableData------');
    // console.log(tableData);
    //解析出来对应的数据
    // 风向为对应的数量。
    var timeArr = [],tempArr = [],humiArr = [],pm2d5Arr = [],pm10Arr = [],winddirArr = [],winddirArr2 = {
        0:0,//'北风',
        1:0,//'东北风',
        2:0,//'东风',
        3:0,//'东南风',
        4:0,//'南风',
        5:0,//'西南风',
        6:0,//'西风',
        7:0,//'西北风',
    },noiseArr = [];
    r.map(y=>{
        timeArr.push(dateFormat(y.addtime,'MM-dd HH:mm'));
        tempArr.push(y.temp);
        humiArr.push(y.humi);
        pm2d5Arr.push(y.pm2d5);
        pm10Arr.push(y.pm10);
        noiseArr.push(y.noise);
        winddirArr.push(dirJson[y.winddir]);
        winddirArr2[y.winddir]++;
    })(tableData);
    // console.log(timeArr);
    // console.log(humiArr);
    // console.log('winddirArr2');
    // console.log(winddirArr2);


    //生成color的方法
    /*
// startColor：开始颜色hex
// endColor：结束颜色hex
// step:几个阶级（几步）
*/


    // const channelNameFilters = r.uniq(r.map((v) => (v.channelName))(tableData));
    const columns = [
        {
        title: '建筑名称',
        dataIndex: 'building',
        key: 'building',
        width: '6%',
    },{
        title: '设备号',
        dataIndex: 'sn',
        key: 'sn',
        width: '6%',
    }, {
        title: 'pm2.5',
        dataIndex: 'pm2d5',
        key: 'pm2d5',
        width: '6%',
    }, {
        title: 'pm10',
        dataIndex: 'pm10',
        key: 'pm10',
        width: '6%',
    },
    {
        title: '温度',
        dataIndex: 'temp',
        key: 'temp',
        width: '6%',

    },
    {
        title: '湿度',
        dataIndex: 'humi',
        key: 'humi',
        width: '4%',
    },
    {
        title: '项目id',
        dataIndex: 'projectId',
        key: 'projectId',
        width: '4%',
    },
    {
        title: '噪声',
        dataIndex: 'noise',
        key: 'noise',
        width: '4%',
    },
    {
        title: '风强度',
        dataIndex: 'windpower',
        key: 'windpower',
        width: '4%',
    },
    {
        title: '风速',
        dataIndex: 'windspeed',
        key: 'windspeed',
        width: '4%',
    },
    {
        title: '风向',
        dataIndex: 'winddir',
        key: 'winddir',
        width: '4%',
        render: (text, record) => {
              return  <span className='tableName'>{dirJson[text]}</span>;
        }
    }
    ];


    const tableTop = {
        result: tableData.length,
        // liData: ['区间', '选择', '搜索',],
        liData: ['区间' ],
        from,
        to,
        select,
        deviceType,
        columns,
        tableData,
        handleScaleChange: ({deviceType, from, to}) => {
            dispatch(state => of({...state, loading: true}))
            return dispatch(state => loadNodeUsage({
                ...state, loading: true,
                deviceType,
                from,
                to
            }))
        },
    };

    const onChange=(page) => {
        console.log('page');
        console.log(page);
        dispatch(state => of({...state,page:page, loading: true}))
        return dispatch(state => loadNodeUsage({
            ...state, loading: true,
            page:page
        }))
    }
    const changeView=() => {
        setView(!view);
        console.log('view-----');
        console.log(view);
    }
    //将数据按照支路进行分类


    // const option1 = {
    //     color: ["#87E7FF", "#D3D7E3"],
    //     grid: { left: '3', top: '25', right: '30', bottom: '22' },
    //     tooltip: { trigger: 'axis',
    //     },
    //     legend: {
    //     },
    //     xAxis: {
    //         axisLine: {       //y轴
    //             show: true,
    //             lineStyle: { color: '#ccc' }
    //         },
    //         // data: r.isEmpty(this.state.chartsTime2) ? this.state.chartsTime1 : this.state.chartsTime2
    //         data: [...Array(tableData.length).keys()]
    //     },
    //     yAxis: {
    //         axisLine: {       //y轴
    //             show: true,
    //             lineStyle: { color: '#ccc' }
    //         },
    //         axisTick: {       //y轴刻度线
    //             show: false
    //         },
    //     },
    // series:{
    //     type: 'line',
    //         smooth: true,
    //     data: r.map(y=>y.Qty)(tableData),
    //     tooltip: {
    //     trigger: 'axis',
    //         formatter:function (params, ticket, callback) {
    //         return '今年平均线'+params.data
    //     }
    // },
    //     itemStyle: {
    //         normal: {
    //             // 定制显示（按顺序）
    //             color: '#84e0fb'
    //         }
    //     }
    // }
    // }


    let getOption = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },
        legend: {
            textStyle: {
                color: '#FEFEFF'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
            axisLabel: {
                color: '#FEFEFF'
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: timeArr,
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#FEFEFF' }
        },
        series: [{
            type: 'line',
            name: 'PM2.5',
            // name: color,
            data: pm2d5Arr,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#78bbfd'
                }
            },
        }]
    }

    let getOption2 = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },
        legend: {
            textStyle: {
                color: '#FEFEFF'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
            axisLabel: {
                color: '#FEFEFF'
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: timeArr
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#FEFEFF' }
        },
        series: [{
            type: 'line',
            name: 'PM10',
            data: pm10Arr,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#d3fd8b'
                }
            },
        }]
    }

    let getOption3 = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },
        legend: {
            textStyle: {
                color: '#FEFEFF'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
            axisLabel: {
                color: '#FEFEFF'
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: timeArr
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#FEFEFF' }
        },
        series: [{
            type: 'line',
            name: '温度',
            data: tempArr,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#FD7F33'
                }
            },
        }]
    }

    let getOption4 = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },

        legend: {
            textStyle: {
                color: '#FEFEFF'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
            axisLabel: {
                color: '#FEFEFF'
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: timeArr
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#FEFEFF' }
        },
        series: [{
            type: 'line',
            name: '湿度',
            data: humiArr,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#fd8993'
                }
            },
        }]
    }

    let getOption5 = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },

        legend: {
            textStyle: {
                color: '#FEFEFF'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
            axisLabel: {
                color: '#FEFEFF'
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: timeArr
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#FEFEFF' }
        },
        series: [{
            type: 'line',
            name: '噪声',
            data: noiseArr,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#a6f16c'
                }
            },
        }]
    }

    let getOption6 = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },
        legend: {
            textStyle: {
                color: '#FEFEFF'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
            axisLabel: {
                color: '#FEFEFF'
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: timeArr
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#FEFEFF' }
        },
        series: [{
            type: 'line',
            name: '风向',
            data: winddirArr,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#56fddf'
                }
            },
        }]
    }

    var optionWindDir = {
        backgroundColor: '#2c343c00',

        title: {
            text: '风向分布',
            left: 'center',
            top: 20,
            textStyle: {
                color: '#ccc'
            }
        },

        tooltip: {
            trigger: 'item',
            // formatter: '{a} <br/>{b} : {c} ({d}%)'
            formatter: function (params) {
                return winddirArr2[params.dataIndex];

            }
        },

        visualMap: {
            show: false,
            min: 80,
            max: 600,
            inRange: {
                colorLightness: [0, 1]
            }
        },
        series: [
            {
                name: '访问来源',
                type: 'pie',
                radius: '55%',
                center: ['50%', '50%'],
                data: [
                    {value: 200, name: '北'},
                    {value: 200, name: '东北'},
                    {value: 200, name: '东'},
                    {value: 200, name: '东南'},
                    {value: 200, name: '南'},
                    {value: 200, name: '西南'},
                    {value: 200, name: '西'},
                    {value: 200, name: '西北'},
                    // {value: 400, name: '搜索引擎'}
                ].sort(function (a, b) { return a.value - b.value; }),
                roseType: 'radius',
                label: {
                    color: 'rgba(255, 255, 255, 0.3)'
                },
                labelLine: {
                    lineStyle: {
                        color: 'rgba(255, 255, 255, 0.3)'
                    },
                    smooth: 0.2,
                    length: 10,
                    length2: 20
                },
                itemStyle: {
                    // color: '#c23531',
                    normal: {
                        // 定制显示（按顺序）
                        color: function (params) {
                            console.log('color-----');
                            var data = params.data;
                            var sid = params.dataIndex;
                            // return '#21d911';
                            return gradientColor('#e8b83f','#e88b6c',50,parseInt(50*winddirArr2[sid])/300);

                        }
                    },
                    shadowBlur: 200,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                },

                animationType: 'scale',
                animationEasing: 'elasticOut',
                animationDelay: function (idx) {
                    return Math.random() * 200;
                }
            }
        ]
    };

    let getOption7 = {
        grid: { left: '55', top: '20', right: '30', bottom: '22' },
        legend: {
            textStyle: {
                color: '#FEFEFF'
            },
            icon: 'circle'
        },
        tooltip: { trigger: 'axis' },
        xAxis: {
            axisLabel: {
                color: '#FEFEFF'
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            data: timeArr
        },
        yAxis: {
            axisTick: {
                show: false,
            },
            splitLine: {
                show: true,
                lineStyle: { color: 'rgba(0, 0, 0, 0.5)' }
            },
            axisLabel: { color: '#FEFEFF' }
        },
        series: [{
            type: 'line',
            name: '湿度',
            data: timeArr,
            smooth: true,
            itemStyle: {
                normal: {
                    color: '#56fddf'
                }
            },
        }]
    }
    

    return (
        <div className='reportRightTable'>
            <div className='divEcharts' style={style4}>
                <ReactEcharts option={getOption}  style={style3} />
            </div>
            <div className='divEcharts' style={style4}>
                <ReactEcharts option={getOption2}  style={style3} />
            </div>
            <div className='divEcharts' style={style4}>
                <ReactEcharts option={getOption3}  style={style3} />
            </div>

            <div className='divEcharts' style={style4}>
                <ReactEcharts option={getOption4}  style={style3} />
            </div>
            <div className='divEcharts' style={style4}>
                <ReactEcharts option={getOption5}  style={style3} />
            </div>
            <div className='divEcharts' style={style4}>
                {/*<ReactEcharts option={option6}  style={style3} />*/}
                <ReactEcharts option={optionWindDir}  style={style3} />
            </div>
            {/*<div className='divEcharts' style={style4}>*/}
                {/*<ReactEcharts option={getOption7}  style={style3} />*/}
            {/*</div>*/}


        </div>
    )
}


export default NoiseInterval;
