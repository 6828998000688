import React from 'react';

import './indexMidAverage.scss';

import IndexMidRightAverage from './indexMidRight/indexMidRightAverage';
import IntervalReport from './intervalReport/intervalReport';
import withStates from 'src/context/withStates';
import * as r from "ramda";
import {from} from "rxjs";
import Api from 'api';
import moment from 'moment'


class IndexMidAverage extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            view:0,
            style: { display: "block" },
            style1: { display: "none"},
            // tableData:[{
            //     average: "019010400616",
            //     name: "2F???-150/5",
            //     order: "01",
            //     type: "1",
            //     area: "344.14",
            //     headCount: "14.92"}],
            tableData:{}
        }
    }
    selected() {
        console.log('begin to change');
        this.setState({
            view:!this.state.view
        })
    }
    setData(tableData) {
        console.log('tableData');
        console.log(tableData);
        this.setState({
            tableData:tableData
        })
    }

    render() {
        return (
            <div className='indexMidAverage'>
                <a onClick={this.selected.bind(this)} style={{fontSize:'30px'}}>切换浏览数据</a>
                <div style={this.state.view?this.state.style:this.state.style1}>
                    {/*<IndexMidRightAverage  setData = {this.setData.bind(this)} />*/}
                    <IndexMidRightAverage   />
                </div>
                <div style={this.state.view?this.state.style1:this.state.style}>
                    {/*<IntervalReport   tableData = {this.state.tableData} />*/}
                    <IntervalReport/>
                </div>


            </div>
        )
    }
}

// const nodeUsage = async ({node, from, to, deviceType, search}) => {
//     const {data: dayReport} = await Api.get(`/api/v3/energyNew/weatherDate?project=${Api.projectId}&from=${from}&to=${to}&dataType=${deviceType}`)
//
//     const isFn = n => {
//         const {sensor, title} = n;
//         const re = new RegExp(search)
//         return r.test(re)(sensor) || r.test(re)(title)
//     }
//     // console.log(dayReport)
//     //增加一个累计平均值
//     let avgData = [],dataX=[],dataY=[];//非风向外的数据
//     let sumAvg=0,i=0;
//     let winddirdata = [];
//     let windDirArr = [];// 风向数据
//     let cnt = 0;//风向是实际天数
//     if(deviceType == 'winddir'){
//         // 风向频率值要用小时数，所以要把实际这么多天的频率数 /（天数*24h）
//         cnt = dayReport[1][0].cnt;
//         let arr2 = r.pipe(r.sort(diff),r.groupBy(r.prop('lev')))(dayReport[0]);
//
//         for( let key in arr2){
//             let unit = arr2[key];
//             for(let i = 0,j=0;i< dirs.length; i++){
//                 if(key==1){
//                     if(dirs[i] == unit[j].wn){
//                         winddirdata.push([key-1,key-0,unit[j].a_w,Math.floor(unit[j].n/cnt)]);
//                         // winddirdata.push([key-1,key-0,unit[j].n-0,Math.floor(unit[j].n/cnt)]);
//                         (j<(unit.length-1))?j++:(j=j);
//
//                     }   else{
//                         winddirdata.push([0,0,0,0]);
//                     }
//                 }else{
//                     if(dirs[i] == unit[j].wn){
//                         winddirdata[i].push(key-1,key-0,unit[j].a_w,Math.floor(unit[j].n/cnt));
//                         // winddirdata[i].push(key-1,key-0,unit[j].n-0,Math.floor(unit[j].n/cnt));
//                         (j<(unit.length-1))?j++:(j=j);
//                     }   else{
//                         winddirdata[i].push(0,0,0,0);
//                     }
//                 }
//
//             }
//
//
//         }
//         // 添加总数的内容
//
//         let arr3 = r.pipe(r.sort(diff),r.groupBy(r.prop('wn')))(dayReport[0]);
//         let arrfill = Array(16-winddirdata[0].length).fill(0);//如果结果没有达到4组，剩下自动补全
//
//         // console.log(arr3);
//         let j = 0;
//         for(let key in arr3){
//             winddirdata[j]=winddirdata[j].concat(arrfill);
//             winddirdata[j].push(3,4,0,Math.floor((r.compose(r.sum, r.pluck('n'))(arr3[key]))/cnt));
//             // console.log(r.compose(r.sum, r.pluck('n'))(arr3[key]));
//             j++;
//         }
//
//
//     }else{
//         r.map(y=>{i++;sumAvg+=y[transDataTypeForAvg(deviceType)];
//             avgData.push((sumAvg/i).toFixed(2));
//             dataX.push(dateFormat(y.caltime,'yyyy-MM-dd HH:mm:ss'));
//         })(dayReport);
//         dataY = (r.pluck(transDataTypeForAvg(deviceType))(dayReport))
//     }
//
//
//
//
//
//     const tableData = dayReport;
//
//     // console.log('tableData')
//     // console.log(tableData)
//
//
//     return {
//         loading: false,
//         node,
//         select: selectType,
//         tableData,
//         winddirdata,
//         avgData,
//         dataX,
//         dataY,
//         from,
//         to,
//         cnt,
//         deviceType,
//         nameType:transDataTypeForAvg2(deviceType),
//         // total: {
//         //     usage: totalUsage.toFixed(2),
//         //     cost: totalCost.toFixed(2),
//         // }
//         // total: {
//         //     start: dayReport[0]?moment(dayReport[0]._min).format('YYYYMMDD'):'',
//         //     end: dayReport[0]?moment(dayReport[0]._max).format('YYYYMMDD'):'',
//         // }
//     }
// }

export const nodeUsage= async (...state) =>{
    // let _this = this;
    // await this.getInfo();
    // const year = await Api.postV3('/cost/monthlyComparison', {
    //     project: Api.projectId,
    // });
    console.log('Api' + Api.projectId);
    const lastYear = moment().add(-1,'y').format('YYYY');
    const thisYear = moment().format('YYYY');
    const lastYearArr = {
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    }
    // 这里请求去年的数据，暂时先不用
    // for(let key in lastYearArr){
    //     let res = await Api.postV3('/energyNew/usageAverageMonthly', {
    //         project: Api.projectId,
    //         sector: key,
    //         from: lastYear+'02',
    //         to: lastYear+'12',
    //     }).then(r.prop('data'));
    //     lastYearArr[key]=res;
    // }

    const thisYearArrOrigin = {
        SPORT: '',
        CBD: '',
        HOSPITAL: '',
        HOTEL: '',
        MARKET: '',
        OFFICE: '',
        TEACH: '',
        OTHER: '',
    }
    const thisYearArrCustomer = {[state[0].deviceType]:''};

    const thisYearArr = (state[0].deviceType==='all')?thisYearArrOrigin:thisYearArrCustomer;
    for(let key in thisYearArr){
        let res = await Api.postV3('/energyNew/usageAverageMonthly', {
            project: Api.projectId,
            deviceType: state[0].deviceType,
            sector: key,
            from: thisYear+'02',
            to: thisYear+'12',
        }).then(r.prop('data'));
        thisYearArr[key]=res;
    }


    // const lastYearAverage = await Api.postV3('/energyNew/usageAverageMonthly', {
    //     project: Api.projectId,
    //     sector: _this.state.sectorOrigin,
    //     from: lastYear+'02',
    //     to: lastYear+'12',
    // }).then(r.prop('data'));
    // const thisYearAverage = await Api.postV3('/energyNew/usageAverageMonthly', {
    //     project: Api.projectId,
    //     sector: _this.state.sectorOrigin,
    //     from: thisYear+'02',
    //     to: thisYear+'12',
    // }).then(r.prop('data'));
    // console.log(lastYearAverage);
    // let results = [lastYearAverage,thisYearAverage];
    const myAdd = function (a, b ) {
        return (b.max -b.min)*b.comi + a;
    };

    // 选择这个项目下的所有的建筑,暂时汇总到这个项目下
    // let prjDealRes = [];
    // let totalDealRes = [];
    // let standLineDealRes = [];
    // let colorDealRes = [];
    let chartsDealArr= {//保存所有建筑的数据
            SPORT: [],
            CBD: [],
            HOSPITAL: [],
            HOTEL: [],
            MARKET: [],
            OFFICE: [],
            TEACH: [],
            OTHER: [],
        },
        // chartsDealArr2=[],
        standLineDealArr= {//保存所有建筑的数据
            SPORT: [],
            CBD: [],
            HOSPITAL: [],
            HOTEL: [],
            MARKET: [],
            OFFICE: [],
            TEACH: [],
            OTHER: [],
        }, // the line for the normal thing
        // standLineDealArr2= [], // the line for the normal thing
        colorDealResArr= {//保存所有建筑的数据
            SPORT: [],
            CBD: [],
            HOSPITAL: [],
            HOTEL: [],
            MARKET: [],
            OFFICE: [],
            TEACH: [],
            OTHER: [],
        },
        // colorDealResArr2= [],
        totalDealResArr= {//保存所有建筑的数据
            SPORT: [],
            CBD: [],
            HOSPITAL: [],
            HOTEL: [],
            MARKET: [],
            OFFICE: [],
            TEACH: [],
            OTHER: [],
        },
        // totalDealResArr2= [],
        totalAreaArr= {//保存所有建筑的数据
            SPORT: [],
            CBD: [],
            HOSPITAL: [],
            HOTEL: [],
            MARKET: [],
            OFFICE: [],
            TEACH: [],
            OTHER: [],
        },
        // totalAreaArr2= [],
        totalNameArr= {//保存所有建筑的数据
            SPORT: [],
            CBD: [],
            HOSPITAL: [],
            HOTEL: [],
            MARKET: [],
            OFFICE: [],
            TEACH: [],
            OTHER: [],
        },
        // totalAreaArr2= [],
        headCountArr= {//保存所有建筑的数据
            SPORT: [],
            CBD: [],
            HOSPITAL: [],
            HOTEL: [],
            MARKET: [],
            OFFICE: [],
            TEACH: [],
            OTHER: [],
        };
    // headCountArr2= [];

    for(let key in thisYearArr){
        // let results = [thisYearArr[key],lastYearArr[key]]; // 后续如果需要历史和同期的，这里可以进行对比，如果不需要的，可以再看。
        let results = [thisYearArr[key]];// 这里是只有今年一年的
        // 处理结果
        let dealRes = [];
        let _key = key;
        r.map(x => {// x 是按照月份排列下来的数据，01：[{},{},{},{},{}]  02：
            console.log('一年的数据：');
            console.log(x);
            let i = 0;
            let result = {sum:0,acreage: 0,//存储今年或者去年的总数据，
                headCount: 0,};
            r.map(y=>{// y 是今年，或者去年的数据数组里的一个具体项，是这个月的单个工程的数据
                ++i;
                // console.log(i+'月');
                let sumMonth = 0;
                let z = r.groupBy(r.prop('bid'))(y);// z 是按照建筑排序的数据
                for(let key in z){  //
                    if(result[key]){

                    }else{
                        console.log(z[key][0].project==Api.projectId);
                        console.log(z[key][0].project);
                        console.log(Api.projectId);
                        result[key]={sum:0,project:(z[key]&&z[key].length>0&&z[key][0].project)?z[key][0].project:'',
                            headCount:(z[key]&&z[key].length>0&&z[key][0].headCount)?(z[key][0].headCount):0,
                            acreage:(z[key]&&z[key].length>0&&z[key][0].acreage)?(z[key][0].acreage):0};
                        result.acreage+=result[key].acreage;
                        result.headCount+=result[key].headCount;
                        totalNameArr[_key].push(z[key][0].title);
                    }
                    let sum = r.reduce(myAdd,0)(z[key]);
                    result[key][i]=sum.toFixed(4);
                    result[key].sum+=parseFloat(sum.toFixed(4));
                    result.sum+=sum;
                    sumMonth+=sum;
                }
                result[i]=sumMonth.toFixed(4);
            })(x);
            result.sum=result.sum.toFixed(4);
            dealRes.push(result);
            // if(i){
            //     totalAreaArr2.push(result.acreage);
            //     headCountArr2.push(result.headCount);
            // }else{
            //     totalAreaArr.push(result.acreage);
            //     headCountArr.push(result.headCount);
            // }
        })(results);
        totalAreaArr[key].push(dealRes[0].acreage);
        headCountArr[key].push(dealRes[0].headCount);
        // console.log(dealRes[0]);
        let index = 0;
        r.map(result=> {
            //计算所有建筑的月份能耗，面积，人数，
            for (let key2 in result) {
                if (result[key2].project) {
                    let prjRes = new Array(12).fill(0);
                    let area = 0;
                    area += parseInt(result[key2].acreage);
                    for (let key3 in result[key2]) {
                        if (parseInt(key3) < 13) {
                            prjRes[parseInt(key3) - 1] += parseFloat(result[key2][key3]);
                        }
                    }

                    prjRes.project = result[key2].project;
                    console.log('area: ' + area);
                    area = (area == 0) ? 1 : area;
                    prjRes = r.map(y => (y / area).toFixed(2))(prjRes);

                    // 计算总的平均值
                    let areaTotal = (result.acreage == 0) ? 1 : result.acreage;;
                    let totalArr = new Array(12).fill(0);
                    console.log('areaTotal' + areaTotal);
                    for (let key in result) {
                        if (parseInt(key) < 13) {
                            totalArr[parseInt(key) - 1] = (parseFloat(result[key]) / areaTotal).toFixed(2);
                        }
                    }

                    let standLine = [];
                    let colorArr = [];
                    prjRes.forEach(function (v, index) {
                        v>totalArr[index]?standLine.push(0):(v==0?standLine.push(0):(standLine.push((totalArr[index]-v).toFixed(3))));
                        colorArr.push(v>totalArr[index]?1:0);
                    })
                    // prjDealRes.push(prjRes);
                    // totalDealRes.push(totalArr);
                    // standLineDealRes.push(standLine);
                    // colorDealRes.push(colorArr);
                    if(index){
                        // chartsDealArr2.push(prjRes);
                        // totalDealResArr2.push(totalArr);
                        // standLineDealArr2.push(standLine);
                        // colorDealResArr2.push(colorArr);
                    }else{
                        chartsDealArr[key].push(prjRes);
                        totalDealResArr[key].push(totalArr);// 这里其实有几个建筑，就增加几次，其实这里每次都是一样的，暂时放着，等后续再改
                        // totalDealResArr[key]=totalArr;
                        standLineDealArr[key].push(standLine);
                        colorDealResArr[key].push(colorArr);
                    }

                }
            }

            index++;
        })(dealRes)


        // console.log('res1,2:------');
        // console.log(prjDealRes[0]);
        // console.log(totalDealRes[0]);
        // console.log(standLineDealRes[0]);

    }
    //本来是今年，去年两年，数据都正常，如果只有今年一年，就会导致数据不太正常
    let defaultArr = new Array(12).fill(0);


    return {
        // chartsTime1: chartsTime3,
        // chartsTime2: chartsTime3,
        // charts1: prjDealRes[0],
        // charts2: prjDealRes[1],
        // standLine: standLineDealRes[0],
        // standLine2: standLineDealRes[1],
        // colorDealRes: colorDealRes,
        // totalDealRes: totalDealRes,
        chartsDealArr:chartsDealArr,// 各种类型里面的建筑物的数据
        totalDealResArr:totalDealResArr,// 各种类型总的数据
        totalNameArr:totalNameArr,// 各种名字数据
        standLineDealArr:standLineDealArr,//标注线的数据，就是标准减去现有的差值，用于柱状图显示
        colorDealResArr:colorDealResArr,//显色，双值：1,0,  用于建筑的柱状图显示，1：高于，红色 0：低于，其他颜色
        // chartsDealArr2:chartsDealArr2,
        // totalDealResArr2:totalDealResArr2,
        // standLineDealArr2:standLineDealArr2,
        // colorDealResArr2:colorDealResArr2,
        totalAreaArr:totalAreaArr,//
        // totalAreaArr2:totalAreaArr2,
        headCountArr:headCountArr,
        select:selectType,
        deviceType:state[0].deviceType,
        // headCountArr2:headCountArr2,
        active: "year"
    }
}
const selectType = [
    {name: '全部', value: 'all'},
    {name: '体育建筑', value: 'SPORT'},
    {name: '综合建筑', value: 'CBD'},
    {name: '医疗卫生建筑', value: 'HOSPITAL'},
    {name: '宾馆饭店建筑', value: 'HOTEL'},
    {name: '商场建筑', value: 'MARKET'},
    {name: '办公建筑', value: 'OFFICE'},
    {name: '文化教育建筑', value: 'TEACH'},
    {name: '其它建筑', value: 'OTHER'}
];

const loadReport = (state) => {
    const input = Promise.all(
        [/*treeViewData(state),*/ nodeUsage(state)])
        .then(r.mergeAll)
    return from(input)
}
const initState = {
    chartsTime1: ['01','02','03','04','05','06','07','08','09','10','11','12'],
    chartsTime2: ['01','02','03','04','05','06','07','08','09','10','11','12'],
    chartsTime3: ['01','02','03','04','05','06','07','08','09','10','11','12'],
    charts1: {},
    select: selectType,
    deviceType: 'all',
    charts2: {},
    // chartsDealArr: [],
    chartsDealArr2: [],
    allChartsArr: [],//保存着
    standLine: {}, // the line for the normal thing
    standLine2: {}, // the line for the normal thing
    // standLineDealArr: [], // the line for the normal thing
    standLineDealArr2: [], // the line for the normal thing
    colorDealRes: [],
    totalDealRes: [],
    // colorDealResArr: [],
    colorDealResArr2: [],
    // totalDealResArr: [],
    totalDealResArr2: [],
    // totalAreaArr: [],
    // totalAreaArr2: [],
    totalAreaArr:{//保存所有建筑的数据
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    },
    totalNameArr:{//保存所有建筑的数据
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    },
    headCountArr: [],
    headCountArr2: [],
    gov_d: 0.15,
    gov_m: 4.58,
    no_gov_d: 0.19,
    // no_gov_d: 0.003,
    no_gov_m: 5.83,
    notMerge: true,
    lazyUpdate: true,
    style: { width: "100%", height: "250px",marginTop:'30px' },
    active: "bar",
    active2: "bar",
    active3: "bar",
    active4: "bar",
    active5: "bar",
    active6: "bar",
    active7: "bar",
    active8: "bar",
    active9: "bar",
    active10: "bar",
    activeArr: ["bar","bar","bar","bar","bar","bar","bar","bar","bar","bar"],
    headCount: 1,
    totalArea: 1,
    sector: '',
    sectorOrigin: '',
    buildType: 1,//1:gov 2:not gov
    projectTypeTransfer: {
        SPORT: '体育建筑',
        CBD: '综合建筑',
        HOSPITAL: '医疗卫生建筑',
        HOTEL: '宾馆饭店建筑',
        MARKET: '商场建筑',
        OFFICE: '办公建筑',
        TEACH: '文化教育建筑',
        OTHER: '其它建筑',
    },
    chartsDealArr: {//保存所有建筑的数据
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    },
    standLineDealArr: {//保存所有建筑的数据
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    },
    colorDealResArr: {
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    },
    totalDealResArr: {//保存总的建筑的数据
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    },
    allBuildArr: {//保存所有建筑的数据
        SPORT: [],
        CBD: [],
        HOSPITAL: [],
        HOTEL: [],
        MARKET: [],
        OFFICE: [],
        TEACH: [],
        OTHER: [],
    }
}

export const {context, Component} = withStates(
    IndexMidAverage,
    initState,
    loadReport,//这里包含有左边树的数据和其他数据
)

export default Component
