import Util from "./util";

let  Util2 =
    {};
Util2.regionJsonConverter = {'330300':"温州市",
    '330301':"市辖区",
    '330302':"鹿城区",
    '330303':"龙湾区",
    '330304':"瓯海区",
    '330305':"洞头区",
    '330324':"永嘉县",
    '330326':"平阳县",
    '330327':"苍南县",
    '330328':"文成县",
    '330329':"泰顺县",
    '330371':"温州高新区(经开区)",
    '330381':"瑞安市",
    '330382':"乐清市",
    '330383':"龙港市",
    '330354':"生态园",
    '330355':"瓯江口",
};
Util2.projectTypeTransfer= {
    SPORT: "体育建筑",
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}
Util2.pvType= {
    familyUse: "家庭户用",
    ICRoof: '工商业屋顶',
    groundPV: '地面电站',
    poorPV: '扶贫电站',
    storagePV: '储能电站',
}
Util2.regionJson = {"市辖区":330301,"鹿城区":330302,"龙湾区":330303,"瓯海区":330304,"洞头区":330305,"永嘉县":330324,"平阳县":330326
    ,"苍南县":330327,"文成县":330328,"泰顺县":330329,"温州高新区(经开区)":330371,"瑞安市":330381,"乐清市":330382,"瓯江口":330355,"生态园":330354,"龙港市":330383};


Util2.regionCodeArr = ['330300',
    '330301',
    '330302',
    '330303',
    '330304',
    '330305',
    '330324',
    '330326',
    '330327',
    '330328',
    '330329',
    '330371',
    '330381',
    '330382',
    '330383',
    '330354',
    '330355',
];
Util2.CodeArr = [  'SPORT',
    'CBD',
    'HOSPITAL',
    'HOTEL',
    'MARKET',
    'OFFICE',
    'TEACH',
    'OTHER',];

Util2.NameArr = [  '体育建筑',
    '综合建筑',
    '医疗卫生建筑',
    '宾馆饭店建筑',
    '商场建筑',
    '办公建筑',
    '文化教育建筑',
    '其它建筑',];

Util2.sourceType = {
    registerPage: '注册',
    drawCheck: '审图',
    OTHER: '其他',
};
Util2.sourceCodeArr = [
    'registerPage',
    'drawCheck',
    'OTHER',
];
Util2.strucType = {
    BRICKCONCRETE: '砖混结构',
    CONCRETE: '混凝土结构',
    IRON: '钢结构',
    WOOD: '木结构',
    OTHER: '其他',
};
Util2.outWallType = {
    SOLIDCLAY: '实心黏土砖',
    HOLLOWCLAY: '空心黏土砖(多孔)',
    DINASBRICKS: '灰砂砖',
    AIRCONCRETE: '加气混凝土砌块',
    GLASS: '玻璃幕墙',
    OTHER: '其他',
};
Util2.warmType = {
    INWARM: '内保温',
    OUTWARM: '外保温',
    INSIDEWARM: '夹芯保温',
    OTHER: '其他',
};
Util2.windType = {
    SIGSIG: '单玻单层窗',
    SIGDOUB: '单玻双层窗',
    SIGSIGSIGDOUB: '单玻单层窗+单玻双层窗',
    EMPDOUB: '中空双层玻璃窗',
    EMPTHREE: '中空三层玻璃窗',
    EMPLAZY: '中空充惰性气体',
    OTHER: '其他',
};
Util2.buildGlassType = {
    NORMAL: '普通玻璃',
    DUMO: '镀膜玻璃',
    LOWE: 'Low-E玻璃',
    OTHER: '其他',
};
Util2.frameType = {
    IRON: '钢窗',
    ALUM: '铝合金窗',
    WOOD: '木窗',
    STOPHOT: '断热窗',
    OTHER: '其他',
};
Util2.heatType = {
    RAD: '散热器采暖',
    FLOOR: '地板辐射采暖',
    ELECTR: '电辐射采暖',
    OTHER: '其他',
};
Util2.airSysType = {
    RAD: '全空气系统',
    FLOOR: '风机盘管+新风系统',
    ELECTR: '分体式空调或变制冷剂流量多联式分体空调机组',
    OTHER: '其他',
};
Util2.checkType = {
    OK: '审核完成',
    ING: '审核中',
};
//
// //  自定义筛选的功能
// const getColumnSearchProps = dataIndex => ({
//     filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
//         <div style={{ padding: 8 }}>
//             <Input
//                 // ref={node => {
//                 //     this.searchInput = node;
//                 // }}
//                 placeholder={`Search ${dataIndex}`}
//                 value={selectedKeys[0]}
//                 onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
//                 onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                 style={{ width: 188, marginBottom: 8, display: 'block' }}
//             />
//             <Button
//                 type="primary"
//                 onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
//                 icon="search"
//                 size="small"
//                 style={{ width: 90, marginRight: 8 }}
//             >
//                 Search
//             </Button>
//             <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
//                 Reset
//             </Button>
//         </div>
//     ),
//     filterIcon: filtered => (
//         <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
//     ),
//     onFilter: (value, record) =>
//         (record[dataIndex]?record[dataIndex]:'')
//             .toString()
//             .toLowerCase()
//             .includes(value.toLowerCase()),
//     onFilterDropdownVisibleChange: visible => {
//         // if (visible) {
//         //     setTimeout(() => this.searchInput.select());
//         // }
//     },
//     // render: text =>
//     //     searchedColumn === dataIndex ? (
//     //         <span
//     //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
//     //             // searchWords={[this.state.searchText]}
//     //             searchWords={[searchText]}
//     //             autoEscape
//     //             textToHighlight={text.toString()}
//     //         />
//     //     ) : (//adfs
//     //         text
//     //     ),
// });


export default Util2;
