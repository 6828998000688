import React, {useContext,useState,useEffect} from 'react';

import './indexMidRightAverage.scss';
import ReactEcharts from 'echarts-for-react';
import Api from 'api';
import * as r from 'ramda';
import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';
// import {context} from "../indexMidAverage";
import {context} from "../averageContext";
import moment from 'moment'

const projectTypeConverter = {
    SPORT: '体育建筑',
    CBD: '综合建筑',
    HOSPITAL: '医疗卫生建筑',
    HOTEL: '宾馆饭店建筑',
    MARKET: '商场建筑',
    OFFICE: '办公建筑',
    TEACH: '文化教育建筑',
    OTHER: '其它建筑',
}

const projectTypeName = [
    'SPORT',
    'CBD',
    'HOSPITAL',
    'HOTEL',
    'MARKET',
    'OFFICE',
    'TEACH',
    'OTHER',
]

const transArrForTable = (arr)=>{
    let res = '';
    arr.forEach(function (v,i) {
        res +=(i+1)+'月:'+v+' ';
    });
    return res;
}


const IndexMidRightAverage =()=>{

    const {state: { active, active2,active3,active4,active5,active6,active7,active8,active9,active10, headCount, totalArea,sector,gov_d ,gov_m,no_gov_d ,no_gov_m,buildType, colorDealRes,chartsDealArr,chartsDealArr2,standLineDealArr,
        standLineDealArr2,totalDealResArr,totalDealResArr2,chartsTime2,colorDealResArr,colorDealResArr2,totalAreaArr,totalAreaArr2,headCountArr,headCountArr2,totalNameArr,activeArr,style}, dispatch} = useContext(context);
    
    const [activeArrLocal,setActiveArrLocal] = useState([]);

    const selected2 = (params,index) =>{

        console.log('change '+ params);
        if (params === "bar") {
            activeArr[index-1]="bar";
            // this.getDataYear()
        } else {
            // this.getData()
            activeArr[index-1]="line";
        }
        let arr = activeArr;
        setActiveArrLocal(activeArr.slice());
    }

     useEffect( ()=>{
        console.log('useEffect');
    },[activeArrLocal])

        console.log('totalAreaArr-------------------------');
        console.log(totalAreaArr);
        if(!totalAreaArr){
            return (<div> no data</div>)
        }
        const colorlist=['#feeecd', '#fce9b1', '#fcde9e',  '#fcd786', '#fad06c','#fcc852', '#fbbf3d',  '#fab921', '#fbb20e', '#fbb110', '#fb5b44' ];
        const colorlist2=['#a5a2fe', '#fc8793'];
        let index = (active == 'year')?((buildType == 1)?gov_m:no_gov_m):((buildType == 1)?gov_d:no_gov_d);
        console.log(index);
        const seriesArr = [],optionArr = new Array(10).fill(0);
        //下面是增加两个标准线，方便查看实际的平均线
        const lines = (key,i,name,index)=> [/*{
            type: 'line',
            smooth: true,
            data: totalDealResArr2[i],
            tooltip: {
                trigger: 'axis',
                formatter:function (params, ticket, callback) {

                    return '去年平均线'+params.data
                }
            },
            itemStyle: {
                normal: {
                    color: '#c2fb5e'
                }
            }
        },*/{
            // type: 'line',
            type: activeArr[index],
            smooth: true,
            data: totalDealResArr[key][i],
            tooltip: {
            trigger: 'axis',
                formatter:function (params, ticket, callback) {
                return '今年平均线'+params.data
            }
        },
        itemStyle: {
            normal: {
                // 定制显示（按顺序）
                color: '#84e0fb'
            }
        }
    }];

        const series =(key,i,name,index)=> [
            {
                type: activeArr[index],
                stack:i,
                // name: totalNameArr[key][i],
                // name: activeArr[index],
                smooth: true,
                data: chartsDealArr[key][i],
                tooltip: {
                    trigger: 'axis',
                    formatter:function (params, ticket, callback) {
                        return '今年'+params.data
                    }
                },
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: function (params) {
                            var data = params.data;
                            var sid = params.dataIndex;
                            return (active == 'year')?colorlist2[colorDealResArr[key][i][sid]]:colorlist2[0];
                        }
                    }
                }
            }, {
                type: activeArr[index],
                stack:i,
                // name: '距离标准',
                smooth: true,
                data: standLineDealArr[key][i],
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: '#c2fb5e',
                    }
                }
            },/*{
                type: active2,
                stack:2,
                // name: '去年',
                smooth: true,
                data: chartsDealArr2[i],
                itemStyle: {
                    normal: {
                        // 定制显示（按顺序）
                        color: function (params) {
                            // return (active == 'year')?colorlist2[colorDealRes[1][params.dataIndex]]:colorlist2[0];
                            return (active == 'year')?colorlist2[colorDealResArr2[i][params.dataIndex]]:colorlist2[0];
                        }
                    }
                },
                // data: this.state.charts1,
            }, {
                type: active2,
                stack:2,
                // name: '距离标准2',
                smooth: true,
                data: standLineDealArr2[i],
                itemStyle: {
                    normal: {
                        color: '#fbedb5',
                    }
                }
            }*/];
        const defaultOption = {
            color: ["#87E7FF", "#D3D7E3"],
            grid: { left: '3', top: '25', right: '30', bottom: '22' },
            tooltip: { trigger: 'axis',
            },
            legend: {
            },
            xAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                // data: r.isEmpty(this.state.chartsTime2) ? this.state.chartsTime1 : this.state.chartsTime2
                data: chartsTime2
            },
            yAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {       //y轴刻度线
                    show: false
                },
            },
            // series: series
        }
        const getOption = (series,key)=> {return {
            color: ["#87E7FF", "#D3D7E3"],
            grid: { left: '3', top: '25', right: '30', bottom: '22' },
            tooltip: {
                trigger: 'axis',
                formatter:function (params, ticket, callback) {
                    let name = '平均:'+params[0].data+'</br>';
                    params.forEach(function (v,i) {
                        if(i>0 && (i%2!=0)){ // 项目好在技术，并且是大于1 的数量
                            let index = Math.floor(i/2);
                            name += totalNameArr[key][index]+':'+v.data+'</br>';
                        }

                    })
                    return name
                }
            },
            legend: {
            },
            xAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                // data: r.isEmpty(this.state.chartsTime2) ? this.state.chartsTime1 : this.state.chartsTime2
                data: chartsTime2
            },
            yAxis: {
                axisLine: {       //y轴
                    show: true,
                    lineStyle: { color: '#ccc' }
                },
                axisTick: {       //y轴刻度线
                    show: false
                },
            },
            series: series
        }}

        if(active == 'year'){
            // series.push(...lines);
            let i = 0;
            // for(let k =0;k<chartsDealArr.length;k++){
            //     let a = series(i);
            //     let b = lines(i);
            //     // seriesArr.push(a.concat(b));
            //     optionArr[i]=getOption(a.concat(b));
            //     i++;
            // };
            // 把所有种类的数据组合起来
            for(let key in chartsDealArr){
                let b = lines(key,0,'',i);//总的平均线
                //  这里添加所有建筑的线条，后面如果需要可以把这里的东西先去掉
                // for(let k =0;k<chartsDealArr[key].length;k++){
                //     // seriesArr.push(a.concat(b));
                //     b.push(...series(key,k,'',i));//添加所有建筑的线条
                // };
                optionArr[i]=getOption(b,key);//保存series
                i++;
            }

            // console.log('seriesArr-----------------');
            // console.log(seriesArr);
            console.log('optionArr-----------------');
            console.log(optionArr);

        }







        return (
            <div className='indexMidRightAverage' >
                <table className='indexMidRightTable'>
                    <tbody>
                        <tr >
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.SPORT}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.SPORT?totalAreaArr.SPORT[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[0]}</span>
                                    {/*<span  className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArrLocal[0] === "line" ? "active" : ""} hover`} onClick={()=>selected2( "line",1)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArrLocal[0] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",1)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[0]?optionArr[0]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.CBD}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.CBD?totalAreaArr.CBD[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[1]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[1] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",2)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[1] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",2)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[1]?optionArr[1]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.HOSPITAL}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.HOSPITAL?totalAreaArr.HOSPITAL[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[2]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[2] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",3)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[2] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",3)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[2]?optionArr[2]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.HOTEL}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.HOTEL?totalAreaArr.HOTEL[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[3]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                        {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                        {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[3] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",4)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[3] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",4)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[3]?optionArr[3]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>

                        </tr>
                        <tr >

                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.MARKET}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.MARKET?totalAreaArr.MARKET[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[4]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[4] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",5)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[4] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",5)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[4]?optionArr[4]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />

                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.OFFICE}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.OFFICE?totalAreaArr.OFFICE[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[5]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[5] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",6)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[5] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",6)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[5]?optionArr[5]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.TEACH}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.TEACH?totalAreaArr.TEACH[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[6]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[6] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",7)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[6] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",7)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[6]?optionArr[6]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                            <td >
                                <div className='indexMidRightHeadAverage' style = {{ padding:'5px'}}>

                                    <span className='row1'>类型：{projectTypeConverter.OTHER}</span>
                                    <span className='limitValue'>面积：{totalAreaArr.OTHER?totalAreaArr.OTHER[0]:''}</span><br/>
                                    <span className='row1'>人数：{headCountArr2[7]}</span>
                                    {/*<span className='limitValue'>能耗阈值：{index}</span>*/}
                                    {/*<span style = {{ float:'right'}}>能耗阈值：{index}</span>*/}

                                    {/*<div>*/}
                                    {/*<i className={`${this.state.active === "month" ? "active" : ""} hover`} onClick={this.selected.bind(this, "month")}>月度统计</i>*/}
                                    {/*<i className={`${this.state.active === "year" ? "active" : ""} hover`} onClick={this.selected.bind(this, "year")}>年度统计</i>*/}
                                    {/*</div>*/}
                                    <br/>
                                    <div style = {{ float:'left'}}>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[7] === "line" ? "active" : ""} hover`} onClick={()=>selected2(   "line",8)}><img src={Img_line} alt="智慧云能源" /></i>
                                        <i style = {{ width:'30px',height:'30px',padding:'0px'}} className={`${activeArr[7] === "bar" ? "active" : ""} hover`} onClick={()=>selected2(   "bar",8)}><img src={Img_bar} alt="智慧云能源" /></i>
                                    </div>
                                    <ReactEcharts option={optionArr[7]?optionArr[7]:defaultOption} notMerge={true} lazyUpdate={true} style={style} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>


            </div>

        )

}

export default IndexMidRightAverage;