import React from 'react';

import './partMiddle1.scss';
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts'
import Api from 'api';
import { timer } from 'rxjs';
import Border4 from '../../ui/border4/border4';
import * as r from 'ramda';

import Img_line from 'img/line.png';
import Img_bar from 'img/bar.png';

const CARBON_FACTOR= 0.49;

class PartMiddle1 extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            xAxisData: [],
            waterData: [],
            electricityData: [],
            notMerge: true,
            lazyUpdate: true,
            style: { width: "100%", height: "230px" },
            active: "bar"
        };
    }


    async getData(active='bar') {
        this.state = { getOption: { series: [] }, ...this.state }
        const data = new Date();
        const hh = data.getHours();
        // console.log(Api.projectId);
        // console.log('------');
        // return;
        //从storage那里取数据

        console.log(' check the Api.regionProjects  1 2 ----');
        console.log(Api.regionProjects);
        console.log(Api.regionProjects2);

        const water = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: JSON.parse(window.sessionStorage.getItem('regionProjects2')),
            type: 'WATER'

        });

        const electricity = await Api.postV3('/energyNew/usageDaily24hMulti', {
            project: Api.projectId,
            region: Api.region,
            // regionProjects: Api.regionProjects,
            regionProjects: JSON.parse(window.sessionStorage.getItem('regionProjects2')),
            type: 'ELECTRICITYMETER'
        });

        let waterTime = [];
        let electricityTime = [];
        let waterData = [];
        let electricityData = [];


        //改成24h后，返回的数组有两个数据，进行重新处理

        let waterRes = water.data.data;
        // console.log('waterRes: ' + waterRes);
        if (waterRes&&waterRes[0]) {
            for (let key in waterRes[0]) {
                // waterTime.push('昨日'+key.replace('ys_hour','')+':00')
                waterTime.push(key.replace('ys_hour','')+':00')
                waterData.push(Number(waterRes[0][key])*CARBON_FACTOR.toFixed(2) || '0.00')
            }
        }
        if (waterRes&&waterRes[1]) {
            for (let key in waterRes[1]) {
                waterTime.push(key.replace('hour','')+':00')
                waterData.push(Number(waterRes[1][key])*CARBON_FACTOR.toFixed(2) || '0.00')
            }
        }


        let eleRes = electricity.data.data;
        if (eleRes&&eleRes[0]) {
            for (let key in eleRes[0]) {
                // electricityTime.push('昨日'+key.replace('ys_hour','')+':00')
                electricityTime.push(key.replace('ys_hour','')+':00')
                electricityData.push(Number(eleRes[0][key]).toFixed(2) || '0.00')
            }
        }
        if (eleRes&&eleRes[1]) {
            for (let key in eleRes[1]) {
                electricityTime.push(key.replace('hour','')+':00')
                electricityData.push(Number(eleRes[1][key]).toFixed(2) || '0.00')
            }
        }

        this.setState({
            xAxisData: waterTime.length > electricityTime.length ? waterTime : electricityTime,
            waterData,
            electricityData,
            active: active
        })
    }


    componentDidMount() {
        this.getData();


        // 区域的数据请求时间太久，不再自动进行请求 -----
        // const upDataTime = 5 * 60 * 1000;//分钟
        // const source = timer(0, upDataTime);
        // source.subscribe((x) => { console.log('indexTopLeft:' + x); this.getData() })
    }

    selected(params) {

        if (params === "bar") {
            this.getData("bar")
        } else {
            this.getData("line")
        }
    }


    render() {
        const { xAxisData, active, waterData, electricityData } = this.state;


        var xAxisData2 = [];
        var data1 = [];
        var data2 = [];
        var data3 = [];
        var data4 = [];

        for (var i = 0; i < 10; i++) {
            xAxisData2.push('Class' + i);
            data1.push((Math.random() * 2).toFixed(2));
            data2.push((Math.random() * 5).toFixed(2));
            data3.push((Math.random() + 0.3).toFixed(2));
            data4.push(Math.random().toFixed(2));
        }

        var emphasisStyle = {
            itemStyle: {
                shadowBlur: 10,
                shadowColor: 'rgba(0,0,0,0.3)'
            }
        };

        let getOption  = {
            title: {
                // text: '折线图堆叠'
            },
            tooltip: {
                trigger: 'axis'
            },
            legend: {
                data: ['鹿城区', '瓯海区', '龙湾区', '洞头区', '其他地区'],

            textStyle:{
                color:'#69a64d'}

            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            // toolbox: {
                // feature: {
                //     saveAsImage: {}
                // }
            // },
            xAxis: {
                type: 'category',
                boundaryGap: false,
                // data: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
                data: xAxisData,
                axisLabel:{
                    textStyle:{
                        color:'#bfd45d'}
                },
            },
            yAxis: {
                type: 'value',
                axisLabel:{
                    textStyle:{
                        color:'#8d6f17'}
                },
            },
            series: [
                //    背景颜色
                { // For shadow
                    type: 'bar',
                    itemStyle: {
                        // color: '#193f75',
                        color: 'rgb(25,63,117,0.4)',
                    },
                    // barWidth:40,
                    barGap: '-100%',
                    barCategoryGap: '40%',
                    data: Array(electricityData.length).fill(r.apply(Math.max)(electricityData)),
                    animation: false
                },
                {
                    name: '水',
                    type: 'line',
                    data: waterData,
                    smooth: true,
                    // itemStyle: {
                    //     normal: {
                    //         color: '#03A9FF'
                    //     }
                    // },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 0, 0, 1,
                            [
                                {offset: 0, color: '#abf6c5'},
                                {offset: 0.5, color: '#82f07e'},
                                {offset: 1, color: '#59f035'}
                            ]
                        )
                    },
                    // emphasis: { label: { show: true } }
                }, {
                    type: 'line',
                    name: '电',
                    data: electricityData,

                    smooth: true,
                    // itemStyle: {
                    //     normal: {
                    //         color: '#FD7F33'
                    //     }
                    // },
                    itemStyle: {
                        color: new echarts.graphic.LinearGradient(
                            0, 1, 0, 0,
                            [
                                {offset: 0, color: '#1ded21'},
                                {offset: 0.5, color: '#3ad92c'},
                                {offset: 1, color: '#3beccf'}
                            ]
                        )
                    },
                },
            ]

            // series: [
            //     {
            //         name: '鹿城区',
            //         type: 'line',
            //         stack: '总量',
            //         data: [120, 132, 101, 134, 90, 230, 210]
            //     },
            //     {
            //         name: '瓯海区',
            //         type: 'line',
            //         stack: '总量',
            //         data: [220, 182, 191, 234, 290, 330, 310]
            //     },
            //     {
            //         name: '龙湾区',
            //         type: 'line',
            //         stack: '总量',
            //         data: [150, 232, 201, 154, 190, 330, 410]
            //     },
            //     {
            //         name: '洞头区',
            //         type: 'line',
            //         stack: '总量',
            //         data: [320, 332, 301, 334, 390, 330, 320]
            //     },
            //     {
            //         name: '其他地区',
            //         type: 'line',
            //         stack: '总量',
            //         data: [820, 932, 901, 934, 1290, 1330, 1320]
            //     }
            // ]
        };



        return (
            <div className='indexPartMiddle1V3'>
                <div className='indexPartMiddle1Head'>
                    {/*<Border4 className='Angle' sideLength={'5px'}>*/}
                        {/*<i className='round'></i>*/}
                    {/*</Border4>*/}
                    <span style={{fontSize: '18px',
                        fontWeight: 'bold',left:'10px',top:'1px',position:'absolute',  color: '#92d271',
                        fontFamily: 'monospace'}}>各时段碳排放总量</span>

                </div>
                <ReactEcharts option={getOption} notMerge={this.state.notMerge} lazyUpdate={this.state.lazyUpdate} style={this.state.style} />
            </div>
        )
    }
}


export default PartMiddle1;
