import React from 'react';
import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';
import { Icon } from 'antd';
import { timer } from 'rxjs';



import Api from 'api';
import './indexTopRight.scss';

import Border4 from '../../ui/border4/border4'

import Img_onlinEquipment from 'img/onlineDevice.gif';
import Img_errEquipment from 'img/errDevice.gif';

import Svg_errDevice1 from 'img/errDevice1.svg';
import Svg_errDevice2 from 'img/errDevice2.svg';
import Svg_errDevice3 from 'img/errDevice3.svg';

import Svg_onlineDevice1 from 'img/onlineDevice1.svg';
import Svg_onlineDevice2 from 'img/onlineDevice2.svg';
import Svg_onlineDevice3 from 'img/onlineDevice3.svg';


TweenOne.plugins.push(Children);

const IconFont = Icon.createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1235147_7f1h4511fvj.js',
});



class IndexTopRight extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onlineNumber: 0,
            errNumber: 0,
            onlineManager: 0,
            errManager: 0,
            svgShow: 0
        };
    }


    async getData() {

        const NewEquipment = await Api.postV3('/deviceNew/status', {
            project: Api.projectId,
            region: Api.region,
            regionProjects: Api.regionProjects,
        });

        return NewEquipment.data && this.setState(NewEquipment.data)
    }


    componentDidMount() {

        const upDataTime = 5 * 60 * 1000;//分钟
        const source = timer(0, upDataTime);
        source.subscribe((x) => { console.log('indexTopRight:' + x); this.getData() })
        let svgShow = 0;
        const wifiAnimation = () => {
            svgShow++
            if (svgShow >= 3) {
                svgShow = 0;
            }
            this.setState({
                svgShow: svgShow
            })
            setTimeout(wifiAnimation, 500)
        }
        wifiAnimation()

    }

    render() {
        return (
            <ul className='indexTopRight'>
                <li>
                    <Border4>
                        <img src={Svg_onlineDevice1} alt="" className='indexTopRightTextImg svg1' />
                        <img src={Svg_onlineDevice2} alt="" className={`indexTopRightTextImg svg2 ${this.state.svgShow >= 1 ? 'show' : null}`} />
                        <img src={Svg_onlineDevice3} alt="" className={`indexTopRightTextImg svg3 ${this.state.svgShow >= 2 ? 'show' : null}`} />
                    </Border4>
                    <div className='indexTopRightTextDiv'>
                        <b>
                            <TweenOne
                                animation={
                                    {
                                        Children: {
                                            value: this.state.onlineNumber, floatLength: 0,
                                        },
                                        duration: 3000,
                                    }
                                }
                            >
                                0
          </TweenOne>
                        </b>

                        <p>在线设备</p>
                    </div>

                </li>
                {/*<li>*/}
                    {/*<Border4>*/}
                        {/*<img src={Svg_errDevice1} alt="" className='indexTopRightTextImg svg1' />*/}
                        {/*<img src={Svg_errDevice2} alt="" className={`indexTopRightTextImg svg2 ${this.state.svgShow >= 1 ? 'show' : null}`} />*/}
                        {/*<img src={Svg_errDevice3} alt="" className={`indexTopRightTextImg svg3 ${this.state.svgShow >= 2 ? 'show' : null}`} />*/}
                        {/*/!* <img src={Img_errEquipment} alt="" className='indexTopRightTextImg err' /> *!/*/}

                        {/*/!* <IconFont type='icon-wuxianshebei' className='indexTopRightTextImg err' /> *!/*/}
                    {/*</Border4>*/}
                    {/*<div className='indexTopRightTextDiv'>*/}
                        {/*<b>*/}
                            {/*<TweenOne*/}
                                {/*animation={*/}
                                    {/*{*/}
                                        {/*Children: {*/}
                                            {/*value: this.state.errNumber, floatLength: 0,*/}
                                        {/*},*/}
                                        {/*duration: 3000,*/}
                                    {/*}*/}
                                {/*}*/}
                            {/*>*/}
                                {/*0*/}
          {/*</TweenOne>*/}
                        {/*</b>*/}
                        {/*<p>异常设备</p>*/}
                    {/*</div>*/}

                {/*</li>*/}

            </ul>
        )
    }
}
export default IndexTopRight;